.mainCard {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 3px #0000000a;
    border: 0.5px solid #e0e0e0;
    border-radius: 4px;
    opacity: 1;
}

.collectCardTitle {
    // height: 14px;
    // line-height: 30px;
    font-size: 16px;
    text-align: left;
    color: #6b6c6f;
    font-family: 'Roboto';
    font-size: 14px;
    padding: 10px 10px 22px;

    @media screen and (max-width: 600px) {
        padding: 10px 10px 12px;
        text-align: center;
    }

    // padding-top: 5px;
    // padding-bottom: 19px;
}

.imageCardHome {
    position: relative;

    .imageCardHomeData {
        position: absolute;
        right: 15px;
        top: -10px;
        text-align: right;
        width: 100px;

        @media screen and (max-width: 600px) {
            position: absolute;
            top: 50%;
            margin-top: 20px;
            /* position the top  edge of the element at the middle of the parent */
            left: 50%;
            /* position the left edge of the element at the middle of the parent */

            transform: translate(-50%, -50%);
        }
    }
    .disableCardImg {
        filter: grayscale(1);
        opacity: 0.6;
    }
}

.collectCardPara {
    font-size: 12px;
    color: #c1c1c1;
    text-align: left;

    padding: 16px 10px 10px;

    @media screen and (max-width: 600px) {
          padding: 40px 10px 10px;
         text-align: center;
    }
}

.collectCardNew {
    text-align: left;
    font: normal normal bold 24px/23px Poppins;
    letter-spacing: 0px;
    color: #273e59;
    opacity: 1;
    @media screen and (max-width: 600px) {
        text-align: center;
        padding: 0;
    }

    .price {
        margin-left: 20px;
        position: relative;

        @media screen and (max-width: 600px) {
            text-align: center;
            width: fit-content;
            margin: 0 auto;
        }
        .dollar {
            position: absolute;
            display: block;
            font-size: 12px;
            font-weight: 900;
            margin-top: -10px;
            margin-left: -10px;
            color: '#000';
            display: 'inline-block';
            @media screen and (max-width: 600px) {
                //   display:none
            }
        }
    }

    .price-nodata {
        font-size: 20px;
        padding: 18px;
        text-align: center;
        color: #c1c1c1;
        opacity: 0.6;
        @media(max-width: 375px) {
            font-size: 18px;
        }
    }
}


.serviceProviderCover {

    .mobilePriceCover {
        display: none;
    }
    @media(max-width: 600px) {
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        padding: 16px;

        .mobilePriceCover {
            display: flex;
            flex-direction: column;
        }

        .imageCardHome {
            display: flex;
            align-items: center;
            justify-content: center;
            .imageCardHomeData  {
                position: relative;
                transform: none;
                top: auto;
                left: auto;
                right: auto;
                margin-top: 0px;
            }
        }
        .desktopRangeCover,
        .desktopPriceCover {
            display: none;
        }
        .collectCardTitle {
            padding-bottom: 0px;
        }
        .collectCardPara {
            padding: 0px;

        }
    }
}