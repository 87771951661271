.nextMoveFinalPage {
    // margin-top: -70px;
    // min-height: 100vh;
    background-color: #eeeeee;
    margin-top: 78px;

    .nextMoveWhiteBackground {
        width: 80%;
        margin: 0px auto;

        // display: flex;
        // flex-direction: column;
        // align-items: center;
        // justify-content: center;
        // padding: 140px 200px;
        background-color: #fff;
        @media (max-width: 600px) {
            width: 100%;
        }
        .nextMoveInnerContentFinal {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 140px 200px;
            @media (max-width: 1024px) {
                padding: 140px 55px;
            }
            @media (max-width: 700px) {
                padding: 140px 15px;
            }
            @media (max-width: 400px) {
                padding: 35px 15px;
            }
            // .checkMarkNextMove {
            //     svg {
            //         height: 190px !important;
            //         width: 190px !important;
            //     }
            // }

            .nextPageFinalCheckMark {
                .checkmark {
                    height: 190px !important;
                    width: 190px !important;
                    @media (max-width: 400px) {
                        height: 135px !important;
                        width: 135px !important;
                    }
                }
                padding-bottom: 25px;
            }
            .nextPageFinalHead {
                font-size: 30px;
                font-weight: bold;
                font-family: 'Roboto';
                padding-bottom: 9px;
                text-align: center;
                @media (max-width: 400px) {
                    font-size: 16px;
                }
            }
            .nextPageFinalSubHead {
                font-size: 16px;
                font-family: 'Roboto';
                color: #6b6c6f;
                line-height: 24px;
                padding: 0px 20px 45px 20px;
                text-align: center;
            }
            .nextPageFinalButton {
                @media (max-width: 400px) {
                    width: 100%;
                    button {
                        width: 100% !important;
                    }
                }
            }
        }
    }
}
