.preMoveSignupMain {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .carouselDiv {
    width: 40%;
    margin-top: 79px;
  }

  .formDiv {
    width: 50%;

    .signupHeadingDiv {
      color: #333333;
      font-size: 30px;
      line-height: 32px;
      font-weight: bold;
      margin-bottom: 10px;
    }

    .signupSubHeadingDiv {
      color: #6B6C6F;
      font-size: 16px;
      line-height: 28px;
      margin-bottom: 33px;
    }
  }
}

.preMoveSignupCarouselMain {
  height: 92vh;
  background: #faf9f9;

  .CarouselItem {
    margin-bottom: 50px;
    height: 100%;
  }

  .carouselCardDiv {
    height: 100%;
    .carouselImageDiv {
      width: 100%;
      text-align: center;
      height: 50%;

      img {
        height: 100%;
        width: 100%;
      }
    }

    .carouselName {
      font-size: 24px;
      line-height: 32px;
      font-weight: bold;
      color: #333333;
      text-align: center;
      padding: 20px;
      margin-top: 20px;
    }

    .carouselDescription {
      font-size: 16px;
      line-height: 22px;
      color: #333333;
      text-align: center;
      padding: 10px 20px 0;
    }
  }
}

.preMoveSignupFormMain {
  .secondLineInput-signUp3 {
    margin-bottom: 30px;

    .helperText {
      margin-left: 46px;
      font-size: 12px;
      line-height: 22px;
      color: #6B6C6F;
    }
  }
}