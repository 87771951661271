.blogCard-main-div {
    width: 100% !important;
    // height: 100% !important;
    height: 370px;
    @media (max-width: 600px) {
        height: 430px !important;
    }
    @media (max-width: 768px) {
        width: 100% !important;
    }
    @media (max-width: 425px) {
        width: 100% !important;
    }
}

.blogCardTopImageDiv {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.blogCardTopImageImgDiv {
    width: 100%;
    height: 40%;
}

.BlogCardTopImageContentDiv {
    display: flex;
    flex-direction: column;
    // align-items: flex-start;
    justify-content: space-between;
    padding: 30px 20px 20px;
    // height: 60%;
    flex: 1;
    background-color: #fff;
}

.blogCardTopTitle {
    font-size: 20px;
    // font-weight: bold;
    margin: 10px 0 5px 0;
    line-height: 27px;

    @media (max-width: 1024px) {
        font-size: 15px;
        line-height: 20px;
    }

    @media (max-width: 425px) {
        font-size: 16px;
        line-height: 22px;
    }
}

.blogCardTopImageSubTitle {
    font-size: 14px;
    margin: 15px 0 0 0;
    opacity: 0.7;
    line-height: 22px;
    color: #6b6c6f;
    // border-bottom: 2px solid #DADCE0;
    padding-bottom: 10px;

    @media (max-width: 1024px) {
        padding-bottom: 7px;
    }
}

.blogCardTopImageView {
    width: 100%;
    padding-top: 15px;
    color: #006dff;
    font-size: 14px;
    border-top: 1px solid #dadce0;

    .blogViewLink {
        color: #006dff;
        cursor: pointer;
    }
}

.blogCardTopImageViewD {
    color: #009cbd !important;

    .blogViewLink {
        color: #009cbd !important;
        cursor: pointer;
    }
}
