.react-select {
    width: 98%;
}

.react-select div {
    background-color: #ffffff 0% 0% no-repeat padding-box !important;
    color: #333333 !important;
    // font-weight: 600;
    font-size: 14px;

    @media (max-width: 1440px) {
        font-size: 14px;
    }
    @media (max-width: 500px) {
        font-size: 12px;
    }
}
.css-1uccc91-singleValue {
    padding-top: 0px;
}

input {
    font-weight: '500';
}

.react-select .select-new__menu {
    z-index: 1000;
    text-align: left;
}

.react-select .css-4ljt47-MenuList {
    max-height: 175px;
}
.react-select .css-yk16xz-control {
    border-color: #d7dbec;
}
.react-select .css-1okebmr-indicatorSeparator {
    background-color: unset;
}
.preset {
    margin: 0;
}
.react-select .select-new__menu .select-new__option {
    background-color: #ffffff;
    cursor: pointer;
    color: #171717 !important;
    font-weight: 500;
    font-size: 14px;
    border-top-color: #d9dddf;
    border-top-width: thin;
    padding-top: 15px !important;
    padding-bottom: 10px;
}
.react-select .select-new__menu .select-new__option.select-new__option--is-focused {
    color: black !important;
    background-color: #f1f3f6;
}

.react-select .select-new__menu .select-new__option.select-new__option--is-selected {
    color: black !important;
    background-color: #f8f9fa;
    margin-top: 0px;
}
.react-select .css-1pahdxg-control {
    color: black !important;
    background-color: #f8f9fa;
    margin-top: 0px;
}
.react-selectD .css-1pahdxg-control {
    color: black !important;
    background-color: #f8f9fa;
    margin-top: 0px;
}
.react-selectD .select-new__menu .select-new__option.select-new__option--is-selected {
    color: black !important;
    background-color: #f8f9fa;
    margin-top: 0px;
}

.react-select .select-new__control {
    min-height: 59px;
    box-shadow: none;
}
// .react-select .select-new__control .select-new__indicators span,
// .react-select .select-new__control .select-new__indicators svg {
//   display: none;
// }
// .react-select
//   .select-new__control
//   .select-new__indicators
//   .select-new__indicator {
//   display: inline-block;
//   cursor: pointer;
//   position: relative;
//   width: 30px;
//   height: 100%;
// }
// .react-select .select-new__control .select-new__indicators .select-new__indicator:after {
//     display: inline-block;
//     color: #1e439b;
//     position: absolute;
//     right: 10px;
//     top: 16px;
//     margin-left: 0.255em;
//     vertical-align: 0.255em;
//     content: '';
//     border-top: 0.5em solid;
//     border-right: 0.4em solid transparent;
//     border-bottom: 0;
//     border-left: 0.4em solid transparent;
// }
.react-select {
    height: auto;
}

//   .css-1pahdxg-control:hover{
//   //  border-color:#1E439B !important;
//   }

.css-1pahdxg-control:hover {
    border-color: unset !important;
}
.react-selectD .css-1pahdxg-control:hover {
    border-color: unset !important;
}
