.warrantyCardMainDiv {
    box-shadow: 0px 3px 10px #00000029;
    // border: 1px solid #009cbd !important;
    border-radius: 4px;
    margin: 20px;
    width: 26%;
    font-family: 'Roboto';
    @media (max-width: 1024px) {
        width: 28%;
    }
    @media (max-width: 768px) {
        width: 40%;
    }
    @media (max-width: 600px) {
        width: 50%;
    }
    @media (max-width: 600px) {
        width: 100%;
    }

    .cardCheck {
        color: #b3b3b3;
        position: absolute;
    }

    .centerText {
        display: flex;
        flex-direction: column;
        // align-items: center;
        .recommend-detailed-card {
            height: 39px;
            margin-top: -38px;
            .recommend-internal {
                height: 39px;
                background-color: #009cbd;
                font-weight: bold;
                font-size: 16px;
                font-style: 'Roboto';
                display: flex;
                align-items: center;
                justify-content: center;
                color: #fff;
            }
        }

        .shield {
            color: #6b6c6f;
            font-family: 'Roboto';
            font-size: 16px;
            line-height: 32px;
            font-weight: bold;

            img {
                height: 50px;
            }

            .firstLetter {
                margin-top: -52px;
                margin-left: 20px;
            }
        }
        .title {
            color: #333333;
            font-size: 22px;
            line-height: 22px;
            font-family: 'Roboto';
            margin-top: 10px;
            padding: 20px;
            text-align: center;
            @media (max-width: 600px) {
                font-size: 14px;
                padding: 14px;
            }
        }
        .name {
            color: #08192f;
            font-size: 14px;
            line-height: 24px;
            font-weight: bold;
            font-family: 'Roboto';
            padding-bottom: 20px;
        }

        .lineDiv {
            width: 93%;
            border: 0.2px solid #d3d3d324;
        }
        .appliances-waranty {
            padding: 25px 0px 25px 30px;
            @media (max-width: 600px) {
                padding: 25px 0px 10px 30px;
            }

            .appliances-inner-content {
                font-size: 16px;
                font-weight: 600;
                font-family: Roboto;
                padding-bottom: 20px;
                @media (max-width: 600px) {
                    font-size: 12px;
                    font-weight: normal;
                }
            }
        }
        .button-select-plan {
            // width: 80%;

            padding: 0px 20px 40px 20px;
            display: flex;
            align-items: center;
            button {
                width: 100% !important;
                background-color: #100b28 !important;
                color: #ffffff !important;
            }
        }
        .rateDiv {
            padding-top: 20px;
            @media (max-width: 600px) {
                padding-top: 14px;
            }
            .rateText {
                font-size: 14px;
                line-height: 24px;
                font-weight: bold;
                margin-bottom: 20px;
                text-align: center;
                @media (max-width: 600px) {
                    font-size: 10px;
                    margin-bottom: 14px;
                }
            }
            .dollarBeforeDiscount {
                font-size: 24px;

                font-family: 'Poppins';
                text-decoration: line-through;
                @media (max-width: 600px) {
                    font-size: 12px;
                }
            }
            .rateBeforeDiscount {
                font-size: 24px;
                font-family: 'Poppins';
                padding-right: 12px;
                text-decoration: line-through;
                @media (max-width: 600px) {
                    font-size: 12px;
                }
            }

            .rate {
                font-size: 48px;
                line-height: 23px;
                font-weight: bold;
                font-family: 'Poppins';
                @media (max-width: 600px) {
                    font-size: 18px;
                    font-weight: 500;
                }
            }
            .dollar {
                font-size: 24px;
                line-height: 23px;
                font-weight: bold;
                font-family: 'Poppins';
                // margin-top: -8px;
                @media (max-width: 600px) {
                    font-size: 18px;
                    font-weight: 500;
                }
            }

            .rateTextDiv {
                display: flex;
                justify-content: center;
                margin-bottom: 20px;
            }
        }
    }
}
