.main-div-step {
    display: flex;
    flex-direction: row;

    @media (max-width: 768px) {
        flex-direction: column;
        width: 100% !important;
    }
}

.only-iPadPro {
    display: flex;
    flex-direction: column;
}

.tabs-card {
    display: flex;
    flex-direction: column;
    margin-left: 0;

    .card-right-body {
        width: 80% !important;
    }

    .wrapper-two-image-div {
        margin-top: -10px !important;
        margin-right: -25px;
        margin-left: 10%;
    }
}

.completed-card {
    display: flex;
    flex-direction: column;
    margin-left: 0;

    .card-right-body {
        width: 100% !important;
    }

    .wrapper-two-image-div {
        margin-top: -10px !important;
        margin-right: 25px;
    }
}

.card-right-body {
    width: 100% !important;
}

.step-title {
    font-size: 16px;
    font-weight: bold;
    margin: 0px 0px 10px 0px;
}

.step-subtitle {
    font-size: 12px;
    width: 70%;
    text-align: left;
}

.wrapper-one {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
}

.wrapper-two {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 10px;
}

.wrapper-two-image-div {
    margin-left: 10%;
    margin-right: 25px;
    img {
        width: 100%;
    }

    @media (max-width: 425px) {
        margin-right: 0;
    }
}

.image-class {
    margin-right: 25px;
    margin-top: -20px !important;
    img {
        width: 100%;
    }
}

.basic-card-body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media (max-width: 425px) {
        width: fit-content !important;
    }
}
