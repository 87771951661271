input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}
.modelContainerMain {
    display: flex;
    flex-direction: column;
    width: 40%;
    min-width: 692px;
    max-height: 80%;

    margin: 40px auto;
    overflow: auto;

    justify-content: center;
    align-items: center;
    position: relative;

    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid transparent;
    border-radius: 4px;
    opacity: 1;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }

    @media screen and (max-width: 1300px) and (min-width: 600px) {
        width: 60%;
        min-width: 60%;
        max-height: 60%;
    }
    @media screen and (max-width: 800px) and (min-width: 600px) {
        width: 90%;
        min-width: 90%;
        max-height: 60%;
    }
    @media screen and (max-width: 600px) {
        width: 95%;
        min-width: 95%;
        max-height: 95%;
    }

    .crossIcon {
        position: absolute;
        z-index: 999999;
        top: 16px;
        right: 26px;
    }

    .modalHeadCoverContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        background: #273e59;
        height: 73px;
        padding-left: 36px;
        padding-right: 20px;

        .modalTitleCover {
            display: flex;
            align-items: center;

            h4 {
                font-size: 16px;
                color: #ffffff;
                font-family: 'Roboto';
                margin-bottom: 0px;
                margin-left: 5px;

                @media(max-width: 375px) {
                    font-size: 14px
                }
            }
        }
        .crossIcon {
            position: relative;
            cursor: pointer;
            top: 0;
            right: 0;
            svg {
                path {
                    fill: #ffffff;
                }
            }
        }
    }

    .agentContactContainer {
        .innerTitle label {
            text-align: left;
            font: normal normal bold 14px/24px Roboto;
            letter-spacing: 0px;
            color: #333333;
            margin-top: 23px;
            margin-bottom: 5px;
        }
        .rated {
            ul {
                list-style: none;
                padding-left: 0;
                display: flex;
                .mortgageCard {
                    margin-right: 15px;
                    display: flex;
                    flex-direction: column;
                    background: #fdfbfb 0% 0% no-repeat padding-box;
                    border: 1px solid #e0e0e0;
                    padding: 15px;
                    max-height: 100px;
                    max-width: 190px;
                    border-radius: 4px;
                    cursor: pointer;
                    .imgspan {
                        min-height: 40px;
                        width: 130px;
                        margin: 0 auto;
                        img {
                            width: 100%;
                        }
                    }
                    .cardtxt {
                        text-align: center;
                        font-size: 12px;
                        font-family: 'Roboto';
                        line-height: 22px;
                        color: #6b6c6f;
                        text-transform: capitalize;
                    }
                    &.active {
                        border: 1px solid #0066f5;

                        .cardtxt {
                            color: #333333;
                        }
                    }
                }

                @media (max-width: 480px) {
                    display: flex;
                    flex-direction: column;

                    .mortgageCard {
                        margin-bottom: 20px;
                        margin-right: 0px;
                        display: flex;
                        justify-content: center;
                        max-width: 100%;
                        

                        &:last-child {
                            margin-bottom: 0px;
                        }
                    }
                }
            }
        }

        .generalFieldsContainer {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            margin-top: 22px;

            .mainContainer {
                width: 300px;
                margin: 0;
                margin-bottom: 24px;
                position: relative;
                .inputFeildMain {
                    width: 100%;
                    border: none;
                    .inputFeild {
                        width: 100%;

                        .MuiInput-formControl {
                            &:before {
                                border-bottom: none !important;
                            }
                        }
                        .MuiInputLabel-shrink{
                            transform: translate(0, 4.5px) scale(1.1);
                        }
                    }
                    .MuiInputBase-input {
                        color: #333333;
                        font-size: 14px;
                        padding-top: 14px;
                        padding-bottom: 4px;
                    }
                }

                @media(max-width: 600px) {
                    width: 100%;
                }

                &.mr {
                    margin-right: 20px;
                    @media(max-width: 600px) {
                        margin-right: 0px;
                    }
                }
            }
        }

        .contactPrefSelection {
            .MuiFormLabel-root.Mui-focused {
                color: #333333 !important;
            }
        }

        .MuiRadio-colorSecondary.Mui-checked, .MuiFormControl-root .Mui-focused {
            color: #0066f5 !important;
        }
        .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
            border-color:#0066f5 !important;
        }
        .MuiFormControl-root .MuiInput-underline:after {
            border-bottom: 2px solid #0066f5;
        }
        .MuiFormControlLabel-root {
            margin-top: 0px !important;
        }
        .MuiFormControlLabel-label {
            font-size: 14px;
            font-family: 'Roboto';
            color: #6B6C6F;
        }
        .MuiInputLabel-root {
            margin-left: 10px !important;
            font-size: 11px !important;
        }
        .errMessage {
            position: absolute;
        }
        .successfullyInquiry{
            margin-top: 57px;
            margin-bottom: 57px;
            .successTitle{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                img{
                    width: 34px;
                    height: 34px;
                    margin-right: 10px;
                    @media (max-width: 1024px) {
                        width: 30px;
                        height: 30px;
                    }
                    @media(max-width: 550px) {
                        width: 20px;
                        height: 20px;
                    }
                }
                span{
                    text-align: left;
                    font: normal normal bold 24px/32px Roboto;
                    letter-spacing: 0px;
                    color: #333333;
                    font-weight: bold;
                    @media (max-width: 1024px) {
                        font-size: 20px;
                        line-height: 24px;
                    }
                    @media(max-width: 550px) {
                        font-size: 15px;
                        line-height: 20px;
                    }
                }
                .subConfirmText {
                    font-size: 16px;
                    margin-top: 10px;
                    font-family: Roboto;
                }
            }
            // .successInfo{
            //     margin-left: 45px;
            //     @media(max-width: 550px) {
            //         margin-left: 30px;
            //     }
            //     .nameInfo{
            //         display: flex;
            //         flex-direction: column;
            //         .name{
            //             font: normal normal bold 16px/22px Roboto;
            //             letter-spacing: 0px;
            //             color: #333333;
            //             @media (max-width: 1024px) {
            //                 font: normal normal bold 14px/20px Roboto;
            //             }
            //         }
            //         .licenseNumber{
            //             font: normal normal 500 12px/22px Roboto;
            //             letter-spacing: 0px;
            //             color: #6B6C6F;
            //             text-transform: capitalize;
            //             @media (max-width: 768px) {
            //                 font: normal normal 500 10px/20px Roboto;
            //             }
            //         }
            //     }
            //     .contectInfo{
            //         margin-top: 18px;
            //         @media(max-width: 550px) {
            //             margin-top: 0px;
            //         }
            //         .mobileEmailDiv{
            //             display: flex;
            //             align-items: center;
            //             @media(max-width: 550px) {
            //                 flex-direction: column;
            //                 align-items: flex-start;
            //             }
            //             .mobileDiv{
            //                 margin-right: 35px;
            //                 @media(max-width: 550px) {
            //                     margin-right: 25px;
            //                     margin-top: 15px;    
            //                 }
            //                 span{
            //                     margin-left: 15px;
            //                     font: normal normal normal 16px/22px Roboto;
            //                     letter-spacing: 0px;
            //                     color: #333333;
            //                     @media(max-width: 550px) {
            //                         margin-left: 5px;
            //                         font: normal normal normal 12px/18px Roboto;
            //                     }
            //                 }
            //             }
            //         }
            //         .location{
            //             margin-top: 18px;
            //             span{
            //                 margin-left: 12px;
            //                 text-align: left;
            //                 font: normal normal normal 16px/22px Roboto;
            //                 letter-spacing: 0px;
            //                 color: #333333;
            //                 @media(max-width: 550px) {
            //                     margin-left: 5px;
            //                     font: normal normal normal 12px/18px Roboto;
            //                 }
            //             }
            //         }
            //     }
            // }
        }
    }
    .agentContactButtons {
        float: none;
        width: 100%;
        text-align: center;

        button {
            &:first-child {
                margin-right: 12px !important;
            }
        }
    }
}

.mainDetailsContainer {
    width: 90%;
    margin: auto;
    text-align: left;

    .mainContainer {
        margin: 30px auto;
        .flexsection {
            width: 80%;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            @media screen and (max-width: 600px) {
                width: 90%;
            }

            .yearSection {
                flex: 0.5;
            }
            .monthSection {
                flex: 0.5;
            }
        }
    }
    .mainHeading {
        text-align: left;
        font: normal normal bold 16px/22px Roboto;
        letter-spacing: 0px;
        color: #333333;
        opacity: 1;
    }
    .mainDetailsContainerHeading {
        display: flex;
        flex-direction: row;
        color: #6b6c6f;
        font: normal normal bold 14px/24px Roboto;
        color: #6b6c6f;
        opacity: 1;
    }

    .inputFeildMain {
        position: relative;
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 1px solid #e0e0e0;
        opacity: 1;
        border-radius: 4px;
        width: 54%;

        padding: 10px 10px 10px 10px;

        .per {
            position: absolute;
            right: 4px;

            font: bold 18px/24px Roboto;
            color: #333333;
        }
        .per1 {
            position: absolute;
            left: 4px;
            top: 3;
            font: bold 14px/24px Roboto;
            color: #333333;
        }
        .inputFeild {
            border: none;
            font: bold 14px/24px Roboto;
            color: #333333;
        }
        .inputFeild:focus {
            outline: none;
        }
        .inputFeild1 {
            border: none;
            margin-left: 8px;
            font: bold 14px/24px Roboto;
            color: #333333;
        }
        .inputFeild1:focus {
            outline: none;
        }

        // .inputFeild:active{
        //     border:none

        // }
    }
}

.mortgageLoanDetailsCover {
    height: auto;
    overflow-y: scroll;
    &::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
    }
}

.mortgageLoanDetailsCover {
    .inputFeildMain,
    .MuiFormControl-root,
    .MuiInputBase-formControl,
    .flexsection {
        @media (max-width: 480px) {
            width: 100%;
        }
    }
    .inputFeildMain {
        display: flex;
        align-items: center;
        padding: 0;

        input {
            padding: 10px;
            width: 100%;
            // height: 100%;
        }
    }
    .errMessage {
        font: normal normal bold 16px/22px Roboto;
        color: red;
        font-size: 12px;
    }
    .actionButtons {
        @media (max-width: 480px) {
            width: 100% !important;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }

    .flexsection {
        @media (max-width: 480px) {
            width: 100% !important;
        }
        .MuiInputBase-formControl {
            @media (max-width: 480px) {
                width: 97%;
            }
        }
    }
}

.selectInput {
    width: 60%;
}

.buttons {
    float: right;
    margin: 20px auto;
}
