.mainSection {
    width: 80%;
    margin: 5px;
}

.aprFieldContaner {
    margin-bottom: 20px;

    .inputWithIcon {
        display: flex;
        align-items: center;

        input {
            margin: 0 !important;
            height: 54px !important;
            border-bottom-right-radius: 0px !important;
            border-top-right-radius: 0px !important;
            border-bottom-left-radius: 4px;
            border-top-left-radius: 4px;
            border: 1px solid #d8dce6;
            padding-left: 10px;
        }
    }
}
.mainSection h3 {
    text-align: left;
    font-size: 20px;
    font-weight: bold;
    font-family: 'Roboto';
    letter-spacing: 0px;
    color: #333333;
    text-transform: capitalize;
    opacity: 1;
}
.headingText {
    text-align: left;
    font-size: 14px;
    margin: 14px auto;
    font-family: 'Roboto';
    font-weight: bold;
    letter-spacing: 0px;
    color: #6b6c6f;
    text-transform: capitalize;
    opacity: 1;
}
.inputSelected[type='text'] {
    font-size: 14px;
    font-weight: bold;
    padding-left: 10px;

    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #d8dce6;
    border-radius: 4px;
    width: 140px;
    height: 50px;
    opacity: 1;
}
.inputSelected:focus {
    outline: none;
}

.radioButtonSelectedSaving {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #006dff;
    border-radius: 4px;
    width: 182px;
    height: 50px;
    opacity: 1;
    align-items: center;
    display: flex;
    margin: 16px 0px;
    justify-content: left;
    padding: 10px;
}
.radioButtonPerSelectedSaving {
    border: 1px solid #d8dce6;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
    width: 54px;
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.radioButtonNotSelectedSaving {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #d8dce6;
    border-radius: 4px;
    width: 182px;
    height: 50px;
    opacity: 1;
    align-items: center;
    display: flex;
    margin: 16px 0px;
    justify-content: left;
    padding: 10px;
}
.textRadioButton {
    padding: 2px 4px;
    text-align: left;
    font-size: 14px;
    font-family: 'Roboto';
    font-weight: bold !important;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
}
.blurTextRadioButton {
    padding: 2px 4px;
    text-align: left;
    font-size: 14px;
    font-family: 'Roboto';
    font-weight: normal;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;

    &.disableRadio {
        opacity: 0.7;
    }
}

.errMessage {
    font: normal normal bold 16px/22px Roboto;
    color: red;
    font-size: 12px;
}
