.someThingWentWrong {
    text-align: center;
    padding: 40px 15px;
    width: 850px;
    margin: 0 auto;
    max-width: 100%;
    box-sizing: border-box;
    h2 {
        font-size: 40px;
        margin: 0 0 20px;
    }
    .someThingWentWrongOopsTextPera {
        color: #6B6C6F;
        font-size: 22px;
        margin-bottom: 40px;
        display: inline-block;
        line-height: 1.4;
    }
    .someThingWentWrongImage {
        margin-bottom: 40px;
        img {
            max-width: 90%;
        }
    }
    .havetwobtns button {
        border-radius: 4px !important;
        height: 48px !important;
    }
}

@media (max-width:991px){
    .someThingWentWrong {
        h2 {
            font-size: 30px;
        }
        .someThingWentWrongOopsTextPera {
            font-size: 18px;
        }
    }
}

@media (max-width:575px){
    .someThingWentWrong {
        h2 {
            font-size: 18px;
        }
        .someThingWentWrongOopsTextPera {
            font-size: 16px;
        }
        .havetwobtns button {
            width: 140px !important;
            padding: 3px !important;
        }
    }
}
