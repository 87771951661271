.savingsSummaryFlowMainPage {
    // margin-top: -70px;
    min-height: 80vh;
    padding-top: 78px;

    .savingSummaryFlexMainDiv {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 70px;

        .savingsSummaryTitle {
            font-size: 30px;
            line-height: 32px;
            font-weight: bold;
            color: #333333;
            margin-bottom: 20px;
            text-align: center;
        }

        .savingsSummarySubTitle {
            font-size: 16px;
            line-height: 28px;
            color: #6b6c6f;
            text-align: center;
        }

        .savingsSummaryCardMainDiv {
            border: 1px solid #e2e2e2;
            width: 30%;
            padding: 30px;
            display: flex;
            margin: 40px 0;
            border-radius: 4px;
            @media (max-width: 1245px) {
                width: 42%;
            }
            @media (max-width: 900px) {
                width: 60%;
            }
            @media (max-width: 600px) {
                width: 85%;
            }
            @media (max-width: 430px) {
                width: 75%;
            }
            @media (max-width: 350px) {
                display: flex;
                flex-direction: column;
                width: 75%;
            }
            @media (max-width: 300px) {
                width: 65%;
            }

            .savingsSummaryImageDiv {
                margin-right: 25px;

                .savingsSummaryImage {
                    width: 85px;
                }
            }

            .savingsSummaryDetailsDiv {
                width: 100%;
                .savingsSummaryName {
                    font-size: 22px;
                    line-height: 28px;
                    color: #333333;
                    margin-bottom: 10px;
                }

                .savingsSummarySubName {
                    font-size: 12px;
                    line-height: 16px;
                    color: #747e8b;
                    margin-bottom: 10px;
                }

                .savingsSummaryPhoneDiv {
                    font-size: 16px;
                    line-height: 22px;
                    color: #333333;
                    display: flex;
                    align-items: center;
                    margin: 10px 0;

                    .savingsSummaryIcon {
                        margin-right: 10px;
                    }
                }

                .savingsSummaryDetailsButtonDiv {
                    float: right;
                    margin: 25px 0 0 0;

                    .rescheduleButton {
                        width: auto !important;
                        border-radius: 4px;
                        margin-right: 20px !important;
                        @media (max-width: 500px) {
                            margin-right: 0px !important;
                            width: 100% !important;
                        }
                    }
                    .downloadButton {
                        width: auto !important;
                        border-radius: 4px;
                        @media (max-width: 500px) {
                            width: 100% !important;
                            margin-top: 10px !important;
                        }
                    }
                }
            }
        }
    }
}
