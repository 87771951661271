.breadCrumbCover {
    display: flex;
    position: relative;
    align-items: center;
    z-index: 99;

    li {
        font: normal normal normal 12px/18px Roboto;
        letter-spacing: 0px;
        color: #bebebe;
        list-style: none;
        transition: color ease-in-out 200ms;

        &.link,
        &:first-child {
            cursor: pointer;
            &:hover {
                color: #6b6c6f;
                transition: color ease-in-out 200ms;
            }
        }
        &:nth-last-child(2) {
            color: #6b6c6f;
        }
        &:last-child {
            display: none;
        }
    }

    .arrorwIcon {
        margin: 0px 5px;
    }
}
