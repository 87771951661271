.scrollmenu {
    z-index: 999;
    position: fixed;
    margin-top: -10px;
    width: 100%;
    height: auto;
    overflow: scroll;
    background: #eeeeee 0% 0% no-repeat padding-box;
    // overflow: auto;
    white-space: nowrap;
    @media screen and (max-width: 576px) and (min-width: 426px) {
        margin-top: 0px;
    }

    .scrollMenuContainer {
        display: inline-block;
        text-align: left;
        font-size: 14px;
        font-weight: normal;
        font-family: 'Roboto';
        letter-spacing: 0px;
        color: #6b6c6f;
        opacity: 1;
        text-align: center;
        padding: 14px;
        text-decoration: none;
    }
    .scrollMenuContainerActive {
        color: #0066f5;
        border-bottom: 2px solid #0066f5;
        padding: 14px;
    }

    @media(max-width: 480px) {
        a {
            font-size: 14px;
            font-family: Roboto;
        }
    }
}
.scrollmenu::-webkit-scrollbar {
    display: none;
}

//  .anchor{

//   height: 115px; /*same height as header*/
//   margin-top: -115px; /*same height as header*/
//   visibility: hidden;
// }
