// .PrivateTabIndicator-colorPrimary-6 {
//     background-color: #3f51b5;
//     // width: 100px !important
// }

// .MuiTabs-flexContainer {
//     // display: flex;
//     justify-content: space-between !important;
// }

// .MuiTabs-fixed {
//     margin: 0 50px !important;
// }

// .PrivateTabIndicator-root-5 .PrivateTabIndicator-colorPrimary-6 .MuiTabs-indicator {
//     width: 200px !important;
// }
// .MuiPickersDay-daySelected {
//     background-color: #009cbd !important;
// }
.dashboardChecklistCardSkeleton {
    width: 49% !important;
    padding-bottom: 20px !important;
}

.movingCardSkeleton {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.getStartedSkeleton {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.__floater__arrow {
    display: none;
}
.__floater__open {
    transform: none;
    padding: 0 !important;

    @media (max-width: 600px) {
        left: 4px !important;
        width: 100% !important;
    }
    @media (max-width: 360px) {
        left: -9px !important;
        width: 99% !important;
    }
}

.react-joyride__tooltip {
    @media (max-width: 600px) {
        padding: 15px 0px !important;
        width: 100% !important;
    }

    @media (max-width: 360px) {
        margin: 0 15px !important;
    }
}

@media (min-width: 815px) and (max-width: 825px) {
    .react-joyride__overlay {
        width: 90vh !important;
    }
}

// .dashboard-main-page {
//     margin-top: -7%;
// }

// .react-joyride__tooltip{
//     @media(max-width:768px){
//         padding: 0 !important;
//         box-sizing: none !important;
//     }
// }

.dashboard-main-page .dashboardMainHeader .MuiPaper-root.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded {
    top: 8% !important;
    left: 28% !important;
}
.moveFromMoveToCard2 {
    margin-top: 5%;
    @media (min-width: 1540px) and (max-width: 1920px) {
        margin-top: 3%;
    }
    @media (min-width: 1921px) and (max-width: 2560px) {
        margin-top: 0%;
    }
    // @media (max-width: 600px) {
    //     margin-top: 15%;
    // }
}
.moveFromMoveToCard2D {
    margin-top: 5%;

    @media (min-width: 1540px) and (max-width: 1920px) {
        margin-top: 3%;
    }
    @media (min-width: 1921px) and (max-width: 2560px) {
        margin-top: 0%;
    }
    @media (min-width: 769px) and (max-width: 1024px) {
        margin-top: 9%;
    }
    @media (min-width: 600px) and (max-width: 768px) {
        margin-top: 10%;
    }
}
.input1-moveCard .MuiFilledInput-root {
    border: 1px solid #dadce0;
    background-color: none !important;
}

.dashboardHeader {
    background-image: linear-gradient(73deg, #fdfbfb, #ebedee);
    display: flex;
    padding: 199px 100px 0 100px;

    @media (max-width: 1366px) {
        // padding: 40px 60px 0px 60px;
        padding: 170px 60px 0px 60px;
    }

    @media (max-width: 425px) {
        padding: 40px 0px 15px;
        background-image: none;
        display: block;
    }
}
.dashboardHeaderBackground {
    // background: url('/src/Assets/images/DouglasImages/mobile/utility.jpg') no-repeat center center/cover !important;
    overflow: hidden;
    padding: 110px 0px 0 0px !important;

    @media (max-width: 1366px) {
        padding-top: 100px !important;
    }
    @media (max-width: 1024px) {
        padding-top: 85px !important;
    }
    @media (max-width: 768px) {
        padding-top: 60px !important;
    }
    @media (max-width: 600px) {
        padding: 25px 0px 15px !important;
        background-image: none;
        display: block;
    }
}

.dashboardHeaderTitleDiv {
    width: 40%;
    margin-top: 5%;
    @media (max-width: 1024px) {
        width: 60% !important;
    }

    @media (max-width: 425px) {
        width: 95% !important;
        padding: 0 10px;
    }
}

.dashboardHeaderTitleDivDouglas {
    position: absolute !important;
    padding-left: 100px;
    z-index: 2;
    margin-top: 3% !important;
    width: 45% !important;
    @media (max-width: 1366px) {
        padding-left: 70px !important;
    }
    @media (max-width: 1024px) {
        width: 60% !important;
        // margin-top: 4% !important;
    }
    @media (max-width: 768px) {
        width: 70% !important;
        margin-top: 9% !important;
    }
    @media (max-width: 600px) {
        padding: 0px 10px !important;
        margin-top: 8% !important;
        width: 87% !important;
    }
    @media (max-width: 320px) {
        padding: 0px 5px !important;
        width: 84% !important;
    }
}

.dashboardImgDiv {
    width: 60%;

    @media (max-width: 1024px) {
        width: 40%;
        display: flex;
        align-items: flex-end;
    }

    @media (max-width: 425px) {
        width: 100%;
    }
}
.dashboardImgDiv1 {
    width: 100%;
    position: relative;
    .webImage {
        min-height: 300px;
        width: 100%;
        height: 450px;
        object-fit: cover;
        @media (max-width: 1024px) {
            display: none;
        }
    }
    .ipadProImage {
        display: none;

        @media (max-width: 1024px) {
            display: unset;
            height: 450px;
        }
        @media (max-width: 768px) {
            display: none;
        }
    }
    .ipadImage {
        display: none;

        @media (max-width: 768px) {
            display: unset;
            height: 500px;
            // object-fit: cover;
        }
        @media (max-width: 600px) {
            display: none;
        }
    }
    .mobileImage {
        display: none;
        @media (max-width: 600px) {
            display: unset;
            // width: 100%;
            // height: 420px;
        }
    }
}

.dashboardImgDiv img {
    width: 100%;
    margin-bottom: -5px;

    @media (max-width: 1366px) {
        margin-bottom: -2px;
    }

    @media (max-width: 1024px) {
        margin-bottom: 0px;
    }
    @media (max-width: 600px) {
        font-size: 12px;
        line-height: 18px;
    }
}

.things-to-do-div {
    padding: 30px 100px 0 100px;

    @media (max-width: 1366px) {
        padding: 30px 60px 0 60px;
    }

    @media (max-width: 425px) {
        padding: 20px 10px 0 10px;
        margin-top: 120px;
    }
    @media (max-width: 320px) {
        margin-top: 130px;
    }
    // @media (max-width: 390px) {
    //     margin-top: 90px;
    // }
}

.thingsTitle {
    font-size: 24px;
    line-height: 32px;
    font-weight: bold;
    margin-left: 15px;

    @media (max-width: 768px) {
        font-size: 20px;
    }

    @media (max-width: 425px) {
        font-size: 16px;
        line-height: 32px;
    }
}

.things-title-div {
    display: flex;
    align-items: center;
    margin-bottom: 25px;

    @media (max-width: 425px) {
        margin-bottom: 15px;
    }
}

.thingsFlexDiv {
    display: flex;
    justify-content: space-between;

    @media (max-width: 768px) {
        flex-wrap: wrap;
    }
}

.scheduleCardDiv,
.otherCardDiv {
    width: 49%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // height: 370px;
    // height: fit-content;

    @media (max-width: 768px) {
        width: 100%;
        margin-bottom: 20px;
    }
}

.dashboardCard {
    padding: 13px;
    height: 40%;
    // border-radius: 4px;
    margin-bottom: 10px;
    @media (max-width: 768px) {
        margin-bottom: 20px;
    }
    @media (max-width: 425px) {
        align-items: flex-start !important;
        height: auto;
    }
}

.dashboardCardSkeleton {
    padding: 13px;
    height: 40%;
    border-radius: 4px;
    margin-bottom: 20px;

    @media (max-width: 425px) {
        align-items: flex-start !important;
        height: auto;
    }
}

.dashboardCard .getStartedImage {
    width: 143px;
    height: 100%;

    @media (max-width: 425px) {
        width: 87px;
        height: 86px;
    }
}

.dashboardCardSkeleton .getStartedImage {
    width: 143px;
    height: 141px;

    @media (max-width: 425px) {
        width: 60px !important;
        height: 60px !important;
    }
}

.dashboardCardSkeleton .getStartedContentDiv {
    @media (max-width: 600px) {
        width: 40%;
    }
}

.only-mobile-date {
    display: none;

    @media (max-width: 600px) {
        display: block;
        margin-top: 20px;
    }
}

// .cardMargin {
//     margin-bottom: 20px;
// }

.getStartedDashBoard {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;

    .dashboardCard {
        height: 175px;
        border-radius: 4px;
    }
}
.dashboardCard:last-child {
    margin-bottom: 0px !important;
}
.dashboardCard .getStartedCardPara {
    margin-bottom: 7px;
}

.step2-right-card {
    @media (max-width: 768px) {
        display: none;
    }
}

.step-2-right {
    @media (max-width: 768px) {
        display: none;
    }
}
.blogCard-step4 {
    @media (max-width: 768px) {
        display: none;
    }
}

.above-mike-step-card {
    display: none;
    @media (max-width: 768px) {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
}

.step2-right-card-mobile {
    display: none;
    @media (max-width: 768px) {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
}

.only-mobile-step-3,
.only-mobile-step-4,
.blogCard-step4-mobile {
    display: none;
    @media (max-width: 768px) {
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-top: 100px;
    }
}
.blogCard-step4-mobile {
    display: none;
    @media (max-width: 768px) {
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-top: 0 !important;
    }
}

.step1-dashboard {
    display: flex;
    flex-direction: row;

    @media (max-width: 768px) {
        flex-direction: column;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }
}
.moveFrommoveToCard {
    margin-top: 50px;
}
.step2-dashboard {
    display: flex;
    flex-direction: row;
    margin-top: 50px;
    @media (max-width: 768px) {
        flex-direction: column;
    }
}
.step3-dashboard {
    display: flex;
    flex-direction: row;
    margin-top: 50px;
    @media (max-width: 768px) {
        flex-direction: column;
        width: 60%;
        margin-left: auto;
        margin-right: auto;
    }
    @media (max-width: 425px) {
        width: 100%;
    }
}
.step4-dashboard {
    display: flex;
    flex-direction: row;
    margin-top: 50px;
    @media (max-width: 768px) {
        flex-direction: column;
        margin-top: -130px;
        width: 60%;
        margin-left: auto;
        margin-right: auto;
    }

    @media (max-width: 425px) {
        flex-direction: column;
        width: 100%;
    }
}

.mike-card {
    width: 590px;
    height: 370px;

    @media (max-width: 768px) {
        width: 407px;
        height: 100%;
    }

    @media (max-width: 425px) {
        width: 100%;
        height: 100%;
    }
}

.step-2-right {
    margin-top: 10%;
}
.empty-div {
    width: 50%;
}
.step-3-left-cards {
    padding-left: 60px;
    display: flex;
    flex-direction: column;
    margin-top: 50px;

    @media (max-width: 1366px) {
        margin-top: 30px;
    }

    @media (max-width: 768px) {
        padding-left: 0;
        padding: 0 auto !important;
        margin-top: 0px;
        margin-left: auto;
        margin-right: auto;
    }
}

.step-3-right {
    margin-top: 200px;

    @media (max-width: 768px) {
        display: none;
    }
}

.step-left-card {
    margin: 10px;
    @media (max-width: 1366px) {
        margin: 5px;
    }
}

.step-4-right {
    margin-top: 50px;
    @media (max-width: 768px) {
        display: none;
    }
}
// .MovingChecklistMainDiv {
//     padding: 40px 100px;
// }

.MovingChecklistTitleDiv {
    display: flex;
    align-items: center;
    padding: 40px 100px 10px 100px;

    @media (max-width: 1366px) {
        padding: 40px 60px 10px 60px;
    }

    @media (max-width: 425px) {
        padding: 10px 10px 10px 10px;
        justify-content: space-between;
    }
}

.checklistTitle {
    font-size: 24px;
    line-height: 32px;
    font-weight: bold;
    margin-left: 10px;

    @media (max-width: 768px) {
        font-size: 20px;
    }

    @media (max-width: 425px) {
        font-size: 16px;
    }
}

.dashboardChecklistCard {
    width: 49% !important;
    height: 162px !important;
    padding-bottom: 14px !important;
    margin-bottom: 30px;
    // border-radius: 4px;

    @media (max-width: 1024px) {
        height: 174px !important;
    }

    @media (max-width: 768px) {
        width: 100% !important;
        height: auto !important;
    }

    @media (max-width: 425px) {
        width: 100% !important;
        height: auto !important;
        margin-bottom: 15px;
    }
}

.dashboardChecklistCard .MovingNextCardContentDiv {
    min-width: 50%;

    @media (max-width: 425px) {
        width: 60%;
        margin-left: 15px;
    }
}

.dashboardChecklistCard .MovingNextCardImgDiv {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px;
    @media (max-width: 1024px) {
        width: 40%;
        margin: 10px;
    }
    @media (max-width: 600px) {
        width: 30%;
        margin: 10px;
    }
}

.dashboardChecklistCard .MovingNextCardImgDiv img {
    @media (max-width: 425px) {
        margin: 0 10px;
        overflow: hidden;
    }
}

.dashboardChecklistCard .movingNextCardTitle {
    font-size: 22px;
    line-height: 30px;

    @media (max-width: 1366px) {
        font-size: 18px;
    }

    @media (max-width: 425px) {
        font-size: 18px;
    }
}

.dashboardChecklistCard .movingNextCardSubTitle {
    font-size: 14px;
    line-height: 22px;
    color: #6b6c6f;

    @media (max-width: 425px) {
        font-size: 12px;
        line-height: 20px;
    }
}

.dashboardChecklistCard .movingNextCardButton {
    width: 150px !important;
    height: 40px !important;
    // border: 1px solid #002e62 !important;
    // box-shadow: 0 1px 1px #161d251a !important;
}
.checklistCardDiv {
    padding: 30px 100px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @media (max-width: 1366px) {
        padding: 30px 60px 0 60px;
    }

    @media (max-width: 768px) {
        padding: 15px 60px;
    }

    @media (max-width: 425px) {
        padding: 15px 10px;
    }
}

.tab-div {
    display: flex;
    justify-content: space-between;
    padding: 0px 100px;
    border-bottom: 1px solid lightgray;

    @media (max-width: 1366px) {
        padding: 0px 60px;
    }

    @media (max-width: 425px) {
        padding: 0px 24px;
    }
}
.tab-divD {
    .tabButton-active {
        color: #009cbd !important;
        border-bottom: 1px solid #009cbd !important;
    }
    .viewAllChecklist {
        color: #009cbd !important;
    }
}
.viewAllChecklistMobileD {
    color: #009cbd !important;
}
.tabs {
    display: flex;
}

.tabButton {
    font-size: 14px;
    line-height: 24px;
    color: #333333;
    margin-right: 30px;
    padding: 5px 0;
    cursor: pointer;
}

.tabButton-active {
    font-size: 14px;
    line-height: 24px;
    color: #0066f5;
    font-weight: bold;
    border-bottom: 1px solid #0066f5;
    padding: 5px 0;
    margin-right: 30px;
    cursor: pointer;
}

.viewAllChecklist {
    font-size: 14px;
    line-height: 24px;
    color: #0066f5;
    font-weight: bold;
    cursor: pointer;

    @media (max-width: 425px) {
        display: none;
    }
}

.movingchecklist {
    display: flex;
}

.viewAllChecklistMobile {
    display: none;
    font-size: 14px;
    line-height: 24px;
    color: #0066f5;
    font-weight: bold;
    cursor: pointer;

    @media (max-width: 425px) {
        display: block;
    }
}

.blogsMainDiv {
    padding: 0px 100px;

    @media (max-width: 1366px) {
        padding: 0px 60px;
    }

    @media (max-width: 425px) {
        padding: 0px 10px;
    }
}

.blogsHeadingDiv {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.blogsTitleDiv {
    display: flex;
    align-items: flex-end;
}

.BlogsCardDiv {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: stretch;
    justify-content: space-between;
    margin-top: 20px;

    @media (max-width: 768px) {
        flex-wrap: wrap;
        .blogCard-div2 {
            margin-top: 20px;
        }
    }

    &.blogsDashCover {
        justify-content: flex-start;
        flex-wrap: wrap !important;
        .blogCard-div2 {
            margin-right: 20px;

            &:last-child {
                margin-right: 0px;
            }
        }

        @media (max-width: 992px) {
            justify-content: space-between;
        }

        @media (max-width: 768px) {
            justify-content: center;
        }
    }
}

.blogCard-div1 {
    width: 48%;

    @media (max-width: 768px) {
        width: 100%;
    }

    @media (max-width: 425px) {
        width: 100%;
        display: none;
    }
}

.blogCard-div1SibcyCline {
    width: 49%;

    @media (max-width: 768px) {
        width: 100%;
    }

    @media (max-width: 425px) {
        width: 100%;
        display: none;
    }
}

.blogCard-div1Mobile {
    display: none;

    @media (max-width: 425px) {
        width: 100%;
        display: block;
        margin-bottom: 20px;
    }
}

.blogCard-div1 .blog-main {
    width: 99% !important;

    @media (max-width: 768px) {
        width: 100% !important;
        height: auto !important;
        margin-bottom: 15px !important;
    }
}

.blogCard-div2 {
    width: 24%;
    display: flex;
    justify-content: space-between;
    border-radius: 4px;

    @media (max-width: 1366px) {
        width: 24%;
    }

    @media (max-width: 768px) {
        width: 49%;
    }

    @media (max-width: 425px) {
        width: 100%;
        margin-bottom: 20px;
    }
}

.bestDealsMainDiv {
    padding: 40px 100px;

    @media (max-width: 1366px) {
        padding: 40px 60px 0 60px;
    }

    @media (max-width: 425px) {
        padding: 13px 10px 0;
    }
}

.dealsCard-div1 {
    width: 73%;

    @media (max-width: 768px) {
        width: 100%;
    }
}

.dealsCard-div2 {
    width: 22%;
    margin-top: 30px;
    background-color: #24364d;
    border: 1px solid #24364d;
    box-shadow: 0 1px 3px #0000000a;
    padding: 20px;

    @media (max-width: 1024px) {
        width: 21%;
    }

    @media (max-width: 768px) {
        width: 100%;
    }

    @media (max-width: 425px) {
        margin-top: 15px;
    }
}

.checklistCardDiv .completedCard {
    width: 46%;
    padding: 10px 15px;

    @media (max-width: 768px) {
        width: 100%;
        // flex-wrap: wrap;
    }
}

.blogCard-div1 .blog-title {
    line-height: 30px;

    @media (max-width: 1366px) {
        font-size: 18px;
    }
}

.pendingTasksMainDiv {
    padding: 0 100px 30px 100px;

    @media (max-width: 1366px) {
        padding: 0px 60px 30px 60px;
    }

    @media (max-width: 425px) {
        padding: 0 10px 30px 10px;
    }
}

.pendingTasksCardsDiv {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;

    flex-wrap: wrap;

    @media (max-width: 425px) {
        margin-top: 26px;
    }

    &.pendingCardWrapper {
        .pendingTaskImageDiv {
            margin-right: 0px;
            width: 35%;
        }
        .pendingTaskContentDiv {
            padding: 20px 10px 20px 10px;
            flex: 1;

            .progress {
                height: auto;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .progressDiv {
                    margin: 0;
                    width: 57%;
                    .progressBarDiv {
                        width: 58%;
                    }

                    @media (max-width: 1024px) {
                        flex-direction: column;
                        justify-content: flex-start;
                        align-items: flex-start;
                        margin-top: 12px;
                        .progressBarDiv {
                            width: 90%;
                        }
                        .completedPercent {
                            margin-top: 8px;
                        }
                    }
                }
                .timer {
                    display: flex;
                    align-items: center;
                    width: 140px;

                    @media (max-width: 1024px) {
                        width: auto;

                        svg {
                            width: 17px !important;
                            margin-right: 5px !important;
                        }
                    }
                    @media (max-width: 425px) {
                        font-size: 12px;
                    }
                }

                @media (max-width: 360px) {
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;

                    .progressDiv {
                        width: 100%;
                        .progressBarDiv {
                            width: 100%;
                        }
                        .completedPercent {
                            font-size: 12px;
                        }
                    }
                }
            }

            .pendingButtonDiv {
                @media (max-width: 425px) {
                    margin-top: 10px;
                }
            }
        }
    }
}

.pendingTaskCard {
    width: 49%;
    padding-bottom: 0px;
    margin-bottom: 30px;
    border-radius: unset !important;

    @media (max-width: 768px) {
        width: 100%;
    }

    @media (max-width: 425px) {
        width: 100%;
    }
}

.dealsCardMainDiv {
    display: flex;
    justify-content: space-between;

    @media (max-width: 768px) {
        flex-wrap: wrap;
    }

    @media (max-width: 425px) {
        flex-wrap: wrap;
        margin-top: -30px;
    }
}

.discountCardImageDiv {
    height: 60%;
    width: 100%;

    @media (max-width: 768px) {
        width: 50%;
        text-align: end;
    }
}

.discountCardImageDiv img {
    width: 100%;
    height: 100%;

    @media (max-width: 768px) {
        width: 70%;
    }

    @media (max-width: 425px) {
        width: 100%;
    }
}

.discountCardContentDiv {
    height: 40%;
    color: #ffffff;
}

.discountHeading {
    font-size: 22px;
    line-height: 30px;
    font-weight: bold;

    @media (max-width: 1024px) {
        font-size: 18px;
        line-height: 26px;
    }

    @media (max-width: 768px) {
        font-size: 25px;
        line-height: 37px;
    }

    @media (max-width: 425px) {
        font-size: 18px;
        line-height: 26px;
    }
}

.discountButton {
    width: 130px !important;
    height: 38px !important;
    // border: 1px solid #08192f !important;
    // background-color: #ffffff !important;
    // box-shadow: 0px 1px 1px #161d251a;
    // color: #08192f !important;
    font-size: 14px !important;
    line-height: 22px !important;
    text-transform: capitalize !important;
    margin-top: 20px !important;
}

.additionalDiscountCard {
    @media (max-width: 768px) {
        display: flex;
        flex-flow: row-reverse;
        justify-content: space-between;
    }
}

.completedImageDiv img {
    @media (max-width: 425px) {
        width: 87px;
        height: 86px;
    }
}

.dashboard-main-page .completedImageDiv {
    img {
        width: 90%;
    }
    @media (max-width: 425px) {
        width: 30% !important;
    }
}

.dashboard-main-page .ContentButtonDiv {
    @media (max-width: 425px) {
        width: 70% !important;
        margin-left: 10px;
    }
}

.dashboardCalandly {
    position: absolute;
    width: 80%;
    height: 90%;
    background-color: #ffffff;
    left: 10%;
    top: 5%;
}

.cancellation-modal {
    padding: 40px;

    .logo-heading-div {
        display: flex;
    }
    .moveaesy-logo {
        max-height: 80px;
        max-width: 200px;

        img {
            width: 100%;
        }
    }
    .cancellation-heading {
        font-size: 24px;
        font-weight: 600;
        margin-left: 10%;
        width: 100%;

        @media (max-width: 800px) {
            font-size: 16px;
        }
    }
    .modal-body {
        display: flex;
        padding: 40px;
        box-shadow: 2px 6px 10px #e0e0e0;
        @media (max-width: 800px) {
            flex-direction: column;
            padding: 10px;
        }

        .left-detail {
            width: 30%;
            @media (max-width: 800px) {
                width: 100%;
            }

            .appointment-details-heading {
                font-weight: 600;
                font-size: 32px;
                margin-top: 5%;

                @media (max-width: 600px) {
                    font-size: 16px;
                    margin-top: 20px;
                }
            }
            .appointment-details {
                width: 80%;
                font-size: 18px;
                font-weight: bold;
                line-height: 40px;
                margin-top: 50px;
                opacity: 0.8;

                @media (max-width: 600px) {
                    font-size: 16px;
                    line-height: 30px;
                    margin-top: 20px;
                }

                .details {
                    font-size: 28px;
                    font-weight: 600;
                    opacity: 0.6;
                    @media (max-width: 600px) {
                        font-size: 18px;
                    }
                }
            }
        }
        .right-form {
            width: 70%;
            align-items: left;
            display: flex;
            flex-direction: column;
            margin: 10% 0 0 15%;

            @media (max-width: 800px) {
                width: 100%;
                margin: 40px 0;
            }

            @media (max-width: 600px) {
                margin: 0;
            }

            .reason-cancellation {
                font-size: 16px;
                font-weight: 600;
                padding: 20px 0;
                @media (max-width: 600px) {
                    padding: 0;
                }
            }
            .input-reason {
                margin: 20px 0;
                @media (max-width: 600px) {
                    margin: 0;
                }
            }

            .input-reason .MuiFormControl-root {
                width: 100%;
            }
            .cancel-event-button {
                margin: 40px 0;
            }
        }
    }
}
