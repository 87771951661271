.laCarteCard-main-div{
    border: 2px solid #DADCE0;
    box-shadow:0 0 10px #e4e6e8;
    border-radius: 5px;
    padding: 30px;
    display: flex;

    .carte-check-div{
        width: 20%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .carte-right-div{
        width: 80%;
    }
    .carte-heading{
        font-weight: bold;
        text-align: left;
    }
    .carte-subheading-check{
        display: flex;
        padding-top: 5px;
    }

    .carte-check{
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .carte-sub-heading,.carte-sub-heading2{
        font-size: 14px;    
    }
    .carte-sub-heading2{
     text-align: left;
    }
}