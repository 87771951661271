.navbar-main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 48px;
    box-shadow: 0px 1px 1px #00000015;
    opacity: 1;
    .navbar-left-side {
        font-size: 16px;
        font-family: 'Roboto';
        font-weight: 600;
        display: flex;
        align-items: center;
        padding-left: 29px;

        img {
            cursor: pointer;
        }

        @media (max-width: 600px) {
            padding-left: 10px;
        }
    }
    .deleteIcon {
        color: #e63717;
    }
    .navbar-right-side {
        display: flex;
        align-items: center;
        padding-right: 85px;
        .navbar-right-head {
            font-size: 14px;
            font-family: 'Roboto';
            font-weight: 600;
            display: flex;
            align-items: center;
            padding-right: 27px;
            cursor: pointer;
        }

        @media (max-width: 1024px) {
            padding-right: 20px;
        }

        @media (max-width: 600px) {
            padding-right: 10px;

            .navbar-right-head {
                display: none;
            }
        }
    }
    .menuIcon {
        display: none;
        @media (max-width: 600px) {
            display: block;
        }
    }
}
.preview-right-nav-main {
    width: 20%;
    .right-left-side {
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-shadow: 0px 1px 1px #00000015;
        padding: 10px;

        .right-side-name {
            font-size: 14px;
            font-family: 'Roboto';
        }
    }
    .right-right-side {
        padding: 10px;
        .right-side-des {
            font-size: 12px;
            font-weight: 500;
            font-family: 'Roboto';
            color: #6b6c6f;
            padding: 10px 0px;
        }
        .right-side-theory {
            font-size: 14px;

            font-family: 'Roboto';
        }
    }
}
.preview-main-div {
    display: flex;
    justify-content: space-between;
    .preview-main-right {
        width: 4%;
        box-shadow: 0px 1px 1px #00000015;

        @media (max-width: 600px) {
            display: none;
        }
    }
    .preview-main-left {
        // padding-top: 75px;
        width: 96%;
        background-color: #e0e0e0;
        display: flex;
        justify-content: center;
        align-items: center;

        @media (max-height: 1024px) {
            height: 95vh;
        }
        @media (max-height: 900px) {
            height: 94vh;
        }
        @media (max-height: 700px) {
            height: 93vh;
        }

        .images {
            width: auto;
            max-width: 80%;
            height: auto;
            max-height: 90%;
            display: flex;
            justify-content: center;
            img {
                width: 52%;
            }
        }
        @media (max-width: 600px) {
            width: 100%;
        }
    }
}
.modalDeleteFolder {
    width: 25%;
    height: 12%;
    background-color: #ffffff;

    // overflow-y: auto;
    padding: 28px 37px;
    .deleteModalMain {
        .deleteModalHead {
            font-size: 16px;
            font-family: 'Roboto';
            font-weight: 500;
            padding-bottom: 38px;
        }
        .deleteModalButtons {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            .deleteButtonBack {
                font-size: 16px;
                font-family: 'Roboto';
                font-weight: 500;
                color: #333333;
                cursor: pointer;
            }
            .deleteButtonRed {
                font-size: 16px;
                font-family: 'Roboto';
                font-weight: 500;
                cursor: pointer;
                color: #ff0000;
                padding-left: 40px;
            }
        }
    }

    @media (max-width: 600px) {
        width: 60%;
    }
}

.previewDocumentDetails {
    display: none;

    @media (max-width: 600px) {
        display: block;

        .descriptionHeading {
            font-size: 14px;
            line-height: 30px;
            color: #333333;
            border-bottom: 1px solid #e0e0e0;
            padding: 15px 10px;
            padding-bottom: 10px;
        }

        .descriptionData {
            padding: 15px 10px;
            font-size: 14px;
            line-height: 22px;
            color: #333333;
        }
    }
}

.modalMenuPreview {
    bottom: 0;
    position: absolute;
    width: 96%;
    background: #ffffff;
    padding: 20px 10px;

    .modalButtonPreview {
        width: 100% !important;
        max-height: 40px !important;
    }

    .modaltitleFlexDiv {
        display: flex;
        align-items: center;
        font-size: 16px;
        line-height: 30px;
        color: #333333;
        margin: 5px 0;

        .modalIcon {
            font-size: 18px;
            margin-right: 10px;
        }
    }
}

.modalEditFolder {
    width: 40%;
    max-height: 80%;
    background: #ffffff;
    padding: 20px 100px 50px;
    overflow: auto;

    .editModal {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .title {
            color: #333333;
            font-size: 18px;
            line-height: 30px;
            font-weight: bold;
            letter-spacing: 0.5px;
            font-family: 'Roboto', sans-serif;
        }

        .image {
            width: 80%;
            img {
                max-width: 100%;
            }
        }

        .uploadText {
            font-size: 14px;
            line-height: 23px;
            color: #0066f5;
            margin: 20px 0;
            cursor: pointer;
            flex-direction: column;
        }
        .inputValueDiv {
            width: 100%;

            .react-select .css-yk16xz-control {
                border-color: #6b6c6f;
            }
        }

        .inputTextValueDiv {
            width: 100%;

            textarea {
                border: 1px solid #6b6c6f !important;
                width: 97% !important;
            }
        }
        .buttonDocument {
            padding-top: 30px;
            width: 100%;
        }
    }
}
