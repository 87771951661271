


.remaining-balance-edit {
    color: #0066F5;
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    margin: -4px 0px;
    cursor: pointer;
}

.edit-icon {
    opacity: 1;
    margin-right: 3px;
}

table {
    width: 100%;
}

td {
    padding-right: 10px;
}

.mortage-title {
    margin: 10px 0 0 20px;
    color: #333333;
    text-align: left;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
}

.mortage-userinfo-container {
    display: flex;
    color: #6B6C6F;
    font-size: 12px;
    font-weight: bold;
    margin: 30px 0px 0px 0px;
    // justify-content: center;
    align-items: center;
}

.mortage-user-details{
    display: flex;
    flex-direction: column;
    margin: 0px 40px;
    gap: 10px;
 
}

.mortage-user-details-username {
 
    text-align: left;
    font: normal normal bold 24px/32px Roboto;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
}

.mortage-user-details-useremail,
.mortage-user-details-userphone,
.mortage-user-details-usertitle {
    display: flex;
    flex-direction: row;
    font-size: 14px;
    letter-spacing: 0px;
    color: #6B6C6F !important;
    opacity: 1;
}

.mortage-user-details-userphone-icon,
.mortage-user-details-useremail-icon {
    font-size: 14px;
    margin-right: 10px;
}

.mortage-greet-message {
    text-align: left;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
    margin-top: 10px;
}

.mortage-list {
    font-size: 14px;
    color: #6B6C6F;
    margin: 15px 0;
}

.mortage-list-icon {
    display: flex;
    flex-direction: row;
    
}
.mortage-list-iconBox1 {
    flex:0.08;
    width: 20px;
    margin: 7px 0px;
    @media (max-width: 600px) {
        flex:0.1;
        
    }

  
}
.mortage-list-iconBox1>img {
   
    width: 20px;
    margin-right: 1px;

  
}
.mortage-list-iconBox2 {
    flex:1;
      margin: 7px 0px;
      @media (max-width: 600px) {
        flex:0.9;
    }
}

// .mortage-list-icon>img {
//     width: 20px;
//     margin-right: 15px;
// }

// .mortage-list-icon {
//     margin-top: 10px;
// }
.MortageBalance{
 margin: 15px 10px;

 .mortgageTitleText {
    color: #6B6C6F;
    font-weight: bold;
    font-size: 20px;
    @media (max-width: 375px) {
      font-size: 14px;
      margin-bottom: 10px;
     }
 }
 
 @media (max-width: 600px) {
margin: 15px 0px;
 }
}

.RemaningMortageBalance{
width:90%;
 @media (max-width: 600px) {
                width: 100%;
                margin:auto;
                
                }
}
.MikeMortageBalance{
width:90%;
@media (max-width: 600px) {
width: 100%;
margin:auto;
}
               
    
}