.bestDealsCard {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-image: linear-gradient(85deg, #ffffff, #e0e0e0);
    padding: 20px 30px;
    border: 1px solid #dadce0;
    margin: 10px 0 0 0;

    @media (max-width: 1024px) {
        padding: 20px 15px;
    }

    @media (max-width: 425px) {
        padding: 20px 10px;
        align-items: flex-start;
    }
}

.bestDealsCardTitle {
    font-size: 22px;
    line-height: 32px;
    color: #333333;
    font-weight: bold;
    font-family: 'Roboto';

    @media (max-width: 1024px) {
        font-size: 18px;
        line-height: 28px;
    }

    @media (max-width: 425px) {
        font-size: 14px;
        line-height: 23px;
    }
}

.bestDealsCardPara {
    font-size: 14px;
    line-height: 24px;
    color: #6b6c6f;
    font-family: 'Roboto';

    @media (max-width: 425px) {
        font-size: 12px;
        line-height: 20px;
    }
}

.bestDealsButton {
    width: 130px !important;
    height: 38px !important;
    background-color: #ffffff !important;
    // border: 1px solid #08192f !important;
    // color: #08192F !important;
    // box-shadow: 0 1px 1px #161d251a !important;
    font-size: 14px !important;
    line-height: 22px !important;
}

.rightImage {
    @media (max-width: 425px) {
        height: 68px;
    }
}

.leftImage {
    @media (max-width: 425px) {
        display: none;
    }
}

.leftImageMobile {
    display: none !important;

    @media (max-width: 425px) {
        display: block !important;
        // color: #006DFF;
    }
}

.leftImageDiv {
    margin-right: 20px;
    @media (max-width: 1024px) {
        margin-right: 10px;
    }
    @media (max-width: 425px) {
        margin-right: 5px;
        margin-top: 5px;
    }
}
