.addTemplateUpperComponentDashboard {
    height: 50vh;
    @media (min-height: 800px) {
        height: 70vh;
    }
    @media (max-width: 600px) {
        height: unset;
    }

    .addtemplate-main-div {
        height: 120%;
        @media (max-width: 600px) {
            height: unset;
        }
    }
}
.TemplateUpperComponentDashboard {
    .firstDouglasDashboard {
        background: url('../../../Assets/images/DouglasImages/serviceIcon/1.jpeg');

        @media (max-width: 600px) {
            background: url('../../../Assets/images/DouglasImages/serviceIcon/1mob.jpg') no-repeat center center/cover;
        }
    }
    .secondDouglasDashboard {
        background: url('../../../Assets/images/DouglasImages/serviceIcon/2.jpeg');

        @media (max-width: 600px) {
            background: url('../../../Assets/images/DouglasImages/serviceIcon/2mob.jpg') no-repeat center center/cover;
        }
    }
    .thirdDouglasDashboard {
        background: url('../../../Assets/images/DouglasImages/serviceIcon/3.jpeg');

        @media (max-width: 600px) {
            background: url('../../../Assets/images/DouglasImages/serviceIcon/3mob.jpg') no-repeat center center/cover;
        }
    }
    .fourthDouglasDashboard {
        background: url('../../../Assets/images/DouglasImages/serviceIcon/4.jpeg');

        @media (max-width: 600px) {
            background: url('../../../Assets/images/DouglasImages/serviceIcon/4mob.jpg') no-repeat center center/cover;
        }
    }
    .leftClass {
        justify-content: left !important;
        padding-left: 100px !important;
        @media (max-width: 1024px) {
            justify-content: center !important;
            padding-left: 0px !important;
        }
    }
    .rightClass {
        justify-content: right !important;
        padding-right: 100px !important;
        @media (max-width: 1024px) {
            justify-content: center !important;
            padding-right: 0px !important;
        }
    }
    .template-main-div {
        padding: 200px 0px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        display: flex;
        justify-content: center;
        align-items: center;
        @media (min-width: 1536px) and (max-width: 2560px) {
            padding: 300px 0px;
        }
        @media (max-width: 1024px) {
            padding: 125px 0px;
        }
        @media (max-width: 1024px) {
            padding: 90px 0px;
        }

        // height: 300px;
        .imageBackground {
            position: relative;
        }
        .template-main-div1 {
            background-color: #ffffff;
            display: flex;
            flex-direction: column;
            padding: 30px 40px;
            // align-items: center;
            justify-content: center;
            // align-items: center;
            // text-align: center;

            .HeaderText {
                color: #100b28;
                font-size: 42px;
                margin: 0px 0 16px 0;
                // text-transform: capitalize;
                font-family: 'EuclidCircularA-Regular';
                // font-family: 'Helvetica', 'Arial', sans-serif;
                @media (max-width: 600px) {
                    font-size: 20px;
                }
            }
            .subheaderText {
                font-family: 'EuclidCircularA-Regular';
                color: #100b28;
                font-size: 16px;
                margin: 0px 0 25px 0;
                // text-transform: capitalize;
                line-height: 1.7;
                @media (max-width: 600px) {
                    font-size: 14px;
                    font-family: 'EuclidCircularA-Light';
                    line-height: 1.5;
                }
            }

            .highlightedText {
                font-family: 'EuclidCircularA-Semibold';
                @media (max-width: 600px) {
                    font-size: 24px;
                }
            }
            .IconsSection {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;

                .iconDivD {
                    .imageDivD {
                        border: 1px solid #bcc6d0;
                        border-radius: 50%;
                        height: 100px;
                        width: 100px;
                        position: relative;
                        margin-left: 8px;
                        img {
                            position: absolute;
                            top: calc(100% - 50px - 18px);
                            left: calc(100% - 50px - 20px);
                            height: 40px;
                            width: 40px;
                            @media (max-width: 600px) {
                                width: 20px;
                                height: 20px;
                                top: calc(100% - 25px - 10px);
                                left: calc(100% - 25px - 10px);
                            }
                        }
                        @media (max-width: 600px) {
                            width: 50px;
                            height: 50px;
                        }
                    }

                    .textContentD {
                        font-size: 16px;
                        color: #221d1d;
                        padding-top: 20px;
                        text-align: center;
                        font-family: 'EuclidCircularA-Regular';
                        @media (max-width: 600px) {
                            font-size: 13px;
                            width: 65px;
                        }
                        @media (max-width: 320px) {
                            font-size: 12px;
                            width: unset;
                        }
                    }
                    @media (max-width: 600px) {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        margin-right: 10px;
                    }
                }
                .iconDivD:last-child {
                    @media (max-width: 600px) {
                        margin-right: 0px;
                    }
                }
            }
            @media (max-width: 600px) {
                padding: 20px 10px;
            }
        }
        @media (max-width: 600px) {
            position: relative;
            min-height: 100px;
            height: 100px;
            margin-bottom: 220px;
        }

        .borderDiv {
            padding: 35px;
            max-width: 700px;
            min-width: 700px;
            @media (max-width: 1024px) {
                margin: 0px 50px;
                min-width: unset !important;
            }
            @media (max-width: 600px) {
                position: absolute;
                top: 50%;
                margin: 0px 10px;
                padding: 24px;
            }
        }
    }

    @media (max-width: 600px) {
        margin-top: -5px;
    }
}
