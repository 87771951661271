.card-insurance {
    margin: 1%;
    width: 25%;
    text-align: left;
    border-radius: 4px !important;
 
}

.card {
    margin: 0;
    width: 24% ;
    text-align: left;
    /* padding: 24px !important; */
    border-radius: none !important;
}

.name {
    color: '#000000';
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.5px;
    font-family: 'Lato', sans-serif;
    font-weight: 600;
    padding-top: 16px;
}

.title {
    color: #aaaaaa;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.5px;
    font-family: 'Lato', sans-serif;
    padding-top: 12px;
    /* letter-spacing: 0.5; */
}

.desc {
    font-size: 14px;
    font-family: 'Lato', sans-serif;
    color: '#000000';
    margin: 0;
    padding-top: 30px;
    line-height: 30px;
    letter-spacing: 0.5px;
    opacity: 0.6;
}

.rating {
    margin: 0;
    padding-top: 42px;
    padding-bottom: 30px;
}

.CarouselItem {
    margin-bottom:100px;
}
.Carousel-buttonWrapper-20 {
    position: relative !important;
}

.Carousel-next-25 {
    right: -25px !important;
    margin-top: 37px;
    margin-bottom: -43px;
}

.Carousel-prev-26 {
    left: -25px !important;
}

.Carousel-button-24 {
    background: transparent !important;
}



.Carousel-button-17 {
    background: transparent !important;
}

.Carousel-next-18 {
    right: -25px !important;
    margin-top: 37px;
    margin-bottom: -43px;
}
.Carousel-prev-19 {
    left: -25px !important;
}

.Carousel-buttonWrapper-13 {
    position: relative !important;
}


@media(max-width:1440px){
    .card-insurance {
        width: 30%;
        /* height: 350px; */
        
    }
}

@media(max-width:1280px){
    .card-insurance {
        width: 30%;
        height: 320px;
        
    }
    .rating {
        padding-top: 10px;
        padding-bottom: 40px;
    }

    .desc{
        padding-top:10px
    }
  
}

@media(max-width:1040px){
    .card-insurance {
        width: 33%;
        height: 280px;
        
    }
    .rating {
        padding-top: 5px;
        padding-bottom: 20px;
    }

    .desc{
        padding-top:5px;
        font-size:12px;
        line-height: 20px;
    }
    .name{
        font-size: 14px;
    }
  
}
/* 
@media(max-width:768px){
    .card-insurance {
        width: 200px;
        height: 230px;
        
    }
    .rating {
        padding-top: 5px;
        padding-bottom: 20px;
        margin-left: -13px;
    }

    .desc{
        padding-top:5px;
        font-size:8px;
        line-height: 15px;
    }
    .name{
        font-size: 10px;
    }

    .title {
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 0.5px;
        font-family: 'Lato', sans-serif;
        padding-top: 5px;
        /* letter-spacing: 0.5; */
    /* }
  
} */ 






@media (max-width: 1366px) {
    .name {
        font-size: 14px;
        line-height: 14px;
        padding-top: 13px;
    }

    .title {
        font-size: 12px;
        line-height: 13px;
        padding-top: 10px;
    }

    .desc {
        font-size: 13px;
        padding-top: 14px;
        line-height: 23px;
    }

    .rating {
        padding-top: 20px;
        padding-bottom: 12px;
    }

    .Carousel-next-25 {
        margin-top: 20px;
    }
}

@media (max-width: 1024px) {
    .desc {
        font-size: 11px;
        padding-top: 11px;
        line-height: 18px;
    }

    .rating {
        padding-top: 12px;
        padding-bottom: 0px;
    }
}

@media (max-width: 768px){

    .CarouselItem {
        margin-bottom: 45px;
    }
    .card-insurance {
        width: 230px;
        height: 230px;
    }
    .name {
        font-size: 11px;
        line-height: 7px;
        padding-top: 8px;
    }

    .title {
        font-size: 10px;
        line-height: 6px;
        padding-top: 9px;
    }

    .desc {
        font-size: 10px;
        padding-top: 8px;
        line-height: 14px;
    }

    .rating .MuiRating-root {
        font-size: 1rem;
    }

    .Carousel-next-25 {
        margin-top: 5px;
    }
}

@media(max-width:425px){
    .Carousel-button-23{
        display: none;
    }
    .card-insurance {
        width: 282px !important;
        text-align: left;
        height: 350px !important;
        border-radius: 4px !important;
        margin-top: 20px;
        margin-left:27px
     
    }

    .name {
        color: '#000000';
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.5px;
        font-weight: 600;
        padding-top: 16px;
    }
    .Carousel-fullHeightHoverWrapper-14{
        display: none;
    }
    
    .title {
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 0.5px;
        padding-top: 12px;
        /* letter-spacing: 0.5; */
    }
    
    .desc {
        font-size: 14px;
        margin: 0;
        padding-top: 30px;
        line-height: 30px;
        letter-spacing: 0.5px;
        opacity: 0.6;
    }

    
.rating {
    margin: 0;
    padding-top: 42px;
    padding-bottom: 30px;
}

}

@media(max-width:360px){
    .Carousel-button-23{
        display: none;
    }
    .card-insurance {
        width: 282px !important;
        text-align: left;
        height: 350px !important;
        border-radius: 4px !important;
        margin-top: 20px;
        margin-left:0 !important
     
    }

    

}