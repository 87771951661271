.trusted-recommended-main-page {
    .editPreferanceDiv,
    .clearAllFilter,
    .clear-text,
    .MuiCheckbox-colorSecondary.Mui-checked {
        color: #006dff !important;
    }
    padding: 0 80px;

    @media (max-width: 1366px) {
        // padding:0px 25px
        padding: 0px 60px;
    }

    @media (max-width: 600px) {
        // padding:0px 25px
        padding: 0px 10px;
    }
}
.trusted-recommended-main-pageDouglas {
    .editPreferanceDiv,
    .clearAllFilter,
    .clear-text,
    .MuiCheckbox-colorSecondary.Mui-checked {
        color: #009cbd !important;
    }
}
.trusted-recommended-main-head-2 {
    // margin: 30px 0px 0px 0px;
    margin-bottom: 30px;
    margin-top: 30px;

    // @media (max-width: 768px) {
    //     padding: 0 10px;
    // }
    @media (max-width: 600px) {
        margin-bottom: 5px;
    }
}

.trusted-recommended-heading {
    font-size: 20px;
    font-weight: 600;
    font-family: sans-serif;

    @media (max-width: 768px) {
        font-size: 22px;
        line-height: 32px;
    }
}

.trusted-recommended-path {
    display: flex;
    // margin-top: -25px;
    font-size: 12px;

    @media (max-width: 768px) {
        font-size: 12px;
        line-height: 18px;
    }
}

.trusted-recommended-Para {
    .Para {
        color: #bebebe;

        @media (max-width: 425px) {
            font-size: 12px;
        }
    }
}

.trusted-recommended-modalHead {
    color: #333333;
    font-size: 32px;
    font-weight: bolder;
    margin-bottom: 10px;

    @media (max-width: 425px) {
        font-size: 24px !important;
        padding: 0 10px;
    }
}

.trusted-recommended-main-divv {
    display: flex;
    justify-content: left;
    flex-direction: column;

    @media (max-width: 425px) {
        font-size: 14px;
        padding: 0 20px;
    }
}

.trusted-recommended-modalData {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    justify-content: left;

    .MuiSvgIcon-root {
        height: 50px !important;
        width: 100px !important;
        animation: myfirst 1s forwards;
    }
}

.trusted-recommended-textModal {
    margin-left: 10px;
    animation: myfirst1 1s forwards;
}

@keyframes myfirst1 {
    0% {
        fill: #bebebe;
        font-weight: 200;
    }
    99% {
        fill: #bebebe;
        font-weight: 200;
    }
    100% {
        fill: #333333;
        font-weight: 700;
    }
}

.trusted-recommended-modalData1 {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    justify-content: left;
}
.trusted-recommended-modalData1 .MuiSvgIcon-root {
    height: 50px !important;
    width: 100px !important;
    animation: myfirst 2s forwards;
}

.trusted-recommended-textModal1 {
    margin-left: 10px;
    animation: myfirst1 2s forwards;
}

.trusted-recommended-modalData2 {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    justify-content: left;
}
.trusted-recommended-modalData2 .MuiSvgIcon-root {
    height: 50px !important;
    width: 100px !important;
    animation: myfirst 3s forwards;
}

.trusted-recommended-textModal2 {
    margin-left: 10px;
    animation: myfirst1 3s forwards;
}
.trusted-recommended-modalData3 {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    justify-content: left;
}
.trusted-recommended-modalData3 .MuiSvgIcon-root {
    height: 50px !important;
    width: 100px !important;
    animation: myfirst 4s forwards;
}

.trusted-recommended-textModal3 {
    margin-left: 10px;
    animation: myfirst1 4s forwards;
}

.trusted-recommended-inner {
    display: flex;
    justify-content: space-between;
    // padding: 20px 0;

    @media (max-width: 600px) {
        flex-wrap: wrap;
        justify-content: center;
    }
}

.trusted-recommended-inner-div1 {
    width: 22%;

    @media (max-width: 600px) {
        display: none;
    }
}

.trusted-recommended-inner-div2 {
    width: 50%;

    @media (max-width: 600px) {
        width: 100%;
    }
}

.trusted-recommended-inner-div3 {
    width: 22%;
    padding: 59px 0;

    @media (max-width: 600px) {
        // display: none;
        width: 100%;
        padding: 0px 0;
    }
}

.preference-heading {
    font-weight: bold;
    padding: 20px 0;
}

.vendors-heading {
    font-weight: bold;
    padding-top: 14px;
    font-size: 22px;
}

.TrustedProsFilterDivMobile {
    display: none;
    border: 1px solid #bebebe;
    border-radius: 4px;
    padding: 10px;
    margin-top: 20px;

    .editPreferanceDiv {
        display: flex;
        color: #009cbd;
        font-size: 14px;
        line-height: 18px;
        cursor: pointer;
        height: fit-content;

        .editPreferanceIcon {
            width: 16px;
            height: 16px;
        }
    }

    .filterDateDiv {
        margin-bottom: 20px;
    }

    .DateHeading {
        color: #6b6c6f;
        font-size: 12px;
        line-height: 22px;
    }

    .DateText {
        font-size: 14px;
        line-height: 22px;
        color: #333333;
    }

    @media (max-width: 600px) {
        display: flex;
        justify-content: space-between;
    }
}

.mobileFilterDrawer {
    .main-filters-trusted {
        box-shadow: none;
        border: none;

        .heads-trusted {
            padding: 10px 0;
        }

        .checkbox-item-trusted {
            margin-left: 0;
        }

        .line1-trusted {
            display: none;
        }
    }

    .drawerHeadingDiv {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 0 30px;

        .iconTextDiv {
            display: flex;
            align-items: center;
            color: #333333;

            .backIconDiv {
                margin-right: 10px;
            }

            .headingTextDiv {
                font-size: 22px;
                line-height: 31px;
                font-weight: bolder;
            }
        }

        .clearAllFilter {
            color: #006dff;
            font-size: 14px;
            line-height: 18px;
        }
    }
}
