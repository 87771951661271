.Main {
    display: flex;
    flex-direction: row;
    // justify-content: space-between;
    margin: 20px auto;
    .MainBox1 {
        flex: 0.1;
        margin-top: 4px;
    }
    .MainBox2 {
        flex: 0.9;
        margin-left: 16px;
    }

    .mainHeading {
        display: block;
        text-align: left;
        margin: 5px 1px;
        font-size: 16px;
        font-family: 'Roboto';
        font-weight: bold;
        letter-spacing: 0px;
        color: #333333;
        opacity: 1;
        @media (max-width: 600px) {
            font-size: 14px;
        }
    }
    .subHeading {
        display: block;
        margin: 10px 1px;
        font-size: 14px;
        letter-spacing: 0px;
        color: #6b6c6f;
        opacity: 1;
        @media (max-width: 600px) {
            font-size: 12px;
        }
    }

    @media (max-width: 375px) {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}

.dashboardTopCard {
    background: #f0f4fc 0% 0% no-repeat padding-box;
    border-radius: 4px;
    padding: 13px;
    margin: 8px auto;
    width: 98%;
    .MainBox1 {
        flex: none;
    }
    .MainBox2 {
        flex: none;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        .loan-details-grp1-info-title {
            font-size: 14px !important;
            @media(max-width: 375px) {
              text-align: center;
            }
        }
        .loan-details-grp1-info-description {
            font-size: 12px;
            margin: 0px;
            max-width: 365px;
            @media(max-width: 600px) {
                width: 100% !important;
            }
            @media(max-width: 480px) {
                width: 100% !important;
                text-align: center;
            }
        }
        button {
            width: 152px !important;
            height: 38px !important;
            margin-left: 0px !important;
            margin-top: 10px !important;

            @media(max-width: 375px) {
                width: 100% !important;
            }
        }
    }
}
