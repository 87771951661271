.nextMoveBrokerageCard {
    box-shadow: 0px 3px 10px #00000029;
    border: 1px solid #e2e2e2;
    border-radius: 4px;
    // width: 100%;
    padding: 18px 20px;
    // @media(max-width:600px)

    .nextMoveBrokerageCardSub {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        @media (max-width: 320px) {
            display: flex;
            flex-direction: column;
        }
        .nextMoveBrokerageCardSub {
            display: flex;
            align-items: center;
        }
        .imageInfoLeft {
            display: flex;

            align-items: center;
            @media (max-width: 375px) {
                display: flex;
                flex-direction: column;
                align-items: center;
            }
        }
        .profileName {
            font-size: 22px;
            font-family: 'Roboto';
            font-weight: bold;
            padding-bottom: 10px;
            @media (max-width: 375px) {
                font-size: 16px;
            }
        }
        .profileInfoDetails {
            font-size: 14px;
            font-family: 'Roboto';
            font-weight: 'normal';
            padding-bottom: 10px;
        }
        .imageLogoBrok {
            margin-right: 20px;
            width: 93px;
            height: 93px;

            img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
            }
        }
        .addressBrokNextMove {
            display: flex;
            flex-direction: column;
            @media (max-width: 375px) {
                align-items: center;
            }
        }
        .brokrageNotify {
            display: flex;
            flex-direction: column;
            justify-content: space-between;


            .brokrageNotifyWrapper {
                background-color: rgb(45, 61, 87);
                letter-spacing: -0.32px;
                font-size: 16px;
                font-weight: 700;
                color: rgb(255, 255, 255);
                width: 135px !important;
                height: 50px;
                border-radius: 0px;
                text-align: center;
                text-transform: capitalize;
                margin-left: 14px !important;
                border: none;
                min-width: 30px;
                box-shadow: none;
            }
            // .brokrageNotifyWrapper2 {
            //     background-color: rgb(45, 61, 87);
            //     letter-spacing: -0.32px;
            //     font-size: 16px;
            //     font-weight: 700;
            //     color: rgb(255, 255, 255);
            //     width: 150px;
            //     height: 50px;
            //     border-radius: 0px;
            //     text-align: center;
            //     text-transform: capitalize;
            //     margin:18px;
            //     min-width: 30px;
            //     box-shadow: none;
            // }
            img {
                width: 60%;
            }
            @media (max-width: 900px) {
                display: none;
            }
            .brokLogo {
                padding-bottom: 55px;
                text-align: end;
                img {
                    width: 60%;
                    min-width: 128px;
                }
            }
            .cBtnCover {
                text-align: end;

                button {
                    border-radius: 4px !important;
                }
            }

            .buttonBrokerage {
                @media (max-width: 768px) {
                    width: 100% !important;
                }
            }
        }
    }
    .brokrageNotifyMobile {
        @media (min-width: 900px) {
            display: none;
        }
        @media (max-width: 900px) {
            display: unset;
        }
        .brokrageNotifyMobileInner {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: baseline;
            padding-top: 20px;

            .brokLogo {
                padding-bottom: 35px;
                width: 128px;
                img {
                    width: 100%;
                }
            }

            .buttonBrokerage {
                border-radius: 4px !important;
                width: 100% !important;
            }
        }
    }
}
.nextMoveApprovalCard {
    border: 1px solid #dadce0;
    border-radius: 4px;
    padding: 21px 30px 13px 20px;
    box-shadow: 0px 3px 10px #00000029;
    @media (max-width: 320px) {
        padding: 21px 20px 13px 20px;
    }
    .nextMoveMoveApprovalCardSub {
        display: flex;
        flex-direction: column;
        @media (min-width: 900px) {
            .approvalMobile {
                display: none;
            }
        }

        .apporvalMortageHead {
            font-size: 18px;
            font-family: 'Roboto';
            font-weight: bold;
            padding-bottom: 8px;
            @media (max-width: 900px) {
                display: none;
            }
        }
        .approvalMobile {
            @media (max-width: 900px) {
                display: unset;
            }
            .approvalMobileInner {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                .apporvalMortageHeadMobile {
                    font-size: 16px;
                    font-family: 'Roboto';
                    font-weight: bold;
                    padding-bottom: 8px;
                    line-height: 25px;
                    padding-bottom: 15px;
                }
                .addressApprovalNextMove {
                    padding-left: 10px;
                }
            }
        }
        .approvalMortrage {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            .enquirySend {
                display: flex;
                flex-direction: column;
                .enquirySendHead {
                    font-size: 14px;
                    font-family: 'Roboto';
                    color: #6b6c6f;
                    padding-bottom: 12px;
                }
            }
            .addressApprovalNextMove {
                @media (max-width: 900px) {
                    display: none;
                }
            }
        }
    }
}

.nextMoveActivateCard {
    width: 150px;
    height: 75px;
    @media (max-width: 855px) {
        width: 120px;
    }
    @media (max-width: 375px) {
        width: 70px;
    }

    .nextMoveActivateCardSub {
        display: flex;
        flex-direction: column;
        align-items: center;
        .nextMoveActivateCardHead {
            font-size: 14px;
            font-weight: 600;
            font-family: 'Roboto';
            padding-top: 8px;
            @media (max-width: 375px) {
                font-size: 8px;
            }
        }
    }
}
