.checkbox-item-utility {
    display: flex;
    align-items: center;
    margin-top: -5px;
    // padding-left: 15px;
}

.heads-utility {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 15px;
    // padding: 15px 15px 0 15px;
}

.line1-utility {
    border-top: 0.3px solid #d2d2d2;
    margin-top: 10px;
    margin-bottom: 15px;

    // margin-right: -20px;
}

.MuiSvgIcon-root {
    height: 18px;
}
.checkbox-item-utility .MuiTypography-body1 {
    font-size: 16px;

    color: #333333;
    opacity: 0.9;

    @media (max-width: 1024px) {
        font-size: 13px;
    }

    @media (max-width: 768px) {
        font-size: 16px;
    }
}

.main-filters-utility {
    border: 1px solid #e0e0e0;
    box-shadow: 0px 3px 4px #00000024;
    border-radius: 4px;
    padding: 15px;

    .MuiFormControlLabel-root {
        margin-right: 0;
    }
    @media (max-width: 768px) {
        box-shadow: none;
        border: none;
    }
}

.headDv-utility {
    display: flex;
    justify-content: space-between;
    align-items: center;

    // margin-bottom: 10px;
}

.checkbox-item-utility {
    .MuiCheckbox-colorSecondary.Mui-checked {
        color: #006dff !important;
    }
}
.checkbox-item-utilityDoug {
    .MuiCheckbox-colorSecondary.Mui-checked {
        color: #009cbd !important;
    }
}
