.card-heading-insurance {
    color: #333333;
    font-size: 22px;
    font-weight: bold;
    font-family: 'Roboto';
    text-align: left;
    padding-bottom: 15px;
}

.propertycard {
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    box-shadow: 0px 3px 10px #00000029;
    margin-bottom: 10px;
}

.property-details-content {
    display: flex;
    flex-direction: row;
    padding: 12px 16px;
}

.text {
    color: #989898;
    font-size: 16px;
    font-weight: bold;
    font-family: 'Roboto';
    text-align: left;
}
.property-details-content .column .text, .home-card-row .home-card-column .text {
    font-size: 16px !important;
    color: #989898;
}
.value {
    color: #333333;
    font-size: 16px;
    font-family: 'Roboto';
    text-align: left;
    padding-top: 6px;
    padding-bottom: 8px;
}

.poolValue {
    width: 100%;
}

.divider {
    border-bottom: 1px solid #d8dce6;
}

.column,
.home-card-column {
    display: flex;
    flex-direction: column;
    padding-left: 14px;
}

.column svg {
    width: 24px !important;
}

.edit {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    color: #0066f5;
    font-size: 14px;
    font-family: 'Roboto';
    text-decoration: underline;
    padding-top: 10px;
    padding-right: 20px;
}
.editDouglas {
    color: #009cbd !important;
}

.vertical-centre {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.property-flex,
.home-card-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 12px 16px;
}

.home-card-row {
    padding: 16px;
    width: 100%;
}

.home-card-column {
    padding-left: 0px;
    width: 50%;
}

@media (max-width: 1700px) {
    .poolValue {
        max-width: 100px;
    }
}

@media (max-width: 1280px) {
    .property-details-content {
        padding: 12px 8px;
    }
}

@media (max-width: 768px) {
    .propertycard .property-flex {
        flex-direction: column;
    }
    .propertycard .home-card-row {
        width: auto;
    }
}
@media (max-width: 600px) {
    .propertycard {
        margin-bottom: 100px !important;
    }
}



