.signup-main-page {
    margin-top: 80px;
    .signupBannerMobile {
        display: none;

        img {
            width: 100%;
        }
        @media (max-width: 600px) {
            display: block;
        }
    }

    .signup-getStartedDiv {
        // background: url('../../../Assets/images/SignUp/signupbackground.svg');
        // background-size: contain;
        // background-repeat: no-repeat;
        padding: 35px 120px;
        display: flex;
        justify-content: space-between;
        // height: 533px;
        // height: 650px;

        .signup-getStartedLeftDiv {
            width: 40%;

            .signup-getStartedMobile {
                display: none;
                margin-top: 25px;
            }

            @media (max-width: 600px) {
                width: 100%;

                .signup-getStartedMobile {
                    display: block;
                    .signup-getStartedRightDiv {
                        display: block;
                        width: 100%;
                    }
                    .signup-getStartedRightDivD {
                        display: none !important;
                    }
                }
            }
        }

        .signup-getStartedRightDiv {
            width: 60%;

            img {
                width: 100%;
            }
            @media (max-width: 768px) {
                display: none !important;
            }
            @media (max-width: 600px) {
                // width: 100%;
                display: none;
            }
        }

        .textDiv {
            // padding: 35px 120px;
            .hello-subPara {
                font-size: 14px;
                opacity: 0.8;
            }
            .hello-div,
            .hello-div-name {
                font-size: 30px;
                line-height: 48px;
                text-transform: capitalize;

                @media (max-width: 1536px) {
                    font-size: 22px;
                    line-height: 32px;
                }

                @media (max-width: 1366px) {
                    font-size: 18px;
                    line-height: 28px;
                }

                @media (max-width: 768px) {
                    font-size: 14px;
                    line-height: 18px;
                }

                @media (max-width: 600px) {
                    font-size: 22px;
                    line-height: 31px;
                    font-weight: bold;
                    // text-align: left;
                }
            }

            // .hello-div-name {
            //     @media (max-width: 600px) {
            //         display: none;
            //     }
            // }
            .name {
                font-size: 30px;
                line-height: 48px;
                text-transform: capitalize;
                font-weight: bold;

                @media (max-width: 1536px) {
                    font-size: 22px;
                    line-height: 32px;
                }

                @media (max-width: 1366px) {
                    font-size: 18px;
                    line-height: 28px;
                }

                @media (max-width: 768px) {
                    font-size: 14px;
                    line-height: 18px;
                }

                @media (max-width: 600px) {
                    font-size: 22px;
                    line-height: 31px;
                }
            }
        }
        .imageDiv {
            .backgroundImage {
                width: 100%;
            }
        }

        @media (max-width: 1600px) {
            // height: 533px;
        }
        @media (max-width: 1536px) {
            // height: 487px;
            padding: 30px 80px;
        }

        @media (max-width: 1366px) {
            // height: 455px;
            padding: 30px 60px;
        }

        @media (max-width: 1024px) {
            // height: 324px;
        }

        @media (max-width: 768px) {
            // height: 230px;
        }

        @media (max-width: 600px) {
            padding: 30px 10px 0 10px;
            // text-align: center;
            background: none;
            background-size: auto;
            height: auto;
            flex-wrap: wrap;
        }
    }
    .signup-getStartedDivDouglas {
        // background: url('../../../Assets/images/SignUp/signUpMainImage.png');
        // background-size: contain;
        // background-repeat: no-repeat;
        // background-size: cover;
        // padding: 35px 0px 25px 120px;
        position: relative;

        // display: flex;
        // justify-content: space-between;
        // height: 533px;
        // height: 650px;
        .topImageClass {
            img {
                width: 100%;
                // height: 700px;
                @media (max-width: 1366px) {
                    // height: 85vh;
                }
                @media (max-width: 1024px) {
                    height: 700px;
                }
                @media (max-width: 768px) {
                    height: 625px;
                }
            }
            @media (max-width: 600px) {
                display: none;
            }
        }

        .signup-getStartedLeftDiv {
            // width: 40%;
            position: absolute !important;
            top: 4%;
            left: 9%;
            .signup-getStartedMobile {
                display: none;
                margin-top: 25px;
            }
            @media (max-width: 1024px) {
                top: 5%;
                left: 8%;
            }
            @media (max-width: 768px) {
                top: 5%;
                left: 4%;
            }

            @media (max-width: 600px) {
                width: 80% !important;
                left: 9% !important;
                top: 50% !important;
                .signup-getStartedMobile {
                    display: block;

                    .signup-getStartedRightDiv {
                        display: block;
                        width: 100%;
                    }
                }
            }
            @media (max-width: 600px) {
                position: unset;
            }
        }

        .signup-getStartedRightDiv {
            width: 60%;

            img {
                width: 100%;
            }

            @media (max-width: 600px) {
                // width: 100%;
                display: none;
            }
        }

        .textDiv {
            // padding: 35px 120px;
            .hello-subPara {
                font-size: 14px;
                opacity: 0.8;
            }
            .hello-div,
            .hello-div-name {
                font-size: 30px;
                line-height: 48px;
                text-transform: capitalize;

                @media (max-width: 1536px) {
                    font-size: 22px;
                    line-height: 32px;
                }

                @media (max-width: 1366px) {
                    font-size: 18px;
                    line-height: 28px;
                }

                @media (max-width: 768px) {
                    font-size: 14px;
                    line-height: 18px;
                }

                @media (max-width: 600px) {
                    font-size: 22px;
                    line-height: 31px;
                    font-weight: bold;
                    // text-align: left;
                }
            }

            // .hello-div-name {
            //     @media (max-width: 600px) {
            //         display: none;
            //     }
            // }
            .name {
                font-size: 30px;
                line-height: 48px;
                text-transform: capitalize;
                font-weight: bold;

                @media (max-width: 1536px) {
                    font-size: 22px;
                    line-height: 32px;
                }

                @media (max-width: 1366px) {
                    font-size: 18px;
                    line-height: 28px;
                }

                @media (max-width: 768px) {
                    font-size: 14px;
                    line-height: 18px;
                }

                @media (max-width: 600px) {
                    font-size: 22px;
                    line-height: 31px;
                }
            }
        }
        .imageDiv {
            .backgroundImage {
                width: 100%;
            }
        }

        @media (max-width: 1600px) {
            // height: 533px;
        }
        @media (max-width: 1536px) {
            // height: 487px;
            // padding: 30px 80px;
        }

        @media (max-width: 1366px) {
            // height: 455px;
            // padding: 30px 60px;
        }

        @media (max-width: 1238px) {
            // height: 324px;
            // height: 46vh;
        }
        @media (max-width: 1204px) {
            // height: 324px;
            // height: 53vh;
        }
        @media (max-width: 768px) {
            // height: 324px;
            // height: 74vh;
        }

        @media (max-width: 768px) {
            // height: 230px;
        }

        @media (max-width: 600px) {
            // padding: 30px 30px 0 30px;

            // text-align: center;
            background: url('../../../Assets/images/DouglasImages/signUpMobile.jpg') no-repeat center center/cover;
            height: 400px !important;
            flex-wrap: wrap;
        }
    }
    .signUp-get-divD {
        @media (max-width: 600px) {
            padding-top: 400px;
        }
    }
    .dashboardDiv {
        // background: url("../../../Assets/images/SignUp/signUpDashboard.png");
        // background-size: contain;
        // background-repeat: no-repeat;
        padding: 35px 0px;
        // height: 600px;
        // display: flex;
        // flex-direction: row;
        // height: 100%;
        // background: #273E59;
        text-align: center;

        // .dashboard-div-section1 {
        //     width: 40%;

        //     @media (max-width: 600px) {
        //         display: none;
        //     }
        // }

        // .dashboard-div-section2 {
        //     width: 60%;
        //     display: flex;
        //     flex-direction: column;

        //     @media (max-width: 600px) {
        //         width: 100%;
        //         padding-left: 10px;
        //     }
        // }

        .dashboardText {
            font-size: 30px;
            line-height: 32px;
            color: #ffffff;
            font-weight: bold;
            // text-align: center;
            padding: 10px 0;
            // margin-bottom: 20px;

            @media (max-width: 1536px) {
                font-size: 24px;
                line-height: 27px;
            }

            @media (max-width: 1366px) {
                font-size: 20px;
                line-height: 27px;
            }

            @media (max-width: 768px) {
                font-size: 14px;
                padding: 0px;
                // margin-bottom: 10px;
            }

            @media (max-width: 600px) {
                font-size: 12px;
                line-height: 23px;
                text-align: center;
                letter-spacing: 0.6px;
                width: 80%;
                margin: auto;
            }
        }

        @media (max-width: 1536px) {
            padding: 30px 0px;
        }

        @media (max-width: 1366px) {
            padding: 30px 0px;
            // height: 530px;
        }

        // @media (max-width: 1024px) {
        //     height: 385px;
        // }

        @media (max-width: 768px) {
            // height: 303px;
            padding: 15px 00px;
        }
    }
    @media (max-width: 600px) {
        margin-top: 55px;
    }
    .footerMobile {
        display: block;
        text-align: center;
        padding: 10px 0;
        color: #ffffff;
        background: #333333;
        font-size: 14px;
        line-height: 22px;
        @media (max-width: 600px) {
            font-size: 12px;
        }
    }
}
.BelowSectionDouglas {
    @media (max-width: 600px) {
        padding-top: 400px;
    }
    .firstSection {
        color: #221d1d;
        font-family: 'EuclidCircularA-Regular';
        font-size: 40px;
        text-align: center;
        margin-bottom: 60px;
        margin-top: 60px;
        @media (max-width: 768px) {
            margin-left: 20px;
            margin-right: 20px;
        }
        @media (max-width: 600px) {
            margin-bottom: 40px;
            margin-top: 20px;
            margin-left: 0px;
            margin-right: 0px;
            font-size: 22px;
            padding: 0 40px;
        }
    }
}
