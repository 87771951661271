.container {
    .loginMobileImgBottom {
        display: none;
        @media (max-width: 1100px) {
            display: unset;
            position: fixed;
            bottom: 0;
            width: 100vw;
        }
        // @media (max-width: 1100px) and (max-height: 800px) {
        //     opacity: 0.5;
        // }
    }
}

.loginMainContainer {
    background: url('../Assets/images/login/oldbg.svg') left bottom/cover;
    background-attachment: fixed;

    .signInButton {
        width: 397px !important;
        border-radius: 4px !important;
        @media (max-width: 1366px) {
            width: 100% !important;
        }
    }

    @media (max-width: 1100px) {
        background-image: linear-gradient(white, #dadce0);
    }
}

.douglasloginMainContainer {
    background: url('../Assets/images/DouglasImages/signInImage.png') center center/cover;
    height: 100%;
    @media (max-width: 1920px) {
        width: 100%;
    }
    @media (max-width: 1024px) {
        background: url('../Assets/images/DouglasImages/signInImage.png') left bottom/cover;
    }
    @media (max-width: 600px) {
        background: url('../Assets/images/DouglasImages/signInImage.png') center center/cover;
    }
    @media (max-width: 1280px) and (min-width: 1024px) {
        height: 120vh;
    }

    .signInButton {
        width: 100% !important;
    }
}
// .containerDouglas {
//     background: url('../Assets/images/DouglasImages/signInImage.png') center center/cover;
//     // position: fixed;

//     height: 100%;
//     @media (max-width: 1920px) {
//         width: 100%;
//     }
//     @media (max-width: 1024px) {
//         background: url('../Assets/images/DouglasImages/signInImage.png') left bottom/cover;
//     }
//     @media (max-width: 600px) {
//         background: url('../Assets/images/DouglasImages/signInImage.png') center center/cover;
//     }
// }
.loginBtn {
    margin-top: 16px;
    flex-grow: 1;
}
.header {
    text-align: center;
    background-color: #212121;
    color: #fff;
}
.card {
    margin-top: 80px;
    width: 500px;
}
.Error {
    color: red !important;
}

.login-getStartedDiv {
    // background: url('../Assets/images/login/loginBackground.svg');

    // background-size: 100;
    height: 100vh;
    max-width: 1920px;
    margin: 0 auto;
    padding: 65px 120px;
    margin-top: 70px;

    // @media (max-width: 2560px) {
    //     padding: 65px 512px;
    // }
    // @media (max-width: 2400px) {
    //     padding: 65px 430px;
    // }
    // @media (max-width: 2300px) {
    //     padding: 65px 380px;
    // }
    // @media (max-width: 2200px) {
    //     padding: 65px 330px;
    // }
    // @media (max-width: 2100px) {
    //     padding: 65px 280px;
    // }
    // @media (max-width: 2000px) {
    //     padding: 65px 230px;
    // }
    @media (max-width: 1920px) {
        padding: 65px 80px;
    }
    @media (max-width: 1700px) {
        padding: 20px 50px;
    }
    @media (max-width: 1100px) {
        padding: 65px 78px;
    }

    // @media (max-width: 2100px) {
    //     height: 95vh;
    // }
    // @media (max-width: 1750px) {
    //     height: 94.5vh;
    // }

    // @media (max-width: 1440px) {
    //     height: 94vh;
    // }

    // @media (max-width: 1366px) {
    //     height: 90vh;
    // }

    // @media (max-width: 1280px) {
    //     height: 92.5vh;
    // }
    // @media (max-width: 1100px) {
    //     background: url('../Assets/images/login/loginbackMobile.svg');
    //     background-repeat: no-repeat;
    //     // background: none;
    // }
    @media (max-width: 1100px) {
        padding: 65px 60px;
    }

    @media (max-width: 768px) {
        padding: 65px 30px;
    }

    @media (max-width: 600px) {
        padding: 0px 12px;
        text-align: center;
        margin-top: 60px;
        // background: none;
        // background-size: auto;
    }

    .loginContainer {
        // position: fixed;
        @media (max-width: 600px) {
            left: 10px;
            right: 10px;
        }
    }
    .loginContent {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;

        @media (max-width: 2560px) {
            justify-content: space-around;
        }

        @media (max-width: 1920px) {
            justify-content: space-between;
        }

        @media (max-width: 1800px) {
            justify-content: space-between;
        }

        @media (max-width: 1600px) {
            justify-content: space-between;
        }

        @media (max-width: 1500px) {
            justify-content: space-between;
        }
        @media (max-width: 1100px) {
            flex-direction: column;
        }
        // @media (max-width: 1280px) {
        //     justify-content: space-between;
        //     width: 100%;
        // }

        @media (max-width: 600px) {
            flex-direction: column;
            justify-content: unset;
        }
        .forgotPasswordMainText {
            color: #333333;
            font-weight: 800;
            font-size: 16px;
            margin-bottom: 20px;
        }
        .resetedPasswordMain {
            color: #333333;
            font-size: 16px;
            padding: 70px 0;
        }
        .loginWelcomeText {
            color: #333333;
            font-size: 44px;
            font-weight: 900;
            padding-bottom: 5px;
            @media (max-width: 1100px) {
                font-size: 36px;
            }
            @media (max-width: 768px) {
                font-size: 28px;
            }
            @media (max-width: 600px) {
                margin-top: 10px;
            }
        }
        .topHeadingForm {
            font-size: 32px;
            font-weight: 600;
            text-align: center;
            font-family: 'EuclidCircularA-Semibold';
            @media (max-width: 600px) {
                font-size: 20px;
            }
        }
        .subHeadingForm {
            font-size: 26px;
            font-family: 'EuclidCircularA-Regular';
            text-align: center;
            // padding-bottom: 27px;
            @media (max-width: 600px) {
                font-size: 20px;
            }
        }

        .loginLine {
            width: 136px;
            height: 8px;
            border-radius: 10px;
            @media (max-width: 600px) {
                display: none;
            }
        }

        .loginSignInText {
            color: #333333;
            font-size: 24px;
            padding-bottom: 20px;
            text-align: center;
            margin-top: 14px;
            @media (max-width: 768px) {
                font-size: 20px;
            }
            @media (max-width: 600px) {
                font-size: 20px;
                font-family: 'Lato';
                padding-top: 13px;
            }
            @media (max-width: 320px) {
                font-size: 17px;
            }
        }

        // Login Card
        .login-form-main-div {
            padding: 22px;
            width: 441px;
            margin-top: 20px;
            background: #ffffff 0% 0% no-repeat padding-box;
            box-shadow: 0px 3px 6px #00000026;
            border: 0.5px solid #e0e0e0;
            border-radius: 4px;

            button {
                border-radius: 4px;
            }

            @media (max-width: 1100px) {
                z-index: 1;
                width: 300px;
            }

            @media (max-width: 768px) {
                width: 320px;
            }

            @media (max-width: 600px) {
                background: transparent;
            }

            .loginCheckWrapper {
                display: flex;
                .PrivateSwitchBase-root-7 {
                    padding: 9px 9px 9px 0;
                }
                .keepMeSignedIn {
                    color: #333333;
                    font-size: 16px;
                    display: flex;
                    flex-direction: column;
                    align-self: center;

                    @media (max-width: 1100px) {
                        font-size: 15px;
                    }
                    @media (max-width: 768px) {
                        font-size: 14px;
                    }
                }
            }

            .loginForgotPassword {
                // color: #100b28 !important;
                font-size: 16px;
                font-weight: 700;
                padding-top: 16px;
                padding-bottom: 8px;
                cursor: pointer;
                @media (max-width: 768px) {
                    font-size: 14px;
                }
                @media (max-width: 600px) {
                    display: flex;
                    justify-content: flex-end;
                    font-size: 14px;
                }
            }

            .Login-inputDiv {
                margin-bottom: 18px;
                width: 397px;
                height: 54px;

                @media (max-width: 1366px) {
                    width: 100%;
                }
                @media (max-width: 768px) {
                    margin-bottom: 10px;
                }
            }

            .loginInput .MuiFilledInput-underline:before,
            .loginInput .MuiFilledInput-underline::after {
                border-bottom: none !important;
            }
            .loginInput .MuiInputLabel-filled.MuiInputLabel-shrink {
                z-index: 1 !important;
                color: #6b6c6f;

                @media (max-width: 768px) {
                    font-size: 12px !important;
                }
            }
            .loginInput .MuiFormLabel-root.Mui-focused {
                color: #0066f5;
            }

            .MuiFilledInput-adornedEnd {
                @media (max-width: 768px) {
                    padding-right: 0;
                }
            }

            .MuiFormControl-root {
                width: 100%;
                background-color: #fff;
                border-radius: 5px;
            }

            .MuiFilledInput-root {
                width: 100%;
                background-color: #fff !important;
                border: 1px solid #6b6c6f;
                border-radius: 5px;
            }

            .MuiFilledInput-root.Mui-focused {
                width: 100%;
                background-color: #fff !important;
                border: 1px solid #006df5;
                border-radius: 5px;
            }

            .MuiFilledInput-root.Mui-error {
                width: 100%;
                background-color: #fff !important;
                border: 1px solid red;
                border-radius: 5px;
            }

            .signInButton {
                height: 50px !important;
                font-size: 16px !important;
                line-height: 22px !important;
                font-weight: bold !important;
                margin-top: 22px !important;
                border-radius: 4px;

                @media (max-width: 1440px) {
                    margin-top: 7px !important;
                }
                @media (max-width: 768px) {
                    // height: 40px !important;
                    font-size: 12px !important;
                }
            }

            .signUpDiv {
                font-size: 14px;
                line-height: 18px;
                text-align: center;
                margin-top: 20px;

                .signUpText {
                    color: #006dff;
                    font-weight: 700;
                    cursor: pointer;
                }

                @media (max-width: 768px) {
                    font-size: 12px;
                    line-height: 18px;
                    text-align: center;
                    margin-top: 10px;
                }
                @media (max-width: 600px) {
                    font-size: 14px;
                }
            }

            .googleButton {
                width: 100% !important;
                font-size: 16px !important;
                line-height: 22px !important;
                font-weight: bold !important;
                color: #333333 !important;
                text-align: center !important;
                margin-top: 10px;
                border: 1px solid #333333 !important;
                box-shadow: none !important;
                border-radius: 4px !important;
                height: 50px !important;

                span {
                    font-weight: bold !important;
                    width: 70% !important;
                    text-align: center !important;
                    font-size: 16px !important;

                    @media (max-width: 768px) {
                        font-size: 12px !important;
                    }
                }

                div {
                    @media (max-width: 768px) {
                        padding: 5px 10px !important;
                    }
                }

                @media (max-width: 768px) {
                    font-size: 12px !important;
                    height: 40px !important;
                    margin-top: 5px;
                }
            }

            .loginLineDiv {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                margin-top: 20px;
                .loginHrLine {
                    border-bottom: 1px solid #707070;
                    width: 43%;
                    display: flex;
                    flex-direction: column;
                    align-self: center;
                }

                @media (max-width: 1440px) {
                    margin-top: 7px !important;
                    height: 20px;
                }
                @media (max-width: 768px) {
                    margin-top: 2px !important;
                    font-size: 12px;
                }
            }

            @media (max-width: 1440px) {
                padding: 18px;
                margin-top: 15px;
            }

            @media (max-width: 1366px) {
                width: 360px;
            }

            @media (max-width: 1100px) {
                width: 350px;
                margin-top: 25px;
            }

            // @media (max-width: 768px) {
            //     width: 240px;
            //     padding: 10px;
            //     margin-top: 8px;
            //     margin-left: 30px;
            // }

            @media (max-width: 600px) {
                width: 100%;
                padding: 10px 0;
                margin-top: 15px;
                position: relative;
                border: none;
                box-shadow: none;
                background-color: unset;
            }

            .errorLogin {
                color: red;
                padding-top: 5px;
                text-align: left;
                margin-bottom: 20px;
            }
        }
    }

    .loginContentDoug {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        width: 100%;

        // @media (max-width: 2560px) {
        //     justify-content: space-around;
        // }

        // @media (max-width: 1920px) {
        //     justify-content: space-between;
        // }

        // @media (max-width: 1800px) {
        //     justify-content: space-between;
        // }

        // @media (max-width: 1600px) {
        //     justify-content: space-between;
        // }

        // @media (max-width: 1500px) {
        //     justify-content: space-between;
        // }
        // @media (max-width: 1100px) {
        //     flex-direction: column;
        // }
        // @media (max-width: 1280px) {
        //     justify-content: space-between;
        //     width: 100%;
        // }

        @media (max-width: 600px) {
            flex-direction: column;
            justify-content: unset;
        }
    }
}

.login-getStartedDivD {
    .login-form-main-div {
        border: 35px solid #100b28 !important;
        @media (max-width: 600px) {
            border: none !important;
        }
        button {
            border-radius: 0px !important;
        }
    }
    .Login-inputDiv {
        width: 100% !important;
    }

    .MuiFilledInput-root {
        border: 1px solid #100b28 !important;
        border-radius: 0px !important;
        color: #323744;
    }
    .MuiInputLabel-filled.MuiInputLabel-shrink.Mui-focused {
        color: #100b28 !important;
    }
    .MuiFilledInput-root.Mui-focused {
        border: 1px solid #100b28 !important;
    }
    .loginForgotPassword,
    .signUpText {
        color: #009cbd !important;
    }
    // .loginForgotPassword {
    //     font-size: 16px;
    //     font-family: 'EuclidCircularA-Bold';
    // }
    .loginLine {
        background-color: #009cbd !important;
    }
    .signUpText,
    .loginSignInText {
        font-family: 'EuclidCircularA-Regular';
    }
    .loginSignInText {
        font-size: 24px;
    }
    .loginWelcomeText {
        font-family: 'EuclidCircularA-Bold';
    }
    .signUpText,
    .signUpDiv {
        font-size: 16px;
    }
}

// .douglasEllimenSite{
//     .login-form-main-div {
//         padding: 22px;
//         width: 460px;
//         margin-top: 20px;
//         background: #FFFFFF 0% 0% no-repeat padding-box;
//         box-shadow: 0px 3px 6px #00000026;
//         border: 0.5px solid #E0E0E0;
//         border-radius: 4px;

//   }
//  }

.douglasEllimenSite {
    .login-getStartedDiv .loginContent .login-form-main-div {
        padding: 22px;
        width: 460px;
        margin-top: 20px;
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000026;
        border: 0.5px solid #e0e0e0;
        border-radius: 4px;
    }
}
