.UpdateAddressMain {
    margin: 30px 80px 0px 80px;

    .changeColorUpdateAddress {
        border: 1px solid #006dff !important;
        background-color: #f1f9fb !important;
    }

    .UpdateAddressheading {
        font-size: 24px;
        font-weight: 600;
        font-family: sans-serif;
        // @media (max-width: 2560px) {
        //     padding-left: 100px;
        // }
        // @media (max-width: 1920px) {
        //     padding-left: 210px;
        // }
        // @media (max-width: 1800px) {
        //     padding-left: 100px;
        // }
        // @media (max-width: 1700px) {
        //     padding-left: 70px;
        // }
        // @media (max-width: 1600px) {
        //     padding-left: unset;
        // }
    }

    .UpdateAddresspath {
        display: flex;
        // margin-top: -25px;
        font-size: 12px;
        // @media (max-width: 2560px) {
        //     padding-left: 100px;
        // }
        // @media (max-width: 1920px) {
        //     padding-left: 210px;
        // }
        // @media (max-width: 1800px) {
        //     padding-left: 100px;
        // }
        // @media (max-width: 1700px) {
        //     padding-left: 70px;
        // }
        // @media (max-width: 1600px) {
        //     padding-left: unset;
        // }
    }

    .UpdateAddressPara {
        color: #bebebe;
    }

    @media (max-width: 1366px) {
        margin: 30px 60px 0px 60px;
    }

    @media (max-width: 600px) {
        margin: 30px 10px 0px 10px;
    }
    .removeMarginTopAddress {
        padding-top: 0 !important;
    }
    .updateAddMailForwarding {
        padding-top: 34px;

        @media (max-width: 768px) {
            margin: 0;
        }

        @media (max-width: 600px) {
            margin: 0;
            padding-top: 20px;
        }

        @media (max-width: 375px) {
            margin: 0;
            padding-top: 20px;
        }

        .updateAddMailForwardingHeading {
            border: 0.5px solid #c6c6c6;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
            padding: 26px 27px 23px 27px;
            margin-top: 30px;
            display: flex;
            .updateAddMailForwardingText {
                color: #ffffff;
                font-size: 16px;
                padding-left: 12px;
                display: flex;
                align-items: center;
            }
            @media (max-width: 600px) {
                margin-top: unset;
            }
        }
        .updateAddMailForwardingBody {
            background-color: #fff;
            padding: 42px 39px;
            border: 0.5px solid #c6c6c6;
            border-top: 0px;
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;

            @media (max-width: 600px) {
                padding: 19px 11px;
            }

            .updateAddRadioWrapper,
            .updateAddRadioWrapperActive {
                border: 1px solid #bebebe;
                border-radius: 4px;
                padding: 10px 8px 24px 20px;
                cursor: pointer;
                @media (max-width: 2560px) {
                    width: 46%;
                }
                @media (max-width: 1920px) {
                    width: 46%;
                }

                @media (max-width: 1440px) {
                    width: 46%;
                }

                @media (max-width: 1024px) {
                    width: 45%;
                }

                @media (max-width: 768px) {
                    width: 95%;
                    margin-bottom: 16px;
                }
                @media (max-width: 600px) {
                    width: 91%;
                    padding: 10px 16px 25px;
                }
                @media (max-width: 375px) {
                    width: 91.5%;
                    padding: 8px 13px 26px;
                }
                @media (max-width: 320px) {
                    width: 90%;
                }
            }

            .updateAddRadioWrapper:hover,
            .updateAddRadioWrapperActive {
                border: 1px solid #0066f5;
                background-color: #f1f9fb;
            }

            .updateAddRadioDesc,
            .updateAddRadioSeeExceptions {
                color: #707070;
                font-size: 14px;
                padding-left: 30px;
                cursor: pointer;

                @media (max-width: 768px) {
                    padding-left: 40px;
                }
                @media (max-width: 600px) {
                    font-size: 12px;
                }
            }

            .updateAddRadioSeeExceptions {
                color: #0066f5;
                padding-top: 13px;
                @media (max-width: 600px) {
                    font-size: 14px;
                }
            }

            .MuiFormGroup-root.updateAddRadio {
                flex-direction: row !important;
                justify-content: space-between;
                @media (max-width: 768px) {
                    flex-direction: column !important;
                }
            }
            .MuiFormGroup-root.updateAddRadio .MuiRadio-colorSecondary.Mui-checked {
                color: #006dff !important;
            }
            .updateAddRadio .MuiFormControlLabel-root {
                @media (max-width: 768px) {
                    margin: 10px 0 0 0;
                }
            }
            .updateAddRadio1 .MuiTypography-body1 {
                color: #333;
                font-weight: 900;
                font-size: 14px;
            }

            .updateAddContactInfo {
                padding-top: 42px;

                @media (max-width: 600px) {
                    padding-top: 16px;
                }

                .updateAddContactInfoHeading {
                    color: #273e59;
                    font-size: 16px;
                    font-weight: 900;
                }

                .updateAddContactInfoContent {
                    display: flex;
                    justify-content: space-between;
                    padding-top: 24px;
                    flex-wrap: wrap;
                    // width: 98%;
                    width: 100%;

                    @media (max-width: 600px) {
                        padding-top: 16px;
                        width: 100%;
                    }

                    .updateAddContactInfoTextbox {
                        padding-bottom: 16px;

                        @media (max-width: 600px) {
                            width: 100%;
                            .MuiFormControl-root {
                                width: 100% !important;
                            }
                        }
                    }

                    .updateAddInput1 input,
                    .updateAddInputActive1 input {
                        width: 340px;
                        height: 11px;
                        background-color: #fff;
                        border: 1px solid #bebebe;
                        border-radius: 5px;
                        font-size: 14px;
                        color: #333333;
                        @media (max-width: 600px) {
                            width: 100%;
                        }
                    }
                    .updateAddInputActive1 .MuiFormControl-root {
                        width: 100% !important;
                    }
                    .updateAddInput1 .MuiFormLabel-root.Mui-focused {
                        color: #006dff !important;
                    }
                    .updateAddInput1Doug .MuiFormLabel-root.Mui-focused {
                        color: #009cbd !important;
                    }
                    .updateAddInput1 .MuiFilledInput-underline:before,
                    .updateAddInput1 .MuiFilledInput-underline::after,
                    .updateAddInputActive1 .MuiFilledInput-underline:before,
                    .updateAddInputActive1 .MuiFilledInput-underline::after {
                        border-bottom: none !important;
                    }
                    .updateAddInput1 input:focus {
                        background-color: #fff;
                        border: unset;
                        border: 1px solid #006dff !important;
                        border-radius: 5px;
                    }
                    .updateAddInputActive1 {
                        background-color: #fff;
                        border: unset;
                        border: 1px solid #009cbd;
                        border-radius: 5px;
                    }
                    .updateAddInput1Doug input:focus {
                        border: 1px solid #009cbd !important;
                    }
                    .updateAddInput1 .MuiInputLabel-filled.MuiInputLabel-shrink {
                        z-index: 1 !important;
                        color: #006dff !important;
                    }
                    .updateAddInput1Doug .MuiInputLabel-filled.MuiInputLabel-shrink {
                        z-index: 1 !important;
                        color: #009cbd !important;
                    }
                    .updateAddInputActive1 .MuiInputLabel-filled.MuiInputLabel-shrink {
                        z-index: 1 !important;
                        color: #009cbd !important;
                    }
                }
            }
        }
        .updateAddMailForwardingBodyAddColor {
            .updateAddRadioWrapper:hover,
            .updateAddRadioWrapperActive {
                border: 1px solid #009cbd !important;
                background-color: #f1f9fb;
            }

            .updateAddRadioSeeExceptions {
                color: #009cbd !important;
                padding-top: 13px;
                @media (max-width: 600px) {
                    font-size: 14px;
                }
            }

            .MuiFormGroup-root.updateAddRadio .MuiRadio-colorSecondary.Mui-checked {
                color: #009cbd !important;
            }
        }
        .updateAddDetails {
            .updateAddDetailsHeading {
                padding-top: 50px;
                padding-bottom: 24px;
                color: #273e59;
                font-size: 16px;
                font-weight: 900;
                @media (max-width: 600px) {
                    padding-top: 32px;
                    padding-bottom: 16px;
                }
            }
            .updateAddDetailsCardWrapper {
                display: flex;
                justify-content: space-between;

                @media (max-width: 820px) {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }
                .updateAddDetailsImgWrapper {
                    display: flex;
                    align-items: center;

                    @media (max-width: 768px) {
                        padding-top: 16px;
                        padding-bottom: 16px;
                        transform: rotate(90deg);
                    }
                }
                .updateAddDetailsCard {
                    border: 1px solid #006dff !important;
                    border-radius: 4px;
                    background-color: #f1f9fb;
                    padding: 19px 35px 29px;
                    width: 46%;

                    @media (max-width: 1024px) {
                        padding: 15px 14px;
                    }

                    @media (max-width: 820px) {
                        width: 90%;
                        margin: 10px 0px;
                    }

                    @media (max-width: 600px) {
                        padding: 15px 14px;
                    }

                    .updateAddStreet .MuiFilledInput-input {
                        background-color: #fff;
                        border: 1px solid #bebebe;
                        border-radius: 4px;
                        width: 316px;
                        height: 11px;
                        color: #333333;
                        font-size: 14px;
                        @media (max-width: 1024px) and (min-width: 1024px) {
                            width: 186px;
                        }
                        @media (max-width: 600px) {
                            // width: 186px;
                            width: 100%;
                        }
                        // @media (max-width: 320px) {
                        //     width: 150px;
                        // }
                    }
                    .updateAddStreet .MuiFilledInput-input:focus {
                        border: 1px solid #006dff !important;
                    }
                    .updateAddStreet .MuiFormLabel-root.Mui-focused {
                        color: #006dff !important;
                    }
                    .updateAddStreet .MuiInputLabel-filled.MuiInputLabel-shrink {
                        z-index: 1 !important;
                        color: #006dff !important;
                    }
                    .updateAddStreetDoug .MuiFilledInput-input:focus {
                        border: 1px solid #009cbd !important;
                    }
                    .updateAddStreetDoug .MuiFormLabel-root.Mui-focused {
                        color: #009cbd !important;
                    }
                    .updateAddStreetDoug .MuiInputLabel-filled.MuiInputLabel-shrink {
                        z-index: 1 !important;
                        color: #009cbd !important;
                    }
                    .updateAddDetailsSubHeadingWrapper {
                        display: flex;
                        .updateAddDetailsSubHeading {
                            padding-left: 10px;
                            display: flex;
                            align-items: center;
                            font-size: 16px;
                            color: #273e59;
                            font-weight: 900;
                        }
                    }

                    .updateAddRow {
                        display: flex;
                        justify-content: space-between;
                        padding-top: 29px;
                        @media (max-width: 1920px) {
                            width: 98%;
                        }
                        @media (max-width: 1600px) {
                            width: 94%;
                        }
                        @media (max-width: 1500px) {
                            width: 98%;
                        }
                        @media (max-width: 768px) {
                            width: 88%;
                        }
                        @media (max-width: 600px) {
                            padding-top: 23px;
                            width: unset;
                        }
                        .MuiOutlinedInput-root {
                            @media (max-width: 1920px) {
                                margin-right: 0px;
                            }
                        }
                        .updateAddAptWrapper {
                            .updateAddRadioError {
                                font-size: 12px;
                                color: red;
                            }
                            @media (max-width: 1024px) and (min-width: 1024px) {
                                padding-left: 13px;
                            }
                            @media (max-width: 600px) {
                                padding-left: 13px;
                            }
                            @media (max-width: 375px) {
                                padding-left: 6px;
                            }
                        }
                        .updateAddApt input {
                            width: 113px;
                            height: 11px;
                            background-color: #fff;
                            border: 1px solid #bebebe;
                            border-radius: 5px;
                            font-size: 14px;
                            color: #333333;
                        }
                        .updateAddApt .MuiInputLabel-filled.MuiInputLabel-shrink {
                            z-index: 10 !important;
                            color: #006dff !important;
                        }
                        .updateAddAptDoug .MuiInputLabel-filled.MuiInputLabel-shrink {
                            z-index: 10 !important;
                            color: #009cbd !important;
                        }
                        .updateAddinput1 .MuiInputBase-input {
                            border-radius: 5px;
                            text-align: left;
                            border: 1px solid #bebebe;
                            background-color: #fff;
                            padding: 27px 10px 10px;
                            width: 316px;
                            height: 11px;
                            font-size: 14px;
                            color: #333333;
                            @media (max-width: 320px) {
                                width: 265px;
                            }
                        }

                        .updateAddApt .MuiFilledInput-underline:before,
                        .updateAddApt .MuiFilledInput-underline::after {
                            border-bottom: none !important;
                        }
                        .updateAddApt input:focus {
                            background-color: #fff;
                            border: 1px solid #006dff !important;
                            border-radius: 4px;
                        }
                        .updateAddApt .MuiInputLabel-filled.MuiInputLabel-shrink {
                            z-index: 1 !important;
                        }
                        .updateAddApt .MuiInputLabel-filled.MuiFormLabel-root.Mui-focused {
                            color: #006dff !important;
                        }
                        .updateAddAptDoug input:focus {
                            background-color: #fff;
                            border: 1px solid #009cbd !important;
                            border-radius: 4px;
                        }
                        .updateAddAptDoug .MuiInputLabel-filled.MuiInputLabel-shrink {
                            z-index: 1 !important;
                        }
                        .updateAddAptDoug .MuiInputLabel-filled.MuiFormLabel-root.Mui-focused {
                            color: #009cbd !important;
                        }
                        .updateAddApt .MuiInputBase-input {
                            width: 113px;
                            height: 11px;
                            font-size: 14px;
                            color: #333333;
                            background-color: #fff;
                            border-radius: 5px;
                            text-align: left;
                            border: 1px solid #bebebe;
                            @media (min-width: 1500px) {
                                width: 133px;
                            }
                            @media (max-width: 1024px) and (min-width: 1024px) {
                                width: 107px;
                            }
                            @media (max-width: 600px) {
                                // width: 107px;
                                width: 100%;
                            }
                            // @media (max-width: 375px) {
                            //     width: 78px;
                            // }
                            // @media (max-width: 320px) {
                            //     width: 64px;
                            // }
                        }
                        .updateAddApt .MuiOutlinedInput-root {
                            margin-right: unset;
                        }
                    }
                    .updateAddAddress {
                        display: flex;
                        justify-content: flex-end;
                        padding-top: 19px;
                        color: #707070;
                        font-size: 14px;

                        @media (max-width: 1920px) {
                            width: 98%;
                        }
                        @media (max-width: 1600px) {
                            width: 94%;
                        }
                        @media (max-width: 1500px) {
                            width: 98%;
                        }
                        @media (max-width: 768px) {
                            width: 88%;
                        }
                        @media (max-width: 600px) {
                            width: unset;
                        }
                    }
                }
                .updateAddDetailsCardDougRem {
                    border: 1px solid #009cbd !important;
                }
            }
        }

        .updateAddFutureAddress {
            padding-top: 51px;
            .updateAddRadioFutureAddDesc {
                color: #707070;
                font-size: 14px;
                padding-left: 30px;
                @media (max-width: 768px) {
                    padding-left: 40px;
                }
            }

            .updateAddDateWrapper {
                display: flex;

                .updateAddEndDate {
                    margin-left: 52px;
                    @media (max-width: 600px) {
                        margin-left: 8px;
                    }
                }

                .updateAddStartDate {
                    color: #273e59;
                    font-weight: 900;
                    font-size: 16px;
                    padding-top: 36px;
                    @media (max-width: 600px) {
                        padding-top: 16px;
                    }
                }

                .updateAddDate1 .MuiFilledInput-root {
                    width: 160px;
                    background-color: #fff;
                    border: 1px solid #bebebe;
                    border-radius: 5px;
                    height: 50px;
                    padding: 3px 0 0;
                    font-size: 14px;
                    color: #333333;
                    @media (max-width: 320px) {
                        width: 140px;
                        font-size: 13px;
                    }
                }
                .updateAddDate1 .MuiFilledInput-root:hover {
                    border: 1px solid #006dff !important;
                }
                .updateAddDate1 .MuiInputLabel-filled.MuiInputLabel-shrink {
                    color: #006dff !important;
                    z-index: 9 !important;
                }
                .updateAddDate1Doug .MuiFilledInput-root:hover {
                    border: 1px solid #009cbd !important;
                }
                .updateAddDate1Doug .MuiInputLabel-filled.MuiInputLabel-shrink {
                    color: #009cbd !important;
                    z-index: 9 !important;
                }
            }
        }
        .updateAddBtnWrapper {
            display: flex;
            justify-content: center;
            padding-top: 49px;
        }
    }

    .updateAddFiledReq {
        @media (max-width: 2560px) {
            padding-left: 100px;
            padding-right: 100px;
        }
        @media (max-width: 1920px) {
            padding-left: 210px;
            padding-right: 210px;
        }

        @media (max-width: 1800px) {
            padding-left: 100px;
            padding-right: 100px;
        }
        @media (max-width: 1700px) {
            padding-left: 70px;
            padding-right: 70px;
        }
        @media (max-width: 1600px) {
            padding-left: unset;
            padding-right: unset;
        }
        .updateAddFiledReqFirstRow {
            display: flex;
            justify-content: space-between;
            .updateAddFiledReqText {
                color: #000000;
                font-size: 18px;
                font-weight: 900;
                padding-top: 10px;
            }
            .updateAddFiledReqAddRequest {
                color: #006dff;
                font-size: 14px;
                cursor: pointer;
                margin-top: 12px;
            }
        }
        .updateAddFiledReqContainer {
            margin-bottom: 16px;
            .updateAddFiledReqCard {
                border: 1px solid #7eb3ff;
                padding: 17px 19px;
                border-radius: 4px;
                background-color: #f1f9fb;
                margin-top: 15px;

                .updateAddFiledReqRow {
                    display: flex;

                    .updateAddFiledReqName {
                        color: #333333;
                        font-size: 20px;
                        font-weight: 900;
                    }

                    .updateAddFiledReqStatus {
                        border: 1px solid #c7ab5f;
                        background-color: #fff0c8;
                        border-radius: 12px;
                        margin-left: 15px;
                        padding: 4px 14px 4px 13px;
                        color: #937b39;
                        font-size: 12px;
                    }
                    .updateAddFiledReqEdit {
                        cursor: pointer;
                    }
                    .updateAddFiledReqDel {
                        margin-left: 16px;
                        cursor: pointer;
                    }
                }
                .updateAddFiledReqSecondRow {
                    padding-top: 22px;
                    display: flex;
                    justify-content: space-between;

                    @media (max-width: 600px) {
                        flex-direction: column;
                        align-items: center;
                        padding-top: unset;
                    }

                    .updateAddFiledReqContent {
                        width: 33%;
                        @media (max-width: 1024px) {
                            width: 30%;
                        }
                        @media (max-width: 600px) {
                            width: 100%;
                        }
                        .updateAddFiledReqHeading {
                            color: #707070;
                            font-size: 14px;
                            @media (max-width: 600px) {
                                padding-top: 16px;
                            }
                        }
                        .updateAddFiledReqValue {
                            color: #333333;
                            font-size: 16px;
                            padding-top: 5px;
                            font-weight: 800;
                        }
                    }
                }
            }
        }
        .updateAddNewServices {
            .updateAddOurNewServices {
                color: #000000;
                font-size: 18px;
                font-weight: 900;
                padding-top: 22px;
                padding-bottom: 22px;
            }
            .updateAddServicesCard {
                border: 1px solid #dadce0;
                border-radius: 4px;
                background-color: #fff;
                padding: 18px;
                width: 46%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin-bottom: 16px;
                @media (max-width: 1024px) {
                    width: 45%;
                }
                @media (max-width: 768px) {
                    width: 100%;
                }
                .updateAddServicesCardHeading {
                    color: #333333;
                    font-size: 22px;
                    font-weight: 900;
                    @media (max-width: 600px) {
                        font-size: 18px;
                    }
                }

                .updateAddServicesCardDesc {
                    color: #333333;
                    font-size: 14px;
                    padding-top: 6px;
                    padding-bottom: 12px;
                    @media (max-width: 600px) {
                        font-size: 10px;
                    }
                }

                .updateAddServicesCardImg {
                    .updateAddsvgIcon {
                        width: 120px;
                    }
                    .updateAddsvgIcon svg {
                        width: 100%;
                    }
                    display: flex;
                    flex-direction: column;
                    align-self: center;
                    .updateAddServicesImg {
                        @media (max-width: 1920px) {
                            width: 150px;
                            height: 70px;
                        }
                        @media (max-width: 375px) {
                            width: 100px;
                            height: 70px;
                        }
                    }
                }
            }

            .updateAddServicesCard:hover {
                box-shadow: 0 1px 10px #161d251a !important;
            }
            .updateAddOurNewServicesRow {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
            }
        }
    }
}
.inputFieldsDouglasEllimanUpdateAddress {
    .MuiInputLabel-filled.MuiInputLabel-shrink {
        z-index: 1 !important;
        color: #100b28;
    }
    input {
        background-color: #fff;
        border: 1px solid #bebebe !important;
        border-radius: 0px !important;
        color: #323744;
    }

    .MuiFilledInput-root {
        input {
            border: 0 !important;
        }
        height: unset !important;
        background-color: #fff;
        border: 1px solid #bebebe !important;
        border-radius: 0px !important;
        color: #323744;
    }
}
.UpdateAddressMainRemoveDouglas {
    // margin-top: 0 !important;
    @media (max-width: 600px) {
        margin: 120px 10px 0px 10px !important;
    }
    @media (max-width: 320px) {
        margin: 140px 0px 0px 0px !important;
    }
}

.MuiFilledInput-underline:after {
    border-bottom: none !important;
}
.MuiFilledInput-underline:before {
    border-bottom: none !important;
}
