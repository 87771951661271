.DrivingLicenceMain {
    margin: 30px 80px 0px 80px;

    .Para-dmv {
        color: #bebebe;
    }

    .path-dmv {
        display: flex;
        // margin-top: -25px;
        font-size: 12px;
    }

    .heading-dmv {
        font-size: 24px;
        font-weight: 600;
        font-family: sans-serif;
    }
    .dmvtab-div {
        display: flex;
        flex-direction: row;
        padding-top: 10px;
        margin-top: 20px;
        border-bottom: 1px solid #d2d2d2;
    }
    .dmvtab-active {
        font-weight: 700;
        color: #0066f5;
        border-bottom: 3px solid #0066f5;
        cursor: pointer;
        font-size: 14px;
        padding-bottom: 10px;
    }
    .changeColordmv {
        color: #009cbd !important;
        border-bottom: 3px solid #009cbd !important;
    }
    .dmvtab-text {
        cursor: pointer;
        font-size: 14px;
        color: #6b6c6f;
    }
    .dmvtab-div > div {
        margin-right: 20px;
    }

    .dmv-content-div {
        margin: 30px 80px 0 80px;
        display: flex;
    }

    .dmvMainRemoveDouglas {
        .MuiCheckbox-colorSecondary.Mui-checked,
        .downloadForm {
            color: #009cbd !important;
        }
        @media (max-width: 600px) {
            margin: 220px 0px 0px 0px !important;
        }
        @media (max-width: 320px) {
            margin: 240px 0px 0px 0px !important;
        }
    }
    .dmv-content-left {
        width: 66%;
        .dmv-left-1 {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .calender-dmv {
                width: 112px;
                height: 125px;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
            }
            .detail {
                width: calc(100% - 150px);
                .title-dmv {
                    font-size: 24px;
                    font-weight: 700;
                    color: #333;
                    margin-bottom: 10px;
                }
                .subText-dmv {
                    font-size: 16px;
                    line-height: 26px;
                    color: #707070;
                }
            }
            @media (max-width: 768px) {
                .detail {
                    .title-dmv {
                        font-size: 18px;
                    }
                    .subText-dmv {
                        font-size: 12px;
                        line-height: 20px;
                    }
                }
            }
        }
        .dmv-left-2 {
            border: #c6c6c6 1px solid;
            margin: 20px 0;
            padding: 20px;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .title-dmv1 {
                font-size: 20px;
                font-weight: 700;
                color: #333;
                margin-bottom: 10px;
            }
            .subText-dmv1 {
                font-size: 14px;
                color: #333333;
            }
            .subText-dmv1-red {
                color: #f94d23;
                font-size: 14px;
                margin-top: 25px;
            }
            .downloadForm {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 25px;
                color: #009cbd !important;
                font-weight: 700;
            }
            @media (max-width: 768px) {
                flex-direction: column;
                justify-content: space-around;
                align-items: flex-start;
                .title-dmv1 {
                    font-size: 18px;
                }
                .subText-dmv1 {
                    margin-bottom: 20px;
                    font-size: 12px;
                }
            }
        }
        .dmv-left-22 {
            border: #c6c6c6 1px solid;
            margin: 20px 0;
            padding: 20px;
            border-radius: 5px;

            .dmv-left-221 {
                display: flex;
                align-items: center;
                justify-content: space-between;
                .title-dmv1 {
                    font-size: 20px;
                    font-weight: 700;
                    color: #333;
                    margin-bottom: 10px;
                }
                .subText-dmv1 {
                    font-size: 14px;
                    color: #333333;
                }
                .addressText {
                    color: #333333;
                    font-size: 14px;
                    font-weight: 700;
                }
            }
            .dmv-left-222 {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 25px;
                .subText-dmv1-red {
                    color: #f94d23;
                    font-size: 14px;
                }
                .downloadForm {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 210px;
                    color: #006dff !important;
                    font-weight: 700;
                }
                .downloadFormDoug {
                    color: #009cbd !important;
                }
            }
            @media (max-width: 768px) {
                flex-direction: column;
                justify-content: space-around;
                align-items: flex-start;
                .dmv-left-221 {
                    flex-direction: column;
                    align-items: flex-start;
                    .title-dmv1 {
                        font-size: 18px;
                    }
                    .subText-dmv1 {
                        margin-bottom: 20px;
                        font-size: 12px;
                    }
                }
                .dmv-left-222 {
                    .downloadForm {
                        width: 170px;
                    }
                }
            }
        }
        .dmv-left-3 {
            border: 1px solid #c6c6c6;
            border-radius: 5px;
            .dataAvailable {
                text-align: center;
                font-size: 22px;
                color: #333;
                font-weight: 700;
            }
            .dmv-left-vehicle-head {
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 70px;
                color: #ffffff;
                border-radius: 5px 5px 0 0;
                padding: 0 30px;

                .dmv-left-3-head1 {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    font-size: 16px;
                }
                .dmv-left-3-head2 {
                    font-size: 14px;
                }
            }
            .dmv-left-3-head {
                height: 70px;
                color: #ffffff;
                border-radius: 5px 5px 0 0;
                padding: 0 30px;
                font-size: 16px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
            }
            .dmv-left-3-body {
                padding: 25px 20px;
                .dmv-left-3-body1 {
                    display: flex;
                    // justify-content: space-between;
                    flex-direction: column;
                    margin-bottom: 15px;
                    .body-class {
                        font-size: 20px;
                        color: #707070;
                        @media (max-width: 425px) {
                            font-size: 14px;
                        }
                    }
                    .body-amount {
                        font-size: 25px;
                        color: #333333;
                        font-weight: 800;
                        overflow: hidden;
                        @media (max-width: 425px) {
                            font-size: 14px;
                        }
                    }
                }
            }
        }
        .dmv-left-4 {
            margin-top: 20px;
            border-radius: 5px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 15px 0 20px;
            color: #fff;
            font-size: 16px;
            .dmv-left-41 {
                margin-right: 30px;
            }
            .dmv-left-4-btns {
                display: flex;
                .dmv-left-4-btns1 {
                    margin-right: 15px;
                }
            }
            @media (max-width: 1024px) {
                .dmv-left-4-btns {
                    flex-direction: column;
                    .dmv-left-4-btns1 {
                        margin-right: 0px;
                    }
                }
            }
            @media (max-width: 768px) {
                .dmv-left-41 {
                    margin-right: 0px;
                    padding: 10px 0;
                }
            }
        }
        @media (max-width: 1280px) {
            width: 62%;
        }
        @media (max-width: 768px) {
            width: calc(100% - 20px);
        }
    }
    .dmv-content-right {
        width: 450px;
        // position: fixed;
        right: calc(100% - 78%);
        // right: 400px;
        // height: 400px;
        height: auto;
        // border: 1px solid #c6c6c6;
        // background: #f0f3f7;
        // border-radius: 5px;
        // padding: 25px 20px;
        // display: flex;
        // flex-direction: column;
        // justify-content: space-between;

        @media (max-width: 1920px) {
            right: 160px;
            margin-top: 3%;
            margin-left: 40px;
        }
        @media (max-width: 1680px) {
            width: 400px;
        }
        @media (max-width: 1600px) {
            right: 160px;
            width: 360px;
        }
        @media (max-width: 1440px) {
            right: 160px;
            width: 340px;
        }
        @media (max-width: 1367px) {
            right: 120px;
        }
        @media (max-width: 1280px) {
            right: 120px;
            // width: 320px;
        }
        @media (max-width: 1024px) {
            right: 120px;
            width: 270px;
        }
        @media (max-width: 960px) {
            right: 50px;
            width: 270px;
        }
        @media (max-width: 768px) {
            display: none;
        }
    }

    .checklistarea-dmv {
        border: 1px solid #c6c6c6;
        background: #f0f3f7;
        border-radius: 5px;
        padding: 25px 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: inherit;
        .dmv-right-1 {
            .dmv-right-11 {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                font-weight: 700;
                font-size: 16px;
            }
            .dmv-right-12 {
                margin-top: 20px;
                height: 300px;
                overflow: auto;
                .MuiFormControlLabel-root {
                    display: unset;
                    margin-right: 0px;
                    margin-left: 0;
                }
                .MuiFormControlLabel-root .MuiTypography-body1 {
                    font-size: 14px !important;
                    color: #333333;
                    margin-bottom: 10px;
                }
                .MuiIconButton-root {
                    padding: 10px 5px 10px 0 !important;
                }
                .MuiCheckbox-colorSecondary.Mui-checked {
                    color: #006dff !important;
                }
            }
            .dmv-right-12Doug {
                .MuiCheckbox-colorSecondary.Mui-checked {
                    color: #009cbd !important;
                }
            }
        }
        .dmv-right-2 {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .dmv-right-2-img {
                display: flex;
                .right-img {
                    background-color: #e2e7ed;
                    border-radius: 50%;
                    padding: 12px;
                }
            }
        }
    }

    .dmv-left-11-checklist {
        display: none;
        @media (max-width: 768px) {
            display: block;
            margin-top: 20px;
        }
    }

    @media (max-width: 1367px) {
        margin: 30px 60px 0px 60px;

        .dmv-content-div {
            margin: 30px 60px 0 60px;
        }
    }
    // @media (max-width: 1280px) {
    //     margin: 30px 40px 0px 40px;

    //     .dmv-content-div {
    //         margin: 30px 40px 0 40px;
    //     }
    // }
    // @media (max-width: 1024px) {
    //     margin: 30px 20px 0px 20px;

    //     .dmv-content-div {
    //         margin: 30px 20px 0 20px;
    //     }
    // }
    @media (max-width: 768px) {
        margin: 30px 60px 0px 60px;
        .dmv-content-div {
            margin: 30px 10px 0 10px;
            width: 100%;
            display: flex;
            flex-direction: column;
        }
    }
    @media (max-width: 600px) {
        margin: 30px 10px 0px 10px;
        .dmv-content-div {
            margin: 30px 10px 0 10px;
            width: 100%;
            display: flex;
            flex-direction: column;
            margin-top: 135px;
        }
    }
}

// @media (max-width: 600px) {
//     .TemplateUpperComponent .template-main-div .borderDiv1 {
//         position: absolute;
//         top: unset !important;
//         margin: 0px 30px;
//     }
// }
