.thankyou-card {
    width: 50%;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    box-shadow: 0px 3px 10px #00000029;
}

.thankyou-card-header {
    background-color: #273e59;
    padding: 30px 20px;
}

.thankyou-card-heading,
.thankyou-sub-heading {
    font-size: 24px;
    font-family: 'Roboto';
    font-weight: bold;
    color: #ffffff;
    text-align: center;
}

.thankyou-sub-heading {
    font-size: 16px;
    font-weight: normal;
}

.thankyou-card-body {
    background-color: #fff;
    padding: 22px 20px;
}

.btn-wrapper {
    padding-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.thankyou-card-call-details {
    color: #006dff;
    font-size: 16px;
    font-family: 'Roboto';
    padding-left: 8px;
}

.thankyou-calendar-wrapper {
    display: flex;
    flex-direction: row;
    padding-top: 24px;
}

.thankyou-call-schedule-details-text {
    color: #333333;
    font-size: 22px;
    font-family: 'Roboto';
}

.thankyou-call-details-card {
    border: 1px solid #e2e2e2;
    border-radius: 4px;
    padding: 18px 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
