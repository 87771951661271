.selectedVendorCard {
    padding: 20px;
    margin: 10px 0;
    border-radius: 4px;

    .vendorNameDeleteButton {
        display: flex;
        justify-content: space-between;
        width: 77%;
    }

    .vendorImage {
        width: 8%;
        color: #6b6c6f;
        font-weight: bold;
        font-size: 35px;
        line-height: 18px;
        // padding: 10px 20px;
        border-radius: 4px;
        // background: #FFF0C8;
    }

    .vendorName {
        font-weight: bold;
        font-size: 22px;
        line-height: 30px;
        color: #333333;

        @media (max-width: 1024px) {
            font-size: 18px;
        }
    }

    .vendorNamefromOrderConfirm {
        font-size: 16px;
        line-height: 22px;
    }

    .vendorTypefromOrderConfirm {
        color: #989898;
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 10px;
    }
}
