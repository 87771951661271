.main-div-signUp2 {
    display: flex;

    .errorSignup {
        color: red;
        padding-top: 5px;
        margin-left: 10px;
        text-align: left;
        margin-bottom: 20px;
    }
}

.div1-signUp2 {
    width: 40%;
    height: 100vh;
    background: url('../Assets/images/SignUp/SignUpLeftImage.svg') no-repeat;
    background-size: cover;

    @media (max-width: 1024px) {
        display: none;
    }
}
.div1-signUp2D {
    width: 40%;
    height: 100vh;
    background: url('../Assets/images/DouglasImages/signUpSecond.png') no-repeat;
    background-size: cover;
    position: relative;
    .cardUpper {
        position: absolute;
        bottom: 0;
        padding: 0 30px 60px 30px;
        .template-main-div1 {
            background-color: #ffffff;
            display: flex;
            flex-direction: column;
            padding: 20px;
            // align-items: center;
            justify-content: center;
            align-items: center;
            .HeaderText {
                color: #100b28;
                font-size: 32px;
                margin: 0px 0 16px 0;
                text-transform: capitalize;
                font-family: 'EuclidCircularA-Light';
                @media (max-width: 600px) {
                    font-size: 20px;
                }
            }
            .highlightedText {
                font-family: 'EuclidCircularA-bold';
                @media (max-width: 600px) {
                    font-size: 24px;
                }
            }
            @media (max-width: 600px) {
                padding: 20px 10px;
            }
        }
        .borderDiv {
            padding: 35px;

            @media (max-width: 1024px) {
                margin: 0px 50px;
                min-width: unset !important;
            }
            @media (max-width: 600px) {
                position: absolute !important;
                top: 60% !important;
                margin: 0px 30px !important;
                left: 0;
                right: 0;
            }
        }
    }
    @media (max-width: 1024px) {
        display: none;
    }
}

.div2-signUp2 {
    width: 60%;
    padding: 100px 0 100px 50px;
    .checkboxContentFlexDiv {
        display: flex;
        justify-content: space-between;
        width: 70%;
        margin-top: 20px;
        margin-bottom: 50px;

        .checkboxContentDiv {
            display: flex;
            align-items: center;
            // border: 1px solid #bebebe;
            border-radius: 4px;
            padding: 15px 10px;
            width: 49%;

            .checkboxLabel {
                font-size: 18px;
                line-height: 24px;
                color: #333333;

                @media (max-width: 1500px) {
                    font-size: 14px;
                }
                @media (max-width: 600px) {
                    font-size: 10px;
                }
            }
            @media (max-width: 1440px) {
                padding: 10px;
            }
            @media (max-width: 1024px) {
                padding: 8px;
            }
            @media (max-width: 768px) {
                padding: 0 10px;
            }

            @media (max-width: 600px) {
                margin: 10px;
                width: 75%;
            }
        }
        @media (max-width: 1200px) {
            width: 80%;
            margin-bottom: 20px;
        }
        @media (max-width: 1024px) {
            width: 95%;
        }
        @media (max-width: 600px) {
            width: 100%;
            flex-wrap: wrap;
            justify-content: center;
        }
    }

    .movedYearAndMonthFlexDiv {
        width: 100%;
        display: flex;
        justify-content: space-between;

        .selectDiv {
            width: 48%;
        }

        .stateTextAddressInsurance {
            transform: translate(-9px, 27px) scale(0.75);
        }

        .css-2b097c-container {
            width: 100%;
        }

        .react-select .select-state__control {
            min-height: 55px;
        }
        .react-select .css-yk16xz-control {
            border-color: #6b6c6f;
        }

        @media (max-width: 1024px) {
            width: 210%;
        }
        @media (max-width: 600px) {
            width: 100%;
        }
    }
    @media (max-width: 1024px) {
        width: 100%;
        padding: 100px 60px;
    }

    @media (max-width: 600px) {
        width: 100%;
        padding: 60px 10px;
        text-align: center;
    }
}

.heading-signUp2 {
    font-size: 30px;
    padding: 20px 0 15px 0;

    @media (max-width: 600px) {
        font-size: 17px;
        line-height: 20px;
        padding: 15px 60px 15px 60px;
    }
}
.heading-signUp2-awesome {
    font-weight: bold;
}

.sub-heading-signUp2 {
    font-size: 16px;
    padding-bottom: 20px;
    color: #6b6c6f;

    @media (max-width: 600px) {
        font-size: 12px;
        line-height: 18px;
        padding: 0px 40px 20px;
    }
}

.firstLineInputs-signUp2 {
    display: flex;
    padding-bottom: 30px;

    @media (max-width: 1024px) {
        justify-content: space-between;
    }
    @media (max-width: 600px) {
        flex-wrap: wrap;
        padding-bottom: 0;
    }
}

.move-signUp2 {
    font-weight: bold;
    display: flex;
    align-items: center;
    padding-bottom: 15px;

    @media (max-width: 600px) {
        font-size: 12px;
        line-height: 17px;
    }
}

.image-signUp {
    padding-right: 5px;
}
.arrow-signUp2 {
    cursor: pointer;
}

.inputs-div-signUp {
    width: 95%;

    @media (max-width: 1024px) {
        width: 100%;
    }
}
.inputs-div-signUpD {
    .MuiFilledInput-root,
    .MuiFilledInput-root.Mui-focused,
    .date-picker-div {
        border: 1px solid #100b28 !important;
        border-radius: 0px !important;
        color: #323744;
    }
    .MuiInputLabel-filled.MuiInputLabel-shrink.Mui-focused {
        color: #100b28 !important;
    }
}

.center-arrow-signUp {
    width: 14%;
    text-align: center;
    margin: auto 0;
}

.input-div-1-signUp {
    width: 40%;

    @media (max-width: 600px) {
        width: 100%;
    }
}
.input-div-2-signUp {
    width: 40%;

    @media (max-width: 600px) {
        width: 100%;
    }
}

.input1-signUp2 .MuiFilledInput-root,
.input2-signUp2 .MuiFilledInput-root {
    width: 100%;
    border: 1px solid #6b6c6f;
    border-radius: 5px;
    background: none !important;
    transition: none;
}

.input-div-1-signUp .MuiFormControl-root,
.input-div-2-signUp .MuiFormControl-root,
.input-div-3-signUp .MuiFormControl-root {
    width: 100% !important;
    transition: none;
}

.MuiFilledInput-root.Mui-focused {
    border-color: #006df5 !important;
}

.douglasInput .MuiFilledInput-root.Mui-focused {
    border-color: #009cbd !important;
}

.MuiInputLabel-filled.MuiInputLabel-shrink.Mui-focused {
    color: #006df5 !important;
}

.douglasInput .MuiInputLabel-filled.MuiInputLabel-shrink.Mui-focused {
    color: #009cbd !important;
}

.secondLineInput-signUp2 {
    width: 30%;
    padding-bottom: 40px;
    @media (max-width: 1400px) {
        width: 37%;
    }

    @media (max-width: 768px) {
        width: 36%;
    }
    @media (max-width: 600px) {
        width: 100%;
    }
}

.date-signUp2 .MuiInput-underline:before {
    border: none !important;
}
.date-signUp2 .MuiInput-underline:after {
    border: none !important;
}

.date-picker-div {
    width: 100% !important;
    background: white;
    border: 1px solid #6b6c6f;
    border-radius: 5px;
    padding: 0px 10px;

    .difference-div {
        margin-left: auto;
        width: fit-content;
        margin-top: -30px;
        color: #6b6c6f;
        opacity: 0.8;
        font-size: 12px;
        margin-bottom: 20px;
        margin-right: 44px;
    }

    .MuiFormHelperText-root {
        max-width: 50%;
    }

    @media (max-width: 600px) {
        width: 94% !important;

        .MuiFormHelperText-root {
            max-width: 100%;
        }
    }
}

.termsAndConditionDiv {
    margin-bottom: 20px;
}
.termsAndConditionText {
    color: #006df5 !important;
}
.termsAndConditionTextDouglas {
    color: #009cbd !important;
}

.buttonDiv-signUp2 {
    width: 27%;

    @media (max-width: 1024px) {
        width: 40%;
    }
    @media (max-width: 768px) {
        width: 40%;
    }

    @media (max-width: 600px) {
        width: 100%;
        margin-top: 20px;
    }
}

.proceedToDashBoard {
    width: 99% !important;
    height: 55px !important;
}

.forwardArrow-signUp {
    margin-top: 25%;

    @media (max-width: 600px) {
        display: none;
    }
}

.input-div-1-signUp .MuiFilledInput-root:focus,
.input-div-2-signUp .MuiFilledInput-root:focus,
.input-div-3-signUp .MuiFilledInput-root:focus {
    background: none !important;
    border: 1px solid #006dff !important;
    border-radius: 5px;
}
.input-div-1-signUpD .MuiFilledInput-root:focus,
.input-div-2-signUpD .MuiFilledInput-root:focus,
.input-div-3-signUpD .MuiFilledInput-root:focus {
    background: none !important;
    border: 1px solid #009cbd !important;
    border-radius: 5px;
}

.input-div-1-signUp .MuiFilledInput-root.Mui-error,
.input-div-2-signUp .MuiFilledInput-root.Mui-error,
.input-div-3-signUp .MuiFilledInput-root.Mui-error {
    background: none !important;
    border: 1px solid red;
    border-radius: 5px;
}

.input-div-1-signUp .autocomplete-dropdown-container {
    width: 19% !important;

    @media (max-width: 1800px) {
        width: 22% !important;
    }
    @media (max-width: 600px) {
        width: 95% !important;
    }
    @media (max-width: 400px) {
        width: 94% !important;
        margin-left: 1px;
    }
}

.input-div-2-signUp .autocomplete-dropdown-container {
    width: 19% !important;

    @media (max-width: 1800px) {
        width: 22% !important;
    }

    @media (max-width: 600px) {
        width: 95% !important;
    }
    @media (max-width: 400px) {
        width: 94% !important;
        margin-left: 1px;
    }
}

.suggestion-item,
.suggestion-item--active {
    padding: 10px;
    color: #333333;
}

.checkboxContentDiv .MuiCheckbox-colorSecondary.Mui-checked {
    color: #006dff !important;
}
.checkboxContentDivDouglas .MuiCheckbox-colorSecondary.Mui-checked {
    color: #009cbd !important;
}
.termsAndConditionDiv .MuiCheckbox-colorSecondary.Mui-checked {
    color: #006dff !important;
}
.termsAndConditionDivDouglas .MuiCheckbox-colorSecondary.Mui-checked {
    color: #009cbd !important;
}

// .date-picker-div {
//     button.MuiButtonBase-root.MuiIconButton-root.MuiPickersDay-day.MuiPickersDay-current.MuiPickersDay-daySelected {
//         background-color: red !important;
//     }
// }

// .date-picker-DivDoug {
//     button.MuiButtonBase-root.MuiIconButton-root.MuiPickersDay-day.MuiPickersDay-current.MuiPickersDay-daySelected {
//         background-color: green !important;
//     }
// }

.MuiPickersDay-daySelected {
    background-color: #009cbd !important;
}
