.powerKioskMainDiv {
    box-shadow: 0px 3px 10px #00000029;
    // border: 1px solid #e2e2e2;
    border-radius: 4px;
    margin: 20px 20px 20px 0px;
    width: 24%;
    font-family: 'Roboto';
    padding: 10px 30px 20px 30px;

    .viewMorePowerKiosk {
        font-size: 12px;
        color: blue;
        text-decoration: underline;
        display: inline;
        margin-left: 10px;
        cursor: pointer;
    }
    // @media (max-width: 1420px) {
    //     width: 20%;
    // }
    @media (max-width: 1024px) {
        width: 35%;
    }
    @media (max-width: 768px) {
        width: 34%;
    }
    @media (max-width: 630px) {
        width: 100%;
        margin: 20px 0px 20px 0px;
    }

    .centerTextPower {
        display: flex;
        flex-direction: column;
        align-items: center;
        // align-items: center;
        .logoPower {
            width: 100%;
            height: auto;
            img {
                width: 100%;
                height: 100%;
            }
        }
        .unitKwh {
            font-size: 34px;
            font-family: 'Poppins';
            font-weight: normal;
        }
        .titlePower {
            font-size: 14px;
            font-family: 'Roboto';
            text-align: center;
            margin-top: 15px;
        }
        .kwhPowerValue {
            font-size: 48px;
            font-weight: bold;
            font-family: 'Poppins';
            padding: 22px 0px 0px 0px;
        }
        .paddingKwh {
            padding-bottom: 15px;
        }

        .kwhLable {
            font-size: 14px;
            font-family: 'Poppins';
            color: #6b6c6f;
        }
        .savingsPower {
            display: flex;
            align-items: center;

            border: 1px solid #e1ebf8;
            color: #006dff;
            font-size: 14px;
            font-family: 'Roboto';
            margin: 10px 0px 10px 0px;
            padding: 5px;
            background-color: #fafcff;
            border-radius: 4px;
        }
        .contractPower {
            font-size: 16px;
            font-weight: bold;
            font-family: 'Roboto';
            padding: 22px 0px 9px 0px;
            text-align: center;
        }
        .cancelationPower {
            font-size: 12px;
            font-family: 'Poppins';
            padding: 22px 0px 9px 0px;
            font-weight: bold;
        }
        .viewDetailsValue {
            font-size: 14px;
            font-family: 'Roboto';
            color: #006dff;
            cursor: pointer;
        }

        .lineDiv {
            width: 97%;
            border: 1px solid #d3d3d324;
        }

        .button-select-plan {
            // width: 80%;

            padding: 0px 0px 13px 0px;
            display: flex;
            align-items: center;
            @media (max-width: 630px) {
                width: 100%;
            }
            button {
                @media (max-width: 768px) {
                    width: 100% !important;
                }
            }
        }
    }
}
