.innercontainer_page {
    padding: 130px 15px 50px;
    min-height: 100vh;
}

.welcomvideo_wrapper {
    background-color: #FFFFFF;
    box-shadow: 0px 3px 24px #00000017;
    border: 1px solid #EAEDF3;
    width: 998px;
    margin: 0 auto;
    border-radius: 4px;
    overflow: hidden;
    max-width: 100%;
}
.welcomvideo_wrapper_small{
    width: 794px;
}
.welcomevideo_banner {
    background-color: #08192F;
    color: #fff;
    padding: 42px 30px;
    background-image: url(../../Assets/images/welcome/banner-img.png);
    background-position: right bottom;
    background-size: contain;
    background-repeat: no-repeat;
    p {
        font-size: 22px;
        margin-bottom: 5px;
    }
    h1 {
        font-size: 32px;
        margin: 0;
        font-weight: 700;
        line-height: 1.3;
    }
}
.welcomvideo_middlecontainer {
    padding: 25px;
    text-align: center;
    .title {
        color: #333;
        font-weight: 700;
        font-size: 18px;
        padding: 0;
        margin-bottom: 20px;
        line-height: 1.3;
    }
}

.videowrapper {
    display: inline-block;
    margin: 0 auto 20px;
    position: relative;
    img, video, iframe{
        max-width: 100%;
        max-height: 300px;
    }
    .playbtn {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
        cursor: pointer;
    }
}
@media (max-width: 991px){
    .welcomevideo_banner {
        p {
            font-size: 18px;
        }
        h1 {
            font-size: 28px;
        }
    }
}

@media (max-width: 767px){
    .innercontainer_page{
        padding: 90px 15px 40px;
    }
    .welcomevideo_banner {
        padding: 42px 15px;
        p {
            font-size: 16px;
        }
        h1 {
            font-size: 24px;
        }
    }
    .welcomvideo_middlecontainer {
        padding: 25px 15px;
        .title{
            margin-bottom: 15px;
            font-size: 16px;
        }
    }
}

@media (max-width: 575px){
    .welcomevideo_banner {
        padding: 35px 15px;
        position: relative;
        &:after{
            display: inline-block;
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: #00000073;
            z-index: 0;
        }
        p, h1{
            position: relative;
            z-index: 1;
        }
        p{
            font-size: 14px;
        }
        h1{
            font-size: 18px;
        }
    }
    .welcomvideo_middlecontainer{
        .videowrapper{
            .playbtn{
                max-height: 30px;
            }
        }
        .bluebtn {
            padding: 9px 20px;
            font-size: 12px;
        }
    }
    
}