.BetterExperienceMain {
    background: #fef0e2 0% 0% no-repeat padding-box;
    border: 1px solid #ff9d00;
    opacity: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 48px;
    .BetterExperienceTitle {
        flex: 1;
        margin: 6px;
        .BetterExperienceTitletext {
            text-align: left;
            font-size: 12px;
            font-weight: bold;
            font-family: 'Roboto';
            letter-spacing: 0px;
            color: #333333;
        }
        .BetterExperienceTitlesubtext {
            display: block;
            text-align: left;
            font-size: 9px;
            line-height: 16px;
            font-weight: normal;
            font-family: 'Roboto';
            letter-spacing: 0px;
            color: #333333;

            @media (max-width: 375px) {
                max-width: 295px;
            }
        }
    }

    svg {
        @media (max-width: 375px) {
            height: 17px !important;
            width: 17px !important;
        }
    }
    @media (max-width: 600px) {
        padding: 0 15px;
    }
}
