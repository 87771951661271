.cartePageMain{
    padding: 0 80px;

    @media (max-width: 1366px) {
        padding: 0px 60px;
    }

    @media (max-width: 600px) {
        padding: 0px 10px;
    }
    text-align: center;

    .carte-main-heading{
        font-size: 30px;
        font-weight: bold;
    }
    .carte-sub-heading{
        opacity: 0.8;
        padding: 10px 0;
    }
    .carte-cards-div{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .carte-card-div{
        width: 32%;
        padding:20px 0;
    }

    .carte-button-div{
        display: flex;
        padding: 20px 0;
        justify-content: center;
        align-items: center;
    }

    .carte-button{
        width: 120px !important;
    }
}