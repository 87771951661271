.autoshippingThankYouPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 30px;
    color: #333333;

    .thankYouText {
        font-size: 24px;
        line-height: 28px;
        font-weight: bold;
        margin-bottom: 10px;

        @media (max-width: 600px) {
            font-size: 22px;
            line-height: 31px;
        }
    }

    .thankYouSubText {
        font-size: 16px;
        line-height: 28px;

        @media (max-width: 600px) {
            font-size: 12px;
            line-height: 18px;
        }
    }

    .autoshippingThankYouPlanDiv {
        width: 50%;
        border: 1px solid #e2e2e2;
        border-radius: 4px;
        margin-top: 40px;

        @media (max-width: 1024px) {
            width: 60%;
        }

        @media (max-width: 600px) {
            width: 100%;
        }
    }

    .thankyouPlanHeading {
        font-size: 22px;
        line-height: 30px;
        padding: 10px 20px;
        border-bottom: 1px solid #e2e2e2;

        @media (max-width: 600px) {
            display: flex;
            justify-content: space-between;
            border-bottom: none;
        }
    }

    .planFlexDiv {
        padding: 20px 30px;
        display: flex;
        justify-content: space-between;

        .destHeading {
            font-size: 16px;
            line-height: 22px;
            font-weight: bold;
            margin-bottom: 10px;
        }

        .destText {
            font-size: 14px;
            line-height: 24px;
            margin: 5px 0;
        }

        @media (max-width: 600px) {
            flex-wrap: wrap;
            padding: 0 20px 20px;
        }
    }

    .dest {
        width: 60%;

        @media (max-width: 600px) {
            width: 100%;
        }
    }

    .vehicle {
        width: 40%;

        @media (max-width: 600px) {
            width: 100%;
            border-top: 1px solid #c6c6c6;
            margin-top: 10px;
            padding-top: 10px;
        }
    }
    .whatNextDiv {
        margin-top: 30px;
        width: 50%;

        .main-card-class {
            width: 100% !important;
        }

        @media (max-width: 1024px) {
            width: 60%;
        }

        @media (max-width: 600px) {
            width: 100%;
        }
    }

    .expandIcon {
        display: none;

        @media (max-width: 600px) {
            display: block;
        }
    }

    @media (max-width: 600px) {
        .btn-wrapper {
            margin-top: 30px;

            button {
                width: 100% !important;
                margin: 0 !important;
            }
        }
    }
}
.moving-main-pageDouglas {
    .tab-active {
        color: #009cbd !important;
        border-bottom: 2px solid #009cbd !important;
    }
}
