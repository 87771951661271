.card-line-title {
    text-align: center;
    /* font: normal normal bold 20px Roboto; */
    font-weight: bold;
    font-size: 20px;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
    margin-top: 7px;
    height: 50px;
    cursor: default;
}

.image-div-long-card {
    margin-top: 9px;
    width: 150px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
}

.checkbox-image-div {
    display: flex;
}

.image-image {
    width: 100%;
}

.image-div-other-card {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
}

.rating-div {
    display: flex;
    justify-content: center;
}

.ratingLongCard {
    margin: 0;
    padding: 20px 0;
    cursor: default;
    height: 20px;
}

.rating-button {
    display: flex;
    color: #009cbd !important;
    border: 1px solid #009cbd !important;
    margin: 0 10px;
    padding: 3px 10px 3px 3px;
    border-radius: 25px;
    font-size: 14px;
    background-color: #f1f9fb;
    cursor: default;
    height: 30px;
    align-items: center;
}
.rating-buttonDoug {
    color: #006dff !important;
    border: 1px solid #006dff !important;
    display: flex;
    align-items: center;
    margin: 0 10px;
    padding: 3px 10px 3px 3px;
    border-radius: 20px;
    font-size: 14px;
    background-color: #f1f9fb;
    cursor: default;
    height: 30px;
}
.rating-buttonDoug span {
    margin: 0;
}
.rating-button-new {
    height: 25px;
}

.review-count {
    cursor: default;
    text-align: center;
    text-decoration: underline;
    font: normal normal normal 16px/22px Roboto;
    letter-spacing: 0px;
    color: #6b6c6f;
    opacity: 1;
    height: 20px;
    margin-top: 14px;
}

.bottom-card-detail {
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
    font-size: 12px;
    font-weight: 300;
    width: 200px;
    margin-left: 5%;
    cursor: default;
}

.years-licencsed-div {
    display: flex;
    justify-content: space-around;
    border-top: 1px solid #e2e2e2;
    padding-top: 27px;
    padding-bottom: 27px;
    margin-top: 23px;
    border-bottom: 1px solid #e2e2e2;
}

.years-licencsed-div div {
    cursor: default;
}

.mover-card-main {
    display: flex;
    justify-content: space-between;
}

.best-moves {
    display: flex;
    flex-direction: column;
    margin-left: 95px;
}

.bestImage {
    padding-bottom: 15px;
    padding-right: 10px;
    margin-left: -2px;
}

.best-image-div {
    display: flex;
}

.best-title {
    font: normal normal bold 14px/24px Roboto;
    letter-spacing: 0px;
    color: #08192f;
    opacity: 1;
    cursor: default;
}

.movers-heading {
    /* font: normal normal bold 22px Roboto; */
    letter-spacing: 0px;
    font-size: 22px;
    font-weight: bold;
    color: #333333;
    opacity: 1;
    cursor: default;
}

.rating-section {
    margin: 0;
}

.checkbox-long-div {
    display: flex;
    margin: -10px;
}

.main-div-rate {
    display: flex;
    padding-top: 12px;
    padding: 14px 0 3px 0;
    cursor: default;
}

.verified-review {
    text-decoration: underline;
    font: normal normal normal 16px/22px Roboto;
    letter-spacing: 0px;
    color: #6b6c6f;
    opacity: 1;
    margin-top: 2%;
    cursor: default;
}

.image {
    padding: 20px;
}

.last-icon-div {
    display: flex;
    justify-content: space-between;
}

.last-icon-main-div {
    display: flex;
    flex-direction: column;
    margin-right: 15px;
    margin-top: 10px;
    width: 25%;
}

.last-icon-main-div .bottom-card-detail, .last-icon-main-div-mobile .bottom-card-detail-mobile {
    margin-bottom: 0px;
}
.last-icon-main-div-mobile .bottom-card-detail-mobile {
    margin-left: 12px;
}

.icon-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: default;
}

.inside-mover {
    display: flex;
    width: 70%;
}

.phoneAddress {
    cursor: default;
}

.recommended-card {
    background-color: #006dff;
    color: #fff;
    text-align: center;
    padding: 10px;
    /* margin-bottom: 30px;
      font-size: 20px;
      margin: -40px 20px 0px 0px;
    border: 2px solid rgb(0, 109, 255);
    border-radius: 3px; */

   
}
.info-icon-cover {
    margin-right: 9px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.recommended-cardDoug {
    background-color: #009cbd !important;
}

.years-licencsed-text {
    text-align: center;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
    margin: 2px 0px;
}

@media (max-width: 1440px) {
    .card-line-title {
        font: normal normal bold 17px Roboto;
    }
    /* .movers-heading {
        font: normal normal bold 17px Roboto;
      } */
}

@media (max-width: 1024px) {
    .years-licencsed-text {
        font-size: 12px;
    }
}

@media (max-width: 768px) {
    .bottom-card-detail {
        width: 300px;
    }
    .card-line-title {
        font-size: 16px;
        line-height: 22px;
        margin: 10px 0;
        height: auto;
    }

    .ratingLongCard {
        margin: 0;
        padding-top: 15px;
        padding-bottom: 0px;
    }

    .inside-mover {
        flex-direction: column;
        align-items: flex-start;
    }
    .mover-card-main {
        flex-direction: column;
    }

    .main-body-card {
        width: 80% !important;
        height: 384px !important;
    }

    .movers-heading {
        margin-top: 20px;
        width: 70%;
    }

    .last-icon-main-div {
        width: 80%;
        margin-top: 40px;
        margin-left: auto;
        margin-right: auto;
    }

    /* .phoneAddress{
        margin-top: 40px;
    } */

    .best-moves {
        margin-left: 35%;
    }
    .verified-review {
        width: 80%;
    }
    /* .rating-section,.verified-review{
     margin-left: 40%;
 } */

    .rating-div {
        /* margin-left: 35px; */
        /* margin-top: -100px; */
    }
    .test-rate {
        /* margin-top: -10px; */
    }
    .image-image {
        width: 100px !important;
        height: 40px !important;
    }
}

@media (max-width: 425px) {
    .checkbox-long-div {
        margin: 0px;
    }
    .card-line-title {
        font-size: 16px;
        line-height: 22px;
        margin: 10px 0;
        height: auto;
    }

    .ratingLongCard {
        margin: 0;
        padding-top: 15px;
        padding-bottom: 0px;
    }

    .inside-mover {
        flex-direction: column;
        align-items: flex-start;
    }
    .mover-card-main {
        flex-direction: column;
    }

    .main-body-card {
        width: 100% !important;
        height: 384px !important;
        padding: 15px 0px !important;
    }

    .movers-heading {
        margin-top: 20px;
        width: 70%;
    }

    .last-icon-main-div {
        width: 80%;
        margin-top: 40px;
        margin-left: auto;
        margin-right: auto;
    }

    /* .phoneAddress{
        margin-top: 40px;
    } */

    .best-moves {
        margin-right: auto;
    }
    .verified-review {
        width: 80%;
    }
    /* .rating-section,.verified-review{
     margin-left: 40%;
 } */

    .rating-div {
        /* margin-left: 35px; */
        /* margin-top: -100px; */
    }
    .test-rate {
        /* margin-top: -10px; */
    }
    .image-image {
        width: 100px !important;
        height: 40px !important;
    }
}

@media (max-width: 375px) {
    .rating-div {
        /* margin-left: 10px; */
        /* margin-top: -100px; */
    }
}

@media (max-width: 360px) {
    .rating-div {
        /* margin-left: 0px; */
        /* margin-top: -100px; */
    }
}
