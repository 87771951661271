.mainEstimated {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 3px #0000000a;
    border: 0.5px solid #e0e0e0;
    border-radius: 4px;
    opacity: 1;
    width: 95%;
    margin: 0px;

    @media (max-width: 600px) {
        width: 100%;
        margin: auto;
    }

    .estimatedText {
        text-align: left;
        font-size: 20px;
        letter-spacing: 0px;
        color: #6b6c6f;
        text-transform: capitalize;
        opacity: 1;
        margin: 20px 20px 0px;

        @media (max-width: 600px) {
            font-size: 14px;
            margin: 20px 10px 0px;
        }
    }
    .estimatedDollarDateMain {
        display: flex;
        flex-direction: column;

        @media (max-width: 600px) {
            flex-direction: row;
        }
    }

    .dollarEstimated {
        margin: 17px 20px 0px;

        @media (max-width: 600px) {
            display: inline-block;
            margin: 7px 10px 0px 10px;
        }

        .dollarEstimatedText {
            text-align: left;
            font-size: 34px;
            letter-spacing: 0px;
            color: #000;
            font-family: 'Poppins';
            font-weight: 700;
            text-transform: capitalize;
            opacity: 1;

            padding: 0px 12px;

            @media (max-width: 600px) {
                font-size: 22px;
                font-weight: 900;
            }
        }
    }

    .dateEstimated {
        font-size: 14px;

        margin: 17px 20px;

        @media (max-width: 600px) {
            display: inline;
            margin: 17px 5px;
            font-size: 12px;
        }
    }

    .addressEstimated {
        margin: 20px 20px;
        font-size: 14px;

        @media (max-width: 600px) {
            margin: 20px 10px 20px;
        }

        .imageAddressEstimated {
            position: absolute;
            padding-right: 20px;
            margin-top: 1px;
            width: 35px;
            height: 20px;
        }

        .imageAddressEstimated1 {
            position: absolute;
            padding-right: 20px;
            margin-top: -2px;
        }
    }

    .estimatedLastSection1 {
        flex: 0.25;

        @media (max-width: 600px) {
            flex: 3.3;
        }
    }

    .estimatedLastSection {
        flex: 0.25;

        @media (max-width: 600px) {
            flex: 1;
        }
    }

    .lastSectionEstimated {
        margin: 20px 20px;
        width: 80%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;

        @media (max-width: 600px) {
            width: 80%;
            margin: 20px 10px;
        }

        .estimatedAlignement {
            margin-top: auto;

            @media (max-width: 600px) {
                margin-top: 20px;
            }
        }
    }
}
.purchaseInfoCover {
    padding: 40px 20px;
    height: 243px;

    @media (max-width: 600px) {
        height: auto;
    }
    .purchaseTitle {
        font-family: Roboto;
        font-size: 20px;
        color: #6b6c6f;
        margin-bottom: 12px;
    }

    .price {
        font-family: Poppins;
        font-size: 34px;
        color: #333333;
        font-weight: 700;
        position: relative;
        margin-bottom: 0px;
        &:before {
            content: '$';
            font-size: 14px;
            position: absolute;
            left: -8px;
        }
    }

    .purchaseDate {
        font-family: Roboto;
        font-size: 14px;
        color: #333333;
    }

    .addressEstimated {
        display: flex;
        align-items: center;
        margin: 0;
        margin-top: 30px;
        
        .addressText {
            font-family: Roboto;
            font-size: 14px;
            color: #0066f5;
            margin-left: 6px;
        }
    }

    &.fromDashboard {
        height: auto;
        padding: 0px 20px;
        padding-right: 0px;

        .purchaseTitle {
            font-size: 12px;
        }
        .purchaseDate {
            margin-left: 8px;
        }

        .moreLinkCover {
            padding-bottom: 0px !important;
            width: fit-content;
        }
    }
}

.lastSectionEstimatedFlex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
