.SubscriptionGetStartedCardMain {
    padding: 20px;
    // height: 100%;
    .headingSubscriptionGetStartedCardMain {
        font-size: 22px;
        line-height: 32px;
        color: #333333;
        margin-bottom: 10px;

        @media (max-width: 1024px) {
            font-size: 17px;
            line-height: 26px;
        }
        @media (max-width: 768px) {
            font-size: 14px;
            line-height: 20px;
        }
    }
    .subHeadingSubscriptionGetStartedCardMain {
        font-size: 14px;
        line-height: 28px;
        color: #333333;
        margin-bottom: 10px;

        @media (max-width: 1024px) {
            font-size: 12px;
            line-height: 20px;
            margin-bottom: 5px;
        }
    }
    .servicesSubscriptionGetStartedCardFlexDiv {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 10px 0;

        .greenIcon {
            color: #00d56d;

            @media (max-width: 1024px) {
                font-size: 20px;
            }
        }
        .greyIcon {
            color: #eeeeee;

            @media (max-width: 1024px) {
                font-size: 20px;
            }
        }
        .servicesSubscriptionGetStartedCardInner {
            display: flex;
            align-items: center;
        }
        .serviceNameSubscriptionGetStartedCard {
            font-size: 14px;
            line-height: 20px;
            color: #333333;
            margin-left: 15px;
            font-family: 'Roboto';
            font-weight: 600;

            @media (max-width: 1024px) {
                font-size: 12px;
                line-height: 18px;
                margin-left: 10px;
            }
        }
        .serviceNameSubscriptionGetStartedCard1 {
            font-size: 14px;
            line-height: 20px;
            color: #002e62;
            margin-left: 15px;
            font-family: 'Roboto';
            font-weight: 600;
        }

        @media (max-width: 768px) {
            margin: 5px 0;
        }
    }
    .internetOnComplete {
        .internetOnCompleteService {
            display: flex;
            justify-content: space-between;
            padding-bottom: 3px;
        }
    }
    .internetOnCompleteContact {
        display: flex;
        flex-direction: row;

        .internetOnCompleteContactDetail {
            display: flex;
            flex-direction: column;
            padding-right: 16px;
        }
        .internetOnCompleteSpeed {
            padding-bottom: 8px;
        }
    }

    @media (max-width: 1024px) {
        padding: 10px 15px;
    }
}
.internetOnCompleteSpeedHead {
    font-size: 14px;
    font-weight: bold;
    font-family: 'Roboto';
    padding-bottom: 6px;
}
.energyNotComplete {
    font-size: 14px;
    font-family: 'Roboto';
    color: #6b6c6f;
    padding: 20px 0px 14px 0px;
    text-align: center;
}
.energynotCompleteHead {
    display: flex;
    align-items: center;
    flex-direction: column;
}
.internetOnCompleteContactHead {
    font-size: 14px;
    font-weight: 500;
    font-style: 'Roboto';
    color: #6b6c6f;
    padding-bottom: 3px;
}
.internetOnCompleteContactValue {
    font-size: 14px;
    // font-weight: 500;
    font-style: 'Poppins';
    // color: #6b6c6f;
}
.border-line {
    border: 1px solid #dadce0;
    margin: 20px 0px 15px 0px;
}
.solarOnComplete {
    .solorOnCompleteMain {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .solorImage {
        padding-right: 17px;
    }
    .solarAccount {
        padding-left: 30px;
    }
    .solarOnCompleteInside {
        display: flex;
        flex-direction: column;
        .solarOnCompletehead {
            font-size: 18px;
            font-family: 'Roboto';
            padding-bottom: 12px;
        }
    }
}
.insuranceOnComplete {
    .insuranceOnCompleteContent {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}
.homeOnComplete {
    .homeOnCompleteInner {
        display: flex;
        justify-content: space-between;
    }
    .homeOnCompleteInnerData {
        display: flex;
        align-items: center;
    }
    .homeSecurityPadding {
        padding-right: 16px;
    }
}
.documentOnComplete {
}
