.date-autoshipping {
    .MuiInputAdornment-root {
        align-items: center;
    }

    .MuiInputBase-root {
        background-color: #ffffff;
        border: 1px solid #bebebe;
        border-radius: 4px;
        height: 50px;
        padding-left: 12px;
        @media (max-width: 1280px) {
            margin-right: -5px;
            padding-left: 4px !important;
        }
        @media (max-width: 425px) {
            padding-left: 8px !important;
            margin-right: unset !important;
            font-size: 16px;
        }
        @media (max-width: 425px) {
            padding-left: 2px !important;
            margin-right: unset !important;
            font-size: 12px;
        }
    }
    .MuiInput-underline:before,
    .MuiInput-underline:after {
        border-bottom: none !important;
    }
}

.autoshipping-main-page {
    padding: 0px 80px;
    margin-top: 30px;
    .deleteFormButton {
        text-align: right;
        margin-top: 10px;
        cursor: pointer;
    }

    .autoShippingTabs {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #00000024;

        .tab-div-moving {
            margin-bottom: 0;
        }
        .autoshipping-quote-div {
            display: flex;

            .quoteBlackText {
                font-size: 14px;
                line-height: 18px;
                color: #333333;
                margin-right: 5px;
            }

            .quoteBlueText {
                font-size: 14px;
                line-height: 18px;
                color: #009cbd;
                text-decoration: underline;
                cursor: pointer;
            }
            .quotedtext {
                font-size: 14px;
                line-height: 18px;
                color: #006dff;
                text-decoration: underline;
                cursor: pointer;
            }

            @media (max-width: 768px) {
                display: none;
            }
        }
    }

    .MuiStepLabel-label {
        font-size: 22px;
        line-height: 30px;
        font-family: 'Roboto', sans-serif;
        color: #333333;
    }

    .stepperEditIcon {
        display: none;
    }

    .MuiStepLabel-label.MuiStepLabel-active {
        color: #006dff !important;
        font-weight: bold;
    }

    .MuiStepIcon-root.MuiStepIcon-active {
        color: #006dff !important;
    }

    // .MuiStep-completed {
    //   .MuiStepLabel-labelContainer{
    //     border-bottom: 1px solid #BEBEBE;
    //   }
    // }

    .MuiStepLabel-label.MuiStepLabel-completed {
        color: #00d56d;
        border-bottom: 1px solid #bebebe;
        display: flex;
        justify-content: space-between;

        .stepperEditIcon {
            color: #6b6c6f;
            display: block;
        }

        @media (max-width: 600px) {
            border-bottom: none;
        }
    }

    .MuiStepIcon-root.MuiStepIcon-completed {
        color: #00d56d;
    }

    .movingStepperButton {
        border-radius: 4px !important; 
    }
    .lastStepBtnCover {
        .movingStepperButton {
           margin-bottom: 28px !important;
        }
    }

    .step1Button {
        margin-bottom: 27px !important;
    }

    .autoshippingRadioInputDiv {
        width: 70%;
    }

    .addVedicleButton {
        color: #009cbd;
        font-size: 14px;
        line-height: 18px;
        cursor: pointer;
    }
    .addVedicleButtonM {
        color: #006dff;
        font-size: 14px;
        line-height: 18px;
        cursor: pointer;
    }

    .autoshippingStepsContent {
        .react-select {
            width: 100%;
        }

        .react-select .select-state__control {
            height: 50px;
        }
        .autoshippingButtonDiv {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
        }

        .autoshippingInpts {
            width: 60%;
            .css-1pahdxg-control {
                border-color: #006dff !important;
            }
            .autoshippingInput1 {
                width: 100%;
            }

            .MuiFormGroup-root {
                flex-direction: row;
            }

            .inputLabel .MuiFormLabel-root {
                color: #333333;

                @media (max-width: 1024px) {
                    font-size: 14px !important;
                }
            }

            .MuiFilledInput-root {
                background-color: #ffffff;
                border: 1px solid #bebebe;
                border-radius: 4px;
            }
            .MuiFilledInput-root:focus {
                border: 1px solid #006dff !important;
            }

            .MuiFilledInput-root.Mui-focused {
                border-color: #006dff !important;
            }

            .MuiFilledInput-root.Mui-error {
                border: 1px solid red;
            }

            .MuiInputAdornment-root {
                align-items: flex-start;
                // padding-bottom: 10px;
            }

            .datePickerDiv {
                width: 48%;

                .MuiFormControl-marginNormal {
                    margin-bottom: 8px;
                }

                .autoshippingButton {
                    width: 100% !important;
                    margin-bottom: 27px;
                }
            }

            .date-autoshipping {
                .MuiInputAdornment-root {
                    align-items: center;
                }

                .MuiInputBase-root {
                    background-color: #ffffff;
                    border: 1px solid #bebebe;
                    border-radius: 4px;
                    height: 50px;
                    padding-left: 12px;
                }
                .MuiInput-underline:before,
                .MuiInput-underline:after {
                    border-bottom: none;
                }
            }
            .checkBtn {
                .MuiRadio-colorSecondary.Mui-checked {
                    color: #006dff !important;
                }
            }
            .checkBtnD {
                .MuiRadio-colorSecondary.Mui-checked {
                    color: #009cbd !important;
                }
            }

            .MuiInputLabel-filled.MuiInputLabel-shrink {
                z-index: 1 !important;
                color: #006dff !important;
            }

            .MuiFilledInput-underline:before,
            .MuiFilledInput-underline:after {
                border-bottom: none;
            }

            @media (max-width: 1024px) {
                width: 85%;
            }

            @media (max-width: 600px) {
                width: 100%;
            }
        }
        .autoInputsD {
            .lastStepBtnCover {
                margin-bottom: 25px;
            }
            .MuiFilledInput-root:focus {
                border: 1px solid #009cbd !important;
            }
            .MuiInputLabel-filled.MuiInputLabel-shrink {
                z-index: 1 !important;
                color: #009cbd !important;
            }
            .MuiFilledInput-root.Mui-focused {
                border-color: #009cbd !important;
            }
        }
        .autoInputsNoDoug {
            .css-1pahdxg-control {
                border-color: #009cbd !important;
            }
        }
    }

    .stepperFlexDiv {
        display: flex;
        justify-content: space-between;
        margin-bottom: 100px;

        .stepperDiv {
            width: 50%;

            @media (max-width: 768px) {
                width: 68%;
            }

            @media (max-width: 600px) {
                width: 100%;
                .MuiStepper-root {
                    padding: 24px 0;
                }
            }
        }

        .detailsAddress {
            width: 20%;
            display: flex;
            padding-top: 50px;
            flex-direction: column;

            @media (max-width: 1024px) {
                width: 25%;
                padding-top: 30px;
            }
            @media (max-width: 768px) {
                width: 30%;
            }

            @media (max-width: 600px) {
                width: 100%;
                display: none;
            }
        }

        @media (max-width: 600px) {
            flex-wrap: wrap;
        }
    }

    .destinationText {
        font-size: 16px;
        line-height: 22px;
        font-weight: bold;
        color: #333333;
        padding: 10px 0;
    }

    .destDetails {
        font-size: 14px;
        line-height: 24px;
        margin: 10px 0;

        @media (max-width: 768px) {
            margin: 5px 0;
        }
    }

    .mobileDestiDetails {
        display: none;

        @media (max-width: 600px) {
            display: block;
            margin-top: -150px;
            padding-left: 32px;
            margin-bottom: 18px;
            border-bottom: 1px solid #c6c6c6;
        }
    }

    .stepContent {
        @media (max-width: 600px) {
            min-height: 120px;
        }
    }
    .vehicalDetailsDiv {
        border-top: 1px solid #c6c6c6;
        padding-top: 20px;
        margin-top: 20px;

        @media (max-width: 768px) {
            padding-top: 10px;
            margin-top: 10px;
        }
    }

    @media (max-width: 1366px) {
        padding: 0px 60px;
    }

    @media (max-width: 600px) {
        padding: 0px 10px;
    }
}
.autoshipping-main-pageDouglas {
    .tab-active {
        color: #009cbd !important;
        border-bottom: 2px solid #009cbd !important;
    }
    .MuiStepLabel-label.MuiStepLabel-active,
    .MuiStepIcon-root.MuiStepIcon-active,
    .MuiRadio-colorSecondary.Mui-checked,
    .addVedicleButton,
    .quoteBlueText {
        color: #009cbd !important;
    }
    .MuiInputBase-root {
        border: 1px solid #100b28 !important;
        border-radius: 0px !important;
        color: #323744;
    }
}

// .css-1pahdxg-control {
//     border-color: #009cbd !important;
// }

// .css-yk16xz-control:hover {
//     border-color: #009cbd !important;
// }
