.checklist-overdue-addTasks {
    display: flex;
    flex-direction: row;
    margin-top: 30px;

    .MuiTextField-root {
        margin: 0 !important;
        width: 610px !important;
        color: #bebebe;
    }
    .MuiOutlinedInput-inputMultiline {
        @media (max-width: 600px) {
            font-size: 12px;
        }
    }
    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: none !important;
        border: none;
    }
    .MuiOutlinedInput-notchedOutline:hover {
        border: none;
        border-color: none !important;
    }

    .MuiOutlinedInput-root {
        height: 53px !important;
    }

    .MuiOutlinedInput-root.Mui-focused {
        border: 1px solid #006dff !important;
    }
    .checklist-overdue-addTasks-save {
        .MuiButton-root.Mui-disabled {
            background-color: #bebebe !important;
            border: none !important;
        }
    }
    .checklist-overdue-addTasks-cancel {
        padding-left: 13px;
    }
    .checklist-overdue-addTasks-btn-wrapper {
        display: flex;
        flex-direction: row;
    }
    @media (max-width: 768px) {
        .MuiTextField-root {
            width: 450px !important;
        }
    }
    @media (max-width: 600px) {
        display: flex;
        flex-direction: column;
        .MuiTextField-root {
            width: 100% !important;
        }
        .checklist-overdue-addTasks-btn-wrapper {
            justify-content: center;
            margin-top: 15px;
        }
    }
}
.checklist-overdue-addTasksDouglas {
    .MuiOutlinedInput-root.Mui-focused {
        border: 1px solid #009cbd !important;
    }
    .checklist-overdue-addTasks-cancel {
        button {
            color: #009cbd !important;
        }
    }
}
.checklist-overdue-editasks {
    display: flex;
    flex-direction: row;
    margin: 30px 0;
    justify-content: space-between;

    .MuiTextField-root {
        margin: 0 !important;
        width: 610px !important;
    }
    .MuiOutlinedInput-inputMultiline {
        @media (max-width: 600px) {
            font-size: 12px;
        }
    }
    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: none !important;
        border: none;
    }
    .MuiOutlinedInput-notchedOutline:hover {
        border: none;
        border-color: none !important;
    }

    .MuiOutlinedInput-root {
        height: 53px !important;
    }
    .MuiOutlinedInput-root.Mui-focused {
        border: 1px solid #006dff !important;
    }
    .checklist-overdue-editTasks-save {
        .MuiButton-root.Mui-disabled {
            background-color: #bebebe !important;
            border: none !important;
        }
    }
    .checklist-overdue-editTasks-save {
        button {
            background-color: #009cbd !important;
        }
    }
    .checklist-overdue-editTasks-saveNoDoug {
        button {
            background-color: #006dff !important;
        }
    }
    .checklist-overdue-editTasks-delete {
        display: flex;
        align-items: center;
        margin: 0 20px 0 0;
        cursor: pointer;
    }
    .checklist-overdue-editTasks-cancel {
        padding-left: 13px;
    }
    .checklist-overdue-editTasks-btn-wrapper {
        display: flex;
        flex-direction: row;
    }
    @media (max-width: 768px) {
        .MuiTextField-root {
            width: 400px !important;
        }
    }
    @media (max-width: 600px) {
        display: flex;
        flex-direction: column;
        .MuiOutlinedInput-root {
            margin-right: 0px;
        }
        .MuiTextField-root {
            width: 100% !important;
        }
        .checklist-overdue-editTasks-btn-wrapper {
            justify-content: center;
            margin-top: 15px;
        }
    }
}

.checklist-overdue-editasksDoug {
    .MuiOutlinedInput-root.Mui-focused {
        border: 1px solid #009cbd !important;
    }
}
