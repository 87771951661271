.main-div-recommendation {
    display: flex;
    width: 100%;

    .main-cards-security {
        display: flex;
        // flex-wrap: wrap;
        padding-bottom: 20px;
        width: 80%;
        overflow-x: auto;
        padding-left: 2px;
    }

    .card-1-recommendation,
    .card-2-recommendation {
        width: 20%;
        padding-bottom: 10px;
        margin: 0px;

        // @media(max-width:1500px){
        //     // width: 250px;
        //     width: 20%;
        // }

        // @media(max-width:1250px){
        //     width: 220px;
        // }

        // @media(max-width:1150px){
        //     width: 200px;
        // }

        // @media(max-width:1050px){
        //     width: 180px;
        // }

        // @media(max-width:1000px){
        //    width: 80%;
        // }
        @media (max-width: 600px) {
            width: 40%;
        }
        @media (max-width: 425px) {
            width: 50%;
        }
        @media (max-width: 325px) {
            width: 52%;
        }
    }

    .card-1-recommendation {
        @media (max-width: 600px) {
            box-shadow: 4px 0 5px -2px #e2e2e2;
        }
    }

    .card-2-recommendation {
        width: 25%;

        @media (max-width: 1024px) {
            width: 40%;
        }
        @media (max-width: 600px) {
            width: 88%;
        }
    }

    .recommended-section {
        height: 40px;

        .recommended {
            color: #fff;
            background: #006dff;
            font-weight: bold;
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .addColor {
            background: #009cbd;
        }
    }

    .logo-section {
        height: 150px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .logoCard {
        width: 200px;
    }

    .brand-title {
        height: 50px !important;
        font-weight: bolder;
        text-align: center;
        font-size: 24px;
        color: #333;
    }
    .monthly-fees {
        text-align: center;
        font-weight: bold;
        font-size: 16px;
        padding: 10px 0 20px 0;
        border-bottom: 1px solid #e2e2e2;
    }

    .upfront-cost-1,
    .installation-cost-1,
    .installation-type-1 {
        text-align: center;
        padding: 19px 0;
        border-bottom: 1px solid #e2e2e2;
        font-weight: bold;
        line-height: normal;
    }
    .exclusive-deals-1,
    .top-features-1,
    .conditions-1 {
        text-align: center;
        padding: 19px 0;
        font-weight: bold;
        font-size: 16px;
    }

    .upfront-cost,
    .installation-cost,
    .installation-type {
        text-align: center;
        padding: 20px;
        border-bottom: 1px solid #e2e2e2;
        font-size: 14px;
        opacity: 0.8;
        line-height: normal;

        @media (max-width: 600px) {
            padding: 20px 10px;
        }
    }
    .waived {
        font-weight: bold;
        opacity: 1;
        color: #333;
        position: relative;
    }
    .waived::after {
        content: '';
        width: 30px;
        height: 2px;
        background: red;
        position: absolute;
        bottom: 7px;
        left: 0;
        transform: rotate(-30deg);
    }

    .tag-special {
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
    }

    .price {
        font-weight: bold;
        font-size: 24px;
        padding: 10px 0;
    }

    .speciality {
        opacity: 0.8;
        font-size: 14px;
        text-align: center;
    }

    .exclusive-deals {
        border-bottom: 1px solid #e2e2e2;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 20px;
        height: 220px;
    }

    .card-1-recommendation {
        .exclusive-deals {
            padding: 20px 0;
        }
        .top-features {
            padding: 20px 0px;
        }
        .conditions {
            padding: 20px 0;
        }
    }

    .top-features {
        border-bottom: 1px solid #e2e2e2;
        padding: 20px 40px;
        height: 150px;
        opacity: 0.8;
        text-align: center;
        font-size: 14px;
        display: flex;
        justify-content: center;
        align-items: center;

        @media (max-width: 600px) {
            padding: 20px;
        }
    }

    .conditions {
        opacity: 0.8;
        text-align: center;
        font-size: 12px;
        padding: 20px;
    }

    .call {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px;

        button {
            @media (max-width: 600px) {
                font-size: 12px !important;
            }
        }
    }

    .condition-inner {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .dollar {
        font-size: 12px;
        font-weight: 400;
        opacity: 0.8;
    }

    .view-details {
        color: #006dff;
        font-size: 14px;
        text-decoration: underline;
        padding: 10px;
        text-align: center;
        cursor: pointer;
    }

    //   @media (max-width:600px) {
    //      flex-wrap: wrap;
    //   }
}

.modalClassesSecurity {
    // position: absolute;
    width: 500px;
    height: 550px;
    max-height: 80vh;
    background-color: #ffffff;
    left: 40%;
    top: 5%;
    overflow: auto;

    .scheduleInsuranceCall {
        height: 70%;
    }
    .inputRecommendationHomeSecurity {
        .MuiInputLabel-filled.MuiInputLabel-shrink {
            z-index: 1 !important;
            color: #100b28;
        }
        .MuiFilledInput-root {
            border: none;
        }
        input {
            // background-color: #fff;
            // border: 1px solid #100b28;
            border-radius: 0px !important;
            color: #323744;
        }
    }

    .date11 {
        width: 100%;
        margin-right: 0;
    }

    .error {
        height: 20px;
    }

    // @media (max-width: 1500px) {
    //     top: 8%;
    //     left: 35%;
    // }

    // @media (max-width: 1400px) {
    //     left: 30%;
    //     top: 2%;
    // }

    @media (max-width: 1600px) {
        left: 35%;
    }
    @media (max-width: 1367px) {
        left: 30%;
    }
    @media (max-width: 1024px) {
        left: 25%;
    }
    @media (max-width: 768px) {
        left: 18%;
    }
    @media (max-width: 600px) {
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
    }
}
