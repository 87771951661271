.info-toast-message {
    @media (max-width: 480px) {
        width: 100%;
        // padding: 0 16px;
    }
}
.main {
    width: 100%;
    padding: 0 80px;

    @media (min-width: 1920px) {
        max-width: 1920px;
        margin: 0 auto;
    }
    // @media screen and (max-width: 2100px) and (min-width: 1900px) {
    //     width: 75%;
    //     margin: auto;
    // }
    // @media screen and (max-width: 1900px) and (min-width: 1700px) {
    //     width: 85%;
    //     margin: auto;
    // }
    // @media screen and (max-width: 1700px) and (min-width: 1360px) {
    //     width: 95%;
    //     margin: auto;
    // }
    // @media screen and (max-width: 1360px) and (min-width: 600px) {
    //     width: 98%;
    //     margin: auto;
    // }
    @media (max-width: 768px) {
        width: 100%;
        margin: auto;
        padding: 0px 10px;

        .row {
            padding: 12px 16px;
        }
    }

    .homeValueationSidePanel {
        position: fixed;
        .navigation {
            width: 284px;
            ul {
                margin-bottom: 0px;
                li {
                    height: 57px;
                    a {
                        font-family: Roboto;
                        font-size: 14px;
                        font-weight: medium;
                    }
                }
            }
        }

        @media(max-width: 1350px) {
            max-height: 50vh;
            overflow-y: scroll;

            &::-webkit-scrollbar {
                width: 4px;
                height: 3px;
            }
    
            &::-webkit-scrollbar-track {
                background: transparent;
            }
    
            /* scrollbar handle */
            &::-webkit-scrollbar-thumb {
                background-color: lightgray;
            }

            .navigation {
             width: 254px;

             ul {
                li {
                    height: 50px;
                }
             }
            }
        }
    }

    .estimatedBlock {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: stretch;
        justify-content: space-between;

        margin: 116px 10px 30px;
        margin-left: 0;

        @media (max-width: 768px) {
            margin-right: 0;
        }
        @media (max-width: 600px) {
            display: flex;
            flex-direction: column;
        }
        @media screen and (max-width: 600px) {
            margin: 10px 10px 10px;
        }
        .estimatedBlockContainer {
            flex: 0.7;
            @media screen and (max-width: 1140px) and (min-width: 600px) {
                flex: 0.7;
            }
            @media (max-width: 600px) {
                flex: 1;
            }
        }
        @media (max-width: 600px) {
            width: 100%;
            margin: 10px auto 0px auto;
        }
    }
    .mortgage-balance {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: stretch;
        justify-content: space-between;
        margin: 0px 0px;
        padding: 1% 1%;
        padding-left: 0;
        width: 100%;
        @media (max-width: 480px) {
            padding: 0;
        }
        @media (max-width: 600px) {
            flex-direction: column;
            margin: 0px 0px;
        }
        .mortgage-balance-container {
            background: #ffffff 0% 0% no-repeat padding-box;
            border-radius: 4px;
            margin: 15px 20px 15px auto;
            padding: 13px 20px;
            box-shadow: 0px 1px 3px #0000000a;
            border: 0.5px solid #dadce0;
            border-radius: 4px;
            opacity: 1;
            flex: 0.45;
            @media screen and (max-width: 600px) {
                flex: 1;
                width: 100%;
                margin: 15px auto 0px;
            }
            &.emptyMortgageCover {
                box-shadow: none;
                border: none;
            }
        }
        .mortgage-balance-container1 {
            flex: 0.55;
            background: #ffffff 0% 0% no-repeat padding-box;
            border-radius: 4px;
            margin: 15px auto;
            padding: 13px 20px;
            box-shadow: 0px 1px 3px #0000000a;
            border: 0.5px solid #dadce0;
            border-radius: 4px;
            opacity: 1;
            @media (max-width: 768px) {
                width: 58%;
            }
            @media screen and (max-width: 600px) {
                margin: 1px auto;
            }
        }
    }

    #mortgage-balance, #savingCalculatorNodata {
        .Main {
            display: flex;
            flex-direction: column;
            width: 356px;
            margin: 0px;
            margin-bottom: 40px;

            @media (max-width: 340px) {
                width: 100%;
            }

            .loan-details-grp1-info-title {
                font-size: 20px !important;
                @media (max-width: 340px) {
                    width: 100%;
                    text-align: center;
                    margin: 0px;
                }
                @media (max-width: 480px) {
                    margin: 0 auto;
                }
            }
            .MainBox1 {
                margin-left: 52px !important;
                img {
                    width: 125px !important;
                }
                @media (max-width: 480px) {
                    margin-left: 0px !important;
                    margin: 0 auto !important;
                }
            }
            .loan-details-grp1-info-description {
                width: 80% !important;
                @media (max-width: 480px) {
                    width: 100% !important;
                    margin: 10px 0px !important;
                }
            }
            .loan-details-grp1-info-description,
            button {
                margin-top: 10px !important;
                @media (max-width: 340px) {
                    width: 100%;
                    text-align: center;
                    margin: 0px;
                }
                @media (max-width: 480px) {
                    margin: 0 auto !important;
                }
            }
            .loan-details-grp1-info-description {
                width: 80% !important;
                @media (max-width: 480px) {
                    width: 100% !important;
                    margin: 10px 0px !important;
                }
            }
            .MainBox1 {
                margin: 0 auto;
            }

            .MainBox2 {
                margin-left: 0px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
            }
        }
    }
    #savingCalculatorNodata {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .HomeProjectMain {
        margin: 24px 10px 0px;
        margin-left: 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        @media (max-width: 768px) {
            margin-right: 0px;
            flex-direction: column;
        }
        @media screen and (max-width: 730px) {
            flex-direction: column-reverse;
            margin: 24px 0px 0px;
        }
        .text {
            text-align: left;
            font-size: 20px;
            font-family: 'Roboto';
            font-weight: medium;
            letter-spacing: 0px;
            color: #333333;
            text-transform: capitalize;
            opacity: 1;
        }
        .HomeProjectBox {
            flex: 0.97;

            .remodelingDisclaimer {
                font-size: 9px;
                color: #6b6c6f;
                margin-top: -15px;
                display: flex;
                align-items: center;
            }
        }
    }
    .progressBarBlock {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 0px 10px 30px;
        margin-left: 0;

        @media screen and (max-width: 768px) {
            margin: 10px 0px 10px;
        }
    }
    .estimatedBlockContainer2 {
        flex: 0.3;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .mainCard {
            .cardContent {
                .collectCardNew {
                    .price {
                      font-size: 18px;

                      .dollar {
                        margin-top: -5px;
                      }
                    }
                }
            }
        }

        @media (max-width: 600px) {
            margin-top: 30px;
            flex-direction: row;

            .mainCard {
                width: 48%;
                height: auto !important;
                margin-bottom: 10px;
                .cardContent {
                    padding-top: 20px;
                    .collectCardNew {
                        .price {
                            width: fit-content;
                            margin: 0 auto;
                        }
                    }
                }
            }
        }
        @media (max-width: 480px) {
            .mainCard {
                .cardContent {
                    .collectCardPara {
                        font-size: 12px;
                    }
                    .collectCardNew {
                        .price {
                            font-size: 18px;
                        }
                    }
                    .collectCardTitle {
                        font-size: 10px;
                    }
                }
            }
        }
        @media screen and (max-width: 1140px) and (min-width: 600px) {
            flex: 0.3;
        }
    }
    .leftNavPanelContainer {
        display: block;
        width: 25%;
        min-width: 284px;
        @media screen and (max-width: 1200px) and (min-width: 0px) {
            display: none;
        }
    }
    .rightContentContainer {
        padding-left: 0;

        @media(min-width: 1233px) and (max-width:1400px) {
            width: 72% !important;
        }

        @media (max-width: 768px) {
            padding: 0;
        }
    }
}

.fix-mobile {
    @media (max-width: 575px) {
        display: block !important;
        position: fixed;
        bottom: -2px;
        z-index: 999;
        width: 100%;

        .MainBanner {
            flex-direction: row;
            margin-left: -12px;
            .nameBanner {
                display: none !important;
            }
            .bannerSubTitle {
                display: none;
            }
            .bannerTitle {
                font: normal normal normal 12px/18px Roboto;
                margin: 5px auto;
                .mobile-nameBanner {
                    display: block;
                    font: normal normal normal 12px/28px Roboto;
                    letter-spacing: 0px;
                    color: #b4b4b4;
                }
            }
            .banner-Container {
                .bannerImage {
                    img {
                        width: 46px !important;
                        height: 46px !important;
                    }
                }
            }
            button {
                width: 120px !important;
                height: 30px !important;
                font-size: 12px !important;
                line-height: 18px;
                font-style: Roboto !important;

                @media (max-width: 340px) {
                    font-size: 11px !important;
                }
            }
        }
    }
}

.hellcBannerCard {
    padding: 10px 15px !important;
}

.AlignementBlogCard {
    // display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 99%;
    height: auto;
    overflow: scroll;
    overflow: auto;
    white-space: wrap;
    -webkit-overflow-scrolling: touch;
    @media screen and (max-width: 600px) {
        width: 100%;
    }
    &::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none; /* IE 11 */
    scrollbar-width: none; /* Firefox 64 */
}

.containerHomeEquity {
    margin: 20px auto;
    
    .bannerTitle {
        margin: 0px 0px !important;
    }
}
.TableTitle {
    display: block;
    text-align: left;
    margin: 40px 0px 20px;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
}
.homeEquityTitle {
    display: block;
    text-align: left;
    margin: 50px 10px 0px;
    margin-left: 0;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
    @media screen and (max-width: 600px) {
        margin: 20px 1px 4px;
        font-size: 16px;
    }
}
.homeEquitySubTitle {
    display: block;
    text-align: left;
    margin: 10px 10px;
    margin-left: 0;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0px;
    color: #6b6c6f;
    opacity: 1;
    @media screen and (max-width: 600px) {
        margin: 0px 1px;
        font-size: 12px;
    }
}
.AffordablityCard {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media (max-width: 768px) {
        :last-child {
            margin-right: 0;
        }
    }
    @media screen and (max-width: 600px) {
        display: none;
    }
}
.AffordabilityCardMain {
    width: 100%;
    padding: 6px auto;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 4px;
    box-shadow: 0px 1px 3px #0000000a;
    border: 1px solid #e0e0e0;
    opacity: 1;
    margin: 10px;
    margin-left: 0;
    padding: 10px;
    position: relative;
    align-items: center;
    text-align: center;

    .mainText, .mainSubText {
        opacity: 0.7;
    }
}
.AffordabilityCardMainSelected {
    width: 100%;
    padding: 6px auto;
    background: #f0f4fc 0% 0% no-repeat padding-box;
    border-radius: 4px;
    box-shadow: 0px 1px 3px #0000000a;
    border: 1px solid #0066f5;
    opacity: 1;
    margin: 10px;
    margin-left: 0;
    padding: 10px;
    position: relative;
    align-items: center;
    text-align: center;
}
.AffordablityCalculator {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: stretch;
    justify-content: space-between;
    padding: 2% 1% 0%;
    padding-left: 0;
    width: 100%;

    @media (max-width: 768px) {
        padding-right: 0px;
        flex-direction: column;
    }

    @media screen and (max-width: 600px) {
        flex-direction: column;

        padding: 2% 0%;
    }
}
.AffordablityCalculatorSection {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 3px #0000000a;
    border: 0.5px solid #e0e0e0;
    opacity: 1;
    flex: 0.45;
    margin-right: 10px;

    @media(max-width: 1439px) {
        flex: none;
        width: 50%;
    }
    @media screen and (max-width: 800px) {
        margin-right: 0px;
    }
    @media(max-width: 768px) {
        width: 100%;

        table {
            width: 100% !important;
            tr {
                td {
                    .termSelectCover {
                        width: 100%;
                        .MuiFormControl-root {
                            width: 100%;
                            .MuiInputBase-formControl {
                                width: 100%;
                            }
                        }
                    }
                }
                .interestRateField {
                    padding-right: 0px;
                }
            }
        }
    }
    .mainSection {
        .saveBtnCover
        button {
            border-radius: 4px !important;
        }
    }
}
.AffordablityChartSection {
    background: #ffffff 0% 0% no-repeat padding-box;
    margin-left: 10px;
    box-shadow: 0px 1px 3px #0000000a;
    border: 0.5px solid #e0e0e0;
    opacity: 1;
    margin-top: 0px;
    flex: 0.55;
    width: 90%;
    @media(max-width: 1439px) {
        flex: none;
        width: 50%;
    }
    @media screen and (max-width: 800px) {
        margin-left: 0px;
        width: 100%;
    }
    @media(max-width: 768px) {
        width: 100%;
        margin-top: 20px;
    }

    .mainQuestion {

        margin-top: 68px;
        @media(max-width: 480px) {
            display: flex;
            flex-direction: column;
        }
        
        .logoIconCover {
            @media(max-width: 1350px) {
                position: relative;
                float: right;
            }
            @media(max-width: 480px) {
                float: none;
                margin-left: 26px;
                margin-bottom: 10px;
            }
        }
    }
}
.AffordablityCardMobile {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    display: none;

    .AffordabilityCardMain {
        width: 100%;
        padding: 6px auto;
        background: #ffffff 0% 0% no-repeat padding-box;
        border-radius: 4px;
        box-shadow: 0px 1px 3px #0000000a;
        border: 1px solid #e0e0e0;
        opacity: 1;
        margin: 10px 0px;
        padding: 10px;
        position: relative;
        align-items: center;
        text-align: center;
       
    }
    .homeEquityMorkageTitle {
        display: block;
        text-align: left;
        margin: 0px 10px;
        font-size: 20px;
        font-weight: bold;
        letter-spacing: 0px;
        color: #333333;
        opacity: 1;
        @media screen and (max-width: 600px) {
            margin: 30px 1px 0px;
        }
    }

    @media(max-width: 600px) {
        display: block;
    }
}

.disclammerAlignemnt {
    margin-left: 6px;
    @media screen and (max-width: 600px) {
        margin-left: 0px;
    }
}

.AffordablityDisclamer {
    width: 98% !important;
    margin: 0px auto;
    margin-left: 0;
}

.navigatorHomeValuation {
    display: flex;
    margin-left: 35px;
    margin-bottom: 33px;
    text-align: left;
    font: normal normal normal 12px/18px Roboto;
    letter-spacing: 0px;
    opacity: 1;
    color: #bebebe;

    p {
        margin-left: 5px;
        color: #333333;
        margin-bottom: 0;
    }
}
.footerInfoBanner {
    .Main {
        .MainBox2 {
            margin-left: 20px;
        }
    }
    @media (max-width: 768px) {
        width: 75%;
        margin: 0 auto;

        .disclamerContainer {
            text-align: center;
        }
    }
    @media (max-width: 768px) {
        width: 100%;
    }
    @media (max-width: 480px) {
        .disclamerContainer {
            max-width: 235px;
            margin: 0 auto;
        }
    }
    @media (max-width: 375px) {
        .Main {
            flex-direction: row;
        }
    }
}
