.greybg {
    background-color: #f8f8f8;
    padding: 30px 0 60px;
    margin-bottom: -60px;
    .main-head {
        margin-top: 0;
    }
}
.greybgextralong{
    background-color: #f8f8f8;
    padding: 30px 0 280px;
    margin-bottom: -280px;
    .main-head {
        margin-top: 0;
    }
}

.summaryHeader {
    text-align: center;
    margin: 20px 0;
    h3 {
        font-size: 30px;
        margin: 0 0 15px;
        font-weight: 600;
    }
    p {
        color: #797979;
        font-size: 16px;
    }
}

.summarybox {
    font-size: 16px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #E2E2E2;
    border-radius: 4px;
    width: 998px;
    max-width: 90%;
    margin: 0 auto 20px;
    .summarybox_head {
        text-align: left;
        border-bottom: 1px solid #D8DCE6;
        padding: 16px 30px;
        .summaryHeader {
            text-align: left;
            margin: 0;
            font-size: 22px;
            line-height: 1.2;
            color: #333;
            font-weight: 600;
        }
    }
    .summarybox_body {
        padding: 25px 30px;
        .summaryContent {
            display: flex;
            .summaryContent_l {
                width: 33%;
                .img_wrapper{
                    display: inline-block;
                    text-align: center;
                    img {
                        max-height: 60px;
                    }
                }
            }
            .summaryContent_m {
                width: 33%;
                ul {
                    margin: 0;
                    padding: 0;
                    li {
                        list-style-type: none;
                        display: inline-block;
                        display: inline-flex;
                        color: #333333;
                        margin-right: 15px;
                        align-items: center;
                        img {
                            margin-right: 5px;
                        }
                    }
                }
            }
            .summaryContent_r {
                width: 34%;
                text-align: right;
                .plan_detail {
                    font-family: 'Poppins';
                    span {
                        font-size: 23px;
                        font-weight: 400;
                        vertical-align: top;
                    }
                    .palnvalue{
                        letter-spacing: 0px;
                        color: #333333;
                        font-size: 48px;
                        font-weight: 600;
                        line-height: 1;
                    }
                    .point{
                        vertical-align: bottom;
                    }
                }
            }
        }
        .call_bar {
            background-color: #FFEDED;
            border-radius: 4px;
            padding: 13px 25px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: #333;
            margin-top: 20px;
            span {
                font-size: 14px;
                color: #989898;
                display: inline-block;
                vertical-align: middle;
                .call_icon {
                    display: inline-block;
                    height: 16px;
                    width: 16px;
                    margin-right: 6px;
                    transition: 0.4s linear;
                    padding: 5px;
                    img {
                        height: 100%;
                        width: 100%;
                    }
                }
                a {
                    color: #333;
                    font-weight: 600;
                    margin-left: 15px;
                    &:hover{
                        .call_icon {
                            border-radius: 100%;
                            background-color: #ffffff;
                            transform: scale(1.4);
                        }
                        svg path {
                            fill: #333;
                        }
                    }
                }
            }
            
        }
        
    }

    .summaryfooter {
        padding: 22px 30px;
        text-align: center;
        color: #3E3F42;
        border-top: 1px solid #D8DCE6;
        .readmorebtn {
            border: none;
            background-color: transparent;
            font-size: 15px;
            color: #3E3F42;
            cursor: pointer;
            transition: 0.4s linear;
            &:hover{
                color: #989898;
            }
            img {
                margin-left: 10px;
                transition: 0.4s linear;
            }
        }
        .summary_hidden_content {
            display: none;
        }
        &.showcontent{
            .summary_hidden_content {
                display: block;
            }
            .summary_hidden_content + .readmorebtn img{
                transform: rotate(180deg);
            }
        } 
    }

    .content_row {
        display: flex;
        flex-wrap: wrap;
        text-align: left;
        align-items: flex-start;
        .content_col33 {
            width: 33.33%;
            margin: 15px 0;
            p {
                margin-bottom: 7px;
            }
        }
    }
    p{
        margin: 0;
    }
    .lab_val {
        color: #989898;
        font-size: 16px;
        margin-bottom: 20px;
    }
}


.utility_box {
    background-color: #FFFFFF;
    border: 1px solid #E2E2E2;
    border-radius: 4px;
    width: 998px;
    max-width: 90%;
    margin: 0 auto 30px;
    display: flex;
    align-items: center;
    .utility_boxl {
        width: 170px;
        padding: 10px;
        img {
            width: 100%;
            // height: 100%;
            // object-fit: cover;
        }
    }
    .utility_boxr {
        width: calc(100% - 170px); 
        color: #6B6C6F;
        font-size: 14px;
        & > div{
            padding: 13px 25px;
        }
        .utility_title {
            font-size: 22px;
            color: #333;
            font-weight: 600;
            margin: 0;
            line-height: 1.4;
        }
        p {
            margin: 0;
        }
        ul {
            margin: 15px 0 0;
            padding: 0;
            li {
                list-style-type: none;
                vertical-align: middle;
                display: inline-block;
                margin-right: 25px;
                svg {
                    vertical-align: middle;
                    margin-right: 10px;
                }
            }
        }
    }
    .gotoutility_btn {
        margin-top: 18px!important;
        color: #002E62;
        font-size: 14px;
        font-weight: 600;
        border: 1px solid #002E62;
        box-shadow: 0px 1px 1px #161D251A;
        background-color: #fff;
        border-radius: 4px;
        padding: 10px 16px;
        cursor: pointer;
        transition: 0.4s linear;
        &:hover{
            background-color: #002E62;
            color: #fff;
        }
    }
}


.bittom_btn_div {
    text-align: center;
    margin-bottom: 100px;   
    &.havetwobtns{
        .borderbtn{
            margin: 3px 5px;
        }
    }
    
}
.bottom_padd{
    padding: 0 20px 20px;
    text-align: center;
    &.verticlehavetwobtns{
        .borderbtn, .bluebtn{
            margin: 10px 0;
        }
    }
}
.borderbtn {
    color: #273E59;
    font-size: 14px;
    font-weight: 700;
    border: 1px solid #273E59;
    box-shadow: 0px 1px 1px #161d251a;
    background-color: #fff;
    border-radius: 4px;
    padding: 15px 22px;
    cursor: pointer;
    transition: 0.4s linear;
    font-family: 'Roboto';
    &:hover{
        background-color: #273E59;
        color: #fff;
    }
}
.bluebtn{
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    border: 1px solid #273E59;
    box-shadow: 0px 1px 1px #161d251a;
    background-color: #273E59;
    border-radius: 4px;
    padding: 15px 22px;
    cursor: pointer;
    transition: 0.4s linear;
    font-family: 'Roboto';
    &:hover{
        background-color: #fff;
        color: #273E59;
    }
}
.borderbtn, .bluebtn{
    &.bigbtn{
        font-size: 16px;
        min-width: 195px;
    }
}

.schedule_profile_box {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .schedule_profileright {
        width: 105px;
        .borderbtn {
            padding: 9px 12px;
            font-weight: 500;
        }
    }
    .schedule_profileleft {
        width: calc(100% - 105px);
    }
    .schedule_profilebottom {
        width: 100%;
        padding-left: 68px;
        .scheduled_datetime {
            font-size: 16px;
            color: #333;
            font-weight: 500;
            align-items: flex-start !important;
            img {
                margin-right: 10px !important;
            }
        }
    }
}

.profile_wrapper {
    display: flex;
    align-items: center;
    .profile_img {
        width: 56px;
        height: 56px;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 100%;
            overflow: hidden;
        }
    }
    .profiledetail {
        width: calc(100% - 56px);
        padding-left: 15px;
        .name {
            margin: 0 0 8px 0;
            padding: 0;
            color: #333333;
            font-size: 22px;
            font-weight: 400;
            line-height: 1.2;
            font-family: 'Roboto';
        }
        .posi {
            color: #747E8B;
            font-size: 12px;
            padding: 0;
            margin: 0;
        }
    }
}
.edit_securityform{
    .form_fix_width{
        width: 100%!important;
    }
}

.utility_boxr.ic_summary img {
    padding: 5px;
}

@media (max-width: 991px){
    .summaryHeader {
        h3 {
            font-size: 24px;
        }
    }
    .summarybox {
        .summarybox_head .summaryHeader {
            font-size: 20px;
        }
        .summarybox_body .summaryContent .summaryContent_r .plan_detail .palnvalue {
            font-size: 40px;
        }
    }
}

@media (max-width: 767px){
    .greybg {
        margin-bottom: -52px;
    }
    .summaryHeader {
        h3 {
            font-size: 22px;
        }
        p {
            br {
                display: none;
            }
        }
    }
    
    
    .summarybox{
        .summarybox_head {
            padding: 15px;
            .summaryHeader {
                font-size: 18px;
            }
        }
        .summarybox_body {
            padding: 15px;
            .call_bar {
                padding: 10px 15px;
                span {
                    text-align: right;
                    a {
                        white-space: nowrap;
                    }
                }
            }
        }
        .content_row{
            .content_col33 {
                width: 50%;
                margin: 10px 0;
            }
            .content_col33:nth-child(2n+1) p {
                padding-right: 10px;
            }
        }
        .summaryfooter {
            padding: 15px;
        }
    }
    .utility_box .utility_boxr .utility_title {
        font-size: 18px;
    }
}

@media (max-width: 575px){
    .summaryHeader {
        h3 {
            font-size: 20px;
        }
    }   
    .summarybox {
        font-size: 14px;
        .summarybox_body {
            .call_bar {
                flex-direction: column;
                font-size: 13px;
                padding: 10px;
                align-items: flex-start;
                span {
                    text-align: left;
                    margin-top: 10px;
                    font-size: 13px;
                    .call_icon {
                        margin: 0;
                    }
                    a {
                        margin-left: 1px;
                    }
                }
            }
            .summaryContent {
                flex-wrap: wrap;
                .summaryContent_l {
                    width: 100%;
                    margin-bottom: 15px;
                }
                .summaryContent_m, .summaryContent_r{
                    width: 50%;
                }
                .summaryContent_r .plan_detail{
                    .palnvalue {
                        font-size: 34px;
                    }
                    span {
                        font-size: 16px;
                    }
                }
            }
        }
        .lab_val {
            margin-bottom: 10px;
            font-size: 14px;
        }
    }
    .utility_box {
        flex-direction: column;
        .utility_boxl {
            margin: 0 auto;
        }
        .utility_boxr {
            width: 100%;
        }
        .utility_boxr > div {
            padding: 15px;
        }
    }

    .schedule_profile_box{
        .schedule_profileleft,.schedule_profileright{
            width: 100%;
        }
        .schedule_profileright {
            margin-top: 15px;
            order: 3;
            text-align: center;
        }
        .schedule_profilebottom {
            padding-left: 0;
            margin-top: 15px;
            .scheduled_datetime {
                font-size: 14px;
                margin: 10px 0;
            }
        }
    } 
    .profile_wrapper .profiledetail{
        padding-left: 10px;
        .name {
            font-size: 18px;
        }
    } 
}