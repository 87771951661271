.ElectricityClassMainCard {
    width: 49%;
    border: 0.2px solid;
    border-radius: 4px;
    @media (max-width: 768px) {
        width: 100%;
        margin-top: 10px;
    }

    .ElectricityGasClass {
        // width: 100%;
        // display: flex;
        // flex-direction: column;
        padding: 14px 20px 25px 20px;
        .ElectricityGasHead {
            font-size: 20px;
            font-family: 'Roboto';
            font-weight: 500;
            padding-bottom: 10px;
            color: #fff;
            display: flex;
            align-items: center;
            .iconMarginRight {
                padding-right: 10px;
            }
        }
        .ElectricityGasInfo {
            font-size: 16px;
            font-family: 'Roboto';
            padding: 0px 0px 20px 28px;
            color: #fff;
        }
        .ElectricityGasProgressButton {
            display: flex;
            justify-content: space-between;
            align-items: center;
            @media (max-width: 1024px) {
                flex-direction: column;
                align-items: flex-start;
            }
            .buttonElectric {
                @media (max-width: 1024px) {
                    margin: 10px 0px 0px 27px;
                }
            }
        }
        .linearStartingProgress {
            width: 40%;
            padding: 0px 0px 0px 28px;
            font-size: 14px;
            font-family: 'Roboto';
            font-weight: bold;
            color: #fff;
            @media (max-width: 1024px) {
                width: 90%;
            }

            .MuiLinearProgress-root {
                height: 15px;
                border-radius: 10px;
                margin-bottom: 10px;
            }
            .MuiLinearProgress-colorPrimary {
                background-color: #e0e0e0;
            }
            .MuiLinearProgress-barColorPrimary {
                background-color: #00d56d;
            }
            .electricityGasUsed {
                font-size: 14px;
                font-family: 'Roboto';
                font-weight: bold;
                color: #fff;
                @media (max-width: 1024px) {
                    margin-bottom: 10px;
                }
            }
        }
    }
}
