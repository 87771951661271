.navigation {
    display: flex;
    flex-direction: column;
    border: 0.5px solid #e0e0e0;
    border-radius: 4px;
    box-shadow: 0px 3px 5px #00000029;
    width: 280px;
    height: auto;
}

.active {
    color: lime;
    font-weight: 600;
}
