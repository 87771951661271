.schedule-a-call-heading,
.schedule-a-call-subheading,
.schedule-a-call-details-heading {
    color: #333333;
    font-size: 24px;
    font-weight: bold;
    font-family: 'Roboto';
}

.schedule-a-call-subheading,
.schedule-a-call-or {
    font-size: 16px;
    font-weight: normal;
    padding-top: 6px;
}

.schedule-a-call-row,
.schedule-a-call-row-wrapper {
    display: flex;
    flex-direction: row;
}

.schedule-a-call-row-wrapper {
    padding-top: 22px;
}

.schedule-a-call-or {
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.schedule-a-call-phone {
    color: #0066f5;
    font-size: 16px;
    font-weight: bold;
    font-family: 'Roboto';
    margin-top: 6px;
}

.schedule-a-call-container {
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    width: 100%;
}

.schedule-a-call-divider {
    border: 1px solid #e2e2e2;
}

.schedule-a-call-details-heading {
    font-size: 14px;
    padding-top: 22px;
}

.schedule-a-call-footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.schedule-a-call-personal-details {
    padding-top: 15px;
    justify-content: space-between;
}

.schedule-a-call-select {
    width: 25%;
}
