.setReminder {
    .MuiCheckbox-colorSecondary.Mui-checked {
        color: #006dff !important;
    }
    position: absolute;
    top: 15%;
    width: 400px;
    left: 45%;
    background-color: #fff;
    @media (max-width: 1920px) {
        left: 40%;
    }

    @media (max-width: 1600px) {
        left: 35%;
    }

    @media (max-width: 1024px) {
        left: 30%;
    }
    @media (max-width: 768px) {
        left: 25%;
    }
    @media (max-width: 600px) {
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
    }

    .setReminderContent {
        padding: 20px;
        .setReminderContentClose {
            display: flex;
            justify-content: flex-end;
            cursor: pointer;
        }
        .setReminderContentHead {
            display: flex;
            padding: 20px 20px 0px 20px;
            justify-content: center;
            .setReminderHeading {
                color: #333333;
                font-size: 18px;
                font-family: Roboto;
                font-weight: bold;
            }
        }

        .setReminderContentBody {
            padding: 20px;
            .setReminderContentBody-checkbox-wrapper {
                padding-top: 10px;
                padding-bottom: 5px;
                width: 100%;
                .setReminderContentBody-checkbox-heading {
                    color: #424242;
                    font-weight: bold;
                    font-family: Roboto;
                    font-size: 12px;
                }
            }
            .setReminderContentBody-btn {
                display: flex;
                justify-content: center;
            }

            .setReminderContentBody-row {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
            }

            .setReminder-email-addPeople {
                color: #006dff !important;
                font-size: 12px;
                font-family: Roboto;
                display: flex;
                align-items: center;
                cursor: pointer;
            }
            .setReminder-email-addPeopleDouglas {
                color: #009cbd !important;
            }
            .setReminder-email-removePeople {
                color: #ff3200;
                font-size: 12px;
                font-family: Roboto;
                display: flex;
                align-items: center;
                cursor: pointer;
            }

            .setReminderContentBody-delReminder {
                color: #ff3200;
                font-size: 14px;
                font-family: Roboto;
                font-weight: bold;
                display: flex;
                justify-content: center;
                padding-top: 12px;
                cursor: pointer;
            }

            .reminderModalTextField {
                width: 100%;
                margin-bottom: 15px;
            }

            .reminderModalTextField .MuiFilledInput-root {
                background-color: #ffffff !important;
                border: 1px solid #bebebe;
                border-radius: 5px;
                // height: 50px;
            }

            .reminderModalTextField .MuiFilledInput-underline:after,
            .reminderModalTextField .MuiFilledInput-underline:before {
                border-bottom: none !important;
            }
            .reminderModalTextField .MuiFilledInput-root.Mui-focused {
                background-color: #fff;
                border: 1px solid #006df5;
                border-radius: 5px;
            }
            .reminderModalTextField .MuiFormLabel-root.Mui-focused {
                color: #006df5 !important;
                // height: 50px;
            }

            .reminderModalTextField .MuiInputLabel-filled.MuiInputLabel-shrink {
                color: #006dff !important;
                z-index: 2 !important;
            }
            .reminderModalTextFieldDouglas .MuiInputLabel-filled.MuiInputLabel-shrink {
                color: #009cbd !important;
                z-index: 2 !important;
            }
        }

        .setReminderGetMovingQuotes {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .setReminder-upperContentBlueChecklist {
                border: 1px solid #009cbd;
                background-color: #f1f9fb;
                color: #009cbd;
                border-radius: 15px;
                padding: 4px 7px;
                font-size: 12px;

                @media (max-width: 600px) {
                    margin-left: 0px;
                    margin-top: 10px;
                }
            }
        }
        .setReminderGetMovingQuotesText {
            font-size: 18px;
            color: #08192f;
            font-family: Roboto;
            text-align: left;
            padding-top: 12px;
        }

        .setReminderGetMovingQuotesContent {
            color: #6b6c6f;
            font-size: 14px;
            font-family: Roboto;
            text-align: left;
            padding-top: 4px;
        }

        .setReminderGetMovingQuotesContentBtnWrapper {
            padding-top: 8px;
            padding-bottom: 11px;
            border-bottom: 1px solid #e8e8e8;
        }

        .setReminderGetMovingQuotesContentFooter {
            display: flex;
            justify-content: flex-end;

            .setReminderGetMovingQuotesContentFooterContent {
                display: flex;
                flex-direction: row;
                padding-top: 11px;

                .setReminderGetMovingQuotesContentFooterContentText {
                    padding-left: 5px;
                    color: #6b6c6f;
                    font-size: 14px;
                    font-family: Roboto;
                    display: flex;
                    align-items: center;
                }
            }
        }
    }
}
.setReminderDouglas {
    .MuiCheckbox-colorSecondary.Mui-checked {
        color: #009cbd !important;
    }
    .MuiInputLabel-filled.MuiInputLabel-shrink {
        z-index: 1 !important;
        color: #100b28;
    }
    input {
        // background-color: #fff;
        // border: 1px solid #100b28 !important;
        border-radius: 0px !important;
        color: #323744;
    }

    // .setReminder-email-addPeople {
    //     color: #006df5 !important;
    // }
    // .setReminder-email-addPeople-Douglas {
    //     color: #009cbd !important;
    // }
    .reminderModalTextFieldDouglas .MuiFilledInput-root.Mui-focused {
        border: 1px solid #009cbd !important;
    }
    .reminderModalTextFieldDouglas .MuiFormLabel-root.Mui-focused {
        color: #009cbd !important;
        // height: 50px;
    }
    .reminderModalTextFieldDouglas .MuiInputLabel-filled.MuiInputLabel-shrink {
        color: #009cbd !important;
        z-index: 2 !important;
    }
    .setReminder-upperContentBlueChecklist {
        border: 1px solid #006df5 !important;
        color: #006df5 !important;
    }
    .setReminder-upperContentBlueChecklistDouglas {
        border: 1px solid #009cbd !important;
        color: #009cbd !important;
    }
}
