.insuranceCallCardMain {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;

    @media (max-width: 1024px) {
        flex-direction: column;
        .callAquoteCardButtonMobile {
            width: 100%;
        }
    }

    .ButtonInsuranceCard {
        width: 160px;
        @media (max-width: 1024px) {
            width: 100%;
        }
        @media (max-width: 320px) {
            width: 72vw;
        }
    }
}

.insuranceCallCardMain .LogoDiv img {
    width: 250px;
    height: 80px;
}

// .cardLogo {
//   width: 50%;
// }

.callFlexDiv {
    // width: 50%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 1024px) {
        margin-top: 10px;
        flex-direction: column;
    }
}

.mobileText {
    font-size: 22px;
    color: #333333;
    margin-left: 15px;
}

.orText {
    font-size: 16px;
    color: #333333;
    margin-left: 15px;
}
