.main-head-title {
    margin: 30px 0px 0px 80px;
    @media (max-width: 1366px) {
        margin: 30px 0px 0px 60px;
    }

    @media (max-width: 600px) {
        margin: 30px 0px 0px 10px;
    }
}
.Para-title {
    color: #bebebe;
}

.path-title {
    display: flex;
    // margin-top: -25px;
    font-size: 12px;
}

.heading-title {
    font-size: 24px;
    font-weight: 600;
    font-family: sans-serif;
}
.title-mobile-top {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 0;
    padding: 10px 60px;
    align-items: center;
    @media (min-width: 769px) {
        display: none;
    }
    @media (max-width: 600px) {
        padding: 10px;
        text-align: center;
    }
}
.btntitle {
    width: 100%;
    padding: 0 60px;
    @media (max-width: 600px) {
        padding: 0 10px;
    }
}
.title-home-img {
    width: 95vw;
    height: max-content;
}
.title-bottom-divMainRemoveDouglas {
    @media (max-width: 600px) {
        margin-top: 180px !important;
    }
    @media (max-width: 320px) {
        margin-top: 200px !important;
    }
}
.title-main-div {
    background-image: linear-gradient(white, #ededed);
    padding: 0 80px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    // height: 300px;
    @media (max-width: 1366px) {
        padding: 0 60px;
    }
    @media (max-width: 768px) {
        display: none;
    }
}
.title-main-div1 {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    // align-items: center;
}
.title-bottom-div {
    margin: 30px 80px 0 80px;
    display: flex;
    // justify-content: space-between;

    @media (max-width: 1366px) {
        margin: 30px 60px 0 60px;
    }
    @media (max-width: 768px) {
        flex-direction: column;
    }
    @media (max-width: 600px) {
        flex-direction: column;
        margin: 30px 10px 0 10px;
    }
}
.title-bottom-div1 {
    width: 35%;
    margin-right: 100px;
    text-align: left;
    @media (max-width: 1024px) {
        margin-right: 50px;
    }
    @media (max-width: 768px) {
        width: 100%;
    }
}
.title-bottom-div2 {
    width: 56%;
    border: 1px solid #e2e2e2;
    border-radius: 7px;
    @media (max-width: 768px) {
        width: 100%;
    }
}
.title-bottom-div3 {
    width: 53%;
    margin-bottom: 90px;
    @media (max-width: 768px) {
        width: 95vw;
        margin-bottom: 20px;
    }
}
.title-enquireCardTitle {
    // height: 50px;
    // background-color: #273e59;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 25px;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    border-radius: 7px 7px 0 0;
}
.title-enquireCardBody {
    padding: 30px;
    @media (max-width: 768px) {
        padding: 15px;
    }
}
.title-formRow {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    @media (max-width: 768px) {
        flex-direction: column;
        margin-bottom: 0px;
    }
}
.inputFieldsDouglasEllimanTitle {
    .title-input .MuiInputLabel-filled.MuiInputLabel-shrink {
        z-index: 1 !important;
        color: #100b28;
    }
    .title-input input {
        background-color: #fff;
        border: 1px solid #100b28;
        border-radius: 0px !important;
        color: #323744;
    }
    .MuiFormGroup-root.titleRadio .MuiRadio-colorSecondary.Mui-checked {
        color: #006dff !important;
    }
    .MuiFormGroup-root.titleRadioD .MuiRadio-colorSecondary.Mui-checked {
        color: #009cbd !important;
    }
}
.title-formRow1 {
    width: 48%;
    @media (max-width: 768px) {
        width: 100%;
        margin-bottom: 20px;
    }
}
.title-input input {
    background-color: #fff;
    border: 1px solid #bebebe;
    border-radius: 5px;
}
.title-input .MuiFilledInput-underline:before,
.title-input .MuiFilledInput-underline::after {
    border-bottom: none !important;
}
.title-input input:focus {
    background-color: #fff;
    border: 1px solid #006dff !important;
    border-radius: 5px;
}
.title-inputD input:focus {
    background-color: #fff;
    border: 1px solid #009cbd !important;
    border-radius: 5px;
}

.title-input .MuiInputLabel-filled.MuiInputLabel-shrink.Mui-focused {
    color: #006dff !important;
}
.title-inputD .MuiInputLabel-filled.MuiInputLabel-shrink.Mui-focused {
    color: #009cbd !important;
}
.title-input .MuiInputLabel-filled.MuiInputLabel-shrink {
    z-index: 1 !important;
    color: #6b6c6f;
}
.title-inputD .MuiFormLabel-root.Mui-focused {
    color: #009cbd !important;
}
.title-input .MuiFormLabel-root.Mui-focused {
    color: #006dff !important;
}
.title-formRow .MuiFormControl-root {
    width: 100% !important;
}
.title-input .Mui-error input {
    border-color: #d92f25;
}
.MuiFormGroup-root.titleRadio {
    flex-direction: row !important;
    @media (max-width: 768px) {
        flex-direction: column !important;
    }
}
.MuiFormGroup-root.titleRadio .MuiRadio-colorSecondary.Mui-checked {
    color: #006dff !important;
}
.MuiFormGroup-root.titleRadioD .MuiRadio-colorSecondary.Mui-checked {
    color: #009cbd !important;
}

.titleRadio .MuiFormControlLabel-root {
    margin: 15px 60px 15px -10px;
    @media (max-width: 768px) {
        margin: 10px 0 0 -10px;
    }
}
.titleRadio1 .MuiTypography-body1 {
    color: #333;
    font-weight: 900;
    font-size: 14px;
}
.title-last-div {
    margin: 40px 80px;
    @media (max-width: 1366px) {
        margin: 40px 60px;
    }

    @media (max-width: 600px) {
        margin: 40px 10px;
    }
}
.title-check {
    width: 70px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #00d56d;
    border-radius: 50%;
    margin-bottom: 20px;
}
.title-pleasenote {
    margin-bottom: 15px;
    color: #333;
    font-weight: 800;
    font-size: 16px;
    margin-top: 10px;
}
.title-enquireCardBody .error {
    font-size: 12px;
    color: red;
}

.title-detail {
    display: flex;
    font-size: 18px;
    margin: 0 0 10px 0;
    .title-detail1 {
        display: flex;
        justify-content: center;
        margin-right: 30px;
    }
    .title-detail2 {
        display: flex;
        justify-content: center;
    }
    @media (max-width: 960px) {
        flex-direction: column;
        .title-detail1 {
            align-items: center;
            justify-content: flex-start;
            margin-right: 0px;
            margin-bottom: 8px;
        }
        .title-detail2 {
            align-items: center;
            justify-content: flex-start;
        }
    }
    @media (max-width: 768px) {
        font-size: 14px;
    }
}
.title-detail3 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 18px;
    margin: 0 0 10px 0;

    @media (max-width: 768px) {
        font-size: 14px;
    }
}

.title-submitbtn {
    display: flex;
    justify-content: center;
    @media (max-width: 768px) {
        display: none;
    }
}
.title-submitbtn1 {
    display: flex;
    justify-content: center;
    margin: 20px 0 0 0;
    @media (min-width: 769px) {
        display: none;
    }
}
.title-radio-text {
    font-size: 14px;
    color: #424242;
    font-weight: 800;
    margin-top: 40px;
    @media (max-width: 768px) {
        font-size: 16px;
        margin-top: 20px;
    }
}
.title-submitted-query {
    font-weight: 800;
    font-size: 36px;
    color: #333;
    margin-bottom: 15px;
    @media (max-width: 768px) {
        font-size: 21px;
    }
}
.title-submitted-query1 {
    margin-bottom: 40px;
    color: #6b6c6f;
    font-size: 16px;
    @media (max-width: 768px) {
        font-size: 14px;
    }
}
