.sideImageNew {
    padding: 5px;
}

.cardMainTrusted {
    font-family: 'Roboto', sans-serif;
    padding: 10px;

    .cardLeftContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        @media (max-width: 425px) {
            max-width: 100%;
            flex-direction: column;
        }

        .imageSection {
            min-height: 60px;
            margin-left: 10px;
            margin-right: 10px;
            min-width: 60px;

            img {
                margin-top: 8px;
                max-height: 3.6rem;
                max-width: 3.6rem;
                @media (max-width: 768px) {
                    max-width: 100%;
                }
            }

        }

        .imageTextSection {
            background-color: #FFF0C8;
            height: 60px;
            border-radius: 6px;
            text-align: center;

            span {
                font-size: 2.5rem;
                font-weight: bold;
                text-transform: uppercase;
            }

            margin-left: 10px;
            margin-right: 10px;
            min-width: 60px;
        }

        .cardLeftContent {
            display: flex;
            flex-direction: row;

            .cardDetails {
                .cardTitle {
                    font-size: 1.375rem;
                    font-weight: bold;
                }

                .cardSubDetails {
                    .cardSubGroup {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        font-size: 1.0rem;
                        margin-bottom: 6px;

                        .img {
                            height: 16px;
                            margin-right: 5px;
                            color: #707070;
                            width:16px;
                        }

                        span {
                            font-size: 0.875rem;
                            @media (max-width: 425px) {
                                font-size: 0.8rem;                                
                            }
                        }
                    }
                }
            }
        }

        .cardRightContent {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
            font-size: 12px;
            margin-right: 10px;

            @media (max-width: 425px) {
                flex-direction: row;
            }

            .cardRightTopContent {
                display: flex;
                flex-direction: column;
                text-align: center;

                img {
                    margin-left: 10px;
                    max-height: 1.875rem;
                    max-width: 8rem;
                }

                .agentType {
                    margin-left: 10px;

                    span {
                        font-weight: bold;
                        color: #002E62
                    }
                }

                @media (max-width: 1024px) {
                    flex-direction: column;
                }

                @media (max-width: 768px) {
                    flex-direction: column;
                    text-align: center;
                }
            }

            .cardRightBottom {
                margin-top: 30px;
                margin-left: auto;
                margin-right: 0;
                .img {
                    width: 24px;
                }
                @media (max-width: 768px) {
                    margin-left: 10px;
                    margin-right: 0;
                    text-align: center;
                }
                @media (max-width: 425px) {
                    margin-top: 10px;
                }
            }

        }
    }
}

.right-div {
    width: 90%;
}

.div-1-trusted {
    display: flex;
    height: 46%;
    justify-content: space-between;

    @media (max-width: 768px) {
        height: auto;
    }
}

.image-trusted {
    width: 20%;
}

.verified-trusted-new1 {
    width: 20%;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    @media (max-width: 1024px) {
        width: 20%;
        position: absolute;
        right: 0px;
        padding-top: 20px;
        padding-bottom: 50%
    }
}

.bottom-card-detail {
    font-weight: bold;
}

.sideTag {
    height: 100%;
}