.thankyou-card-main {
    @media (max-width: 425px) {
        width: 355px !important;
    }
    @media (max-width: 375px) {
        width: 300px !important;
    }
}

.thankyou-image-div {
    margin: 0 40px 0 20px;
    width: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.company {
    @media (max-width: 425px) {
        margin-left: 5px;
        font-size: 12px;
    }
}

.company-name {
    @media (max-width: 425px) {
        margin-left: 5px;
        font-size: 12px;
    }
}
