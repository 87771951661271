.facility-card-main {
    display: flex;

    @media (max-width: 425px) {
        flex-wrap: wrap;
        justify-content: center;
    }
}
.facility-card-mainDouglas {

    .rating-button {
        color: #009cbd !important;
        border: 1px solid #009cbd !important;
    }
}

.facilityIconDiv {
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 768px) {
        width: 45%;
    }

    @media (max-width: 425px) {
        width: 100%;
    }
}

.facilityIconDiv img {
    // width: 260px;
    width: 100%;

    // @media (max-width: 1440px) {
    //     width: 240px;
    // }
    // @media (max-width: 1366px) {
    //     width: 200px;
    // }
    // @media (max-width: 1040px) {
    //     width: 180px;
    // }
    @media (max-width: 768px) {
        width: 100%;
        height: 100%;
    }
    @media (max-width: 425px) {
        width: 100%;
        border-radius: 8px;
        height: auto;
    }
}

.facility-content-div {
    width: 80%;
    display: flex;
    justify-content: space-between;
    text-align: left;
    padding: 0 0 0 20px;

    @media (max-width: 768px) {
        width: 55%;
        flex-wrap: wrap;
        padding: 0 0 0 20px;
    }

    @media (max-width: 425px) {
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
        padding: 0 10px;
    }
}

.facilityContent {
    @media (max-width: 425px) {
        text-align: center;
    }
}

.facilityButtonDiv {
    display: flex;
    align-items: center;

    @media (max-width: 768px) {
        margin-top: 10px;
    }

    @media (max-width: 425px) {
        width: 100%;
        margin-top: 0;
    }
}

.facility-content-div .rating-button {
    margin: 0 10px 0 0;
    border: 1px solid #009cbd !important;
    color: #009cbd !important;
    background: #f1f9fb;
    border-radius: 20px;
    padding: 5px 10px 5px 5px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
}
.facility-content-div .rating-buttonDoug {
    margin: 0 10px 0 0;
    border: 1px solid #006dff !important;
    color: #006dff !important;
    background: #f1f9fb;
    border-radius: 20px;
    padding: 5px 10px 5px 5px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
}

.facility-content-div .verified-review {
    margin: 0 5px;
    margin-top: 2px;
}
.viewFacilityDetails {
    font-size: 14px;
    text-decoration: underline;
    width: fit-content;
    margin-top: 15px;

    @media (max-width: 425px) {
        width: 100%;
        margin-bottom: 15px;
    }
}

.facilitySubHeading {
    font-size: 16px;
    color: #6b6c6f;
    margin: 15px 0;

    @media (max-width: 425px) {
        line-height: 22px;
    }
}

.facilityContent .moving-main-div {
    @media (max-width: 425px) {
        justify-content: center;
        align-items: center;
    }
}

.facility-content-div .movers-heading {
    @media (max-width: 425px) {
        margin-top: 20px;
        font-size: 22px;
        line-height: 30px;
        width: 100%;
    }
}

.viewAllPriceButton {
    width: 150px !important;
    height: 50px !important;

    @media (max-width: 425px) {
        width: 98% !important;
        height: 50px !important;
    }
}

.facilityContent .rating-section {
    margin-left: 0;
}

.storageFacilityDiv {
    margin-top: 30px;

    @media (max-width: 425px) {
        margin-top: 10px;
    }
}

.facility-card-main .moving-main-div {
    align-items: center;
}
