.MovingNextCardContentDiv {
    width: 60%;
    margin-left: 20px;
    justify-content: center;
    align-items: center;
    padding-top: 10px;

    @media (max-width: 425px) {
        margin-left: 15px;
    }
}

.MovingNextCardImgDiv {
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.movingNextCardTitle {
    font-size: 18px;
    font-weight: bold;
    margin: 10px 0px 5px 0;

    @media (max-width: 1366px) {
        font-size: 16px;
        margin: 5px 0px;
    }
    @media (max-width: 425px) {
        font-size: 15px;
    }
}

.movingNextCardImage {
    @media (max-width: 768px) {
        margin-right: 20px;
        margin-left: -20px;
    }
    @media (max-width: 500px) {
        margin-right: unset;
    }

    @media (max-width: 375px) {
        width: 80px;
    }
}

.movingNextCardSubTitle {
    font-size: 14px;
    height: 50px;
    @media (max-width: 1366px) {
        font-size: 12px;
        margin: 5px 0px;
    }
}
.main-card-class {
    border-radius: 5px;
    background-color: #fff;

    @media (max-width: 1366px) {
        height: 120px !important;
    }

    @media (max-width: 768px) {
        width: 100% !important;
        height: fit-content !important;
    }

    @media (max-width: 425px) {
        // height:150px !important;
    }

    @media (max-width: 375px) {
        // height:150px !important;
        width: 100% !important;
    }
}

.main-card-class:hover {
    box-shadow: 0 1px 1px #161d251a !important;
}

.wrapper-card {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 100%;
    //  margin-top: 10px ;

    @media (max-width: 425px) {
        margin-top: 10px;
    }
}

.movingNextCardButton {
    @media (max-width: 425px) {
        height: 40px !important;
        width: 140px !important;
        margin-top: 10px !important;
    }

    @media (max-width: 375px) {
        height: 35px !important;
        width: 120px !important;
        margin-top: 5px !important;
    }
}

.mobile-svg-div svg {
    @media (max-width: 600px) {
        width: 100% !important;
        height: 100% !important;
    }
}
