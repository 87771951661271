.modalMainFlexDiv {
    display: flex;
    justify-content: space-between;

    .modalMainLeftDiv {
        width: 35%;
        background: linear-gradient(#eeeeee, #ffffff);
        height: 100vh;
        max-height: 100vh;
        position: fixed;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .imageDiv {
            img {
                max-width: 100%;
            }
        }

        .leftTitle {
            font-size: 24px;
            line-height: 32px;
            font-weight: bold;
            margin: 20px 0;
            color: #333333;
        }
        .leftDescription {
            font-size: 16px;
            line-height: 22px;
            margin: 0 30px;
            color: #333333;
        }

        @media (max-width: 600px) {
            display: none;
        }
    }
}
.HomeOwnerDashboardModalMainDiv {
    margin-left: 35%;
    width: 70%;
    // text-align: center;
    padding: 30px 70px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 600px) {
        padding: 30px 10px 70px;
    }

    .HomeOwnerDashboardModalTitle {
        font-size: 30px;
        line-height: 32px;
        font-weight: bold;
        color: #333333;
        margin-bottom: 20px;

        @media (max-width: 768px) {
            font-size: 22px;
        }
    }

    .HomeOwnerDashboardformDiv {
        width: 90%;
        background: #ffffff;
        padding: 25px 50px 25px 27px;
        margin: 25px 0 0;

        .formTitleFlexDiv {
            // display: flex;
            display: flex;
            justify-content: space-between;
            margin: 0px 0 20px;

            .titleServiceName {
                font-size: 18px;
                line-height: 28px;
                color: #6b6c6f;
                font-weight: 700;
            }

            // @media (max-width: 600px) {
            //     display: none;
            // }
        }
        .imnotsure {
            font-size: 14px;
            line-height: 18px;
            color: #0066f5;
            cursor: pointer;
        }
        .inputFlexDiv1 {
            display: flex;
            // justify-content: space-between;
            margin: 15px 0px;

            .MuiRadio-colorSecondary.Mui-checked {
                color: #0066f5;
            }

            .inputFlexDiv2 {
                width: 45%;
                margin-right: 30px;
                display: flex;
                align-items: center;

                .css-2b097c-container {
                    width: 100%;
                }
                .react-select .select-state__control {
                    min-height: 50px;
                }

                .inputDiv1 {
                    width: 80%;
                    .MuiFormControl-root {
                        width: 100%;
                    }

                    @media (max-width: 600px) {
                        width: 76%;
                    }
                }

                .inputsupportText {
                    margin-left: 20px;
                    margin-top: 19px;
                    font-size: 16px;
                    line-height: 22px;
                    color: #6b6c6f;
                    font-weight: 700;
                }

                @media (max-width: 600px) {
                    width: 100%;
                    margin: 15px 0;
                }
            }

            .inputDiv2 {
                width: 60%;

                .internetServiceCardOuterDiv {
                    border: 1px solid;
                    padding: 10px 20px;
                    border-radius: 4px;
                    width: auto;
                    margin-right: 20px;

                    // @media (max-width: 768px) {
                    //     width: 70%;
                    // }
                    @media (max-width: 600px) {
                        // width: 23%;
                        display: inline-block;
                        margin-right: 10px !important;
                        padding: 10px;
                    }
                    @media (max-width: 400px) {
                        margin-right: 7px !important;
                    }
                    @media (max-width: 350px) {
                        margin-right: 5px !important;
                        // width: 30%;
                        margin-bottom: 5px;
                    }
                }

                .internetServiceCard {
                    display: flex;
                    align-items: center;
                    font-size: 16px;
                    line-height: 22px;
                    // color: #333333;

                    .internetIcon {
                        margin-right: 15px;
                    }

                    @media (max-width: 600px) {
                        font-size: 12px;

                        .internetIcon {
                            margin-right: 8px;
                        }
                    }
                }

                @media (max-width: 600px) {
                    width: 100%;
                }
            }
            @media (max-width: 600px) {
                display: block;
                .inputDiv1 {
                    width: 100%;

                    .css-2b097c-container {
                        width: 100%;
                    }
                }
            }
        }

        @media (max-width: 768px) {
            width: 80%;
            padding: 25px 25px 25px 14px;
            margin: 10px 0;
        }
        @media (max-width: 600px) {
            width: 87%;
            padding: 25px 0px 0px 14px;
            margin-bottom: 0;
        }
    }
    .HomeOwnerDashboardModalButtonDiv {
        width: 30%;
        margin-top: 25px;

        .HomeOwnerDashboardModalButton {
            width: 100% !important;
        }

        @media (max-width: 600px) {
            display: none;
        }
    }

    .stepperHeaderMainFlexDiv {
        display: flex;
        align-items: center;
        width: 100%;
        .circleNumber {
            color: #ffffff;
            border-radius: 50%;
            width: 30px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-bottom: 1px;
            margin-right: 20px;
        }
        .steppertitle {
            font-size: 14px;
            line-height: 14px;
            margin-bottom: 5px;
        }
        .steppersubtitle {
            font-size: 12px;
            line-height: 16px;
            color: #747e8b;
        }
        @media (max-width: 600px) {
            width: 100%;
            display: flex;
            align-items: center;
        }
    }

    .skipButton {
        font-size: 14px;
        line-height: 18px;
        color: #0066f5;
        margin-right: 30px;
        margin-left: 20px;
        cursor: pointer;
    }

    .DashboardUtilitiesSubscriptionFormButtonDiv {
        display: none;
        @media (max-width: 600px) {
            display: none;
            // display: flex;
            flex-direction: column;
            align-items: center;
            margin: 20px 0;

            .DashboardUtilitiesSubscriptionFormButton {
                width: 100% !important;
                margin-bottom: 20px !important;
            }
        }
    }

    .DashboardUtilitiesSubscriptionFormButton {
        @media (max-width: 500px) {
            width: 200px !important;
            margin: 10px auto 30px auto !important;
            display: flex;
            justify-content: center;
            align-items: center;
            align-self: center;
        }
    }
    .lineDiv {
        // display: none;
        display: block;
        border: 1px solid #ebebeb;
        width: 100%;
        margin: 10px 0;
        @media (max-width: 600px) {
            display: block;
            border: 1px solid #ebebeb;
            width: 100%;
            margin: 10px 0;
        }
    }

    .checkIconForm {
        fill: #00d56d;
        font-size: 34px;
        margin-right: 15px;
    }

    .error {
        color: red;
        height: 20px;
    }

    @media (max-width: 600px) {
        width: 100%;
        margin-left: 0%;
    }
}
