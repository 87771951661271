.bestForTitle {
    height: 14px;
    font-size: 14px;
    font-weight: 800;
    color: #333;
    opacity: 1;
    margin: 15px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;

    @media (max-width: 1024px) and (min-width: 780px) {
        font-size: 12px;
        margin: 7px;
    }
}

.featuresFlexDivlong {
    display: flex;
    justify-content: center;
    margin: 0px;

    @media (max-width: 1024px) and (min-width: 780px) {
        flex-wrap: wrap;
    }
}

.featureDiv {
    display: flex;
    color: #009cbd;
    border: 1px solid #009cbd;
    margin: 0 5px;
    padding: 5px 10px 5px 5px;
    border-radius: 25px;
    font-size: 14px;
    align-items: center;
    background: #f1f9fb;
    @media (max-width: 1024px) and (min-width: 780px) {
        font-size: 12px;
        margin: 5px;
    }
    @media (max-width: 600px) {
        margin: 0 5px;
    }
}

.featureDivnoDoug {
    display: flex;
    color: #006dff !important;
    border: 1px solid #006dff !important;
    margin: 0 5px;
    padding: 5px 10px 5px 5px;
    border-radius: 25px;
    font-size: 14px;
    align-items: center;
    background: #f1f9fb;
    @media (max-width: 1024px) and (min-width: 780px) {
        font-size: 12px;
        margin: 5px;
    }
    @media (max-width: 600px) {
        margin: 0 5px;
    }
}

.cardTitle {
    height: 14px;
    font-size: 16px;
    color: #333;
    opacity: 1;
    margin: 15px 15px;
    // @media (max-width: 1440px) {
    //     // font-size: 14px;
    //     margin: 7px;
    // }
}

.currency {
    font-size: 24px;
    margin-top: 10px;
    // @media (max-width: 1440px) {
    //     font-size: 16px;
    // }
}

.availableSpeedContent {
    font-size: 24px;
    font-weight: 800;
    margin: 25px 0 10px 0;

    // @media (max-width: 1440px) {
    //     font-size: 16px;
    //     margin: 5px;
    // }
}
.availableSpeedTitle {
    font-size: 14px;
    // margin: 10px 0;
    color: #6b6c6f;

    // @media (max-width: 1440px) {
    //     font-size: 12px;
    //     margin: 7px;
    // }
}

.planRateText {
    font-size: 14px;
    font-weight: 800;
    color: #333;
    opacity: 1;
    margin: 15px 0px;

    // @media (max-width: 1440px) {
    //     font-size: 12px;
    //     margin: 7px 0;
    // }
}

.amount {
    font-size: 48px;

    // @media (max-width: 1440px) {
    //     font-size: 30px;
    // }
}

.rate {
    font-size: 14px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    // @media (max-width: 1440px) {
    //     font-size: 12px;
    // }
}

.validFor {
    font-size: 12px;
    color: #6b6c6f;

    // @media (max-width: 1440px) {
    //     font-size: 10px;
    //     margin: 10px;
    // }
}

.rewardDiv {
    display: flex;
    text-align: center;
    justify-content: center;
    margin: 20px 0;

    // @media (max-width: 1440px) {
    //     margin: 10px;
    // }
}

.reward {
    font-size: 16px;
    color: #333333;
    padding: 5px 15px;
    text-align: center;
    width: fit-content;
    border: 1px solid #00d56d;
    border-left: 10px solid #00d56d;
    background: #dbf9ea;

    // @media (max-width: 1440px) {
    //     font-size: 13px;
    // }
}

.viewDetails {
    font-size: 14px;
    margin: 10px 0;
    margin-bottom: 25px;
    color: #009cbd;
    text-decoration: underline;

    // @media (max-width: 1440px) {
    //     font-size: 12px;
    //     margin: 7px;
    // }
}

.viewDetails a {
    color: #006dff;
}

.availableSpeedMainDiv {
    border-bottom: 1px solid #00000029;
    padding: 0 0 20px 0;

    // @media (max-width:1440px) {
    //   padding: 0px 0;
    // }
}
