.NavigationContainer{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    border-bottom: 1px solid #E0E0E0;
    cursor: pointer;
    .selectedContainer{
text-align: left;
font: normal normal bold 12px/22px Roboto;
letter-spacing: 0px;
color: #0066F5;
text-transform: capitalize;
opacity: 1;
border-bottom: 2px solid #0066F5;
padding:10px;

    }
    .notSelectedContainer{
        text-align: left;
font: normal normal normal 12px/22px Roboto;
letter-spacing: 0px;
color: #6B6C6F;
text-transform: capitalize;
opacity: 1;
padding:10px;
    }
}