@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');

@font-face {
    font-family: 'Lato';
    src: local('Lato'), url('../../Assets/font/Lato/Lato-Regular.ttf') format('ttf');
}
.landingNavbar{
        padding: 15px 80px;
        box-shadow: 0px 4px 4px #eee;
        position: sticky;
        top: 0;
        z-index: 100;
        background: #fff;
        font-family: 'Lato', sans-serif;
        /* margin-top: -180px; */
  
}
  
@media (max-width: 1366px) {
    .landingNavbar{
        padding: 15px 60px;
    }
}
@media (max-width: 600px) {
    .landingNavbar{
        padding: 15px 10px;
    }
}