.blog-main {
    // border-radius: 5px;
    border: 1px solid #dadce0;
    background-color: #fff;
    @media (max-width: 600px) {
        height: 430px !important;
    }
}

.blog-main-2 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.blog-card-wrapper {
    display: flex;
    flex-direction: column;
    //   align-items:flex-start;
    justify-content: space-between;
    width: 52%;
    padding: 10px 30px 20px 20px;

    @media (max-width: 1440px) {
        width: 45%;
    }
}

.blog-title {
    font-size: 22px;
    margin: 10px 0 0 0;
    line-height: 40px;
}
.blog-subtitle {
    font-size: 14px;
    margin: 15px 0 0 0;
    opacity: 0.7;
    line-height: 30px;
    //    border-bottom:2px solid #DADCE0;
    padding-bottom: 40px;
}
.blog-view {
    margin-top: 15px;
    color: #006dff;
    font-size: 14px;
    padding-top: 20px;
    border-top: 1px solid #dadce0;
    width: 100%;

    .viewLink {
        color: #006dff;
        cursor: pointer;
    }

    @media (max-width: 1024px) {
        width: 228%;
    }
}
.blog-viewD {
    margin-top: 15px;
    color: #009cbd;
    font-size: 14px;
    padding-top: 20px;
    border-top: 1px solid #dadce0;
    width: 100%;

    .viewLink {
        color: #009cbd;
        cursor: pointer;
    }

    @media (max-width: 1024px) {
        width: 228%;
    }
}
.blog-image-div {
    width: 48%;

    @media (max-width: 1440px) {
        width: 55%;
    }

    @media (max-width: 1024px) {
        img {
            height: 200px;
        }
    }
}
