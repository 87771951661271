.diy-detail-div {
    width: 100%;
}

.titleFlexDiv {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
}
.moving-main-pageDouglas {
    .tab-active {
        color: #009cbd !important;
        border-bottom: 2px solid #009cbd !important;
    }
}
.diyMovingCardsFlexDiv {
    display: flex;
    margin-top: 20px;
    margin-bottom: 50px;
    align-items: flex-end;
    flex-wrap: wrap;

    .diyMovingCard {
        width: 23%;
        margin-right: 20px;
        margin-bottom: 20px;

        @media (max-width: 1024px) {
            width: 31%;
        }

        @media (max-width: 768px) {
            width: 30%;
        }

        @media (max-width: 600px) {
            width: 100%;
            margin-right: 0;
        }
    }

    .nextCardDiv {
        width: 48%;
        margin-right: 20px;

        .main-card-class {
            width: 100% !important;
        }

        @media (max-width: 1024px) {
            width: 44%;
        }

        @media (max-width: 600px) {
            width: 100%;
            margin-right: 0;
            margin-bottom: 20px;
        }
    }

    @media (max-width: 1024px) {
        flex-wrap: wrap;
    }
}
