.warrantyPlanskeletonCard {
    border: 1px solid #e2e2e2;
    width: 250px;
    margin: 20px;
    // width: 30%;

    .warrantyPlanskeletonCard1 {
        span {
            width: 100%;
        }
        border-bottom: 1px solid #00000029;
        padding: 10px;
        .div0 {
            display: flex;
            width: 100%;
            .div1 {
                width: 100%;
            }
        }
    }
    .warrantyPlanskeletonCard2 {
        span {
            width: 100%;
        }
        .div4 {
            padding: 15px;
            width: 87%;
        }
        .div5 {
            padding: 15px;
            width: 87%;
        }
        .div6 {
            padding: 15px;
            width: 87%;
        }
    }
}
