@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');

@font-face {
    font-family: 'Roboto';
    src: local('Roboto'), url('../../../Assets/font/Roboto/Roboto-Regular.ttf') format('ttf');
}
.webShowDouglas {
    // @media (max-width: 600px) {
    //     display: none;
    // }
}
.webShowDouglasD {
    @media (max-width: 600px) {
        display: none;
    }
}
.mobileShowDouglas {
    display: none;
    cursor: pointer;
    @media (max-width: 600px) {
        display: block;
    }
}
.date-picker-div-move {
    // position:relative;
    // width:190px;
    // background:white;
    // border:1px solid #00000029;
    // border-radius: 5px;
    display: none;
}
.card-section-1 .MuiFilledInput-root:hover,
.card-section-2 .MuiFilledInput-root:hover {
    background: #fff !important;
}

.card-section-1 .MuiFilledInput-root,
.card-section-2 .MuiFilledInput-root {
    background: #fff !important;
    border: 1px solid #81696929 !important;
    width: 98% !important;
    border-radius: 5px;
}

.card-section-3 .MuiInput-underline:before {
    border: none !important;
}

.card-section-3 .MuiInput-underline:after {
    border: none !important;
}

.card-section-1 .MuiFormControl-root,
.card-section-2 .MuiFormControl-root {
    width: 100%;
}

.card-section-1 .MuiFilledInput-input,
.card-section-2 .MuiFilledInput-input {
    padding: 15px 12px 15px 10px;
    box-shadow: 2px 6px 10px #e0e0e0;
}

.overflow-auto1 {
    width: 250px !important;
    z-index: 4000;
    background: #fff;
    opacity: 1 !important;
    margin-top: 10px;
    position: absolute;
    animation: popup 0.4s;
}
@keyframes popup {
    0% {
        transform: scale(0.9);
    }
    100% {
        transform: scale(1);
    }
}

.overflow-auto1 .autocomplete-dropdown-container {
    width: 245px !important;
    z-index: 4000;
    box-shadow: 2px 6px 10px #e0e0e0;
}
.card-height-width-2 {
    height: 100% !important;
    padding: 0 !important;
    @media (max-width: 768px) {
        width: 100% !important;
        height: auto !important;
    }
}
.card-height-width-2Douglas {
    height: 100% !important;
    padding: 0 !important;
    @media (max-width: 768px) {
        width: 100% !important;
        // height: 90px !important;
    }
    @media (max-width: 600px) {
        width: 92% !important;
    }
    .card-section-1 {
        width: 35% !important;
        padding: 10px 10px !important;
        @media (max-width: 600px) {
            width: 50% !important;
            padding: 0px !important;
            border-right: none !important;
        }
        .card-section-1InnerD {
            @media (max-width: 600px) {
                padding: 10px !important;
                margin: 0px !important;
                // border: 1px solid #dadce0 !important;
                border-radius: 4px 0px 0px 4px !important;
            }
        }
    }

    .card-section-2 {
        width: 35% !important;
        padding: 10px 10px !important;
        @media (max-width: 600px) {
            width: 50% !important;
            padding: 0px !important;
            border-right: 1px solid #dadce0;
        }
        .card-section-2InnerD {
            @media (max-width: 600px) {
                padding: 10px !important;
                margin: 0px !important;
                // border: 1px solid #dadce0 !important;
                border-radius: 0px 4px 4px 0px !important;
                border-left: none !important;
            }
        }
    }
    .card-section-3 {
        width: 30% !important;
        padding: 20px 0px 35px 20px !important;
        @media (max-width: 600px) {
            // display: block;
        }
    }
}

// .moveFromMoveToCard2 .MuiPaper-root.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded{
//     top: 34% !important;
//     left: 37% !important;

// @media(max-width:768px){
//     left: 23% !important;
// }

// }

// .date12 .MuiFilledInput-root{
//     border:none;
//     margin-top: -2px;

// }

.card-section-1 {
    border: 1px solid #dadce0;
    width: 35%;
    padding: 0px 0px 0px 20px;
    @media (min-width: 768px) {
        // width: 50% !important;
        padding-left: 15px !important;
    }
}
.nodata-card-section {
    padding-left: 0px !important;
}

.card-section-2 {
    border: 1px solid #dadce0;
    width: 35%;
    padding: 0px 0px 0px 20px;
    @media (max-width: 768px) {
        width: 50% !important;
        border-right: none;
    }


}



.card-section-3 {
    border: 1px solid #dadce0;
    width: 30%;
    padding: 20px 0px 35px 20px;
    height: 100%;
    @media (max-width: 600px) {
        width: 0% !important;
        display: none;
    }
}

.topDateCover {
    padding-top: 10px !important;
}

.section-1-heading,
.section-2-heading,
.section-3-heading {
    font-size: 12px;
    // opacity: 0.8;
    font: 'Roboto';
    color:#6B6C6F;
}

.section-1-number,
.section-2-number,
.section-3-number {
    font-weight: bold;
    padding-top: 10px;
    padding-bottom: 5px;
    font-size: 16px;
    color: #333333;
}

.section-1-content,
.section-2-content {
    font-size: 12px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;

    &.addT {
        color: #333333 !important;
    }
}

.section-3-content {
    font-size: 12px;
}

.truck {
    margin-top: 30px;
    align-self: flex-end;
    margin-left: 60px;
    margin-right: -14px;
}

.cardContentDDashboard {
    display: none;
    @media (max-width: 600px) {
        display: unset;
    }
}
// .auto-width{
//     width: 350px;
// }
.openModalDashboard {
    display: none;
    @media (max-width: 600px) {
        display: unset;
    }
    position: absolute;
    top: 15%;
    width: 400px;
    left: 45%;
    background-color: #fff;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    .backarrowDiv {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin: 20px 0px 40px 20px;
        svg {
            height: 24px;
            width: 24px;
        }
        .textBackD {
            font-size: 16px;
            font-family: 'Lato';
            color: #333333;
            margin-left: 20px;
            font-weight: bold;
        }
    }
    .inputDivDMove {
        margin: 10px;
        border: 1px solid #dadce0;
        border-radius: 4px;
        padding: 10px 0px;
        .MuiFormControl-root {
            width: 100%;
        }
        .upperTextD {
            font-size: 10px;
            color: #6b6c6f;
            margin-left: 10px;
        }
        .MuiFilledInput-input {
            padding: 10px 12px 10px;
        }
        .section-3-heading,
        .section-3-content,
        .section-1-heading,
        .section-1-content,
        .section-2-content,
        .section-2-heading {
            padding: 0px 10px;
        }
        .section-3-number,
        .section-1-number,
        .section-2-number {
            padding-left: 10px;
            padding-right: 10px;
        }
        .autocomplete-dropdown-container {
            // margin-left: -10px !important;
            margin-top: 10px;
            width: calc(100% - 22px);
        }
    }
    .ButtonConfirmDouglas {
        margin: 0px 10px;
        position: absolute;
        bottom: 20px;
        width: 94%;
    }
}

.MuiPopover-root {
    z-index: 130000 !important;
}
.openModalDashboardDouglas {
    input {
        background-color: #fff;
    }
    .MuiFilledInput-root {
        border: none !important;
    }
}

.welcomeTextDiv {
    display: flex;
    padding: 20px 20px 0px 20px;
    .welomeText1 {
        font-size: 24px;
        color: #100b28;
        font-family: 'EuclidCircularA-Light';
        margin-right: 15px;
        font-weight: 600;
        // margin-bottom: 10px;

        @media (max-width: 425px) {
            margin-bottom: 0;
            font-size: 16px;
        }
        .nameWelcome {
            font-family: 'EuclidCircularA-Bold';
            font-size: 34px;
            text-transform: capitalize;
            @media (max-width: 425px) {
                font-size: 20px;
            }
        }
    }
}

.welcomeTextDiv img {
    height: max-content;
}

.welomeText {
    font-size: 24px;
    line-height: 32px;
    font-family: 'Roboto';
    color: #333333;
    margin-right: 15px;
    margin-bottom: 10px;

    @media (max-width: 1024px) {
        font-size: 20px;
        line-height: 28px;
    }

    @media (max-width: 425px) {
        font-size: 16px;
        line-height: 32px;
        margin-bottom: 0;
    }
}

.nameDiv {
    font-size: 34px;
    line-height: 32px;
    font-family: 'Roboto';
    color: #333333;
    font-weight: bold;
    margin-bottom: 30px;
    padding-left: 20px;

    @media (max-width: 1024px) {
        font-size: 28px;
        line-height: 28px;
        margin-bottom: 15px;
    }

    @media (max-width: 425px) {
        font-size: 20px;
        line-height: 32px;
    }
}

.morningText {
    font-size: 16px;
    padding: 0px 20px;
    line-height: 22px;
    font-family: 'EuclidCircularA-Light';

    @media (max-width: 1024px) {
        font-size: 14px;
        line-height: 18px;
    }
}
.morningText1 {
    padding: 0px 20px;
    font-size: 16px;
    line-height: 22px;
    color: #100b28;
    font-family: 'Roboto';
}

.input1-moveCard{
    .MuiFilledInput-root.Mui-focused{
        border-color:white !important;

    }
 
}

.input2-jammer
{
    .MuiFilledInput-root.Mui-focused{
        border-color:white !important;

    }
 
}

.bold{
    font-weight: bold;
}

//Scss for HomeValuation.

.main-home-line
{
    display: flex;
    flex-direction:row;
    .section-4-heading{
        font-size: 12px;
        display: flex;
        flex-direction: row;
        font-family: 'Poppins';
        font-weight: bold;
        .section-4-subheading{
            font-size:34px;
            color: #333333;
            font-weight: bold;
        }
     }
     .section-5-subheading{
         
     }
}

