.MuiAppBar-colorPrimary {
    color: #333333 !important;
    background-color: #ffffff !important;
}
.MuiTab-wrapper {
    text-transform: uppercase !important;
    font-size: 12px !important;
}

.icon-button-image {
    /* width: 130px; */
}
.dre-no {
    font-size: 12px;
    /* font-weight: bold; */
    margin-top: 5px;
}

.icon-button-image img {
    /* max-width: 100%;
    max-height: 70px; */
    width: 200px;
    max-height: 70px;
}

.service-main-div {
    display: flex;
    flex-grow: 1;
    justify-content: flex-start;
    background-color: transparent;
    margin-left: 20px;
    align-items: center;
}

.service-main-div:hover,
.service-main-div:focus {
    background-color: transparent;
}

.profileText {
    margin-left: 10px;
    font-size: 15px;
}

.onbehalfof {
    font-size: 13px;
    text-align: center;
}

.dropdownIcon {
    width: 15px;
    height: 10px;
    margin: 0 6px;
}
.docHeadIcon {
    height: 34px !important;
}
.headerTab .MuiTabs-flexContainer {
    display: flex;
    justify-content: space-around !important;
    border-bottom: 0 !important;
}

.footerDashboard {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border: #e2e2e2 1px solid;
    padding: 5px;
    margin-top: 50px;
    z-index: 999;
}

.pages::-webkit-scrollbar {
    display: none;
}

.links {
    /* display: flex;
    margin: 100px;
    justify-content: space-between; */
    /* padding: 0px 75px 0px 75px; */
    margin: 0 auto;
    max-width: 1680px;
    min-width: 325px;
    width: 100vw;
    position: relative;
}

.AppBarFirst {
    z-index: 10000;
    box-shadow: 0px 3px 3px #ededed !important;
}
.InnerNavDiv {
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
}

.lastHeaderRow {
    display: flex;
    align-items: center;
    /* margin-left: 1015px; */
}

/* li {
    list-style: none;
    width: 100%;
} */

.firstBar {
    box-shadow: 0px 3px 3px #ededed !important;
}
.secondBar {
    box-shadow: none !important;
    border-bottom: 1px solid #eaedf3 !important;
}

.PrivateTabIndicator-colorPrimary-6 {
    height: 3px !important;
}
.headerPopover {
    margin-top: 3px;
    position: absolute;
    /* max-width: 1920px; */
}

.moreIcon {
    margin-top: 20px !important;
}

.HeaderDropdownValues .MuiMenuItem-root {
    width: 180px;
    font-size: 14px !important;
    white-space: unset !important;
}

.headerPopover .MuiPopover-paper {
    right: 95px !important;
    left: auto !important;
    /* width: 245px;
    left: 1355px !important; */
}

.HeaderDropdownValues a {
    text-decoration: none;
    color: #6b6c6f !important;
    text-transform: uppercase !important;
}

a {
    text-decoration: none;
}

a.active {
    color: #333333;
    border-bottom: 1px solid #765b13;
}

.MuiTab-textColorPrimary.Mui-selected {
    color: #333333 !important;
}

.mainDiv {
    padding: 10px 40px;
    font-size: 18px;
    /* border-bottom: 1px solid #765B13; */
}

.HeaderDropdownValues > a {
    display: flex;
    padding: 15px;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #e0e0e0;
}

.headerTab .MuiTabs-flexContainer > a {
    padding: 9px 0px 0px 0px !important;
}

.mobileList .MuiListItemText-root {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    color: #6b6c6f !important;
}

.mobileListActive .MuiListItemText-root {
    font-weight: 600 !important;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    color: #273e59 !important;
}

.mobileList .MuiListItemIcon-root {
    min-width: 40px;
}
.mobileListActive .MuiListItemIcon-root {
    min-width: 40px;
}

.mobileList .MuiTypography-body1 {
    font-size: 1em;
}

.tabIcons {
    height: 35px;
    width: auto;
}

.MobileScreenHeader {
    display: none;
}
.bigScreenHeader .MuiToolbar-gutters {
    padding-left: 80px !important;
    padding-right: 85px !important;
    height: 75px;
}
/* Media Queries */
@media (max-width: 3000px) {
    .headerPopover .MuiPopover-paper {
        right: calc(100% - 75%) !important;
    }
}
@media (max-width: 2560px) {
    .headerPopover .MuiPopover-paper {
        right: calc(100% - 80%) !important;
    }
}

@media (max-width: 1920px) {
    .links {
        max-width: 1920px;
    }

    .InnerNavDiv {
        /* max-width: 1680px; */
        width: 100%;
    }

    .bigScreenHeader.MuiToolbar-gutters {
        padding-left: 80px !important;
        padding-right: 95px !important;
    }
    /* .lastHeaderRow {
        margin-left: 777px;
    } */
    .headerPopover .MuiPopover-paper {
        right: 95px !important;
    }
}

@media (max-width: 1680px) {
    .headerPopover .MuiPopover-paper {
        right: 95px !important;
    }
    .links {
        max-width: 1680px;
    }
    .bigScreenHeader.MuiToolbar-gutters {
        padding-left: 85px !important;
        padding-right: 95px !important;
    }
}

@media (max-width: 1536px) {
    .headerPopover .MuiPopover-paper {
        right: 85px !important;
    }
    .links {
        max-width: 1536px;
    }
    .InnerNavDiv {
        /* max-width: 1680px; */
        width: 100%;
    }
    .bigScreenHeader.MuiToolbar-gutters {
        padding-left: 80px !important;
        padding-right: 85px !important;
    }
}

@media (max-width: 1440px) {
    .InnerNavDiv {
        /* max-width: 1440px; */
        width: 100%;
    }

    .headerPopover .MuiPopover-paper {
        right: 85px !important;
    }
    .links {
        max-width: 1440px;
    }
    .bigScreenHeader.MuiToolbar-gutters {
        padding-left: 70px !important;
        padding-right: 80px !important;
    }
}

@media (max-width: 1366px) {
    /* .lastHeaderRow {
        margin-left: 700px;
    } */

    .headerPopover .MuiPopover-paper {
        right: 65px !important;
    }
    .links {
        max-width: 1366px;
    }
}

@media (max-width: 1280px) {
    /* .lastHeaderRow {
        margin-left: 640px;
    } */
    .links {
        max-width: 1280px;
    }
}

@media (max-width: 1080) {
    .headerPopover .MuiPopover-paper {
        left: 10px !important;
    }
}

@media (max-width: 1024px) {
    /* .lastHeaderRow {
        margin-left: 375px;
    } */

    .links {
        max-width: 1024px;
    }
}
@media (max-width: 768px) {
    /* .lastHeaderRow {
        margin-left: 130px;
    } */
    .bigScreenHeader.MuiToolbar-gutters {
        padding-left: 70px !important;
        padding-right: 70px !important;
    }
}

@media (min-width: 600px) {
    .MuiTab-root {
        min-width: 150px !important;
    }
}

@media (max-width: 426px) {
    .MobileScreenHeader {
        display: unset !important;
    }
    .bigScreenHeader {
        display: none;
    }
    .links {
        margin: 0px 0px 0px 0px;
        max-width: 1218px;
        width: auto;
    }

    /* .lastHeaderRow {
        margin-left: 55px;
    } */

    .profileText {
        display: none;
    }
    .tabIcons {
        width: 40px;
        height: 20px;
        margin-right: 10px;
    }
}

.MobileScreenHeader .MuiIconButton-edgeStart {
    margin-left: -20px !important;
}

.logoutButton {
    display: flex;
    align-items: center;
    padding-left: 40px;
    cursor: pointer;
}
.logoutButton1 {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding-left: 20px;
}
.logoutText {
    font-size: 14px;
    color: #6b6c6f;
    font-family: 'Roboto';
    fill: #6b6c6f;
}
.logoutButton:hover .logout_a,
.logoutButton:hover .logoutText {
    fill: #d92f25;
    color: #d92f25;
}
.lineLogout {
    border-bottom: 1px solid #bebebe;
    margin-bottom: 10px;
    padding: 0 16px;
}
.nextmoveIconMainDiv {
    display: flex;
    justify-content: center;
    align-items: center;
}
.nextmoveIconMainDiv img {
    max-width: 100px;
    max-height: 77px;
}
.nextMoveSymbol {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
  
}
.clickNext {

    width:fit-content;
   
    color:#0066F5;
    text-align: center;
    padding:auto 2px;
    font-size:12px;
background: #F0F4FC ;
opacity:1;
}
