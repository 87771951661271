.insuranceCardMain {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;

    @media (max-width: 1024px) {
        flex-direction: column;
        .textAboveFooter {
            position: fixed;
            bottom: 62px;
            font-size: 12px;
            color: #6b6c6f;
            // text-align: center;
            padding: 0 80px;
            width: 1530px;
            line-height: 23px;
            background-color: #fff;

            @media (max-width: 1920px) {
                width: 1340px;
            }
            @media (max-width: 1440px) {
                width: 1280px;
                line-height: 15px;
            }
            @media (max-width: 1366px) {
                padding: 0 60px;
                width: 1220px;
            }
            @media (max-width: 1280px) {
                width: 1160px;
                line-height: 14px;
            }
            @media (max-width: 1024px) {
                width: 930px;
            }
            @media (max-width: 768px) {
                display: none;
            }
            // @media (min-width: 1020px) and (max-width: 1319px) {
            //     line-height: 14px;
            // }
            // @media (max-width: 600px) {
            //     margin: 0 10px;
            //     display: none;
            // }
        }
    }

    .planRateDiv {
        @media (max-width: 1024px) {
            margin: 20px 0px;
        }
    }

    .ButtonInsuranceCard {
        width: 150px;
        @media (max-width: 1024px) {
            width: 100%;
        }
    }
}

.insuranceCardMain .rate {
    margin: 15px;
    font-size: 14px;
    display: block;
}

.viewCoverage {
    margin-top: 15px;
    // margin-left: 30px;
    text-align: center;
}

.viewCoverage a {
    text-decoration: underline;
    color: blue;
}

.insuranceCardMain .LogoDiv img {
    width: 230px;
    height: 46px;

    @media (max-width: 600px) {
        margin-top: 10px;
    }
}
