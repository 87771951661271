@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');

@font-face {
    font-family: 'Lato';
    src: local('Lato'), url('../Assets/font/Lato/Lato-Regular.ttf') format('ttf');
}

.arrowsInternet {
    margin-top: 190px !important;
}

.arrowIconInternet {
    height: 40px;
    width: 40px;
}

.arrow-wrapper-internet {
    position: relative;
    text-align: center;
    left: 6%;
    right: 6%;
    width: 10%;
    margin-left: auto;
    margin-right: auto;
}
.mobile-div-internet {
    display: none;
}

.carousel-2-internet {
    display: none;
}

.navlinkHeading {
    text-decoration: none;
    font-size: 14px;
    font-family: 'Lato', sans-serif;
    font-weight: bold;
}

.week-time-div-internet {
    padding-top: 50px;
}

.landingHeaderInternet {
    padding: 15px 100px;
    box-shadow: 0px 4px 4px #eee;
    position: sticky;
    top: 0;
    z-index: 100;
    background: #ffffff;
    font-family: 'Lato', sans-serif;
    /* margin-top: -180px; */
}

.landingGetQuoteButtonInternet {
    width: 120px !important;
    height: 45px !important;
    font-size: 14px !important;
    font-family: 'Lato', sans-serif !important;
    font-weight: bold !important;
    line-height: 24px !important;
    background-color: #30ab86 !important;
    border-radius: 4px !important;
    margin-top: 10px !important;
}

.landingNav-li-internet {
    list-style: none;
    padding: 10px 16px;
    margin: 0 15px;
    cursor: pointer;
    font-family: 'Lato', sans-serif;
}
.internetLanding-main-page {
    margin-top: -500px;
    padding-left: 120px;
    display: flex;
    flex-direction: row;
    box-shadow: 0px -10px 10px #eee inset;
    font-family: 'Lato', sans-serif;
}

.landingHeaderButtonInternet {
    font-size: 14px !important;
    width: 116px !important;
    height: 45px !important;
    border-radius: 4px !important;
    background-color: #30ab86 !important;
    font-family: 'Lato', sans-serif;
    margin-left: 45px !important;
}

.navLinkFlexDivInternet {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.logo-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: 'Lato', sans-serif;
    /* margin-left: 4%; */
}

.logo-container img {
    width: 142px;
    height: 34px;
}

.phoneInternetLanding {
    color: #0066f5;
    font-size: 16px;
    text-align: center;
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
}

.phone-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3px;
    padding: 0 5px;
}

.you-deserve-text-internet,
.wfh-internet {
    color: #333333;
    font-size: 56px;
    text-align: left;
    font-family: 'Lato', sans-serif;
    font-weight: 900;
    line-height: 80px;
    text-transform: capitalize;
}

.wfh-internet {
    color: #30ab86;
}

.header-desc-internet {
    width: 43%;
    font-size: 16px;
    font-family: 'Lato', sans-serif;
    text-align: left;
    color: #333333;
    font-weight: 300;
    line-height: 28px;
    opacity: 0.7;
}

.worlds-internet {
    margin-top: 100px;
    text-align: center;
    padding-top: 120px;
    padding-bottom: 80px;
    background-color: #f5faf8;
    font-family: 'Lato', sans-serif;
}
.how-it-works-wrapper-internet {
    background: url('../Assets/images/InsuranceImages/howItWorksBackground.png');
    text-align: center;
    padding-top: 70px;
    padding-bottom: 50px;
    background-color: #f5faf8;
    font-family: 'Lato', sans-serif;
}
.worlds-heading-internet {
    font-weight: 900;
    color: #333333;
    font-size: 30px;
    line-height: 45px;
    letter-spacing: 0.9px;
    font-family: 'Lato', sans-serif;
    text-transform: capitalize;
}
.worlds-para-internet {
    color: #333333;
    font-size: 16px;
    font-family: 'Lato', sans-serif;
    width: 30%;
    font-weight: 300;
    line-height: 28px;
    opacity: 0.7;
    margin: 12px auto;
}
.main-div-internet {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
}
.main-div-internet .div-1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 58%;
    margin-left: auto;
    margin-right: auto;
}
.main-div-internet .div-2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 68%;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;
    margin-top: 20px;
}

.how-it-works-wrapper-internet {
    background-color: #f5faf8;
}

.card-img-wrapper-internet,
.how-it-works-card-div-internet .card-wrapper,
.how-it-works-card-div-internet .active-card {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.how-it-works-wrapper-internet .card-img-wrapper-internet {
    padding-top: 10px;
}

.card-img-wrapper-internet {
    padding-top: 60px;
}

.call-wrapper-internet {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 60px 180px 60px 60px;
}

.how-it-works-card-div-internet .card-wrapper,
.how-it-works-card-div-internet .active-card {
    padding: 24px 12px;
    margin-top: 16px;
    cursor: pointer;
    width: 400px;
    /* height: auto; */
}

.how-it-works-card-div-internet .linearProgressInternetLanding {
    margin-top: -17px;
    margin-bottom: 17px;
    /* width: 72%; */
    width: 420px;
}

.how-it-works-card-div-internet .progressBarFlexDiv {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.how-it-works-card-div-internet .MuiLinearProgress-barColorPrimary {
    background-color: #30ab86;
}

.how-it-works-card-div-internet .card-heading,
.card-desc-internet,
.card-desc {
    text-align: left;
    color: #333333;
    font-size: 22px;
    line-height: 28px;
    font-family: 'Lato', sans-serif;
    font-weight: bold;
}

.how-it-works-card-div-internet .card-heading {
    padding: 0px;
}

.card-desc-internet,
.card-desc {
    font-size: 16px;
    font-weight: lighter;
    opacity: 0.8;
    margin-top: 10px;
    font-family: 'Lato', sans-serif;
}

.how-it-works-card-div-internet .serial {
    color: #aaaaaa;
    font-size: 20px;
    border: 1px solid #aaaaaa;
    background-color: #ffffff;
    border-radius: 50%;
    line-height: 30px;
    height: 24px;
    padding: 0px 10px 6px 9px;
    margin-top: 0px;
    font-family: 'Lato', sans-serif;
}

.how-it-works-card-div-internet .card-wrapper:hover {
    background-color: #fff;
}

.how-it-works-card-div-internet .card-wrapper:hover .how-it-works-card-div-internet .serial {
    background-color: #fff;
}

.how-it-works-card-div-internet .active-card {
    background-color: #fff;
    /* border-bottom: 2px solid #30ab86; */
    position: relative;
}

/* .how-it-works-card-div-internet .active-card::after {
    content: '';
    width: 60%;
    position: absolute;
    bottom: 0;
    left: 0;
    border-bottom: 2px solid #30ab86;
} */

.how-it-works-card-div-internet .active-serial {
    background-color: #30ab86;
    color: #fff;
    font-size: 20px;
    line-height: 30px;
    border: 1px solid #aaaaaa;
    border-radius: 50%;
    height: 24px;
    padding: 0px 10px 6px 9px;
    margin-top: 0px;
    font-family: 'Lato', sans-serif;
}

.collectionInternet {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    padding: 50px 0;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}

.collectionCardInternet {
    width: 32% !important;
    height: 320px !important;
    padding: 35px 30px !important;
    margin: 0 10px;
    font-family: 'Lato', sans-serif !important;
}

.MuiTab-wrapper {
    text-transform: capitalize;
    font-family: 'Lato', sans-serif;
}
.MuiTabs-flexContainer {
    border-bottom: 1px solid lightgray;
}

.collectionCardImgInternet {
    width: 150px !important;
    height: 135px !important;
    margin: 0 !important;
}

.carousel-internet {
    /* margin-top: 50px; */
    /* padding-bottom: 50px; */
    padding-top: 35px;
    margin-left: auto;
    margin-right: auto;
    background: #415b76;
    text-align: center;
    font-family: 'Lato', sans-serif;
}

.review {
    width: 77%;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
}

.carousel-div-internet {
    width: 77%;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 50px;
}

.questions-internet {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    padding-top: 60px;
    padding-bottom: 30px;
    font-family: 'Lato', sans-serif;
}

.frequently-internet {
    font-size: 30px;
    line-height: 45px;
    letter-spacing: 0.9px;
    font-weight: bold;
    padding-bottom: 30px;
    font-family: 'Lato', sans-serif;
    color: #333333;
}

.address-internet .heading-internet {
    font-size: 26px;
    font-weight: bold;
    padding-bottom: 20px;
    font-family: 'Lato', sans-serif;
    color: #333333;
}
.heading2-internet {
    font-size: 30px;
    line-height: 45px;
    letter-spacing: 0.9px;
    font-weight: bold;
    padding-top: 15px;
    color: #ffffff;
    width: 30%;
    margin-left: auto;
    margin-right: auto;
    font-family: 'Lato', sans-serif;
}
.accordion-internet {
    margin-bottom: 22px;
}

.accordion-internet .MuiPaper-elevation1 {
    box-shadow: none;
    border-bottom: 1px solid lightgray;
}

.accordion-internet .MuiAccordionSummary-root {
    padding: 0;
}

.accordion-internet .MuiAccordionDetails-root {
    padding: 0 0 16px;
}

.accordion-internet .MuiTypography-body1 {
    font-size: 16px;
    line-height: 22px !important;
    text-align: left;
    font-family: 'Lato', sans-serif;
}

.accordion-internet .MuiAccordionDetails-root .MuiTypography-body1 {
    font-size: 14px;
    line-height: 28px !important;
    text-align: left;
}

.address-internet {
    margin-top: 50px;
    width: 34%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    padding-bottom: 50px;
    font-family: 'Lato', sans-serif;
}

.addressCard {
    padding: 0px 100px 0px 100px;
    display: flex;
    justify-content: center;
}

.address-wrapper-internet .boxCard-internet {
    background-color: #ffffff;
    text-align: left;
    width: 488px;
    margin-left: auto;
    margin-right: auto;
    height: auto;
    box-shadow: 0px 3px 10px #00000029;
    border: 1px solid #e2e2e2;
    border-radius: 4px;
    padding: 20px 20px 30px 20px;
    display: flex;
    flex-direction: column;
}

.address-wrapper-internet .Para1 {
    display: flex;
    margin: 10px 0px 10px 0px;
    font-family: 'Lato', sans-serif;
    font-size: 12px;
    line-height: 22px;
    color: #6b6c6f;
}

.address-wrapper-internet .MuiOutlinedInput-notchedOutline {
    border: none;
}

.address-wrapper-internet .inputs-internet {
    margin-top: 0px;
}

/* .address-wrapper-internet .input1 {
    width: 90% !important;
} */

.input1 .MuiInputLabel-filled.MuiInputLabel-shrink.Mui-focused,
.innerInputs-internet .MuiInputLabel-outlined.MuiInputLabel-shrink.Mui-focused {
    z-index: 10 !important;
    color: #006dff;
}

.address-wrapper-internet .input1 .MuiInputBase-input,
.address-wrapper-internet .innerInputs-internet .MuiInputBase-input {
    border-radius: 5px;
    text-align: left;
    border: 1px solid #bebebe;
    background-color: #fff;
    padding: 27px 12px 10px;
}

.MuiFormLabel-root.Mui-focuesd {
    color: #006dff;
}

.address-wrapper-internet .input1 .Mui-error .MuiInputBase-input,
.address-wrapper-internet .innerInputs-internet .Mui-error .MuiInputBase-input {
    border-radius: 5px;
    text-align: left;
    border: 1px solid red;
    background-color: #fff;
    padding: 27px 12px 10px;
}

.address-wrapper-internet .input1 .MuiInputBase-input:focus,
.address-wrapper-internet .innerInputs-internet .MuiInputBase-input:focus {
    border-radius: 5px;
    text-align: left;
    border: 1px solid #006dff;
    background-color: #fff;
    padding: 27px 12px 10px;
}

.address-wrapper-internet .MuiInputLabel-outlined.MuiInputLabel-shrink {
    margin-top: 13px;
}

.address-wrapper-internet .innerInputs-internet {
    display: flex;
    margin-top: 20px;
    margin-bottom: 15px;
    justify-content: space-between;
}

/* .carousel-inner .MuiCard-root {
 width: 24% !important;
 text-align: left;
} */

.carousel-inner .MuiCardContent-root {
    padding: 24px;
}

.innerInput2Internet .MuiOutlinedInput-root {
    /* margin-right: 10px; */
    margin-right: 0px !important;
    height: 50px !important;
}

.innerInput1Internet {
    width: 33%;
}

.innerInput2Internet {
    width: 29%;
}

.address-wrapper-internet .input1 .MuiOutlinedInput-root,
.address-wrapper-internet .input1 {
    /* width: 440px !important; */
    width: 100%;
}

.address-wrapper-internet .input2 .MuiOutlinedInput-root {
    /* width: 170px !important; */
    width: 100% !important;
}

.address-wrapper-internet .input3landing {
    /* width: 100px !important; */
    width: 100% !important;
    /* margin-right: 10px !important; */
}
/* .react-select .select-state__control {
    height: 48px;
    margin-right: 10px;
} */
.address-wrapper-internet .input4 .MuiOutlinedInput-root {
    /* width: 150px !important; */
    width: 100% !important;
}
.checkbox .MuiSvgIcon-root {
    fill: 'red' !important;
}

.call-now-internet {
    padding-top: 39px;
    font-size: 20px;
    color: #0066f5;
    font-weight: bold;
    font-family: 'Lato', sans-serif;
}

.week-time-internet {
    display: flex;
    flex-direction: row;
}

.day-internet,
.time-internet {
    color: #333333;
    font-size: 16px;
    line-height: 22px;
    font-weight: bold;
    font-family: 'Lato', sans-serif;
    width: 35%;
    padding-bottom: 16px;
}

.day-internet {
    width: 40%;
}

.time-internet {
    width: 60%;
}

.call-wrapper-internet .scheduleCallButtonDiv {
    padding-top: 18px;
}

.call-wrapper-internet .imgDiv {
    width: 55%;
}

.call-wrapper-internet img {
    margin-left: 25px;
}

.call-wrapper-internet .scheduleCallDiv {
    padding-left: 4%;
    padding-top: 2%;
    padding-right: 4%;
    width: 45%;
}

.call-wrapper-internet .scheduleCallButton {
    background-color: #fff !important;
    color: #333 !important;
    border: 1px solid #30ab86 !important;
    box-shadow: none !important;
    font-size: 14px !important;
    font-weight: bold !important;
    width: 138px !important;
    height: 45px !important;
    line-height: 24px !important;
    font-family: 'Lato', sans-serif !important;
}

.time {
    font-weight: lighter;
    width: 40%;
    /* padding-left: 2%; */
    font-family: 'Lato', sans-serif;
}

.Carousel-button-15 {
    top: calc(50% - 20px) !important;
    color: white;
    cursor: pointer;
    margin: 0 10px;
    position: relative;
    font-size: 30px;
    transition: 200ms;
    /* background-color: #494949; */
}

.Carousel-buttonHidden-14 {
    opacity: 1;
}
.MuiTab-textColorInherit.Mui-selected {
    color: #0066f5 !important;
    text-transform: lowercase;
}

.MuiTab-textColorInherit {
    color: #333333 !important;
    text-transform: lowercase;
}

.MuiTab-textColorInherit.Mui-selected {
    color: #0066f5 !important;
}

.MuiTab-textColorInherit {
    color: #333333 !important;
    text-transform: lowercase !important;
}

.compare-section-internet {
    background: #f5faf8;
    padding: 60px 0;
}

.carousel-inner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

/* .Carousel-prev-18{
    left:-72px !important;
    position: relative !important;
    top:-17px !important;
}

.Carousel-next-17{
    position:relative !important;
    top:22px !important
   
} */

.address-wrapper-internet {
    background: url('../Assets/images/helpCenterCTA.svg');
    padding: 20px 0;
}

.how-it-works-card-div-internet {
    width: 40%;
    padding: 50px 80px 0 50px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.how-it-works-image-div-internet {
    width: 60%;
    display: flex;
    justify-content: flex-end;
}

.how-it-works-image-div-internet img {
    width: 100%;
    height: 100%;
}

.how-it-works-tab-mobile {
    display: none;
}

@media (max-width: 1500px) {
    .dynamicHeaderImageDiv {
        margin-top: 54px;
    }
}

@media (max-width: 1440px) {
    .landingNav-li-internet {
        margin: 0;
    }

    .you-deserve-text-internet,
    .wfh-internet {
        font-size: 48px;
        line-height: 70px;
    }

    .worlds-heading-internet {
        font-size: 26px;
        line-height: 39px;
    }

    .worlds-para-internet {
        font-size: 14px;
        line-height: 20px;
    }

    .collectionCardImgInternet {
        width: 100px !important;
        height: 95px !important;
    }

    .how-it-works-wrapper-internet {
        padding-top: 48px;
        padding-bottom: 36px;
    }
}

@media (max-width: 1366px) {
    .arrowsInternet {
        margin-top: 118px !important;
    }

    .arrowIconInternet {
        height: 35px;
        width: 35px;
    }

    .arrow-wrapper-internet {
        left: 5%;
        right: 5%;
    }

    .you-deserve-text-internet,
    .wfh-internet {
        font-size: 37px;
        line-height: 55px;
    }

    .dynamicHeaderImageDiv img {
        margin-top: -43px;
    }

    .internetLanding-main-page {
        margin-top: -438px;
    }

    .worlds-internet {
        margin-top: 76px;
        padding-top: 105px;
        padding-bottom: 67px;
    }

    .collectionInternet {
        padding: 35px 0;
    }

    .collectionCardInternet {
        padding: 20px !important;
        height: 290px ​ !important;
    }

    .collectionCardImgInternet {
        width: 85px !important;
        height: 80px !important;
    }

    .how-it-works-wrapper-internet {
        padding-top: 29px;
        padding-bottom: 25px;
    }

    .how-it-works-card-div-internet .card-heading {
        font-size: 18px;
        line-height: 24px;
    }

    .card-desc-internet,
    .card-desc {
        font-size: 14px;
        line-height: 24px;
    }

    .how-it-works-card-div-internet .serial,
    .how-it-works-card-div-internet .active-serial {
        font-size: 16px;
        margin-top: 0;
        padding: 0 10px 7px 9px;
        height: 22px;
    }

    .how-it-works-card-div-internet .linearProgressInternetLanding {
        margin-top: -25px;
        margin-bottom: 0px;
    }

    .time {
        width: 50%;
    }

    .worlds-heading-internet {
        font-size: 20px;
        line-height: 27px;
    }

    .call-now-internet {
        font-size: 16px;
        padding-top: 20px;
    }

    .call-wrapper-internet img {
        width: 360px;
        height: 422px;
        display: flex;
        justify-content: center;
    }

    .call-wrapper-internet {
        padding: 44px 180px 44px 71px;
    }

    .compare-section-internet {
        padding: 30px 0;
    }

    .heading2-internet {
        font-size: 22px;
        line-height: 31px;
        letter-spacing: 0.5px;
        padding-top: 5px;
    }

    .review img {
        width: 50px;
        height: 77px;
    }

    .carousel-inner .MuiCardContent-root {
        padding: 16px;
    }

    .carousel-div-internet {
        padding-bottom: 24px;
    }

    .questions-internet {
        padding-top: 30px;
        padding-bottom: 15px;
    }

    .frequently-internet {
        font-size: 23px;
        line-height: 22px;
        letter-spacing: 0.5px;
        padding-bottom: 28px;
    }

    .accordion-internet {
        margin-bottom: 15px;
    }

    .address-wrapper-internet {
        padding: 1px 0;
    }

    .address-wrapper-internet .MuiOutlinedInput-input {
        padding: 12.5px 10px;
    }

    .address-wrapper-internet .innerInputs-internet {
        display: flex;
        margin-top: 15px;
    }

    .address-wrapper-internet .boxCard-internet {
        width: 437px;
        padding: 10px 20px 15px 20px;
    }
}

@media (max-width: 1024px) {
    .arrowIconInternet {
        height: 27px;
        width: 27px;
    }
    .landingHeaderInternet {
        padding: 6px 22px;
    }

    .landingNav-li-internet {
        padding: 5px 7px;
        margin: 0 5px;
    }

    .navlinkHeading {
        font-size: 12px;
    }

    .logo-container img {
        /* width: 100px;
        height: 22px; */
    }

    .phoneInternetLanding {
        font-size: 13px;
    }

    .landingHeaderButtonInternet {
        font-size: 8px !important;
        width: 76px !important;
        height: 25px !important;
        border-radius: 2px !important;
    }

    .phone-icon .MuiSvgIcon-root {
        width: 14px;
        margin-bottom: -8px;
    }

    .internetLanding-main-page {
        margin-top: -343px;
        padding-left: 41px;
        box-shadow: none;
    }

    .you-deserve-text-internet,
    .wfh-internet {
        font-size: 22px;
        line-height: 29px;
    }

    .header-desc-internet {
        font-size: 11px;
        line-height: 17px;
    }

    .landingGetQuoteButtonInternet {
        width: 90px !important;
        height: 29px !important;
        font-size: 9px !important;
        line-height: 16px !important;
        border-radius: 2px !important;
        margin-top: 5px !important;
    }

    .worlds-internet {
        margin-top: 95px;
        padding-top: 105px;
        padding-bottom: 36px;
    }

    .main-div-internet .div-1 img,
    .main-div-internet .div-2 img {
        width: 60px;
    }

    .main-div-internet {
        margin-top: 18px;
    }

    .worlds-para-internet {
        font-size: 11px;
        line-height: 17px;
        margin: 5px auto;
    }

    .worlds-heading-internet {
        font-size: 16px;
        line-height: 24px;
    }

    .collectionCardImgInternet {
        width: 61px !important;
        height: 50px !important;
    }

    .collectionCardInternet {
        height: 274px !important;
        padding: 10px !important;
    }

    .how-it-works-card-div-internet .card-heading {
        font-size: 13px;
        line-height: 21px;
    }

    .how-it-works-card-div-internet .linearProgressInternetLanding {
        margin-top: -20px;
        /* margin-bottom: 20px; */
        width: 284px;
    }

    .card-desc-internet,
    .card-desc {
        font-size: 12px;
        line-height: 18px;
    }

    .how-it-works-card-div-internet .card-wrapper,
    .how-it-works-card-div-internet .active-card {
        padding: 14px 10px;
        margin-bottom: 16px;
        width: 264px;
        height: auto;
    }

    .how-it-works-card-div-internet .serial,
    .how-it-works-card-div-internet .active-serial {
        font-size: 11px;
        margin-top: -1px;
        padding: 0 10px 7px 9px;
        height: 17px;
        line-height: 24px;
    }

    .call-wrapper-internet img {
        width: 288px;
        height: 333px;
        display: flex;
        justify-content: center;
    }

    .worlds-heading-internet {
        font-size: 13px;
        line-height: 18px;
    }

    .call-now-internet {
        font-size: 13px;
        padding-top: 14px;
    }

    .day-internet,
    .time-internet {
        font-size: 13px;
        line-height: 12px;
    }

    .call-wrapper-internet .scheduleCallButton {
        width: 100px !important;
        height: 35px !important;
        line-height: 24px !important;
        font-size: 11px !important;
    }

    .week-time-div-internet {
        padding-top: 27px;
    }

    .heading2-internet {
        font-size: 16px;
        line-height: 27px;
        padding-top: 0px;
    }

    .review img {
        width: 32px;
        height: 52px;
    }

    .carousel-internet {
        padding-top: 26px;
    }

    .frequently-internet {
        font-size: 16px;
        line-height: 15px;
        padding-bottom: 21px;
    }

    .accordion-internet {
        margin-bottom: 10px;
    }

    .accordion-internet .MuiTypography-body1 {
        font-size: 13px;
        line-height: 11px !important;
    }

    .accordion-internet .MuiAccordionSummary-root {
        height: 40px;
    }

    .address-internet .heading-internet {
        font-size: 16px;
    }

    .address-internet {
        width: 50%;
    }

    /* .how-it-works-card-div-internet .card-wrapper,
    .how-it-works-card-div-internet .active-card {
        
    } */
}

@media (max-width: 768px) {
    .arrowsInternet {
        margin-top: 100pxpx !important;
    }

    .arrow-wrapper-internet {
        left: 6%;
        right: 6%;
    }
    .phoneInternetLanding {
        font-size: 9px;
    }

    .phone-icon .MuiSvgIcon-root {
        margin-bottom: -2px;
    }

    .navlinkHeading {
        font-size: 9px;
    }

    .landingNav-li-internet {
        padding: 5px 5px;
        margin: 0 0px;
    }

    .landingHeaderButtonInternet {
        width: 65px !important;
    }

    .internetLanding-main-page {
        margin-top: -253px;
    }

    .worlds-internet {
        margin-top: 0px;
        padding-bottom: 26px;
    }

    .worlds-para-internet {
        font-size: 10px;
        line-height: 16px;
        width: 40%;
    }

    .main-div-internet .div-1 {
        width: 68%;
    }

    .main-div-internet .div-2 {
        width: 80%;
    }

    .main-div-internet .div-1 img,
    .main-div-internet .div-2 img {
        width: 74px;
    }

    .collectionInternet {
        padding: 25px 0;
    }

    .collectionCardImgInternet {
        width: 51px !important;
        height: 40px !important;
    }

    .collectionCardInternet {
        margin: 0 7px;
    }

    /* .how-it-works-card-div-internet {
        padding: 30px 45px 0 100px;
    } */

    .how-it-works-card-div-internet .card-wrapper,
    .how-it-works-card-div-internet .active-card {
        padding: 8px 8px;
        width: 227px;
    }

    .how-it-works-card-div-internet .linearProgressInternetLanding {
        width: 242px;
    }

    .card-desc-internet,
    .card-desc {
        font-size: 10px;
        line-height: 15px;
    }

    .call-wrapper-internet img {
        width: 233px;
        height: 271px;
    }

    .week-time-div-internet {
        padding-top: 18px;
    }

    .call-wrapper-internet {
        padding: 30px 65px 30px 31px;
    }

    .day-internet,
    .time-internet {
        font-size: 11px;
        line-height: 8px;
    }

    .carousel-inner .MuiCardContent-root {
        padding: 10px;
    }

    .carousel-inner .MuiCardContent-root:last-child {
        padding-bottom: 10px;
    }

    .heading2-internet {
        font-size: 20px;
        line-height: 18px;
        width: 95%;
    }

    .carousel-div-internet {
        padding-bottom: 10px;
    }

    .frequently-internet {
        font-size: 13px;
        line-height: 10px;
        padding-bottom: 16px;
    }

    .accordion-internet .MuiAccordionSummary-root {
        min-height: 20px;
        height: 30px;
    }

    .address-internet .heading-internet {
        font-size: 13px;
    }

    .address-internet {
        margin-top: 31px;
        width: 100%;
        padding-bottom: 39px;
    }

    .address-wrapper-internet .Para1 {
        margin: 0px 0px 0px 0px;
        font-size: 9px;
        display: flex;
        align-items: center;
    }

    .address-wrapper-internet .Para1 .MuiSvgIcon-root {
        font-size: 1rem;
    }

    .gpsIconDiv {
        font-size: 12px;
        display: flex;
        align-items: center;
    }

    .gpsIconDiv .MuiSvgIcon-fontSizeSmall {
        font-size: 1rem;
    }

    .address-wrapper-internet .boxCard-internet {
        width: 317px;
        padding: 10px 15px 15px 15px;
    }

    /* .address-wrapper-internet .input1 .MuiOutlinedInput-root,
    .address-wrapper-internet .input1 {
        width: 313px !important;
    }

    .address-wrapper-internet .input2 .MuiOutlinedInput-root,
    .address-wrapper-internet .input4 .MuiOutlinedInput-root {
        width: 108px !important;
    } */

    .innerInput2Internet .input3landing .MuiOutlinedInput-root {
        /* width: 79px !important; */
        width: 100% !important;
    }

    /* .address-wrapper-internet .input3 {
        width: auto !important;
        margin-right: auto !important;
    } */

    .collectionCardInternet {
        height: 176px !important;
        padding: 5px !important;
    }
}

@media (max-width: 600px) {
    .landingGetQuoteButtonInternet {
        width: 114px !important;
        height: 40px !important;
        font-size: 12px !important;
        margin-top: 0px !important;
    }

    .address-wrapper-internet .inputs-internet {
        padding: 20px 0;
    }

    .phone-icon .MuiSvgIcon-root {
        /* margin-bottom: 10px;
        margin-top: 2px; */
    }

    .address-wrapper-internet .Para1 {
        font-size: 12px;
    }
    .address-internet {
        width: 96%;
    }
    .carousel-internet {
        display: none;
    }
    .landingHeaderInternet {
        padding: 6px 12px;
        box-shadow: none;
    }

    .navLinkFlexDivInternet {
        justify-content: flex-end;
    }

    .landingHeaderInternet .navLinkFlexDivInternet nav {
        display: none;
    }

    .landingHeaderButtonInternet {
        display: none !important;
    }

    .internetLanding-main-page {
        margin-top: -280px;
        text-align: center;
        padding: 0 30px;
    }

    .dynamicHeaderImageDiv {
        margin-top: 220px;
    }

    .you-deserve-text-internet,
    .wfh-internet {
        font-size: 20px;
    }

    .you-deserve-text-internet,
    .wfh-internet {
        text-align: center;
    }

    .header-desc-internet {
        width: 100%;
        text-align: center;
    }

    .worlds-internet {
        margin-top: 72px;
    }

    .worlds-heading-internet {
        font-size: 20px;
        line-height: 32px;
    }

    .worlds-para-internet {
        font-size: 12px;
        line-height: 16px;
        width: 70%;
    }

    .main-div-internet {
        text-align: left !important;
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        overflow-x: scroll;
        padding: 0 10px;
    }

    .main-div-internet .div-1,
    .main-div-internet .div-2 {
        width: auto;
    }

    .main-div-internet .div-2 {
        margin-top: 0;
    }

    .main-div-internet .div-1 img,
    .main-div-internet .div-2 img {
        width: 80px;
        margin: 0 10px;
    }

    .collectionInternet {
        width: 85%;
        flex-wrap: wrap;
    }

    .collectionCardInternet {
        width: 100% !important;
        text-align: center;
        height: auto !important;
        margin: 10px 0;
    }

    /* .collectionCardTitle {
        font-size: 14px;
        text-align: center;
        margin: 13px 0 10px 0;
    } */

    /* .collectionCardPara {
        font-size: 13px;
        text-align: center;
        line-height: 22px;
        height: auto;
    } */

    .collectionCardImgInternet {
        width: 64px !important;
        height: 50px !important;
    }

    .call-wrapper-internet {
        padding: 20px 20px;
    }

    .call-wrapper-internet .imgDiv {
        display: none;
    }

    .call-wrapper-internet .scheduleCallDiv {
        width: 100%;
        text-align: center;
    }

    .card-desc-internet,
    .card-desc {
        font-size: 12px;
        line-height: 18px;
        text-align: center;
    }

    .week-time-internet {
        justify-content: space-between;
    }

    .day-internet,
    .time-internet {
        text-align: left;
        font-size: 12px;
        line-height: 17px;
    }

    .compare-section-internet {
        padding: 15px 10px;
    }

    .questions-internet {
        width: 95%;
    }

    .MuiAccordion-rounded {
        border-radius: 0 !important;
    }

    .address-wrapper-internet .boxCard-internet {
        width: 92%;
        margin-right: 0;
        margin-left: 0;
    }

    .address-wrapper-internet .innerInputs-internet {
        flex-wrap: wrap;
        margin-top: 20px;
        margin-bottom: 0;
    }

    /* .address-wrapper-internet .input1 .MuiOutlinedInput-root,
    .address-wrapper-internet .input1,
    .address-wrapper-internet .input2 .MuiOutlinedInput-root,
    .address-wrapper-internet .input3 .MuiOutlinedInput-root,
    .address-wrapper-internet .input4 .MuiOutlinedInput-root {
        width: 390px !important;
    } */

    .landingFormButtonDiv {
        display: flex;
        justify-content: flex-end;
    }

    .how-it-works-wrapper-internet .card-img-wrapper-internet {
        display: none;
    }

    .accordion-internet .MuiTypography-body1,
    .accordion-internet .MuiAccordionDetails-root .MuiTypography-body1 {
        font-size: 12px;
        line-height: 22px;
    }

    .frequently-internet {
        font-size: 20px;
        line-height: 32px;
        padding-bottom: 20px;
    }

    .address-internet .heading-internet {
        font-size: 20px;
        line-height: 32px;
    }

    .call-now-internet {
        font-size: 16px;
        line-height: 30px;
    }

    .how-it-works-tab-mobile {
        display: block;
    }

    .how-it-works-wrapper-internet {
        padding: 29px 15px 0 15px;
    }

    .mobile-div-internet {
        display: flex;
        justify-content: center;
        position: fixed;
        width: 100%;
        bottom: 10px;
        z-index: 200;
    }

    .getStartedButtonInternet {
        width: 98% !important;
        font-size: 14px !important;
        height: 60 !important;
        border-radius: 4px !important;
        background-color: #30ab86 !important;
        font-family: 'Lato', sans-serif;
        margin: auto !important;
    }

    .carousel-2-internet {
        display: block;
        padding-bottom: 50px;
        padding-top: 35px;
        margin-left: auto;
        margin-right: auto;
        background: #415b76;
        text-align: center;
        font-family: 'Lato', sans-serif;
    }

    /* .carousel-div-internet {
        width: 80%;
        margin-left: auto;
        margin-right: auto;
    
    } */
}

@media (max-width: 380px) {
    /* .address-wrapper-internet .input1 .MuiOutlinedInput-root,
    .address-wrapper-internet .input1,
    .address-wrapper-internet .input2 .MuiOutlinedInput-root,
    .address-wrapper-internet .input3 .MuiOutlinedInput-root,
    .address-wrapper-internet .input4 .MuiOutlinedInput-root {
        width: 340px !important;
    } */
}

@media (max-width: 360px) {
    /* .address-wrapper-internet .input1 .MuiOutlinedInput-root,
    .address-wrapper-internet .input1,
    .address-wrapper-internet .input2 .MuiOutlinedInput-root,
    .address-wrapper-internet .input3 .MuiOutlinedInput-root,
    .address-wrapper-internet .input4 .MuiOutlinedInput-root {
        width: 320px !important;
    } */
}
