.loan-details-grp1 {
    display: flex;
}

.loan-details-grp1-info {
    display: flex;
    flex-direction: column;
}

.loan-details-grp1-info-title {
    text-align: left;
    color: #333333;
    font: bold 24px Roboto !important;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
}

.loan-details-grp1-info-description {
    margin-top: 12px;
    font: normal 14px Roboto;
    letter-spacing: 0px;
    color: #D92F25 !important;
    opacity: 1;
    text-align: left;
    word-wrap: break-word;
    overflow-wrap: break-word;
    width: 75%;
}

@media(max-width: 375px) {

    .loan-details-grp1-info-title {
        font-size: 20px !important;
    }
}