.provider-image{
    width:143px;
}

.martford-image{
    width:143px;
    height: 75px;
}

.core-feature-upper-div{

    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    width: 66%;
}

.sub-card{
 width:25%
}

.core-heading{
    color: #3E3F42;
    opacity: 1;
    font-size: 20px;
    font-weight: 900;
    font-family: 'Lato', sans-serif;
}

.core-subheading{
    letter-spacing: 0px;
color: #333333;
opacity: 0.8;
font-size: 14px;
font-weight: 300;
}

.core-feature-bottom-div{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    width: 66%;
    margin-top: 30px;
    padding-bottom: 47px;

}