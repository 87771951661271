.mainSection {
    width: 100%;
    margin: auto;
}

.headingText {
    text-align: left;
    font-size: 14px;
    margin: 20px auto;
    font-family: 'Roboto';
    font-weight: bold;
    letter-spacing: 0px;
    color: #6b6c6f;
    text-transform: capitalize;
    opacity: 1;
}

.inputSelected[type='text'] {
    font-size: 18px;
    font-weight: bold;
    padding-left: 10px;
    color: #333333;

    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #d8dce6;
    border-radius: 4px;
    width: 140px;
    height: 55px;
    opacity: 1;
    margin: 2px auto 10px;
}

.inputSelected:focus {
    outline: none;
}
.radioButtonSelected {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #006dff;
    border-radius: 4px;
    width: 182px;
    height: 50px;
    opacity: 1;
    align-items: center;
    display: flex;
    margin: 10px 0px;
    justify-content: left;
}
.radioButtonPerSelected {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #d8dce6;
    border-radius: 4px;
    width: 182px;
    height: 39px;
    opacity: 1;
    padding: 17px 10px 19px;
    color: #333333;
    font-weight: bold;
}

.radioButtonNotSelected {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #d8dce6;
    border-radius: 4px;
    width: 182px;
    height: 50px;
    opacity: 1;
    align-items: center;
    display: flex;
    margin: 10px 0px;
    justify-content: left;
}

.text {
    padding-top: 1px;
    text-align: left;
    font-size: 14px;
    font-family: 'Roboto';
    font-weight: bold;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
}

.blurText {
    padding-top: 1px;
    text-align: left;
    font-size: 14px;
    font-family: 'Roboto';
    font-weight: normal;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
}

.boldest-24px-text {
    text-align: left;
    margin-left: 4px;
    font: normal normal bold 24px/23px Poppins;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
}

.light-12px-roboto {
    text-align: left;
    font-size: 12px;
    letter-spacing: 0px;
    color: #6b6c6f;
    text-transform: capitalize;
    opacity: 1;
}

.estimationLabelCover {
    margin-bottom: 10px;
    margin-left: 4px;
    display: flex;
    align-items: centers;
}

.dark-12px-roboto {
    text-align: left;
    font-size: 12px;
    letter-spacing: 0px;
    font-weight: bold;
    color: #333333;
    text-transform: capitalize;
    opacity: 1;
}

.dark-14px-roboto {
    text-align: left;
    font-size: 14px;
    letter-spacing: 0px;
    font-weight: bold;
    color: #333333;
    text-transform: capitalize;
    opacity: 1;
}
.dollarArrangment {
    position: absolute;
    font-weight: 900;
    color: #000;
    display: inline-block;
    margin-left: -12px;
    margin-top: -10px;
}

.equity-grey-box {
    background: #eeeeee 0% 0% no-repeat padding-box;
    border: 0.5px solid #e0e0e0;
    opacity: 1;
    padding: 20px;

    .estimationContainer {
        width: 90%;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        .estimationLabel {
            flex: 0.4; 
            margin-top: 10px;
            @media(max-width: 480px) {
                width: 30%;
                flex: 0.6; 
            }
        }
        .estimationValue {
            margin-left: auto;
            display: flex;
            justify-content: left;
            align-items: left;
            flex-direction: column;
            flex: 0.6;

            @media(max-width: 480px) {
                width: 70%;
                flex: 1;
            }

        }

      

        @media(max-width: 480px) {
            width: 100%;
            
        }
    }

    @media(max-width: 480px) {
        padding: 14px; 
    }
}
.WithoutGraySection {
    width: 90%;
    margin: auto;
    .purchaseAndRent {
        display: flex;
        justify-content: space-between;
        width: 100% !important;

        @media(max-width: 768px) {
            width: 100%;
        }
    }

    .headingText {
        @media(max-width: 480px) {
            font-size: 12px !important;
        }
    }
    .inputFeildMain1  {
        @media(max-width: 480px) {
            width: 100% !important;
        }  
    }

    .specCalcFieldsCover {
        .headingText {
            color: #6b6c6f !important;
        }
        .downpaymentInputCover {
            .inputFeildMain1 {
                border-top-right-radius: 0px;
                border-bottom-right-radius: 0px;
                &:last-child {
                     border-top-left-radius: 0px;
                     border-bottom-left-radius: 0px;
                     border-top-right-radius: 4px;
                     border-bottom-right-radius: 4px;
                  
                    width: 20% !important;
                    max-width: 60px !important;
                    @media(max-width: 480px) {
                        max-width: 100% !important;
                        width: 30% !important;
                    } 
                    @media(max-width: 340px) {
                        width: 34% !important;
                    } 
                }
                @media(max-width: 480px) {
                    width: 70% !important;
                } 
                @media(max-width: 340px) {
                    width: 66% !important;
                }
            }  
        }

        @media(max-width: 768px) {
            display: flex;
            align-items: center;

            .affFieldsCover {
                width: 50%;
                margin-right: 5px;
                .purchasePriceCover, .rentCover {
                    width: 98%;
                    .inputFeildMain1  {
                        width: 100% !important;
                    }
                }
            }
            .purchaseAndRent {
                width: 100% !important;
            }

            .downpaymentInputCover {
                width: 50%;

                .inputFeildMain1 {
                    width: 80% !important;
                }

                .headingText {
                    margin: 10px auto;
                }
            }
        }

        @media(max-width: 480px) {
            .downpaymentInputCover {
                .inputFeildMain1 {
                    width: 70% !important;
                }
            }
        } 

        @media(max-width: 380px) {
            align-items: flex-start;
            .downpaymentInputCover {
                .headTextCover {
                    padding-top: 6px;

                    .headingText {
                        display: none;
                    }

                    &::after {
                        content: 'Down Payment';
                        text-align: left;
                        font-size: 12px;
                        font-family: 'Roboto';
                        font-weight: bold;
                        letter-spacing: 0px;
                        color: #6b6c6f;
                        text-transform: capitalize;
                        opacity: 1;
                    }

                }
                .inputFeildMain1 {
                    transform: translateY(8px);
                }
            }
           
        } 

        @media(max-width: 340px) {
            .downpaymentInputCover {
                .inputFeildMain1 {
                    width: 66% !important;
                }
            }
        } 

        &.fieldsWithRent {
            flex-wrap: wrap;

            .affFieldsCover {
                width: 50%;
                .purchasePriceCover, .rentCover {
                    width: 48%;
                }
            }
            .downpaymentInputCover {
                width: 100% !important;
                .inputFeildMain1 {
                    width: 80% !important;
                    &:last-child {
                        width: 20% !important;
                    }
                }
            }
        }
    }
}
.interestRateField {
    .errMessage {
        position: absolute;
        max-width: 170px;
    }
}
.saveBtnCover {
    margin: 20px 0px;
}
.equity-grey-boxTextHeading {
    text-align: left;
    font: normal normal bold 14px/24px Roboto;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
}
.equity-grey-boxTextSubHeading {
    text-align: left;
    font: normal normal medium 10px/22px Roboto;
    letter-spacing: 0px;
    color: #6b6c6f;
    text-transform: capitalize;
    margin: 6px 0px;
    opacity: 1;

    @media(max-width: 480px) {
        font-size: 12px;
    }
}
.equity-grey-boxText {
    margin-bottom: 30px;

    @media(max-width: 480px) {
        .inputFeildMain1 {
            width: 162px !important;
            padding: 14px 10px 14px 18px !important;

            .per {
                left: 10px;
            }
            .inputFeild1 {
                margin-left: 12px !important;
            }
        }
    }
}
.interestRateCover {
    .inputFeild1 {
        @media(max-width: 480px) {
            width: 90% !important;
        }
    }
}
.equity-greyInput {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    opacity: 1;
    padding: 14px 5px;
}
.headingInfoIcon {
    text-align: center;
    margin-left: 40px;
}

.inputFeildMain1 {
    position: relative;

    border: 1px solid #ced4da;
    opacity: 1;
    border-radius: 4px;
    width: 90%;

    padding: 14px 10px 14px 10px;
    @media screen and (max-width: 768px) {
        width: 100%;
    }

    .per {
        position: absolute;
        left: 4px;
        top: 3;
        font: bold 24px/24px Roboto;
        color: #6b6c6f;
    }
    .per2 {
        position: absolute;
        left: 40px;
        top: 16px;
        font: bold 14px/24px Roboto;
        color: #6b6c6f;
        @media screen and (max-width: 768px) {
            left: 30px;
        }
    }
    .per1 {
        position: absolute;
        left: 3;
        top: 3;
        font: bold 14px/24px Roboto;
        color: #6b6c6f;
    }
    .inputFeild {
        border: none;
        font: bold 24px/24px Roboto;
        color: #333333;
    }
    .inputFeild:focus {
        outline: none;
    }
    .inputFeild1 {
        width: 97%;
        margin: auto;
        font: bold 14px/24px Roboto;
        border: none;
        margin-left: 8px;

        color: #333333;
    }
    .inputFeild1:focus {
        outline: none;
    }
}
