.utility-powersaving-head {
    .heading {
        font-size: 24px;
        font-weight: 600;
        font-family: sans-serif;
        @media (max-width: 600px) {
            font-size: 22px;
        }
    }
    margin: 30px 0px 0px 80px;

    @media (max-width: 1366px) {
        margin: 30px 0px 0px 60px;
    }

    @media (max-width: 768px) {
        margin: 30px 60px 0px 60px;
    }

    @media (max-width: 475px) {
        margin: 30px 0px 0px 10px;
    }
}

.Para {
    color: #bebebe;
}

.path {
    display: flex;
    // margin-top: -25px;
}
.steps1UtilityPowerSaving {
    margin: 40px 80px 0px 0px;

    @media (max-width: 1366px) {
        margin: 40px 60px 0px 0px;
    }

    @media (max-width: 768px) {
        margin: 40px 10px 0px 0px;
    }
}
.utilityPowerSavingLeft {
    width: 78%;
    @media (max-width: 768px) {
        width: 100%;
    }

    // @media (max-width: 600px) {
    //     width: 100%;
    // }
}
.utilityPowerSavingRight {
    width: 20%;
    @media (max-width: 768px) {
        width: 50%;
        padding-top: 35px;
    }
    @media (max-width: 768px) {
        display: none;
    }

    // @media (max-width: 600px) {
    //     width: 100%;
    //     padding: 10px 0;
    //     display: none;
    // }
}
.steps1UtilityPowerSavingMain {
    display: flex;
    justify-content: space-between;

    .noPlanMainDiv {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 100px;

        .noPlansHeading {
            font-size: 18px;
            line-height: 22px;
            color: #333333;
            font-weight: 600;
            margin: 30px 0 20px;
        }

        .noPlansSubHeading {
            font-size: 16px;
            line-height: 22px;
            color: #6b6c6f;
            @media (max-width: 375px) {
                font-size: 14px;
            }
            @media (max-width: 320px) {
                font-size: 12px;
            }
        }
    }

    @media (max-width: 768px) {
        flex-direction: column;
        justify-content: center;
    }
}
.utilityPowerSavingHead {
    font-size: 22px;
    font-family: 'Roboto';
    font-weight: bold;
    @media (max-width: 768px) {
        width: 70%;
    }
    @media (max-width: 475px) {
        font-size: 18px;
    }
}
.utilityPowerSavingLeftHead {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .mobileFilterOpeningModal {
        display: none;
        @media (max-width: 768px) {
            display: unset;
        }
    }
}
.otherElectricityPlans {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .planDataLengthPwer {
        color: #6b6c6f;
    }
}
.marginFilter {
    padding-bottom: 14px;
}
.powerKiosCards {
    display: flex;
    flex-wrap: wrap;
    padding: 48px 0px;
    // justify-content: space-between;
}
.planDataPowerKiosk {
    @media (max-width: 768px) {
        display: flex;
        flex-wrap: wrap;
    }
}

.viewPlanDetailsEnergyModal {
    width: 70%;
    background: #ffffff;
    height: 100%;
    position: absolute;
    right: 0;
    overflow-y: auto;
    @media (max-width: 768px) {
        width: 100%;
    }

    .modalmaindiv {
        padding: 80px 110px 80px 45px;
        @media (max-width: 768px) {
            padding: 30px 12px 30px 12px;
        }

        .backButtonDiv {
            display: flex;
            font-size: 22px;
            font-weight: bold;

            .backicon {
                font-size: 30px;
                font-weight: bold;
                margin-right: 30px;
            }
        }

        .flexDivModal {
            display: flex;
            justify-content: space-between;
            margin: 20px;

            .kwhHour {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .topKwhHourdiv {
                    font-size: 14px;
                    line-height: 24px;
                    color: #333333;
                }

                .middleKwhHourdiv {
                    font-size: 48px;
                    line-height: 23px;
                    font-weight: bold;
                    margin: 10px 0;
                    @media (max-width: 600px) {
                        font-size: 28px;
                    }
                }

                .bottomKwhHourdiv {
                    font-size: 14px;
                    line-height: 24px;
                    color: #6b6c6f;
                }
            }
        }

        .aboutDiv {
            .aboutTitleDiv {
                font-size: 22px;
                line-height: 30px;
                font-weight: bold;
                @media (max-width: 600px) {
                    font-size: 16px;
                }

                .recommended {
                    font-size: 14px;
                    line-height: 24px;
                    font-weight: bold;
                    color: #ffffff;
                    background: #006dff;
                    padding: 5px 10px;
                    border-radius: 5px;
                    margin-left: 20px;
                }
            }
            .aboutData {
                font-size: 16px;
                line-height: 22px;
                color: #333333;
                margin: 20px 0;
                @media (max-width: 600px) {
                    font-size: 14px;
                }
            }

            .planMonthlyrateFlexDiv {
                display: flex;
                margin: 20px 0;

                .planMonthlyrateDiv {
                    font-size: 16px;
                    line-height: 22px;
                    font-weight: bold;
                    width: 25%;
                }
            }
        }

        .planFlexDiv {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            .planflexDivInside {
                display: flex;
                justify-content: center;
                width: 50%;
                margin: 20px 0;
                @media (max-width: 600px) {
                    flex-direction: column;
                    justify-content: flex-start;
                }

                .title {
                    font-size: 16px;
                    line-height: 22px;
                    color: #333333;
                    width: 50%;
                    padding-top: 0;
                    @media (max-width: 600px) {
                        width: 100%;
                    }
                }
                .data {
                    cursor: pointer;
                    font-size: 16px;
                    line-height: 22px;
                    color: #333333;
                    width: 50%;
                    font-weight: bold;
                    @media (max-width: 600px) {
                        width: 100%;
                    }
                }
            }
        }

        .buttonDivUtilityPlan {
            width: 100%;
            // margin: auto;
            // display: flex;
            // justify-content: center;
            margin: 20px auto 0px auto;
            @media (max-width: 600px) {
                button {
                    width: 100% !important;
                }
            }
        }
    }
}

.viewFiltersUtilityModal {
    width: 70%;
    background: #ffffff;
    min-height: 100vh;
    position: absolute;
    right: 0;
    @media (max-width: 600px) {
        width: 100%;
        height: 100%;
    }

    .modalmaindivFilter {
        padding: 20px 12px;
        .backButtonFilterDiv {
            margin-top: 50px;
            display: flex;
            font-size: 22px;
            font-weight: bold;
            padding-bottom: 20px;

            .backicon {
                font-size: 30px;
                font-weight: bold;
                margin-right: 30px;
            }
        }

        .filterMobileModalUtility {
            margin-bottom: 40px;
        }
        .submitButtonFilterUtility {
            width: 100% !important;
        }
    }
}
