.getStartedCard {
    display: flex;
    align-items: center;
    border: 1px solid #d92f25;
    border-radius: 4px;
    padding: 20px;
    width: 49%;
}

.getStartedImageDiv {
    width: 25%;
    margin-right: 15px;
    height: 100%;

    @media (max-width: 1024px) {
        width: 34%;
    }

    @media (max-width: 425px) {
        width: 30%;
    }
}
.getStartedImageDivD {
    width: 25%;
    margin-right: 15px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 1024px) {
        width: 34%;
    }

    @media (max-width: 425px) {
        width: 30%;
    }
}

.getStartedImage {
    width: 253px;
    height: 250px;

    @media (max-width: 425px) {
        width: 87px;
        height: 86px;
    }
}
.getStartedImageD {
    margin-right: unset !important;
    @media (max-width: 600px) {
        height: 50px !important;
    }
}

.getStartedContentDiv {
    padding: 0 20px;

    padding: 0 20px 10px 20px;

    @media (max-width: 425px) {
        padding: 0 20px;
        width: 70%;
    }
}

.getStartedCardTitle {
    font-size: 22px;
    line-height: 30px;
    text-transform: capitalize;
    font-weight: bold;
    font-family: 'Roboto';

    @media (max-width: 1024px) {
        font-size: 18px;
    }

    @media (max-width: 425px) {
        font-size: 18px;
    }
}

.getStartedCardPara {
    font-size: 14px;
    line-height: 22px;
    font-family: 'Roboto';
    color: #6b6c6f;
    margin-bottom: 30px;

    @media (max-width: 425px) {
        font-size: 12px;
        line-height: 17px;
    }
}

.currentStatus {
    font-size: 14px;
    line-height: 24px;
    font-weight: bold;
    font-family: 'Roboto';
    color: #6b6c6f;
}

.progress {
    margin-bottom: 50px;
    background-color: transparent;
}

.progressDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: transparent;
    margin: 10px 0 20px 0;
    width: 75%;

    @media (max-width: 768px) {
        width: 100%;
    }
}

.progressBarDiv {
    width: 65%;
}

.progressBarDiv .MuiLinearProgress-barColorPrimary {
    background-color: #00d56d;
}

.getStartedDashboardButton {
    width: 150px !important;
    height: 40px !important;
    border-radius: 5px !important;
    border: 1px solid #d92f25 !important;
    box-shadow: 0 1px 1px #161d251a !important;
    font-size: 14px !important;
    line-height: 22px !important;
    // font-weight: normal !important;
    color: #d92f25 !important;
    background-color: transparent !important;
}
.getStartedDashboardButtonD {
    border-radius: 0px !important;
    border: 1px solid #100b28 !important;
    box-shadow: none !important;
    background: #100b28 !important;
    color: #fff !important;
}
.getStartedDashboardButtonDSke {
    border-radius: 0px !important;
    border: 1px solid #eee !important;
    box-shadow: none !important;
    background: #eee !important;
    color: #eee !important;
}
.timer {
    font-size: 14px;
    line-height: 24px;
    color: #333333;
    font-weight: bold;
    font-family: 'Roboto';
    display: flex;
    align-items: center;
}

.timerIcon {
    margin-right: 10px;
}

.svg-image-mobile svg {
    @media (max-width: 600px) {
        width: 100% !important;
    }
}

.douglasEllimenSite {
    .timer {
        width: 170px;
        font-size: 14px;
        line-height: 24px;
        color: #333333;
        font-weight: bold;
        font-family: 'Roboto';
        display: flex;
        align-items: center;
        margin: 10px;
        margin-top: 0px;
    }
    .progressBarDiv {
        width: 63%;
    }
}
