.awesomeSavingsPage {
    // margin-top: -70px;
    // min-height: 100vh;
    background-color: #eeeeee;
    .progressBarFinalSavings {
        margin-top: 78px;
        @media (max-width: 426px) {
            margin-top: 58px;
        }
    }
    .awesomeSavingsWhiteBackground {
        width: 75%;
        margin: 0px auto;
        background-color: #fff;
        @media (max-width: 768px) {
            width: 100%;
        }
        .awesomeSavingsInnerContentFinal {
            display: flex;
            flex-direction: column;

            padding: 50px 30px 140px 30px;
            @media (max-width: 600px) {
                padding: 20px 30px 140px 30px;
            }
            @media (max-width: 320px) {
                padding: 20px 15px 140px 10px;
            }
            .awesomeSavingsInnerData {
                padding: 0px 200px;
                @media (max-width: 1300px) {
                    padding: 0px 155px;
                }
                @media (max-width: 1024px) {
                    padding: 0px 50px;
                }
                @media (max-width: 600px) {
                    padding: 0px;
                }
                .awesomeSavingsInnerImage {
                    display: flex;
                    justify-content: center;
                    @media (max-width: 320px) {
                        width: 90%;
                    }
                }
                .awesomeSavingsInnerHead {
                    text-align: center;
                    font-size: 30px;
                    font-family: 'Roboto';
                    padding-bottom: 7px;
                    @media (max-width: 600px) {
                        font-size: 24px;
                    }
                }
                .awesomeSavingsInnerSubHead {
                    text-align: center;
                    font-size: 14px;
                    color: #6b6c6f;
                    font-family: 'Roboto';
                    padding-bottom: 25px;
                }
                .awesomeSavingsInnerDiscount {
                    font-family: 'Poppins';
                    font-size: 48px;
                    font-weight: bold;
                    text-align: center;
                    // padding-bottom: 50px;
                    display: flex;
                    align-items: flex-start;
                    justify-content: center;
                    @media (max-width: 600px) {
                        font-size: 38px;
                    }
                    span {
                        text-align: center;
                        font-size: 24px;
                        font-weight: lighter;
                    }
                }
                .awesomeSavingsInnerNext {
                    text-align: left;
                    font-size: 22px;
                    font-family: 'Roboto';
                    padding-bottom: 18px;
                    @media (max-width: 600px) {
                        font-size: 18px;
                    }
                }
                .awesomeSavingsInnerNotInterested {
                    font-size: 14px;
                    font-family: 'Roboto';
                    color: #0066f5;
                    padding-top: 40px;
                    text-align: center;
                    cursor: pointer;
                }
                .awesomeSavingsInnerCard {
                    border: 1px solid #e2e2e2;
                    border-radius: 4px;
                    opacity: 1;
                    width: 90%;
                    display: flex;
                    box-shadow: 0px 3px 10px #00000029;
                    @media (max-width: 600px) {
                        width: 100%;
                    }
                    // justify-content: center;
                    .awesomeSavingsCardContent {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        padding: 18px 19px 17px 19px;
                        @media (max-width: 400px) {
                            flex-direction: column;
                        }
                        .awesomeSavingsCardContentImage {
                            padding-right: 28px;
                            width: 40%;

                            img {
                                max-width: 100%;
                                width: 100%;
                                border-radius: 50%;
                            }
                        }
                        .awesomeSavingsCardContentInner {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;

                            .awesomeSavingsCardContentSchedule {
                                font-size: 22px;
                                font-weight: bold;
                                font-family: 'Roboto';
                                padding-bottom: 6px;
                                @media (max-width: 600px) {
                                    font-size: 18px;
                                }
                            }
                            .awesomeSavingsCardContentConies {
                                font-size: 14px;
                                font-family: 'Roboto';
                                line-height: 24px;
                                @media (max-width: 600px) {
                                    font-size: 12px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
.confirmSavingsPage {
    margin-top: 70px;
    padding: 0px 40px;
    .confirmSavingsWhiteBackground {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .mainConfirmRight {
            width: 40%;
        }
        .mainConfirmLeft {
            width: 55%;
        }
        .mainHeadConfirm {
            font-size: 32px;
            font-weight: bold;
            font-family: 'Roboto';
            padding-bottom: 20px;
        }
        .subHeadConfirm {
            font-size: 22px;
            font-family: 'Roboto';
            color: #6b6c6f;
        }
    }
}
