.nextMoveFlowMainPage {
    // margin-top: -70px;
    min-height: 80vh;
    .progressBarFinalMove {
        // position: fixed;
        margin-top: 78px;
        @media (max-width: 426px) {
            margin-top: 58px;
        }
    }
    // .checkcheck {
    //     position: fixed;
    // }
    .nextMoveFlowMainFlexDiv {
        display: flex;
        justify-content: center;
        max-height: 87vh;
        // @media (max-width: 600px) {
        //     display: block;
        // }

        .imageContentNextMoveDiv {
            width: 40%;
            text-align: center;
            background: linear-gradient(#eeeeee, #ffffff);
            box-shadow: 0 1px 5px #eeeeee;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            padding-bottom: 30px;
            max-height: 640px;
            @media (max-width: 600px) {
                display: none;
            }

            .image {
                img {
                    width: 100%;
                }
            }

            .contentDiv {
                padding: 0 60px;

                .contentTitle {
                    font-size: 24px;
                    line-height: 32px;
                    font-weight: bold;
                    margin-bottom: 20px;
                }

                .contentDetails {
                    font-size: 16px;
                    line-height: 22px;
                    color: #333333;
                }
            }
        }
        .stepperNextMoveMainDiv {
            width: 60%;
            padding: 30px 100px;
            overflow-y: scroll;

            @media (max-width: 900px) {
                padding: 30px 25px;
            }
            @media (max-width: 600px) {
                width: 100%;
            }
            @media (max-width: 475px) {
                padding: 30px 10px;
            }
            .MuiStepContent-root {
                @media (max-width: 475px) {
                    border-left: unset;
                    padding-left: 0px;
                }
            }
            .MuiStepConnector-line {
                @media (max-width: 475px) {
                    border-color: unset;
                    padding-left: 0px;
                }
            }
            .MuiStepConnector-lineVertical {
                @media (max-width: 475px) {
                    border-top-style: outset;
                    border-left-style: unset;
                }
            }

            .nextMoveHeading {
                font-size: 30px;
                line-height: 32px;
                color: #333333;
                margin: 30px 0px 60px 0px;
                font-weight: bold;
                @media (max-width: 700px) {
                    font-size: 24px;
                }
                @media (max-width: 600px) {
                    // font-size: 16px;
                    margin: 30px 0px 37px 0px;
                }
                @media (max-width: 375px) {
                    font-size: 16px;
                    margin: 0px 0px 20px 0px;
                }
            }
            .StepperDivNextMove {
                .MuiStepper-root {
                    @media (max-width: 475px) {
                        padding: 0px;
                    }
                    @media (max-width: 700px) {
                        padding: 0px;
                    }
                }
                .labelDiv {
                    .labelDivMain {
                        font-size: 18px;

                        color: #747e8b;
                        @media (max-width: 375px) {
                            font-size: 14px;
                            font-weight: 500;
                        }
                        // margin-top: 5px;
                    }
                    .labelNextMove {
                        font-size: 18px;
                        @media (max-width: 375px) {
                            font-size: 14px;
                            font-weight: 500;
                        }

                        // margin-top: 5px;
                    }
                    .secondaryLabel {
                        font-size: 12px;
                        line-height: 16px;
                        color: #747e8b;
                        margin-top: 5px;
                    }
                }
                .MuiStepLabel-label {
                    display: flex;
                    justify-content: space-between;
                }

                .MuiStepLabel-label.MuiStepLabel-active {
                    color: #333333;
                    font-weight: 600;
                }

                .MuiStepLabel-label.MuiStepLabel-completed {
                    color: #333333;
                }
                .MuiStepIcon-root.MuiStepIcon-completed {
                    color: #00d56d;
                }

                .MuiStepLabel-label {
                    color: #6b6c6f;
                    font-size: 22px;
                    line-height: 28px;
                }

                .MuiStepLabel-root {
                    align-items: normal;
                }

                .savingStepButton {
                    float: right;
                    width: 15%;

                    .savingNextButton {
                        width: 100% !important;
                        height: 40px !important;
                    }
                    @media (max-width: 500px) {
                        float: none;
                        width: 100%;
                    }
                }
                .activateNextMove {
                    font-size: 24px;
                    font-family: 'Roboto';
                    padding-bottom: 8px;
                    @media (max-width: 375px) {
                        font-size: 16px;
                        font-weight: 500;
                    }
                }
                .activateGetAccess {
                    font-size: 16px;
                    font-family: 'Roboto';
                    color: #6b6c6f;
                    line-height: 28px;
                    @media (max-width: 375px) {
                        font-size: 12px;
                    }
                }
                .nextMoveStepButton {
                    display: flex;
                    justify-content: flex-end;
                    margin-top: 40px;
                    @media (max-width: 500px) {
                        justify-content: center;
                        button {
                            width: 100% !important;
                        }
                    }
                }
                .greatBrokerageNextMove {
                    font-size: 20px;
                    font-weight: bold;
                    font-family: 'Roboto';
                    padding: 40px 0px 26px 0px;
                    line-height: 32px;
                    @media (max-width: 375px) {
                        font-size: 16px;
                    }
                }

                .nextMoveStepsContent {
                    .activateLastStep {
                        display: flex;
                        flex-wrap: wrap;
                        .activateCardNextMove {
                            margin-top: 25px;
                        }
                    }
                    .nextMoveStepsApproval {
                        margin-top: 50px;
                        @media (max-width: 600px) {
                            margin-top: 30px;
                        }
                    }
                    .inputDivSavings {
                        display: flex;
                        justify-content: space-between;
                        margin: 10px 0 20px;
                        .inputFlowNextMove {
                            .MuiFormGroup-root {
                                display: flex;
                                flex-direction: row;
                            }
                            .MuiRadio-colorSecondary.Mui-checked {
                                color: #0066f5;
                                border: #0066f5;
                            }
                        }

                        .inputDiv1 {
                            width: 48%;
                            display: flex;
                            align-items: center;
                            @media (max-width: 500px) {
                                width: 100%;
                            }

                            .inputDiv2 {
                                width: 30%;
                            }

                            .percentageDiv {
                                margin-top: 20px;
                                font-size: 24px;
                                line-height: 22px;
                                color: #6b6c6f;
                                margin-left: 20px;
                            }
                            .addressNextMoveMain {
                                font-size: 16px;
                                font-family: 'Roboto';
                                font-weight: normal;
                                span {
                                    font-weight: bold;
                                }
                            }
                        }

                        .inputDiv3 {
                            display: flex;
                        }
                        .css-2b097c-container {
                            width: 100%;
                        }

                        .react-select .select-state__control {
                            min-height: 50px;
                        }
                    }
                }
                .MuiCollapse-wrapperInner {
                    @media(max-width: 1350px) {
                        width: auto;
                    }
                    @media(max-width: 375px) {
                        width: 100%;
                    }
                }
            }
        }
    }
}
