.footer {
    // position: sticky;
    // left: 0;
    // bottom: 0;

    display: flex;
    align-items: center;
    padding-top: 30px;
    font-family: "Lato", sans-serif;
}

.footer-mobile {
    display: none;
}

.footer-main-div {
    width: 100%;
    background-color: #333333;
    color: #ffffff;
}

.footer-start {
    //  padding:100px 130px 130px 120px;
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 55px;
}

.footer-secondary-div {
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    // align-items: center;
}

.Heading-div {
    font-size: 16px;
    line-height: 22px;
    color: #ffffff;
    padding: 30px 20px;
    font-weight: bold;
}

.footer-text {
    font-size: 25px;
    font-weight: 600;
}

.footer-subheading {
    font-size: 14px;
    font-weight: normal;
    line-height: 22px;
    //  text-align: center;
    padding-bottom: 22px;
    padding-left: 22px;
}
.footer-bottom-div {
    display: flex;
    justify-content: center;
    font-size: 12px;
    line-height: 22px;
    padding: 30px;
    font-family: "Lato", sans-serif;
    margin-bottom: -10%;
}

@media (max-width:1366px) {
    .Heading-div {
        font-size: 15px;
        line-height: 20px;
        padding: 20px 15px;
    }

    .footer-subheading {
        font-size: 13px;
        line-height: 20px;
        padding-bottom: 16px;
        padding-left: 13px;
    }
}

@media( max-width: 1024px) {
    .Heading-div {
        font-size: 13px;
        line-height: 11px;
        padding: 13px 10px;
    }

    .footer-subheading {
        font-size: 11px;
        line-height: 13px;
        padding-bottom: 10px;
        padding-left: 9px;
    }

    .footer-bottom-div {
        font-size: 10px;
        line-height: 19px;
        padding: 18px;
    }

    .footer-start img {
        width: 158px;
        height: 32px;
    }
}

@media (max-width: 425px) {
    .footer {
        display: none;
    }

    .footer-mobile {
        display: block;
        padding: 25px 10px 20px 10px;
        font-family: 'Lato', sans-serif;
    }

    .footerImg-div {
        text-align: center;
    }

    .footerImg-div img {
        width: 146px;
        height: 37px;
    }

    .footer-heading-div {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
    }

    .footer-heading {
        font-size: 16px;
        line-height: 22px;
    }

    .footer-li {
        list-style: none;
        font-size: 14px;
        line-height: 22px;
        opacity: 0.7;
        padding: 4px 0;
        margin-left: -10px;
    }

    .footer-ul {
        margin-top: 6px;
    }
}