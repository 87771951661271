@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
.mb_50{
    margin-bottom: 50px;
}
.innerbox_width {
    font-family: 'Roboto', sans-serif;
    background-color: #FFFFFF;
    box-shadow: 0px 3px 24px #00000017;
    border: 1px solid #EAEDF3;
    width: 998px;
    margin: 0 auto;
    border-radius: 4px;
    overflow: hidden;
    max-width: 100%;
    font-weight: 400;
}

.havesidebar {
    display: flex;
    .sidebar_blue, .contentbar {
        padding: 40px 23px;
    }
    .sidebar_blue {
        width: 284px;
        background-color: #08192F;
        background-image: url(../../Assets/images/welcome/sidebar-bg.png);
        color: #fff;
        background-position: bottom right;
        background-size: 91%;
        background-repeat: no-repeat;
        &.homebgimg{
            background-image: url(../../Assets/images/welcome/sidebar-bg2.png);
        }
        .sidebartitle {
            font-size: 28px;
            line-height: 1.5;
            font-weight: 400;
            span {
                font-weight: 500;
            }
        }
    }
    .contentbar {
        width: calc(100% - 284px);
        color: #333;
        min-height: 550px;
        a {
            color: #333;
        }
        .contentbar_maintitle{
            margin-bottom: 20px;
            a {
                font-size: 28px;
                font-weight: 400;
                svg{
                    margin-right: 8px;
                    height: 18px;
                }
            }
        }
        .contentbarlogo {
            img {
                max-height: 33px;
                margin-bottom: 10px;
            }
            p {
                font-size: 14px;
                font-weight: 500;
                padding-left: 23px;
                margin-bottom: 20px;
            }
        }
        .contentbar_profile {
            display: flex;
            align-items: center;
            margin-bottom: 15px;
            .contentbar_profileimg {
                height: 100%;
                width: 90px;
                object-fit: cover;
                img {
                    max-height: 90px;
                    max-width: 100%;
                }

                &.agentProfilePhotoCover {
                    border-radius: 50%;
                    height: 90px;
                    width: 90px;

                    img {
                        height: 90px;
                        border-radius: 50%;
                        width: 90px;
                        object-fit: cover;
                    }
                }
            }
            .contentbar_profilecontent {
                width: calc(100% - 90px);
                padding-left: 20px;
                h4 {
                    font-size: 24px;
                    margin-bottom: 10px;
                    font-weight: 400;
                }
                ul {
                    padding: 0;
                    margin: 0;
                    list-style-type: none;
                    li {
                        display: inline-block;
                        margin-right: 20px;
                        font-size: 14px;
                        color: #6B6C6F;
                        svg{
                            margin-right: 5px;
                        }

                        a {
                            display: flex;
                            align-items: center;
                        }
                    }
                }
            }
            
        }
        .contentlist {
            h4 {
                font-size: 22px;
                line-height: 1.4;
                margin-bottom: 12px;
                font-weight: 500;
            }
            p{
                color: #6B6C6F;
                font-size: 16px;
            }
            ul {
                margin: 0;
                padding: 0;
                list-style-type: none;
                margin-bottom: 30px;
                li {
                    display: flex;
                    color: #6B6C6F;
                    font-size: 16px;
                    align-items: center;
                    margin-bottom: 15px;
                    svg {
                        height: 18px;
                        width: 18px;
                        margin-right: 10px;
                    }
                    p {
                        margin: 0;
                    }
                }
            }
            .btns{
                button {
                    margin-right: 15px !important;
                    border-radius: 4px !important;
                }
                .actionlink {
                    color: #0066F5!important;
                    text-decoration: underline;
                    width: unset!important;
                    // &:hover{
                    //     color: #00d56d!important;
                    // }
                }
            } 
        }
    }
}
.thankyou_wrapper {
    display: flex;
    padding: 30px 15px;
    margin-bottom: 50px;
    padding-left: 0px;
    .thankyou_wrapper_ic {
        width: 39px;
        padding-top: 15px;
    }
    .thankyou_wrapper_content {
        width: calc(100% - 39px);
        padding-left: 15px;
    }
    p{
        margin: 0;
    }
    &.havespace{
        margin: 90px 0;
    }
    &.havebg{
        background-color: #F0F4FC;
        border-radius: 4px;
    }
}


.checkbox_wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;   
    margin-bottom: 30px;
    .single_checkbox{
        width: calc(50% - 10px);
        position: relative;
        margin: 10px 0;
    }
    input[type="checkbox"] {
        position: absolute;
        left: 0px;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        z-index: 9;
    }
    &.smallinput{
        justify-content: flex-start;
        .single_checkbox{
            width: 35%;
            margin-right: 25px;
        }
    }
}
.custom_check{
    border: 1px solid #E0E0E0;
    border-radius: 4px;
    padding: 20px 10px;
    display: flex;   
    position: relative;
    font-size: 14px;
    align-items: center;
    height: 100%;
    .custom_check_ic{
        height: 18px;
        width: 18px;
        border-radius: 100%;
        border: 1px solid #E0E0E0;
        position: relative;
        &:after{
            height: 6px;
            width: 10px;
            display: inline-block;
            content: "";
            border: 2px solid #fff;
            border-width: 0 0 2px 2px;
            transform: rotate(-45deg);
            position: absolute;
            top: 4px;
            left: 3px;
            opacity: 0;
            z-index:1;
        }      
    }
    .custom_check_text {
        width: calc(60% - 18px);
        padding-left: 8px;
    }
    .check_img_wrapper {
        width: 40%;
        text-align: right;
        img {
            max-width: 100%;
            max-height: 54px;
        }
    }
}
.single_checkbox {
    input:checked + .custom_check{
        border-color:#273E59;
    }
    input:checked + .custom_check .custom_check_ic {
        background-color: #00D56D;
        border-color: #00D56D;
        &:after{
            opacity: 1;
        }
    }
    input:checked + .custom_check .custom_check_text{
        font-weight: 500;
    }
}

.single_checkbox label.MuiInputLabel-root {
    font-size: 14px !important;
    color: #6B6C6F !important;
    font-family: 'Roboto' !important;
    margin: 0 0 17px 0 !important;
}

.preApprovalDates {
    display: flex;
    .inputFlexDiv2 {
        max-width: 240px;
        margin-right: 10px;
    }
    .MuiInputBase-input {
        height: 55px;
        box-sizing: border-box;
    }
    .MuiFormControl-root .MuiFilledInput-root {
        border: 1px solid rgba(0, 0, 0, 0.23) !important;
        border-radius: 4px !important;
    }
    .inputFlexDiv2 label.MuiFormLabel-root {
        margin: 0 !important;
        font-size: 14px !important;
        color: #6B6C6F !important;
        font-family: 'Roboto' !important;
        font-weight: 500 !important;
    }
}

.stepwrapper {
    padding: 0 60px;
    text-align: right;
    margin-top: 80px;
    color: #949494;
    font-size: 14px;
}

@media (max-width: 991px){
    .havesidebar {
        .sidebar_blue {
            width: 200px;
            .sidebartitle {
                font-size: 24px;
            }
        }
        .contentbar {
            width: calc(100% - 200px);
            .contentbar_profile .contentbar_profilecontent h4 {
                font-size: 20px;
            }
            .contentbar_maintitle a, .contentlist h4 {
                font-size: 24px;
            }
        }
    }
    
}

@media (max-width: 767px){
    .havesidebar {
        flex-direction: column;
        .sidebar_blue {
            width: 100%;
            background-size: contain;
        }
        .contentbar {
            width: 100%;
            min-height: 90px;
        }
    }
    
    .thankyou_wrapper {
        padding: 20px;
        .thankyou_wrapper_ic {
            width: 25px;
            padding-top: 4px;
            svg {
                height: 25px;
                width: 25px;
            }
        }
        .thankyou_wrapper_content {
            width: calc(100% - 25px);
            padding-left: 15px;
        }
    }

    .checkbox_wrapper.smallinput{
        justify-content: space-between;
        .single_checkbox{
            width: calc(50% - 10px);
            margin: 0;
        }
    }

    .stepwrapper{
        padding: 0 20px;
        margin-top: 10px;
    }
}

@media (max-width: 575px){
    .havesidebar {
        .sidebar_blue, .contentbar {
            padding: 30px 12px;
        }
        .sidebar_blue {
            .sidebartitle {
                font-size: 20px;
            }
        }
        .contentbar {
            .contentbar_maintitle {
                font-size: 16px;
            }
            .contentbar_maintitle a, .contentlist h4 {
                font-size: 20px;
            }
            .contentbar_profile {
                flex-direction: column;
                align-items: center;
                text-align: center;
                .contentbar_profilecontent {
                    width: 100%;
                    padding: 15px 0;
                }
            }
            .contentbarlogo {
                text-align: center;
            }
            .contentlist .btns{
                text-align: center;
                button {
                    width: 100% !important;
                    margin: 6px 0 !important;
                    padding: 10px;
                }

            } 
        }
    }

    .thankyou_wrapper {
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding: 15px;
        .thankyou_wrapper_ic {
            margin-bottom: 20px;
            width: 100%;
            svg {
                width: 40px;
                height: 40px;
            }
        }
        .thankyou_wrapper_content {
            width: 100%;
            padding: 0;
        }
        &.havespace{
            padding: 40px 0;
        }
    }

    .checkbox_wrapper {
        justify-content: center;
        .single_checkbox{
            width: 100%;
        }
    }

    .checkbox_wrapper.smallinput{
        justify-content: flex-start;
        .single_checkbox{
            width: 100%;
        }
    }

    .preApprovalDates {
        flex-wrap: wrap;
        .inputFlexDiv2 {
            max-width: 100%;
            margin-right: 0px;
            margin-bottom: 10px;
            width: 100%;
        }
    }

    .btns.responsive_wrap {
        display: flex;
        flex-direction: column;
        button{
            width: 100%;
        }
    }
}
@media (max-width: 480px){
    .havesidebar .contentbar .contentlist h4{
        font-size: 18px;
    }
}