.quoteDivCard {
    padding: 15px 0px;
}
.image-div-quote {
    margin-top: 15px;
    width: 80px;
    height: 40px;
}
.quote-button {
    font-size: 18px !important;
    height: 52px !important;
    margin-bottom: 15px !important;
}

.quote-main-div {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 4px #5c515124;
    border: 1px solid #e0e0e0;
    opacity: 1;

    @media (max-width: 600px) {
        // box-shadow: none;
        // border: none;
        // .quoteDivCard {
        //     display: none;
        // }
    }
}

.quote-button-main {
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 1024px) {
        padding: 0 10px 10px;
    }
    @media (max-width: 768px) {
        button {
            font-size: 12px !important;
            height: 40px !important;
        }
    }

    @media (max-width: 600px) {
        padding: 0;
        button {
            font-size: 16px !important;
            height: 50px !important;
        }
    }
}

.quote-card-top {
    display: flex;
    justify-content: space-between;
    padding: 0 15px 15px 15px;

    @media (max-width: 600px) {
        display: block;
    }
}

.data-selected-div {
    padding: 0 20px;
}

.image-trusted-quote {
    width: 2rem;
    height: 2rem;
    border-radius: 8px;
    margin: 0 1rem 0 0;
    align-items: center;
    text-align: center;
}

.logo-initials-quote {
    font-weight: bold;
    color: #6b6c6f;
    font-size: 1rem;
    margin-top: 20%;
}

.clear-mobile {
    @media (max-width: 425px) {
        display: none;
    }
}

.clear-text-mobile {
    display: none;

    @media (max-width: 425px) {
        display: none;
        font: normal normal normal 14px Roboto;
        letter-spacing: 0px;
        color: #009cbd !important;
        opacity: 1;
        margin-top: 40px;
        text-align: center;
    }
}

.clear-text-mobile-2 {
    display: none;
}

.add-arrow-img {
    display: flex;
    justify-content: center;
    // padding-top: 50px;
}

.mobile-selected-detail {
    @media (max-width: 425px) {
        display: none !important;
    }
}

.mobile-div-only {
    display: none;

    @media (max-width: 425px) {
        display: block;
    }
}

.quote-text {
    text-align: center;
    font: normal normal normal 14px/24px Roboto;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
    margin-top: 25px;
}

.selected-detail {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 5px;
    font-weight: bold;
    border-bottom: 1px solid #00000024;

    @media (max-width: 600px) {
        width: 100%;
    }
}

.selected-detail:last-child {
    border-bottom: none !important;
}
.selected-movers-count {
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0px;
    color: #6b6c6f;
    opacity: 1;
}
.selected-movers-count1 {
    font-size: 22px;
    font-weight: 700;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;

    @media (max-width: 425px) {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-bottom: 10px;
        border-bottom: 1px solid #e2e2e2;
    }
}
.clear-text {
    font: normal normal normal 12px Roboto;
    letter-spacing: 0px;
    // color: #009cbd !important;
    opacity: 1;
    font-weight: bold;
    white-space: nowrap;

    @media (max-width: 425px) {
        display: none;
    }
}

.mover-name {
    font-size: 14px;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
    margin-top: 8px;
}

.location-text {
    font: normal normal normal 12px/22px Roboto;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
}

.detail-main-card {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 4px #00000024;
    border: 1px solid #e0e0e0;
    opacity: 1;
    // width: 20%;
    display: flex;
    padding: 15px;

    @media (max-width: 768px) {
        display: none;
    }
}

.name-detail {
    font-size: 16px;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
    font-weight: 800;
}

.sub-text {
    text-align: left;
    font: normal normal normal 12px/16px Roboto;
    letter-spacing: 0px;
    color: #747e8b;
    opacity: 1;
    margin-top: -12px;
    font-weight: 300;
}
