.utility-Savings-main-head {
    margin: -68px 0px 0px 0px;

    .error {
        font-size: 12px;
        color: red;
        height: 10px;
        margin-top: 10px;
    }

    .MuiFormGroup-root.energyRadio {
        flex-direction: row !important;
    }
    .MuiFormGroup-root.energyRadio .MuiRadio-colorSecondary.Mui-checked {
        color: #5cbd9d !important;
    }

    .utlitityInptsDiv {
        margin-top: 10px;
    }

    @media (max-width: 1366px) {
        margin: -68px 0px 0px 0px;
    }

    @media (max-width: 768px) {
        margin: -68px 0px 0px 0px;
    }

    @media (max-width: 425px) {
        margin: -3px 0px 0px 0px;
    }
}
.utility-savings-sub-class {
    margin: 30px 80px 0px 80px;

    @media (max-width: 1366px) {
        margin: 30px 60px 0px 60px;
    }

    @media (max-width: 768px) {
        margin: 30px 60px 0px 60px;
    }

    @media (max-width: 475px) {
        margin: 30px 10px 0px 10px;
    }
}
.utility-Savings-main-head .MuiFormControl-root {
    width: 100%;
}
.utility-Savings-main-head {
    .progressBarFinal {
        position: fixed;
        width: 100%;
        top: 74px;
        border: none;
        z-index: 100000;

        @media (max-width: 425px) {
            top: 54px;
        }
    }

    .MuiStepIcon-root.MuiStepIcon-completed {
        color: #00d56d;
    }
    .MuiStepLabel-label {
        font-size: 22px;
        line-height: 30px;
        font-family: 'Roboto', sans-serif;
        display: flex;
        justify-content: space-between;
    }
    .MuiStepLabel-label.MuiStepLabel-active {
        color: #333333;
        font-weight: bold;
    }
    .MuiStepLabel-label.MuiStepLabel-completed {
        color: #6b6c6f;
        border-bottom: 1px solid #bebebe;
        display: flex;
        justify-content: space-between;

        .stepperEditIcon {
            color: #000000;
            display: block;
        }

        @media (max-width: 600px) {
            border-bottom: none;
        }
    }
    .MuiStepIcon-root.MuiStepIcon-active {
        color: #006dff;
    }

    .MuiStepIcon-root.MuiStepIcon-completed {
        color: #6b6c6f;
    }
}
.utlitityInpts {
    text-align: left;

    .MuiFormControl-root {
        width: 100%;
    }

    .MuiFilledInput-root {
        width: 100%;
        background-color: #fff !important;
        border: 1px solid #6b6c6f;
        border-radius: 5px;

        // @media (max-width: 768px) {
        //     height: 40px;
        // }
    }

    .MuiFilledInput-root.Mui-focused {
        width: 100%;
        background-color: #fff !important;
        border: 1px solid #006df5;
        border-radius: 5px;

        // @media (max-width: 768px) {
        //     height: 40px;
        // }
    }

    .MuiFilledInput-root.Mui-error {
        width: 100%;
        background-color: #fff !important;
        border: 1px solid red;
        border-radius: 5px;

        // @media (max-width: 768px) {
        //     height: 40px;
        // }
    }

    .MuiFilledInput-root:focus {
        border: 1px solid #009cbd;
        background: transparent;
    }

    .MuiFilledInput-underline:before,
    .MuiFilledInput-underline::after {
        border-bottom: none !important;
    }
    // .signUpInput input:focus {
    //     background-color: #fff;
    //     border: 1px solid #006df5;
    //     border-radius: 5px;
    // }
    .MuiInputLabel-filled.MuiInputLabel-shrink {
        z-index: 1 !important;
        color: #6b6c6f;

        @media (max-width: 768px) {
            font-size: 12px !important;
        }
    }
    .MuiFormLabel-root.Mui-focused {
        color: #006df5;
    }
}
.utlitityInpts {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 600px) {
        flex-direction: column;
    }
}
.inputUtilityDiv {
    width: 45%;
    .css-2b097c-container {
        width: 100%;
    }
    @media (max-width: 600px) {
        width: 100%;
    }
}
.progressBarFinal {
    .MuiLinearProgress-colorPrimary {
        background-color: #fff;
    }
    .MuiLinearProgress-barColorPrimary {
        background-color: #0066f5;
    }
    .MuiLinearProgress-root {
        height: 6px;
    }
}
.inputUtilityDivFull {
    width: 100%;
    .MuiFormControl-root {
        width: 40%;
        @media (max-width: 600px) {
            width: 100%;
        }
    }
    .toolCenter {
        display: flex;
    }
    .findUrAccount {
        font-size: 12px;
        color: #6b6c6f;
        font-family: 'Roboto';
        align-self: center;
    }
    .iconMarginLeftFinal {
        margin: 0px 5px 0px 20px;
    }
}
.inputFullDivUtility {
    width: 100%;

    .react-select {
        width: 100%;
    }
}
.inputUtilityFlexDiv {
    display: flex;
    align-items: center;
    @media (max-width: 600px) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
}

.buttonInputPowerKiosk {
    display: flex;
    justify-content: flex-end;
    padding-top: 24px;
}
// .inputMarginSide {
//     margin-left: 45px;
//     @media (max-width: 600px) {
//         margin-left: 0px;
//     }
// }
.utility-savings-submain {
    display: flex;
    justify-content: space-between;
    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
    }
}
.utility-saving-left-div {
    width: 50%;
    @media (max-width: 768px) {
        width: 100%;
    }
}
.utility-saving-right-div {
    width: 45%;
    height: max-content;
    border: 1px solid #e2e2e2;
    border-radius: 4px;

    .selectedPlanHeadingDiv {
        display: flex;
        justify-content: space-between;
        padding: 20px;
        border-bottom: 1px solid #e2e2e2;
        align-items: center;

        .selectedPlanHeading {
            font-size: 22px;
            font-family: 'Poppins';
            font-weight: 600;
            color: #333333;
        }
        .changePlan {
            font-size: 16px;
            font-family: 'Poppins';
            color: #006dff;
            cursor: pointer;
        }
    }
    @media (max-width: 768px) {
        width: 100%;
    }
}
.greatSavingsHead {
    font-size: 30px;
    font-family: 'Roboto';
    font-weight: bold;
    @media (max-width: 1024px) {
        font-size: 22px;
    }
}
.arrowBackIconFinal {
    padding-bottom: 40px;
    img {
        cursor: pointer;
    }
}
.inputLabel {
    .MuiFormLabel-root {
        color: #333333;
    }
}
.disclousreFinal {
    font-size: 16px;
    font-weight: bold;
    font-family: 'Roboto';
    padding: 15px 0px;
}
.description {
    font-size: 12px;
    color: #747e8b;
}
.showMore {
    font-size: 12px;
    color: #747e8b;
    font-weight: bold;
    cursor: pointer;
}
.inputCheckboxFlexDiv {
    font-size: 16px;
    font-family: 'Roboto';
    padding-top: 25px;
}

.stepingStepper {
    .MuiStepper-root {
        @media (max-width: 600px) {
            padding: 24px 0px 24px 0px;
        }
    }
    .MuiStepContent-root {
        @media (max-width: 600px) {
            padding-left: 0px;
        }
    }
    .MuiStepContent-root {
        @media (max-width: 600px) {
            border-left: 0px;
        }
    }
}
.utilityConfirmationPage {
    background-color: #eeeeee;

    .utilityConfirmationSubPage {
        width: 70%;
        margin: 0px auto;
        background-color: #fff;
        @media (max-width: 1024px) {
            width: 80%;
        }
        @media (max-width: 600px) {
            width: 100%;
        }
        .utilityConfirmationInnerContent {
            display: flex;
            flex-direction: column;
            padding-top: 34px;
        }
        .arrowButtonWidth {
            .MuiSvgIcon-root {
                width: 71px;
                cursor: pointer;
            }
        }
        .congoHeadUtility {
            font-size: 30px;
            font-weight: bold;
            margin: auto;
            font-family: 'Roboto';
            padding: 10px 20px 0px 20px;
            text-align: center;
        }
        .congoSubHeadUtility {
            font-size: 16px;
            font-family: 'Roboto';
            margin: auto;

            color: #6b6c6f;
            text-align: center;
        }
        .finalConfirmationCardContent {
            width: 56%;
            margin: 0px auto;
            padding-top: 49px;
            @media (max-width: 768px) {
                width: 80%;
            }
            @media (max-width: 600px) {
                width: 95%;
            }

            .electrincityGasCardCongo {
                padding-bottom: 48px;
                .ElectricityClassMainCard {
                    width: 100%;
                }
            }
            .whatsNextCongo {
                padding: 16px 0px;
                font-size: 20px;
                font-family: 'Roboto';
            }
            .congratulationDetailsCard {
                padding: 22px 0px 22px 0px;
                border: 1px solid #e2e2e2;
                border-radius: 4px;

                .quoteDetailsCongo {
                    text-align: left;
                    font-size: 22px;
                    font-family: 'Roboto';
                    font-weight: 600;
                    padding: 0px 22px 20px 20px;
                }
                .borderLineQuote {
                    border: 1px solid #e2e2e2;
                }
                .congoDetailsUtility {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 15px 0px;
                    .congoDetailsUtilityHead {
                        padding-left: 20px;
                        font-size: 16px;
                        font-family: 'Roboto';
                        font-weight: 600;
                    }
                    .congoDetailsUtilityright {
                        padding-right: 20px;
                        font-size: 16px;
                        font-family: 'Roboto';
                    }
                }
                .endDisclaimerUtility {
                    font-size: 12px;
                    font-family: 'Roboto';
                    color: #6b6c6f;
                    padding: 0px 20px;
                }
            }
            .confirmationTopCardUtility {
                padding: 20px 0px 29px 0px;
                margin-bottom: 20px;
                color: #fff;
                text-align: center;
                border-radius: 4px;
                .confirmationHeadingUtility {
                    font-size: 22px;
                    font-weight: 600px;
                    font-family: 'Roboto';
                }
                .confirmationbottomUtility {
                    font-size: 16px;
                    padding-top: 12px;
                    font-family: 'Roboto';
                }
            }
            .confirmationBottomUtility {
                padding: 19px 44px 19px 21px;
                margin-bottom: 40px;
                box-shadow: 0px 0px 6px #00000029;
                border-radius: 4px;
                .finalStepAsAProcess {
                    font-size: 18px;
                    font-weight: bold;
                    font-family: 'Roboto';
                    color: #333333;
                }
                .sentCallOnNumber {
                    font-size: 16px;
                    line-height: 24px;

                    font-family: 'Roboto';
                    color: #6b6c6f;
                    padding-top: 12px;
                }
            }
            .finalPageCardUtility {
                margin-bottom: 20px;
            }
            .backToHomeUtility {
                display: flex;
                justify-content: center;
                padding-bottom: 34px;
            }
        }
    }
}

.selectedPlanFinal {
    border: 1px solid #e2e2e2;
    display: flex;
    justify-content: space-between;
    padding: 22px 18px;
    align-items: center;
    .selectedPlanHead {
        font-size: 22px;
        font-family: 'Roboto';
        font-weight: 600;
        @media (max-width: 600px) {
            font-size: 20px;
        }
    }
    .changeFinalPlan {
        font-size: 14px;
        font-family: 'Roboto';
        color: #006dff;
    }
}

@media (max-width: 600px) {
    .toolCenter {
        margin-top: 15px;
    }
    .__react_component_tooltip {
        width: 70% !important;
    }
}
