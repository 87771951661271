.main-div-Refinance {
    
    padding: 17px 22px 23px 23px;
    align-items: center;
    display: flex;
    flex-direction: column;
    
    @media(max-width: 375px) {
        padding: 17px 20px;
    }
    .main--head-div-Refinance {
        text-align: left;
        font-size: 32px;
        font-weight: 600;
        font-family: 'Roboto';
        line-height: 38px;
        padding-bottom: 12px;
        @media(max-width: 375px) {
            font-size: 22px;
        }
    }

    .agent-headshot {
        width: 124px;
        height: 124px;
        border-radius: 50%;
        position: relative;
        display: flex;

        img {
            width: 100% !important;
            height: 124px !important;
            border-radius: 50% !important;   
            border: 3px solid #273E59;
        }
    }

    .main--image-div-Refinance {
        padding-bottom: 19px;
        img {
            // width: 124px !important;
            // height: 124px !important;
        }
    }
    .main--broker-div-Refinance {
        padding-bottom: 5px;
    }

    .main--content-div-Refinance {
        font-size: 14px;
        font-family: 'Roboto';
        color: #6B6C6F;
        text-align: left;
        line-height: 24px;
        padding-bottom: 15px;

        @media(max-width: 375px) {
            text-align: center;
        }
    }

    .main--button-div-Refinance {
        @media(max-width: 375px) {
           width: 100%;

           button {
            width: 100% !important;

           }
        }
    }
}
