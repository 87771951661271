.main-final-utility {
    // border: 1px solid #e2e2e2;
    border-radius: 4px;
    margin-bottom: 20px;
    // .selectedPlanFinal {
    //     display: flex;
    //     justify-content: space-between;
    //     padding: 22px 18px;
    //     align-items: center;
    //     .selectedPlanHead {
    //         font-size: 22px;
    //         font-family: 'Roboto';
    //         font-weight: 600;
    //         @media (max-width: 600px) {
    //             font-size: 20px;
    //         }
    //     }
    //     .changeFinalPlan {
    //         font-size: 14px;
    //         font-family: 'Roboto';
    //         color: #006dff;
    //     }
    // }
    .borderFinal {
        border: 1px solid #e2e2e2;
    }
    .lineFinal {
        display: flex;
        justify-content: space-between;
        padding: 22px 18px;
        align-items: center;
        @media (max-width: 600px) {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }
        .logoFinal {
            width: 160px;
            height: auto;
            img {
                width: 100%;
                height: 100%;
            }
        }

        .kwhContent {
            display: flex;
            flex-direction: column;
        }
        .kwhFinalValue {
            font-size: 22px;
            font-family: 'Poppins';
            font-weight: bold;
        }
        .kwhFinalUnit {
            font-size: 14px;
            font-family: 'Roboto';
            color: #6b6c6f;
        }
    }
}
