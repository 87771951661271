.three-wrapper {
    color: #333;
    background: url('../../../Assets/images/SignUp/Dotted.svg') no-repeat 46% 67% / contain;
    background-size: auto 800px;

    @media (max-width: 1800px) {
        background: url('../../../Assets/images/SignUp/Dotted.svg') no-repeat 45% 67% / contain;
        background-size: auto 780px;
    }
    @media (max-width: 1700px) {
        background-size: auto 700px;
    }
    @media (max-width: 1500px) {
        background-size: auto 650px;
    }
    @media (max-width: 1366px) {
        background-size: auto 630px;
    }
    @media (max-width: 1200px) {
        background-size: auto 550px;
    }
    @media (max-width: 850px) {
        background-size: auto 650px;
    }
    @media (max-width: 600px) {
        background: none;
    }
}

.three-para {
    font-size: 30px;
    font-weight: bold;
    text-align: center;

    @media (max-width: 600px) {
        font-size: 16px;
        line-height: 32px;
    }
}

.three-div1 {
    width: 70%;
    margin: 100px auto;
    display: flex;

    @media (max-width: 600px) {
        flex-wrap: wrap;
        width: 90%;
        padding: 0 10px;
        margin: 30px auto;
    }
}

.three-div2 {
    width: 70%;
    display: flex;
    margin-left: 10%;
    margin-top: 100px;
    margin-bottom: 100px;

    @media (max-width: 600px) {
        flex-wrap: wrap;
        flex-direction: column-reverse;
        width: 90%;
        padding: 0 10px;
        margin: 30px auto;
    }
}

.three-div3 {
    width: 70%;
    margin: 100px auto;
    display: flex;

    @media (max-width: 600px) {
        flex-wrap: wrap;
        width: 90%;
        padding: 0 10px;
        margin: 30px auto;
    }
}

.three-image1 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    img {
        width: 70%;
    }

    @media (max-width: 600px) {
        width: 100%;
    }
}
.three-image2 {
    width: 50%;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    img {
        width: 70%;
    }

    @media (max-width: 600px) {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
.three-image3 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    img {
        width: 70%;
    }

    @media (max-width: 600px) {
        width: 100%;
    }
}

.three-content1 {
    width: 50%;

    @media (max-width: 600px) {
        width: 100%;
        margin-top: 20px;
    }
}

.three-content2 {
    width: 50%;

    @media (max-width: 600px) {
        width: 100%;
        margin-top: 20px;
    }
}

.three-content3 {
    width: 50%;

    @media (max-width: 600px) {
        width: 100%;
        margin-top: 20px;
    }
}

.content1-heading,
.content2-heading,
.content3-heading {
    font-weight: bold;
    font-size: 20px;
    line-height: 32px;
}

.content1-para,
.content2-para,
.content3-para {
    font-size: 14px;
    opacity: 0.8;
    padding: 10px 20px 10px 0;
    line-height: 20px;

    @media (max-width: 600px) {
        font-size: 12px;
    }
}
.content1-icons,
.content2-icons,
.content3-icons {
    display: flex;
    justify-content: space-between;
    padding-top: 15px;
}
.icon-div-first {
    width: 12%;
    opacity: 1;
    padding: 12px 0;
    font-weight: bold;

    img {
        height: 30px;
        width: 30px;
    }
    .text-new {
        font-size: 10px;
        padding: 10px 0;
    }
}
.icon-div {
    width: 20%;
    opacity: 1;
    padding: 12px 0;
    font-weight: bold;

    .content-title-icon {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    img {
        height: 30px;
        width: 30px;
    }
    .text-new {
        font-size: 10px;
        padding: 10px 0;
        text-align: center;
    }
}
