.main-div-home-imporvement {
    padding: 16px 22px 10px 23px;


    .main--head-div-home-imporvement {
        font-size: 32px;
        line-height: 38px;
        font-family: 'Roboto';
        text-align: left;
        color: rgba(255, 255, 255, 0.8);
        padding-bottom: 17px;

        @media(max-width: 768px) {
            font-size: 24px;
        }
    }
    .main--button-div-home-imporvement {
        padding-bottom: 11px;

        button {
            @media(max-width: 375px) {
                width: 149px !important;
                height: 38px !important;
            }
        }
    }
    .main--image-div-home-imporvement {
        display: flex;
        justify-content: flex-end;
      
    }
}
