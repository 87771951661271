.utilityCardMain {
    text-align: center;

    .placeOrderBtn {
        border-radius: 4px !important;
    }
}

.headCard {
    font-size: 24px;
    font-weight: bold;
    color: #9c9da1;
    margin: 20px 0px 10px 0px;
}

.headSubCard {
    font-size: 24px;
    color: #333333;
    margin: 20px 0px 10px 0px;
    height: 60px;
    margin-bottom: 10px;

    @media (max-width: 768px) {
        height: 108px;
    }
    @media (max-width: 600px) {
        height: auto;
    }
}

.phoneutility {
    color: #9c9da1;
    width: 12px !important;
    height: 12px !important;
    margin-right: 5px;
    margin-top: 4px;
}

.globe {
    color: #9c9da1;
    width: 12px !important;
    height: 12px !important;
    margin-right: 5px;
    margin-top: 4px;
}

.subhead1 {
    color: #9c9da1;
    font-size: 16px;
}

.subCard {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    padding: 20px 10px 0px 10px;
    height: 40px;

    @media (max-width: 1280px) {
        padding: 20px 5px 0px 5px;
    }
    @media (max-width: 768px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
   .utiCover {
    align-items: center;

    svg {
        margin-top: 0px !important;
    }
   }
}

.buttonsDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    margin-bottom: auto;
}

.utilityIndividualCard {
    width: 386px;

    @media (min-width: 1500px) {
        width: 32%;
    }
    @media (min-width: 1700px) {
        width: 24%;
    }
    @media (max-width: 1411px) {
        width: 32%;
    }
    @media (max-width: 1260px) {
        width: 48%;
    }
    @media (max-width: 750px) {
        width: 100%;
    }
}

.MuiButton-label {
    text-transform: none;
}
