
.alert-select{
    width: 20%;
}

.card {
    width: 49%;
    padding: 20px;
}

.card .getStartedImage {
    width: 157px;
    height: 155px;
}