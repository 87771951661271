.monthly-usage-card-main {
    border: 1px solid #e2e2e2;
    margin-top: 15px;

    border-radius: 4px;
    opacity: 1;
    padding: 28px 40px 36px 40px;
    @media (max-width: 1024px) {
        padding: 28px 15px 36px 15px;
    }
    @media (max-width: 630px) {
        padding: 22px 12px 0px 12px;
    }
    .montly-usage-card-submain {
        .electricityUtiltity {
            font-size: 14px;
            font-family: 'Roboto';
            padding-bottom: 37px;
            span {
                margin-left: 10px;
                font-weight: bold;
                color: #006dff;
                .editicon {
                    margin-left: 10px;
                    font-size: 14px;
                    cursor: pointer;
                }
            }
            @media (max-width: 630px) {
                padding-bottom: 30px;
            }
        }
        .monthly-main {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            @media (max-width: 630px) {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
            }
            .monthlyValues1 {
                display: flex;
                flex-direction: row;
                align-items: center;
                width: 30%;
                @media (max-width: 630px) {
                    width: 100%;
                    padding-bottom: 30px;
                }
                .iconMonthly {
                    margin-right: 17px;
                }
                .montlyUsagePower {
                    display: flex;
                    flex-direction: column;
                    .monthlyHeading {
                        font-size: 14px;
                        font-family: 'Roboto';
                        color: #6b6c6f;
                        padding-bottom: 4px;
                    }

                    .monthlyValues {
                        font-size: 22px;
                        font-weight: bold;
                        font-family: 'Roboto';
                    }
                }
            }
        }
    }
}
