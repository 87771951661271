.trustedPros-carpenter-main-page {
    .vendorsImageDiv {
        display: flex;
        justify-content: center;
    }
    .trustedPros-carpenter-title-div {
        padding: 0 80px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media (max-width: 600px) {
            flex-direction: column;
            justify-content: unset;
            align-items: unset;
        }
        .trustedProsCarpenterAddressDiv {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            @media (max-width: 600px) {
                justify-content: unset;
                align-items: unset;
            }
            .addressTextCarpenter {
                font-size: 14px;
                line-height: 24px;
                font-weight: bold;
                @media (max-width: 600px) {
                    font-size: 12px;
                }
            }

            .MuiFilledInput-root {
                background-color: transparent;
                border-bottom: 1px solid #009cbd;
                color: #009cbd;
            }
            .trustedProsStepsAutoCompleteCarpenter {
                .MuiFilledInput-input {
                    padding: 0;
                    width: 300px;

                    @media (max-width: 600px) {
                        width: 250px;
                    }
                    @media (max-width: 375px) {
                        width: 230px;
                    }
                    @media (max-width: 325px) {
                        width: 180px;
                    }
                }
            }
            .trustedProsStepsAutoCompleteCarpenter1 {
                .MuiFilledInput-input {
                    padding: 0;
                    width: 300px;
                    color:#006dff;

                    @media (max-width: 600px) {
                        width: 250px;
                    }
                    @media (max-width: 375px) {
                        width: 230px;
                    }
                    @media (max-width: 325px) {
                        width: 180px;
                    }
                }
            }
        }

        @media (max-width: 1366px) {
            padding: 0 60px;
        }

        @media (max-width: 600px) {
            padding: 0 10px;
        }
    }

    .trustedProsStepperCarpenter {
        padding: 0 300px;
        @media (max-width: 1024px) {
            padding: 0 200px;
        }
        @media (max-width: 768px) {
            padding: 0 100px;
        }
        @media (max-width: 600px) {
            padding: 0 10px;
        }

        .serviceTitleCarpenter {
            font-size: 22px;
            line-height: 30px;
            font-weight: bold;
            margin-top: 10px;
        }

        .MuiStepper-root {
            display: none;
        }

        .MuiFormControl-root {
            width: 100%;
        }

        .MuiRadio-colorSecondary.Mui-checked {
            color: #006dff;
        }

        .MuiFormLabel-root {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 16px;
            line-height: 22px;
            color: #333333;
            font-family: 'Roboto';
        }
        .trustedProsCarpenterRow {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 20px;

            .trustedProsCarpenterDesc1 {
                color: #333333;
                font-size: 22px;
                font-weight: 900;
                text-align: center;
                padding-top: 9px;
            }
            .trustedProsCarpenterDesc2 {
                color: #6b6c6f;
                font-size: 14px;
                padding-top: 9px;
                padding-bottom: 22px;
                text-align: center;
            }
        }
    }
    .trustedprosReqSubmittedWrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        .trustedprosReqSubmittedCircle {
            display: flex;
            justify-self: center;
        }
        .trustedprosReqSubmitted {
            color: #333333;
            font-size: 24px;
            font-weight: 900;
            padding-top: 22px;
            text-align: center;
        }
        .trustedprosReqSubmittedDesc {
            color: #6b6c6f;
            font-size: 14px;
            text-align: center;
            padding-top: 7px;
            @media (max-width: 1920px) {
                width: 22%;
            }
            @media (max-width: 1700px) {
                width: 28%;
            }
            @media (max-width: 1500px) {
                width: 35%;
            }
            @media (max-width: 1366px) {
                width: 38%;
            }
            @media (max-width: 1024px) {
                width: 48%;
            }
            @media (max-width: 768px) {
                width: 52%;
            }
            @media (max-width: 600px) {
                width: 70%;
            }
            @media (max-width: 375px) {
                width: 80%;
            }
            @media (max-width: 320px) {
                width: 93%;
            }
        }
        .trustedprosStatusWrapper {
            display: flex;
            padding-top: 12px;
            padding-bottom: 14px;
            .trustedprosStatus {
                color: #333333;
                font-size: 12px;
                font-weight: 900;
                padding-right: 4px;
                display: flex;
                flex-direction: column;
                align-self: center;
            }
            .trustedprosStatusVal {
                border: 1px solid #c7ab5f;
                background-color: #fff0c8;
                border-radius: 13px;
                color: #937b39;
                font-size: 12px;
                font-weight: 500;
                padding: 3px 8px 2px 7px;
                width: 59px;
                text-align: center;
            }
        }
    }
}
.newCardsTrustedHomeFlow {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
}
.modalFolderCreateDisclaimer {
    width: 50%;
    // height: 55%;
    background-color: #ffffff;
    overflow-y: auto;
    padding: 22px 22px 10px 22px;

    .error {
        height: 20px;
        font-size: 12px;
        color: red;
        margin-top: 3px;
    }

    @media (max-width: 768px) {
        width: 70%;
    }
    @media (max-width: 600px) {
        width: 75%;
        padding: 30px 25px 26px 25px;
    }

    @media (max-width: 440px) {
        height: 90%;
        width: 95%;
    }

    .homeAdvisorHeading {
        font-size: 16px;
        font-family: 'Roboto';
        font-weight: bold;
        text-align: left;
        padding-top: 30px;
    }
    .homeAdvisorSubData {
        font-size: 14px;
        font-family: 'Roboto';
        line-height: 24px;
        text-align: left;
        padding-bottom: 46px;
    }
    .homeAdvisorOnClick {
        font-size: 16px;
        font-family: 'Roboto';

        text-align: left;
        padding-bottom: 50px;



    }
    
    .deBlue{
        color:#009CBD;
        text-decoration: underline;
    }
    .MuiCheckbox-colorSecondary.Mui-checked.deBlue{
        color:#009CBD !important;
    }
    .deMoveColor{
        color: #2270EE;
        text-decoration: underline;

    }
    .MuiCheckbox-colorSecondary.Mui-checked.deMoveColor{
        color:#2270EE !important;
    }
    .borderDisclaimerLine {
        border: 1px solid #e2e2e2;
        margin-bottom: 10px;
    }
    .buttonDisclaimerHomeAdvisor {
        float: right;
    }
}
.CloseIcon123 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 10px 0px 0px;
}
.buttonCarpenter {
    margin-top: 30px;
}
