.cardCss {
    width: 98%;
    margin: auto;
    height: fit-content;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #dadce0;
    opacity: 1;

    // margin: this.props.cardMargin ? this.props.cardMargin : '',
    // padding: this.props.cardPadding ? this.props.cardPadding : '5px',
}

.dashboardTopInfoCardContainer {
    margin-bottom: 10px;

    .cardCss {
        .cardContent {
            .section-1-heading {
                font-size: 12px;

                .address-small-title {
                    @media (max-width: 375px) {
                        font-size: 14px !important;
                    }
                }
            }
        }

        .topCardInfoLabel {
            color: #6b6c6f;
            font-size: 12px;
            font-weight: 500;
            @media (max-width: 375px) {
                font-size: 14px !important;
            }
        }
        .section-4-subheading {
            font-family: 'Poppins';
            @media (max-width: 375px) {
                font-size: 22px !important;
            }
        }

        .dateInfoCover {
            font-weight: normal;
            font-size: 14px;
            color: #333333;
            margin-right: 5px;

            @media (max-width: 375px) {
                font-size: 12px !important;
            }
            .growpercent {
                font-family: Roboto;
                font-weight: bold;
                color: #00d56d;
                margin-right: 5px;
                @media (max-width: 375px) {
                    font-size: 12px !important;
                }
            }
            .lowpercent {
                font-family: Roboto;
                font-weight: bold;
                color: #d92f25;
                margin-right: 5px;
                @media (max-width: 375px) {
                    font-size: 12px !important;
                }
            }
        }

        .homeServicesContainer {
            display: flex;
            margin-top: 15px;

            @media (max-width: 480px) {
                justify-content: space-between;
                .basicInfo {
                    display: flex;
                    align-items: center;
                    justify-content: space-evenly;
                }
            }

            @media (max-width: 375px) {
                .basicInfo {
                    font-size: 12px !important;
                }
            }
        }

        .moreLinkCover {
            display: flex;
            margin-left: auto;
            margin-top: 15px;
            padding-bottom: 12px;
            margin-right: 15px;
            cursor: pointer;
            align-items: center;
            @media (max-width: 375px) {
                padding-bottom: 5px;
            }

            .moreLinkLabel {
                color: #0066f5;
                font-size: 12px;
                @media (max-width: 375px) {
                    font-size: 12px !important;
                }
            }
        }
    }
}

.dashboardBottom {
    .blocksTitleDiv {
        .blocksTitle {
            @media (max-width: 375px) {
                font-size: 16px !important;
            }
        }
    }
}
