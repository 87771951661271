.insuranceAddressCard {
    // padding: 0px 100px 0px 100px;
    display: flex;
    justify-content: center;
}

.only-mobile-edit-button {
    display: none;

    @media (max-width: 600px) {
        display: unset;
    }
}

.footerBelowTextInsurance {
    font-size: 12px;
    color: #6b6c6f;
    margin: 0 80px;
    margin-top: 20px;
    @media (max-width: 600px) {
        display: none;
    }
}
.insuranceStepsLanding {
    margin-top: -180px !important;
}

.main-flexInsurance {
    display: flex;

    @media (max-width: 1024px) {
        flex-direction: column;
    }
}

.innerContentInsurance {
    font-size: 16px;
    color: #333;

    @media (max-width: 768px) {
        font-size: 16px;
    }
}

.headInsuranceMain {
    font-size: 34px;
    font-weight: bold;

    @media (max-width: 600px) {
        font-size: 24px;
        margin-bottom: 10px;
    }
}

.insuranceSteps {
    .buttonsInsurancePage {
        display: flex;
        justify-content: space-between;
        .buttonDivInsurance {
            padding-bottom: 100px;
        }
        @media (max-width: 600px) {
            justify-content: center;
            margin-top: 20px;
            margin-bottom: 80px;

            .buttonDivInsurance {
                display: flex;
            }
        }
    }

    .steps {
        padding: 0px 80px 0px 80px;
        text-align: center;
        margin-top: 0%;
        padding-bottom: 50px;

        @media (max-width: 1366px) {
            padding: 0px 60px 0px 60px;
        }
        @media (max-width: 768px) {
            padding-bottom: 60px;
        }

        @media (max-width: 600px) {
            padding: 0px 10px 0px 10px;
        }
    }
}
.DouglasInsuranceTetxfields {
    .MuiInputLabel-filled .MuiInputLabel-shrink {
        z-index: 1 !important;
        color: #100b28;
    }

    .insuranceAddressCard .innerInputs.insurance .MuiInputLabel-filled.MuiInputLabel-shrink.Mui-focused,
    .insuranceAddressCard .input1 .MuiInputLabel-filled.MuiInputLabel-shrink.Mui-focused,
    .insuranceAddressCard .input .MuiInputLabel-filled.MuiInputLabel-shrink.Mui-focused {
        color: #100b28 !important;
    }
    input {
        // background-color: #fff;
        // border: 1px solid #100b28 !important;
        border-radius: 0px !important;
        color: #323744;
    }
    .MuiFormGroup-root.customRadio .MuiRadio-colorSecondary.Mui-checked,
    .MuiCheckbox-colorPrimary.Mui-checked {
        color: #009cbd !important;
    }
    .insuranceAddressCard .react-select .css-yk16xz-control,
    .insuranceAddressCard .react-select .select-state__control {
        border: 1px solid #100b28 !important;
        border-radius: 0px !important;
        color: #323744;
    }
    .css-b8ldur-Input {
        visibility: hidden !important;
    }
}
.InsuranceDrop {
    .MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded {
        @media (max-width: 600px) {
            top: 185px !important;
        }
    }
}
.steps1Insurance {
    padding: 0px 80px 0px 80px;
    text-align: center;
    @media (max-width: 1366px) {
        padding: 0px 60px 0px 60px;
    }
    @media (max-width: 600px) {
        padding: 0px 10px;
    }
}

.Para-insurance {
    color: #bebebe;
}

.path-insurance {
    display: flex;
    // margin-top: -25px;
    font-size: 12px;
}

.main-head-insurance {
    margin: 30px 0px 0px 80px;

    @media (max-width: 1366px) {
        margin: 30px 0px 0px 60px;
    }

    @media (max-width: 1024px) {
        position: unset !important;
    }

    @media (max-width: 600px) {
        margin: 30px 0px 0px 10px;
    }
}
.progressStepD .MuiLinearProgress-barColorPrimary {
    background: #009cbd !important;
}
.progressStep .MuiLinearProgress-barColorPrimary {
    background: #006dff !important;
}
.heading-insurance-regular {
    font-size: 24px;
    font-weight: 600;
    font-family: sans-serif;
}

.BoxCardRightInsurance {
    margin-top: 190px;
    min-width: 500px;

    @media (max-width: 1024px) {
        min-width: 390px;
        margin-top: 10px;
    }
    @media (max-width: 600px) {
        min-width: 100%;
    }
    // @media (max-width: 320px) {
    //     min-width: 300px;
    //     margin-top: 10px;
    // }
}
.BoxCardLeftInsurance {
    min-width: 670px;

    @media (max-width: 1024px) {
        min-width: 400px;
    }
    @media (max-width: 600px) {
        min-width: 100%;
    }
}
.callOrderplaningInsurance1 {
    margin-top: 10px;
    margin-left: 10px;
    display: flex;
    flex-wrap: wrap;
    @media (max-width: 600px) {
        margin-left: 0px;
    }
}
.callOrderplaningInsurance2 {
    margin-top: 10px;
    margin-left: 10px;
    @media (max-width: 600px) {
        margin-left: 0px;
    }
}
.callOrderplaningInsurance {
    color: #0066f5;
    margin-left: 10px;
    margin-right: 10px;
}
.callOrderplaningInsuranceDouglas {
    color: #009cbd;
    margin-left: 10px;
    margin-right: 10px;
}

.BoxCardRightInsuranceImage {
    width: 25%;
    @media (max-width: 600px) {
        width: 75%;
        margin-bottom: 20px;
    }
}
.BoxCardRightInsuranceImageBelow {
    width: 73%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    @media (max-width: 600px) {
        // flex-direction: column;
        width: 100%;
    }
}
.planDetailsTagsInsurance {
    color: #333333;
    font-size: 16px;
    margin-bottom: 10px;
    text-align: left;
}
.planDetailsTagsInsuranceSummary {
    color: #333333;
    font-size: 16px;
    text-align: left;
    @media (max-width: 600px) {
        text-align: center;
        margin-top: 10px;
    }
}
.insuranceplancardamount {
    color: #333333;
    font-size: 24px;
    margin-top: 15px;
    @media (max-width: 600px) {
        font-size: 16px;
        margin-top: 10px;
    }
}
.insuranceplancardamount1 {
    color: #333333;
    font-size: 62px;
    font-weight: 800;
    @media (max-width: 600px) {
        font-size: 42px;
    }
}
.insuranceBoxCard {
    width: fit-content;
    height: auto;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 10px 10px 10px 20px;

    @media (max-width: 768px) {
        width: 100%;
    }
}

.insuranceBox {
    text-align: left;
}
.addHeight {
    height: 290px;
    @media (max-width: 768px) {
        height: unset;
    }
}
.insuranceAddressCard .heading {
    font-size: 24px;

    @media (max-width: 768px) {
        font-size: 18px;
    }
    @media (max-width: 600px) {
        margin-bottom: 20px;
    }
}

.addressFlexDiv {
    display: flex;
    justify-content: center;
    margin: 10px;
    align-items: center;

    @media (max-width: 600px) {
        // flex-wrap: wrap;
        margin: 10px 30px -15px;
        // justify-content: flex-start;
    }
    .select-state__input {
        display: none !important;
    }
}

.addressFlexDiv-landing {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.errorInsurance {
    .react-select div {
        color: #f44336 !important;
        border-color: #f44336;
    }
}
.addressFlexDiv .select-state__menu {
    z-index: 1000;
    text-align: left;
}
.addressFlexDiv .react-select .select-state__control .select-state__indicators .select-state__indicator:after {
    display: none;
}
.addressFlexDiv .react-select .select-state__control .select-state__indicators svg {
    display: inline;
    // margin-top: 10px;
}
.addressFlexDiv .react-select div {
    font-size: 13px;
    visibility: visible !important;
}
.stateTextAddressInsurance {
    color: #6b6c6f;
    position: absolute;
    z-index: 9;
    font-size: 14px;
    width: 100%;
    transform: translate(-13px, 6px) scale(0.75);
}
.stateTextAddressInsurance1 {
    color: #6b6c6f;
    position: absolute;
    z-index: 9;
    font-size: 14px;
    transform: translate(7px, 6px) scale(0.75);
}
.stateTextAddressInsurance2 {
    color: #6b6c6f;
    position: absolute;
    z-index: 9;
    font-size: 14px;
    transform: translate(1px, 6px) scale(0.75);
}

.slectedAdressInsurance .react-select div {
    margin-top: 6px;
}
.slectedAdressInsurance .select-state__indicator.select-state__dropdown-indicator {
    margin-top: -10px;
}
// .addressFlexDiv .react-select .select-state__control .select-state__indicators .select-state__indicator {
//     // display: none;
// }

.location-Icon {
    margin-left: -60px;
    margin-right: 10px;
    position: absolute;
    margin-top: 0px;
}

.icons {
    margin-right: 20px;
    width: 50px;
    height: 50px;
}

.insuranceAddressCard .MuiOutlinedInput-input {
    // border: 1px solid #009cbd;
    border-radius: 4px;
}

.inputLabel .MuiFormLabel-root {
    font-size: 12px !important;
    margin: 15px 0;
}

.insuranceAddressCard .iconDiv12 {
    display: flex;
    align-items: flex-end;
    margin-top: 20px;
    margin-right: 20px;
    width: 50px;
}

.addressFlexDiv .input1 .MuiOutlinedInput-root,
.addressFlexDiv .react-select {
    width: 200px;
    height: 50px;

    @media (max-width: 600px) {
        width: 190px;
        height: auto;
        // margin-top: 10px;
    }
}

.addressFlexDiv-landing .input1 .MuiOutlinedInput-root,
.addressFlexDiv-landing .react-select {
    width: 100%;
}

.insuranceAddressCard .react-select .select-state__control {
    height: 55px !important;
    @media (max-width: 600px) {
        margin-top: 10px !important;
        // height: auto !important;
        // height: 50px !important;
    }
}

.insuranceAddressCard .input .MuiOutlinedInput-root {
    width: 440px !important;
    height: 50px !important;
}

.marginDiv {
    margin-top: 30px;

    .addressFlexDiv {
        display: flex;
        justify-content: center;
        margin: 10px;

        .input1 .MuiFilledInput-root {
            width: 200px;
        }

        @media (max-width: 600px) {
            flex-wrap: wrap;
            margin: 10px 30px;
            // justify-content: flex-start;
            .input1 .MuiFilledInput-root {
                width: 190px;
            }
        }
    }
    @media (max-width: 600px) {
        margin-top: 0px;
    }
}

.PlanMainPage {
    display: flex;
    justify-content: space-between;

    .mobileDetailsDiv {
        display: none;

        @media (max-width: 600px) {
            display: unset;
        }

        .MuiSvgIcon-root {
            height: 15px;
        }
    }

    .discountDiv {
        background-color: #273e59;
        padding: 0 30px;
        margin: 10px 0px 30px 0px;
        color: #ffffff;
        display: flex;
        @media (max-width: 1024px) {
            padding: 10px 0px;
        }
        @media (max-width: 768px) {
            flex-direction: column;
            position: relative;
            margin: 10px 0px 10px 0px;
        }
    }

    .discountContent {
        width: 60%;
        text-align: left;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media (max-width: 1024px) {
            padding: 0 10px;
        }
        @media (max-width: 768px) {
            width: 95%;
            z-index: 1;
        }
    }

    .discountImgDiv {
        width: 40%;
        overflow: hidden;

        @media (max-width: 768px) {
            width: 50%;
            position: absolute;
            z-index: 0;
            right: 0;
            bottom: 0;
            opacity: 0.5;
        }
    }

    .discountImgDiv > svg {
        // width: 40%;
        width: 293px;
        height: 164px;
        margin-bottom: -4px;
        // @media (max-width:768px) {
        //     width: 140px;
        //     height: 153px;
        // }
        @media (max-width: 768px) {
            width: 100%;
            height: 100%;
        }
    }

    .discountTitle {
        font-size: 22px;
        font-weight: bold;
        // margin: 20px 0 0 0;

        @media (max-width: 1024px) {
            margin: 10px 0 0 0;
        }

        @media (max-width: 768px) {
            margin: 0;
        }
    }

    .discountText {
        font-size: 16px;
        margin: 10px 0 20px 0;

        @media (max-width: 1024px) {
            margin: 10px 0px;
        }
    }

    .detailsDiv {
        width: 30%;
        padding: 20px 0 0 22px;

        @media (max-width: 1024px) {
            width: 35%;
            padding: 20px 0 0 15px;
        }

        @media (max-width: 600px) {
            width: 100%;
            padding: 10px 0;
            display: none;
        }

        // .propertyDetails {
        //     @media (max-width:600px) {
        //        display: none;
        //     }
        // }
    }

    .cardsDiv1 {
        width: 80%;

        @media (max-width: 1024px) {
            width: 65%;
        }

        @media (max-width: 600px) {
            width: 100%;
        }

        .planCardDiv {
            margin-right: 0px;
        }

        .ButtonInsuranceCard {
            .radiusBtn {
                border-radius: 4px !important;
            }
        }
    }

    .headerDiv {
        display: flex;
        justify-content: space-between;
        margin-top: 25px;

        @media (max-width: 768px) {
            flex-direction: column;
            margin-top: 10px;
        }
    }

    .planCardDiv {
        margin: 20px 30px 0px 0px;

        @media (max-width: 1024px) {
            margin: 20px 35px 0px 0px;
        }
        @media (max-width: 600px) {
            margin: 20px 0px 0px 0px;
        }
    }

    .insurancetitle {
        font-size: 22px;
        font-weight: bold;

        @media (max-width: 768px) {
            // font-size: 16px;
            text-align: left !important;
            margin-bottom: 10px;
        }
    }

    @media (max-width: 600px) {
        flex-direction: column;
    }
}
.callquoteInsurance {
    @media (max-width: 600px) {
        padding: 0px !important;
    }
}
.plancardInsurancePage {
    @media (max-width: 600px) {
        padding: 0px !important;
    }
}
.titleCount {
    color: #6b6c6f;
    display: inline-block;
    margin-left: 5px;
}

.sortByDiv {
    display: flex;
    align-items: center;
}

.sortByDiv .MuiSelect-select.MuiSelect-select {
    color: #009cbd !important;
}

.sortByText {
    font-size: 16px;
    color: #333333;
    // margin-right: 10px;
    display: flex;
}

.sortByText > svg {
    width: 20px;
    height: 20px;
}

.sortByDiv .react-select {
    width: 150px;
}

.sortByDiv .react-select div {
    color: #009cbd !important;
}

.sortByDiv .react-select .css-yk16xz-control {
    border: none;
    border-bottom: 1px solid #009cbd;
}

.quoteDiv {
    margin-top: 30px;
    margin-bottom: 20px;

    @media (max-width: 600px) {
        margin-top: 20px;
    }
}

.backIcon {
    font-size: 22px;
    font-weight: bold;
    display: flex;
    align-items: center;

    // @media (max-width: 1440px) {
    //     font-size: 18px;
    // }
}

.coverageMainDiv .logoDiv {
    width: 60%;
}

.coverageMainDiv .logoDiv img {
    width: 250px;
    @media (max-width: 1440px) {
        width: 150px;
    }
}

.policyDetailsDiv {
    margin-top: 30px;
}

.aboutHeadingInsurance {
    font-size: 22px;
    color: #333333;
    font-weight: bold;
    margin: 20px 0;

    // @media (max-width: 1440px) {
    //     font-size: 17px;
    // }
}

.aboutContentInsurance {
    font-size: 16px;
    color: #333333;
    line-height: 22px;
    letter-spacing: 0.5px;

    @media (max-width: 600px) {
        font-size: 14px;
    }
}

.coverage {
    display: flex;
    padding: 30px 0;
    border-bottom: 1px solid #e2e2e2;
}

.coverageTitleDiv {
    width: 35%;
    @media (max-width: 600px) {
        margin-right: 15px;
    }
}

.coverageDetailsDiv {
    width: 65%;
    font-size: 16px;
    @media (max-width: 600px) {
        font-size: 14px;
    }
}

.coverageAmountDiv {
    display: flex;
}

.coverageTitle {
    font-size: 16px;
    @media (max-width: 600px) {
        font-size: 14px;
    }
}

.coverageCurrency {
    font-size: 24px;
    margin-top: 10px;
    @media (max-width: 600px) {
        font-size: 16px;
        margin-top: 5px;
    }
}
.coverageAmount {
    font-size: 48px;
    color: #333333;
    font-family: Poppins;
    font-weight: 800;
    @media (max-width: 600px) {
        font-size: 32px;
    }
}
.insuranceAddressCard .react-select .css-yk16xz-control {
    border-color: #bebebe;
}
.addressFlexDiv .dateInsurance .MuiFilledInput-root,
.addressFlexDiv-landing .dateInsurance .MuiFilledInput-root {
    width: 200px;
    background-color: #fff;
    border: 1px solid #100b28 !important;
    border-radius: 0px;
    // height: 55px;
    height: 57px;
    margin-top: -3px;
}
.addressFlexDiv .dateInsurance1 .MuiFilledInput-root {
    width: 200px;
    background-color: #fff;
    border: 1px solid #100b28 !important;
    border-radius: 0px;
    // height: 55px;
    height: 57px;
    margin-top: -3px;
}
.addressFlexDiv-landing .dateInsurance .MuiFilledInput-root,
.addressFlexDiv-landing .dateInsurance .MuiFilledInput-root {
    width: 100%;
    background-color: #fff;
    border: 1px solid #100b28 !important;
    border-radius: 5px;
    // height: 55px;
    height: 57px;
    margin-top: -3px;
}
.addressFlexDiv .dateInsurance .MuiSvgIcon-root,
.addressFlexDiv-landing .dateInsurance .MuiSvgIcon-root {
    display: none;
}
.insuranceAddressCard .innerInput1 .MuiFormControl-root.MuiTextField-root.input {
    width: 100% !important;
    border-radius: 5px !important;
}
.insuranceAddressCard .innerInputs.insurance .MuiFilledInput-input {
    padding: 20px 12px 10px;
}

.insuranceAddressCard .innerInputs.insurance .MuiInputBase-input {
    border: 1px solid #100b28;
    border-radius: 5px;
    background-color: #fff;
    text-align: left;
}
.insuranceAddressCard .MuiFilledInput-root.Mui-error .MuiInputBase-input {
    border: 1px solid #f44336;
}
.insuranceAddressCard .dateInsurance .MuiFilledInput-root.Mui-error .MuiInputBase-input {
    border: none;
}

.dateInsurance .MuiFilledInput-root.MuiFilledInput-underline.Mui-error {
    border-color: #f44336 !important;
}
.addressFlexDiv-landing .dateInsurance .MuiFilledInput-root.MuiFilledInput-underline.Mui-error {
    border-color: #f44336 !important;
    width: 100%;
}

.insuranceAddressCard .innerInputsDouglas.insurance .MuiInputBase-input:focus,
.addressFlexDiv .dateInsurance1 .MuiFilledInput-root:hover,
.addressFlexDiv .dateInsurance1 .MuiFilledInput-root:focus {
    border: 1px solid #006dff !important;
}
.insuranceAddressCard .innerInputs.insurance .MuiInputBase-input:focus,
.addressFlexDiv .dateInsurance .MuiFilledInput-root:hover,
.addressFlexDiv .dateInsurance .MuiFilledInput-root:focus {
    border: 1px solid #009cbd !important;
}

.dateInsurance .MuiFilledInput-adornedEnd {
    padding-right: 0px;
}

.dateInsurance .MuiInputAdornment-positionEnd {
    display: none;
}

.insuranceAddressCard .innerInputs.insurance .MuiInputLabel-filled.MuiInputLabel-shrink.Mui-focused,
.insuranceAddressCard .input1 .MuiInputLabel-filled.MuiInputLabel-shrink.Mui-focused,
.insuranceAddressCard .input .MuiInputLabel-filled.MuiInputLabel-shrink.Mui-focused {
    color: #006dff !important;
    z-index: 9 !important;
}
.insuranceAddressCard .innerInputsDouglas.insurance .MuiInputLabel-filled.MuiInputLabel-shrink.Mui-focused,
.insuranceAddressCard .input1Douglas .MuiInputLabel-filled.MuiInputLabel-shrink.Mui-focused {
    color: #009cbd !important;
}

.addressFlexDiv .dateInsurance .MuiInputLabel-filled.MuiInputLabel-shrink,
.addressFlexDiv-landing .dateInsurance .MuiInputLabel-filled.MuiInputLabel-shrink,
.insuranceAddressCard .input1 .MuiInputLabel-filled.MuiInputLabel-shrink,
.insuranceAddressCard .innerInputs.insurance .MuiInputLabel-filled.MuiInputLabel-shrink,
.insuranceAddressCard .input .MuiInputLabel-filled.MuiInputLabel-shrink {
    color: #6b6c6f !important;
    z-index: 1 !important;
}

.insuranceAddressCard .MuiFilledInput-underline:before,
.insuranceAddressCard .MuiFilledInput-underline:after {
    border-bottom: none !important;
    border: 1xp solid #009cbd;
}

.insuranceAddressCard .innerInputs.insurance .MuiFilledInput-underline:before {
    border-bottom: none !important;
}
.insuranceAddressCard .innerInputs.insurance .MuiFilledInput-underline:after {
    border-bottom: none !important;
    border: 1xp solid #009cbd;
}
.MuiFilledInput-underline .Mui-focused:after {
    transform: scaleX(1) !important;
}
.insuranceAddressCard .inputs {
    text-align: left;
}
.addressFlexDiv .inputs {
    // margin-top: -20px;

    @media (max-width: 768px) {
        width: 100%;
    }
}
.addressFlexDiv .innerInputs {
    display: flex;
    margin-top: 10px;

    .insuranceInnerInput1 {
        margin-right: 15px;
        width: 200px;

        @media (max-width: 768px) {
            width: 100%;
            margin-right: 0px;
            margin-top: 10px;
        }
    }
    .insuranceInnerInput2 {
        margin-right: 15px;
        width: 80px;
        @media (max-width: 768px) {
            margin-right: 0px;
            width: 100%;
            margin-top: 10px;
        }
    }
    .insuranceInnerInput3 {
        width: 160px;
        @media (max-width: 768px) {
            margin-right: 0px;
            width: 100%;
            margin-top: 10px;
        }
    }

    @media (max-width: 768px) {
        flex-direction: column;
        margin-top: 0px;
    }
}
.addressFlexDiv .innerInput1 {
    margin-top: 30px;
}
.insuranceAddressCard .error {
    font-size: 12px;
    margin: 3px 14px 0 14px;
    color: red;
    height: 20px;
    text-align: left;
}

.scheduleInsuranceCall .error {
    font-size: 12px;
    color: red;
}
.dateInsurance .MuiFormHelperText-root.MuiFormHelperText-contained.Mui-error.MuiFormHelperText-filled,
.ModalTimeDate .date11 .MuiFormHelperText-root.MuiFormHelperText-contained.Mui-error.MuiFormHelperText-filled {
    display: none;
}
.insuranceAddressCard .input1 .MuiInputBase-input,
.insuranceAddressCard .innerInput1 .MuiInputBase-input {
    border-radius: 5px;
    text-align: left;
    border: 1px solid #100b28 !important;
    background-color: #fff;
    margin-top: 0px;
    // height: 16px;
}

.insuranceAddressCard .input1 .MuiInputBase-input:focus,
.insuranceAddressCard .innerInput1 .MuiInputBase-input:focus {
    border: 1px solid #006dff !important;
}
.insuranceAddressCard .input1Douglas .MuiInputBase-input:focus,
.insuranceAddressCard .innerInputDouglas .MuiInputBase-input:focus {
    border: 1px solid #009cbd !important;
}
// .MuiInputLabel-filled.MuiInputLabel-shrink {
//     transform: translate(12px, 10px) scale(0.75) !important;
// }

.insuranceAddressCard .input1.date .MuiInputBase-input {
    color: #fff;
}

.arrangeLeft {
    text-align: left;
    position: fixed;
    margin-top: 120px;

    @media (max-width: 1024px) {
        position: unset;
        margin-top: 10px;
        // text-align: center;
    }
}

.lineInsurance {
    border-top: 0.5px solid #d2d2d2;
    margin-top: 40px;
    margin-bottom: 40px;
    @media (max-width: 600px) {
        display: none;
    }
}

.InnerBox1 {
    display: flex;
    align-items: center;
    position: fixed;
    margin-top: 210px;
    // justify-content: space-around;
    @media (max-width: 1024px) {
        position: unset;
        margin-top: 20px;
    }
    @media (max-width: 600px) {
        flex-direction: column;
    }
}

.boxPlanDetailInsurance {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;

    @media (max-width: 600px) {
        flex-direction: column;
    }
}

.line12 {
    border-top: 0.5px solid #d2d2d2;
}

.smallDiv {
    display: flex;
    justify-content: space-between;
    padding: 15px 20px 15px 20px;
}

.quoteSmall {
    color: #6b6c6f;
    font-size: 14px;
    padding: 20px;
    text-align: left;
}

.CloseIcon {
    display: flex;
    justify-content: space-between;
    padding: 10px 10px 10px 10px;
    align-items: center;
    height: 40px;
}

.CloseIcon > svg {
    height: 30px;
    width: 30px;
    color: #6b6c6f;
}

.date11 {
    width: 200px;
    margin-right: 20px !important;
    margin-top: 10px !important;

    @media (max-width: 600px) {
        width: 100%;
    }
}

.ModalTimeDate {
    display: flex;

    @media (max-width: 600px) {
        flex-direction: column;
    }
}
// .MuiPopover-root {
//     z-index: 90000 !important;
// }

.InsuranceOrderPageButton {
    padding: 0px 20px 0px 20px;
    display: flex;
    justify-content: flex-end;
    height: 75px;

    @media (max-width: 600px) {
        justify-content: center;
        margin-bottom: 70px !important;
    }
}

// .date11 .MuiFilledInput-underline:before {
//     border-bottom: none;
// }

.date11 .MuiFilledInput-underline:after,
.date11 .MuiFilledInput-underline:before {
    border-bottom: none !important;
}

.InsurancecoverDiv {
    height: 80vh;
    display: flex;
    align-items: center;

    .mainPageInsurance {
        text-align: center;
        padding: 0px 100px 0px 100px;
        width: 100%;

        .btn-div {
            display: flex;
            justify-content: center;
            margin-top: -10px !important;
        }
        .btn-div > button {
            width: 130px !important  ;
            @media (max-width: 600px) {
                width: 100% !important;
            }
        }

        @media (max-width: 600px) {
            padding: 0 10px;
        }
    }

    .InsuranceImageMain {
        width: 20%;
        height: 15%;

        @media (max-width: 600px) {
            width: 40%;
        }
    }
    @media (min-height: 850px) {
        height: 60vh;
    }
    @media (min-height: 1000px) {
        height: 55vh;
    }
    @media (min-height: 700px) {
        height: 60vh;
    }
}

.date11 .MuiFilledInput-root {
    // height: 50px;
    background-color: #fff;
    border: 1px solid #100b28 !important;
    border-radius: 0px !important;
}
.date11 .MuiFilledInput-root.Mui-error {
    border-color: #f44336;
}
.date11 .MuiFilledInput-root.Mui-error .MuiSvgIcon-root {
    fill: #f44336;
}

.date11 .MuiInputLabel-filled.MuiInputLabel-shrink {
    color: #006dff !important;
    z-index: 2 !important;
}
.date11 .MuiInputBase-input:focus {
    border: 1px solid #006dff !important;
    border-radius: 5px;
}
.date11 .MuiInputLabel-filled.MuiInputLabel-shrink.Mui-focused {
    color: #006dff !important;
}
.date11 .MuiFilledInput-root.Mui-focused {
    border: unset !important;
}

.dateNoDoug {
    .MuiInputLabel-filled.MuiInputLabel-shrink {
        color: #009cbd !important;
        z-index: 2 !important;
    }
    .MuiInputBase-input:focus {
        border: 1px solid #009cbd !important;
        border-radius: 5px;
    }
    .MuiInputLabel-filled.MuiInputLabel-shrink.Mui-focused {
        color: #009cbd !important;
    }
}

.checkbox11 .MuiTypography-body1 {
    color: #6b6c6f;
    font-size: 10px;
    margin-top: 10px;
}

.checkboxMain .MuiFormControlLabel-root {
    margin-top: 10px;
    align-items: flex-start !important;
}
.checkboxMain {
    .MuiCheckbox-colorPrimary.Mui-checked {
        color: #009cbd !important;
    }
}
.checkboxnoDoug {
    .MuiCheckbox-colorPrimary.Mui-checked {
        color: #006dff !important;
    }
}
.modalClasses {
    position: absolute;
    width: 500px;
    // height: 660px;
    height: 80vh;
    background-color: #ffffff;
    left: 40%;
    top: 10%;
    overflow: auto;

    // @media (max-width: 1500px) {
    //     top: 8%;
    //     left: 35%;
    // }

    // @media (max-width: 1400px) {
    //     left: 30%;
    //     top: 2%;
    // }

    @media (max-width: 1600px) {
        left: 35%;
    }
    @media (max-width: 1367px) {
        left: 30%;
    }
    @media (max-width: 1024px) {
        left: 25%;
    }
    @media (max-width: 768px) {
        left: 18%;
    }
    @media (max-width: 600px) {
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
    }
}
.modalClassesDouglasOrder {
    .MuiInputLabel-filled.MuiInputLabel-shrink {
        z-index: 1 !important;
        color: #100b28;
    }
    input {
        background-color: #fff;
        // border: 1px solid #100b28 !important;
        border-radius: 0px !important;
        color: #323744;
    }
    .date11 .MuiFilledInput-root {
        background-color: #fff;
        // border: 1px solid #100b28 !important;
        border-radius: 0px !important;
        color: #323744;
        height: unset !important;
    }
    .MuiCheckbox-colorPrimary.Mui-checked {
        color: #009cbd !important;
    }
}
// stepper
.MuiStepper-horizontal.insuranceStepper {
    display: none !important;
}

// .linearProgressInsurance .MuiLinearProgress-barColorPrimary {
//     // background-color: #273e59 !important;
//     // background-color: #0066f5 !important;
// }

.Para12 {
    color: #333333;
    font-size: 12px;
    margin-top: 20px;
    margin-bottom: 15px;
}

// summary insurance

.summaryInsurance {
    border: 1px solid #e2e2e2;
    width: 590px;
    margin: 25px 0px 0px 0px;

    .summaryInsuranceHead {
        height: 120px;
        background: #273e59;
        color: #fff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;

        @media (max-width: 600px) {
            padding: 5px;
        }
    }

    .summaryInsuranceCards {
        margin: 20px;
        border: 1px solid #e2e2e2;
        padding: 20px;

        .summaryButtonInsuranceCards {
            display: flex;
            justify-content: space-between;

            @media (max-width: 600px) {
                flex-direction: column;
            }

            .buttonInsuranceSummary {
                @media (max-width: 600px) {
                    display: none;
                }
            }
        }
        .buttonInsuranceSummaryMobile {
            display: none;
            @media (max-width: 600px) {
                display: unset;
            }
        }

        .summaryButtonInsuranceCards > div {
            @media (max-width: 600px) {
                margin-bottom: 10px;
            }
        }
    }
    .summaryInsuranceCards1 {
        margin: 20px;
        border: 1px solid #e2e2e2;
        // padding: 20px;
        .titleInsuaranceSummary {
            font-size: 22px;
            color: #333;
            padding: 20px;
            font-weight: bold;
            border-bottom: 1px solid #e2e2e2;
        }

        .boxPlanDetail {
            display: flex;
            padding: 20px;

            .boxPlanDetailInside {
                width: 73%;
                display: flex;
                flex-direction: row;
                justify-content: space-around;

                @media (max-width: 600px) {
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    margin-top: 5px;
                }
            }

            .policyDetailcurrency {
                color: #333333;
                font-size: 24px;
                margin: 20px 5px 0px 5px;

                @media (max-width: 600px) {
                    margin: 10px 5px 0px 5px;
                }
            }

            .currencyTag {
                color: #333333;
                font-size: 62px;
                font-weight: 800;
                font-family: 'Poppins', sans-serif;

                @media (max-width: 600px) {
                    font-size: 45px;
                }
            }

            .insuranceCardImage {
                width: 25%;

                @media (max-width: 600px) {
                    width: 70%;
                }
            }

            @media (max-width: 600px) {
                flex-direction: column;
            }
        }
    }

    @media (max-width: 600px) {
        margin: 15px 10px 0px 10px;
        width: 100%;
    }
}

.redirectInsuranceD {
    color: #009cbd;
    text-decoration: underline;
    cursor: pointer;
}
.redirectInsurance {
    color: #006dff;
    text-decoration: underline;
    cursor: pointer;
}

.peronalDetailsInsurance .MuiInputBase-input.Mui-disabled {
    color: #333;
    text-align: left;
}

.insuranceFooter {
    .textAboveFooter {
        position: fixed;
        bottom: 44px;
        font-size: 12px;
        color: #6b6c6f;
        // text-align: center;
        padding: 20px 80px;
        width: 1530px;
        line-height: 23px;
        background-color: #fff;

        @media (max-width: 1920px) {
            width: 1760px;
        }
        @media (max-width: 1600px) {
            width: 1555px;
        }
        @media (max-width: 1440px) {
            width: 1280px;
            line-height: 15px;
        }
        @media (max-width: 1366px) {
            padding: 20px 60px;
            width: 1250px;
        }
        @media (max-width: 1280px) {
            width: 1160px;
            line-height: 10px;
            font-size: 10px;
            padding: 5px 80px;
        }
        @media (max-width: 1024px) {
            width: 930px;
            font-size: 12px;
        }
        @media (max-width: 768px) {
            display: none;
        }
        // @media (min-width: 1020px) and (max-width: 1319px) {
        //     line-height: 14px;
        // }
        // @media (max-width: 600px) {
        //     margin: 0 10px;
        //     display: none;
        // }
    }

    .footerDashboard {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        border: #e2e2e2 1px solid;
        padding: 5px;
        margin-top: 50px;
        z-index: 999;
        .footerNameDetails {
            .footerNameDetails1 {
                color: #333333;
                font-size: 16px;
                font-weight: 700;
            }
            .footerNameDetails2 {
                color: #747e8b;
                font-size: 12px;
            }
        }
        // .footerNamequestion {
        //     display: none;
        // }
        .footerQuestion {
            color: #333333;
            font-size: 18px;
            font-weight: 500;
            margin: 0 25px;
        }
        .footerCallDetails {
            display: flex;
        }

        @media (max-width: 1024px) {
            .footerQuestion {
                font-size: 16px;
                margin: 0 10px;
            }
        }
        @media (max-width: 960px) {
            .footerNameDetails {
                margin-right: 10px;
            }
            .footerQuestion {
                display: none;
            }
        }
        @media (max-width: 768px) {
            position: fixed;
            margin-top: 10px;
            .footerNamequestion {
                display: block;
                margin-right: 20px;
                .footerNamequestion1 {
                    color: #333333;
                    font-size: 16px;
                    font-weight: 700;
                }
                .footerNamequestion2 {
                    color: #747e8b;
                    font-size: 12px;
                }
            }
            .footerNameDetails {
                display: none;
            }
            .footerCallDetails {
                display: none;
            }
        }
    }
}
.scheduleCallInsuranceMain {
    position: absolute;
    width: 70%;
    height: 90%;
    background-color: #ffffff;
    left: 15%;
    top: 5%;
    // @media (max-width: 1440px) {
    //     left: 25%;
    // }
    // @media (max-width: 1024px) {
    //     left: 15%;
    // }
    // @media (max-width: 960px) {
    //     left: 5%;
    // }
    .scheduleCallBack {
        display: none;
    }
    @media (max-width: 600px) {
        left: 0;
        top: 0;
        z-index: 2000;
        width: 100%;
        height: 100%;
        .scheduleCallBack {
            display: block;
            padding: 30px 0 0 30px;
        }
    }
}

.insuranceRecommendationMainPageModal {
    position: absolute;
    width: 70%;
    height: 100%;
    background-color: #fff;
    border-radius: 2px;
    padding: 20px;
    left: 30%;
    top: 0;
    overflow: auto;
    .coverageMainDiv {
        padding: 80px 150px 80px 40px;
        @media (max-width: 1440px) {
            padding: 35px 80px 35px 20px;
        }
        @media (max-width: 1024px) {
            padding: 35px 60px 35px 20px;
        }
        @media (max-width: 600px) {
            padding: 35px 10px 35px 10px;
        }
    }
    .content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0px 18px;
        padding-bottom: 24px;
    }

    .content .logoDiv {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    @media (max-width: 600px) {
        width: 100%;
        left: 0;
        padding: 0px;
        .content {
            flex-direction: column;
        }
        .content .logoDiv {
            margin-bottom: 10px;
        }
    }
}

.sfhfdjbdjbdh {
    margin-top: -20px;

    @media (max-width: 768px) {
        margin-top: 0px;
    }
}

.what-next-main-div-insurance {
    width: 590px;
    margin: 20px auto;

    @media (max-width: 600px) {
        width: 95%;
    }

    .what-next-insurance {
        font-size: 22px;
        margin: 10px 0;
        @media (max-width: 600px) {
        }
    }

    .card-3-next {
        padding: 20px;
        border: 1px solid #bebebe;
        box-shadow: 0px 0px 2px #e2e2e2;
        margin-bottom: 20px;
        border-radius: 3px;
    }
    .card-4-next {
        border: 1px solid #bebebe;
        box-shadow: 0px 0px 2px #e2e2e2;
        margin-bottom: 20px;
        border-radius: 3px;
    }

    .card-3-next {
        background: url('../../Assets/images/SecurityCard.png');
        background-size: cover;
        background-repeat: no-repeat;
    }

    .card-4-next {
        display: flex;
        height: 150px;
    }

    .card-image {
        width: 60%;
    }

    .card-heading-next-2 {
        width: 40%;
        font-size: 22px;
        font-weight: bold;
        padding: 20px;
    }

    .card-heading-next {
        width: 60%;
        font-size: 22px;
        font-weight: bold;
    }
    .highlighted {
        color: #30ab86;
    }
    .button-next-card {
        width: 100px !important;
        height: 40px !important;
        font-size: 14px !important;
        margin: 10px 0 !important;
        border-radius: 5px !important;
    }
}

.addressFlexDiv-landing .MuiFormControl-root {
    width: 100%;
}

.addressFlexDiv-landing .MuiFormControl-marginNormal {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}

// #react-select-2-input {
//     display: none !important;
// }
.select-state__input {
    display: none !important;
}

.react-select .css-yk16xz-control {
    border-color: #6b6c6f !important;
}

.css-1pahdxg-control {
    border-color: unset !important;
}

.douglasEllimenSite {
    .textAboveFooter {
        @media (max-width: 1440px) {
            width: 100%;
            line-height: 18px;
        }
    }
}
