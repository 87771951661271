.summarybox2{
    .summarybox_head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px 30px;
    }
    .summarybox_body{
        .summaryContent{
            .summaryContent_l {
                width: 60%;
                .img_wrapper {
                    text-align: left;
                    .img_name {
                        margin-top: 20px;
                    }
                }
            }
            .summaryContent_r {
                width: 40%;
                text-align:center;
                p{
                    margin-bottom: 20px;
                }
                .plan_wrapper{
                    display: inline-block;
                    text-align: left;
                }
            }
        }
    }
}

.summarybox_headbtn {
    color: #273E59;
    font-size: 14px;
    font-weight: 600;
    border: 1px solid #273E59;
    box-shadow: 0px 1px 1px #161d251a;
    background-color: #fff;
    border-radius: 4px;
    padding: 10px;
    cursor: pointer;
    transition: 0.4s linear;
    &:hover{
        background-color: #273E59;
        color: #fff;
    }
}

.buttom_btd_div_hs {
    text-align: center;
    margin-top: 20px;
}

.summarydetailbox {
    width: 590px;
    margin: 25px auto;
    border: 1px solid #E2E2E2;
    border-radius: 4px;
    max-width: calc(100% - 30px);
    overflow: hidden;
    font-size: 16px;
    background-color: #fff;
    .summarydetailbox_head {
        text-align: center;
        background-color: #273E59;
        padding: 25px 7px;
        color: #fff;
        h2 {
            margin: 0 0 15px;
            font-size: 24px;
            font-weight: 600;
        }
        p {
            margin: 0;
        }
    }
    .summarydetailbox_body {
        padding: 20px;
    }
    .borderbox {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 1px solid #E2E2E2;
        border-radius: 4px;
        padding: 18px 20px;
        color: #333333;
        font-size: 16px;
        .borderbox_top {
            display: flex;
            align-items: center;
            justify-content: space-between;
            h3 {
                margin: 0;
                font-size: 22px;
                font-weight: 400;
            }
            .topbtn {
                background-color: #fff;
                box-shadow: 0px 1px 1px #161D251A;
                border: 1px solid #273E59;
                padding: 10px 12px;
                border-radius: 4px;
                color: #273E59;
                font-size: 14px;
                font-weight: 500;
                cursor: pointer;
                transition: 0.4s linear;
                &:hover{
                    background-color: #273E59;
                    color: #fff;
                }
            }
        }
        .scheduled_datetime {
            display: flex;
            align-items: center;
            line-height: 1.3;
            margin-top: 20px;
            img {
                margin-right: 5px;
                max-height: 16px;
            }
        }
    }
}

.warmTransferClass {
    width: 90%;
    text-align: center;
    margin: 30px auto;
    padding: 30px 15px;
    background-color: #f0f0ff;
    font-size: 20px;
    line-height: 1.5;
}

@media (max-width:991px){
    .summarydetailbox {
        .summarydetailbox_head h2 {
            font-size: 20px;
        }
        .borderbox .borderbox_top h3 {
            font-size: 18px;
        }
    }
}
@media (max-width: 767px){
    .summarybox2 .summarybox_head {
        padding: 10px 15px;
    }
}
@media (max-width: 575px){
    .summarybox2 .summarybox_body .summaryContent {
        .summaryContent_l, .summaryContent_r {
            width: 50%;
        }
        .summaryContent_l .img_wrapper .img_name {
            margin-top: 10px;
        }
    }
    .summarybox_headbtn {
        font-size: 12px;
    }
}

@media (max-width: 480px){
    .summarydetailbox {
        .summarydetailbox_head {
            padding: 15px 5px;
        }
        .summarydetailbox_body {
            padding: 10px;
        }
        .borderbox {
            padding: 10px;
            font-size: 14px;
            .borderbox_top {
                flex-direction: column;
                align-items: flex-start;
                h3 {
                    margin-bottom: 10px;
                }
                .topbtn {
                    padding: 8px;
                }
            }
        }
    }
}