.TrustedCssDouglas {
    .MuiFilledInput-root {
        background-color: transparent;
        // border-bottom: 1px solid #009cbd !important;
        color: #009cbd !important;
    }
    .trustedProsTab-active {
        border-bottom: 1px solid #009cbd !important;
        color: #009cbd !important;
    }
    .MostPopularServiceViewAllService,
    .MuiTab-textColorInherit.Mui-selected {
        color: #009cbd !important;
    }
}

.helpInputAllService {
    width: 30%;
    @media (max-width: 600px) {
        width: 100%;
    }
    .MuiFormControl-root {
        border-radius: 4px 0 0 4px;
        min-width: 100%;
        width: auto;
        @media (max-width: 600px) {
            border-radius: 4px;
            width: 100%;
        }
    }

    .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
        padding: 20px 15px 15px 0px;
    }

    .MuiOutlinedInput-root.Mui-focused {
        border: 1px solid #006dff !important;
    }
    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: none !important;
        border: #100b28;
    }

    .MuiAutocomplete-inputRoot {
        flex-wrap: nowrap;
    }

    .MuiInputLabel-outlined.MuiInputLabel-shrink {
        transform: translate(16px, 0px) scale(0.75);
    }

    .MuiOutlinedInput-notchedOutline:hover {
        border: none;
        border-color: #100b28 !important;
    }
    .MuiInputLabel-filled.MuiInputLabel-shrink {
        z-index: 1 !important;
        color: #100b28 !important;
    }
    .MuiFormLabel-root.Mui-focused {
        color: #006dff !important;
        margin-bottom: 10px;
    }

    &::-webkit-scrollbar {
        width: 4px;
        height: 3px;
    }

    &::-webkit-scrollbar-track {
        background: lightgray;
    }

    /* scrollbar handle */
    &::-webkit-scrollbar-thumb {
        background-color: #989b9e;
    }
}
.helpInputAllServiceDoug {
    .MuiOutlinedInput-root.Mui-focused {
        border: 1px solid #009cbd !important;
    }
    .MuiFormLabel-root.Mui-focused {
        color: #009cbd !important;
        margin-bottom: 10px;
    }
}

.icon-card-skeleton {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    @media (max-width: 600px) {
        justify-content: center;
    }
}

.popularCardSkeleton {
    margin: 10px 20px 10px 0;
}

.trustedProsAddressDivAllService {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .addressText {
        font-size: 14px;
        line-height: 24px;
        font-weight: bold;
    }

    .MuiFilledInput-root {
        background-color: transparent;
        border-bottom: 1px solid #006dff !important;
        color: #006dff !important;
    }
    .trustedProsStepsAutoComplete {
        .MuiFilledInput-input {
            padding: 0;
            width: 300px;

            @media (max-width: 600px) {
                width: 250px;
            }
            @media (max-width: 375px) {
                width: 230px;
            }
            @media (max-width: 325px) {
                width: 180px;
            }
        }
    }

    @media (max-width: 600px) {
        display: none;
    }
}
.trustedProsAddressDivAllServiceDoug {
    .MuiFilledInput-root {
        background-color: transparent;
        border-bottom: 1px solid #009cbd !important;
        color: #009cbd !important;
    }
}
.MuiFilledInput-root:hover,
.MuiFilledInput-root:focus {
    background: none !important;
}

.allServicesHeadCover {
    margin-top: 42px !important;
}

.trustedPros-main-page {
    .MuiTab-wrapper {
        font-family: 'Roboto', sans-serif;
    }
    .main-head-2 {
        margin-top: 20px;
        margin-bottom: 5px;
        padding: 0;
    }

    .trustedPros-headersDiv {
        background: linear-gradient(to right, #fcfcfc, #ecebeb);
        padding: 30px 80px 0px;
        display: flex;

        .trustedProsHeaderContent,
        .trustedProsHeaderImageDiv {
            width: 50%;
            img {
                width: 100%;
            }
            @media (max-width: 600px) {
                width: 100%;
            }
        }
        .trustedInputTextarea {
            @media (max-width: 600px) {
                display: none;
            }
        }
        .trustedInputTextareaMobile {
            display: none;
            @media (max-width: 600px) {
                display: unset;
                width: 100%;
            }
        }

        @media (max-width: 1366px) {
            padding: 0px 60px;
        }

        @media (max-width: 600px) {
            padding: 0px 10px;
            flex-direction: column;
            align-items: flex-start;
            background: unset;
        }
    }

    .trustedHeaderText {
        color: #333333;
        font-size: 34px;
        line-height: 32px;
        font-weight: bold;
        text-transform: capitalize;
        margin-bottom: 12px;
        padding-top: 50px;

        @media (max-width: 1024px) {
            padding-top: 30px;
            font-size: 24px;
            margin-bottom: 5px;
        }

        @media (max-width: 768px) {
            font-size: 18px;
            margin-bottom: 0px;
            padding-top: 0px;
        }
        @media (max-width: 600px) {
            padding-top: 30px;
            font-size: 22px;
        }
    }

    .trustedHeaderSubText {
        color: #6b6c6f;
        font-size: 16px;
        line-height: 22px;

        @media (max-width: 1024px) {
            font-size: 13px;
        }
        @media (max-width: 768px) {
            font-size: 11px;
        }
        @media (max-width: 600px) {
            font-size: 12px;
        }
    }
    .TPScheduledService {
        margin-top: 30px;
        .browseTitleSS {
            color: #333333;
            padding: 0px 80px;
            font-size: 32px;
            font-weight: bold;
            @media (max-width: 1366px) {
                padding: 0 60px;
            }

            @media (max-width: 600px) {
                font-size: 20px;
                padding: 0 10px;
            }
        }
        .TPSSCards {
            display: flex;
            padding: 0px 80px;
            flex-wrap: wrap;

            @media (max-width: 1366px) {
                padding: 0 60px;
            }

            @media (max-width: 600px) {
                padding: 0 10px;
            }
        }
    }

    // @media (max-width: 600px) {
    //     margin-top: 250px;
    // }
}
.TrustedProsMainRemoveDouglas {
    @media (max-width: 600px) {
        margin-top: 260px !important;
    }
    @media (max-width: 320px) {
        margin-top: 280px !important;
    }
}
.removeMargintrustedProsInputDivSectionAA {
    margin-top: 10px !important;
    width: 80% !important;
    margin-bottom: 20px;
    .MuiFormControl-root {
        border-radius: 0px !important;
        border: 1px solid #100b28 !important;
        border-right: none !important;
        @media (max-width: 600px) {
            border: 1px solid #100b28 !important;
        }
    }
    .MuiFilledInput-input {
        border-radius: 0px !important;
    }
    @media (max-width: 1024px) {
        margin-bottom: 0px;
        width: 100% !important;
    }
}
.trustedProsInputDivSectionAA {
    display: flex;
    // width: 85%;
    width: 100%;
    margin-top: 40px;

    @media (max-width: 600px) {
        flex-direction: column;
        width: 100%;
    }

    .trustedProsInput .MuiOutlinedInput-input {
        // padding: 27px 12px 10px;
        padding: 16px 6px 10px !important;
    }
    .MuiAutocomplete-hasPopupIcon .MuiAutocomplete-inputRoot,
    .MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot {
        padding-right: 0px;
    }

    .trustedProsZipCodeInput .MuiFilledInput-input {
        padding: 26px 12px 5px;
    }

    .trustedProsInput .MuiOutlinedInput-notchedOutline,
    .trustedProsZipCodeInput .MuiOutlinedInput-notchedOutline {
        border: none;
    }

    .helpInput {
        // width: 50%;
        width: 40%;
        @media (max-width: 600px) {
            width: 100%;
        }
        .MuiFormControl-root {
            border: 1px solid #6b6c6f;
            border-radius: 4px 0 0 4px;
            width: 100%;
            @media (max-width: 600px) {
                border-radius: 4px;
                width: 100%;
            }
        }
    }

    .zipInput {
        // width: 25%;
        width: 40%;
        @media (max-width: 600px) {
            width: 100%;
        }
        .MuiFormControl-root {
            border: 1px solid #6b6c6f;
            border-left: none;
            width: 100%;
            @media (max-width: 600px) {
                border-left: 1px solid #6b6c6f;
                border-radius: 4px;
                width: 100%;
                margin-top: 14px;
            }
        }
    }

    .trustedProsButtonDiv {
        // width: 25%;
        width: 20%;
        @media (max-width: 600px) {
            width: 100%;
            padding-top: 27px;
            padding-bottom: 43px;
        }
        .trustedProsGetStartedButton {
            width: 100% !important;
            height: 100% !important;
            border-radius: 0 4px 4px 0 !important;
            margin: 0 !important;

            @media (max-width: 1024px) {
                font-size: 12px !important;
            }

            @media (max-width: 768px) {
                font-size: 10px !important;
            }
            @media (max-width: 600px) {
                border-radius: 4px !important;
                height: 53px !important;
                font-size: 16px !important;
                font-weight: 900;
            }
        }
        .removeBorderRadius {
            border-radius: 0px !important;
        }
    }

    .MuiInputLabel-outlined.MuiInputLabel-shrink {
        margin-top: 12px;
        color: #6b6c6f;

        @media (max-width: 768px) {
            font-size: 10px !important;
        }
        @media (max-width: 600px) {
            font-size: 12px !important;
        }
    }

    .MuiInputLabel-filled.MuiInputLabel-shrink {
        margin-top: -3px;
        color: #6b6c6f !important;
        margin-bottom: unset !important;

        @media (max-width: 768px) {
            font-size: 10px !important;
        }
        @media (max-width: 600px) {
            font-size: 12px !important;
        }
    }

    .MuiFilledInput-root {
        background: none !important;
    }

    .MuiOutlinedInput-root {
        margin-right: 0 !important;
    }

    @media (max-width: 1024px) {
        width: 100%;
        margin-top: 22px;
    }
    @media (max-width: 768px) {
        margin-top: 10px;
    }
}
.popularHomeServiceDiv {
    background: #f5faf8;
}
.popularHomeServiceDiv {
    background: #f5faf8;
}
.browseByCategoryDiv,
.popularHomeServiceDiv {
    padding: 35px 0;

    .browseTitle {
        color: #333333;
        padding: 0px 80px 20px;
        font-size: 34px;
        line-height: 32px;
        font-weight: bold;

        @media (max-width: 1366px) {
            padding: 0px 60px 20px;
        }

        @media (max-width: 1024px) {
            font-size: 24px;
            padding-bottom: 15px;
        }

        @media (max-width: 768px) {
            font-size: 18px;
            padding-bottom: 0;
        }
        @media (max-width: 600px) {
            padding: 0px 10px;
        }
    }
    .trustedProsTabs {
        display: flex;
        padding: 15px 80px 0;
        border-bottom: 1px solid lightgray;

        .trustedProsTab {
            font-size: 14px;
            line-height: 24px;
            margin-right: 30px;

            @media (max-width: 768px) {
                font-size: 11px;
            }
            @media (max-width: 600px) {
                font-size: 14px;
                margin-right: 40px;
            }
        }
        @media (max-width: 600px) {
            overflow-x: scroll;
        }
        .trustedProsTab-active {
            font-size: 14px;
            line-height: 24px;
            color: #009cbd;
            font-weight: bold;
            border-bottom: 1px solid #009cbd;
            margin-right: 30px;

            @media (max-width: 768px) {
                font-size: 11px;
            }
            @media (max-width: 600px) {
                font-size: 14px;
                margin-right: 40px;
            }
        }
        @media (max-width: 1366px) {
            padding: 15px 60px 0;
        }

        @media (max-width: 768px) {
            padding: 5px 60px 0;
        }
        @media (max-width: 600px) {
            padding: 5px 10px 0;
            // overflow-x: scroll;
        }
    }

    @media (max-width: 768px) {
        padding: 20px 0;
    }
}

.MostPopularServiceDiv {
    background: #f5faf8;
    padding: 30px 80px;

    .MostPopularServiceTitleDiv {
        display: flex;
        justify-content: space-between;
        padding-bottom: 20px;

        .MostPopularServiceTitle {
            font-size: 24px;
            line-height: 32px;
            font-weight: bold;
            color: #333333;

            @media (max-width: 1024px) {
                font-size: 20px;
            }

            @media (max-width: 768px) {
                font-size: 16px;
            }
        }

        .MostPopularServiceViewAllService {
            font-size: 14px;
            line-height: 42px;
            color: #006dff !important;
            font-weight: bold;
            cursor: pointer;

            @media (max-width: 768px) {
                font-size: 11px;
            }
            @media (max-width: 600px) {
                // display: none;
            }
        }
        .MostPopularServiceViewAllServiceDoug {
            color: #009cbd !important;
        }
    }

    .PopularCardsDiv {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        @media (max-width: 600px) {
            justify-content: center;
            align-items: center;
        }
        .popularCard {
            width: 15% !important;
            margin: 10px 6px;
            background: #ffffff !important;
            border: 1px solid #dadce0 !important;

            @media (max-width: 600px) {
                width: 47% !important;
            }

            img {
                height: 100px !important;
                width: 100px !important;

                @media (max-width: 1024px) {
                    height: 70px !important;
                    width: 70px !important;
                }

                @media (max-width: 768px) {
                    height: 50px !important;
                    width: 50px !important;
                }
                @media (max-width: 600px) {
                    height: 100% !important;
                    width: 100% !important;
                }
            }
        }
    }

    .MostPopularServiceViewAllServiceMobile {
        display: none;
    }

    .PopularCardsDivMobile {
        display: none;
        .popularCardMobile {
            @media (max-width: 600px) {
                margin-bottom: 23px;
            }
            @media (max-width: 375px) {
                width: 165px !important;
            }
            @media (max-width: 320px) {
                width: 142px !important;
            }
        }
        @media (max-width: 600px) {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
        }
    }

    @media (max-width: 1366px) {
        padding: 15px 60px 0;
    }
    @media (max-width: 600px) {
        padding: 10px 10px 0;
    }
}

.trustedPros-last-div {
    display: flex;
    padding-top: 50px;

    @media (max-width: 1024px) {
        padding-top: 30px;
    }
    @media (max-width: 600px) {
        flex-direction: column;
    }
}

.trustedPros-last-div-left {
    width: 50%;
    @media (max-width: 600px) {
        width: 100%;
    }
}

.trustedPros-last-div-right {
    width: 50%;
    @media (max-width: 600px) {
        width: 100%;
    }
}

.lastImage-trustedPros {
    width: 100%;
    height: 100%;
}

.trustedPros-left-content-div {
    width: 75%;
    padding: 80px 85px;

    @media (max-width: 1024px) {
        padding: 60px;
    }
    @media (max-width: 600px) {
        width: 80%;
        padding: 12px;
    }
}

.left-buttons-div {
    display: flex;
    @media (max-width: 600px) {
        display: none;
    }
}
.ViewAllServiceBackIconDiv {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.viewAllTitle {
    padding: 0 20px;
    font-size: 24px;
    font-weight: bold;
}

.search-address-div {
    display: flex;
    justify-content: space-between;
    padding: 30px 0 50px 0;
}

.left-heading-div {
    font-size: 34px;
    font-weight: bold;
    padding-bottom: 20px;

    @media (max-width: 1024px) {
        font-size: 24px;
    }

    @media (max-width: 768px) {
        font-size: 18px;
        padding-bottom: 10px;
    }
    @media (max-width: 600px) {
        font-size: 22px;
    }
}
.left-sub-heading-div {
    opacity: 0.6;
    padding-bottom: 40px;

    @media (max-width: 768px) {
        font-size: 13px;
    }
}
.image-1-left,
.image-2-left {
    padding: 10px 10px 10px 0;

    @media (max-width: 768px) {
        width: 50%;
    }
    @media (max-width: 425px) {
        width: 44%;
        padding: 10px;
    }
}

.left-buttons-div-mobile {
    display: none;
    @media (max-width: 600px) {
        display: flex;
    }
}

.scroll-cardsTrusted {
    display: flex;
    // width: 100%;
    overflow-x: hidden;
    padding: 30px 0px 30px 80px;
    scroll-behavior: smooth;

    @media (max-width: 1366px) {
        padding: 30px 0px 30px 60px;
    }

    @media (max-width: 600px) {
        padding: 10px 0px 10px 10px;
    }
}

.scroll-card {
    margin-right: 20px;
}
.float-div {
    float: right;
    margin-top: -12%;
    z-index: 100;
    position: relative;

    @media (max-width: 1600px) {
        margin-top: -15%;
    }

    @media (max-width: 1024px) {
        margin-top: -20%;
    }

    @media (max-width: 768px) {
        margin-top: -23%;
        img {
            width: 40px;
        }
    }

    @media (max-width: 425px) {
        margin-top: -34%;
    }
}

.float-left-div {
    float: left;
    margin-top: -12%;
    z-index: 100;
    position: relative;

    @media (max-width: 1600px) {
        margin-top: -15%;
    }

    @media (max-width: 1024px) {
        margin-top: -20%;
    }
    @media (max-width: 768px) {
        margin-top: -23%;
        img {
            width: 40px;
        }
    }

    @media (max-width: 425px) {
        margin-top: -34%;
    }
}

.projectCard {
    height: 284px !important;

    @media (max-width: 1024px) {
        height: 250px !important;
    }

    @media (max-width: 768px) {
        height: 240px !important;
    }
}

.trustedPros {
    .MuiTabs-root {
        padding: 0 80px;

        @media (max-width: 1366px) {
            padding: 0 60px;
        }

        @media (max-width: 600px) {
            padding: 0 10px;
        }
    }

    .MuiTab-textColorInherit.Mui-selected {
        font-weight: bold !important;
        color: #009cbd;
    }
}

.loaderTrustedProsRecomendation {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 130%;
    position: absolute;
    width: 100%;
    background-color: #ffffff;
    left: 0px;
    top: 0px;
    z-index: 100;
}
.browseByCategoryDiv .MuiTabs-indicator {
    // background: #009cbd !important;
    background: #0066f5 !important;
}
.browseByCategoryDivDouglas .MuiTabs-indicator {
    background: #009cbd !important;
    // background: #0066f5 !important;
}
