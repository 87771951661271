.main-progressBar {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;

    //    margin:10px;
    width: 100%;
    height: auto;

    @media (max-width: 600px) {
        width: 94%;
        margin: 0px 4px;
    }
    .progressBar-content {
        margin: 10px 0px;
        .text {
            font-family: Roboto;
            font-size: 12px;
            padding: 8px 0px;
            color: #6b6c6f;
            display: block;

            text-transform: capitalize;
            opacity: 1;

            @media (max-width: 600px) {
                width: 130px;
            }
        }
        .text-no {
            font-family: Roboto;
            font-size: 28px;
            font-weight: 900;

            color: #000;
            padding: 0px 12px;
        }
    }
    .progressBarRight {
        min-width: 128px;
        .progressBarRightColor {
            content: '';
            background-color: #273e59;
            height: 23px;
        }
    }

    .progressBarLeft {
        min-width: 128px;
        .progressBarLeftColor {
            content: '';
            background-color: #00d56d;
            height: 23px;
        }
    }

    @media (max-width: 600px) {
        width: 100%;
        display: block;
    }
}
.navbarStyle {
    scroll-behavior: smooth;
}

.selected-nav {
    background-color: #f0f4fc;
    color: #333;
    font-weight: 700;
}
