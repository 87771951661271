.service-card-main-div{
   .service-card-ImgDiv{
       height: 200px;
   }

   .sideTag{
       height: 100px;
       float: right;
   }

   .service-card-ContentDiv{
       padding: 20px ;
   }

   .service-card-Title{
       font-size: 16px;
       font-weight: bold;
   }
   .service-card-points{
      padding-top: 10px;
   }
   .check-point-div{
       display: flex;
       padding: 5px 0;
   }
   .point{
       font-size: 14px;
   }
   .likes-div{
       display: flex;
   }
   .favoriteIcon{
       margin-right: 6px;
   }
   .favoriteText{
       opacity: 0.8;
       font-size: 14px;
   }
}

.service-card-main-div:hover{
    border:2px solid #0066F5  !important;
    border-radius: 8px !important;
}