.TrustedScheduledServicesMain {
    margin: 30px 80px 0px 80px;

    @media (max-width: 1920px) {
        margin: 30px 210px 0px 210px;
    }

    @media (max-width: 1500px) {
        margin: 30px 80px 0px 80px;
    }

    .TrustedScheduledServicesheading {
        font-size: 24px;
        font-weight: 600;
        font-family: sans-serif;
    }

    .TrustedScheduledServicespath {
        display: flex;
        // margin-top: -25px;
        font-size: 12px;
    }

    .TrustedScheduledServicesPara {
        color: #bebebe;
    }

    @media (max-width: 1366px) {
        margin: 30px 60px 0px 60px;
    }

    @media (max-width: 600px) {
        margin: 30px 10px 0px 10px;
    }

    .TrustedScheduledAccordion {
        display: flex;
        padding-bottom: 12px;

        .TrustedScheduledLine {
            align-self: center;
            border-top: 2px solid #e0e0e0;
            width: 42%;
        }

        .TrustedScheduledHead {
            color: #333333;
            font-size: 14px;
            text-align: center;
            padding-right: 7px;
            padding-left: 7px;
            align-self: center;
        }
        .TrustedScheduledNumber {
            border: 1px solid #6b6c6f;
            border-radius: 10px;
            color: #333333;
            font-size: 12px;
            text-align: center;
            padding: 6px 16px;
            margin-right: 7px;
        }
    }
    .TrustedScheduledShareCollapse {
        display: none;
    }
    .TrustedScheduledAccordion:hover .TrustedScheduledLine {
        border-top: 2px solid #333;
    }
    .TrustedScheduledAccordion:hover .TrustedScheduledHead {
        font-weight: 900;
    }
    .TrustedScheduledAccordion:hover .TrustedScheduledShareCollapse {
        display: flex;
    }

    .TrustedScheduledRow {
        display: flex;
        justify-content: space-between;
        padding-top: 34px;
        padding-bottom: 27px;

        .TrustedScheduledSearch {
            background-color: #fff;
            border: 1px solid #bebebe;
            height: 34px;
            width: 284px;
            border-radius: 4px;
        }
        .TrustedScheduledSearch .MuiFilledInput-adornedStart {
            background-color: #fff;
            height: 18px;
        }
        .TrustedScheduledSearch .MuiFilledInput-input {
            font-size: 14px;
        }

        @media (max-width: 600px) {
            flex-wrap: wrap;

            .searchBarDiv {
                width: 100%;
            }
        }
    }

    .TrustedScheduledSort {
        margin-top: -10px;
        display: flex;
        align-items: center;

        .TrustedScheduledSortIcon {
            text-align: left;
            letter-spacing: 0px;
            color: #333333;
            opacity: 1;
            text-decoration: underline;
        }
        @media (max-width: 600px) {
            .TrustedScheduledSortIcon {
                font-size: 14px !important;
            }
            margin-top: 30px;
        }
    }
    .TrustedScheduledSort .react-select {
        width: 150px;
    }

    .scheduleServiceCard{
        margin-bottom: 20px;
    }
}
