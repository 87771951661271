.collectionCardTitle {
  // height: 14px;
  line-height: 30px;
  font-size: 20px;
  text-align: left;
  font-weight: 800;
  color: #333;
  opacity: 1;
  margin: 20px 0 15px 0;
}
.collectionCardPara {
  height: 16px;
  text-align: left;
  line-height: 28px;
  font-size: 14px;
  color: #333333;
  opacity: 0.8;
  margin: 10px 0px;
  // padding-top: 20px;
}

@media(max-width:1024px){
  .collectionCardTitle{
 font-size: 16px;
 margin: 20px 0 5px 0;
  }
  .card-height-width{
    height: 250px !important;
  }
  .collectionCardPara {
    line-height: 18px;
    font-size: 12px;
    margin: 5px 0px;
    // padding-top: 20px;
  }
  
}

// @media(max-width:768px){
//   .collectionCardTitle{
//  font-size: 14px;
//  margin: 0;
//  line-height: 18px;
//   }
//   .card-height-width{
//     height: 200px !important;
//   }
//   .collectionCardPara {
//     line-height: 12px;
//     font-size: 10px;
//     margin: 5px 0px;
//     // padding-top: 20px;
//   }

// }
  
@media(max-width: 1440px) {
  .collectionCardTitle {
    line-height: 25px;
    font-size: 16px;
    margin: 20px 0 10px 0;
}
}

@media(max-width: 1366px) {
  .collectionCardTitle {
    font-size: 16px;
    line-height: 26px;
}

.collectionCardPara {
    font-size: 13px;
    line-height: 26px;
}
}

// @media(max-width: 1024px) {
//   .collectionCardTitle {
//     line-height: 20px;
//     font-size: 12px;
//     margin: 10px 0 0px 0;
// }

// .collectionCardPara {
//     line-height: 18px;
//     font-size: 11px;
// }
// }

@media(max-width: 768px) {
  .collectionCardTitle {
    line-height: 14px;
    font-size: 10px;
    margin: 8px 0 0px 0;
  }
  .collectionCardPara {
    line-height: 16px;
    font-size: 10px;
  }
}

@media (max-width: 425px) {
  .collectionCardTitle, .collectionCardPara{
    text-align: center;
  }

  .card-height-width{
    height: auto !important;
    width: auto;
  }

  .collectionCardPara {
    font-size: 11px;
    text-align: center;
    line-height: 22px;
    height: auto;
}
}