.documentryMainDiv {
    margin: 0px 0px 0px 80px;
    @media (max-width: 1366px) {
        margin: 0px 0px 0px 60px;
    }

    @media (max-width: 600px) {
        margin: 0px 0px 0px 10px;
    }
}
.ParaDocumentry {
    color: #bebebe;
}

.pathDocumentry {
    display: flex;
    // margin-top: -25px;
    font-size: 12px;
}
.headingDocumentry {
    font-size: 24px;
    font-weight: bold;
    font-family: sans-serif;
    padding: 22px 0px 0px 0px;
    font-family: 'Roboto';
}
.documentryMainOFAll {
    background-image: linear-gradient(#fdfbfb, #ebedee);
}
.documentry-sub-div {
    padding: 0 80px 60px 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 768px) {
        padding: 0 80px 25px 80px;
    }
    @media (max-width: 600px) {
        padding: 65px 20px 25px 20px;
    }
    @media (max-width: 475px) {
        padding: 40px 20px 25px 20px;
    }
    // height: 300px;
    // @media (max-width: 1366px) {
    //     padding: 0 60px;
    // }
    // @media (max-width: 768px) {
    //     display: none;
    // }
}
.documentry-sub-div1 {
    width: 40%;
    @media (max-width: 768px) {
        width: 60%;
    }
    .documentSubHeadingDiv1 {
        font-size: 34px;
        font-family: 'Roboto';
        font-weight: bold;
        text-transform: capitalize;
        padding-bottom: 35px;
        span {
            color: #0066f5;
        }
        @media (max-width: 768px) {
            font-size: 26px;
        }
        @media (max-width: 600px) {
            font-size: 16px;
        }
    }
}
.documentry-sub-div2 {
    @media (max-width: 768px) {
        display: none;
    }
}
.documentry-sub-div4 {
    display: none;
    @media (max-width: 768px) {
        display: unset;
        img {
            height: 200px;
        }
        // height: 200px;
    }

    @media (max-width: 600px) {
        img {
            height: 100px;
        }
    }
}
.documentInventoryInnerDiv {
    padding: 0 80px 80px 80px;

    @media (max-width: 1366px) {
        padding: 0 60px 60px;
    }
    @media (max-width: 400px) {
        padding: 0px 24px 60px;
    }
    .documentryHeadRecent {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 25px;
        .documentRecent {
            font-size: 24px;
            font-family: 'Roboto';
            font-weight: bold;
            @media (max-width: 500px) {
                font-size: 16px;
            }
        }
        .documentView {
            font-size: 16px;
            font-family: 'Roboto';
            color: #0066f5;
            cursor: pointer;

            @media (max-width: 500px) {
                font-size: 12px;
            }
        }
    }
    .documentImages {
        padding: 30px 0px 10px 0px;
        display: flex;
        width: 100%;
        overflow-x: auto;

        // @media (max-width: 600px) {
        //     overflow-x: scroll;
        // }
        // overflow-x: scroll;
        // flex-wrap: wrap;

        .documentImagesInfo {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            // width: 30%;
            padding-right: 25px;
            width: 150px;

            img {
                max-height: 100%;
                // max-width: 100%;
                height: 100px;
                width: 100%;
            }
            @media (max-width: 1300px) {
                width: 16%;
            }
            @media (max-width: 1024px) {
                width: 19%;
            }
            @media (max-width: 768px) {
                width: 29%;
            }
            @media (max-width: 600px) {
                width: 40%;
            }
            @media (max-width: 500px) {
                width: 47%;
            }
            @media (max-width: 400px) {
                width: 68%;
            }
            .imageName {
                font-size: 14px;
                font-weight: bold;
                font-family: 'Roboto';
                padding-top: 5px;
            }
            .folderName {
                font-size: 12px;
                color: #6b6c6f;
                font-family: 'Roboto';
                padding: 6px 0px 15px 0px;
            }
        }
    }
    .documentFolders {
        padding-top: 8px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .documentFolderSub {
            cursor: pointer;
            display: flex;
            // justify-content: space-between;
            padding: 18px 22px;
            border: 1px solid #e0e0e0;
            border-radius: 4px;
            opacity: 1;
            width: 28%;
            align-items: center;
            margin-top: 22px;
            @media (max-width: 1024px) {
                width: 42%;
            }
            @media (max-width: 768px) {
                width: 39%;
            }
            @media (max-width: 600px) {
                width: 85%;
            }
            .folderDocumentName {
                font-size: 18px;
                font-family: 'Roboto';
                font-weight: 600;
                margin-left: 26px;
                @media (max-width: 400px) {
                    font-size: 16px;
                }
            }
        }
    }
}
.inputValueDiv {
    .MuiFilledInput-root {
        width: 100%;
        background-color: #fff !important;
        border: 1px solid #6b6c6f;
        border-radius: 5px;
        margin-bottom: 10px;

        // @media (max-width: 768px) {
        //     height: 40px;
        // }
    }

    .MuiFilledInput-root.Mui-focused {
        width: 100%;
        background-color: #fff !important;
        border: 1px solid #006df5;
        border-radius: 5px;

        // @media (max-width: 768px) {
        //     height: 40px;
        // }
    }
    // .css-2b097c-container {
    //     width: 100%;
    // }

    .MuiFilledInput-root.Mui-error {
        width: 100%;
        background-color: #fff !important;
        border: 1px solid red;
        border-radius: 5px;

        // @media (max-width: 768px) {
        //     height: 40px;
        // }
    }

    .MuiFilledInput-root:focus {
        border: 1px solid #006df5;
        background: transparent;
    }

    .MuiFilledInput-underline:before,
    .MuiFilledInput-underline::after {
        border-bottom: none !important;
    }
    // .signUpInput input:focus {
    //     background-color: #fff;
    //     border: 1px solid #006df5;
    //     border-radius: 5px;
    // }
    .MuiInputLabel-filled.MuiInputLabel-shrink {
        z-index: 1 !important;
        color: #6b6c6f;

        @media (max-width: 768px) {
            font-size: 12px !important;
        }
    }
    .MuiFormLabel-root.Mui-focused {
        color: #006df5;
    }

    .MuiFormControl-root {
        width: 100%;
    }
    .MuiFilledInput-input {
        padding: 17px 12px 10px;
    }
    .css-2b097c-container {
        width: 100% !important;
    }
    .react-select .select-state__control {
        min-height: 50px;
    }
}
.modalFolderCreate {
    // position: absolute;
    width: 40%;
    height: 75%;
    background-color: #ffffff;
    overflow-y: auto;
    padding: 4px 53px 10px 53px;

    .error {
        height: 20px;
        font-size: 12px;
        color: red;
        margin-top: 3px;
    }

    @media (max-width: 768px) {
        width: 70%;
    }
    @media (max-width: 600px) {
        width: 75%;
        padding: 30px 25px 26px 25px;
    }

    // @media (max-width: 750px) {
    //     width: 70%;
    //     // height: 100%;
    //     // top: 0;
    //     // left: 8%;
    // }
    // @media (max-width: 600px) {
    //     width: 88%;
    //     padding: 0px 9px 26px 9px;

    //     // height: 100%;
    //     // top: 0;
    //     // left: 8%;
    // }
}
.modalFolderCreateFolder {
    // position: absolute;
    width: 40%;
    height: 45%;
    background-color: #ffffff;
    overflow-y: auto;
    padding: 4px 53px 10px 53px;

    .error {
        height: 20px;
        font-size: 12px;
        color: red;
        margin-top: 3px;
    }

    @media (max-width: 768px) {
        width: 70%;
    }
    @media (max-width: 600px) {
        width: 75%;
        height: unset !important;
        padding: 30px 25px 26px 25px;
    }

    // @media (max-width: 750px) {
    //     width: 70%;
    //     // height: 100%;
    //     // top: 0;
    //     // left: 8%;
    // }
    // @media (max-width: 600px) {
    //     width: 88%;
    //     padding: 0px 9px 26px 9px;

    //     // height: 100%;
    //     // top: 0;
    //     // left: 8%;
    // }
}
.inputTextValueDiv {
    // padding-bottom: 20px;
    textarea {
        margin-top: 0px;
        width: 96% !important;
        border: 1px solid #d8dce6 !important;
        opacity: 1;
        padding: 12px 8px;
    }
}
.buttonDocument {
    padding-top: 30px;
    button {
        width: 100% !important;
    }
}

.ConfirmationDetailsDiv {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding-bottom: 10px;
    position: relative;

    .MuiFormControl-root {
        width: 100% !important;
        border: 1px solid #eff2f7;
        border-radius: 4px;
    }

    .MuiFilledInput-root {
        background-color: #ffffff;
        border: 1px solid #3c7084;
    }

    .MuiFilledInput-underline:before {
        border-bottom: none;
    }

    .ConfirmationLabel {
        font-family: 'Lato', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 11px;
        line-height: 16px;
        letter-spacing: 0.02em;
        text-transform: capitalize;
        color: #333333;

        padding-bottom: 10px;
    }
    .uploadText {
        // position: absolute;
        // top: 43%;
        // left: 48%;
        display: flex;
        align-items: center;
        height: 100%;
        flex-direction: column;
        justify-content: center;
    }

    .react-select .css-yk16xz-control {
        border-color: #6b6c6f;
    }

    .inputFileDiv {
        width: 93%;
        height: 90px;
        cursor: pointer;
        border: 2px dashed #333333;
        border-radius: 4px;
        padding: 20px;

        .helperTextUpload {
            color: #d3cfcf;
            font-size: 12px;
            line-height: 24px;
        }

        .textUpload {
            color: #0066f5;
            font-size: 14px;
            line-height: 18px;
            text-decoration: underline;
            cursor: pointer;
        }

        img {
            max-height: 100%;
        }
        @media (max-width: 1024px) {
            width: 90%;
        }
        @media (max-width: 375px) {
            width: 84%;
        }
    }
}
.helpInputDocument {
    width: 100%;
}
.topAddDocument {
    display: flex;
    @media (max-width: 768px) {
        display: none;
    }
}
.topAddDocumentMobile {
    display: none;
    @media (max-width: 768px) {
        display: flex;

        padding: 0 80px 25px 80px;
    }
    @media (max-width: 600px) {
        padding: 0 20px 25px 20px;
    }
}
.DocumentGetStartedButton {
    width: 150px !important;
    margin: 0px !important;
}
