.checkbox-item-trusted {
    display:flex;
    align-items: center;
    margin-top: -5px;
    margin-left: 10px;
  }
  
  .heads-trusted {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 5px;
    padding: 10px 10px 0 10px;
  }
  
  .line1-trusted {
    border-top: 0.3px solid #d2d2d2;
    margin-top: 5px;
    margin-bottom: 10px;

    // margin-right: -20px;
  }
  
  .MuiSvgIcon-root {
    height: 18px;
  }
  .checkbox-item-trusted .MuiTypography-body1 {
    font-size: 16px;

    color: #333333;
    opacity: 0.9;

    @media (max-width: 1024px) {
      font-size: 13px;
    }

    @media (max-width: 768px) {
      font-size: 11px;
    }
  }

  .main-filters-trusted{
      width: 100%;
      border: 2px solid #E0E0E0;
      box-shadow: 0 2px 2px #00000024;
      border-radius: 5px;

      .MuiFormControlLabel-root {
        margin-right: 0;
      }
  }

  
  
  .headDv-trusted {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  
