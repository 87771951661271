.scheduledServiceCard {
    border: 1px solid #e2e2e2;
    box-shadow: 0px 3px 4px #0000000d;
    border-radius: 4px;
    padding: 24px 22px;
    position: relative;

    // .scheduledServiceAgentVerified {
    //     width: 86px;
    //     height: 80px;
    //     transform: rotate(-45);
    //     // transform: matrix(0, -1, 1, 0, 0, 0);
    //     opacity: 1;
    //     background-color: #006dff;
    //     color: #fff;
    //     font-size: 9px;
    // }
    .scheduledServiceShare {
        display: flex;
        justify-content: flex-end;

        @media (max-width: 600px) {
            display: none;
        }
    }

    .scheduledServiceCardCol {
        display: flex;

        @media (max-width: 600px) {
            flex-wrap: wrap;
        }
    }

    .scheduledServiceChar {
        color: #6b6c6f;
        font-size: 35px;
        font-weight: 800;
        font-family: Poppins;
        border-radius: 6px;
        padding: 5px 7px 4px 8px;
        width: 55px;
        height: 58px;

        @media (max-width: 768px) {
            font-size: 25px;
        }
    }

    .scheduledServiceCardLogo {
        width: 15%;
    }

    .scheduledServiceSecondRow {
        width: 50%;
        padding-left: 20px;
        .scheduledServiceCompany {
            color: #333333;
            font-size: 16px;
            font-weight: 800;
        }

        .scheduledServiceRating {
            display: flex;
            padding-top: 11px;
            .scheduledServiceRatingWrapper {
                width: 57px;
                height: 26px;
                border-radius: 20px;
                border: 1px solid #1966e7;
                background-color: #dfe9fa;
                display: flex;
                align-content: center;

                .scheduledServiceRate {
                    display: flex;
                    align-self: center;
                    padding-left: 5px;
                    color: #006dff;
                    font-size: 14px;
                    font-weight: 800;
                }
            }

            .scheduledServiceReadReviews {
                text-decoration: underline;
                color: #006dff;
                font-size: 14px;
                display: flex;
                align-self: center;
                padding-left: 4px;
                cursor: pointer;
            }
        }

        .scheduledServiceContactInfoWrapper {
            padding-top: 22px;
            .scheduledServiceContactInfo {
                color: #6b6c6f;
                font-size: 12px;
                font-weight: 800;
                padding-bottom: 4px;

                @media (max-width: 600px) {
                    display: none;
                }
            }
            .scheduledServiceContactInfoPhoneWrapper {
                display: flex;
                padding-bottom: 7px;
                .scheduledServiceContactInfoPhone {
                    color: #333333;
                    font-size: 14px;
                    padding-left: 7px;

                    @media (max-width: 768px) {
                        font-size: 12px;
                    }
                    @media (max-width: 600px) {
                        font-size: 14px;
                    }
                }
            }
            @media (max-width: 600px) {
               margin-left: -22px;
            //    margin-left: -22%;
            }

            @media (max-width: 325px) {
               margin-left: -64px;
            //    margin-left: -22%;
            }
        }

        @media (max-width: 768px) {
            padding-left: 10px;
            width: 30%
        }

        @media (max-width: 600px) {
            width: 80%;
            padding-left: 20px;
        }
        @media (max-width: 325px) {
            width: 75%;
        }
    }

    .scheduledServiceThirdRow {
        @media (max-width: 768px) {
            width: 30%;
        }
        @media (max-width: 600px) {
            width: 100%;
            padding: 30px 0;
        }
    }

    .scheduledServiceFourthRow {

        @media (max-width: 768px) {
            width: 30%;
        }
        @media (max-width: 600px) {
            width: 100%;
        }
    }

    .scheduledServiceDtTime {
        color: #6b6c6f;
        font-size: 12px;
        font-weight: 500;
    }
    .scheduledServiceDtTimeVal {
        color: #333333;
        font-size: 14px;
        padding-top: 4px;
        line-height: 22px;

        @media (max-width: 768px) {
            font-size: 12px;
        }
        @media (max-width: 600px) {
            font-size: 14px;
        }
    }
    .scheduledServiceAddress {
        padding-top: 44px;

        @media (max-width: 600px) {
            padding-top: 25px;
        }
    }
    .scheduledServiceStatusWrapper {
        display: flex;
        width: 150%;
        justify-content: space-between;
        padding-left: 42px;

        @media (max-width: 1366px) {
            width: 130%;
        }
        @media (max-width: 1024px) {
            width: 120%;
            padding-left: 12px;
        }
        @media (max-width: 768px) {
            width: 100%;
            padding-left: 2px;
        }
        @media (max-width: 600px) {
            padding-left: 0px;
        }
        .scheduledServiceRequested,
        .scheduledServiceVendorContacted {
            font-size: 14px;
            font-weight: 800;
            color: #333333;
            padding-bottom: 6px;

            @media (max-width: 768px) {
                font-size: 12px;
            }

        }
        // .scheduledServiceVendorContacted {
        //     font-weight: unset;
            
        // }
        .scheduledServiceToday {
            color: #6b6c6f;
            font-size: 12px;
            text-align: center;
            padding-top: 6px;
        }
        .scheduledServiceStatusContainer {
            display: flex;
            justify-content: center;
            .scheduledServiceStatus {
                border: 1px solid #c7ab5f;
                color: #937b39;
                background-color: #fff0c8;
                border-radius: 13px;
                font-size: 12px;
                font-weight: 500;
                padding: 4px 8px;
                text-align: center;
                width: 59px;
            }
        }
    }
    .shareIconMobile {
        display: none;

        @media (max-width: 600px) {
            display: block;
        }
    }
    .scheduledServiceBtn {
        // width: 10%;
        position: absolute;
        right: 22px;
        top: 84px;

        @media (max-width: 1024px) {
            top: 130px;
        }
        @media (max-width: 600px) {
            position: relative;
            top: auto;
            right: auto;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 30px 30px 10px;
        }
    }

    @media (max-width: 768px) {
        padding: 15px;
    }
}
