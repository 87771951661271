.UpcomingTaskFirstCardMain {
    display: flex;
    justify-content: space-between;
    border: 1px solid #d92f25;
    border-radius: 4px;
    background: #d92f2508;
    padding: 20px 10px 0 20px;

    .UpcomingTaskFirstCardLeftDiv {
        .UpcomingTaskFirstTitle {
            font-size: 22px;
            line-height: 20px;
            color: #333333;
            font-weight: 600;
            margin-bottom: 15px;

            @media (max-width: 1024px) {
                font-size: 16px;
            }
            @media (max-width: 375px) {
                margin-bottom: 10px;
            }
        }
        .UpcomingTaskFirstDescription {
            font-size: 14px;
            line-height: 24px;
            color: #333333;
            margin-bottom: 15px;

            @media (max-width: 1024px) {
                font-size: 12px;
                line-height: 16px;
                margin-bottom: 0px;
            }
        }

        .UpcomingTaskFirstButtonDiv {
            margin-bottom: 20px;

            button {
                border-radius: 4px !important;
            }

            @media (max-width: 1024px) {
                margin-bottom: 10px;
                .buttonUpcomingTaskFirst {
                    height: 40px !important;
                    font-size: 12px !important;
                    width: 130px !important;
                }
            }
        }

        .progress {
            margin-bottom: 20px;

            @media (max-width: 1024px) {
                margin-bottom: 0px;

                .progressDiv {
                    margin-bottom: 10px;
                }
            }
        }
        .checkDetailsMainDiv {
            margin-bottom: 24px;
            margin-top: 20px;

            .checkDetailsDiv {
                display: flex;
                align-items: center;
                margin: 10px 0;

                .checkIcon {
                    color: #dadce0;
                    margin-right: 10px;
                }
                .checkIconCompleted {
                    color: #00d56d;
                    margin-right: 10px;
                }

                @media (max-width: 1024px) {
                    margin: 5px 0;

                    .checkTitleDiv {
                        font-size: 12px;
                    }
                    .checkIcon,
                    .checkIconCompleted {
                        font-size: 18px;
                    }
                }
                .checkTitleDiv {
                    font-weight: 600;
                }
            }

        }
    }
    .imageUpcomingTaskFirstDiv {
        align-items: flex-end;
        display: flex;

        @media (max-width: 1024px) {
            width: 30%;
            img {
                width: 100%;
            }
        }
    }
    @media (max-width: 1024px) {
        padding: 15px 10px 0 13px;
    }
}
