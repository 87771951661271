.ElectricityGasMainSkeletonCard {
    border: 1px solid #e2e2e2;

    .ElectricityGasMainSkeletonCard1 {
        span {
            width: 100%;
        }
        // border-bottom: 1px solid #00000029;
        display: flex;
        justify-content: space-between;
        padding: 10px 10px 5px;
        .div0 {
            display: flex;
            width: 60%;
            .div1 {
                width: 50%;
                margin-right: 20px;
            }
        }
    }
    .div2 {
        width: 96%;
        padding: 5px 10px;
    }

    .ElectricityGasMainSkeletonCard2 {
        .div3 {
            width: 96%;
            padding: 10px;
            display: flex;
            justify-content: space-between;

            .div4 {
                width: 57%;
            }
            .div5 {
                width: 40%;
            }
        }
    }
}
