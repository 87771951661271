.completedCard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #DADCE0;
  padding: 20px 30px;
  margin: 10px 0;
  width: 98%;
  background: white;

  @media (max-width: 425px) {
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.completedImageDiv {
  @media (max-width: 425px) {
    margin-right: 15px;
  }
}

.ContentButtonDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;

  @media (max-width: 425px) {
    flex-wrap: wrap;
  }
}

.completedCardTitle {
  font-size: 22px;
  line-height: 30px;
  color: #333333;
  font-family: "Roboto";
  font-weight: bold;

  @media (max-width: 425px) {
    font-size: 16px;
  }
}

.completedCardPara {
  font-size: 16px;
  line-height: 22px;
  color: #989898;
  font-weight: bold;
  font-family: "Roboto";

  @media (max-width: 425px) {
    font-size: 14px;
    margin-top: 5px;
  }
}

.tasksDiv{
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.taskTitle {
  font-size: 16px;
  line-height: 22px;
  font-family: "Roboto";
  margin-left: 10px;
}

.cancelButtonDiv {
  height: 100%;
  display: flex;
  align-items: flex-end;

  @media (max-width: 425px) {
    width: 100%;
    height: auto;
    justify-content: flex-end;
  }
}

.cancelButton {
  width: 83px !important;
  height: 38px !important;
  color: #D92F25 !important;
  font-size: 14px !important;
  line-height: 22px !important;
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}