@font-face {
    font-family: 'EuclidCircularA-Bold';
    src: local('EuclidCircularA-Bold'), url('./Assets/font/Euclid/EuclidCircularA-Bold.otf') format('truetype');
}

@font-face {
    font-family: 'EuclidCircularA-BoldItalic';
    src: local('EuclidCircularA-BoldItalic'),
        url('./Assets/font/Euclid/EuclidCircularA-BoldItalic.otf') format('truetype');
}

@font-face {
    font-family: 'EuclidCircularA-Light';
    src: local('EuclidCircularA-Light'), url('./Assets/font/Euclid/EuclidCircularA-Light.otf') format('truetype');
}

@font-face {
    font-family: 'EuclidCircularA-LightItalic';
    src: local('EuclidCircularA-LightItalic'),
        url('./Assets/font/Euclid/EuclidCircularA-LightItalic.otf') format('truetype');
}

@font-face {
    font-family: 'EuclidCircularA-Medium';
    src: local('EuclidCircularA-Medium'), url('./Assets/font/Euclid/EuclidCircularA-Medium.otf') format('truetype');
}

@font-face {
    font-family: 'EuclidCircularA-MediumItalic';
    src: local('EuclidCircularA-MediumItalic'),
        url('./Assets/font/Euclid/EuclidCircularA-MediumItalic.otf') format('truetype');
}

@font-face {
    font-family: 'EuclidCircularA-Regular';
    src: local('EuclidCircularA-Regular'), url('./Assets/font/Euclid/EuclidCircularA-Regular.otf') format('truetype');
}

@font-face {
    font-family: 'EuclidCircularA-RegularItalic';
    src: local('EuclidCircularA-RegularItalic'),
        url('./Assets/font/Euclid/EuclidCircularA-RegularItalic.otf') format('truetype');
}

@font-face {
    font-family: 'EuclidCircularA-Semibold';
    src: local('EuclidCircularA-Semibold'), url('./Assets/font/Euclid/EuclidCircularA-Semibold.otf') format('truetype');
}

@font-face {
    font-family: 'EuclidCircularA-SemiboldItalic';
    src: local('EuclidCircularA-SemiboldItalic'),
        url('./Assets/font/Euclid/EuclidCircularA-SemiboldItalic.otf') format('truetype');
}

@font-face {
    font-family: 'Helvetica';
    src: local('Helvetica'), url('./Assets/font/Helvetica/Helvetica.ttf') format('truetype');
}

@font-face {
    font-family: 'Helvetica-Bold';
    src: local('Helvetica-Bold'), url('./Assets/font/Helvetica/Helvetica-Bold.ttf') format('truetype');
}

html {
    scroll-behavior: smooth;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}