.trustedPros-main-page {
    .trustedProsStepper .MuiRadio-colorSecondary.Mui-checked {
        color: #006dff !important;
    }

    .trustedPros-title-div {
        padding: 0 80px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .heading {
            @media (max-width: 600px) {
                font-size: 22px;
                line-height: 32px;
            }
        }

        .trustedProsAddressDiv {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;

            .addressText {
                font-size: 14px;
                line-height: 24px;
                font-weight: bold;
            }

            .MuiFilledInput-root {
                background-color: transparent;
                border-bottom: 1px solid #006dff !important;
                color: #006dff !important;
            }
            .trustedProsStepsAutoComplete {
                .MuiFilledInput-input {
                    padding: 0;
                    width: 300px;
                    // color: #009cbd !important;

                    @media (max-width: 600px) {
                        width: 250px;
                    }
                    @media (max-width: 375px) {
                        width: 230px;
                    }
                    @media (max-width: 325px) {
                        width: 180px;
                    }
                }
            }

            @media (max-width: 600px) {
                display: none;
            }
        }
        .trustedProsAddressDivDoug {
            .MuiFilledInput-root {
                background-color: transparent;
                border-bottom: 1px solid #009cbd !important;
                color: #009cbd !important;
            }
        }

        @media (max-width: 1366px) {
            padding: 0 60px;
        }

        @media (max-width: 600px) {
            padding: 0 10px;
        }
    }

    .trustedProsStepper {
        padding: 0 300px;

        .serviceTitle {
            font-size: 22px;
            line-height: 30px;
            font-weight: bold;
            margin-top: 10px;

            @media (max-width: 600px) {
                margin-top: 0;
                margin-left: 15px;
            }
        }

        .TrustedProsBackIconDiv {
            @media (max-width: 600px) {
                display: flex;
                align-items: center;
            }
        }

        .MuiStepper-root {
            display: none;
        }

        .MuiFormControl-root {
            width: 100%;

            @media (max-width: 600px) {
                margin-top: 15px;
            }
        }

        .radioInputs {
            margin: 10px 0;
            padding: 5px 10px;
            border-radius: 4px;
        }

        .otherInput {
            border-bottom: none;
            border: 1px solid #d8dce6;
            border-radius: 4px;
            font-size: 14px;
            line-height: 22px;
            color: #aaaaaa;
            // height: 100px;

            .MuiOutlinedInput-notchedOutline {
                border: none;
            }
        }

        // .MuiFormControlLabel-root {
        //   margin: 10px 0;
        // }

        // .MuiSvgIcon-root {
        //   // fill: #006DFF;
        // }
        .MuiRadio-colorSecondary.Mui-checked {
            color: #009cbd;
        }

        .MuiFormLabel-root {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 16px;
            line-height: 22px;
            color: #333333;
            font-family: 'Roboto';

            .para11 {
                font-size: 14px;
                line-height: 24px;
                color: #5f6369;
                font-weight: bold;
            }

            @media (max-width: 600px) {
                font-size: 13px;
            }
        }

        @media (max-width: 1366px) {
            padding: 0 240px;
        }

        @media (max-width: 768px) {
            padding: 0 60px;
        }

        @media (max-width: 600px) {
            padding: 0 10px;
        }
    }

    .btnTrustedPros {
        width: 109px !important;
        margin-top: 10px !important;

        @media (max-width: 600px) {
            width: 100% !important;
        }
    }

    .desktopPara {
        @media (max-width: 600px) {
            display: none;
        }
    }

    .mobilePara {
        display: none;
        font-size: 14px;
        line-height: 24px;
        text-align: center;
        margin-top: 20px;
        margin-bottom: 40px;

        @media (max-width: 600px) {
            display: block;
        }
    }
}
.trustedPros-main-pageDouglas {
    .trustedProsStepper .MuiRadio-colorSecondary.Mui-checked {
        color: #009cbd !important;
    }
}
