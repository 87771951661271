.greybglong{
    background-color: #f8f8f8;
    padding: 30px 0 90px;
    margin-bottom: -90px;
}
.form_section{
    width: 590px;
    margin: 0 auto 30px;
    padding-bottom: 60px;
    max-width: 90%;
}
.form_box {
    background-color: #fff;
    border: 1px solid #E2E2E2;
    border-radius: 4px;
    
    padding: 45px 0;
    .edit_securityform {
        width: 300px;
        margin: 0 auto;
        max-width: 90%;
        &.edit_securityformleft {
            width: calc(100% - 160px);
            // width: calc(100% - 80px);
            .form_fix_width {
                width: 300px;
                max-width: 100%;
                .select-new__control {
                    height: 50px;
                }
            }
        }
    }
    label {
        font-size: 16px !important;
        font-weight: 600;
        color: #333;
        width: 100%;
        display: inline-block;
        margin-bottom: 13px;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
    }
    .form_control {
        border: 1px solid #E0E0E0;
        border-radius: 4px;
        height: 50px;
        width: 100%;
        padding: 5px 15px;
        color: #6B6C6F;
        font-size: 16px;
        display: inline-block;
        box-sizing: border-box;
    }
    .dollar_control{
        background-image: url(../../Assets/images/dollar.png);
        background-position: center left 10px;
        background-size: 11px;
        background-repeat: no-repeat;
        padding-left: 25px !important;
    }
    .form_group {
        margin-bottom: 25px;
    }
    label.smalllabel {
        color: #6B6C6F;
        font-size: 14px;
    }
}

.form_row {
    display: flex;
    justify-content: space-between;
    .col50 {
        width: calc(50% - 7px);
    }
}
.internerserviceoptions {
    display: flex;
    .internetServiceCardOuterDiv {
        width: 128px;
        padding: 10px;
        border-radius: 4px;
        margin-right: 9px;
        .internetServiceCard {
            display: flex;
            align-items: center;
            svg{
                margin-right: 10px;
            }
        }
    }
}

.mb_0{
    margin-bottom: 0 !important;
}
.form_btns{
    margin-top: 20px;
    text-align: right;
    .theme_btn{
        margin-left:15px;
    }
    
}
.theme_btn{
    color: #273E59;
    font-size: 14px;
    font-weight: 600;
    border: 1px solid #273E59;
    box-shadow: 0px 1px 1px #161d251a;
    background-color: #fff;
    border-radius: 4px;
    padding: 15px 22px;
    cursor: pointer;
    transition: 0.4s linear;
    &:hover{
        background-color: #273E59;
        color: #fff;
    }
}
.permonthcontrol {
    position: relative;
    span {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        display: inline-block;
        right: -60px;
        color: #6B6C6F;
    }
}

.modalMainFlexDiv {
    display: flex;
    justify-content: space-between;

    .modalMainLeftDiv {
        width: 35%;
        background: linear-gradient(#eeeeee, #ffffff);
        height: 100vh;
        max-height: 100vh;
        position: fixed;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .imageDiv {
            img {
                max-width: 100%;
            }
        }

        .leftTitle {
            font-size: 24px;
            line-height: 32px;
            font-weight: bold;
            margin: 20px 0;
            color: #333333;
        }
        .leftDescription {
            font-size: 16px;
            line-height: 22px;
            margin: 0 30px;
            color: #333333;
        }

        @media (max-width: 600px) {
            display: none;
        }
    }
}
.HomeOwnerDashboardModalMainDiv {
    margin-left: 35%;
    width: 70%;
    // text-align: center;
    padding: 30px 70px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 600px) {
        padding: 30px 10px 70px;
    }

    .HomeOwnerDashboardModalTitle {
        font-size: 30px;
        line-height: 32px;
        font-weight: bold;
        color: #333333;
        margin-bottom: 20px;

        @media (max-width: 768px) {
            font-size: 22px;
        }
    }

    .HomeOwnerDashboardformDiv {
        width: 90%;
        background: #ffffff;
        padding: 25px 50px 25px 27px;
        margin: 25px 0 0;

        .formTitleFlexDiv {
            // display: flex;
            display: flex;
            justify-content: space-between;
            margin: 0px 0 20px;

            .titleServiceName {
                font-size: 18px;
                line-height: 28px;
                color: #6b6c6f;
                font-weight: 700;
            }

            // @media (max-width: 600px) {
            //     display: none;
            // }
        }
        .imnotsure {
            font-size: 14px;
            line-height: 18px;
            color: #0066f5;
            cursor: pointer;
        }
        .inputFlexDiv1 {
            display: flex;
            // justify-content: space-between;
            margin: 15px 0px;

            .MuiRadio-colorSecondary.Mui-checked {
                color: #0066f5;
            }

            .inputFlexDiv2 {
                width: 45%;
                margin-right: 30px;
                display: flex;
                align-items: center;

                .css-2b097c-container {
                    width: 100%;
                }
                .react-select .select-state__control {
                    min-height: 50px;
                }

                .inputDiv1 {
                    width: 80%;
                    .MuiFormControl-root {
                        width: 100%;
                    }

                    @media (max-width: 600px) {
                        width: 76%;
                    }
                }

                .inputsupportText {
                    margin-left: 20px;
                    margin-top: 19px;
                    font-size: 16px;
                    line-height: 22px;
                    color: #6b6c6f;
                    font-weight: 700;
                }

                @media (max-width: 600px) {
                    width: 100%;
                    margin: 15px 0;
                }
            }

            .inputDiv2 {
                width: 60%;

                .internetServiceCardOuterDiv {
                    border: 1px solid;
                    padding: 10px 20px;
                    border-radius: 4px;
                    width: 24%;
                    margin-right: 20px;

                    @media (max-width: 768px) {
                        width: 70%;
                    }
                    @media (max-width: 600px) {
                        width: 23%;
                        display: inline-block;
                        margin-right: 10px !important;
                        padding: 10px;
                    }
                    @media (max-width: 400px) {
                        margin-right: 7px !important;
                    }
                    @media (max-width: 350px) {
                        margin-right: 5px !important;
                        width: 30%;
                        margin-bottom: 5px;
                    }
                }

                .internetServiceCard {
                    display: flex;
                    align-items: center;
                    font-size: 16px;
                    line-height: 22px;
                    // color: #333333;

                    .internetIcon {
                        margin-right: 15px;
                    }

                    @media (max-width: 600px) {
                        font-size: 12px;

                        .internetIcon {
                            margin-right: 8px;
                        }
                    }
                }

                @media (max-width: 600px) {
                    width: 100%;
                }
            }
            @media (max-width: 600px) {
                display: block;
                .inputDiv1 {
                    width: 100%;

                    .css-2b097c-container {
                        width: 100%;
                    }
                }
            }
        }

        @media (max-width: 768px) {
            width: 80%;
            padding: 25px 25px 25px 14px;
            margin: 10px 0;
        }
        @media (max-width: 600px) {
            width: 87%;
            padding: 25px 0px 0px 14px;
            margin-bottom: 0;
        }
    }
    .HomeOwnerDashboardModalButtonDiv {
        width: 30%;
        margin-top: 25px;

        .HomeOwnerDashboardModalButton {
            width: 100% !important;
        }

        @media (max-width: 600px) {
            display: none;
        }
    }

    .stepperHeaderMainFlexDiv {
        display: flex;
        align-items: center;
        width: 100%;
        .circleNumber {
            color: #ffffff;
            border-radius: 50%;
            width: 30px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-bottom: 1px;
            margin-right: 20px;
        }
        .steppertitle {
            font-size: 14px;
            line-height: 14px;
            margin-bottom: 5px;
        }
        .steppersubtitle {
            font-size: 12px;
            line-height: 16px;
            color: #747e8b;
        }
        @media (max-width: 600px) {
            width: 100%;
            display: flex;
            align-items: center;
        }
    }

    .skipButton {
        font-size: 14px;
        line-height: 18px;
        color: #0066f5;
        margin-right: 30px;
        margin-left: 20px;
        cursor: pointer;
    }

    .DashboardUtilitiesSubscriptionFormButtonDiv {
        display: none;
        @media (max-width: 600px) {
            display: none;
            // display: flex;
            flex-direction: column;
            align-items: center;
            margin: 20px 0;

            .DashboardUtilitiesSubscriptionFormButton {
                width: 100% !important;
                margin-bottom: 20px !important;
            }
        }
    }

    .DashboardUtilitiesSubscriptionFormButton {
        @media (max-width: 500px) {
            width: 200px !important;
            margin: 10px auto 30px auto !important;
            display: flex;
            justify-content: center;
            align-items: center;
            align-self: center;
        }
    }
    .lineDiv {
        // display: none;
        display: block;
        border: 1px solid #ebebeb;
        width: 100%;
        margin: 10px 0;
        @media (max-width: 600px) {
            display: block;
            border: 1px solid #ebebeb;
            width: 100%;
            margin: 10px 0;
        }
    }

    .checkIconForm {
        fill: #00d56d;
        font-size: 34px;
        margin-right: 15px;
    }

    .error {
        color: red;
        height: 20px;
    }

    @media (max-width: 600px) {
        width: 100%;
        margin-left: 0%;
    }
}

.imnotsure {
    font-size: 14px;
    line-height: 18px;
    color: #0066f5;
    cursor: pointer;
}

.form_fix_widthmonth {
    form.makeStyles-root-11 {
        // width: 300px !important;
        display: inline-block;
        max-width: 100%;
    }
    .form_fix_widthmonthtext {
        // width: calc(100% - 310px);
        display: inline-block;
        vertical-align: bottom;
        padding-left: 10px;
        height: 35px;
    }
}
.otipnval{
    display: flex;
    align-items: center;
    img {
        margin-right: 10px;
    }
} 
hr.form_sepertor {
    background-color: #afc3c9;
    border: none;
    height: 1px;
    margin: 20px 0;
}

.text_right {
    display: inline-block;
    text-align: right;
    width: 100%;
    margin-bottom: 15px;
    .theme_btn.small {
        padding: 10px 25px;
        display: inline-block;
    }
}


@media (max-width: 575px){
    .permonthcontrol span {
        position: relative;
        transform: translate(0);
        right: 0;
        display: inline-block;
        width: 100%;
        text-align: right;
    }
    .form_box {
        padding: 30px 0;
        label {
            font-size: 14px;
            margin-bottom: 7px;
        }
        .form_control{
            height: 45px;
            padding: 5px;
        }
    }
    .form_box .edit_securityform.edit_securityformleft {
        width: calc(100% - 24px);
    }
    
}

@media (max-width: 480px){
    .form_box .edit_securityform.edit_securityformleft {
        width: calc(100% - 30px);
        max-width: 100%;
        .form_fix_width {
            width: 100%;
        }
    } 
    .internerserviceoptions {
        flex-wrap: wrap;
        .internetServiceCardOuterDiv {
            width: calc(50% - 35px);
            margin: 5px 10px 5px 0;
            max-width: 100%;
        }
    }    
    .form_fix_widthmonth {
        position: relative;
        // .MuiFormControl-root .form_fix_width{
            // width: calc(100% - 70px) !important;
        // }
        .form_fix_widthmonthtext {
            width: 60px;
            height: auto;
            position: absolute;
            right: 0;
            bottom: 17px;
        }
        form.makeStyles-root-11 {
            width: 100% !important;
        }
    }
}