.tabHead .MuiTabs-flexContainer {
    justify-content: space-between !important;
}

.tabHead .MuiTab-textColorInherit.Mui-selected {
    color: #1d7e64 !important;
    font-weight: 600;
}
.MuiBox-root-12 {
    padding: 0px !important;
}

.tab-content-details {
    font-size: 11px;
    line-height: 18px;
    color: #333333;
    opacity: 0.8;
}

.tab-content-image img {
    width: 100%;
    margin-top: 20px;
}
.headingCardSkeleton {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.documentDataImages {
    display: flex;
    // margin: 0px auto;
    align-items: center;
    flex-direction: column;
}
.scroll-cardsDocumentry {
    display: flex;
    // width: 100%;
    overflow-x: hidden;
    padding: 30px 0px 30px 0px;
    scroll-behavior: smooth;

    @media (max-width: 1366px) {
        padding: 30px 0px 30px 0px;
    }

    @media (max-width: 600px) {
        padding: 10px 0px 10px 0px;
    }
}

.documentryPros {
    .MuiTabs-root {
        // padding: 0 80px;

        // @media (max-width: 1366px) {
        //     padding: 0 60px;
        // }

        @media (max-width: 600px) {
            padding: 0 10px;
        }
    }

    .MuiTab-textColorInherit.Mui-selected {
        font-weight: bold !important;
        color: #0066f5;
    }
}

@media (max-width: 425px) {
    .tabHead {
        width: 100%;
    }
    .tabHead .MuiTabs-flexContainer {
        justify-content: left !important;
    }
}
.check {
    .MuiTab-wrapper {
        text-transform: none !important;
    }
}
