.main-stand-alone {
    padding: 100px;

    .service-name-div {
        font-size: 20px;
        font-weight: bold;
        padding: 20px;
    }

    .center-div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 40px;
    }

    .thank-you-text {
        padding: 10px 0;
        font-weight: 600;
    }

    .back-to-pros-button {
        padding: 20px 0;
    }

    @media (max-width: 600px) {
        padding: 100px 10px;
    }
}

.arrow-name-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.MuiFormHelperText-root {
    color: red;
}
