.light-popins-14px {
    text-align: left;
    font-size: 14px;
    letter-spacing: 0px;
    color: #6B6C6F;
    opacity: 1;
}

.dollarEstimated1 {
    position: absolute;
    top: -100;
    left: 39%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 120px;

    .text {
        font-size: 12px;
        font-family: 'Roboto';
        font-weight: normal;
        letter-spacing: 0px;
        color: #6B6C6F;
        ;
        opacity: 1;
    }

    .boldest-24px-text1 {
        text-align: left;

        font: normal normal bold 18px/23px Poppins;
        letter-spacing: 0px;
        color: #333333;
        opacity: 1;
        margin: 12px 0px;

        .dollarArrangment1 {
            align-items: left;
            text-align: left;
            position: absolute;
            font: normal normal normal 14px/23px Poppins;
            color: #000;
            display: inline-block;
            margin-left: -12px;
            margin-top: -10px;
        }
    }

}

.Monthlyexpenses {
    display: flex;
    justify-content: space-between;
    width: 73%;
    margin: 6px 0px;
    font: normal normal bold 14px/24px Roboto;
    letter-spacing: 0px;
    color: #333333;
    text-transform: capitalize;
    opacity: 1;

}

.chartResponsive {
    height: 30%;
}

.inputBoxParent {

    width: 100%;
    margin-top: 20px;


    .inputBoxData {
        display: block;
        text-align: left;
        padding: auto;
        margin: 14px auto;

        .inputBox {

            border: 1px solid #E0E0E0;
            text-align: left;
            padding-left: 20px;
            width: 80px;
            height: 30px;
        }

        .inputBox1 {

            text-align: left;
            padding-left: 20px;
            width: 80px;
            height: 30px;
        }
    }

}

.mainQuestion {
    background-color: #F0F4FC;
    width: 95%;
    margin: 20px auto;
    padding: 20px 0px;
    position: relative;

    .heading {
        display: block;
        text-align: left;
        margin: 20px 15px;


        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0px;
        color: #333333;
        opacity: 1;
    }

    .logoIconCover {
        position: absolute;
        right: 15px;

        img {
            width: 124px;
        }
    }
}

.MikeContainer {
    margin: auto 10px;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 1000px) {
        margin: 0px 20px 10px;
    }

    .ImagecontainerMike {
        flex: 0.2;

    }

    .containerMikeDetails {
        flex: 0.8;
        //  margin:10px auto 20px;
        margin: 15px 10px;

        @media screen and (max-width: 1400px) {
            margin: 20px 20px;
        }

        .email {
            padding-left: 12px;
            @media screen and (max-width: 1400px) {
                padding-left: 0px;
            }
        }
    }

    .MikeText {
        text-align: left;
        font-size: 16px;

        font-weight: bold;
        font-family: 'Roboto';
        letter-spacing: 0px;
        color: #333333;
        opacity: 1;
    }

    .phoneEmail {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        width: 90%;

        span {
            font-weight: normal !important;
        }

        .infoRow {
            span {
                svg {
                    max-width: 14px;
                }
                &:last-child {
                    padding: 0;
                    padding-left: 7px;
                }
            }
            .brokerEmail {
                max-width: 240px;
                overflow-x: scroll;
                &::-webkit-scrollbar {
                    display: none; /* Safari and Chrome */
                }
               
                @media(max-width: 480px) {
                    max-width: 75%;
                    word-wrap: break-word
                }
                @media(max-width: 340px) {
                    max-width: 50%;
                }
            }
        }

        @media screen and (max-width: 1400px) {
            flex-direction: column;
        }


    }
}

.cardWithoutHeadShotWithLogo {
    .logoIconCover {
        position: relative;
        right: 0;
        margin-left: 20px;
        max-width: 124px;
        display: block;
        img {
            width: 100%;
        }
    }
    .MikeContainer {
        margin-top: 0px !important;
        margin: 0;
        margin-left: 10px;
    }

    .heading {
        margin: 5px 20px 15px;
    }
    .phoneEmail {

        @media(max-width: 420px)
        {
            width: 100%;
        }
    }
}
.cardWithoutHeadShotWithoutLogo {
    .phoneEmail {
        width: fit-content;
    }
    .MikeContainer {
        margin-top: 0px !important;
        margin-left: 10px;
    }

}

.details {
    text-align: left;
    font-size: 14px;
    letter-spacing: 0px;
    color: #6B6C6F;
    opacity: 1;
    padding-left: 26px;



}

.AffChartData {
    width: 90%;
    margin: 20px auto;

    @media(max-width: 480px) {
        width: 100%;
    }

    .AffChartDataContainer {

        .inputFeildMain1 {
            min-width: 100px;
            @media(max-width: 360px) {
                min-width: 80px;
            }
        }

        tr {
            td {
                padding: 10px;
            }

            .oldExpenseInputBox {
                border: none !important;
            }
        }

        @media(max-width: 480px) {
            tr {
                .inputFeildMain1 {
                    width: 80px !important;
                    height: 30px !important;
                    display: flex;
                    align-items: center;

                    input {
                        height: 24px;
                    }
                }
            }
        }

    }
    .rentAmount {
        color:#D92F25;
    }

    //     .AffChartDataContainer{

    // .AffChartDataContainer td, .AffChartDataContainer th {
    //   border: 1px solid #ddd;
    //   padding: 18px;
    // }

    // .tableConatinerMain{


    //   margin: 28px auto;
    // }
    .tableConatinerMain {
        border-bottom: 1px solid #6b6c6f0a;

        .inputFeildMain1 {
            input {
                font-size: 16px;
                font-weight: normal;
                font-family: Roboto;
                color: #6B6C6F;
            }

            display: flex;
        }

        @media(max-width: 480px) {
            border-bottom: none;
        }
    }

    .tableBot {
        text-align: center;
        width: fit-content;
        display: flex;
        align-items: center;
        justify-content: center;

        padding: 20px auto;

        @media(max-width: 480px) {
            padding-right: 0px !important;
        }

        @media(max-width: 340px) {
            padding-left: 5px !important;
        }
    }

    .tableNameHeading {
        padding: 20px auto;
        text-align: left;
        font: normal normal normal 14px/24px Roboto;
        letter-spacing: 0px;
        color: #6B6C6F;
        opacity: 1;

        @media(max-width: 340px) {
            padding-right: 0px !important;
        }
    }

    .inputBoxData {
        display: block;
        text-align: center;
        padding: auto;

        //  margin:14px auto;
        @media(max-width: 340px) {
            padding-right: 0px !important;
        }

        .inputBox {
            border: 1px solid #E0E0E0;
            text-align: left;
            padding-left: 20px;
            width: 80px;
            height: 30px;
        }

    }


    .inputFeildMain1 {
        position: relative;

        border: 1px solid #ced4da;
        opacity: 1;
        border-radius: 4px;
        width: 80px;
        margin: 0px auto;



        padding: 4px 10px 4px 10px;

        @media screen and (max-width: 768px) {
            width: 100%;

        }



        .per {
            position: absolute;
            left: 4px;
            top: 3;
            font: bold 16px/24px Roboto;
            color: #6B6C6F;
        }



        .inputFeild1 {

            width: 93%;
            margin: auto;
            font: bold 12px/24px Roboto;
            border: none;
            margin-left: 8px;

            color: #333333;

        }

        .inputFeild1:focus {
            outline: none;
        }

    }
}

.dropDownIconCover {
    @media(max-width: 480px) {
        padding-left: 0px !important;
    }
}
.affTitle {
    font-family: Roboto;
    font-size: 16px;
    color: #6B6C6F;
    font-weight: 600;
    margin: 20px;
}
.doughnutChartCover {
    margin-top: 20px;

    canvas {
        height: 244px !important;
    }
}