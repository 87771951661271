.Para {
    color: #bebebe;
}

.path {
    display: flex;
    // margin-top: -25px;
}

.utilitymain-head {
    .heading {
        font-size: 24px;
        font-weight: 600;
        font-family: sans-serif;
    }
    margin: 30px 0px 0px 80px;

    @media (max-width: 1366px) {
        margin: 30px 0px 0px 60px;
    }

    @media (max-width: 768px) {
        margin: 30px 60px 0px 60px;
    }

    @media (max-width: 475px) {
        margin: 30px 0px 0px 10px;
    }
}

.utilityMainheading {
    font-size: 20px;
    font-weight: 600;
    font-family: sans-serif;
}

.utilityBlackCard {
    display: flex;
    margin-top: 10px;
    // background: #002e62;
    color: #fff;
    // padding: 30px;
    // height: 164px;

    @media (max-width: 1536px) {
        margin-right: 10px;
    }

    @media (max-width: 768px) {
        // height: 124px;
        margin-right: 0px;
    }

    @media (max-width: 425px) {
        flex-direction: column;
    }
}

.utilityhead {
    font-size: 24px;
    padding: 10px 0px 0px 0px;
    font-weight: bold;
    text-align: left;

    @media (max-width: 475px) {
        font-size: 18px;
        padding: 10px 0px 10px 0px;
    }
}

.utilitypara111 {
    margin-top: 10px;
    font-size: 16px;
    margin-bottom: 10px;

    @media (max-width: 768px) {
        font-size: 10px;
        margin-top: 5px;
        margin-bottom: 5px;
    }
}
.utilityMainElect {
    margin-bottom: 20px;
    // border-radius: 4px;
}
.utilitymainCard {
    // width: 470px;
    padding: 15px 0px 0px 22px;
    display: flex;
    flex-direction: column;

    @media (max-width: 1280px) {
        padding: 0px 0px 0px 15px;
    }
}

.disbledAutocomplete input:disabled {
    background-color: #fff;
}

.utilityimage1 {
    margin-left: auto;
    width: 565px;
    display: flex;
    align-items: flex-end;
    padding-bottom: 4px;
    // height: 164px;

    @media (max-width: 1024px) {
        width: 728px;
        margin-top: 23px;
        // overflow: hidden;
    }

    @media (max-width: 768px) {
        width: 396px;
        margin-top: 30px;
    }

    @media (max-width: 425px) {
        width: 191px;
        height: 70px;
        margin-top: -30px;
        // overflow: auto;
    }
    @media (max-width: 375px) {
        margin-top: -43px;
        // overflow: auto;
    }
    // padding-right: 100px;
}

.main-tabs {
    display: flex;
    border-bottom: 1px solid #d2d2d2;
    margin-top: 20px;
    padding-bottom: 15px;
}

.inner-tabs {
    margin-right: 10px;
}

.utility {
    padding: 0px 80px 0px 0px;

    @media (max-width: 768px) {
        padding: 0px 10px 0px 0px;
    }
}
.UtilityMainRemoveDouglas {
    margin-top: 0 !important;
    @media (max-width: 600px) {
        margin: 200px 0px 0px 0px !important;
    }
    @media (max-width: 320px) {
        margin: 220px 0px 0px 0px !important;
    }
}

.utilitytab-div {
    display: flex;
    flex-direction: row;
    padding-top: 10px;
    margin-top: 20px;
    border-bottom: 1px solid #d2d2d2;
}
.utilitytab-active {
    color: #006dff !important;
    border-bottom: 3px solid #006dff !important;
    cursor: pointer;
    font-size: 14px;
    padding-bottom: 10px;
    font-weight: bold;
}
.changeColorUtility {
    color: #009cbd !important;
    border-bottom: 3px solid #009cbd !important;
    cursor: pointer;
    font-size: 14px;
    padding-bottom: 10px;
    font-weight: bold;
}
.utilitytab-text {
    cursor: pointer;
    font-size: 14px;
}

.utilitytab-div > div {
    margin-right: 20px;
}

.utilitycontentDiv {
    margin-top: 20px;
}

.utilityheading {
    font-size: 24px;
    font-weight: bolder;
    margin: 30px 0px 15px 0px;
}

.utilityheadingCongo {
    font-size: 24px;
    font-weight: bolder;
}

.utilitysubheading {
    font-size: 16px;
    margin-bottom: 20px;
}

.utilitylocationIcon {
    color: #006dff !important;
    text-decoration: underline;
    margin-left: 0px !important;
    margin-right: 0px !important;
    // margin-top: 5px;
    height: 20px !important;
}
.changeColorUtilityLocationIcon {
    color: #009cbd !important;
}

.utility .editAddressAutoComplete {
    border: 0;
    border-bottom: 1px solid #006dff;
    width: fit-content;
    width: 350px;
    font-size: 14px;
    color: #006dff !important;

    @media (max-width: 375px) {
        width: 320px;
    }
    @media (max-width: 325px) {
        width: 320px;
    }
    @media (max-width: 320px) {
        width: 270px;
    }
}
.UtilityMainRemoveDouglas .editAddressAutoComplete {
    color: #009cbd !important;
    border-bottom: 1px solid #009cbd !important;
    opacity: 1 !important;
}

.utility .editAddressAutoComplete:focus {
    outline: none;
}

.utilityinnerContent {
    display: flex;
    justify-content: space-between;

    @media (max-width: 768px) {
        flex-direction: column;
    }
}

.iconInfo {
    display: flex;
    margin-bottom: 100px;

    @media (max-width: 768px) {
        margin-top: 15px;
    }
}
.iconInfo > svg {
    width: 20px;
    height: 20px;
    margin-left: 5px;
    margin-right: 5px;
}

.utilityCards {
    width: 100%;
    margin-top: 20px;
    display: flex;
    // justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 0px;

    @media (max-width: 425px) {
        flex-direction: column;
    }
}

.utilityfirstDiv {
    display: flex;
    .utilitynewAddressText {
        font-size: 14px;
        font-weight: bold;
        @media (max-width: 425px) {
            font-size: 12px;
        }
    }
    @media (max-width: 425px) {
        padding-top: 10px;
        align-items: flex-end;
    }
}

.utilitysecondDiv {
    display: flex;

    @media (max-width: 425px) {
        margin-top: 10px;
    }
}
.utility {
    @media (max-width: 1024px) {
        .editAddressAutoComplete {
            width: 100% !important;
        }
    }
}

.scheduleCallutility {
    position: absolute;
    width: 70%;
    height: 90%;
    background-color: #ffffff;
    left: 15%;
    top: 5%;
    .scheduleCallBack {
        display: none;
    }
    // @media (max-width: 1440px) {
    //     left: 25%;
    // }
    // @media (max-width: 1024px) {
    //     left: 15%;
    // }
    // @media (max-width: 960px) {
    //     left: 5%;
    // }
    @media (max-width: 600px) {
        left: 0;
        top: 0;
        z-index: 2000;
        width: 100%;
        height: 100%;
        .scheduleCallBack {
            display: block;
            padding: 30px 0 0 30px;
        }
    }
}

.utilityFooter .footerDashboard {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border: #e2e2e2 1px solid;
    padding: 5px;
    margin-top: 50px;
    z-index: 999;
    .footerNameDetails {
        .footerNameDetails1 {
            color: #333333;
            font-size: 16px;
            font-weight: 700;
        }
        .footerNameDetails2 {
            color: #747e8b;
            font-size: 12px;
        }
    }
    .footerNamequestion {
        display: none;
    }
    .footerQuestion {
        color: #333333;
        font-size: 18px;
        font-weight: 500;
        margin: 0 25px;
    }
    .footerCallDetails {
        display: flex;
    }

    @media (max-width: 1024px) {
        .footerQuestion {
            font-size: 16px;
            margin: 0 10px;
        }
    }
    @media (max-width: 960px) {
        .footerNameDetails {
            margin-right: 10px;
        }
        .footerQuestion {
            display: none;
        }
    }
    @media (max-width: 768px) {
        position: fixed;
        margin-top: 10px;
        .footerNamequestion {
            display: block;
            margin-right: 20px;
            .footerNamequestion1 {
                color: #333333;
                font-size: 16px;
                font-weight: 700;
            }
            .footerNamequestion2 {
                color: #747e8b;
                font-size: 12px;
            }
        }
        .footerNameDetails {
            display: none;
        }
        .footerCallDetails {
            display: none;
        }
    }
}
.scheduleCall {
    position: absolute;
    width: 700px;
    height: 650px;
    background-color: #ffffff;
    left: 30%;
    top: 10%;
    .scheduleCallBack {
        display: none;
    }
    @media (max-width: 1440px) {
        left: 25%;
    }
    @media (max-width: 1024px) {
        left: 15%;
    }
    @media (max-width: 960px) {
        left: 5%;
    }
    @media (max-width: 600px) {
        left: 0;
        top: 0;
        z-index: 2000;
        width: 100%;
        height: 100%;
        .scheduleCallBack {
            display: block;
            padding: 30px 0 0 30px;
        }
    }
}

.utilityHeadMainTop {
    display: flex;
    justify-content: space-between;
    padding: 30px 80px 30px 0;
    align-items: center;
    @media (max-width: 600px) {
        flex-wrap: wrap;
    }
    @media (max-width: 425px) {
        flex-direction: column;
    }
}

.utilityMainCardsFlow {
    display: flex;
    justify-content: space-between;
    @media (max-width: 768px) {
        flex-direction: column;
    }
}
.utilitesSteps {
    display: flex;
    padding-top: 60px;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    overflow-y: scroll;
    &::-webkit-scrollbar-track {
        display: none;
    }
    &::-webkit-scrollbar {
        display: none;
    }
    &::-webkit-scrollbar-thumb {
        display: none;
    }
    @media (max-width: 475px) {
        padding-top: 20px;
    }
    .MuiFormControl-root {
        width: 100% !important;
    }

    .utilityInput {
        width: 50%;
    }
    .MuiFormGroup-root {
        flex-direction: row;
        justify-content: space-between;
    }
    .selectDiv {
        width: 50%;
        margin-top: 30px;

        .css-2b097c-container {
            width: 100%;
        }
        .react-select .select-state__control {
            min-height: 55px;
        }
        @media (max-width: 500px) {
            width: 75% !important;
        }
    }
    .radioInputsUtility {
        // margin: 10px 0;
        width: 47%;
        padding: 10px 0px 10px 9px;
        border-radius: 4px;
        margin-top: 22px;
        .MuiTypography-body1 {
            @media (max-width: 600px) {
                font-size: 14px !important;
            }
        }
        @media (max-width: 600px) {
            margin-top: 15px;
            width: 75%;
            padding: 0px 0px 0px 9px;
        }
    }
    .MuiRadio-colorSecondary.Mui-checked {
        color: #273e59 !important;
    }
    .buttonUtilitySteps {
        display: flex;
        padding-top: 40px;
        justify-content: center;
        @media (max-width: 475px) {
            display: flex;
            flex-direction: column;
            width: 100%;
            padding-top: 25px;
        }
    }
}

.btnUtilitySecondSteps {
    width: 115px !important;
    // margin: 0 7px !important;

    @media (max-width: 475px) {
        width: 100% !important;
        margin-bottom: 10px !important;
    }
}

.radioInputsUtility {
    display: flex;
    align-items: center;
    margin: 10px 0;

    .greenCircleIcon {
        color: #00d56d !important;
    }
    .greyCircleIcon {
        color: #bebebe !important;
    }
}
