.warranty-main-page {
    padding: 24px 80px;
    @media (max-width: 1366px) {
        // padding:0px 25px
        padding: 10px 60px;
    }
    @media (max-width: 768px) {
        // padding:0px 25px
        padding: 10px 45px;
    }

    @media (max-width: 600px) {
        // padding:0px 25px
        padding: 0px 10px;
    }

    .homewarrantymainpagediv {
        padding-top: 20px;

        .questionDiv {
            margin-bottom: 20px;

            .question {
                font-size: 16px;
                line-height: 32px;
                color: #333333;
            }
        }

        .cardsDiv {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }

        .optionalCoverageHead {
            font-size: 20px;
            line-height: 32px;
            color: #333333;
            margin-bottom: 30px;
            margin-top: 20px;
        }

        .optionalCoverageDiv {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 5px 20px 5px 10px;
        }

        .checkboxNameDiv {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;

            .title,
            .rate {
                font-size: 16px;
                color: #333333;
                font-family: 'Roboto';
            }
        }

        .buttonDiv {
            display: flex;
            justify-content: center;
            width: 100%;
            margin-top: 40px;
        }
        .detailedPlanData {
            .detailedHead {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                font-size: 36px;
                font-weight: 600;
                font-family: Roboto;
                padding: 50px 0px 30px 0px;
                @media (max-width: 600px) {
                    font-size: 26px;
                    flex-direction: column;
                }
                @media (max-width: 375px) {
                    font-size: 16px;
                }
            }
        }

        .noPlanMainDiv {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-top: 100px;

            .noPlansHeading {
                font-size: 18px;
                line-height: 22px;
                color: #333333;
                font-weight: 600;
                margin: 30px 0 20px;
            }

            .noPlansSubHeading {
                font-size: 16px;
                line-height: 22px;
                color: #6b6c6f;
                @media (max-width: 400px) {
                    font-size: 14px;
                }
                @media (max-width: 320px) {
                    font-size: 12px;
                }
            }
        }
    }
}

.dropBorder {
    .css-1pahdxg-control {
        border-color: #006dff !important;
    }
    .css-1pahdxg-control:hover {
        border-color: #006dff !important;
    }
    .react-select .css-1pahdxg-control:focus {
        border-color: #006dff !important;
    }
}
.dropBorderD {
    .css-1pahdxg-control {
        border-color: #009cbd !important;
    }

    .css-1pahdxg-control:hover {
        border-color: #009cbd !important;
    }
    .react-select .css-1pahdxg-control:focus {
        border-color: #009cbd !important;
    }
}
.viewDetailsModalWaranty {
    width: 75%;
    height: 100%;
    background: #ffffff;
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 5px;
    padding: 50px 20px 50px 60px;
    overflow-y: auto;

    .modalTitleDiv {
        display: flex;

        justify-content: space-between;
        font-family: 'Roboto';

        .modaltitleWaranty {
            font-size: 36px;
            line-height: 32px;
            color: #333333;
            display: flex;
            .backIcon {
                margin-right: 20px;
                font-size: 36px;
            }
            @media (max-width: 1024px) {
                font-size: 30px;
            }
        }

        .closeTextIconDivWaranty {
            color: #e0e0e0;

            display: flex;
            align-items: center;
            font-size: 22px;
            line-height: 32px;

            .closeIcon {
                margin-right: 10px;
            }
        }
    }
    .homeWarantyCampModal {
        padding-top: 100px;
        margin-bottom: 100px;
    }
    .homeWarantyCampModalD {
        .MuiCheckbox-colorSecondary.Mui-checked {
            color: #009cbd !important;
        }
    }
}
.viewCheckListModal {
    width: 75%;
    height: 55%;
    background: #ffffff;

    border-radius: 4px;
    padding: 28px 36px 28px 36px;
    overflow-y: auto;
    @media (max-width: 600px) {
        padding: 16px 6px 28px 6px;
        width: 95%;
    }
    .modalCheckTitleDiv {
        font-weight: 600;
        font-size: 20px;
        font-family: 'Roboto';
        padding-bottom: 30px;
        @media (max-width: 600px) {
            font-size: 12px;
        }
    }
    .checkListGrey {
        background-color: #f4f4f4;
        padding: 0px 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media (max-width: 600px) {
            padding: 0px 10px 0px 0px;
        }
    }
    .checkListHead {
        font-size: 16px;
        font-weight: normal;
        width: 80%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

        @media (max-width: 600px) {
            font-size: 11px;

            .checkListCheckbox {
                width: 10%;
                .PrivateSwitchBase-root-31 {
                    padding: 0px;
                }
                .MuiIconButton-root {
                    padding: 0px;
                }
            }
        }
    }
    .checkListPrice {
        font-size: 16px;
        font-weight: 600;
        @media (max-width: 600px) {
            font-size: 11px;
        }
    }
    .checkListWhite {
        background-color: #fff;
        padding: 9px 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media (max-width: 600px) {
            padding: 0px 10px 0px 0px;
        }
    }
    .checkListButton {
        display: flex;
        justify-content: flex-end;
        padding-top: 15px;
    }
}
.seeDetailedComparison {
    float: right;
    text-decoration: underline;
    font-size: 14px;
    color: #006dff;
    font-family: 'Roboto';
    font-weight: normal;
    cursor: pointer;
}
