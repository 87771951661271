.flex-div {
    display: flex;

    justify-content: space-between;

    .div-1-name,
    .div2-name {
        width: 48%;
    }
}

.hsPage {
    margin: 10px 80px 0px 80px;
    .top-home-security-plans {
        display: flex;
        justify-content: space-between;
        padding: 20px 0;

        @media (max-width: 600px) {
            flex-wrap: wrap;
        }
    }

    .plans-top {
        font-size: 24px;
        font-weight: bold;
    }
    .hs-main-head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .hs-heading {
            font-size: 24px;
            font-weight: 600;
            font-family: sans-serif;
        }
        .hs-path {
            display: flex;
            // margin-top: -25px;
            font-size: 12px;
        }
        .hs-Para {
            color: #bebebe;
        }
        .hs-address {
            display: flex;
            align-items: flex-end;
            justify-content: flex-start;
            .maintext {
                font-size: 14px;
                font-weight: 800;
                margin-top: 5px;
            }
            .locationIcon {
                color: #009cbd;
                width: 22px;
                text-decoration: underline;
                margin-left: 0px !important;
                margin-right: 0px !important;
                margin-top: 0px;
            }
            .editAddressAutoComplete {
                border: 0;
                border-bottom: 1px solid #009cbd;
                width: -moz-fit-content;
                width: fit-content;
                width: 350px;
                font-size: 14px;
                color: #009cbd;
            }
        }
        @media (max-width: 768px) {
            .hs-address {
                .editAddressAutoComplete {
                    width: 300px;
                }
            }
        }
        @media (max-width: 600px) {
            flex-direction: column;
            align-items: flex-start;
            .hs-address {
                flex-direction: column;
                align-items: flex-start;
                .maintext {
                    margin-bottom: 10px;
                }
            }
        }
    }
    .hs-FirstPage {
        text-align: center;
        height: 50vh;
        display: flex;
        align-items: center;
        .hs-landing {
            width: 100%;
            padding: 0 20px 85px;
        }
        .hs-heading {
            font-size: 24px;
            font-weight: 600;
            color: #333333;
        }
        .hs-subtext {
            font-size: 16px;
            color: #333333;
        }
        .hs-line {
            border-top: 0.5px solid #d2d2d2;
            margin: 40px 0;
        }
        .hs-btn-div {
            display: flex;
            justify-content: flex-end;
            .hs-btn-div1 {
                width: 130px;
            }
        }

        @media (min-height: 1000px) {
            height: 70vh;
        }
        @media (max-height: 850px) {
            height: 60vh;
        }
        @media (max-height: 750px) {
            height: 60vh;
        }
        @media (max-height: 680px) {
            height: 60vh;
        }
        @media (max-height: 580px) {
            height: 75vh;
        }
        @media (max-width: 768px) {
            .hs-mainImg {
                width: 100%;
                height: 100%;
            }
        }
        @media (max-width: 600px) {
            .hs-heading {
                font-size: 22px;
            }
            .hs-subtext {
                font-size: 12px;
            }
            .hs-btn-div {
                display: unset;
                .hs-btn-div1 {
                    width: 100%;
                }
            }
        }
    }
    .hs-steps {
        text-align: center;
        margin-top: 30px;
        .headinghs {
            font-size: 24px;
            font-weight: 800;
            font-family: 'Roboto';
            @media (max-width: 600px) {
                font-size: 22px;
            }
        }

        .para1hs {
            margin-top: 10px;
            font-size: 16px;
            @media (max-width: 600px) {
                font-size: 12px;
            }
        }
        .hscheckboxCards {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin-top: 20px;
            margin-bottom: 40px;

            @media (max-height: 700px) {
                margin-bottom: 0px;
            }
        }

        .hsindividualCard {
            margin-right: 15px;
        }
        .hs-stepsbuttons {
            display: flex;
            justify-content: flex-end;
            padding: 0px 30px 0px 30px;

            @media (max-width: 600px) {
                flex-direction: column;
                align-items: center;
                padding: 10px 0px;

                .hsButtonDiv {
                    display: flex;
                }
            }

            // @media(max-width:360px){
            //     padding-bottom: 80px;
            // }
        }
        .hs-Stepper {
            display: none !important;
        }

        .Para11 {
            color: #333333;
            font-size: 12px;
            margin-top: 20px;
            margin-bottom: 20px;

            @media (max-height: 700px) {
                margin-top: 10px;
                margin-bottom: 10px;
            }
        }

        .RadioStep2 {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 40px;
            margin-bottom: 30px;

            .MuiIconButton-root {
                padding: 12px 10px 12px 7px;
            }
            .MuiFormControlLabel-root {
                @media (max-width: 600px) {
                    margin-right: 0px;
                    margin-left: 0px;
                }
            }
            // .MuiTypography-body1 {
            //     color: #6b6c6f;
            // }

            .innerStep2Radio {
                // border-radius: 5px;
                padding: 0px 116px 5px 0px;
                // margin-right: 20px;
                // padding: 0px 95px 5px 0;
                // margin-left: 0;

                @media (max-width: 600px) {
                    padding: 5px 0px 5px 0px;
                }
            }
            .innerStep2Radio1 {
                // border-radius: 3px;
                padding: 0px 116px 5px 0px;
                // padding: 0px 95px 5px 0;
                // margin-left: 0;

                @media (max-width: 600px) {
                    padding: 5px 0px 5px 0px;
                }
            }

            .radioCardDiv {
                border-radius: 5px;
                margin: 0 10px;
                width: 191px;
                height: 170px;

                .label-component {
                    margin: 0 25px 20px;

                    .label-text {
                        font-size: 16px;
                        line-height: 22px;
                        text-align: center;
                    }

                    .label-icon {
                        @media (max-width: 600px) {
                            height: 70%;
                            margin-right: 10px;

                            .checkboxCardImage {
                                height: 100%;
                                width: 100%;
                            }
                        }
                    }

                    @media (max-width: 600px) {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin: 0;
                    }
                }

                @media (max-width: 600px) {
                    margin: 10px;
                    display: flex;
                    width: calc(100vw - 40px);
                    height: 55px;
                }
            }

            @media (max-width: 600px) {
                flex-direction: column;
                margin-bottom: 20px;
                margin-top: 20px;
            }
        }
    }
    .hs-recommendation {
        @media (max-width: 600px) {
            display: flex;
            flex-direction: column-reverse;
        }
        .hs-topDiv {
            .hs-address {
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                .maintext {
                    font-size: 22px;
                    font-weight: 800;
                    margin-top: 5px;
                }
                .locationIcon {
                    color: #009cbd;
                    width: 28px;
                    margin-left: 5px !important;
                    margin-right: 0px !important;
                    margin-top: 0px;
                }
                .editAddressAutoComplete {
                    border: 0;
                    border-bottom: 1px solid #009cbd;
                    width: -moz-fit-content;
                    width: fit-content;
                    width: 400px;
                    font-size: 22px;
                    color: #009cbd;
                }
                @media (max-width: 1024px) {
                    flex-direction: column;
                    .maintext {
                        margin-bottom: 10px;
                    }
                    .locationIcon {
                        margin-left: 0px !important;
                    }
                }
                @media (max-width: 600px) {
                    .maintext {
                        font-size: 14px;
                    }
                    .locationIcon {
                        font-size: 20px;
                    }
                    .editAddressAutoComplete {
                        font-size: 14px;
                        width: 300px;
                    }
                }
            }
        }
        .hs-secondDiv {
            display: flex;
            justify-content: space-between;
            margin: 20px 0;

            @media (max-width: 600px) {
                padding-bottom: 50px;
            }

            .hs-blackCard {
                background-color: #100b28 !important;
                border-radius: 5px;
                width: 50%;
                display: flex;
                align-items: center;
                margin-right: 10px;
                .hs-backCard-content {
                    padding: 20px 10px 20px 20px;
                    .hs-title {
                        font-size: 22px;
                        font-weight: 800;
                        color: #ffffff;
                    }
                    .hs-subTitle {
                        font-size: 14px;
                        color: #ffffff;
                        margin: 10px 0 20px 0;
                    }
                }
            }
            .hs-offerCard {
                width: 50%;
                border: 1px solid #bebebe;
                border-radius: 5px;
                margin-left: 10px;
                display: flex;
                padding: 10px 20px 0 20px;
                .hs-offerTagImg {
                    padding: 20px;
                }
                .hs-offerContent {
                    padding: 20px 0;
                    .hs-offerTitle {
                        font-size: 22px;
                        color: #333333;
                        font-weight: 800;
                    }
                    .hs-offerSubTitle {
                        color: #6b6c6f;
                        font-size: 14px;
                        margin: 15px 0 20px 0;
                    }
                    .hs-viewDetails {
                        // color: #009cbd;
                        font-size: 14px;
                        cursor: pointer;
                        width: fit-content;
                        // border: 1px solid #009cbd;
                        text-align: center;
                        padding: 10px 20px;
                        border-radius: 4px;
                    }
                }
                .hs-offerTagImg1 {
                    padding: 20px 20px 0 0;
                }
            }
            @media (max-width: 768px) {
                flex-direction: column;
                .hs-blackCard {
                    width: 100%;
                    margin-right: 0px;
                    margin-bottom: 20px;
                }
                .hs-offerCard {
                    width: 100%;
                    margin-left: 0px;
                }
            }
            @media (max-width: 600px) {
                .hs-blackCard {
                    flex-direction: column;
                    position: relative;
                    .hs-backCard-content {
                        z-index: 20;
                    }
                    .hs-girl {
                        position: absolute;
                        bottom: 0;
                        right: 0;
                        opacity: 0.5;
                    }
                }
                .hs-offerCard {
                    flex-direction: column;
                    position: relative;
                    .hs-offerContent {
                        padding: 10px 20px 20px 0;
                    }
                    .hs-offerTagImg1 {
                        padding: 0;
                        display: flex;
                        justify-content: center;
                        height: 100px;
                        position: absolute;
                        bottom: 0;
                        right: 0;
                        opacity: 0.7;
                        z-index: -10;
                    }
                }
            }
        }
        .hs-filters {
            text-align: left;
            display: flex;
            margin-top: 20px;
            .filtershs {
                width: 20%;
            }
            .heading1hs {
                display: flex;
                font-size: 22px;
                font-weight: 600;
                margin-bottom: 0;
                margin-left: 10px;
            }
            .link1hs {
                color: #009cbd;
                cursor: pointer;
                margin-right: -20px;
                font-size: 14px;
            }
            .filtersHeadhs {
                display: flex;
                justify-content: space-between;
                align-items: center;
                .heading1hs {
                    display: flex;
                    font-size: 22px;
                    font-weight: 600;
                }
            }
            .dynamicFiltershs {
                box-shadow: 0px 0px 4px #9c9da1;
                padding: 20px 20px 20px 15px;
                border-radius: 3px;
                margin-top: 10px;
                width: 100%;
            }
            .cardsDivhs {
                margin-left: 50px;
                width: 100%;
                .upperheadCardshs {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 10px;
                    .headinghs {
                        display: flex;
                        font-size: 22px;
                        font-weight: 600;
                    }
                    .sortByhs {
                        // margin-top: -10px;
                        display: flex;
                        align-items: center;
                    }
                    @media (max-width: 600px) {
                        flex-direction: column;
                        .sortByhs {
                            margin: 10px 0;
                        }
                    }
                }
                .hsplancarddiv {
                    @media (max-width: 600px) {
                        display: none;
                    }
                }
                .hsplancardmobilediv {
                    display: none;
                    @media (max-width: 600px) {
                        display: unset;
                    }
                }
            }

            @media (max-width: 768px) {
                .filtershs {
                    display: none;
                }
                .cardsDivhs {
                    margin-left: 0px;
                    width: 100% !important;
                }
            }
        }
        .hs-recommendation-long-cards {
            display: flex;
            justify-content: space-between;
            .hs-recommendationcardDiv {
                width: 33%;
                margin: 60px 0 30px 0;
                .hsrecomhs {
                    border: 2px solid #009cbd;
                    border-radius: 3px;
                    margin-top: -50px;
                }
                .hsrecommendationLongCard0 {
                    margin: 0 10px 0 0;
                }
                .hsrecommendationLongCard1 {
                    margin: 0 1px 0 1px;
                }
                .hsrecommendationLongCard2 {
                    margin: 0 0 0 10px;
                }
                .recommendedCardhs {
                    background-color: #009cbd;
                    color: #fff;
                    text-align: center;
                    padding: 10px 10px 15px 10px;
                    font-size: 20px;
                    font-weight: 700;
                    margin-top: -1px;
                }
            }
            @media (max-width: 768px) {
                flex-direction: column;
                .hs-recommendationcardDiv {
                    width: 100%;
                    margin: 10px 0 0 0;
                    .hsrecomhs {
                        margin-top: 0px;
                    }
                    .hsrecommendationLongCard0 {
                        margin: 0px 0px 0 0;
                    }
                    .hsrecommendationLongCard1 {
                        margin: 0px 0px 0px 0px;
                        order: 1;
                    }
                    .hsrecommendationLongCard2 {
                        margin: 0 0 0px 0px;
                        order: 2;
                    }
                }
                .hsr1 {
                    order: 3;
                }
                .hsr2 {
                    order: 1;
                }
                .hsr3 {
                    order: 2;
                }
            }
        }
    }

    .filterButtonrecommendation {
        display: none;

        @media (max-width: 768px) {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            order: 3;
        }
    }

    @media (max-width: 1366px) {
        margin: 10px 60px 0px 60px;
    }
    @media (max-width: 600px) {
        margin: 10px 10px 0px 10px;
    }
}
.summaryhs {
    border: 1px solid #e2e2e2;
    width: 590px;
    margin: 25px 0px 0px 0px;

    .summaryhsHead {
        height: 120px;
        background: #273e59;
        color: #fff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;

        @media (max-width: 600px) {
            padding: 5px;
        }
    }

    .summaryhsCards {
        margin: 20px;
        border: 1px solid #e2e2e2;
        padding: 20px;

        .summaryButtonhsCards {
            display: flex;
            justify-content: space-between;
            .rescheduleHsButton {
                @media (max-width: 600px) {
                    display: none;
                }
            }
            @media (max-width: 600px) {
                flex-direction: column;
            }
        }
        .rescheduleHsButtonMobile {
            display: none;
            @media (max-width: 600px) {
                display: unset;
            }
        }
        .summaryButtonhsCards > div {
            @media (max-width: 600px) {
                margin-bottom: 10px;
            }
        }
    }
    .summaryhsCards1 {
        margin: 20px;
        border: 1px solid #e2e2e2;
        // padding: 20px;
        .titlehsSummary {
            font-size: 22px;
            color: #333;
            padding: 20px;
            font-weight: bold;
            border-bottom: 1px solid #e2e2e2;
        }
        .planDetailsTags1hs {
            color: #333333;
            font-size: 16px;
            margin-bottom: 15px;
            font-weight: bold;
        }

        .boxPlanDetailhs {
            display: flex;
            padding: 20px;

            .boxPlanDetailInsidehs {
                width: 73%;
                display: flex;
                flex-direction: row;
                justify-content: space-around;
                align-items: center;

                .left-details {
                    display: flex;
                    justify-content: space-between;

                    flex-direction: column;
                    height: 100px;
                }

                @media (max-width: 600px) {
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    margin-top: 5px;
                    width: 100%;
                }
            }

            .policyDetailcurrencyhs {
                color: #333333;
                font-size: 24px;
                font-family: Poppins;
                margin: 20px 5px 0px 5px;

                @media (max-width: 600px) {
                    margin: 10px 5px 0px 5px;
                }
            }

            .currencyTaghs {
                color: #333333;
                font-size: 62px;
                font-weight: 800;
                font-family: 'Poppins', sans-serif;

                @media (max-width: 600px) {
                    font-size: 45px;
                }
            }

            .hsCardImage {
                width: 25%;
                margin-right: 50px;

                @media (max-width: 600px) {
                    width: 50%;
                    margin-right: 0;
                    margin: 10px 0;
                }
            }

            @media (max-width: 600px) {
                flex-direction: column;
                align-items: center;
            }
        }
    }

    @media (max-width: 600px) {
        margin: 15px 10px 0px 10px;
        width: 100%;
    }
}
.HSFooter .footerDashboard {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border: #e2e2e2 1px solid;
    padding: 5px;
    margin-top: 50px;
    z-index: 999;
    .footerNameDetails {
        .footerNameDetails1 {
            color: #333333;
            font-size: 16px;
            font-weight: 700;
        }
        .footerNameDetails2 {
            color: #747e8b;
            font-size: 12px;
        }
    }
    .footerNamequestion {
        display: none;
    }
    .footerQuestion {
        color: #333333;
        font-size: 18px;
        font-weight: 500;
        margin: 0 25px;
        span {
            color: #333333 !important;
        }
    }
    .footerCallDetails {
        display: flex;
    }

    @media (max-width: 1024px) {
        .footerQuestion {
            font-size: 16px;
            margin: 0 10px;
        }
    }
    @media (max-width: 960px) {
        .footerNameDetails {
            margin-right: 10px;
        }
        .footerQuestion {
            display: none;
        }
    }
    @media (max-width: 768px) {
        position: fixed;
        margin-top: 10px;
        .footerNamequestion {
            display: block;
            margin-right: 20px;
            .footerNamequestion1 {
                color: #333333;
                font-size: 16px;
                font-weight: 700;
            }
            .footerNamequestion2 {
                color: #747e8b;
                font-size: 12px;
            }
        }
        .footerNameDetails {
            display: none;
        }
        .footerCallDetails {
            display: none;
        }
    }
}
.scheduleCallHS {
    position: absolute;
    width: 70%;
    height: 90%;
    background-color: #ffffff;
    left: 15%;
    top: 5%;
    .scheduleCallBack {
        display: none;
    }

    // @media (max-width: 1440px) {
    //     left: 25%;
    // }
    // @media (max-width: 1024px) {
    //     left: 15%;
    // }
    // @media (max-width: 960px) {
    //     left: 5%;
    // }
    @media (max-width: 600px) {
        left: 0;
        top: 0;
        z-index: 2000;
        width: 100%;
        height: 100%;
        .scheduleCallBack {
            display: block;
            padding: 30px 0 0 30px;
        }
    }
}
.hs-initalModal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    .hs-modalHead {
        color: #333333;
        font-size: 32px;
        font-weight: bolder;
        margin-bottom: 10px;
        @media (max-width: 768px) {
            font-size: 22px;
        }
        @media (max-width: 600px) {
            padding: 10px;
            text-align: center;
        }
    }

    .hs-modal-main-div {
        display: flex;
        justify-content: left;
        flex-direction: column;
        .hsmodalData {
            display: flex;
            align-items: center;
            margin-bottom: 15px;
            justify-content: left;
        }
        .hsmodalData .MuiSvgIcon-root {
            height: 50px !important;
            width: 100px !important;
            animation: myfirst 1s forwards;
            @media (max-width: 768px) {
                height: 40px !important;
                width: 80px !important;
            }
        }
        .hstextModal {
            margin-left: 10px;
            animation: myfirst1 1s forwards;
            @media (max-width: 600px) {
                font-size: 14px;
            }
        }
        @keyframes myfirst {
            0% {
                fill: #bebebe;
            }
            99% {
                fill: #bebebe;
            }
            100% {
                fill: #00d56d;
            }
        }

        @keyframes myfirst1 {
            0% {
                fill: #bebebe;
                font-weight: 200;
            }
            99% {
                fill: #bebebe;
                font-weight: 200;
            }
            100% {
                fill: #333333;
                font-weight: 700;
            }
        }

        .hsmodalData1 {
            display: flex;
            align-items: center;
            margin-bottom: 15px;
            justify-content: left;
        }
        .hsmodalData1 .MuiSvgIcon-root {
            height: 50px !important;
            width: 100px !important;
            animation: myfirst 2s forwards;
            @media (max-width: 768px) {
                height: 40px !important;
                width: 80px !important;
            }
        }

        .hstextModal1 {
            margin-left: 10px;
            animation: myfirst1 2s forwards;
            @media (max-width: 600px) {
                font-size: 14px;
            }
        }

        .hsmodalData2 {
            display: flex;
            align-items: center;
            margin-bottom: 15px;
            justify-content: left;
        }
        .hsmodalData2 .MuiSvgIcon-root {
            height: 50px !important;
            width: 100px !important;
            animation: myfirst 3s forwards;
            @media (max-width: 768px) {
                height: 40px !important;
                width: 80px !important;
            }
        }

        .hstextModal2 {
            margin-left: 10px;
            animation: myfirst1 3s forwards;
            @media (max-width: 600px) {
                font-size: 14px;
            }
        }
        @media (max-width: 600px) {
            padding: 10px;
        }
    }
}

.securityRecommendationMainPageModal {
    position: absolute;
    width: 70%;
    height: 100%;
    background-color: #fff;
    border-radius: 2px;
    padding: 20px;
    left: 30%;
    top: 0;
    overflow: auto;

    .coverageMainDiv {
        padding: 80px 150px 80px 40px;

        .featuresDiv {
            display: flex;
            align-items: center;
            font-size: 14px;
            line-height: 24px;
            margin: 10px 0;

            .checkIcon {
                color: #00d56d;
                margin-right: 10px;
            }
        }

        .policyDetailsDiv {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .monthlyFeeDiv {
                .monthlyText {
                    font-size: 12px;
                    line-height: 24px;
                    margin-top: 20px;
                    margin-left: 17px;
                }

                .feeDiv {
                    font-family: Poppins;
                    display: flex;
                    align-items: flex-start;

                    .dollor {
                        font-size: 24px;
                        line-height: 8px;
                    }
                    .amount {
                        font-size: 48px;
                        line-height: 23px;
                        font-weight: bold;

                        @media (max-width: 600px) {
                            font-size: 30px;
                        }
                    }
                }
            }
        }

        @media (max-width: 1440px) {
            padding: 35px 80px 35px 20px;
        }
        @media (max-width: 1024px) {
            padding: 35px 60px 35px 20px;
        }
        @media (max-width: 600px) {
            padding: 35px 10px 35px 10px;
        }
    }

    @media (max-width: 600px) {
        width: 95%;
        left: 0;
    }
}

.recommendation-cards-all {
    // @media (max-width: 600px) {
    width: 100%;
    margin-bottom: 100px;
    // overflow-x: auto;
    // }

    @media (max-width: 600px) {
        margin-bottom: 10px;
    }
}

.what-next-main-div-insurance {
    width: 590px;
    margin: 20px auto;

    @media (max-width: 600px) {
        width: 95%;
    }

    .what-next-insurance {
        font-size: 22px;
        margin: 10px 0;
        @media (max-width: 600px) {
        }
    }

    .card-5-next {
        padding: 20px;
        border: 1px solid #bebebe;
        box-shadow: 0px 0px 2px #e2e2e2;
        margin-bottom: 20px;
        border-radius: 3px;
    }
    .card-6-next {
        border: 1px solid #bebebe;
        box-shadow: 0px 0px 2px #e2e2e2;
        margin-bottom: 20px;
        border-radius: 3px;
    }

    .card-5-next {
        background: url('../../Assets/images/InsuranceCard.svg');
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }

    .card-6-next {
        display: flex;
        height: 150px;
    }

    .card-image {
        width: 60%;
    }

    .card-heading-next-2 {
        width: 40%;
        font-size: 22px;
        font-weight: bold;
        padding: 20px;
    }

    .card-heading-next {
        width: 60%;
        font-size: 22px;
        font-weight: bold;
    }
    .highlighted {
        color: #30ab86;
    }
    .button-next-card {
        width: 100px !important;
        height: 40px !important;
        font-size: 14px !important;
        margin: 10px 0 !important;
        border-radius: 5px !important;
    }
}

.homesecurity-container {
    .hsPage {
        margin: 30px 80px 0px 80px;

        @media (max-width: 1366px) {
              margin: 10px 60px 0px 60px;
            }
        @media (max-width: 600px) {
             margin: 10px 10px 0px 10px; 
         }
    }
}

.edit_securityform {
    .react-select {
        width: 100%;
    }
    .edit_paying_input{
        position: relative;
        .inputsupportText{
            position: absolute;
            right: -65px;
            top: 50px;
            @media (max-width: 575px) {
                position: relative;
                top: 0;
                right: 0;
            }
        }
    }
}