.mike-main {
    // border-radius: 5px;
    background-color: #fff;
}

.mike-main-2 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 100%;

    @media (max-width: 768px) {
        height: 100% !important;
    }
    @media (max-width: 425px) {
        height: fit-content !important;
    }
}

.appointment-time-div {
    display: flex;
    flex-direction: column;
}

.details {
    font-size: 14px;
    font-weight: bold;
    padding-bottom: 5px;
}

.details-content {
    font-size: 12px;
    font-weight: 600;
    opacity: 0.8;
    padding-bottom: 5px;
}

.not-mobile-div {
    @media (max-width: 768px) {
        display: none;
    }
}
.mike-div-1 {
    width: 40%;

    @media (max-width: 1440px) {
        width: 50%;
    }

    @media (max-width: 1024px) {
        width: 40%;
        image {
            height: auto;
        }
    }

    @media (max-width: 768px) {
        width: 30%;
        image {
            height: 100%;
        }
    }
    @media (max-width: 425px) {
        width: 30%;
        // max-height: 160px;
        image {
            height: 100%;
        }
    }
}

.mike-div-2 {
    width: 55%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 30px;

    @media (max-width: 1440px) {
        width: 50%;
    }
    @media (max-width: 1024px) {
        width: 60%;
    }

    @media (max-width: 768px) {
        width: 65%;
        margin-left: 10px;
    }
}

.mike-mobile-div {
    display: none;

    @media (max-width: 768px) {
        display: block;
        padding: 10px 0;
        width: 100%;
    }
}

.mike-title {
    font-size: 24px;
    font-weight: bold;
    margin: 15px 0 10px 0;
    width: 100%;

    @media (max-width: 600px) {
        font-size: 19px;
    }
}

.mike-subtitle {
    font-size: 14px;
    margin: 10px 0;
    opacity: 0.7;
    line-height: 22px;

    @media (max-width: 425px) {
        margin: 0;
    }
}
.mike-paragraph {
    font-size: 14px;
    margin: 10px 0 0 0;
    font-weight: 500;
    line-height: 22px;
    width: 100%;
    text-overflow: none;
}
.mike-button-wrapper {
    font-size: 14px;
    margin: 10px 0 0 0;
    font-weight: 500;
    line-height: 22px;
}
.checkDetailsMainDiv {
    margin-bottom: 20px;

    .checkDetailsDiv {
        display: flex;
        align-items: center;
        margin: 10px 0;

        .checkIcon {
            color: #dadce0;
            margin-right: 10px;
        }
        .checkIconCompleted {
            color: #00d56d;
            margin-right: 10px;
        }

        @media (max-width: 1024px) {
            margin: 5px 0;

            .checkTitleDiv {
                font-size: 12px;
            }
            .checkIcon,
            .checkIconCompleted {
                font-size: 18px;
            }
        }
    }

    @media (max-width: 1024px) {
        margin-bottom: 10px;
    }
}
