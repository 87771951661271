.Modal-button {
    @media (max-width: 600px) {
        height: 35px !important;
    }
}

.alert-dialog-title .MuiTypography-h6 {
    @media (max-width: 600px) {
        font-size: 16px !important;
    }
}

.scheduleCallMoving {
    position: absolute;
    width: 70%;
    height: 90%;
    background-color: #ffffff;
    left: 15%;
    top: 5%;
    .scheduleCallBackMoving {
        display: none;
    }
    // @media (max-width: 1440px) {
    //     left: 25%;
    // }
    // @media (max-width: 1024px) {
    //     left: 15%;
    // }
    // @media (max-width: 960px) {
    //     left: 5%;
    // }
    @media (max-width: 600px) {
        // left: 0;
        // top: 0;
        // z-index: 2000;
        // width: 100%;
        // height: 100%;
        left: 7%;
        top: 5%;
        z-index: 2000;
        width: 85%;
        height: 85%;
        .scheduleCallBack {
            display: block;
            padding: 30px 0 0 30px;
        }
    }
}

.call-input-moving .MuiInputLabel-filled.MuiInputLabel-shrink {
    z-index: 10 !important;
}

.call-input-moving-after .MuiFilledInput-root {
    background-color: transparent !important;
    border: 1px solid #bebebe !important;
}

.call-input-moving-2 .MuiFilledInput-root {
    background-color: transparent !important;
}

// .call-input-moving .MuiFilledInput-input {
//     padding: 20px 12px 10px;
// }

.call-input-moving .MuiInputBase-input.Mui-disabled {
    color: #333;
}
.call-input-moving .MuiInputBase-input:focus {
    border: 1px solid #006dff !important;
}
.call-input-moving-2 .MuiInputBase-input.Mui-disabled {
    color: #333;
}
.call-input-moving-after .MuiInputBase-input.Mui-disabled {
    color: #333;
}
.call-input-moving .MuiInputLabel-filled.MuiInputLabel-shrink {
    color: #006dff !important;
}
.call-input-moving-2 .MuiInputLabel-filled.MuiInputLabel-shrink {
    color: #009cbd !important;
}
.call-input-moving-2 .MuiInputLabel-filled.MuiInputLabel-shrink.Mui-focused {
    color: #009cbd !important;
}
.call-input-moving-noDoug .MuiInputLabel-filled.MuiInputLabel-shrink {
    color: #006dff !important;
}
.call-input-moving-noDoug .MuiInputLabel-filled.MuiInputLabel-shrink.Mui-focused {
    color: #006dff !important;
}
.call-input-moving-after .MuiInputLabel-filled.MuiInputLabel-shrink {
    color: #009cbd !important;
}
.call-input-moving-after .MuiInputBase-input:focus {
    border: 1px solid #009cbd !important;
}

.mobile-button-div {
    display: none;

    @media (max-width: 425px) {
        width: 100%;
        display: unset;
    }
}

.main-card-moving-mobile {
    display: none;
    @media (max-width: 425px) {
        display: block;
    }
}

.btnMoving {
    width: 100px !important;
    height: 50px !important;
    @media (max-width: 425px) {
        font-size: 12px !important;
        width: 150px !important;
    }
}

.btnMoving2 {
    width: 225px !important;
    height: 50px !important;

    @media (max-width: 425px) {
        width: 150px !important;
        height: 50px !important;
        font-size: 12px !important;
    }
}

.btnMoving3 {
    width: 225px !important;
    height: 50px !important;

    @media (max-width: 425px) {
        width: 300px !important;
        height: 50px !important;
        font-size: 12px !important;
    }
}

.call-input-moving .MuiInputBase-input {
    border: 1px solid #bebebe;
    background-color: #fff;
    text-align: left;
    border-radius: 5px;
    margin-top: 0px;
}
// .call-input-moving .MuiFilledInput-underline:before {
//     border-bottom: none !important;
// }
// .call-input-moving .MuiFilledInput-underline:after {
//     border-bottom: none !important;
//     border: 1xp solid #006dff;
// }

// .call-input-moving .MuiInputBase-input:focus {
//     border: 1px solid #006dff !important;
// }

// .call-input-moving .MuiInputLabel-outlined.MuiInputLabel-shrink {
//     margin-top: 7px;
// }
// .call-input-moving .MuiFilledInput-root {
//     background: none !important;

// }

// .call-input-moving .MuiInputBase-input {
//     width: 345px;
// }

// .call-input-moving-after .MuiInputLabel-filled.MuiInputLabel-shrink {
//     z-index: 9 !important;

// }
// .call-input-moving-after .MuiInputBase-root.Mui-disabled {

// }

// .call-input-moving-after .MuiFilledInput-input {
//     padding: 20px 12px 10px;
// }
// .call-input-moving-after .MuiInputBase-input {
//     border: none !important;
//     background: #fff !important;
//     text-align: left;
//     border-radius: 5px;
//     margin-top: 0px;
// }
// .call-input-moving-after .MuiFilledInput-underline:before {
//     border-bottom: none !important;
// }
// .call-input-moving-after .MuiFilledInput-underline:after {
//     border-bottom: none !important;
//     border: 1px solid #006dff;
// }

// .call-input-moving-after .MuiInputBase-input:focus {
//     border: 1px solid #009cbd !important;
// }

// .call-input-moving-after .MuiInputLabel-outlined.MuiInputLabel-shrink {
//     margin-top: 7px;
// }

.date-check {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 425px) {
        flex-direction: column;
        align-items: flex-start;
    }

    .date-check-inputDiv {
        width: 50%;

        @media (max-width: 600px) {
            width: 100%;
        }
    }
}
.call-input-moving-2 .MuiInputLabel-filled.MuiInputLabel-shrink {
    z-index: 9 !important;
}
// .call-input-moving-2 .MuiFilledInput-input {
//     padding: 20px 12px 10px;
// }
.call-input-moving-2 .MuiInputBase-input {
    border: 1px solid #bebebe;
    background-color: #fff;
    text-align: left;
    border-radius: 5px;
    margin-top: 0px;
}

//     @media (max-width: 1040px) {
//         width: 115px;
//     }
//     @media (max-width: 768px) {
//         width: 105px;
//     }
//     @media (max-width: 425px) {
//         width: 338px;
//     }

//     @media (max-width: 375px) {
//         width: 285px;
//     }
//     @media (max-width: 360px) {
//         width: 270px;
//     }
// }
// .call-input-moving-2 .MuiFilledInput-underline:before {
//     border-bottom: none !important;
// }
// .call-input-moving-2 .MuiFilledInput-underline:after {
//     border-bottom: none !important;
//     border: 1xp solid #006dff;
// }

.call-input-moving-2 .MuiInputBase-input:focus {
    border: 1px solid #009cbd !important;
}
.call-input-moving-noDoug .MuiInputBase-input:focus {
    border: 1px solid #006dff !important;
}

// .call-input-moving-2 .MuiInputLabel-outlined.MuiInputLabel-shrink {
//     margin-top: 7px;
// }
// .call-input-moving-2 .MuiFilledInput-root {
//     width: 295px;

//     @media (max-width: 1600px) {
//         width: 240px;
//     }
//     @media (max-width: 1440px) {
//         width: 210px;
//     }
//     @media (max-width: 1366px) {
//         width: 200px;
//     }
//     @media (max-width: 1040px) {
//         width: 140px;
//     }
// }
.moving-main-page {
    margin-top: 30px;
    .moving-title-cover {
        padding: 0;
    }
    .tab-active {
        color: #006dff !important;
        border-bottom: 2px solid #006dff !important;
    }
}
.moving-main-page {
    padding: 0 80px;
    overflow-y: visible !important;

    @media (max-width: 1366px) {
        // padding:0px 25px
        padding: 0px 60px;
    }
    @media (min-width: 815px) and (max-width: 825px) {
        padding: 0px 35px;
    }

    @media (max-width: 600px) {
        // padding:0px 25px
        padding: 0px 10px;
    }
}

.Para {
    color: #bebebe;

    @media (max-width: 425px) {
        font-size: 12px !important;
    }
}

.para-para {
    @media (max-width: 425px) {
        font-size: 11px;
    }
}

.main-head-moving .path {
    display: flex;
    // margin-top: -25px;
    font-size: 12px;

    @media (max-width: 768px) {
        font-size: 12px;
        line-height: 18px;
    }
}
.thankyou-tab {
    padding: 0 !important;
    margin-top: 20px;
}

.main-head-2 {
    margin-top: 0px;

    @media (max-width: 768px) {
        padding: 0 0px;
    }
}
.main-head-moving {
    margin: 30px 0 0 80px;

    @media (max-width: 1366px) {
        margin: 30px 0 0 60px;
    }

    @media (max-width: 600px) {
        margin: 30px 0 0 10px;
    }
}

.landingPageMoveMain .heading {
    font-size: 20px;
    font-weight: 600;
    font-family: sans-serif;

    @media (max-width: 768px) {
        font-size: 22px;
        line-height: 32px;
    }
}

.main-head-moving .heading,
.landingPageMove .heading {
    font-size: 20px;
    font-weight: 600;
    font-family: sans-serif;

    @media (max-width: 768px) {
        font-size: 22px;
        line-height: 32px;
    }
}

.main-heading {
    margin-top: 0 !important;
}

.coverDivMove {
    display: flex;
    align-items: center;
    // @media (min-height: 850px) {
    //     height: 60vh;
    // }
    // @media (min-height: 1000px) {
    //     height: 70vh;
    // }
}

.coverDivMove-2 {
    // height: 70vh;
    display: flex;
    align-items: center;
    // @media (min-height: 850px) {
    //     // height: 70vh;
    //     height: unset;
    // }
    // @media (min-height: 1000px) {
    //     // height: 80vh;
    // }

    .react-select .select-state__control .select-state__indicators .select-state__indicator {
        height: 70%;
    }
}

.landingPageMoveMain {
    text-align: center;
    padding: 50px 80px 0px 80px;
    width: 100%;

    @media (max-width: 1366px) {
        padding: 50px 60px 0px 60px;
    }

    @media (max-width: 425px) {
        padding: 20px 10px 0px 10px;
    }
}

.landingPageMove {
    text-align: center;
    padding: 0px 80px 0px 80px;
    width: 100%;

    @media (max-width: 1366px) {
        padding: 0px 60px 0px 60px;
    }

    @media (max-width: 425px) {
        padding: 0px 10px 0px 10px;
    }
}

.btn-div {
    display: flex;
    justify-content: flex-end;
    // margin-top: -25px;
}

.coverDivMove .line {
    border-top: 0.5px solid #d2d2d2;
    margin-top: 40px;
    margin-bottom: 40px;

    @media (max-width: 425px) {
        margin-bottom: 0;
        display: none;
    }
}
.MuiDialog-paperWidthSm {
    max-width: 600px;
    height: 400px;
}

.steps {
    padding: 0px 80px 0px 80px;
    text-align: center;
    margin-top: 5%;
}

//stepper styless

.buttons-moving {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 100px;

    @media (max-width: 768px) {
        justify-content: center !important;
        // padding-bottom: 100px;
    }
}

.skipStyle {
    text-decoration: underline;
    color: #006dff;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.moving-main-page .Para11 {
    text-align: center;
    color: #333333;
    font-size: 12px;
    margin-top: 20px;
    margin-bottom: 10px;
}

//checkbox cards

.checkbox .MuiTypography-body1 {
    @media (max-width: 1040px) {
        font-size: 12px !important;
    }
}

.checkboxCards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 40px;
}

.individualCard {
    margin-right: 15px;
}

// progress bar styles

.MuiLinearProgress-barColorPrimary {
    background-color: #00d56d;
}

.movingAddressCard {
    // padding: 0px 100px 0px 100px;
    display: flex;
    justify-content: center;

    @media (max-width: 425px) {
        justify-content: left;
        // margin-top: 250px;
    }
}

.dialog-box .MuiDialog-paperWidthSm,
.done-alert .MuiDialog-paperWidthSm {
    max-height: 140px;
}

.movingBoxCard {
    width: fit-content;
    height: auto;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 0px 10px 0px 20px;
    // margin-top: -100px;

    @media (max-width: 768px) {
        justify-content: left;
        padding: 0;
        // margin-top: 50px;
    }
}
// .opened {
//     @media (max-width: 425px) {
//         margin-top: 300px;
//     }
// }

.openedCard {
    // @media (max-width: 425px) {
    //     margin-top: 300px !important;
    // }
}
.movingBoxCardNew {
    width: fit-content;
    height: auto;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 10px 10px 10px 20px;
    // margin-top: -100px;

    @media (max-width: 768px) {
        justify-content: left;
        padding: 0;
        // margin-top: 50px;
    }
}

.movingBox {
    text-align: left;
    .MuiFilledInput-root {
        width: 100%;
        background-color: #fff !important;
        border: 1px solid #6b6c6f;
        border-radius: 5px;

        // @media (max-width: 768px) {
        //     height: 40px;
        // }
    }

    .MuiFilledInput-root.Mui-focused {
        width: 100%;
        background-color: #fff !important;
        border: 1px solid #006df5;
        border-radius: 5px;

        // @media (max-width: 768px) {
        //     height: 40px;
        // }
    }

    .MuiFilledInput-root.Mui-error {
        width: 100%;
        background-color: #fff !important;
        border: 1px solid red;
        border-radius: 5px;

        // @media (max-width: 768px) {
        //     height: 40px;
        // }
    }

    .MuiFilledInput-root:focus {
        border: 1px solid #006dff !important;
        background: transparent;
    }
    .douglasInput .MuiFilledInput-root:focus {
        border: 1px solid #009cbd !important;
        background: transparent;
    }

    .MuiFilledInput-underline:before,
    .MuiFilledInput-underline::after {
        border-bottom: none !important;
    }
    // .signUpInput input:focus {
    //     background-color: #fff;
    //     border: 1px solid #006df5;
    //     border-radius: 5px;
    // }
    .MuiInputLabel-filled.MuiInputLabel-shrink {
        z-index: 1 !important;
        color: #6b6c6f;

        @media (max-width: 768px) {
            font-size: 12px !important;
        }
    }
    // .MuiFormLabel-root.Mui-focused {
    //     color: #006df5;
    // }
}
.cards-para {
    text-align: center;
    margin-top: -5px;
}

.movingAddressCard .heading {
    font-size: 24px;
    text-align: center;

    @media (max-width: 768px) {
        font-size: 22px;
    }
}

.movingAddressCard .headingCards {
    font-size: 24px;
    text-align: center;
    font-weight: bold;

    @media (max-width: 425px) {
        font-size: 22px;
        // margin-top: 300px;
    }
}

.movingAddressCard p {
    text-align: center;

    @media (max-width: 768px) {
        font-size: 12px;
        opacity: 0.8;
    }
}

.movingAddressCard .innerInputs {
    display: flex;
    width: 100%;
    @media (max-width: 768px) {
        flex-direction: column;
        justify-content: left;
        // margin-left: -20px;
        margin-top: 0px;
    }
}

.movingAddressCard .innerInputsCards {
    display: flex;
    align-items: center;
    @media (max-width: 768px) {
        flex-direction: column;
        justify-content: center;
        margin-top: 0px;
    }

    @media (max-width: 425px) {
        flex-direction: column;
        justify-content: center;
        margin-top: 10px;
    }
}

.movingAddressCard .addressFlexDivIconInput {
    display: flex;
    justify-content: center;
    margin: 0px 15px;
    width: 50%;
    .moveSelectFilter {
        .movingDropdownSteps {
            #react-select-2-input {
                display: inline-block !important;
            }
        }
    }

    @media (max-width: 768px) {
        flex-direction: row;
        justify-content: left;
        margin: 0px auto;
        width: 100% !important;
    }
}

.movingAddressCard .addressFlexDivNew {
    display: flex;
    justify-content: center;
    margin: 0px 15px;
    // width: 330px;
    @media (max-width: 768px) {
        flex-direction: column;
        margin: 10px auto;
    }
}

.icons {
    margin-right: 20px;
    width: 50px;
    height: 50px;

    @media (max-width: 425px) {
        margin-right: 10px !important;
        margin-left: 0px !important;
        width: 45px !important;
        height: 45px !important;
    }
}

//input

.moving-main-page .MuiInputLabel-outlined.MuiInputLabel-shrink {
    margin-top: 15px;
    color: #6b6c6f;
}

.moving-main-page .MuiOutlinedInput-notchedOutline {
    border: none;
}
.moving-main-page .MuiOutlinedInput-input {
    text-align: left;
    // border: 1px solid #D8DCE6;
}
.moving-main-page .orderFlexDiv .MuiFormControl-root {
    border: none;
}

.date-check .MuiFormControl-root {
    width: 100%;
}
.moving-main-page .MuiFormControl-root {
    // border: 1px solid #bebebe;
    border-radius: 4px;
    // width: 250px;
    width: 100%;

    @media (max-width: 768px) {
        // width: 400px !important;
    }

    @media (max-width: 425px) {
        // width: 95% !important;
    }
}

.movingAddressCard .MuiOutlinedInput-input {
    // border: 1px solid #006DFF;
    border-radius: 4px;
}

.inputLabel .MuiFormLabel-root {
    font-size: 16px !important;
    margin: 15px 0;

    @media (max-width: 425px) {
        font-size: 12px !important;
    }
}

.iconDiv {
    display: flex;
    align-items: flex-end !important;
    height: 100px;
    width: 20%;
}

.moveSelectFilter {
    width: 80%;
}

// .movingAddressCard .css-2b097c-container {
//     width: 100%;
// }
.movingAddressCard .input1 .MuiOutlinedInput-root,
.movingAddressCard .react-select {
    // width: 250px !important;
    width: 100% !important;
    // height: 52px !important;

    @media (max-width: 768px) {
        // width: 400px !important;
    }
    @media (max-width: 425px) {
        // width: 250px !important;
    }
}

.movingAddressCard .react-select .select-state__control {
    height: 56px !important;
    // width: 250px !important;

    @media (max-width: 768px) {
        width: 100% !important;
    }
}

.marginDiv {
    margin-top: 30px;
}

.black-card-moving {
    margin-top: 10px;
    // background: #08192f;
    color: #fff;
    padding: 30px 0px 0 25px;
    border-radius: 5px;

    @media (max-width: 425px) {
        padding: 10px;
    }
}

.main-card-moving {
    display: flex;
    justify-content: space-between;

    @media (max-width: 425px) {
        display: none;
    }
}

.top-movers {
    text-align: left;
    font: Roboto;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
    margin-top: 20px;
    font-size: 22px;
    font-weight: bold;

    @media (max-width: 425px) {
        font-size: 16px;
        line-height: 24px;
        padding: 0 20px;
    }
}

.steps .MuiTabs-flexContainer {
    justify-content: flex-start !important;
}

.other-suitable-mover {
    display: flex;
    justify-content: space-between;
    margin-top: 28px;

    @media (max-width: 425px) {
        flex-direction: column;
    }
}

.other-suitable-text {
    text-align: left;
    font-size: 22px;
    letter-spacing: 0px;
    color: #333333;
    font-weight: bold;
    @media (max-width: 600px) {
        font-size: 16px;
    }
}

.average {
    display: flex;
    justify-content: center;
    align-items: center;
}
.down-arrow {
    padding: 6px 10px 0 10px;
}

.avg-customer-review {
    text-align: left;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
    // text-decoration: underline;

    @media (max-width: 425px) {
        text-align: right;
        margin-bottom: -150px;
    }
}

.facility-details .avg-customer-review {
    @media (max-width: 425px) {
        margin-bottom: 0px;
    }
}

.pods-card {
    margin-top: 20px;
    margin-bottom: 40px;

    // background: #273e59 url('../../Assets/images/MovingImages/pods-background.png');
    color: #fff;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    // padding: 30px;
    @media (max-width: 425px) {
        // margin-top: 50px;
    }
}

.pods-content-card {
    display: flex;
    height: 175px;
}

.bottom-cards-div {
    padding: 0 30px 0 0;
    @media (max-width: 768px) {
        display: none;
    }
}

.bottom-cards-div-mobile {
    display: none;

    @media (max-width: 768px) {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0px 65px 0 30px;
    }

    @media (max-width: 425px) {
        padding: 0px;
        margin-top: 35px;
    }
}

.bott .disc-img {
    padding: 0px 20px;
    margin-top: -10px;
    height: 145px;
    width: 102px;
}

.pods-image {
    margin-top: 5%;
}
.pods-image-moving {
    @media (max-width: 425px) {
        // height: 175px;
        width: 100%;
        margin-top: 20px;
    }
}

.pods-text {
    font-size: 22px;
    // font-weight: 600;
    // letter-spacing: 0px;
    color: #fff;
    font-family: 700;
    margin-top: 10px;
    margin-bottom: 15px;
}
.tab-div-moving {
    display: flex;
    flex-direction: row;
    // width: 35%;
    // justify-content: space-between;
    padding-top: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #00000024;
    // padding-left: 80px;

    @media (max-width: 425px) {
        // justify-content: space-between;
        // padding: 10px 10px;
        margin-top: 10px;
    }

    @media (max-width: 375px) {
        font-size: 12px;
    }
}

.tab-div-moving > div {
    margin-right: 30px;

    @media (max-width: 425px) {
        padding: 0 12px 10px 0px;
        margin-right: 0;
        font-size: 14px;
    }

    @media (max-width: 325px) {
        font-size: 12px;
        padding: 0 10px 10px 0px;
    }
}

.tab-active {
    font-weight: 700;
    color: #006dff;
    border-bottom: 3px solid #006dff;
    cursor: pointer;
    padding-bottom: 10px;
}
.tab-activeD {
    font-weight: 700;
    color: #009cbd;
    border-bottom: 3px solid #009cbd;
    cursor: pointer;
    padding-bottom: 10px;
}
.tab-text {
    cursor: pointer;
}
.pods-background img {
    width: 763px;
}

.button-div {
    // margin-top: -5px;
}

.moving-main-div {
    display: flex;
    padding: 0px;
}

.left-div {
    width: 24%;
    margin-left: 15px;
    position: absolute;
    left: 73%;
    margin-bottom: 20px;

    @media (min-width: 1921px) {
        left: 68%;
    }

    @media (max-width: 1536px) {
        left: 72%;
    }
    @media (min-width: 815px) and(max-width:825px) {
        left: 87%;
    }
    @media (max-width: 425px) {
        display: none;
    }
}
.left-div-2 {
    width: 24%;
    margin-left: 15px;
    position: absolute;
    left: 73%;
    // top: 20%;
    margin-bottom: 20px;

    @media (min-width: 1921px) {
        left: 68%;
    }

    @media (max-width: 1536px) {
        left: 72%;
    }
    @media (min-width: 815px) and(max-width:825px) {
        left: 87%;
    }

    @media (max-width: 425px) {
        display: none;
    }
}

.left-div-scroll {
    position: fixed;
    right: 80px;
    width: 22%;
    top: 180px;
    @media (max-width: 1320px) {
        width: 21%;
        right: 48px;
    }

    @media (max-width: 425px) {
        display: none;
    }
}
.movers-detail-div {
    width: 75%;
    margin-top: -0.8%;

    @media (max-width: 425px) {
        width: 100%;
        // margin:0 10px;
    }
}
.done-main {
    margin-left: auto;
    margin-right: auto;
    padding: 20px 80px;

    @media (max-width: 425px) {
        padding: 0px 10px;
    }
}
.done-mainDouglas {
    .moving-card-header,
    .number-2,
    .call-card-header {
        background: #009cbd !important;
    }
    .moving-card-header-2,
    .moving-card-heading-2,
    .number,
    .MuiCheckbox-colorPrimary.Mui-checked,
    .clear-text {
        color: #009cbd !important;
    }
    .call-input-moving .MuiInputBase-input,
    .call-input-moving-2 .MuiInputBase-input {
        // border: 1px solid #100b28 !important;
        border-radius: 0px !important;
        color: #323744;
    }
}

.done-heading {
    text-align: left;
    font-size: 24px;
    font-weight: bold;
    margin-top: 20px;
    @media (max-width: 425px) {
        font-size: 18px;
    }
}
.done-para {
    text-align: left;
    font-size: 16px;
    padding: 10px 0px 20px 0px;
    color: #333333;
    display: flex;

    @media (max-width: 425px) {
        font-size: 12px;
    }
}
.main-outline {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media (max-width: 425px) {
        flex-direction: column;
    }
}

.quote-div {
    width: 43%;
    // margin-right: 10px;

    @media (max-width: 425px) {
        display: none;
    }
}

.quote-div-mobile {
    display: none;

    @media (max-width: 425px) {
        display: block;
        margin-top: 40px;
    }
}
.move-cards-div {
    display: flex;
    flex-direction: column;
    width: 55%;

    @media (max-width: 425px) {
        width: 100%;
    }
}

.rdtDays {
    margin-left: 25%;
    cursor: pointer;
}
.rdtTime {
    margin-left: 35%;
    cursor: pointer;
}
.rdtMonths {
    margin-left: 35%;
    cursor: pointer;
}

// .done-main {
//     .MuiInputBase-root {
//         border: 1px solid #bebebe;
//         border-radius: 4px;
//     }

//     .MuiInput-underline:before,
//     .MuiInput-underline:after {
//         border-bottom: none;
//     }

//     .MuiInputBase-input {
//         padding: 27px 12px 10px;
//     }

//     .MuiInputBase-root.MuiInputBase-input:focus {
//         border: 1px solid #006dff;
//     }

//     .MuiInputLabel-formControl {
//         transform: translate(14px, 16px) scale(1);
//     }

//     .MuiInputLabel-shrink {
//         transform: translate(14px, 1.5px) scale(0.75);
//     }

//     label + .MuiInput-formControl {
//         margin-top: -7px;
//     }

//     .MuiInputLabel-root {
//         color: #6b6c6f;
//     }
// }

// .MovingDateTime .MuiInput-underline:before {
//     border: none !important;
// }
// .MovingDateTime .MuiInput-underline:after {
//     border: none !important;
// }
// .MovingDateTime .MuiInputBase-root {
//     margin-left: 10px;
//     width: 100%;
//     text-align: center;
//     height: 40px;
//     font-size: 16px;
//     border: 0.5px solid #c6c6c6;
//     border-radius: 3px;
//     text-align: left;
//     padding: 27px 12px 10px;

//     @media (max-width: 1600px) {
//         width: 240px;
//     }
//     @media (max-width: 1440px) {
//         width: 200px;
//     }
//     @media (max-width: 1040px) {
//         width: 200px;
//     }
//     @media (max-width: 768px) {
//         width: 150px;
//     }

//     @media (max-width: 600px) {
//         // width: calc(100vw - 55px);
//         width: 100%;
//         margin-left: 0;
//         padding-left: 10px;
//     }
//     // @media (max-width: 375px) {
//     //     width: 320px;
//     // }

//     // @media (max-width: 360px) {
//     //     width: 305px;
//     // }
// }

.text-text:focus {
    outline: none !important;
    border: 1px solid #009cbd;
}
.text-text:hover {
    outline: none !important;
    border: 1px solid #009cbd;
}
// .MovingDateTime .MuiFilledInput-root:focus {
//     border: 1px solid #009cbd;
// }

// .MovingDateTime input:hover {
//     border: 1px solid #009cbd;
// }

.moving-textarea {
    text-align: left;
    font-size: 14px;
    font-weight: bold;
    margin: 10px 10px 20px;

    @media (max-width: 425px) {
        display: none;
    }
}

.moving-textarea-textarea {
    display: flex;
    justify-content: flex-start;
    margin-left: 10px;

    @media (max-width: 1024px) {
        justify-content: space-around;
        margin-left: 0;
        align-items: center;
    }
    @media (max-width: 425px) {
        display: none;
    }
}

.checkbox-done {
    width: 50%;
    margin-left: 2%;
    height: 55px;

    @media (max-width: 425px) {
        width: 100%;
        margin-left: 0px;
    }
}

// .MovingDateTime .MuiInputLabel-animated {
//     display: none;
// }

textarea {
    margin-top: 10px;
    border: 0.5px solid #c6c6c6;
    border-radius: 3px;

    @media (max-width: 768px) {
        width: 300px;
    }
}
.moving-card {
    border: 1px solid #c6c6c6;
    height: 85%;
    border-radius: 2px;
}

.moving-confirmationCard {
    width: 33%;
    margin: 10px 10px;

    @media (max-width: 425px) {
        width: 100%;
        margin: 10px 10px 10px 0px;
    }
}
.moving-card-2 {
    border: 1px solid #c6c6c6;
    height: 83%;
    border-radius: 2px;
}
.call-card {
    border: 1px solid #c6c6c6;
    // height: 450px;
    border-radius: 5px;
    margin-top: 20px;
}

.moving-card-header {
    display: flex;
    flex-direction: row;
    height: 40px;
    align-items: center;
    // background: #273e59;
    // color: #fff;
    padding: 10px 0;
}
.call-card-header {
    display: flex;
    flex-direction: row;
    height: 40px;
    align-items: center;
    background: #273e59;
    color: #fff;

    @media (max-width: 425px) {
        padding: 10px 0;
    }
    .moving-card-heading {
        height: auto;
        padding-bottom: 0;
    }
}
.moving-card-header-2 {
    display: flex;
    flex-direction: row;
    height: 40px;
    // background: #fff;
    // color: #273e59;
    align-items: center;
    padding-bottom: 10px;
}
.card-number-div {
    width: 5%;
    padding-left: 10px;
    margin-right: 12px;

    .number {
        margin-top: 0px;
    }

    @media (max-width: 1024px) {
        width: 12%;
    }
}

.moving-card-heading {
    width: 70%;
    font-size: 16px;
    color: #fff;
    text-align: left;
    // margin-top: 13px;
    font-weight: bold;
    height: auto;
    align-items: center;
    margin-left: 10px;

    @media (max-width: 425px) {
        height: auto;
        padding-bottom: 0px;
        margin-top: 0;
    }
}

.call-card-heading {
    width: 70%;
    font-size: 16px;
    color: #fff;
    text-align: left;
    margin-top: 14px;
    font-weight: bold;
}

.moving-card-heading-2 {
    width: 90%;
    font-size: 16px;
    color: #273e59;
    text-align: left;
    margin-top: 10px;
    font-weight: bold;
    margin-left: 10px;
}

.number {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    color: #273e59;
    background: #fff;
    margin-top: 12px;
    margin-left: 10px;
    text-align: center;
    span {
        margin: 0;
        font-size: 14px;
    }

    @media (max-width: 425px) {
        margin-top: 0;
    }
}

.number-2 {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    color: '#fff';
    background: #273e59;
    margin-top: 12px;
    margin-left: 10px;
    color: #fff;
    text-align: center;

    span {
        margin: 0;
        font-size: 14px;
    }
}

.conditional-edit-button {
    display: flex;
    margin-top: 10px;
}
.conditional-edit {
    margin-left: auto;
    margin-right: 10px;
}

.move-inputs {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 0 10px;

    @media (max-width: 425px) {
        flex-direction: column;
    }
}

.move-inputs-after {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 0 0 30px 10px;

    @media (max-width: 425px) {
        flex-direction: column;
    }
}

.input-left {
    width: 40%;
    display: flex;
    flex-direction: column;
}
.input-right {
    width: 40%;
}
.arrow {
    width: 18%;
    // margin-left: 10%;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 425px) {
        margin-top: 0;
        width: 100%;
        transform: rotate(90deg);
    }
}

.arrow-2 {
    width: 18%;
    margin-top: -10%;
    // margin-left: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 425px) {
        margin-top: 0;
        width: 100%;
        transform: rotate(90deg);
    }
}

.num {
    font-weight: bold;
    margin-left: 25%;
}

.input-move-1 {
    padding: 20px 10px;

    @media (max-width: 425px) {
        width: 234%;
        padding: 10px;
    }
}

.moveFrom {
    width: 250px !important;
}

.moveTo {
    width: 250px !important;
    margin-left: -35px;
}
.input-move-2 {
    padding: 10px 10px;
    @media (max-width: 425px) {
        width: 234%;
    }
}

.moveSize {
    width: 250px !important;
}

.MuiInputLabel-root {
    margin-bottom: 10px !important;
    text-align: left !important;
    font-size: 11px !important;
    font-weight: bold !important;
}

.personal-details-1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media (max-width: 425px) {
        flex-direction: column;
    }
}

.move-buttons {
    display: flex;
    flex-direction: row;
    justify-content: left;
    padding: 0 10px;

    @media (max-width: 425px) {
        padding: 0;
    }
}

.move-button {
    margin: 20px 0;
    margin-left: 10px;
}

.call-card .move-button {
    @media (max-width: 425px) {
        margin-left: 0;
        width: 100%;

        button {
            width: 100% !important;
        }
    }
}

// .call-input-moving {
//     width: 30%;
//     margin: 10px 15px;
//     height: 50px;
// }

.move-select {
    width: 150px !important;
    margin: 10px 15px;
}
.move-select-2 {
    width: 150px !important;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-right: 15px;
}

.close {
    display: none;
}

.modalHead {
    color: #333333;
    font-size: 32px;
    font-weight: bolder;
    margin-bottom: 10px;

    @media (max-width: 425px) {
        font-size: 24px !important;
        padding: 0 10px;
    }
}

.modalSubHead {
    color: #333333;
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 30px;
    @media (max-width: 425px) {
        font-size: 16px !important;
        padding: 0 10px;
    }
}

.modalData {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    justify-content: left;
}
.modalData .MuiSvgIcon-root {
    height: 50px !important;
    width: 100px !important;
    animation: myfirst 1s forwards;
}

@keyframes myfirst {
    0% {
        fill: #bebebe;
    }
    99% {
        fill: #bebebe;
    }
    100% {
        fill: #00d56d;
    }
}

.textModal {
    margin-left: 10px;
    animation: myfirst1 1s forwards;
}

@keyframes myfirst1 {
    0% {
        fill: #bebebe;
        font-weight: 200;
    }
    99% {
        fill: #bebebe;
        font-weight: 200;
    }
    100% {
        fill: #333333;
        font-weight: 700;
    }
}

.modalData1 {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    justify-content: left;
}
.modalData1 .MuiSvgIcon-root {
    height: 50px !important;
    width: 100px !important;
    animation: myfirst 2s forwards;
}

.textModal1 {
    margin-left: 10px;
    animation: myfirst1 2s forwards;
}

.modalData2 {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    justify-content: left;
}
.modalData2 .MuiSvgIcon-root {
    height: 50px !important;
    width: 100px !important;
    animation: myfirst 3s forwards;
}

.textModal2 {
    margin-left: 10px;
    animation: myfirst1 3s forwards;
}
.modalData3 {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    justify-content: left;
}
.modalData3 .MuiSvgIcon-root {
    height: 50px !important;
    width: 100px !important;
    animation: myfirst 4s forwards;
}

.textModal3 {
    margin-left: 10px;
    animation: myfirst1 4s forwards;
}

.main-divv {
    display: flex;
    justify-content: left;
    flex-direction: column;

    @media (max-width: 425px) {
        font-size: 14px;
        padding: 0 20px;
    }
}

.Storage-main-div .headerDiv {
    font-size: 22px;
    display: flex;
    justify-content: space-between;

    @media (max-width: 425px) {
        align-items: center;
        padding: 0 10px;
        margin: 25px 0;
    }
}

.storageFacilityDiv .headerDiv {
    font-size: 22px;
    display: flex;
    justify-content: space-between;

    @media (max-width: 425px) {
        flex-wrap: wrap;
        padding: 0 10px;
    }
}
.storageFacilityDiv .titleDiv {
    display: flex;
    margin: 0;
    align-items: flex-end;
    font-weight: bold;

    @media (max-width: 768px) {
        flex-wrap: wrap;
        width: 50%;
    }

    @media (max-width: 425px) {
        width: 100%;
    }
}

.storageFacilityDiv .headerDiv .titleLocation {
    color: #006dff;
}

.storageFacilityDiv .headerDiv .roomIcon {
    color: #006dff;
    margin: 5px 5px 0;

    @media (max-width: 425px) {
        margin: 5px 5px 0 -5px;
    }
}

.storageFacilityDiv .selectDiv,
.Storage-main-div .selectDiv {
    display: flex;
    align-items: center;

    @media (max-width: 425px) {
        margin-top: 25px;
    }
}

.storageFacilityDiv .selectDiv .react-select,
.Storage-main-div .selectDiv .react-select {
    width: 150px;
}

.storageFacilityDiv .selectDiv .react-select .css-yk16xz-control,
.Storage-main-div .selectDiv .react-select .css-yk16xz-control {
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #333333;
}

.Storage-main-div {
    display: flex;
    width: 100%;
    margin-top: 20px;

    @media (max-width: 768px) {
        justify-content: space-between;
    }

    @media (max-width: 425px) {
        flex-wrap: wrap;
    }
}

.Storage-main-div .image-div {
    width: 40%;
    box-shadow: 0 0 10px #e4e6e8;
    height: fit-content;

    @media (max-width: 768px) {
        width: 47%;
    }

    @media (max-width: 425px) {
        width: 100%;
        text-align: center;
        padding-top: 30px;
    }
}

.Storage-main-div .image-div .dataDiv {
    padding: 40px 100px;
    text-align: left;
    display: none;

    @media (max-width: 425px) {
        display: block;
        padding: 25px 0px;
        border-top: 1px solid lightgray;
        margin-top: 20px;
    }
}

.Storage-main-div .image-div img {
    // width: 480px;
    width: 100%;
    height: 420px;

    // @media (max-width: 1366px) {
    //     width: 455px;
    // }

    @media (max-width: 1024px) {
        width: 100%;
        height: auto;
    }

    @media (max-width: 425px) {
        width: 95%;
        height: auto;
        border-radius: 8px;
    }
}

.Storage-main-div .facility-details {
    text-align: left;
    padding: 0 30px;
    width: 60%;

    @media (max-width: 768px) {
        width: 50%;
        padding: 0 0 0 0px;
    }

    @media (max-width: 425px) {
        width: 100%;
        padding: 0;
    }
}

.Storage-main-div .facility-details .facilityName {
    font-size: 28px;
    color: #333333;
    font-weight: 600;

    @media (max-width: 768px) {
        font-size: 18px;
    }

    @media (max-width: 425px) {
        display: none;
    }
}

.Storage-main-div .facility-details .facilityAddress {
    font-size: 20px;
    margin: 20px 0 25px 0;
    display: flex;
    align-items: center;

    @media (max-width: 768px) {
        font-size: 15px;
        margin: 10px 0;
    }

    @media (max-width: 425px) {
        display: none;
    }
}

.Storage-main-div .facility-details .room-icon {
    color: #6b6c6f;
    margin-right: 10px;
}

.Storage-main-div .titleDiv {
    font-size: 22px;
    font-weight: bold;
    margin: 0;

    @media (max-width: 768px) {
        font-size: 16px;
    }

    @media (max-width: 425px) {
        margin: 0;
    }
}

.Storage-main-div .tab {
    display: flex;
    margin: 20px 0;
    border-bottom: 1px solid lightgrey;

    @media (max-width: 425px) {
        // display: none;
        padding: 0 10px;
    }
}

.Storage-main-div .tab .blueTab {
    font-size: 16px;
    color: #006dff;
    padding-bottom: 10px;
    font-weight: bold;
    border-bottom: 3px solid #006dff;
    margin-right: 25px;

    @media (max-width: 1024px) {
        font-size: 14px;
    }
    @media (max-width: 768px) {
        font-size: 9px;
        margin-right: 8px;
    }

    @media (max-width: 425px) {
        font-size: 12px;
        margin-right: 15px;
    }
}

.Storage-main-div .tab .blackTab {
    font-size: 16px;
    padding-bottom: 10px;
    margin-right: 25px;

    @media (max-width: 1024px) {
        font-size: 14px;
    }
    @media (max-width: 768px) {
        font-size: 9px;
        margin-right: 8px;
    }

    @media (max-width: 425px) {
        font-size: 12px;
        margin-right: 15px;
    }
}

.Storage-main-div .tabContent {
    box-shadow: 0 0 10px #e4e6e8;
    padding: 20px;
    margin: 15px 0;
}

.Storage-main-div .tabContent:hover {
    border: 1px solid #006dff;

    // .unitButton {
    //     background-color: rgb(85, 36, 72) !important;
    //     color: #ffffff !important;
    // }
    // @media (max-width: 425px) {
    //     border: none;

    // .unitButton {
    //     background-color: #ffffff !important;
    //     color: rgb(85, 36, 72) !important;
    // }
    // }
}

.storageDetailsMobile .unitCard .unitLogo {
    @media (max-width: 425px) {
        width: 30%;
    }
}

.storageDetailsMobile .unitCard .unitTitleDiv {
    @media (max-width: 425px) {
        width: 60%;
    }
}
.storageDetailsMobile .unitCard .unitCurrencyDiv {
    @media (max-width: 425px) {
        margin-top: 20px;
    }
}

.Storage-main-div .unitCard .unitLogo {
    width: 15%;

    img {
        width: 90%;
    }

    @media (max-width: 768px) {
        width: 30%;
    }
    @media (max-width: 425px) {
        width: 30%;
    }
}
.Storage-main-div .unitCard .unitTitleDiv {
    margin: 0 10px;
    width: 40%;

    @media (max-width: 768px) {
        margin: 0 0px;
        width: 65%;
    }

    @media (max-width: 425px) {
        margin: 0 0px;
        width: 70%;
    }
}

.Storage-main-div .unitCard {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 768px) {
        align-items: center;
        flex-wrap: wrap;
    }
}

.Storage-main-div .unitCard .unitCurrencyDiv {
    display: flex;
    width: 25%;

    @media (max-width: 768px) {
        width: 100%;
        justify-content: center;
        margin: 20px 0;
    }
}

.unitTitle {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 10px;
}

.unitLocation {
    font-size: 14px;
}

.unitCurrency {
    font-size: 24px;
    margin-top: 3px;
    font-family: Poppins, sans-serif;
}

.unitAmount {
    font-size: 48px;
    font-weight: bolder;

    @media (max-width: 1024px) {
        font-size: 40px;
    }
}

.unitRate {
    font-size: 14px;
    display: flex;
    align-items: flex-end;
    margin-bottom: 7px;
}

.unitButtonDiv {
    width: 20%;
    text-align: right;
}

.unitButtonDiv .unitButton {
    height: 53px !important;
    width: 97px !important;
    // background-color: transparent !important;
    // border: 1px solid #002e62 !important;
    // color: #002e62 !important;
    font-size: 16px !important;
    font-weight: bold;
}

.aboutHeading {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 20px;

    @media (max-width: 768px) {
        font-size: 14px;
    }

    @media (max-width: 425px) {
        font-size: 14px;
        line-height: 22px;
    }
}

.aboutDescription {
    font-size: 14px;
    margin-bottom: 25px;
    // border-bottom: 1px solid #E0E0E0;

    @media (max-width: 768px) {
        font-size: 12px;
    }

    @media (max-width: 425px) {
        padding: 0 10px;
    }
}

.phoneDiv {
    display: flex;
    color: #6b6c6f;
    text-align: left;
    margin-bottom: 20px;
}

.phoneDiv .phone {
    width: 21px;
}

.phoneDetails {
    margin-left: 20px;
}

.phoneHeading {
    font-size: 14px;
}

.phoneNumber {
    font-size: 22px;
}

.TimingDiv {
    @media (max-width: 425px) {
        padding: 0 10px;
    }
}

.featursDivReserveStorage {
    padding: 0 20px;

    @media (max-width: 768px) {
        padding: 0 10px;
    }
    @media (max-width: 425px) {
        padding: 0 10px;
    }
}

.hoursDiv {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    margin: 7px 0;
}

.hoursHeading {
    font-size: 16px;
    font-weight: bold;
    line-height: 22px;
    margin: 15px 0;

    @media (max-width: 768px) {
        font-size: 14px;
    }
}

.hoursDiv .hours,
.hoursDiv .days {
    @media (max-width: 1024px) {
        font-size: 12px;
    }

    @media (max-width: 768px) {
        font-size: 10px;
    }

    @media (max-width: 425px) {
        font-size: 14px;
    }

    @media (max-width: 325px) {
        font-size: 11px;
    }
}

.hoursDiv .days {
    width: 30%;
}

.hoursDiv .hours {
    width: 70%;
    margin-bottom: 10px;
}

.accessHoursDiv .hoursDiv .hours {
    width: 100%;
}

.amenityHeading {
    margin: 25px 0 15px 0;
    font-size: 16px;
    font-weight: bold;

    @media (max-width: 768px) {
        font-size: 14px;
    }
}

.amenityDiv {
    display: flex;
    margin: 10px 0;
    font-size: 16px;
    color: #00d56d;

    @media (max-width: 768px) {
        font-size: 14px;
    }
}

.amenityTitle {
    color: #333333;
    margin-left: 15px;
}

.order-place-main {
    text-align: left;

    // @media (max-width: 425px) {
    //     padding: 0 10px;
    // }
}

.orderMainHeading {
    font-size: 24px;
    font-weight: bold;

    @media (max-width: 768px) {
        font-size: 20px;
        line-height: 24px;
    }

    @media (max-width: 425px) {
        font-size: 18px;
        line-height: 24px;
    }
}

.orderSubHeading img {
    width: 20px;

    @media (max-width: 425px) {
        width: 14px;
        margin-top: 5px;
        margin-bottom: -5px;
        margin-left: 5px !important;
    }
}

.orderSubHeading {
    font-size: 16px;
    display: flex;
    align-items: center;
    margin: 10px 0;

    @media (max-width: 425px) {
        font-size: 12px;
        line-height: 18px;
        display: inline;
    }
}

.completeReservationDiv {
    border: 1px solid #c6c6c6;
    width: 58%;
    border-radius: 5px;
    // padding: 0 20px 20px 20px;

    @media (max-width: 1024px) {
        width: 50%;
    }

    @media (max-width: 768px) {
        width: 100%;
    }

    @media (max-width: 425px) {
        width: 100%;
        border: none;
    }
}

.completeReservationHeading {
    background: #273e59;
    color: #ffffff;
    padding: 15px 20px;
    font-size: 16px;
    font-weight: bold;
    border-radius: 5px 5px 0 0;

    @media (max-width: 425px) {
        border-radius: 0;
    }
}

.detailsHeading {
    font-size: 14px;
    margin: 10px 0;
    font-weight: bold;
}

.reservationFormDiv {
    padding: 15px 20px 20px 20px;

    @media (max-width: 425px) {
        padding: 15px 0px 20px 0px;
        border: 1px solid #d8dce6;
    }
}

.order-place-main .call-input-moving {
    margin-right: 15px;
    border: 1px solid #d8dce6;
}

.personalDetailsDiv {
    margin-bottom: 25px;

    @media (max-width: 425px) {
        padding: 0 17px;
    }
}

.orderFlexDiv {
    display: flex;
    margin-top: 25px;
    justify-content: space-between;
}

.selectedStorageDiv {
    border: 1px solid #e2e2e2;
    width: 40%;
    height: fit-content;

    @media (max-width: 1024px) {
        width: 48%;
    }

    @media (max-width: 768px) {
        display: none;
    }

    @media (max-width: 425px) {
        display: none;
    }
}

.selectedStotageHeading {
    font-size: 22px;
    font-weight: bold;
    padding: 15px 20px;
    border-bottom: 1px solid #e2e2e2;

    @media (max-width: 1024px) {
        font-size: 20px;
        padding: 15px 10px;
    }

    @media (max-width: 425px) {
        font-size: 16px;
        padding: 15px 10px;
    }
}

.storageDiv {
    padding: 15px 20px 20px 20px;

    @media (max-width: 1024px) {
        padding: 15px 10px;
    }

    @media (max-width: 425px) {
        padding: 15px 10px;
    }
}

.nameHeading {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 10px;

    @media (max-width: 1024px) {
        font-size: 18px;
    }

    @media (max-width: 425px) {
        line-height: 28px;
    }
}

.addressHeading {
    font-size: 16px;

    @media (max-width: 768px) {
        font-size: 14px;
    }
}

.storageDiv .unitCard {
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
    align-items: center;
}

.storageDiv .unitCard .unitCurrencyDiv {
    display: flex;
}

.datepickerDiv {
    display: flex;
    margin-top: 25px;
    justify-content: space-between;

    @media (max-width: 425px) {
        display: block;
    }
}

.moving-main-page .input1 .MuiInputBase-input,
.innerInput1 .MuiInputBase-input {
    border: none;
    // margin-top: 16px;
    background-color: transparent;
}

.moving-main-page .MuiStepper-horizontal {
    display: none;
}

// .moving-main-page .MuiLinearProgress-barColorPrimary {
//     // background-color: #273e59;
//     // background-color: #009cbd;
// }

.moving-main-page .sortBy .MuiInputBase-input {
    border-bottom: 1px solid #bebebe;
    border-radius: 0;
    padding-top: 0;
    padding-bottom: 0;
    background-color: transparent;
}

.moveFooter .footerDashboard {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border: #e2e2e2 1px solid;
    padding: 5px;
    margin-top: 50px;
    z-index: 999;
}

.reservationFormDiv .MuiOutlinedInput-input {
    padding: 27px 12px 10px;
    border: 1px solid #bebebe;
    border-radius: 4px;
}
.reservationFormDiv .call-input-moving.MuiFormControl-root {
    border: 0;
    margin-right: 0;
}
.main-head-moving-order {
    margin: 30px 0 20px;
}
.internetFooter .footerDashboard {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border: #e2e2e2 1px solid;
    padding: 5px;
    margin-top: 50px;
    z-index: 999;
    .footerNameDetails {
        .footerNameDetails1 {
            color: #333333;
            font-size: 16px;
            font-weight: 700;
        }
        .footerNameDetails2 {
            color: #747e8b;
            font-size: 12px;
        }
    }
    .footerNamequestion {
        display: none;
    }
    .footerQuestion {
        color: #333333;
        font-size: 18px;
        font-weight: 500;
        margin: 0 25px;
    }
    .footerCallDetails {
        display: flex;
    }

    @media (max-width: 1024px) {
        .footerQuestion {
            font-size: 16px;
            margin: 0 10px;
        }
    }
    @media (max-width: 960px) {
        .footerNameDetails {
            margin-right: 10px;
        }
        .footerQuestion {
            display: none;
        }
    }
    @media (max-width: 768px) {
        position: fixed;
        margin-top: 10px;
        .footerNamequestion {
            display: block;
            margin-right: 20px;
            .footerNamequestion1 {
                color: #333333;
                font-size: 16px;
                font-weight: 700;
            }
            .footerNamequestion2 {
                color: #747e8b;
                font-size: 12px;
            }
        }
        .footerNameDetails {
            display: none;
        }
        .footerCallDetails {
            display: none;
        }
    }
}

.MovingPageMainImage {
    width: 50%;

    @media (max-width: 1440px) {
        width: 32%;
    }

    @media (max-width: 768px) {
        width: 95%;
    }

    // @media (max-width: 768px) {
    //     width: 525px;
    // }

    @media (max-width: 425px) {
        width: 287px;
        height: 88px;
    }
}

.landingPageMoveMain .headPara {
    @media (max-width: 425px) {
        font-size: 12px;
        line-height: 18px;
    }
}
.movingStepsDouglas {
    .MuiInputLabel-filled.MuiInputLabel-shrink {
        z-index: 1 !important;
        color: #100b28 !important;
    }
    .MuiInputLabel-filled.MuiInputLabel-shrink.Mui-focused {
        color: #100b28 !important;
    }
    .movingBox .MuiFilledInput-root,
    .movingAddressCard .input1 .MuiOutlinedInput-root,
    .movingAddressCard .react-select {
        // border: 1px solid #100b28 !important;
        border-radius: 0px !important;
        color: #323744;
    }

    .movingAddressCard .react-select .select-state__control {
        border: none !important;
    }
    .MuiCheckbox-colorPrimary.Mui-checked {
        color: #009cbd !important;
    }
}
.moving-main-pageDouglas {
    .tab-active {
        color: #009cbd !important;
        border-bottom: 2px solid #009cbd !important;
    }

    .blueTab {
        color: #009cbd !important;
        border-bottom: 3px solid #009cbd !important;
    }
    .roomIcon,
    .main-class-diy .discount-div .discount-value,
    .main-class-diy .body-div .rate-diy {
        color: #009cbd !important;
    }
    .Storage-main-div .tabContent:hover,
    .main-class-diy .body-div .rating-capsule-div {
        border: 1px solid #009cbd !important;
    }
    .reservationFormDiv .MuiOutlinedInput-input {
        background-color: #fff;
        border: 1px solid #100b28 !important;
        border-radius: 0px !important;
        color: #323744;
    }
    .order-place-main .datesInputDiv .MuiInputBase-root {
        border: 1px solid #100b28 !important;
        border-radius: 0px !important;
        color: #323744;
    }
    .black-card-moving {
        background: #100b28 !important;
        padding: 10px 0 10px 20px;
    }
    .brokerageImage {
        // display: none;
    }
    .main-class-diy .body-div .button-know {
        border-radius: 0px !important;
    }
    .main-class-diy .discount-div .tag-div,
    .main-class-diy .body-div .star-diy {
        background: #009cbd !important;
    }
}
.landingPageMove .headPara {
    @media (max-width: 425px) {
        font-size: 12px;
        line-height: 18px;
    }
}

.movingGetStartedButton {
    @media (max-width: 425px) {
        width: 100% !important;
        margin-top: 30px !important;
        position: fixed;
    }
}

.moving_service_button_tabs {
    display: none;

    @media (max-width: 425px) {
        padding: 0 10px 0px 10px;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #d8dce6;
    }
}

.moving_service_button_tab {
    @media (max-width: 425px) {
        font-size: 14px;
        line-height: 24px;
        padding-bottom: 10px;
    }
}

.moving_service_button_tab-active {
    @media (max-width: 425px) {
        font-size: 14px;
        line-height: 24px;
        color: #009cbd;
        font-weight: bold;
        padding-bottom: 10px;
        border-bottom: 2px solid #009cbd;
    }
}

.brokerageImage {
    @media (max-width: 768px) {
        width: 192px;
        height: 73px;
    }

    @media (max-width: 425px) {
        width: 145px;
        height: 73px;
    }
}

.main-card-moving-moving {
    display: none;

    @media (max-width: 425px) {
        display: block;
    }
}

.mobile-card-heading {
    font-size: 20px;
    line-height: 31px;
}

.mobile-card-para {
    width: 60%;
    font-size: 12px;
    line-height: 18px;
    margin: 10px 0 20px 0;
    color: #ffffff;
}

.movingmobile {
    display: flex;
    justify-content: space-between;
}

.longCardFlexDiv {
    display: flex;
    justify-content: flex-start;
    margin-top: 20px;
    // align-items: flex-end;

    @media (max-width: 768px) {
        flex-wrap: wrap;
        padding: 0 20px;
    }

    @media (max-width: 425px) {
        flex-wrap: wrap;
        padding: 0px;
    }
}
.longCardDiv {
    width: 32%;
    margin-right: 2%;

    @media (max-width: 768px) {
        width: 90%;
        margin: 5px 0;
    }

    @media (max-width: 425px) {
        width: 100%;
        margin: 5px 0;
    }
}

.longCard {
    @media (max-width: 768px) {
        padding: 5px !important;
    }
    @media (max-width: 425px) {
        padding: 5px !important;
    }
}

.moving-thankyou-main {
    padding: 20px 80px;

    @media (max-width: 425px) {
        padding: 0px 10px !important;
    }
}

.main-heading-thankyou {
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    margin-top: 20px;
    margin-bottom: 10px;

    @media (max-width: 768px) {
        font-size: 22px;
        margin-top: 40px;
    }
}

.sub-heading-thankyou {
    text-align: center;
    font-size: 16px;
    margin-bottom: 20px;

    @media (max-width: 425px) {
        font-size: 12px;
    }
}
.edit-mobile {
    display: none;

    @media (max-width: 425px) {
        display: block;
        font-size: 12px;
        color: #009cbd;
        float: right;
        margin-top: -45px;
    }

    @media (max-width: 375px) {
        margin-top: -70px;
        margin-left: 20px;
    }
}

.wrapper-2 {
    @media (max-width: 425px) {
        align-items: center;
        margin-left: auto;
        margin-right: auto;
        padding: 0 20px;
    }
}

.what-next {
    @media (max-width: 425px) {
        margin-bottom: 35px !important;
    }
}
.storageFacilityDiv .titleDiv .titleHeader {
    font-size: 22px;

    @media (max-width: 768px) {
        margin-bottom: 10px;
    }

    @media (max-width: 425px) {
        font-size: 12px;
        line-height: 28px;
        margin-bottom: auto;
    }
}

.storageFacilityDiv .editAddressAutoComplete {
    @media (max-width: 425px) {
        font-size: 12px;
        line-height: 28px;
        width: 300px;
    }
}

.storageFacilityDiv .avg-customer-review {
    // text-decoration: underline;
    @media (max-width: 425px) {
        display: flex;
        align-items: center;
        margin-bottom: 0;
    }
}

.storageFacilityDiv .sortIcon {
    display: none;

    @media (max-width: 425px) {
        display: block;
        margin-right: 5px;
    }
}

.storageFacilityDiv .facilityCardsDiv {
    @media (max-width: 425px) {
        padding: 0 10px;
    }
}

.storageFacilityDiv .facilityCard {
    width: 98% !important;

    @media (max-width: 768px) {
        width: 96% !important;
    }

    @media (max-width: 425px) {
        width: 100% !important;
        padding: 0px 0px 20px 0px !important;
    }
}

.Storage-main-div .facilityNameMobile {
    font-size: 22px;
    line-height: 30px;
    color: #333333;
    font-weight: 600;
    display: none;
    margin-top: 20px;

    @media (max-width: 425px) {
        display: block;
        padding: 0 10px;
    }
}

.Storage-main-div .facilityAddressMobile {
    font-size: 16px;
    line-height: 22px;
    color: #6b6c6f;
    margin: 5px 0 0px 0;
    display: none;

    @media (max-width: 425px) {
        display: block;
        padding: 0 10px;
    }
}

.officeHoursDiv {
    width: 50%;

    @media (max-width: 425px) {
        width: 50%;
    }
}

.Storage-main-div .facility-details .selectDiv {
    @media (max-width: 425px) {
        margin-top: 0px;
    }
}

.Storage-main-div .unitCard .unitButtonDiv {
    @media (max-width: 768px) {
        width: 100%;
        text-align: center;
    }
}

.Storage-main-div .unitCard .unitButtonDiv .unitButton {
    @media (max-width: 768px) {
        width: 98% !important;
    }
}

.reserveStorageFacilityDiv {
    @media (max-width: 425px) {
        padding: 0 10px;
    }
}

.reserveStorageFacilityDiv .path {
    @media (max-width: 425px) {
        display: block;
        margin-top: 0px;
    }
}

.reservationFormDiv .orderFlexDiv {
    @media (max-width: 425px) {
        display: block;
    }
}

.reservationFormDiv .MuiFormControl-root {
    @media (max-width: 425px) {
        width: 100% !important;
        margin: 10px 0;
    }
}

.confirmReservationButton {
    width: 190px !important;
    height: 50px !important;

    @media (max-width: 425px) {
        width: 98% !important;
    }
}

.storageDetailsMobile {
    display: none;

    @media (max-width: 768px) {
        display: block;
        border-top: 1px solid lightgrey;
        border-bottom: 1px solid lightgrey;
        padding-top: 20px;
        margin-bottom: 30px;
    }
    // @media (max-width: 425px) {
    //     display: block;
    //     border-top: 1px solid lightgrey;
    //     border-bottom: 1px solid lightgrey;
    //     padding-top: 20px;
    //     margin-bottom: 30px;
    // }
}

.selectedStorageDetailsTitleDiv {
    @media (max-width: 768px) {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        font-size: 16px;
        line-height: 31px;
        font-weight: bold;
    }
}

.storageDetailsMobile .unitCard {
    @media (max-width: 768px) {
        display: flex;
        flex-wrap: wrap;
        margin-top: 20px;
        justify-content: space-between;
        align-items: center;
    }

    @media (max-width: 425px) {
        justify-content: center;
    }
}

.storageDetailsMobile .unitCard .unitCurrencyDiv {
    @media (max-width: 768px) {
        display: flex;
    }
}

.storageDetailsMobile .selectedStorageDetailsContentDiv {
    @media (max-width: 768px) {
        padding-bottom: 20px;
    }
}

.reserveStorage-autocompleteDiv {
    @media (max-width: 425px) {
        width: 100%;
    }
}

.reserveStorageAboutDiv {
    @media (max-width: 425px) {
        display: flex;
        justify-content: space-between;
        padding: 0 10px;
    }
}

.Storage-main-div .image-div .dataDivDesktop {
    display: block;
    @media (max-width: 425px) {
        display: none;
    }
}

.Storage-main-div .image-div .dataDivDesktop .aboutHeading {
    padding: 30px 20px 0;

    @media (max-width: 768px) {
        padding: 20px 10px 0;
    }
}
.Storage-main-div .image-div .dataDivDesktop .aboutDescription {
    padding: 0px 20px;

    @media (max-width: 768px) {
        padding: 0px 10px;
    }
}

.Storage-main-div .image-div .dataDivDesktop .TimingDiv .hoursDiv {
    padding: 0px 20px;

    @media (max-width: 768px) {
        padding: 0px 10px;
    }
}

.Storage-main-div .image-div .dataDivDesktop .officeHoursDiv .hoursDiv,
.Storage-main-div .image-div .dataDivDesktop .accessHoursDiv .hoursDiv {
    padding: 0px;
}

.order-place-main .completeReservationDiv .MuiOutlinedInput-root {
    @media (max-width: 425px) {
        margin-right: 0px;
    }
}

.order-place-main .completeReservationDiv .MuiInputBase-input:focus {
    border: 1px solid #006dff;
}
.moving-main-page {
    .editAddressAutoComplete {
        color: #006dff !important;
        border-bottom: 2px solid #006dff !important;
    }
}
.moving-main-pageDouglas {
    .editAddressAutoComplete {
        color: #009cbd !important;
        border-bottom: 2px solid #009cbd !important;
    }
}

.reserveStorage .moving-main-page {
    .tab-div-moving {
        padding: 10px 10px 0;
    }
    @media (max-width: 425px) {
        padding: 0;
    }
}

.order-place-main .datesInputDiv {
    width: 53%;

    .MuiInputBase-root {
        border: 1px solid #bebebe;
        border-radius: 4px;
    }

    .MuiInput-underline:before,
    .MuiInput-underline:after,
    .MuiInput-underline:hover {
        border-bottom: none;
    }

    .MuiInputBase-input {
        padding: 27px 12px 10px;
    }

    .MuiInputBase-input:focus {
        border: none;
    }

    .MuiInputLabel-formControl {
        transform: translate(14px, 16px) scale(1);
    }

    .MuiInputLabel-shrink {
        transform: translate(14px, 1.5px) scale(0.75);
    }

    label + .MuiInput-formControl {
        margin-top: -7px;
    }

    .MuiInputLabel-root {
        color: #6b6c6f;
    }

    @media (max-width: 1024px) {
        width: 75%;
    }
    @media (max-width: 768px) {
        width: 60%;
    }
    @media (max-width: 425px) {
        width: 92%;
    }
}

.order-place-main .MuiFormLabel-root.Mui-focused {
    color: #006df5;
}

.sortArrow {
    width: 10px;
    height: 10px;
    margin-bottom: 4px;
}

.notAvailableUnitsText {
    text-align: left;
    font-size: 18px;
}

.errorMoving {
    color: red;
    padding-top: 5px;
    // margin-left: 10px;
    text-align: left;
    // margin-bottom: 20px;
    height: 20px;
}

.orderPlacingInputDiv {
    width: 32%;

    @media (max-width: 425px) {
        width: 100%;
    }
}

.call-input .MuiInputBase-input:focus {
    border: 1px solid #009cbd !important;
}

.call-inputnoDoug .MuiInputBase-input:focus {
    border: 1px solid #006dff !important;
}

.call-input .MuiInputLabel-filled.MuiInputLabel-shrink {
    color: #009cbd !important;
}
.call-inputnoDoug .MuiInputLabel-filled.MuiInputLabel-shrink {
    color: #006dff !important;
}
.call-input .MuiInputLabel-root.Mui-focused {
    color: #009cbd !important;
}
.call-inputnoDoug .MuiInputLabel-root.Mui-focused {
    color: #006dff !important;
}
.call-input1 .MuiInputLabel-root.Mui-focused {
    color: #009cbd !important;
}
.call-input1noDoug .MuiInputLabel-root.Mui-focused {
    color: #009cbd !important;
}
.orderPlacingDateInputDiv {
    width: 48%;

    @media (max-width: 425px) {
        width: 98%;
    }
}

.movingModal {
    background: #ffffff;
    width: 50%;
    position: absolute;
    padding: 20px;
    border-radius: 4px;
    // top: 23%;
    // left: 30%;
}

.ModalTitle {
    font-size: 24px;
    line-height: 32px;
    font-weight: bold;
    border-bottom: 1px solid #e2e2e2;
    color: #333333;
    padding: 0 0 10px 0;
}

.ModalSubtitle {
    font-size: 16px;
    line-height: 30px;
    font-weight: bold;
    color: #333333;
    padding: 10px 0;
}

.requirementsDiv {
    display: flex;
    margin: 7px 0;

    .tickIcon {
        margin-right: 10px;
        color: #00d56d;
    }

    .requirementsText {
        font-size: 14px;
        line-height: 24px;
        color: #333333;
    }

    .requirementsTextBold {
        font-weight: bold;
    }
}

.appointment-time-div-2 {
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    justify-content: center;
}

.details-2 {
    font-size: 14px;
    font-weight: bold;
    padding-bottom: 5px;
}

.details-content-2 {
    font-size: 12px;
    font-weight: 600;
    opacity: 0.8;
    // padding-bottom: 20px;
}

.progressStepD .MuiLinearProgress-barColorPrimary {
    background: #009cbd !important;
}
.progressStep .MuiLinearProgress-barColorPrimary {
    background: #006dff !important;
}
// .makeStyles-barColorPrimary-12 {
//     background: #009cbd !important;
// }

@media (max-width: 450px) {
    .details-2 {
        font-size: 12px;
    }
    .appointment-time-div-2 {
        padding: 0px 5px;
    }
    .utilityFooter .footerDashboard .footerNamequestion {
        margin-right: 5px !important;
    }
    .details-content-2 {
        padding-bottom: 10px;
    }
    .AppointmentMobCol {
        flex-direction: column !important;
        align-items: unset !important;
    }
}

.douglasEllimenSite {
    .main-head-2-de {
        margin-top: 22px;
    }
}
