.electricity-card-main {
    border: 1px solid #e2e2e2;
    margin-top: 15px;
    box-shadow: 0px 3px 10px #00000029;
    border-radius: 4px;
    opacity: 1;

    .viewMorePowerKiosk {
        font-size: 12px;
        color: blue;
        text-decoration: underline;
        display: inline;
        margin-left: 10px;
        cursor: pointer;
    }
    @media (max-width: 768px) {
        width: 45%;
        margin-right: 15px;
    }
    @media (max-width: 650px) {
        width: 100%;
        margin-right: 15px;
    }

    .main-body-div-electricity {
        display: flex;
        justify-content: space-between;

        padding: 40px 22px 40px 22px;
        align-items: center;
        .lineDiv {
            display: none;
        }
        @media (max-width: 768px) {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            .lineDiv {
                width: 97%;
                border: 1px solid #d3d3d324;

                display: unset;
                margin: 17px 0px;
            }
        }

        .tag-title-div-electricity {
            width: 20%;
            @media (max-width: 768px) {
                width: 100%;
            }
        }

        .rating-reviews-div-electricity {
            width: 15%;
            @media (max-width: 768px) {
                width: 100%;
            }
        }

        .tag-title-div-electricity,
        .rating-reviews-div-electricity {
            // margin: auto 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        .button-div-suitable {
            @media (max-width: 768px) {
                padding-top: 15px;
            }
            @media (max-width: 630px) {
                width: 100%;
                margin: auto 0;
                padding-top: 15px;
                button {
                    width: 100% !important;
                }
            }
        }
        .tag-div-elec {
            width: 15%;
            // margin: auto 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            @media (max-width: 768px) {
                width: 100%;
            }
        }
        .viewDetailsUtility {
            font-size: 14px;
            color: #006dff;
            font-family: 'Roboto';
        }
        .logo-div-electricity {
            width: 100%;
            height: auto;
            margin: auto;
            margin-bottom: 8px;
        }

        .logo {
            width: 100%;
            height: 100%;
            margin-bottom: 10px;
        }

        .title-div-electricity {
            font-weight: 700;
            font-family: Lato;
            font-size: 15px;
            margin: 0 auto;
        }

        .energy-card-button {
            text-transform: none !important;
            font-size: 13px !important;
            padding: 5px 10px !important;
            width: 100px !important;
        }
        .perKwhValue {
            font-family: 'Roboto';
            font-size: 22px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: left;
        }
        .cancellationValue {
            font-family: 'Roboto';
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: left;
        }
        .perKwh {
            font-family: 'Roboto';
            font-size: 14px;
            font-style: normal;

            line-height: 14px;

            text-align: center;
            color: #6b6c6f;
            padding-top: 10px;
        }
        .contract {
            font-family: 'Roboto';
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: left;
        }
        .margin-dollar {
            margin-right: 2px;
        }
        .margin-dollar-left {
            margin-left: 2px;
        }
    }
}
