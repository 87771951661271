.main-div-amount {
    height: 80px;
    display: flex;
   width:100%;
   margin:20px auto;
    align-items: center;
    .border-line-amount {
        height: 87px;
        border: 1px solid #273e59;
    }
    .main-div-amount-pending {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        background-color: #273e59;
        padding: 11px 0px 12px 12px;
        height: 80px;
        position: relative;
        border: 1px solid #273E59;  
        min-width: 120px;
        overflow-x: scroll;
        overflow-y: hidden;

        &::-webkit-scrollbar-track {
            display: none;
          }
          &::-webkit-scrollbar {
            display: none;
          }
          &::-webkit-scrollbar-thumb {
            display: none;
          }
    }
    .main-div-amount-sub-amount {
        font-family: 'Poppins';
        font-weight: bold;
        font-size: 24px;

        &:before {
            content: "$";
            position: absolute;
            font-size: 10px;
            left: 5px;
        }

        @media(max-width: 480px) {
            font-size: 18px;
        }
    }
  
    .main-div-amount-balance {
        background-color: #e3f9ee;
        padding: 11px 0px 12px 12px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        height: 80px;
        position: relative;
        border: 1px solid #00D56D;
        min-width: 120px;
        overflow-x: scroll;
        overflow-y: hidden;

        &::-webkit-scrollbar-track {
            display: none;
          }
          &::-webkit-scrollbar {
            display: none;
          }
          &::-webkit-scrollbar-thumb {
            display: none;
          }
    }
    .main-div-amount-sub-head {
        font-family: 'Roboto';
        font-weight: 600;
        font-size: 12px;
        width: fit-content;

        @media(max-width: 480px) {
          width: 100px;
        }
        .tooltipCover {
            padding: 20px 10px;
            width: 400px;
            z-index: 9999;
        }
    }
   
}
