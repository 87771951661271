.card-heading {
    color: #333333;
    font-size: 22px;
    font: Roboto;
    padding: 22px 18px;
}

.divider {
    border-bottom: 1px solid #d8dce6;
}

.insuranceQuote .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 22px 18px;
}

.value {
    font-size: 16px;
    color: #333333;
}

.terms-and-condition {
    font-size: 12px;
    color: #6b6c6f;
    padding: 22px 18px;
}
