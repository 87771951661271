.mainDashboardBottom {
    padding: 0px 100px;
    .slick-prev {
        left: -2% !important;
        z-index: 1;
    }
    .slick-prev::before {
        color: #666;
    }
    .slick-next {
        right: -2% !important;
        z-index: 1;
    }
    .slick-next::before {
        color: #666;;
    }
    .slick-track {
        margin-left:0;
    }
    .dashboardBlocks {
        margin: 35px 0;

        .blocksTitleDiv {
            display: flex;
            align-items: center;

            .blocksIconRed {
                color: red;
                margin-right: 13px;
                font-size: 35px;

                @media (max-width: 1024px) {
                    font-size: 23px;
                    margin-right: 8px;
                }
            }
            .blocksIconGrey {
                color: #6b6c6f;
                margin-right: 13px;
                font-size: 35px;
            }
            .blocksTitle {
                font-size: 24px;
                line-height: 32px;
                font-weight: bold;
                margin-left: 10px;
                color: #333333;

                @media (max-width: 1024px) {
                    font-size: 18px;
                    line-height: 24px;
                }
            }
        }
        .homeserviceCardFlexDiv1 {
            margin-top: 20px;
            // display: flex;
            // justify-content: space-between;
            background-color: #ffffff;

            .homeServiceCardDiv {
                width: 32%;
                border: 1px solid #dadce0;
                margin-right: 14px;
                @media (max-height: 600px) {
                    margin-right: 0px;
                }

                .homeServiceCardBottomDiv {
                    // height: 82%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    // border: 1px solid #dadce0;
                    padding-bottom: 20px;

                    .buttonDivhomeOwnerDashboard {
                        display: flex;
                        justify-content: center;

                        @media (max-width: 1024px) {
                            .buttonhomeOwnerDashboard {
                                height: 35px !important;
                                font-size: 12px !important;
                                width: 130px !important;
                            }
                        }
                    }

                    @media (max-width: 768px) {
                        padding-bottom: 10px;
                    }
                }

                @media (max-width: 600px) {
                    width: 100%;
                    margin-bottom: 10px;
                }
            }
            @media (max-width: 600px) {
                flex-wrap: wrap;
            }

            &.ownerDashboardServicesCardCover {

                .slick-slider {

                    .slick-list {
                      width: 96%;
                      margin: 0 auto;

                      @media(max-width: 480px) {
                        width: 90%;
                      }
                    }
                    .slick-prev, .slick-next {
                        &:before {
                            font-size: 26px;
                        }
                    }
                    .slick-next {
                        right: 0 !important;
                    }
                    .slick-prev {
                        left: 0 !important;
                    }
                }

                .CustomServiceCardMain {
                    height: 255px;
                    margin: 0 5px !important;

                    @media(max-width: 600px) {
                        margin: 0 10px !important;
                    }

                    .image {
                        padding: 20px 0px 10px;

                        .imageHomeService {
                            max-height: 60px;
                        }
                    }

                    .titleHomeService {
                        height: 53px;
                    }

                    .buttonDivHomeService {
                        button {
                            font-weight: 600;
                            font-size: 14px;
                            color: #222222;
                            width: 113px;
                            height: 38px;
                            text-transform: capitalize;

                            &:hover {
                                background-color: transparent !important;
                                opacity: 0.8;
                            }
                        }
                    }

                    @media(max-width: 1024px) {
                        height: 239px;
                    }
                    @media(max-width: 768px) {
                        height: 208px;
                    }

                    @media(max-width: 600px) {
                        height: 133px;
                        .titleHomeService {
                            height: auto;
                        }
                    }
                }
            }
        }

        .homeserviceCardFlexDiv {
            margin-top: 20px;
            display: flex;
            justify-content: space-between;
            background-color: #ffffff;

            .homeServiceCardDiv {
                width: 32%;
                border: 1px solid #dadce0;
                margin-right: 10px;
                @media (max-height: 600px) {
                    margin-right: 0px;
                }

                .homeServiceCardBottomDiv {
                    // height: 82%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    // border: 1px solid #dadce0;
                    padding-bottom: 20px;

                    .buttonDivhomeOwnerDashboard {
                        display: flex;
                        justify-content: center;

                        @media (max-width: 1024px) {
                            .buttonhomeOwnerDashboard {
                                height: 35px !important;
                                font-size: 12px !important;
                                width: 130px !important;
                            }
                        }
                    }

                    @media (max-width: 768px) {
                        padding-bottom: 10px;
                    }
                }

                @media (max-width: 600px) {
                    width: 100%;
                    margin-bottom: 10px;
                }
            }
            @media (max-width: 600px) {
                flex-wrap: wrap;
            }
        }

        .upcomingTaskFlexDiv {
            display: flex;
            justify-content: space-between;
            margin: 20px 0;
            color: #333333;
            background-color: #ffffff;

            .upcomingTaskLeftDiv {
                width: 49%;

                @media (max-width: 600px) {
                    width: 100%;
                    margin-bottom: 12px;
                }
            }
            .upcomingTaskRightDiv {
                width: 49%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                @media (max-width: 600px) {
                    width: 100%;
                }
            }

            @media (max-width: 600px) {
                flex-wrap: wrap;
            }
        }

        @media (max-width: 600px) {
            margin: 15px 0;
        }
    }
    @media (max-width: 1024px) {
        padding: 0px 60px;
    }
    @media (max-width: 768px) {
        padding: 0 60px 40px 60px;
    }
    @media (max-width: 600px) {
        padding: 0 10px 40px 10px;
    }
}
