@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');

@font-face {
    font-family: 'Lato';
    src: local('Lato'), url('../Assets/font/Lato/Lato-Regular.ttf') format('ttf');
}

.slider {
    margin-top: -30px;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
}

.arrowIconInsurance {
    height: 40px;
    width: 40px;
}

.rangeslider {
    background: #627f9f !important;
}

.core-subheading-insurance {
    font-size: 16px;
    font-weight: lighter;
    line-height: 23px;
    font-family: 'Lato';
    letter-spacing: 1px;
    opacity: 80%;
}

.rangeslider-horizontal .rangeslider__handle {
    display: none !important;
}
.rangeslider-horizontal .rangeslider__handle:after {
    display: none;
}
.rangeslider-horizontal .rangeslider__fill {
    background-color: #ffffff !important;
}

.main-insurance {
    padding: 0 !important;
    margin: 0 !important;
    width: 100%;
    height: 100%;
}
.arrows {
    margin-top: 190px !important;
}

.arrow-wrapper {
    position: relative;
    text-align: center;
    left: 6%;
    right: 6%;
    width: 10%;
    margin-left: auto;
    margin-right: auto;
}
.how-it-works-tab-mobile-insurance {
    display: none;
}

.provider.accordion-insurance .MuiTypography-body1 {
    text-align: left !important;
}

.iIcon-div {
    margin-top: 10px;
    margin-left: 5px;
}
.carousel-2 {
    display: none;
}

.mobile-div {
    display: none;
}
.schedule-img-card {
    padding-left: 4%;
}

.insurance-header-links {
    text-decoration: none;
    font-size: 14px;
    font-family: 'Lato', sans-serif;
    font-weight: bold;
}

.navLinkFlexDiv-insurance {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

/* .width-1 {
    width: 40%;
    margin-right: 10%;
} */

.width-2 {
    width: 60%;
    display: flex;
    align-items: center;
}

.core-heading-insurance {
    font-size: 20px;
    font-weight: bold;
    font-family: 'lato';
    color: #333333;
}

.taken-insurance-1 {
    font-size: 24px;
    font-weight: bold;
}

.taken-insurance-2 {
    font-size: 24px;
    font-weight: bold;
}

.landingHeader-insurance {
    padding: 15px 100px;
    box-shadow: 0px 4px 4px #eee;
    position: sticky;
    top: 0;
    z-index: 100;
    background: #fff;
    font-family: 'Lato', sans-serif;
    /* margin-top: -180px; */
}

.landingGetQuoteButton-insurance {
    width: 120px !important;
    height: 45px !important;
    font-size: 14px !important;
    font-family: 'Lato', sans-serif !important;
    font-weight: bold !important;
    line-height: 24px !important;
    background-color: #30ab86 !important;
    border-radius: 4px !important;
    margin-top: 10px !important;
}

.insurance-li {
    list-style: none;
    padding: 10px 16px;
    margin: 0 15px;
    cursor: pointer;
    font-family: 'Lato', sans-serif;
}
.insuranceLanding-main-page {
    margin-top: -38%;
    padding-left: 120px;
    display: flex;
    flex-direction: row;
    /* box-shadow: 0px -10px 10px #eee inset; */
    font-family: 'Lato', sans-serif;
}

.landingHeaderButton-insurance {
    font-size: 14px !important;
    width: 116px !important;
    height: 45px !important;
    border-radius: 4px !important;
    background-color: #30ab86 !important;
    font-family: 'Lato', sans-serif;
    margin-left: 45px !important;
}
.banner-image img {
    width: 100%;
    /* height: 82vh; */
}

.quote-button {
    font-weight: bold;
    padding: 20px 20px;
    border-radius: 4px;
}

.logo-container-insurance {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: 'Lato', sans-serif;
    /* margin-left: 4%; */
}

/* .insurance-nav-div{
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    
} */

.logo-container-insurance img {
    width: 142px;
    height: 34px;
}

.phone-insurance {
    color: #0066f5;
    font-size: 16px;
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    font-family: 'Lato', sans-serif;
    display: flex;
    flex-direction: row;
    margin-top: 10px;
}
.phone-number {
    margin-left: 10px;
    margin-top: 6px;
}
.phone-icon-insurance .MuiSvgIcon-root {
    /* margin-bottom: -6px; */
    font-family: 'Lato', sans-serif;
}

.you-deserve-text-insurance,
.wfh-insurance {
    color: #333333;
    font-size: 56px;
    text-align: left;
    font-family: 'Lato', sans-serif;
    font-weight: 900;
    line-height: 80px;
    text-transform: capitalize;
}

.collectionCard-insurance {
    width: 32% !important;
    height: 320px !important;
    padding: 35px !important;
    margin: 0 10px;
    font-family: 'Lato', sans-serif !important;
}

.collectionCardImg-insurance {
    width: 150px !important;
    height: 135px !important;
    margin: 0 !important;
}

.wfh-insurance {
    color: #30ab86;
}

.header-desc-insurance {
    width: 50%;
    font-size: 16px;
    font-family: 'Lato', sans-serif;
    text-align: left;
    color: #333333;
    font-weight: 300;
    line-height: 28px;
    opacity: 07;
}

.worlds-insurance {
    margin-top: 12%;
    text-align: center;
    padding-top: 120px;
    padding-bottom: 80px;
    background-color: #f5faf8;
    font-family: 'Lato', sans-serif;
}
.how-it-works-wrapper-insurance {
    background: url('../Assets/images/InsuranceImages/howItWorksBackground.png');
    text-align: center;
    padding-top: 70px;
    padding-bottom: 50px;
    background-color: #f5faf8;
    font-family: 'Lato', sans-serif;
}
.side-image {
    /* margin-left:10% */
}
.worlds-heading-insurance {
    font-weight: 900;
    color: #333333;
    font-size: 30px;
    line-height: 45px;
    letter-spacing: 0.9px;
    font-family: 'Lato', sans-serif;
    text-transform: capitalize;
    margin-top: 60px;
}
.worlds-para-insurance {
    color: #333333;
    font-size: 16px;
    font-family: 'Lato', sans-serif;
    width: 28%;
    font-weight: 300;
    line-height: 28px;
    opacity: 0.7;
    margin: 12px auto;
}
.main-div {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
}
.div-1-insurance {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
}
.div-2-insurance {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;
    margin-top: 20px;
}

.how-it-works-card-div-insurance {
    width: 40%;
    padding: 50px 80px 0 50px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.card-img-wrapper-insurance,
.card-wrapper,
.active-card {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

/* .card-img-wrapper-insurance {
    padding-top: 10px;
} */

.card-img-wrapper-insurance {
    padding-top: 60px;
}

.call-wrapper-insurance {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 60px 180px 60px 60px;
}

.card-wrapper,
.active-card {
    padding: 24px 12px;
    margin-bottom: 16px;
    cursor: pointer;
    width: 400px;
    margin-left: 29%;
}

.core-features-heading {
    text-align: center;
    font-size: 30px;
    font-weight: bold;
    font-family: 'Lato', sans-serif;
    /* margin-top: -2%; */
    color: #333333;
}

.core-features {
    margin-top: 60px;
}

.core-features-para {
    text-align: center;
    font-family: 'Lato', sans-serif;
    margin-top: 1%;
    width: 40%;
    padding-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    font-size: 16px;
}

.insurance-card-heading,
.insurance-card-description {
    text-align: left;
    color: #333333;
    font-size: 22px;
    line-height: 28px;
    font-family: 'Lato', sans-serif;
    font-weight: bold;
}

.insurance-card-description {
    font-size: 16px;
    font-weight: lighter;
    opacity: 0.8;
    margin-top: 10px;
    font-family: 'Lato', sans-serif;
}

.serial {
    color: #aaaaaa;
    font-size: 20px;
    border: 1px solid #aaaaaa;
    background-color: #ffffff;
    border-radius: 50%;
    line-height: 30px;
    height: 24px;
    padding: 0px 10px 6px 9px;
    margin-top: 6px;
    font-family: 'Lato', sans-serif;
}
.how-it-works-card-div-insurance .card-wrapper,
.how-it-works-card-div-insurance .active-card {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.how-it-works-wrapper-insurance .card-img-wrapper-insurance {
    padding-top: 10px;
}

.card-img-wrapper-insurance {
    padding-top: 60px;
}

.how-it-works-card-div-insurance .card-wrapper,
.how-it-works-card-div-insurance .active-card {
    padding: 24px 12px;
    margin-top: 16px;
    cursor: pointer;
    width: 400px;
    /* height: auto; */
}

.how-it-works-card-div-insurance .linearProgressLanding-insurance {
    margin-top: -17px;
    margin-bottom: 17px;
    /* width: 72%; */
    width: 420px;
}

.how-it-works-card-div-insurance .progressBarFlexDiv-insurance {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.how-it-works-card-div-insurance .MuiLinearProgress-barColorPrimary {
    background-color: #30ab86;
}

.how-it-works-card-div-insurance .card-heading,
.card-desc-insurance,
.card-desc {
    text-align: left;
    color: #333333;
    font-size: 22px;
    line-height: 28px;
    font-family: 'Lato', sans-serif;
    font-weight: bold;
}

.how-it-works-card-div-insurance .card-heading {
    padding: 0px;
}

.card-desc-insurance,
.card-desc {
    font-size: 16px;
    font-weight: lighter;
    opacity: 0.8;
    margin-top: 10px;
    font-family: 'Lato', sans-serif;
}

.sub-card img {
    height: 100px;
}

.how-it-works-card-div-insurance .serial {
    color: #aaaaaa;
    font-size: 20px;
    border: 1px solid #aaaaaa;
    background-color: #ffffff;
    border-radius: 50%;
    line-height: 30px;
    height: 24px;
    padding: 0px 10px 6px 9px;
    margin-top: 0px;
    font-family: 'Lato', sans-serif;
}

.how-it-works-card-div-insurance .card-wrapper:hover {
    background-color: #fff;
}

.how-it-works-card-div-insurance .card-wrapper:hover .how-it-works-card-div-insurance .serial {
    background-color: #fff;
}

.how-it-works-card-div-insurance .active-card {
    background-color: #fff;
    border-bottom: none;
    position: relative;
}

/* .how-it-works-card-div-insurance .active-card::after {
    content: '';
    width: 60%;
    position: absolute;
    bottom: 0;
    left: 0;
    border-bottom: 2px solid #30ab86;
} */

.how-it-works-card-div-insurance .active-serial {
    background-color: #30ab86;
    color: #fff;
    font-size: 20px;
    line-height: 30px;
    border: 1px solid #aaaaaa;
    border-radius: 50%;
    height: 24px;
    padding: 0px 10px 6px 9px;
    margin-top: 0px;
    font-family: 'Lato', sans-serif;
}
.card-wrapper:hover {
    background-color: #fff;
}

.card-wrapper:hover .serial {
    background-color: #fff;
}

.active-card {
    background-color: #fff;
    /* border-bottom: 2px solid #30ab86; */
    position: relative;
}

.active-card::after {
    content: '';
    width: 60%;
    position: absolute;
    bottom: 0;
    left: 0;
    /* border-bottom: 2px solid #30ab86; */
}

.active-serial {
    background-color: #30ab86;
    color: #fff;
    font-size: 20px;
    line-height: 30px;
    border: 1px solid #aaaaaa;
    border-radius: 50%;
    height: 24px;
    padding: 0px 10px 6px 9px;
    margin-top: 6px;
    font-family: 'Lato', sans-serif;
}

.collection-insurance {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    padding: 50px 0;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}

.collectionCard-insurance {
    width: 32% !important;
    height: 320px !important;
    padding: 35px !important;
    margin: 0 10px;
    font-family: 'Lato', sans-serif !important;
}

.MuiTab-wrapper {
    text-transform: capitalize;
    font-family: 'Lato', sans-serif;
}
.MuiTabs-flexContainer {
    border-bottom: 1px solid lightgray;
}

.collectionCardImg-insurance {
    width: 150px !important;
    height: 135px !important;
    margin: 0 !important;
}

.carousel {
    /* margin-top: 50px; */
    padding-bottom: 50px;
    padding-top: 35px;
    margin-left: auto;
    margin-right: auto;
    background: #415b76;
    text-align: center;
    font-family: 'Lato', sans-serif;
}

.review {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
}

.carousel-div-insurance {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.questions {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    padding-top: 60px;
    font-family: 'Lato', sans-serif;
}

.frequently-insurance {
    font-size: 24px;
    font-weight: bold !important;
    padding-bottom: 20px;
    font-family: 'Lato', sans-serif;
}

.heading-insurance {
    font-size: 26px;
    font-weight: bold;
    padding-bottom: 20px;
    font-family: 'Lato', sans-serif;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    line-height: 30px;
    color: #333333;
}
.heading2-insurance {
    font-size: 30px;
    line-height: 45px;
    letter-spacing: 0.9px;
    font-weight: bold;
    padding-top: 15px;
    color: #ffffff;
    width: 30%;
    margin-left: auto;
    margin-right: auto;
    font-family: 'Lato', sans-serif;
}
.accordion-insurance {
    margin-bottom: 10px;
}

.address {
    margin-top: 50px;
    width: 34%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    padding-bottom: 50px;
    font-family: 'Lato', sans-serif;
}
.provider2-image {
    width: 143px;
    /* height: 48px; */
}

.addressCard {
    padding: 0px 100px 0px 100px;
    display: flex;
    justify-content: center;
}

.address-wrapper-insurance .boxCard-insurance {
    background-color: #ffffff;
    text-align: left;
    width: 488px;
    margin-left: auto;
    margin-right: auto;
    height: auto;
    box-shadow: 0px 3px 10px #00000029;
    border: 1px solid #e2e2e2;
    border-radius: 4px;
    padding: 20px 20px 30px 20px;
    display: flex;
    flex-direction: column;
}

.address-wrapper-insurance {
    margin: 10px 0px 0px 0px;
    font-family: 'Lato', sans-serif;
    font-size: 12px;
    line-height: 22px;
    color: #6b6c6f;
}
.Para1 {
    margin: 18px 0px 0px 0px;
    font-family: 'Lato', sans-serif;
    font-size: 12px;
    line-height: 22px;
    color: #6b6c6f;
}

.address-wrapper-insurance .MuiOutlinedInput-notchedOutline {
    border: none;
}

.address-wrapper-insurance .input1-insuranceLanding .MuiInputBase-input {
    border-radius: 5px;
    text-align: left;
    border: 1px solid #bebebe;
    background-color: #fff;
    /* width: 450px; */
    width: 100%;
}
.address-wrapper-insurance .input2-insuranceLanding .MuiInputBase-input,
.address-wrapper-insurance .input3-insuranceLanding .MuiInputBase-input,
.address-wrapper-insurance .input4-insuranceLanding .MuiInputBase-input {
    border-radius: 5px;
    text-align: left;
    border: 1px solid #bebebe;
    background-color: #fff;
    /* width: 115px; */
    width: 100%;
}

.address-wrapper-insurance .input1-insuranceLanding .MuiInputBase-input:focus,
.address-wrapper-insurance .input2-insuranceLanding .MuiInputBase-input:focus,
.address-wrapper-insurance .input3-insuranceLanding .MuiInputBase-input:focus,
.address-wrapper-insurance .input4-insuranceLanding .MuiInputBase-input:focus {
    border-radius: 5px;
    text-align: left;
    border: 1px solid #006dff;
    background-color: #fff;
    /* width: 115px; */
    width: 100%;
}

.address-wrapper-insurance .input1-insuranceLanding .Mui-error .MuiInputBase-input,
.address-wrapper-insurance .input2-insuranceLanding .Mui-error .MuiInputBase-input,
.address-wrapper-insurance .input3-insuranceLanding .Mui-error .MuiInputBase-input,
.address-wrapper-insurance .input4-insuranceLanding .Mui-error .MuiInputBase-input {
    border-radius: 5px;
    text-align: left;
    border: 1px solid red;
    background-color: #fff;
    padding: 27px 12px 10px;
}

.address-wrapper-insurance .MuiFormControl-root {
    width: 100%;
}

.address-wrapper-insurance .MuiInputLabel-outlined.MuiInputLabel-shrink {
    margin-top: 7px;
}

.innerInputs-insurance {
    display: flex;
    margin-top: 20px !important;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 15px;
}

.div-input {
    width: 32%;
}

.MuiOutlinedInput-root {
    margin-right: 0px !important;
    height: 50px !important;
}

.inputs-insurance {
    margin-top: 10px;
}

.inputs-insurance .MuiInputLabel-filled.MuiInputLabel-shrink {
    z-index: 1 !important;
    color: #b3b3b3;
}

.inputs-insurance .MuiInputLabel-filled.MuiInputLabel-shrink.Mui-focused,
.innerInputs-insurance .MuiInputLabel-outlined.MuiInputLabel-shrink.Mui-focused {
    z-index: 10 !important;
    color: #006dff;
}
/* .address-wrapper-insurance .input1-insuranceLanding .MuiOutlinedInput-root {
    width: 440px !important;
} */

/* .address-wrapper-insurance .input2-insuranceLanding .MuiOutlinedInput-root {
    width: 170px !important;
} */

/* 
.address-wrapper-insurance .input4-insuranceLanding .MuiOutlinedInput-root {
    width: 150px !important;
} */
.checkbox .MuiSvgIcon-root {
    fill: 'red' !important;
}

.call-now-insurance {
    padding-top: 39px;
    font-size: 20px;
    color: #0066f5;
    font-weight: bold;
    font-family: 'Lato', sans-serif;
}

.week-time {
    display: flex;
    flex-direction: row;
}

.day-insurance,
.time-insurance {
    color: #333333;
    font-size: 16px;
    font-weight: bold;
    font-family: 'Lato', sans-serif;
    width: 30%;
    padding-bottom: 12px;
}

.time {
    font-weight: lighter;
    width: 40%;
    /* padding-left: 2%; */
    font-family: 'Lato', sans-serif;
}

.Carousel-button-15 {
    top: calc(50% - 20px) !important;
    color: white;
    cursor: pointer;
    margin: 0 10px;
    position: relative;
    font-size: 30px;
    transition: 200ms;
    /* background-color: #494949; */
}

.Carousel-buttonHidden-14 {
    opacity: 1;
}
.carousel-inner-insurance .MuiCardContent-root {
    padding: 24px;
}

.MuiTab-textColorInherit.Mui-selected {
    color: #0066f5 !important;
    text-transform: lowercase;
}

.MuiTab-textColorInherit {
    color: #333333 !important;
    text-transform: lowercase;
}

.MuiTab-textColorInherit.Mui-selected {
    color: #0066f5 !important;
}

.MuiTab-textColorInherit {
    color: #333333 !important;
    text-transform: lowercase !important;
}

.compare-section {
    background: #f5faf8;
    padding: 60px 0;
}

.carousel-inner-insurance {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.Carousel-fullHeightHoverWrapper-21 {
    top: 0%;
    height: 100%;
}
/* .Carousel-prev-18{
    left:-72px !important;
    position: relative !important;
    top:-17px !important;
}

.Carousel-next-17{
    position:relative !important;
    top:22px !important
   
} */

/* .auto-cards {
    margin-left: 10%;
} */

.address-wrapper-insurance {
    background: url('../Assets/images/helpCenterCTA.svg');
    padding: 20px 0;
}

.core-feature-upper-div {
    margin-top: 20px;
}

@media (max-width: 1536px) {
    .worlds-heading-insurance {
        margin-top: 32px;
    }

    .side-image {
        /* margin-left: 6%; */
    }

    .moving-auto-image {
        width: 800px;
    }
}

@media (max-width: 1440px) {
    .sub-card img {
        height: 75px;
    }
    .insurance-li {
        margin: 0;
    }
    .side-image img {
        /* margin-left: -6.5%; */
    }
    .collectionCardImg-insurance {
        width: 100px !important;
        height: 95px !important;
    }
    /* 
    .auto-cards {
        margin-left: 0;
    } */

    .provider2-image {
        width: 90px;
        /* height: 40px; */
    }
}

@media (max-width: 1366px) {
    .arrows {
        margin-top: 118px !important;
    }
    .collectionCard-insurance {
        padding: 20px !important;
        height: 290px ​ !important;
    }

    .collectionCardImg-insurance {
        width: 85px !important;
        height: 80px !important;
    }
    .worlds-heading-insurance {
        margin-top: 20px;
    }
    .side-image img {
        margin-top: -60px;
        margin-left: -50px;
        height: 600px;
        width: 650px;
    }

    /* .auto-cards {
        margin-left: 0px;
        width: 500px;
        height: 120px;
        margin-bottom: 40px;
    } */

    /* .width-1 {
        width: 40%;
        margin-top: -80px;
    } */
    .width-2 {
        width: 60%;
    }
    .provider2-image {
        width: 85px;
        /* height: 40px; */
    }

    .card-wrapper,
    .active-card {
        padding: 15px 12px 24px 12px;
        margin-bottom: 25px;
        cursor: pointer;
        width: 400px;
        margin-left: 18%;
        height: 120px;
    }

    .insurance-card-heading {
        font-size: 18px;
        line-height: 20px;
    }

    .insurance-card-description {
        font-size: 14px;
        font-weight: lighter;
        margin-top: 10px;
    }
    .how-it-works-card-div-insurance .linearProgressLanding-insurance {
        margin-top: -25px;
        margin-bottom: 0px;
    }
}

@media (max-width: 1280px) {
    .you-deserve-text-insurance,
    .wfh-insurance {
        font-size: 45px;
    }
    .you-deserve-text-insurance {
        margin-top: 15px;
    }

    .provider2-image {
        width: 80px;
        /* height: 37px; */
    }
    .worlds-insurance {
        margin-top: 35px;
    }
}

@media (max-width: 1200px) {
    .insurance-header-links {
        font-size: 12px;
    }
    .worlds-insurance {
        margin-top: 12px;
    }
}

@media (max-width: 1100px) {
    .insurance-li {
        padding: 5px 7px;
        margin: 0 5px;
    }

    .phone-insurance {
        font-size: 13px;
    }

    .Head-space {
        margin-top: -20px;
    }

    .header-desc-insurance {
        font-size: 14px;
    }

    .you-deserve-text-insurance,
    .wfh-insurance {
        font-size: 35px;
    }
    /* 
    .auto-cards {
        width: 350px;
        height: 100px;
    } */
    .card-wrapper,
    .active-card {
        padding: 15px 12px 24px 12px;
        margin-bottom: 25px;
        cursor: pointer;
        width: 320px;
        margin-left: 10%;
        height: 110px;
    }

    .insurance-card-heading {
        font-size: 16px;
        line-height: 20px;
    }

    .insurance-card-description {
        font-size: 12px;
        font-weight: lighter;
        margin-top: 10px;
    }
}

@media (max-width: 1024px) {
    .sub-card img {
        height: 60px;
    }
    .core-heading-insurance {
        font-size: 18px;
    }
    .core-subheading-insurance {
        line-height: 15px;
        font-size: 14px;
    }

    .collectionCardImg-insurance {
        width: 61px !important;
        height: 50px !important;
    }

    .collectionCard-insurance {
        height: 190px !important;
        padding: 10px !important;
    }

    .side-image img {
        width: 530px;
    }

    .landingHeader-insurance {
        padding: 6px 22px;
    }

    .phone-insurance {
        font-size: 12px;
    }

    .innerInputs-insurance {
        margin-left: 8px;
    }

    .phone-icon-insurance .MuiSvgIcon-root {
        height: 20px;
        width: 20px;
        margin-left: 10px;
        margin-bottom: 0px !important;
    }

    .insurance-li {
        padding: 5px 7px;
        margin: 0 5px;
    }

    .landingHeaderButton-insurance {
        font-size: 8px !important;
        width: 76px !important;
        height: 25px !important;
        border-radius: 2px !important;
    }
    .worlds-insurance {
        margin-top: -40px;
    }
    .worlds-para-insurance {
        font-size: 12px;
        line-height: 15px;
    }

    .worlds-heading-insurance {
        font-size: 26px;
    }

    .provider2-image {
        width: 75px;
        /* height: 30px; */
    }

    .card-desc-insurance {
        margin-top: -8px;
        font-size: 13px;
    }

    .call-now-insurance {
        font-size: 16px;
    }

    .time-insurance {
        padding-left: 20%;
        width: 68%;
    }
    .day-insurance {
        font-size: 14px;
        width: 40%;
    }

    .taken-insurance-1 {
        font-size: 20px;
        margin-bottom: 5px;
    }

    .taken-insurance-2 {
        font-size: 20px;
    }

    .heading2-insurance {
        font-size: 20px;
        line-height: 30px;
    }

    .carousel-div-insurance {
        margin-top: -30px;
    }

    .heading-insurance {
        font-size: 18px;
    }
    .address-wrapper-insurance .boxCard-insurance {
        width: 440px;
        margin-left: -60px;
    }
    /* .address-wrapper-insurance .input1-insuranceLanding .MuiInputBase-input {
        width: 410px;
    }
    .address-wrapper-insurance .input2-insuranceLanding .MuiInputBase-input,
    .address-wrapper-insurance .input3-insuranceLanding .MuiInputBase-input,
    .address-wrapper-insurance .input4-insuranceLanding .MuiInputBase-input {
        width: 104px;
    } */
}

@media (max-width: 768px) {
    .innerInputs-insurance {
        margin-left: 0px;
    }
    .core-heading-insurance {
        font-size: 15px;
    }
    .core-subheading-insurance {
        line-height: 14px;
        font-size: 10px;
    }
    .arrows {
        margin-top: 120px !important;
    }

    .arrow-wrapper {
        left: 8%;
        right: 8%;
    }
    .address {
        width: 80% !important;
        margin-top: 20px;
    }

    .innerInputs-insurance {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 20px !important;
        align-items: center;
    }

    /* .address-wrapper-insurance .input2-insuranceLanding .MuiInputBase-input,
    .address-wrapper-insurance .input3-insuranceLanding .MuiInputBase-input,
    .address-wrapper-insurance .input4-insuranceLanding .MuiInputBase-input {
        width: 60px !important;
    } */

    .address-wrapper-insurance .input3-insurance-insuranceLanding .MuiOutlinedInput-root {
        margin-left: auto;
        margin-right: auto;
    }

    .collectionCardImg-insurance {
        width: 51px !important;
        height: 40px !important;
    }

    .collectionCard-insurance {
        margin: 0 7px;
        height: 176px !important;
        padding: 5px !important;
    }
    .side-image img {
        width: 380px;
        height: 450px;
    }

    .insurance-header-links {
        font-size: 9px;
    }
    .insurance-li {
        padding: 5px 5px;
        margin: 0 0px;
    }
    /* .logo-container-insurance img {
        width: 88px;
        height: 20px;
    } */

    .phone-insurance {
        font-size: 9px;
    }

    .landingHeaderButton-insurance {
        width: 65px !important;
    }

    .button-button {
        margin-right: 20px;
        margin-top: 10px;
    }

    .phone-icon-insurance .MuiSvgIcon-root {
        height: 16px;
        width: 16px;
        margin-right: 10px;
    }
    .you-deserve-text-insurance,
    .wfh-insurance {
        font-size: 28px;
        margin-top: -17px;
    }
    .header-desc-insurance {
        font-size: 13px;
        line-height: 20px;
        margin-top: -15px;
    }

    .landingGetQuoteButton-insurance {
        width: 90px !important;
        height: 30px !important;
        font-size: 12px !important;
        line-height: 20px !important;
    }

    .insuranceLanding-main-page {
        padding-left: 50px !important;
    }

    .worlds-heading-insurance {
        font-size: 24px;
        /* margin-top: -20px; */
    }
    .worlds-para-insurance {
        width: 35%;
    }

    .div-1-insurance {
        width: 60%;
    }

    .div-2-insurance {
        width: 70%;
    }
    .provider2-image {
        width: 66px;
        /* height: 27px; */
    }
    .collection-insurance {
        padding: 10px 0;
    }
    .insurance-card-heading,
    .insurance-card-description {
        font-size: 14px;
        line-height: 14px;
    }

    .insurance-card-description {
        font-size: 10px;
        margin-top: 8px;
    }

    .card-wrapper .active-card {
        padding: 8px 8px;
        padding-bottom: 10px !important;
        width: 300px !important;
        height: 100px !important;
    }

    .carousel-inner-insurance {
        margin-top: 20px;
    }

    .phone-number {
        margin-top: 10px;
        font-size: 7px;
    }
    .phone-icon-insurance .MuiSvgIcon-root {
        margin-left: 6px;
        margin-right: 10px;
        margin-bottom: 10px !important;
    }
    .call-now-insurance {
        font-size: 12px;
        padding-top: 20px;
    }

    .core-features-heading {
        font-size: 24px;
    }
    .core-features-para {
        font-size: 12px;
    }

    .core-image {
        width: 50px;
        height: 50px;
    }

    .heading2-insurance {
        font-size: 20px;
    }
    .review-image {
        height: 60px;
        width: 60px;
    }

    .frequently-insurance {
        font-size: 24px;
    }
    .accordion-insurance .MuiTypography-body1 {
        font-size: 13px;
        text-align: left !important;
    }
    .heading-insurance {
        font-size: 15px;
    }
    .address-wrapper-insurance .boxCard-insurance {
        width: 317px !important;
        padding: 10px 15px 15px 15px;
        margin-left: auto;
        margin-right: auto;
    }

    /* .address-wrapper-insurance .input1-insuranceLanding .MuiInputBase-input {
        width: 280px !important;
    } */

    .footer-subheading {
        font-size: 11px;
    }
    .how-it-works-card-div-insurance .card-wrapper,
    .how-it-works-card-div-insurance .active-card {
        padding: 8px 8px;
        width: 227px;
    }

    .how-it-works-card-div-insurance .linearProgressLanding-insurance {
        width: 242px;
    }

    .core-features {
        margin-top: 30px;
    }
}

@media (max-width: 600px) {
    .heading-insurance {
        font-size: 20px;
    }

    .inputs-insurance {
        padding: 10px 0 20px;
    }
    .how-it-works-wrapper-insurance {
        padding: 29px 15px 0 15px;
    }
    .carousel {
        display: none;
    }

    .carousel-2 {
        display: block;
        padding-bottom: 50px;
        padding-top: 35px;
        margin-left: auto;
        margin-right: auto;
        background: #415b76;
        text-align: center;
        font-family: 'Lato', sans-serif;
    }
    .mobile-div {
        display: flex;
        justify-content: center;
        position: fixed;
        width: 100%;
        bottom: 10px;
        z-index: 200;
    }
    .main-insurance {
        width: 100%;
    }
    .landingHeader-insurance {
        padding: 6px 12px;
        box-shadow: none;
    }
    .random-button {
        width: 90% !important;
        font-size: 14px !important;
        height: 60 !important;
        border-radius: 4px !important;
        background-color: #30ab86 !important;
        font-family: 'Lato', sans-serif;
    }

    .navLinkFlexDiv-insurance {
        justify-content: flex-end;
    }

    .landingHeader-insurance nav {
        display: none;
    }

    .landingHeaderButton-insurance {
        display: none !important;
    }
    /* .button-button{
        margin-top: 600px;
    } */

    .insuranceLanding-main-page {
        margin-top: -268px;
        text-align: center;
        padding: 100px 30px;
    }

    .dynamicHeaderImageDivInsurance {
        margin-top: 200px;
    }

    .text-insurance {
        margin-top: -200px;
    }

    .you-deserve-text-insurance,
    .wfh-insurance {
        font-size: 20px;
        line-height: 50px;
    }
    .you-deserve-text-insurance {
        margin-top: 40px;
    }
    .wfh-insurance {
        padding-bottom: 20px;
    }

    .you-deserve-text-insurance,
    .wfh-insurance {
        text-align: center;
    }

    .header-desc-insurance {
        width: 100%;
        text-align: center;
        margin-top: 7px;
        font-size: 11px;
    }

    .landingGetQuoteButton-insurance {
        width: 120px !important;
        height: 45px !important;
    }

    .core-features {
        text-align: left !important;
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        overflow-x: scroll;
        padding: 0 10px;
        margin-top: 0px;
    }

    .core-feature-upper-div {
        width: 135% !important;
        padding-left: 15px;
        margin-top: -15px !important;
    }
    .core-feature-bottom-div {
        width: 135% !important;
        padding-right: 15px;
        margin-left: -10px;
    }

    .sub-card {
        width: 500px !important;
        padding: 0px 12px;
    }

    .address {
        width: 100% !important;
        margin-top: 0;
    }

    .heading2-insurance {
        width: 323px;
    }

    .phone-icon-insurance .MuiSvgIcon-root {
        margin-left: 6px;
    }

    .worlds-insurance {
        margin-top: -56px;
    }
    .div-1-insurance img,
    .div-2-insurance img {
        width: 143px;
        height: 48px;
        margin: 0px 13px;
    }
    .worlds-heading-insurance {
        font-size: 20px;
        line-height: 30px;
    }

    .worlds-para-insurance {
        font-size: 12px;
        line-height: 16px;
        width: 70%;
    }

    .main-div {
        text-align: left !important;
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        overflow-x: scroll;
        padding: 0 10px;
    }

    .div-1-insurance,
    .div-2-insurance {
        width: auto;
    }

    .div-2-insurance {
        margin-top: 0;
    }

    .collection-insurance {
        width: 60%;
        flex-wrap: wrap;
    }

    .collectionCard-insurance {
        width: 100% !important;
        text-align: center;
        height: auto !important;
        margin: 10px 0;
    }

    .collectionCardTitle {
        font-size: 14px;
        text-align: center;
        margin: 13px 0 10px 0;
    }

    /* .collectionCardPara {
        font-size: 13px;
        text-align: center;
        line-height: 22px;
        height: auto;
    } */

    .collectionCardImg-insurance {
        width: 64px !important;
        height: 50px !important;
    }

    .core-features-heading {
        font-size: 20px;
    }
    .core-features-para {
        width: 80%;
    }
    .core-image {
        width: 75px;
        height: 65px;
    }

    .compare-section {
        padding: 15px 0;
    }

    .questions {
        width: 95%;
    }

    .MuiAccordion-rounded {
        border-radius: 0 !important;
    }

    .address-wrapper-insurance {
        width: 100%;
    }

    .boxCard-insurance {
        width: 70% !important;
        margin-left: auto !important;
        margin-right: auto !important;
    }

    /* .footer{
        display: none !important;
    } */
    .address-wrapper-insurance .innerInputs-insurance {
        display: flex;
        /* flex-direction: column; */
        margin: 0px 0;
        margin-top: 10px !important;
        align-items: flex-start;
    }

    .div-input {
        margin: 10px 0px;
        /* width: 100%; */
    }
    /* .address-wrapper-insurance .input1-insuranceLanding .MuiOutlinedInput-root {
        width: 300px !important;
    } */

    /* .address-wrapper-insurance .input2-insuranceLanding .MuiInputBase-input,
    .address-wrapper-insurance .input4-insuranceLanding .MuiInputBase-input {
        width: 280px !important;
        /* margin-bottom: 20px;
        margin-top: 10px; */
    /* }  */

    /* .address-wrapper-insurance .input3-insuranceLanding .MuiInputBase-input {
        width: 260px !important;
    } */

    .address-wrapper-insurance .boxCard-insurance {
        width: 90% !important;
    }

    .last-button {
        margin-left: auto;
        /* margin-top: 10px; */
    }

    .last-button button {
        margin: 0px !important;
    }

    /* .iIcon-div {
        margin-top: 20px !important;
        margin-left: 5px !important;
    } */

    .landingFormButtonDiv {
        display: flex;
        justify-content: flex-end;
    }

    .how-it-works-wrapper-insurance .card-img-wrapper-insurance {
        display: none;
    }
    .how-it-works-tab-mobile-insurance {
        display: block;
    }
    .innerInputs-insurance {
        margin-left: 19px;
    }
}

@media (max-width: 360px) {
    .carousel {
        display: none;
    }

    .carousel-2 {
        display: block;
        padding-bottom: 50px;
        padding-top: 35px;
        margin-left: auto;
        margin-right: auto;
        background: #415b76;
        text-align: center;
        font-family: 'Lato', sans-serif;
    }
    .mobile-div {
        display: flex;
        justify-content: center;
        position: fixed;
        width: 100%;
        bottom: 10px;
        z-index: 200;
    }
    .main-insurance {
        width: 100%;
    }
    .landingHeader-insurance {
        padding: 6px 12px;
    }
    .random-button {
        width: 90% !important;
        font-size: 14px !important;
        height: 60 !important;
        border-radius: 4px !important;
        background-color: #30ab86 !important;
        font-family: 'Lato', sans-serif;
    }

    .navLinkFlexDiv-insurance {
        justify-content: flex-end;
    }

    .landingHeader-insurance nav {
        display: none;
    }

    .landingHeaderButton-insurance {
        display: none !important;
    }
    /* .button-button{
        margin-top: 600px;
    } */

    .insuranceLanding-main-page {
        margin-top: -268px;
        text-align: center;
        padding: 100px 30px;
    }

    .dynamicHeaderImageDivInsurance {
        margin-top: 265px;
    }

    .text-insurance {
        margin-top: -258px;
    }

    .you-deserve-text-insurance,
    .wfh-insurance {
        font-size: 20px;
        line-height: 50px;
    }
    .you-deserve-text-insurance {
        margin-top: 40px;
    }
    .wfh-insurance {
        padding-bottom: 20px;
    }

    .you-deserve-text-insurance,
    .wfh-insurance {
        text-align: center;
    }

    .header-desc-insurance {
        width: 100%;
        text-align: center;
        margin-top: 7px;
        font-size: 11px;
    }

    .landingGetQuoteButton-insurance {
        width: 120px !important;
        height: 45px !important;
    }

    .core-features {
        text-align: left !important;
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        overflow-x: scroll;
        padding: 0 10px;
    }

    .sub-card {
        width: 500px !important;
        padding: 0px 12px;
    }
    .address {
        width: 100% !important;
    }

    .heading2-insurance {
        width: 323px;
    }

    .phone-icon-insurance .MuiSvgIcon-root {
        margin-left: 6px;
    }

    .worlds-insurance {
        margin-top: -56px;
    }
    .div-1-insurance img,
    .div-2-insurance img {
        width: 143px;
        height: 48px;
        margin: 0px 13px;
    }
    .worlds-heading-insurance {
        font-size: 20px;
        line-height: 30px;
    }

    .worlds-para-insurance {
        font-size: 12px;
        line-height: 16px;
        width: 70%;
    }

    .main-div {
        text-align: left !important;
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        overflow-x: scroll;
        padding: 0 10px;
    }

    .div-1-insurance,
    .div-2-insurance {
        width: auto;
    }

    .div-2-insurance {
        margin-top: 0;
    }

    .collection-insurance {
        width: 60%;
        flex-wrap: wrap;
    }

    .collectionCard-insurance {
        width: 100% !important;
        text-align: center;
        height: auto !important;
        margin: 10px 0;
    }

    .collectionCardTitle {
        font-size: 14px;
        text-align: center;
        margin: 13px 0 10px 0;
    }

    /* .collectionCardPara {
        font-size: 13px;
        text-align: center;
        line-height: 22px;
        height: auto;
    } */

    .collectionCardImg-insurance {
        width: 64px !important;
        height: 50px !important;
    }

    .core-features-heading {
        font-size: 20px;
    }
    .core-features-para {
        width: 80%;
    }
    .core-image {
        width: 75px;
        height: 65px;
    }

    .compare-section {
        padding: 15px 0;
    }

    .questions {
        width: 95%;
    }

    .MuiAccordion-rounded {
        border-radius: 0 !important;
    }

    .address-wrapper-insurance {
        width: 100%;
    }

    .boxCard-insurance {
        width: 70% !important;
        margin-left: auto !important;
        margin-right: auto !important;
    }

    /* .footer{
        display: none !important;
    } */

    .address-wrapper-insurance .boxCard-insurance {
        width: 88% !important;
    }

    .last-button {
        margin-left: auto;
    }

    /* .iIcon-div {
        margin-top: 20px !important;
        margin-left: 5px !important;
    } */

    .landingFormButtonDiv {
        display: flex;
        justify-content: flex-end;
    }

    .how-it-works-wrapper-insurance .card-img-wrapper-insurance {
        display: none;
    }
    .how-it-works-tab-mobile-insurance {
        display: block;
    }
    .innerInputs-insurance {
        margin-left: 19px;
    }
}
