.signup-form-main-div {
    padding: 22px;
    background: #ffffff;
    width: 327px;
    margin-top: 20px;
    box-shadow: 0 3px 6px #00000026;
    border: 1px solid #e0e0e0;
    // position: fixed;

    // .MuiOutlinedInput-notchedOutline {
    //   border: none;
    // }

    .inputDiv {
        margin-bottom: 18px;

        @media (max-width: 1280px) {
            margin-bottom: 10px;
        }
    }

    //   .signUpInput input {
    //     background-color: #fff;
    //     border: 1px solid #bebebe;
    //     border-radius: 5px;
    // }
    .signUpInput .MuiFilledInput-underline:before,
    .signUpInput .MuiFilledInput-underline::after {
        border-bottom: none !important;
    }
    // .signUpInput input:focus {
    //     background-color: #fff;
    //     border: 1px solid #006df5;
    //     border-radius: 5px;
    // }
    .signUpInput .MuiInputLabel-filled.MuiInputLabel-shrink {
        z-index: 1 !important;
        color: #6b6c6f;

        @media (max-width: 768px) {
            font-size: 12px !important;
        }
    }
    .signUpInput .MuiFormLabel-root.Mui-focused {
        color: #006df5;
    }

    .MuiFilledInput-adornedEnd {
        @media (max-width: 768px) {
            padding-right: 0;
        }
    }

    // .MuiOutlinedInput-root {
    //   width: 100%;
    // }

    .MuiFormControl-root {
        width: 100%;
        background-color: #fff;
        // border: 1px solid #6b6c6f;
        border-radius: 5px;

        // @media (max-width: 768px) {
        //     height: 40px;
        // }
    }

    // .MuiFormControl-root.Mui-focused  {
    //   width: 100%;
    //   background-color: #fff;
    //   border: 1px solid #006df5;
    //   border-radius: 5px;

    //   @media (max-width: 768px) {
    //     height: 40px;
    //   }
    // }

    .MuiFilledInput-root {
        width: 100%;
        background-color: #fff !important;
        border: 1px solid #6b6c6f;
        border-radius: 5px;

        // @media (max-width: 768px) {
        //     height: 40px;
        // }
    }

    .MuiFilledInput-root.Mui-focused {
        width: 100%;
        background-color: #fff !important;
        border: 1px solid #006df5;
        border-radius: 5px;

        // @media (max-width: 768px) {
        //     height: 40px;
        // }
    }

    .MuiFilledInput-root.Mui-error {
        width: 100%;
        background-color: #fff !important;
        border: 1px solid red;
        border-radius: 5px;

        // @media (max-width: 768px) {
        //     height: 40px;
        // }
    }

    //  .MuiOutlinedInput-input {
    //   width: 100%;
    //   padding: 27px 12px 10px;
    //   // border: 1px solid #6B6C6F;
    //   // border-radius: 4px;

    //   @media (max-width: 1440px) {
    //     padding: 22px 12px 10px;
    //   }

    //   @media (max-width: 768px) {
    //     padding: 18px 12px 10px;
    //     font-size: 14px;
    //   }
    //  }

    //   .MuiInputLabel-outlined.MuiInputLabel-shrink {
    //     margin-top: 13px;
    //     font-size: 12px !important;
    //     color: #6B6C6F;

    //     @media (max-width: 768px) {
    //       font-size: 10px !important;
    //     }
    //   }

    .signUpButton {
        width: 100% !important;
        height: 50px !important;
        font-size: 16px !important;
        line-height: 22px !important;
        font-weight: bold !important;
        margin-top: 22px !important;

        @media (max-width: 1440px) {
            margin-top: 7px !important;
        }
        @media (max-width: 768px) {
            margin-top: 0px !important;
            height: 40px !important;
            font-size: 12px !important;
        }

        //     @media (max-width: 600px) {
        //         // position: fixed;
        // /* left: 7px; */
        //         display: flex;
        //         bottom: 4px;
        //         justify-content: center;
        //         margin: 0 !important;
        //         width: 95% !important;
        //     }

        @media (max-width: 325pxpx) {
            left: 8px;
        }
    }

    .signInDiv {
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        margin-top: 20px;

        .signInText {
            color: #006dff;
            cursor: pointer;
            font-weight: 700;
        }
        .signInText1 {
            color: #009cbd !important;
        }
        @media (max-width: 1280px) {
            margin-top: 10px;
        }
        @media (max-width: 768px) {
            font-size: 12px;
            line-height: 18px;
            text-align: center;
        }
    }

    .googleButton {
        width: 100% !important;
        font-size: 16px !important;
        line-height: 22px !important;
        font-weight: bold !important;
        color: #333333 !important;
        text-align: center !important;
        margin-top: 10px;
        border: 1px solid #333333 !important;
        box-shadow: none !important;
        border-radius: 4px !important;
        height: 50px !important;

        span {
            font-weight: bold !important;
            width: 70% !important;
            text-align: center !important;
            font-size: 16px !important;

            @media (max-width: 768px) {
                font-size: 12px !important;
            }
        }

        div {
            @media (max-width: 768px) {
                padding: 5px 10px !important;
            }
        }

        @media (max-width: 768px) {
            font-size: 12px !important;
            height: 40px !important;
            margin-top: 5px;
        }
    }

    .lineDiv {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-top: 20px;
        .line {
            border-bottom: 1px solid #707070;
            width: 43%;
        }

        @media (max-width: 1440px) {
            margin-top: 7px !important;
            height: 20px;
        }
        @media (max-width: 768px) {
            margin-top: 2px !important;
            font-size: 12px;
        }
    }

    @media (max-width: 1440px) {
        padding: 18px;
        margin-top: 15px;
    }

    @media (max-width: 768px) {
        width: 260px;
        padding: 10px;
        margin-top: 8px;
    }

    @media (max-width: 600px) {
        width: 91%;
        padding: 17px;
        margin-top: 15px;
        position: relative;
        // border: none;
        // box-shadow: none;
    }

    .errorSignup {
        color: red;
        padding-top: 5px;
        // margin-left: 10px;
        text-align: left;
        margin-bottom: 20px;
        @media (max-width: 1280px) {
            margin-bottom: unset;
            padding-top: 0px;
        }
    }
}

.signup-form-main-divD {
    border: 35px solid #100b28 !important;
    @media (max-width: 600px) {
        width: 70%;
    }
    .MuiFilledInput-root,
    .MuiFilledInput-root.Mui-focused {
        border: 1px solid #100b28 !important;
        border-radius: 0px !important;
        color: #323744;
    }
    .MuiInputLabel-filled.MuiInputLabel-shrink.Mui-focused {
        color: #100b28 !important;
    }
    .topHeadingForm {
        font-size: 32px;
        font-weight: 600;
        text-align: center;
        font-family: 'EuclidCircularA-Semibold';
        @media (max-width: 768px) {
            font-size: 24px;
        }
        @media (max-width: 600px) {
            font-size: 20px;
        }
    }
    .subHeadingForm {
        font-size: 32px;
        font-family: 'EuclidCircularA-Regular';
        text-align: center;
        padding-bottom: 27px;
        @media (max-width: 1280px) {
            padding-bottom: 15px;
        }
        @media (max-width: 768px) {
            font-size: 24px;
        }
        @media (max-width: 600px) {
            font-size: 20px;
        }
    }
}
.douglasEllimenSite {
    .signup-form-main-div {
        padding: 22px;
        background: #ffffff;
        width: 450px;
        margin-top: 20px;
        box-shadow: 0 3px 6px #00000026;
        border: 1px solid #e0e0e0;
    }
}
