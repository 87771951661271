.compareCard-main-div {
  display: flex;
  flex-direction: row;
}

.image-moveEasy{
  width: 150px;
  height: 30px;
 
}

.image-moveEasy  img{
 width: 100%;   
 height: 100%;

}

.compareCard {
  width: 418px !important;
  height: 427px !important;
  padding: 10px;
  font-family: 'Lato', sans-serif;
}

.compareCard img {
  width: 400px !important;
  height: 285px !important;
}

.compareCard p {
  font-size: 20px !important;
  line-height: 30px!important;
  margin-top: 43px !important;
  font-family: "Lato", sans-serif;
  color: #333333;
}

.vl {
    border-left:1px solid #AAAAAA;
    height: 210px;
    /* margin-left: 65px; */
  }

  .vl-line-main{
padding: 0px 20px 0 37px;
margin-top: 40px;
  }

  /* .circle-img{
    
    
  } */
  .circle
    {
    width:40px;
    height:40px;
    border-radius:50%;
    font-size:12px;
    color:#fff;
    line-height:40px;
    text-align:center;
    background:#0066F5;
    margin-left: -18px 
    }

  /* .circle-img img{
      width:45px;
  } */
  .compareHeading {
    font-size: 22px;
    line-height: 28px;
    font-weight: 900;
    text-align: center;
    height: 40px;
    font-family: "Lato", sans-serif;
    color: #333333;
  }


  .hr-line-main {
    display: none;
  }
  @media(max-width:1440px){
    .circle-img{
    
        margin-left: -16px 
      }
    
      .circle-img img{
          width:35px;
      }
    
  }

  @media(max-width: 1366px ) {
    .vl {
      height: 130px;
    }
    .compareHeading {
      font-size: 18px;
      line-height: 20px;
      height: 29px;
  }

  .compareCard img {
      width: 302px !important;
      height: 206px !important;
  }

  .compareCard p {
      font-size: 16px !important;
      line-height: 20px!important;
      margin-top: 25px !important;
  }

  .compareCard {
      width: 313px !important;
      height: 300px !important;
  }
}

@media(max-width: 1024px) {
    .compareHeading {
      font-size: 13px;
      line-height: 18px;
      height: 22px;
  }
  .compareCard {
    width: 226px !important;
    height: 209px !important;
  }
  .compareCard img {
    width: 224px !important;
    height: 128px !important;
  }

  .compareCard p {
      font-size: 12px !important;
      line-height: 18px!important;
      margin-top: 18px !important;
  }

  .vl {
    height: 84px;
  }
}

@media (max-width: 425px){
  .compareCard-main-div{
    flex-direction: column;
  }

  .vl-line-main {
    display: none;
  }

  .hr-line-main {
    display: flex;
    justify-content: center;
    padding-top: 19px;
    padding-bottom: 19px;
  }

  .hr {
    width: 154px;
    border-top: 1px solid #AAAAAA;
    margin-top: 21px;
  }

  .card-Div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .compareCard {
    width: 80% !important;
    height: auto !important;
  }

  .compareHeading {
    font-size: 16px;
    line-height: 28px;
  margin: 20px 0;
}
.compareCard p {
  font-size: 16px !important;
  line-height: 30px!important;
  height: auto !important;
}
}