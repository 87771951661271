.signUp-get-div {
    // display: flex;
    // flex-direction: row;
    // height: 100%;
    // margin-top: 30px;
    padding: 20px 0;

    @media (max-width: 768px) {
        padding: 10px 0;
    }

    @media (max-width: 600px) {
        padding: 20px 0 10px 0;
    }
}

.get-div-section1 {
    width: 40%;
    background: url('../../../Assets/images/SignUp/getBackground.svg');

    @media (max-width: 600px) {
        display: none;
    }
}

.get-div-section2 {
    width: 45%;
    display: flex;
    flex-direction: column;

    @media (max-width: 1367px) {
        width: 50%;
    }

    @media (max-width: 600px) {
       width: 100%;
       padding: 0 10px;
    text-align: center;
    }
}

.get-section2-div1 {
    display: flex;
    justify-content: space-between;
    padding-bottom: 30px;

    @media (max-width: 600px) {
        flex-wrap: wrap;
     }
}
.CardSignUp {
    width: 42% !important;
    height: 270px !important;
    box-shadow: 0px 12px 38px #0000001a !important;
    padding: 24px 20px !important;

    @media (max-width: 1024px) {
        // width: 250px !important;
        height: 220px !important;
        padding: 15px !important;
    }
    @media (max-width: 768px) {
        // width: 157px !important;
        height: 180px !important;
        padding: 5px !important;
        width: 44% !important;
    }
    @media (max-width: 600px) {
        width: 100% !important;
        box-shadow: none !important;
        height: auto !important;
    }

}

.CardSignUp img {
    @media (max-width: 768px) {
        width: 60px;
        height: 60px;
    }
}

.CardSignUp .collectionCardTitle {
    font-size: 22px;
    font-weight: bold;

    @media (max-width: 1024px) {
        font-size: 15px;
        margin: 10px 0 5px 0;
    }
    @media (max-width: 768px) {
        font-size: 12px;
        margin: 8px 0 0px 0;
    }

    @media (max-width: 600px) {
        font-size: 16px;
        line-height: 30px;
        text-align: center;
    }
}

.CardSignUp .collectionCardPara {
    height: auto;
    font-size: 16px;

    @media (max-width: 1024px) {
        font-size: 13px;
        margin: 10px 0 5px 0;
        line-height: 20px;
    }
    @media (max-width: 768px) {
        font-size: 11px;
        margin: 10px 0 5px 0;
        line-height: 17px;
    }

    @media (max-width: 600px) {
        font-size: 12px;
        line-height: 18px;
        text-align: center;
    }
}

.getText {
    font-size: 30px;
    line-height: 32px;
    // text-align: center;
    font-weight: bold;
    text-transform: capitalize;
    padding: 10px 0;
    margin-bottom: 20px;

    @media (max-width: 1440px) {
        font-size: 24px;
    }

    @media (max-width: 1366px) {
        font-size: 20px;
    }

    @media (max-width: 768px) {
        font-size: 14px;
        margin-bottom: 0px;
    }

    @media (max-width: 600px) {
        font-size: 22px;
        margin-bottom: 0px;
    }
}
