.main-schedule-card{
    @media(max-width:600px){
        width: 100% ;
        
    }
}
.scheduledServicesCard {
    padding: 20px;
    border: 1px solid #e2e2e2;
    width:200px;
    border-radius: 5px;
    cursor: pointer;
    margin: 20px 20px 20px 0;

    @media(max-width:600px){
        width: 100% ;
      margin: 10px 0;
      padding: 0 0 20px 0;
    }
    .box-box{
        height: 65px;
    display: flex;
    align-items: flex-start;
    }

    .scheduledServicesCardInner {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 50px;

        img {
            height: 20px;
            width: 30px;
            margin-right: 15px;
        }

        .textInnerSchedule {
            color: #333333;
            font-weight: 800;
            font-size: 20px;
        }
    }
    .more{
        // color: #006DFF;
        font-size: 12px;
        font-weight: bold;
      margin-left: 70%;
    }

    .textInnerSchedule1 {
        color: #6b6c6f;
        font-size: 14px;
        font-weight: 800;
        margin-top: 16px;

        @media(max-width:600px){
           padding: 0 10px;
        }
        
    }

    .dateAndTimeDiv{
        color: #6b6c6f;
        font-size: 12px;
        font-weight: 800;
        margin:5px 10px
    }
    .dateAndTimeContentDiv{
        font-size: 12px;
        margin:0px 10px
    }
    .scheduledServicesCardInner2 {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-top: 12px;

        @media(max-width:600px){
            padding: 0 10px;
        }
        .boxDivSchedule {
            height: 25px;
            width: 24px;
            font-size: 15px;
            font-family: Poppins;
            font-weight: 800;
            padding: 2px 4px 2px 3px;
        }
        .textInnerSchedule1 {
            color: #333333;
            font-size: 14px;
            font-weight: 800;
            margin-left: 10px;
            display: flex;
            align-items: center;
            margin-top: 0px;
        }

     
    }
}
