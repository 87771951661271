.savingsFlowMainPage {
    min-height: 80vh;
    padding-top: 78px;
    @media (max-width: 426px) {
        padding-top: 58px;
    }

    .savingsFLowMainFlexDiv {
        display: flex;
        justify-content: center;
        max-height: 95vh;

        .imageContentDiv {
            width: 40%;
            text-align: center;
            background: linear-gradient(#eeeeee, #ffffff);
            box-shadow: 0 1px 5px #eeeeee;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            padding-bottom: 30px;
            max-height: 640px;
            @media (max-width: 600px) {
                display: none;
            }

            .image {
                img {
                    width: 100%;
                }
            }

            .contentDiv {
                padding: 0 60px;

                .contentTitle {
                    font-size: 24px;
                    line-height: 32px;
                    font-weight: bold;
                    margin-bottom: 20px;
                }

                .contentDetails {
                    font-size: 16px;
                    line-height: 22px;
                    color: #333333;
                }
            }
        }
        .stepperMainDiv {
            overflow-y: scroll;
            width: 60%;
            padding: 30px 100px;
            @media (max-width: 950px) {
                padding: 30px 25px;
            }
            @media (max-width: 600px) {
                width: 100%;
            }
            @media (max-width: 320px) {
                padding: 30px 15px;
            }
            .mainLabel {
                @media (max-width: 500px) {
                    font-size: 14px;
                    font-weight: 600;
                }
            }
            .individualCardSavings {
                @media (max-width: 900px) {
                    width: 135px;
                    height: 160px;
                }
                @media (max-width: 600px) {
                    height: 37px;
                    width: 155px;
                    padding: 6px 0px 0px 10px;
                    .checkboxCardBody {
                        justify-content: flex-start;
                    }
                    span {
                        display: none;
                    }
                }
            }

            .SavingHeading {
                font-size: 30px;
                line-height: 32px;
                color: #333333;
                margin-top: 30px;
                font-weight: bold;
                @media (max-width: 500px) {
                    font-size: 16px;
                    margin-top: 7px;
                }
            }
            .StepperDiv {
                .MuiStepper-root {
                    @media (max-width: 500px) {
                        padding: 24px 0px;
                    }
                }
                .labelDiv {
                    .secondaryLabel {
                        font-size: 12px;
                        line-height: 16px;
                        color: #747e8b;
                        margin-top: 5px;
                    }
                }
                .MuiStepConnector-lineVertical {
                    // @media (max-width: 600px) {
                    min-height: 10px;
                    // }
                }
                .MuiStepContent-root {
                    // @media (max-width: 600px) {
                    border-left: unset;
                    padding-left: 0px;
                    // }
                }
                .MuiStepConnector-line {
                    // @media (max-width: 600px) {
                    border-color: unset;
                    padding-left: 0px;
                    // }
                }
                .MuiStepConnector-lineVertical {
                    // @media (max-width: 600px) {
                    border-top-style: outset;
                    border-left-style: unset;
                    // }
                }

                .MuiStepLabel-label {
                    display: flex;
                    justify-content: space-between;
                }

                .MuiStepLabel-label.MuiStepLabel-active {
                    color: #333333;
                    font-weight: 600;
                }

                .MuiStepLabel-label.MuiStepLabel-completed {
                    color: #333333;
                }
                .MuiStepIcon-root.MuiStepIcon-completed {
                    color: #00d56d;
                }

                .MuiStepLabel-label {
                    color: #6b6c6f;
                    font-size: 22px;
                    line-height: 28px;
                }

                .MuiStepLabel-root {
                    align-items: normal;
                }

                .savingStepButton {
                    float: right;
                    width: 15%;

                    .savingNextButton {
                        width: 100% !important;
                        height: 40px !important;
                    }
                }
                .savingStepButton {
                    float: right;
                    width: 15%;

                    .savingNextButton {
                        width: 100% !important;
                        height: 40px !important;
                    }
                    @media (max-width: 500px) {
                        float: none;
                        width: 100%;
                    }
                }

                .autoshippingStepsContent {
                    .inputDivSavings {
                        display: flex;
                        justify-content: space-between;
                        margin: 10px 0 20px;

                        .inputDiv1 {
                            width: 48%;
                            display: flex;
                            align-items: center;
                            @media (max-width: 1024px) {
                                width: 70%;
                                margin-right: 2%;
                            }
                            @media (max-width: 768px) {
                                width: 100%;
                                margin-right: 2%;
                            }

                            .inputDiv2 {
                                width: 50%;
                                @media (max-width: 768px) {
                                    width: 50%;
                                    margin-left: 20px;
                                }
                            }

                            .percentageDiv {
                                margin-top: 20px;
                                font-size: 16px;
                                line-height: 22px;
                                color: #6b6c6f;
                                margin-left: 20px;
                                @media (max-width: 500px) {
                                    font-size: 14px;
                                }
                            }
                        }
                        .inputDiv4 {
                            width: 48%;
                            display: flex;

                            @media (max-width: 1024px) {
                                width: 70%;
                            }
                            @media (max-width: 768px) {
                                width: 100%;
                            }
                        }
                        .inputDiv5 {
                            width: 48%;
                            display: flex;
                            flex-direction: column;

                            @media (max-width: 1024px) {
                                width: 70%;
                            }
                            @media (max-width: 768px) {
                                width: 100%;
                            }
                        }

                        .inputDiv3 {
                            display: flex;
                            flex-wrap: wrap;
                        }
                        .css-2b097c-container {
                            width: 100%;
                        }

                        .react-select .select-state__control {
                            min-height: 50px;
                        }
                    }
                }
            }
        }
    }
}
.error {
    // height: 20px;
    font-size: 12px;
    color: red;
    margin-top: 3px;
}
.skipTheActiveStep {
    font-family: 'Roboto';
    font-size: 14px;
    color: #0066f5;
    padding-right: 5px;
}
.buttonInputPowerKioskLast {
    display: flex;
    justify-content: flex-end;
    padding-top: 24px;
    align-items: center;
}
.btnWarantyCheck {
    width: 160px !important;
}
