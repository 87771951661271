.ChecklistMain {
    margin: 30px 0px 0px 80px;

    .Checklistheading {
        font-size: 24px;
        font-weight: 600;
        font-family: sans-serif;
    }

    .ChecklistPara {
        color: #bebebe;
    }

    .Checklistpath {
        display: flex;
        // margin-top: -25px;
    }

    @media (max-width: 1366px) {
        margin: 30px 0px 0px 60px;
    }

    @media (max-width: 768px) {
        margin: 30px 60px 0px 60px;
    }

    @media (max-width: 600px) {
        margin: 30px 0px 0px 10px;
    }
}

.ChecklistOverdueContent {
    margin: 0 80px;

    .checklistMainCards {
        margin-top: 30px;

        @media (max-width: 600px) {
            margin-top: 10px;
        }
    }

    .MuiToolbar-regular {
        min-height: 0px;
    }
    div[class^='Cell-dayOfWeek'] {
        font-size: 14px;
        color: #333;
        padding-bottom: 8px;
    }

    .checklist-overdue-content-header2 {
        display: flex;
        justify-content: space-between;
    }

    .checklist-addTasks {
        color: #009cbd;
        font-family: Roboto;
        font-size: 14px;
        text-align: left;
        font-weight: bold;
        cursor: pointer;
    }

    //tab div styles

    .checklistTabdiv {
        border-bottom: 1px solid #d2d2d2;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
        padding-top: 10px;

        .checklistAddTask {
            cursor: pointer;
            font-size: 14px;
            font-weight: bold;
            color: #009cbd;

            @media (max-width: 600px) {
                display: none;
            }
        }

        .checklistTabInnerDiv {
            display: flex;
        }

        .checklistTabactive {
            color: #009cbd;
            border-bottom: 3px solid #009cbd;
            cursor: pointer;
            font-weight: bold;
            font-size: 14px;
            padding-bottom: 10px;
        }
        .checklistTabtext {
            cursor: pointer;
            font-size: 14px;
        }
    }
    .checklistOverViewMaindiv {
        margin-top: 30px;
        .dataAvailable {
            text-align: center;
            font-size: 22px;
            color: #333;
            font-weight: 700;
        }
    }
    .checklistTabInnerDiv > div {
        margin-right: 20px;
    }
    .checklistAddTaskMobile {
        display: none;
        @media (max-width: 600px) {
            display: flex;
            justify-content: flex-end;
            margin: 10px 0;
            cursor: pointer;
            font-size: 14px;
            font-weight: bold;
            color: #009cbd;
        }
    }
    @media (max-width: 1366px) {
        margin: 0 60px;
    }

    @media (max-width: 768px) {
        margin: 0 60px;
    }

    @media (max-width: 600px) {
        margin: 0 10px;
    }
}
