.trusted-pros-icon-ImgDiv {
    width: 60%;
    min-width: 100px;
    margin: 0 auto;
    padding: 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
        width: 100%;
    }

    @media (max-width: 768px) {
        padding: 13px 0;
    }
    @media (max-width: 600px) {
        padding: 18px 0;
    }
}

.trusted-pros-icon-ContentDiv {
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    padding-bottom: 20px;
    color: #333333;

    @media (max-width: 1024px) {
        font-size: 12px;
        padding-bottom: 15px;
    }

    @media (max-width: 768px) {
        font-size: 9px;
        padding-bottom: 8px;
    }
    @media (max-width: 600px) {
        padding-bottom: 22px;
    }
}
