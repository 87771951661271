.trustedPros-OrderConfirmPage {
    padding: 0 80px;
    position: sticky;
    top: 100px;
    background: #fff;

    .trustedPros-orderConfirm-main-div {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;

        .orderConfirm-main-title {
            font-weight: bold;
            font-size: 24px;
            line-height: 28px;
            margin-bottom: 10px;

            @media (max-width: 600px) {
                font-size: 22px;
                line-height: 31px;
            }
        }

        .orderConfirm-main-para {
            font-size: 16px;
            line-height: 28px;

            @media (max-width: 600px) {
                font-size: 12px;
                line-height: 18px;
            }
        }

        .venderList {
            width: 40%;
            margin-top: 20px;

            .venderListTitle {
                font-size: 16px;
                line-height: 22px;
                font-weight: bold;
                text-align: left;
            }

            .selectedAddressBox {
                text-align: left;
                border: 1px solid #e2e2e2;
                padding: 20px;
            }

            .selectedAddressDiv {
                margin-bottom: 20px;
            }

            .dateTimeDiv {
                display: flex;

                .dateDiv,
                .timeDiv {
                    width: 50%;

                    @media (max-width: 600px) {
                        width: 100%;
                    }
                }

                @media (max-width: 600px) {
                    flex-wrap: wrap;

                    .timeDiv {
                        margin-top: 20px;
                    }
                }
            }

            .selectedAddressTitle {
                color: #6b6c6f;
                font-size: 12px;
                line-height: 22px;
            }

            .selectedAddress {
                color: #333333;
                font-size: 14px;
                line-height: 22px;
            }

            .whatsNextCard1 {
                border: 1px solid #e2e2e2;
                padding: 20px 20px 0;
                display: flex;
                border-radius: 4px;
                box-shadow: 0 1px 1px #00000029;
                margin-bottom: 20px;
            }

            .whatsNextCard2 {
                border: 1px solid #e2e2e2;
                padding: 20px;
                display: flex;
                border-radius: 4px;
                box-shadow: 0 1px 1px #00000029;
            }

            .card1ContentDiv {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                padding-bottom: 20px;
            }

            .card2ContentDiv {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }

            .searchProButton {
                width: 109px !important;
                height: 38px !important;
                background: #ffffff !important;
                color: #273e59 !important;
                font-size: 14px !important;
                line-height: 22px !important;
            }

            .card1AppDownloadImageDiv {
                display: flex;
                justify-content: flex-start;
                width: 75%;

                .card1Image {
                    // width: 49%;
                    margin-right: 30px;

                    img {
                        // width: 106px;
                        height: 34px;
                        width: 100%;
                        // height: 100%;
                        border-radius: 4px;
                    }
                }
            }

            .card1Title,
            .card2Title {
                font-size: 22px;
                line-height: 30px;
                font-weight: bold;
                text-align: left;

                @media (max-width: 1024px) {
                    font-size: 18px;
                }

                @media (max-width: 600px) {
                    font-size: 16px;
                    line-height: 28px;
                }
            }

            .card2Title {
                color: #ffffff;
                margin-bottom: 10px;
            }

            .whatsNextTitle {
                font-size: 22px;
                line-height: 28px;
                text-align: left;
                margin: 20px 0;

                @media (max-width: 768px) {
                    font-size: 18px;
                }
                @media (max-width: 600px) {
                    font-size: 16px;
                    line-height: 31px;
                }
            }

            @media (max-width: 768px) {
                width: 60%;
            }
            @media (max-width: 600px) {
                width: 100%;
            }
        }
    }

    @media (max-width: 1366px) {
        padding: 0 60px;
    }

    @media (max-width: 600px) {
        padding: 0 10px;
        top: 55px;
    }
}
