.greybglong{
    background-color: #f8f8f8;
    padding: 30px 0 90px;
    margin-bottom: -90px;
}
.form_section{
    width: 590px;
    margin: 0 auto 30px;
    padding-bottom: 60px;
    max-width: 90%;
}
.form_box {
    background-color: #fff;
    border: 1px solid #E2E2E2;
    border-radius: 4px;
    
    padding: 45px 0;
    .edit_securityform {
        width: 300px;
        margin: 0 auto;
        max-width: 90%;
    }
    label {
        font-size: 16px;
        font-weight: 600;
        color: #333;
        width: 100%;
        display: inline-block;
        margin-bottom: 13px;
    }
    .form_control {
        border: 1px solid #E0E0E0;
        border-radius: 4px;
        height: 50px;
        width: 100%;
        padding: 5px 15px;
        color: #6B6C6F;
        font-size: 16px;
        display: inline-block;
        box-sizing: border-box;
    }
    .dollar_control{
        background-image: url(../../Assets/images/dollar.png);
        background-position: center left 10px;
        background-size: 11px;
        background-repeat: no-repeat;
        padding-left: 25px !important;
    }
    .form_group {
        margin-bottom: 25px;
    }
    label.smalllabel {
        color: #6B6C6F;
        font-size: 14px;
    }
}

.form_row {
    display: flex;
    justify-content: space-between;
    .col50 {
        width: calc(50% - 7px);
    }
}


.mb_0{
    margin-bottom: 0 !important;
}
.form_btns{
    margin-top: 20px;
    text-align: right;
    .theme_btn{
        margin-left:15px;
    }
    
}
.theme_btn{
    color: #273E59;
    font-size: 14px;
    font-weight: 600;
    border: 1px solid #273E59;
    box-shadow: 0px 1px 1px #161d251a;
    background-color: #fff;
    border-radius: 4px;
    padding: 15px 22px;
    cursor: pointer;
    transition: 0.4s linear;
    &:hover{
        background-color: #273E59;
        color: #fff;
    }
}
.permonthcontrol {
    position: relative;
    span {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        display: inline-block;
        right: -60px;
        color: #6B6C6F;
    }
}

@media (max-width: 575px){
    .permonthcontrol span {
        position: relative;
        transform: translate(0);
        right: 0;
        display: inline-block;
        width: 100%;
        text-align: right;
    }
    .form_box {
        padding: 30px 0;
        label {
            font-size: 14px;
            margin-bottom: 7px;
        }
        .form_control{
            height: 45px;
            padding: 5px;
        }
    }
    
}