.pendingTaskCardMain {
    display: flex;
    // justify-content: space-between;
    height: 162px;
    border-radius: 4px;
}

.pendingTaskImageDiv {
    width: 40%;
    // margin-top: auto;
    // margin-bottom: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;

    @media (max-width: 425px) {
        margin-right: 0;
    }
}
.pendingTaskImageDivD {
    width: 40%;
    // margin-top: auto;
    // margin-bottom: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    img {
        @media (max-width: 425px) {
            height: 60px !important;
        }
    }
    @media (max-width: 425px) {
        margin-right: 0;
    }
}

.pendingTaskImageDiv .svg-image svg {
    height: 100%;
    width: 194px;

    @media (max-width: 1024px) {
        width: 146px;
    }

    @media (max-width: 425px) {
        width: 117px;
    }

    @media (max-width: 375px) {
        width: 117px;
    }
}

.pendingTaskImageDiv img {
    height: 100%;
    width: 194px;

    @media (max-width: 1024px) {
        width: 90%;
    }

    // @media (max-width: 600px) {
    //     width: 117px;
    // }

    // @media (max-width: 375px) {
    //     width: 117px;
    // }
}

.pendingTitle {
    font-size: 22px;
    line-height: 30px;
    font-weight: bold;
    margin-bottom: 10px;

    @media (max-width: 1024px) {
        font-size: 18px;
    }

    @media (max-width: 425px) {
        font-size: 16px;
        margin-bottom: 3px;
    }
}

.pendingTaskContentDiv {
    padding: 20px 20px 20px 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 60%;

    @media (max-width: 425px) {
        padding: 19px 12px 15px 10px;
    }
}

.pendingSubtitle {
    width: 85%;
    font-size: 14px;
    line-height: 24px;
    color: #6b6c6f;

    @media (max-width: 425px) {
        font-size: 12px;
        line-height: 20px;
    }
}

.pendingButtonDiv {
    display: flex;
    justify-content: flex-end;

    @media (max-width: 425px) {
        justify-content: space-between;
    }
}

.pendingCancelButton {
    color: #d92f25 !important;
    background-color: transparent !important;
    border: transparent !important;
    font-size: 14px !important;
    line-height: 22px !important;
    box-shadow: none !important;
    width: 94px !important;
    height: 38px !important;
}

.pendingCompleteTaskButton {
    color: #273e59 !important;
    background-color: transparent !important;
    font-size: 14px !important;
    line-height: 22px !important;
    box-shadow: none !important;
    border: 1px solid #273e59 !important;
    width: 94px !important;
    height: 38px !important;
}
.pendingCompleteTaskButtonD {
    color: #fff !important;
    background-color: #100b28 !important;
    font-size: 14px !important;
    line-height: 22px !important;
    box-shadow: none !important;
    border: 1px solid #100b28 !important;
    width: 94px !important;
    height: 38px !important;
}

.pendingTaskContentDiv .progress {
    margin-bottom: 0;
}

.completedPercent {
    font-weight: 600;
    @media (max-width: 1024px) {
        font-size: 10px;
        padding: 0px;
    }
    @media (max-width: 320px) {
        padding: 0px;
    }
    @media (max-width: 375px) {
        font-size: 10px;
    }
}

.douglasEllimenSite {
    .pendingTaskImageDivD {
        width: 29%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 20px;
    }

    .pendingTaskContentDiv {
        padding: 20px 20px 20px 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 69%;
    }

    .pendingTaskContentDiv .progress {
        margin-bottom: 0;
        height: 100%;
        display: flex;
        flex-direction: column;
    }
}

// .pendingTasksCardsDiv {
//   margin-top: 30px;
// }
