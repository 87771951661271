// .MuiTabs-flexContainer {
//     justify-content: space-around !important;
// }

.progress-margin {
    margin: 0 15px;

    @media (max-width: 600px) {
        margin: 0px;
    }
}
.progress-percent {
    @media (max-width: 767px) {
        margin-left: 10px;
    }
    @media (max-width: 340px) {
        font-size: 12px;
    }
}
.Para {
    color: #bebebe;
}

.internet-formRow {
    display: flex;
    // justify-content: space-between;
    margin-bottom: 20px;
    @media (max-width: 768px) {
        flex-direction: column;
        margin-bottom: 0px;
    }
}
.internet-formRow1 {
    width: 44%;
    margin-right: 2%;
    @media (max-width: 768px) {
        width: 100%;
        margin-bottom: 20px;
    }
}
.internet-input input {
    background-color: #fff;
    border: 1px solid #bebebe;
    border-radius: 5px;
}
.internet-input .MuiFilledInput-underline:before,
.internet-input .MuiFilledInput-underline::after {
    border-bottom: none !important;
}
.internet-input input:focus {
    background-color: #fff;
    border: 1px solid #009cbd;
    border-radius: 5px;
}
.internet-input .MuiInputLabel-filled.MuiInputLabel-shrink {
    z-index: 1 !important;
    color: #6b6c6f;
}
.internet-input .Mui-error input {
    border-color: #d92f25;
}
.internet-input .MuiFormLabel-root.Mui-focused {
    color: #009cbd;
}
.internet-formRow .MuiFormControl-root {
    width: 100% !important;
}
.onlyLandingDiv .error {
    font-size: 12px;
    color: red;
}

.path {
    display: flex;
    // margin-top: -25px;
    font-size: 12px;
}

.main-head {
    margin: 30px 0px 0px 80px;
    @media (max-width: 1366px) {
        margin: 30px 0px 0px 60px;
    }
    @media (max-width: 600px) {
        margin: 30px 0px 0px 10px;
    }
}

.heading {
    font-size: 24px;
    font-weight: 600;
    font-family: sans-serif;
}

.InternetcoverDiv {
    height: 50vh;
    display: flex;
    align-items: center;

    @media (min-height: 1000px) {
        height: 70vh;
    }
    @media (max-height: 850px) {
        height: 55vh;
    }
    @media (max-height: 750px) {
        height: 60vh;
    }
    @media (max-height: 680px) {
        height: 60vh;
    }
    @media (max-height: 580px) {
        height: 75vh;
    }

    svg {
        @media (max-width: 992px) {
            margin-top: 220px;
        }
        @media (max-width: 480px) {
            margin-top: 160px;
        }
    }
}

.landingPage {
    text-align: center;
    padding: 0px 100px 0px 100px;
    width: 100%;

    .heading {
        font-size: 24px;
        font-weight: 600;
        font-family: sans-serif;
    }
    .subtext {
        font-size: 16px;
    }
    .btn-div {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 67px;
        // margin-top: -20px !important;
        .btn-div1 {
            margin-right: 15px;
            width: 220px;
        }
        .btn-div {
            width: 125px;
        }
    }
    .line {
        border-top: 0.5px solid #d2d2d2;
        margin-top: 40px;
        margin-bottom: 40px;
    }
    @media (max-width: 600px) {
        padding: 0 10px;
        .heading {
            font-size: 22px;
        }
        .subtext {
            font-size: 12px;
        }
        .mainImg {
            width: 90%;
            height: 100%;
        }
        .line {
            display: none;
        }
        .btn-div {
            flex-direction: column;
            .btn-div1 {
                margin: 0 0 15px 0;
                width: 100%;
            }
            .btn-div2 {
                width: 100%;
            }
        }
    }
}

.internetStep .steps {
    padding: 0px 80px 0px 80px;
    text-align: center;
    margin-top: 2%;
    .heading {
        font-size: 24px;
        font-weight: 600;
        font-family: sans-serif;
    }
    .para1 {
        margin-top: 10px;
        font-size: 16px;
    }
    .buttonsInternet {
        display: flex;
        justify-content: space-between;
        padding: 0px 30px 0px 30px;
        margin-bottom: 100px;
        .skipStyle {
            text-decoration: underline;
            color: #009cbd;
            cursor: pointer;
            display: flex;
            align-items: center;
        }
        .skipMove {
            text-decoration: underline;
            color: #006dff;
            cursor: pointer;
            display: flex;
            align-items: center;
        }
        @media (max-width: 600px) {
            margin-bottom: 30px;
        }
    }
    @media (max-width: 600px) {
        padding: 0px 10px;
        .heading {
            font-size: 22px;
        }
        .para1 {
            font-size: 12px;
        }
        .buttonsInternet {
            flex-direction: column-reverse;
            align-items: center;
            padding: 0px;
            margin-bottom: 80px;
            .buttondivInternet {
                display: flex;
            }
            .skipStyle {
                margin-top: 20px;
            }
        }
    }
}
.internetStepDouglas {
    .skipStyle {
        color: #009cbd !important;
    }
}
.recommendationStepsInternet {
    padding: 0px 80px;
    text-align: center;
    @media (max-width: 1366px) {
        padding: 0px 60px;
    }
    @media (max-width: 600px) {
        padding: 0px 10px;
    }
    .MuiCheckbox-colorSecondary.Mui-checked {
        color: #006dff !important;
    }
}
.recommendationStepsInternetDouglas {
    .locationIcon,
    .link1,
    .link,
    .MuiCheckbox-colorSecondary.Mui-checked {
        color: #009cbd !important;
    }
    .main-div-internet-main .heading1 .editAddressAutoComplete,
    .editAddressAutoComplete {
        color: #009cbd;
        border-bottom: 1px solid #009cbd;
    }
    .recommendedCard {
        background-color: #009cbd !important;
    }
    .main-div-internet-main .RecommendedCard .recommendationLongSuggested {
        border: 2px solid #009cbd !important;
    }
    .featureDiv {
        color: #009cbd !important;
        border: 1px solid #009cbd !important;
    }
}

// .steps1 {
//     padding: 0px 80px 0px 80px;
//     text-align: center;
//     @media (max-width: 600px) {
//         padding: 0px 10px;
//     }
// }

//boxCard
.AddressCardInternet {
    height: 60vh;
    display: flex;
    align-items: center;
    justify-content: center;
    .addressCard.Internet {
        padding: 0px;
        display: flex;
        justify-content: center;
        // margin-top: 5%;
        height: fit-content;
        .boxCard {
            width: 488px;
            height: auto;
            box-shadow: 0px 3px 10px #00000029;
            border: 1px solid #e2e2e2;
            border-radius: 4px;
            display: flex;
            flex-direction: column;
            padding: 35px 20px 35px 20px;
            margin-bottom: 100px;
            // margin-top: -7%;

            .spec-state-input {
                border-radius: 0px !important;
            }
        }
    }
    .addressInternetBtn {
        width: 110px;
    }
    .inputs {
        margin-top: -20px;
        .innerInputs {
            display: flex;
            margin-top: 20px;
        }
        .innerInput1 {
            margin-top: 30px;
        }
    }
    @media (max-width: 600px) {
        height: unset;
        .addressCard.Internet {
            .boxCard {
                width: 100%;
                margin-right: 10px;
            }
        }
        .inputs {
            .innerInputs {
                flex-direction: column;
            }
        }
        .addressInternetBtn {
            width: 98%;
        }
    }
}
.AddressCardInternetDouglas {
    .addressCard.Internet .input1 .MuiInputBase-input,
    .addressCard.Internet .innerInput1 .MuiInputBase-input,
    .addressCard.Internet .input2 .MuiFilledInput-input,
    .addressCard.Internet .input4 .MuiFilledInput-input,
    .addressCard .react-select .select-state__control {
        border: 1px solid #100b28 !important;
        border-radius: 0px !important;
        color: #323744;
    }
}
// .inputs {
//     margin-top: -20px;
// }
// .innerInputs {
//     display: flex;
//     margin-top: 20px;
//     @media (max-width: 600px) {
//         flex-direction: column;
//     }
// }
// .innerInput1 {
//     margin-top: 30px;
// }
.Para1 {
    margin: 10px 0px 10px 0px;
}

.MuiOutlinedInput-root {
    margin-right: 10px;
    height: 50px !important;
}

.input1 .MuiOutlinedInput-root {
    width: 440px !important;
}

.input2 .MuiOutlinedInput-root {
    width: 170px !important;
}

.input3 .MuiOutlinedInput-root {
    width: 100px !important;
}
.input4 .MuiOutlinedInput-root {
    width: 150px !important;
}
.checkbox .MuiSvgIcon-root {
    fill: 'red' !important;
}

//stepper styless

.Para11 {
    color: #333333;
    font-size: 12px;
    margin-top: 20px;
    margin-bottom: 20px;
}

//checkbox cards

.checkboxCards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 40px;
}

.individualCard {
    margin-right: 15px;
}

// recommended page

.main-div {
    text-align: left !important;
    // margin-left: 18px;
}
.main-div-internet-mainDoug {
    .heading1 {
        .editAddressAutoComplete {
            border: 0;
            border-bottom: 1px solid #009cbd;
            width: fit-content;
            width: 350px;
            font-size: 18px;
            color: #009cbd;
        }
    }
}
.main-div-internet-main {
    display: flex;
    text-align: left !important;
    flex-direction: column;
    .heading1 {
        display: flex;
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 10px;
        .locationIcon {
            color: #009cbd;
            text-decoration: underline;
            margin-left: 10px !important;
            margin-right: 0px !important;
            margin-top: 5px;
        }
        .moveLocationIcon {
            color: #006dff !important;
            text-decoration: underline;
            margin-left: 10px !important;
            margin-right: 0px !important;
            margin-top: 5px;
        }
        .editAddressAutoComplete {
            border: 0;
            border-bottom: 1px solid #006dff;
            width: fit-content;
            width: 350px;
            font-size: 18px;
            color: #006dff;
        }
    }

    .BlackCard {
        display: flex;
        margin-top: 10px;
        align-items: flex-end;
        color: #fff;
        .image1 {
            margin-left: auto;
            padding-right: 100px;
        }
        .mainCard {
            background: transparent !important;
            border: none !important;
            width: 470px;
            padding: 20px 10px 20px 30px;
        }
    }
    .RecommendedCard {
        display: flex;
        margin: 60px 0px 30px 0px;
        .recommendationLongSuggested {
            margin: 0px 15px 0 10px;
            border: 2px solid #006dff !important;
            border-radius: 3px;
            margin-top: -40px;
            width: 33%;
            @media (max-width: 1024px) and (min-width: 780px) {
                width: 35%;
            }
        }
        .recommendationLongSuggestedDoug {
            border: 2px solid #009cbd !important;
        }
    }
    @media (max-width: 1024px) {
        .BlackCard {
            .image1 {
                padding-right: 30px;
            }
        }
    }
    @media (max-width: 768px) {
        flex-direction: column;
        .heading1 {
            order: 2;
            margin-top: 20px;
            flex-direction: column;
            margin: 10px 0;
            .heading111 {
                margin-bottom: 10px;
            }
            .locationIcon {
                margin: 0px 0px 0px 0px !important;
            }
        }

        .BlackCard {
            order: 1;
            margin-top: 0;
            flex-direction: column;
            align-items: unset;
            position: relative;
            .mainCard {
                z-index: 1;
            }
            .image1 {
                position: absolute;
                z-index: 0;
                right: 0;
                bottom: 0;
                opacity: 0.5;
            }
        }
        .RecommendedCard {
            order: 4;
            margin: 10px 0px 30px 0px;
            flex-direction: column;
            .recommendationLongSuggested {
                width: 630px;
                margin: 0;
            }
            .recommendationLongCard1 {
                order: 3;
                width: 600px !important;
            }
            .recommendationLongCard2 {
                order: 1;
                width: 600px !important;
            }
            .recommendationLongCard3 {
                order: 2;
                width: 600px !important;
                margin: 20px 0;
            }
        }
    }
    @media (max-width: 600px) {
        .heading1 {
            font-size: 14px;
            .editAddressAutoComplete {
                font-size: 14px;
                width: 300px;
            }
            .locationIcon {
                font-size: 18px;
            }
        }
        .BlackCard {
            .mainCard {
                width: calc(100% - 30px);
                padding: 20px 10px;
                .head {
                    font-size: 22px;
                }
                .para111 {
                    font-size: 12px;
                }
            }
            .image1 {
                padding-right: 0px;
                svg {
                    width: 100%;
                }
            }
        }
        .RecommendedCard {
            order: 3;
            margin: 10px 0px 0px 0px;
            flex-direction: column;
            .recommendationLongSuggested {
                width: auto;
                margin: 0;
            }
            .recommendationLongCard1 {
                order: 3;
                width: auto !important;
                margin: 0 !important;
            }
            .recommendationLongCard2 {
                order: 1;
                width: auto !important;
            }
            .recommendationLongCard3 {
                order: 2;
                width: auto !important;
                margin: 20px 0;
            }
        }
    }
}
.heading1 {
    display: flex;
    font-size: 22px;
    font-weight: 600;
    // margin-bottom: 20px;
    @media (max-width: 1024px) {
        margin-bottom: 20px;
    }
}
.locationIcon {
    color: #009cbd;
    text-decoration: underline;
    margin-left: 10px !important;
    margin-right: 0px !important;
    margin-top: 5px;
    @media (max-width: 600px) {
        margin-left: 0px !important;
        margin-right: 0px !important;
        margin-top: 5px;
    }
}

.BlackCard1 {
    display: flex;
    margin-top: 10px;
    color: #fff;
    text-align: left;
    align-items: flex-end;
    .image1 {
        margin-left: auto;
        padding-right: 100px;
    }
    .mainCard {
        width: 500px;
        padding: 20px 10px 20px 30px;
        background: transparent;
        border: none;

        .internetRecommendationProgressBar {
            display: flex;
            margin-top: 20px;
            align-items: center;
        }

        .head {
            @media (max-width: 340px) {
                font-size: 20px !important;
            }
        }
    }
    @media (max-width: 1024px) {
        .image1 {
            padding-right: 30px;
        }
    }
    @media (max-width: 768px) {
        flex-direction: column;
        margin-top: 0;
        flex-direction: column;
        align-items: unset;
        position: relative;
        height: 170px;
        width: 100%;
        .mainCard {
            z-index: 1;
            padding: 10px 10px 0px 20px;
            width: calc(100% - 30px);
        }
        .image1 {
            position: absolute;
            z-index: 0;
            right: 0;
            bottom: 0;
            opacity: 0.5;
            padding-right: 10px;
        }
    }
    @media (max-width: 600px) {
        height: 180px;
        .mainCard {
            margin: 10px 10px;
            padding: 0px;
            width: calc(100% - 20px);
            .head {
                width: 100%;
                font-size: 22px;
                margin-bottom: 10px;
            }
            .head + div {
                font-size: 12px;
            }
            .internetRecommendationProgressBar {
                flex-direction: column-reverse;
                justify-content: flex-start;
                align-items: flex-start;
            }
            .internetRecommendationProgressBar > div {
                // margin-bottom: 10px;
            }
        }
        .image1 {
            padding-right: 0px;
            svg {
                width: 100%;
            }
        }
    }
}

.head {
    font-size: 34px;
    font-weight: bold;
}

.para111 {
    margin-top: 10px;
    font-size: 16px;
}

.editAnswer {
    text-decoration: underline;
    margin-top: 15px;
    font-size: 16px;
    cursor: pointer;
    margin-left: -10px;
}

.editAnswer > svg {
    height: 15px !important;
}

.recommendedCard {
    background-color: #006dff !important;
    color: #fff;
    text-align: center;
    // padding: 10px;
    padding: 10px 10px 15px 10px;
    // margin-bottom: 30px;
    font-size: 20px;
    font-weight: 700;
}
.recommendedCardDoug {
    background-color: #009cbd !important;
}
// progress bar styles

.recommendationInternetProgress
    .MuiLinearProgress-bar.MuiLinearProgress-barColorPrimary.MuiLinearProgress-bar1Determinate {
    background-color: #00d56d;
}

//filters style
.filters-div {
    text-align: left;
    display: flex;
    margin-top: 30px;

    .cardsDiv {
        margin-left: 50px;
        width: 80% !important;
        padding-bottom: 60px;
        // padding-right: 30px;
        .upperheadCards {
            display: flex;
            margin-bottom: 20px;
            justify-content: space-between;
            .heading1 {
                .editAddressAutoComplete {
                    border: 0;
                    border-bottom: 1px solid #006dff;
                    width: -moz-fit-content;
                    width: fit-content;
                    width: 350px;
                    font-size: 18px;
                    color: #006dff;
                }

                .moveLocationIcon {
                    color: #006dff !important;
                    text-decoration: underline;
                    margin-left: 10px !important;
                    margin-right: 0px !important;
                    margin-top: 5px;
                }
                .locationIcon {
                    color: #009cbd !important;
                    text-decoration: underline;
                    margin-left: 10px !important;
                    margin-right: 0px !important;
                    margin-top: 5px;
                }
            }
        }
    }
    @media (max-width: 1024px) {
        .cardsDiv {
            .upperheadCards {
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
            }
        }
    }
    @media (max-width: 768px) {
        margin-top: 10px;
        .filters {
            display: none;
        }
        .cardsDiv {
            margin-left: 0px;
            width: 100% !important;
            .upperheadCards .heading1 .editAddressAutoComplete {
                width: 335px;
            }
        }
    }
    @media (max-width: 600px) {
        .cardsDiv {
            .upperheadCards {
                flex-direction: column;
                margin-bottom: 10px;

                .heading1 {
                    font-size: 14px;
                    flex-direction: column;
                    .heading111 {
                        margin-bottom: 10px;
                    }
                    .editAddressAutoComplete {
                        font-size: 14px;
                        width: 300px;
                    }
                    .locationIcon {
                        margin: 0px 0px 0px 0px !important;
                        font-size: 18px;
                    }
                }
            }
        }
    }
}

.link1 {
    color: #009cbd;
    cursor: pointer;
    margin-right: -20px;
    font-size: 14px;
}
.linknoDoug {
    color: #006dff;
    cursor: pointer;
    font-size: 14px;
}
.link {
    color: #009cbd;
    cursor: pointer;
    font-size: 14px;
    margin-top: -7px;
}

.filtersHead {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}
.dynamicFilters {
    box-shadow: 0px 0px 4px #9c9da1;
    padding: 20px 20px 20px 15px;
    border-radius: 3px;
    margin-top: 10px;
    width: 100%;
}

.internet-cards {
    margin-top: 10px;
    text-align: center;
    @media (max-width: 600px) {
        display: none;
    }
}

.internet-cards-mobile {
    display: none;
    @media (max-width: 600px) {
        display: unset;
    }
}

.sortByInternet {
    margin-top: -10px;
    display: flex;
    align-items: center;
    .avg-customer-review-internet {
        text-align: left;
        letter-spacing: 0px;
        color: #333333;
        opacity: 1;
        text-decoration: underline;
    }
    @media (max-width: 600px) {
        .avg-customer-review-internet {
            font-size: 14px !important;
        }
    }
}
.sortByInternet .react-select {
    width: 150px;
}
.css-2b097c-container {
    width: 150px;
}

.addressCard .react-select .select-state__control {
    height: 50px !important;
    width: 99px !important;
    margin-right: 10px;
    font-size: 16px;
    @media (max-width: 600px) {
        width: calc(100vw - 70px);
        margin: 20px 0;
    }
}
.addressCard.Internet.slectedAdressIC
    .react-select
    .select-state__control
    .select-state__indicators
    .select-state__indicator {
    margin-top: -15px;
}
.addressCard.Internet .react-select .select-state__control .select-state__indicators .select-state__indicator {
    // margin-top: 10px;
}
.addressCard .react-select .css-yk16xz-control {
    border-color: #bebebe;
}

.addressCard .react-select div {
    font-size: 16px;
    color: #6b6c6f;
    padding-top: 6px;
    div {
        padding-top: 0;
    }
}
.slectedAdressIC .react-select div {
    padding-top: 6px;

    &:first-child {
        padding-top: 0;
    }
}
// .slectedAdressIC .react-select .css-b8ldur-Input {
//     transform: translateY(15px);
// }
.addressCard .error {
    font-size: 12px;
    color: red;
}
//modal

.close {
    display: none;
}

.modalHead {
    color: #333333;
    font-size: 32px;
    font-weight: bolder;
    margin-bottom: 10px;
    @media (max-width: 768px) {
        font-size: 22px;
    }
    @media (max-width: 600px) {
        padding: 10px;
        text-align: center;
    }
}

.modalSubHead {
    color: #333333;
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 30px;
    @media (max-width: 768px) {
        font-size: 18px;
    }
    @media (max-width: 600px) {
        padding: 10px;
        text-align: center;
    }
}

.modalData {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    justify-content: left;
}
.modalData .MuiSvgIcon-root {
    height: 50px !important;
    width: 100px !important;
    animation: myfirst 1s forwards;
    @media (max-width: 768px) {
        height: 40px !important;
        width: 80px !important;
    }
}

@keyframes myfirst {
    0% {
        fill: #bebebe;
    }
    99% {
        fill: #bebebe;
    }
    100% {
        fill: #00d56d;
    }
}

.textModal {
    margin-left: 10px;
    animation: myfirst1 1s forwards;
    @media (max-width: 600px) {
        font-size: 14px;
    }
}

@keyframes myfirst1 {
    0% {
        fill: #bebebe;
        font-weight: 200;
    }
    99% {
        fill: #bebebe;
        font-weight: 200;
    }
    100% {
        fill: #333333;
        font-weight: 700;
    }
}

.modalData1 {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    justify-content: left;
}
.modalData1 .MuiSvgIcon-root {
    height: 50px !important;
    width: 100px !important;
    animation: myfirst 2s forwards;
    @media (max-width: 768px) {
        height: 40px !important;
        width: 80px !important;
    }
}

.textModal1 {
    margin-left: 10px;
    animation: myfirst1 2s forwards;
    @media (max-width: 600px) {
        font-size: 14px;
    }
}

.modalData2 {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    justify-content: left;
}
.modalData2 .MuiSvgIcon-root {
    height: 50px !important;
    width: 100px !important;
    animation: myfirst 3s forwards;
    @media (max-width: 768px) {
        height: 40px !important;
        width: 80px !important;
    }
}

.textModal2 {
    margin-left: 10px;
    animation: myfirst1 3s forwards;
    @media (max-width: 600px) {
        font-size: 14px;
    }
}
.modalData3 {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    justify-content: left;
}
.modalData3 .MuiSvgIcon-root {
    height: 50px !important;
    width: 100px !important;
    animation: myfirst 4s forwards;
    @media (max-width: 768px) {
        height: 40px !important;
        width: 80px !important;
    }
}

.textModal3 {
    margin-left: 10px;
    animation: myfirst1 4s forwards;
    @media (max-width: 600px) {
        font-size: 14px;
    }
}

.main-divv-internet {
    display: flex;
    justify-content: left;
    flex-direction: column;
    @media (max-width: 600px) {
        padding: 10px;
    }
}

.viewDetailHeader {
    border-bottom: #e2e2e2 1px solid;
    height: 60px;
    font-size: 24px;
    font-weight: 800;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // box-shadow: 0px 3px blur #ededed;
    // width: 800px;
    padding: 0 15px;
}
.viewDetailBody {
    height: 445px;
    display: flex;
    @media (max-width: 600px) {
        height: calc(100vh - 135px);
    }
}
.viewDetailFooter {
    height: 75px;
    border-top: #e2e2e2 1px solid;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 10px;
}

.viewDetailTab {
    height: 50px;
    /* text-align: center; */
    display: flex;
    align-items: center;
    padding: 0 0 0 10px;
    border-bottom: #e2e2e2 1px solid;
    color: #333;
    font-size: 14px;
}

.viewDetailTab.active {
    background-color: #dce0e4;
    border-left: 5px solid #273e59;
    font-weight: 700;
}

.xfinityModal {
    height: 300px;
    margin: 25px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    font-size: 22px;
}
.editAddressAutoComplete {
    border: 0;
    border-bottom: 1px solid #009cbd;
    width: fit-content;
    width: 350px;
    font-size: 18px;
    color: #009cbd;
}
.ic-recommended .editAddressAutoComplete {
    border: 0;
    border-bottom: 1px solid #009cbd;
    width: fit-content;
    width: 350px;
    font-size: 18px;
    color: #009cbd;
    margin-top: -20px;
}
.editAddressAutoComplete:focus {
    outline: none;
}

//order page

.MuiInputLabel-filled.MuiInputLabel-shrink {
    z-index: 0 !important;
}
.main-flex {
    display: flex;
    justify-content: space-between;
    .BoxCardLeft {
        width: 57%;
    }
    .BoxCardRight {
        width: 40%;
        // margin-left: 20px;
    }
}
.BoxPlanDetailsRight {
    color: #333;
    font-size: 22px;
    font-weight: 700;
    padding: 20px;
    border-bottom: 1px solid #e2e2e2;
}
.InputDivs {
    padding: 20px;
}

.upperHeadDiv {
    padding: 15px;
    background-color: #273e59;
    color: #fff;
    font-weight: bold;
}

.upperHeadDiv1 {
    padding: 15px;
    background-color: #fff;
    color: #273e59;
    font-weight: bold;
}

.OrderInternet {
    text-align: left;

    .main-head {
        margin: 30px 0px 0px 80px;
    }
    .orderMainDivInternet {
        padding: 0 80px;
        .head {
            font-size: 34px;
            font-weight: bold;
        }
        .main-flex {
            display: flex;
            justify-content: space-between;
            .BoxCardLeft {
                width: 57%;
            }
            .BoxCardRight {
                width: 40%;
                // margin-left: 20px;
            }
        }
    }
    @media (max-width: 1366px) {
        .main-head {
            margin: 30px 0px 0px 60px;
        }
        .orderMainDivInternet {
            padding: 0 60px;
        }
    }
    @media (max-width: 600px) {
        .main-head {
            margin: 30px 0px 0px 10px;
        }
        .orderMainDivInternet {
            padding: 0 10px;
            .head {
                font-size: 24px;
            }
            .headsub {
                font-size: 12px;
                margin-top: 10px;
            }
            .main-flex {
                flex-direction: column-reverse;
                justify-content: unset;
                .BoxCardLeft {
                    width: 100%;
                }
                .BoxCardRight {
                    width: 100%;
                }
            }
        }
    }
}
.BoxCardInner {
    border: 0.5px solid #c6c6c6;
    border-radius: 4px;
    margin-top: 25px;

    .answer {
        margin-bottom: 15px;
        width: 90%;
    }
    .birthdate {
        // margin-top: 10px;
        width: 44% !important;
        margin-right: 2% !important;
    }
    .questionInternet {
        width: 90%;
    }
    .pin {
        // margin-top: 10px;
        width: 44% !important;
    }
    .birthdate .MuiFilledInput-adornedEnd {
        padding-right: 0px;
    }
    .birthdate .MuiInputLabel-filled.MuiInputLabel-shrink,
    .pin .MuiInputLabel-filled.MuiInputLabel-shrink,
    .answer .MuiInputLabel-filled.MuiInputLabel-shrink {
        z-index: 1 !important;
    }
    .MuiFormControl-marginNormal {
        margin-top: 0;
        margin-bottom: 0;
    }
    .questionInternet .select-state__menu {
        z-index: 9000;
        height: 80px !important;
    }
    .questionInternet .menuPaper {
        max-height: 80px;
    }

    .questionInternet.react-select .css-yk16xz-control {
        border: 1px solid #bebebe;
    }
    .questionInternet .select-state__control {
        height: 58px !important;
        margin-bottom: 15px;
        margin-right: 0 !important;
        padding: 0;
    }

    .birthdate .MuiInputBase-root,
    .pin .MuiInputBase-root,
    .answer .MuiInputBase-root {
        width: 100%;
        background-color: #fff;
        border: #bebebe 1px solid;
    }

    .birthdate .MuiFilledInput-underline:before,
    .birthdate .MuiFilledInput-underline:after,
    .answer .MuiFilledInput-underline:before,
    .answer .MuiFilledInput-underline:after,
    .pin .MuiFilledInput-underline:before,
    .pin .MuiFilledInput-underline:after {
        border-bottom: none;
    }
    .answer .MuiFormLabel-root.Mui-focused,
    .birthdate .MuiFormLabel-root.Mui-focused,
    .pin .MuiFormLabel-root.Mui-focused {
        color: #009cbd !important;
        z-index: 999 !important;
    }
    .answer .MuiInputBase-input:focus,
    .pin .MuiInputBase-input:focus,
    .birthdate .MuiInputBase-input:focus {
        // border: 1px solid#009cbd;
    }
    .bithdatePinDiv .MuiInputBase-root.MuiFilledInput-root.MuiFilledInput-underline.Mui-error {
        border: 1px solid red;
    }

    .btnnext {
        width: 100px;
    }
    @media (max-width: 768px) {
        .bithdatePinDiv {
            display: flex;
            flex-direction: column;
        }
        .questionInternet {
            width: 100%;
        }
        .answer {
            width: 100%;
        }
        .birthdate {
            width: 100% !important;
            margin-right: 0 !important;
            margin-bottom: 15px;
        }
        .pin {
            width: 100% !important;
        }
        .btnnext {
            width: 100%;
        }
    }
}
.scheduleCallInnerDivsDetails {
    display: flex;
    padding: 0 50px;
    .scheduleCallInnerDivsDetails1 {
        display: flex;
        margin-right: 30px;
    }
    @media (max-width: 1024px) {
        padding: 0 30px;
    }
    @media (max-width: 768px) {
        padding: 0;
        .scheduleCallInnerDivsDetails1 {
            margin-right: 10px;
        }
    }
}
.btnOrder {
    margin-top: 20px !important;
}

.headLabels {
    color: #273e59;
    font-size: 16px;
    font-weight: 700;
    margin-left: 15px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.answerDivs {
    margin-left: 45px;
    margin-bottom: 15px;
}

.spanNumber {
    background-color: #fff;
    color: #273e59;
    border-radius: 50%;
    padding: 3px 9px;
    margin-right: 10px;
    border: #273e59 1px solid;
}

.spanNumber1 {
    background-color: #273e59;
    color: #fff;
    border-radius: 50%;
    padding: 3px 9px;
    margin-right: 10px;
}

.orderConciergeName {
    color: #333;
    font-size: 18px;
    font-weight: 700;
}

.orderConciergeNameLabel {
    color: #747e8b;
    font-size: 12px;
}

.planDetailsTags {
    color: #989898;
    font-size: 16px;
    font-weight: bold;
    margin: 10px 0;
}

.planDetailsTags1 {
    color: #333333;
    font-size: 16px;
    margin-bottom: 15px;
}

.boxPlanDetail {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
    padding: 0 20px;
}
.orderSummaryDetailHeader {
    color: #333;
    font-size: 22px;
    font-weight: 700;
    padding: 20px;
    border-bottom: 1px solid #e2e2e2;
}
// .boxPlanDetail1 {
//     border-bottom: 1px solid #e2e2e2;
// }
.abcdLine {
    border-bottom: 1px solid #e2e2e2;
}
.orderSummaryPrice {
    color: #333;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    border-bottom: 1px solid #e2e2e2;
}

.secureTab {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
    @media (max-width: 600px) {
        display: none;
    }
}

.placeOrderBtnInternet {
    display: flex;
    justify-content: center;
    margin: 20px;
}

// order summary
.summayInternet-div {
    display: flex;
    justify-content: center;
    .summaryInternet {
        border: 1px solid #e2e2e2;
        width: 520px;
        margin-top: 25px;
        .summaryInternetHead {
            height: 120px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            .summaryInternettitle {
                font-size: 24px;
                font-weight: 800;
                margin-bottom: 10px;
            }
            .summaryInternetsubtitle {
                font-size: 16px;
                padding: 0px 20px;
                line-height: 1.5;
            }
        }
    }
    @media (max-width: 600px) {
        margin: 0 10px;
        .summaryInternet {
            width: 100%;
            .summaryInternetHead {
                .summaryInternettitle {
                    font-size: 22px;
                    padding: 0px 0px;
                    line-height: unset;
                }
                .summaryInternetsubtitle {
                    font-size: 12px;
                }
            }
        }
    }
}

.summaryOrderTag {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    .orderTitleInternet {
        color: #617d31;
        font-size: 22px;
        margin: 8px 0;
        font-weight: bold;
    }
    .orderTitleInternet1 {
        color: #333;
        font-size: 16px;
        margin: 0 0 8px 0;
        width: 500px;
    }
    @media (max-width: 600px) {
        .orderTitleInternet1 {
            width: calc(100% - 10px);
        }
    }
}

.summaryBoxPlanDetail {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 15px;
    padding: 10px;
    border: 1px solid #e2e2e2;
    .summaryBoxPlanDetailcompanyImg {
        width: 25%;
        margin: 0 auto;
    }
    .summaryBoxPlanDetailContent {
        width: 65%;
        text-align: left;
    }
    @media (max-width: 600px) {
        flex-direction: column;
        justify-content: center;
        padding: 10px 20px;
        .summaryBoxPlanDetailcompanyImg {
            margin: 20px 0 20px 0;
        }
        .summaryBoxPlanDetailContent {
            text-align: left;
            width: 100%;
        }
    }
}

.summaryBoxPlanDetail1 {
    margin: 15px;
    // padding: 10px;
    border: 1px solid #e2e2e2;
    .summaryBoxPlanConcierge {
        display: flex;
        justify-content: space-between;
        margin: 20px;
    }
    .summaryBoxPlanConciergeImg {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin: 0 20px 0 0;
    }
    .summaryBoxPlanConciergeCall {
        display: flex;
        margin-top: 15px;
        align-items: center;
        .summaryBoxPlanConciergeCallImg {
            width: 19px;
            margin: 0px 4px 0 0;
            height: 19px;
        }
        .summaryBoxPlanConciergeCallNumber {
            font-size: 14px;
            color: #333;
        }
    }
    @media (max-width: 600px) {
        .summaryBoxPlanConcierge {
            flex-direction: column;
            justify-content: center;
        }
        .summaryBoxPlanConcierge1 {
            flex-direction: column !important;
            justify-content: center;
        }
        .summaryBoxPlanConciergedetail {
            display: flex;
        }
        .summaryBoxPlanConciergeImg {
            margin: 0 5px 0 0;
        }
        .summaryBoxPlanConciergeCall {
            align-items: flex-start;
            margin-top: 0;
            .summaryBoxPlanConciergeCallImg {
                width: 16px;
                height: 16px;
            }
            .summaryBoxPlanConciergeCallNumber {
                font-size: 14px;
            }
        }
        .summaryBoxPlanConciergeScheduleCall {
            display: flex;
            justify-content: center;
            margin-top: 20px;
        }
    }
}

.priceDetailInternet {
    color: #333;
    font-size: 22px;
    font-weight: 700;
    text-align: left;
    padding: 20px;
    border-bottom: 1px solid #e2e2e2;
}

.marginRemove {
    margin-top: -5%;
    // margin-left: -20px;
}

// address

.addressCard.Internet .innerInput1 .MuiFormControl-root.MuiTextField-root.input1 {
    width: 98% !important;
}
.addressCard.Internet .MuiInputLabel-filled.MuiInputLabel-shrink {
    z-index: 9 !important;
}
.addressCard .css-1pahdxg-control:hover {
    // border: 1px solid#009cbd !important;
}
.stateTextAddressInternet {
    color: #ada1a1;
    position: absolute;
    z-index: 9;
    font-size: 12px;
    transform: translate(7px, 5px) scale(0.75);

    @media (max-width: 600px) {
        transform: translate(7px, 25px) scale(0.75);
    }
}

.addressCard.Internet .input1 .MuiFilledInput-input,
.addressCard.Internet .innerInput1 .MuiFilledInput-input {
    padding: 20px 12px 10px;
}

.addressCard.Internet .input1 .MuiInputBase-input,
.addressCard.Internet .innerInput1 .MuiInputBase-input {
    border: 1px solid #bebebe;
    background-color: #fff;
    text-align: left;
    border-radius: 5px;
    margin-top: 0px;
}
.addressCard.Internet .input1 .MuiInputBase-input:focus,
.addressCard.Internet .innerInput1 .MuiInputBase-input:focus {
    border: 1px solid #006dff !important;
}
.addressCard.Internet .innerInput1D .MuiInputBase-input:focus {
    border: 1px solid #009cbd !important;
}
.addressCard.Internet .input2 .MuiInputBase-input:focus,
.addressCard.Internet .input4 .MuiInputBase-input:focus {
    border: 1px solid #006dff !important;
}
.addressCard.Internet .input2D .MuiInputBase-input:focus,
.addressCard.Internet .input4D .MuiInputBase-input:focus {
    border: 1px solid #009cbd !important;
}
.addressCard.Internet .input1 .MuiFormLabel-root.Mui-focused,
.addressCard.Internet .innerInput1 .MuiFormLabel-root.Mui-focused,
.addressCard.Internet .input2 .MuiFormLabel-root.Mui-focused,
.addressCard.Internet .input4 .MuiFormLabel-root.Mui-focused {
    color: #006dff !important;
    z-index: 999 !important;
}
.addressCard.Internet .input1D .MuiFormLabel-root.Mui-focused,
.addressCard.Internet .innerInput1D .MuiFormLabel-root.Mui-focused,
.addressCard.Internet .input2D .MuiFormLabel-root.Mui-focused,
.addressCard.Internet .input4D .MuiFormLabel-root.Mui-focused {
    color: #009cbd !important;
}

.addressCard.Internet .input1 .MuiFilledInput-underline:before,
.addressCard.Internet .innerInput1 .MuiFilledInput-underline:before,
.addressCard.Internet .input4 .MuiFilledInput-underline:before,
.addressCard.Internet .input2 .MuiFilledInput-underline:before {
    border-bottom: none !important;
}
.addressCard.Internet .input1 .MuiFilledInput-underline:after,
.addressCard.Internet .input2 .MuiFilledInput-underline:after,
.addressCard.Internet .input4 .MuiFilledInput-underline:after,
.addressCard.Internet .innerInput1 .MuiFilledInput-underline:after {
    border-bottom: none !important;
    border: 1xp solid #009cbd;
}

.addressCard.Internet .css-2b097c-container {
    // width: fit-content;
    height: 50px !important;
    width: 99px !important;
    margin-right: 10px;
    font-size: 16px;
    @media (max-width: 600px) {
        width: calc(100vw - 70px);
        margin: 20px 0;
    }
}

.addressCard.Internet .input2 .MuiFilledInput-input,
.addressCard.Internet .input4 .MuiFilledInput-input {
    border: 1px solid #bebebe !important;
    background-color: #fff !important;
    text-align: left;
    height: 12px;
    border-radius: 5px;
}

.addressCard.Internet .input2 {
    width: 200px;
    margin-right: 10px;
    @media (max-width: 600px) {
        width: 98%;
    }
}
.addressCard.Internet .input4 {
    width: 120px;
    @media (max-width: 600px) {
        width: 98%;
    }
}

.internetStepper {
    display: none !important;
}
// .linearProgressInternet .MuiLinearProgress-barColorPrimary {
//     // color: #273e59;
//     color: #009cbd;
// }
.internetFooter .footerDashboard {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border: #e2e2e2 1px solid;
    padding: 5px;
    margin-top: 50px;
    z-index: 999;
    .footerNameDetails {
        .footerNameDetails1 {
            color: #333333;
            font-size: 16px;
            font-weight: 700;
        }
        .footerNameDetails2 {
            color: #747e8b;
            font-size: 12px;
        }
    }
    .footerNamequestion {
        display: none;
    }
    .footerQuestion {
        color: #333333;
        font-size: 18px;
        font-weight: 500;
        margin: 0 25px;
    }
    .footerCallDetails {
        display: flex;
    }

    @media (max-width: 1024px) {
        .footerQuestion {
            font-size: 16px;
            margin: 0 10px;
        }
    }
    @media (max-width: 960px) {
        .footerNameDetails {
            margin-right: 10px;
        }
        .footerQuestion {
            display: none;
        }
    }
    @media (max-width: 768px) {
        position: fixed;
        margin-top: 10px;
        .footerNamequestion {
            display: block;
            margin-right: 20px;
            .footerNamequestion1 {
                color: #333333;
                font-size: 16px;
                font-weight: 700;
            }
            .footerNamequestion2 {
                color: #747e8b;
                font-size: 12px;
            }
        }
        .footerNameDetails {
            display: none;
        }
        .footerCallDetails {
            display: none;
        }
    }
}
.scheduleCallInternet {
    position: absolute;
    width: 70%;
    height: 90%;
    background-color: #ffffff;
    left: 15%;
    top: 5%;
    .scheduleCallBack {
        display: none;
    }
    // @media (max-width: 1440px) {
    //     left: 25%;
    // }
    // @media (max-width: 1024px) {
    //     left: 15%;
    // }
    // @media (max-width: 960px) {
    //     left: 5%;
    // }
    @media (max-width: 600px) {
        left: 0;
        top: 0;
        z-index: 2000;
        width: 100%;
        height: 100%;
        .scheduleCallBack {
            display: block;
            padding: 30px 0 0 30px;
        }
    }
}
.internetRecommendationfilterModal {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    // padding: 15px;
    height: 100%;
    background-color: #fff;
    overflow: auto;
    .ModalFilter {
        width: calc(100% - 50px);
        margin: 25px;
    }
    .filtersHead {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: calc(100% - 60px);
        margin: 20px;
        .link1 {
            color: #009cbd;
            cursor: pointer;
            font-size: 14px;
        }
    }
}
.recommendationInternetViewDetails {
    position: absolute;
    width: 800px;
    height: 580px;
    background-color: #ffffff;
    left: 30%;
    top: 10%;
    @media (max-width: 1600px) {
        left: 25%;
    }
    @media (max-width: 1367px) {
        left: 20%;
    }
    @media (max-width: 1024px) {
        left: 10%;
    }
    @media (max-width: 768px) {
        width: 600px;
        left: 10%;
    }
    @media (max-width: 600px) {
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
    }
}
.xfinityModalInternet {
    position: absolute;
    width: 500px;
    height: 350px;
    background-color: #ffffff;
    left: 40%;
    top: 25%;
    @media (max-width: 1600px) {
        left: 35%;
    }
    @media (max-width: 1367px) {
        left: 30%;
    }
    @media (max-width: 1024px) {
        left: 25%;
    }
    @media (max-width: 768px) {
        left: 16%;
    }
    @media (max-width: 600px) {
        width: 100%;
        left: 0;
    }
}
.filterButtonrecommendation {
    display: none;
    @media (max-width: 768px) {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        order: 3;
        color: #333;
    }
}

.headingFilter1 {
    display: flex;
    font-size: 22px;
    font-weight: 600;
}
.loaderInternetRecomendation {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    position: absolute;
    width: 100%;
    background-color: #ffffff;
    left: 0px;
    top: 0px;

    @media (min-width: 600px) and (max-height: 700px) {
        top: 30px;
    }
    @media (min-width: 600px) and (max-height: 650px) {
        top: 75px;
    }
    @media (min-width: 600px) and (max-height: 500px) {
        top: 115px;
    }
}

.what-next-main-div {
    width: 520px;
    margin: 20px auto;

    @media (max-width: 600px) {
        width: 100%;
    }

    .what-next {
        font-size: 22px;
        margin: 10px 0;
        @media (max-width: 600px) {
        }
    }

    .card-1-next,
    .card-2-next {
        padding: 20px;
        border: 1px solid #bebebe;
        box-shadow: 0px 0px 2px #e2e2e2;
        margin-bottom: 20px;
        border-radius: 3px;
    }

    .card-1-next {
        background: url('../../Assets/images/InsuranceCard.svg');
        background-size: cover;
        background-repeat: no-repeat;
    }

    .card-2-next {
        background: url('../../Assets/images/SecurityCard.png');
        background-size: cover;
        background-repeat: no-repeat;
    }
    .card-heading-next {
        width: 60%;
        font-size: 22px;
        font-weight: bold;
    }
    .highlighted {
        color: #30ab86;
    }
    .button-next-card {
        width: 100px !important;
        height: 40px !important;
        font-size: 14px !important;
        margin: 10px 0 !important;
        border-radius: 5px !important;
    }
}
.makeStyles-barColorPrimary-19 {
    background: #009cbd !important;
}
.progressStepD .MuiLinearProgress-barColorPrimary {
    background: #009cbd !important;
}
.progressStep .MuiLinearProgress-barColorPrimary {
    background: #006dff !important;
}

.editAddressAutoCompleteTrue {
    color: #009cbd !important;
    border-bottom-color: #009cbd !important ;
}
.addressCard.react-select .css-yk16xz-control {
    border-color: #100b28 !important;
}

.addressCard .react-select .select-new__control {
    min-height: 50px !important;
    border: 1px solid #bebebe !important;
    // border-radius: 0px !important;
}
.AddressCardInternetDouglas .react-select .select-new__control {
    border-color: #100b28 !important;
    border-radius: 0px !important;

    input {
        margin-top: 10px;
    }
}
.AddressCardInternetDouglas .react-select .spec-state-input {
    border-radius: 0 !important;
}

.douglasEllimenSite {
    .main-div-internet-main {
        .BlackCard {
            .mainCard {
                background: transparent !important;
                border: none !important;
                width: 489px;
                padding: 20px 10px 20px 30px;
            }
        }
    }
}
