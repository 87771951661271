// .confirmSavingsWhiteBackground {
//     display: flex;
//     justify-content: space-between;
// }
.confirmSavingsPage1 {
    min-height: 80vh;

    // padding-top: 78px;
    padding: 78px 40px 0px 40px;
    @media (max-width: 325px) {
        padding: 78px 20px 0px 20px;
    }
    .confirmSavingsWhiteBackground1 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 60px;
        @media (max-width: 1000px) {
            flex-direction: column;

            justify-content: center;
            align-items: center;
        }
        .mainConfirmRight {
            width: 40%;
            @media (max-width: 1000px) {
                width: 100%;
                display: flex;
                justify-content: center;
            }
            @media (max-width: 600px) {
                display: none;
            }
        }
        .mainSave {
            display: none;
            @media (max-width: 600px) {
                display: unset;
            }
        }
        .mainConfirmLeftSavings {
            width: 55%;
            @media (max-width: 1204px) {
                width: 45%;
            }
            @media (max-width: 1000px) {
                width: 100%;
                display: flex;
                align-items: center;
                flex-direction: column;
            }
        }
        .mainHeadConfirm {
            font-size: 32px;
            font-weight: bold;
            font-family: 'Roboto';
            padding-bottom: 20px;
            @media (max-width: 1000px) {
                text-align: center;
                padding-top: 10px;
            }
            @media (max-width: 600px) {
                font-size: 18px;
            }
        }
        .subHeadConfirm {
            font-size: 22px;
            font-family: 'Roboto';
            color: #6b6c6f;
            padding-bottom: 10px;
            @media (max-width: 1000px) {
                text-align: center;
            }
            @media (max-width: 600px) {
                font-size: 14px;
            }
        }
    }
}

.buttonMovedToNextStage {
    display: flex;
    align-items: center;
    padding-top: 30px;

    @media (max-width: 1000px) {
        padding-bottom: 20px;
    }
    @media (max-width: 325px) {
        flex-direction: column;
    }
    .dashboardGoBack {
        font-size: 14px;
        color: #0066f5;
        padding-left: 10px;
        font-family: 'Roboto';
        cursor: pointer;
    }
}
