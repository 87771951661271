.waranty-thankyou-main {
    display: flex;
    justify-content: center;
}
.waranty-thanku-submain {
    display: flex;
    flex-direction: column;
    width: 100%;
    // height: auto;
}
.testing {
    width: 50%;
    margin: auto;
    .waranty-thanku-Heading-div {
        background-color: #273e59;
        // width: 100%;
        padding: 30px 25px 30px 25px;
        margin-top: 50px;
        border: 1px solid #e2e2e2;
        border-radius: 4px 4px 0px 0px;
        @media (max-width: 600px) {
            padding: 20px 5px 20px 5px;

            // width: 100%;
        }
        .heading-main {
            font-size: 24px;
            text-align: center;
            color: #fff;
            font-weight: bold;
            @media (max-width: 600px) {
                font-size: 14px;
            }
        }
        .heading-small {
            font-size: 16px;
            text-align: center;
            color: #fff;
            padding-top: 5px;
            @media (max-width: 600px) {
                font-size: 12px;
            }
        }
    }
    .waranty-thanku-content {
        // width: 100%;
        border: 1px solid #e2e2e2;
        border-radius: 4px;
        padding: 30px 25px 30px 25px;
        .waranty-thanku-subcontent {
            border: 1px solid #e2e2e2;
            border-radius: 4px;
            font-size: bold;
            // width: 80%;
            // padding: 26px 22px 30px 20px;
            .quote-heading {
                font-size: 22px;
                font-weight: 500;
                padding: 18px;
                @media (max-width: 600px) {
                    font-size: 16px;
                    font-weight: bold;
                    padding: 10px 7px 18px 7px;
                }
            }
        }
        @media (max-width: 600px) {
            padding: 0px;
            margin-top: 15px;
            // width: 100%;
        }
    }
    @media (max-width: 768px) {
        width: 90%;
        margin: auto;
    }
    @media (max-width: 320px) {
        width: 100%;
        margin: auto;
    }
}
.inside-modal-content-main {
    display: flex;
    justify-content: space-between;
    padding: 18px;
    align-items: center;

    span {
        width: 15%;
    }
    @media (max-width: 600px) {
        font-size: 13px;
        padding: 18px 7px 18px 7px;
    }
}
.inside-modal-content {
    font-size: 16px;
    font-style: Roboto;
    display: flex;
    align-items: center;

    @media (max-width: 600px) {
        font-size: 13px;
    }
}
.border-thanku {
    border-bottom: 1px solid #e2e2e2;
}
.inside-modal-content-main-first {
    display: flex;
    flex-direction: column;
    padding: 18px;
}
.inside-content-first-row {
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
}
.waranty-button {
    display: flex;
    justify-content: flex-end;
    padding-top: 30px;
    margin-bottom: 65px;
    .thankuWaranty {
        width: 25% !important;
        @media (max-width: 600px) {
            width: 45% !important;
        }
    }
    @media (max-width: 600px) {
        justify-content: center;
    }
}
.closeIconWaranty {
    float: right;
}
.subHeadingWarantyModal {
    margin: 20px auto 0px auto;
    font-size: 14px;
    text-align: center;
    font-weight: 500;
    padding: 0px 40px;
    @media (max-width: 600px) {
        padding: 0px 0px;
        font-size: 11px;
        font-weight: normal;
        margin: 0px;
    }
}
.sendDocuments {
    font-size: 18px;
    text-align: left;
    font-weight: 500;
    padding: 45px 0px 4px 0px;
    @media (max-width: 600px) {
        font-size: 15px;
        padding: 30px 0px 4px 0px;
    }
}
.sendAddress {
    font-size: 14px;
    text-align: left;

    padding: 0px 0px 4px 0px;
    @media (max-width: 750px) {
        font-size: 13px;
        color: #333333;
    }
}

.closeDocument {
    background-color: #f2f5f8;
    padding: 12px;
    @media (max-width: 600px) {
        padding: 10px 12px 10px 12px;
    }
}
.closeDocumentInner {
    font-size: 16px;
    font-weight: 500;
    color: #6b6c6f;
    padding-bottom: 5px;
    @media (max-width: 750px) {
        font-size: 13px;
        color: #333333;
        padding-bottom: 1px;
    }
}

// .tableheader123 {
//     display: flex;
//     justify-content: space-between;
//     .td123 {
//         width: 10%;
//     }
//     .td123-r {
//         width: 30%;
//     }
// }

.tableSetting {
    padding: 26px 0px;
}
.warantyThankuPage {
    border: 1px solid #e2e2e2;
    border-radius: 4px;
}
.waranty-thanku-rightnow {
    font-size: 18px;
    font-family: 'Roboto';
    font-weight: bold;
    color: #333333;
    padding-bottom: 12px;
    @media (max-width: 600px) {
        font-size: 15px;
        font-weight: 600;
        padding-bottom: 6px;
    }
}
.waranty-thanku-payment {
    font-size: 16px;
    font-family: 'Roboto';
    line-height: 26px;
    padding-bottom: 25px;
    color: #6b6c6f;
    @media (max-width: 600px) {
        font-size: 12px;
        font-weight: 'normal';
        padding-bottom: 14px;
    }
}
.waranty-thanku-contact {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-family: 'Roboto';
    font-weight: 500;
    .checkit {
        color: green !important;
        margin-right: 10px;
    }
}
.waranty-thanku-contact-main {
    border: 1px solid #e2e2e2;
    border-radius: 4px;
    padding: 19px 60px 19px 26px;
    margin-top: 20px;
    @media (max-width: 600px) {
        padding: 12px 12px 16px 16px;
    }
}
.waranty-thanku-home {
    display: flex;
    justify-content: center;
    margin-top: 60px;
}

.modalHomeWaranty {
    // position: absolute;
    width: 650px;
    height: 80%;
    background-color: #ffffff;
    // left: 40%;
    // top: 5%;
    overflow: auto;
    padding: 0px 26px 26px 26px;

    .scheduleInsuranceCall {
        height: 70%;
    }

    .date11 {
        width: 100%;
        margin-right: 0;
    }

    .error {
        height: 20px;
    }

    // @media (max-width: 1500px) {
    //     top: 8%;
    //     left: 35%;
    // }

    // @media (max-width: 1400px) {
    //     left: 30%;
    //     top: 2%;
    // }

    // @media (max-width: 1600px) {
    //     left: 27%;
    // }
    // @media (max-width: 1367px) {
    //     left: 23%;
    // }
    // @media (max-width: 1024px) {
    //     left: 19%;
    //     height: 85%;
    // }
    // @media (max-width: 768px) {
    //     left: 4%;
    //     height: 85%;
    // }
    @media (max-width: 750px) {
        width: 70%;
        // height: 100%;
        // top: 0;
        // left: 8%;
    }
    @media (max-width: 600px) {
        width: 88%;
        padding: 0px 9px 26px 9px;

        // height: 100%;
        // top: 0;
        // left: 8%;
    }
}
.modalHomeWarantyD {
    .MuiCheckbox-colorSecondary.Mui-checked {
        color: #009cbd !important;
    }
}
.additionCardsAdd {
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    color: #0066f5;
    @media (max-width: 600px) {
        font-size: 10px;
    }
}
.buttonThanku {
    display: flex;
    justify-content: center;
    // margin-bottom: 60px;
    // padding-top: 40px;
    // position: absolute;
    // bottom: 10%;
    align-items: center;
    // left: 25%;
    // right: 25%;
}
.modalClassesWarn {
    position: absolute;
    width: 500px;
    height: 550px;
    max-height: 80vh;
    background-color: #ffffff;
    left: 40%;
    top: 5%;
    overflow: auto;

    .scheduleInsuranceCall {
        height: 70%;
    }

    .date11 {
        width: 100%;
        margin-right: 0;
    }

    .error {
        height: 20px;
    }

    @media (max-width: 1600px) {
        left: 35%;
    }
    @media (max-width: 1367px) {
        left: 30%;
    }
    @media (max-width: 1024px) {
        left: 25%;
    }
    @media (max-width: 768px) {
        left: 18%;
    }
    @media (max-width: 600px) {
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
    }
}
.subHeadingWarantyModalThanku {
    font-size: 24px;
    font-weight: bold;
    // position: absolute;
    // bottom: 25%;
    text-align: center;
    // @media(max-width: 600px){

    // }
}
.iconDivWaranty {
    height: 215px;
    @media (max-width: 768px) {
        height: 145px;
    }
}
.modalClassesSecureWaranty {
    width: 50%;
    height: 85%;
    background-color: #ffffff;

    overflow: auto;
    padding: 30px 16px;
    @media (max-width: 600px) {
        width: 85%;
    }

    .HeadingWarantyModalThanku {
        font-size: 22px;
        font-family: Roboto;
        font-weight: 600;
        text-align: center;
        line-height: 32px;
        padding: 0px 10px 20px 10px;
        @media (max-width: 768px) {
            font-size: 18px;
        }
    }
    .subHeadingWarantyModalThanku {
        font-size: 16px;

        font-weight: 500;
        text-align: center;
        color: #6b6c6f;
        line-height: 26px;
        padding: 0px 10px 26px 10px;
        @media (max-width: 768px) {
            font-size: 12px;
        }
    }
    .modalTableThanku {
        display: flex;
        flex-direction: column;
        border: 1px solid #e0e0e0;
        margin-bottom: 26px;

        .modalTableSubContentThanku {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 15px 20px;
            .contentTable {
                font-size: 16px;
                font-weight: 500;
                font-family: Roboto;
                @media (max-width: 768px) {
                    font-size: 12px;
                }
            }
        }
        .borderLineThanku {
            border: 1px solid #e0e0e0;
        }
    }
}

// .jagWxH .checkmark {
//     width: 30% !important;
//     height: 40% !important;

// }
.enZcGT .checkmark {
    width: 27% !important;
    height: 40% !important;
}

.additionalInputButtonDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    .additionalInputDiv {
        width: 68%;
    }

    .ButtonDiv {
        width: 30%;
        display: flex;

        .addButton {
            border-radius: 4px;
        }
    }
}
