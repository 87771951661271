.updateAddVerification,
.updateAddSubmitModal,
.updateAddDelModal {
    position: absolute;
    top: 25%;
    // width: 400px;
    width: 378px;
    left: 35%;
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #e2e2e2;

    .updateAddModalErr {
        display: flex;
        justify-content: center;
        color: #f44336;
        font-size: 14px;
    }
    @media (max-width: 1600px) {
        left: 35%;
    }
    @media (max-width: 1367px) {
        left: 30%;
    }
    @media (max-width: 1024px) {
        left: 25%;
    }
    @media (max-width: 768px) {
        left: 18%;
    }
    @media (max-width: 600px) {
        // width: 100vw;
        // left: 0;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
    }
}
.updateAddressModals {
    .MuiInputLabel-filled.MuiInputLabel-shrink {
        z-index: 1 !important;
        color: #100b28;
    }
    input {
        background-color: #fff;
        border: 1px solid #100b28 !important;
        border-radius: 0px !important;
        color: #323744;
    }
}
.updateAddSubmitModal {
    width: 488px;
    @media (max-width: 1600px) {
        left: 35%;
    }
    @media (max-width: 1367px) {
        left: 30%;
    }
    @media (max-width: 1024px) {
        left: 25%;
    }
    @media (max-width: 768px) {
        left: 18%;
    }
    @media (max-width: 600px) {
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
    }
}

.updateAddDelModal {
    width: 436px;
    @media (max-width: 1600px) {
        left: 35%;
    }
    @media (max-width: 1367px) {
        left: 30%;
    }
    @media (max-width: 1024px) {
        left: 25%;
    }
    @media (max-width: 768px) {
        left: 18%;
    }
    @media (max-width: 600px) {
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
    }
}
.updateAddMain {
    padding-top: 44px;
    padding-bottom: 30px;

    @media (max-width: 600px) {
        height: 90vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: unset;
    }

    .updateAddHeading {
        color: #333333;
        font-size: 24px;
        text-align: center;
    }

    .updateAddSubHeading {
        color: #707070;
        font-size: 16px;
        text-align: center;
        padding-top: 11.5px;
        margin-left: 14%;
        margin-right: 14%;
    }

    .updateAddRow {
        display: flex;
        justify-content: center;
        padding-top: 27px;

        .updateAddPhone input {
            width: 294px;
            height: 11px;
            background-color: #fff;
            border: 1px solid #bebebe;
            border-radius: 5px;
            font-size: 14px;
            color: #333333;
            @media (max-width: 320px) {
                width: 260px;
            }
        }
        .updateAddPhone .MuiFormLabel-root.Mui-focused {
            color: #006dff !important;
        }
        .updateAddPhoneDoug .MuiFormLabel-root.Mui-focused {
            color: #009cbd !important;
        }
        .updateAddPhone .MuiFilledInput-underline:before,
        .updateAddPhone .MuiFilledInput-underline::after {
            border-bottom: none !important;
        }
        .updateAddPhone input:focus {
            background-color: #fff;
            border: unset;
            border: 1px solid #006dff !important;
            border-radius: 5px;
        }
        .updateAddPhoneDoug input:focus {
            background-color: #fff;
            border: unset;
            border: 1px solid #009cbd !important;
            border-radius: 5px;
        }
        .updateAddPhone .MuiInputLabel-filled.MuiInputLabel-shrink {
            z-index: 1 !important;
            color: #6b6c6f;
        }
    }
}

.updateAddLink {
    color: #006dff !important;
    font-size: 14px;
    text-align: center;
    padding-top: 21px;
    cursor: pointer;
}
.updateAddLinkDoug {
    color: #009cbd !important;
    font-size: 14px;
    text-align: center;
    padding-top: 21px;
    cursor: pointer;
}

.updateAddSubmitMain {
    padding: 23px 26px;
    @media (max-width: 600px) {
        height: 90vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-top: unset;
        padding-bottom: unset;
    }
    .updateAddCircle,
    .updateAddMakeReq {
        display: flex;
        justify-content: center;
    }
    .updateAddSuccessText {
        color: #333333;
        font-size: 24px;
        text-align: center;
    }
    .updateAddMakeReq {
        padding-top: 16px;
        padding-bottom: 21px;
    }
}

.updateAddDelMain {
    padding: 34px 24px 36px 24px;

    @media (max-width: 600px) {
        padding: 0 24px 0 24px;
        height: 90vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .updateAddDel {
        display: flex;
        justify-content: center;
    }

    .updateAddConfirmText {
        color: #333333;
        font-size: 24px;
        text-align: center;
        padding-top: 10px;
    }
    .updateAddDelBtnWrapper {
        display: flex;
        justify-content: center;
        padding-top: 20px;
        .updateAddCancelBtn:hover {
            border-radius: 4px;
            border: 1px solid #002e62;
        }

        .updateAddDelBtn {
            margin-left: 10px;
        }
        .updateAddDelBtn:hover {
            border-radius: 4px;
            border: 1px solid #d92f25;
        }
    }
}
