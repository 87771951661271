.trustedPros-AlmostDonePage {
    .MuiCheckbox-colorSecondary.Mui-checked {
        color: #006dff !important;
    }
    padding: 0 80px;
    width: 80%;

    .slectedVendorsDiv {
        width: 38%;
        border: 1px solid #c6c6c6;
        border-radius: 4px;
        height: fit-content;

        .venderList {
            padding: 10px 20px;
        }

        .selectedVendorsTitleDiv {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 20px;
            border-bottom: 1px solid #c6c6c6;
        }
        .selectedVendorsTitle {
            color: #333333;
            font-size: 22px;
            line-height: 30px;

            @media (max-width: 1024px) {
                font-size: 18px;
            }
        }
        .selectedVendorsAddButton {
            font-size: 14px;
            line-height: 18px;
            color: #0066f5;
            cursor: pointer;
        }

        @media (max-width: 600px) {
            width: 100%;
            margin-top: 15px;
        }
    }
    .main-title {
        font-size: 24px;
        line-height: 28px;
        font-weight: bold;

        @media (max-width: 600px) {
            font-size: 22px;
        }
    }

    .main-para {
        font-size: 16px;
        line-height: 28px;

        @media (max-width: 600px) {
            font-size: 12px;
        }
    }

    .almostDoneFlexDiv {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;

        @media (max-width: 600px) {
            flex-wrap: wrap;
            justify-content: center;
        }
    }

    .scheduleAppointmentDiv {
        width: 60%;
        border: 1px solid #c6c6c6;

        .appointmentTitle {
            padding: 20px;
            font-size: 16px;
            line-height: 22px;
            font-weight: bold;
        }

        .formDiv {
            padding: 35px 20px;

            .allInfoDiv {
                border-bottom: 1px solid #c6c6c6;
                padding-bottom: 20px;
            }

            .row1Div,
            .row2Div {
                display: flex;
                justify-content: space-between;
                margin: 10px 0 30px;

                .MuiOutlinedInput-input {
                    padding: 27px 12px 10px;
                }

                .MuiInputBase-input {
                    border: 1px solid #bebebe;
                    border-radius: 5px;
                    background-color: #fff;
                    text-align: left;
                }
                .MuiInputBase-input:focus {
                    border: 1px solid #006dff !important;
                }
                .MuiFilledInput-root.Mui-error .MuiInputBase-input {
                    border: 1px solid #f44336;
                }

                .MuiInputLabel-outlined.MuiInputLabel-shrink {
                    transform: translate(14px, 4px) scale(0.75);
                    color: #006dff !important;
                }

                .MuiOutlinedInput-notchedOutline {
                    border: none;
                }

                .MuiFormLabel-root {
                    font-size: 12px;
                    line-height: 22px;
                    color: #6b6c6f;
                }

                .MuiFormLabel-root.Mui-focused {
                    color: #006df5 !important;
                }

                .leftInputDiv {
                    width: 39%;
                }

                .rightInputDiv {
                    width: 59%;
                }

                .MuiFormControl-root {
                    width: 100%;
                }
            }

            .row2Div {
                margin: 10px 0;
            }
            .InputDoug {
                .MuiInputBase-input:focus {
                    border: 1px solid #009cbd !important;
                }

                .MuiInputLabel-outlined.MuiInputLabel-shrink {
                    transform: translate(14px, 4px) scale(0.75);
                    color: #009cbd !important;
                }
                .MuiFormLabel-root.Mui-focused {
                    color: #009cbd !important;
                }
            }
        }

        .mailPhoneDiv {
            margin-top: 20px;

            .textDiv {
                font-size: 14px;
                font-weight: bold;
                line-height: 24px;
                display: flex;
                align-items: center;
                color: #333333;

                .InfoIcon {
                    color: #333333;
                    font-size: 14px !important;
                    margin-left: 10px;
                }
            }

            .MuiOutlinedInput-input {
                padding: 27px 12px 10px;
            }

            .MuiOutlinedInput-notchedOutline {
                border: none;
            }

            .MuiFormControl-root {
                border: 1px solid #d8dce6;
                border-radius: 4px;
            }

            .MuiInputLabel-outlined.MuiInputLabel-shrink {
                margin-top: 12px;
            }

            .MuiFormLabel-root {
                font-size: 12px;
                line-height: 22px;
                color: #6b6c6f;
            }

            .mailPhoneFlexDiv {
                display: flex;
                padding: 10px 0;

                .MuiFormControl-root {
                    width: 48%;
                }
            }
        }

        .trustedProsAppointmentButton {
            font-size: 14px !important;
            line-height: 22px !important;
            font-weight: bold !important;

            @media (max-width: 600px) {
                width: 100% !important;
            }
        }

        @media (max-width: 600px) {
            width: 100%;
        }
    }

    @media (max-width: 1366px) {
        padding: 0 60px;
    }

    @media (max-width: 1024px) {
        width: 88%;
    }

    @media (max-width: 600px) {
        padding: 0 10px;
        width: 95%;
    }
}
.trustedPros-AlmostDonePageDouglas {
    .selectedVendorsAddButton,
    .MuiCheckbox-colorSecondary.Mui-checked {
        color: #009cbd !important;
    }
    .MuiInputBase-input {
        border: 1px solid #100b28 !important;
        border-radius: 0px !important;
        color: #323744;
    }
    .appointmentTitle {
        background-color: #009cbd !important;
    }
}
.errorTrusted {
    color: red;
    padding-top: 5px;
    // margin-left: 10px;
    text-align: left;
    // margin-bottom: 20px;
    height: 20px;
}
