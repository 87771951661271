.mainContainerSavingCalculator {
    display: flex;
    flex-direction: row;

    justify-content: space-between;
    width: 100%;
    @media screen and (max-width: 800px) {
        display: none;
    }

    /* important */

    .subContainer {
        background: white;
        color: grey;
        flex: 0.42;
        margin-right: 20px;
        @media screen and (max-width: 800px) {
            flex: 1;
        }
        @media(max-width: 1024px) {
            margin-right: 66px; 
        }

        &.disabledCalculator {
            filter: grayscale(1);
        }
    }
    .subContainer1 {
        flex: 0.54;
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 1px 3px #0000000a;
        border: 0.5px solid #dadce0;
        opacity: 1;
        position: relative;
    }
}

.mainSavingCalculator {
    height: fit-content;

    .heading {
        display: block;
        text-align: left;
        margin: 20px 15px;
        font-family: Roboto;
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0px;
        color: #333333;
        opacity: 1;
    }
    .saving {
        display: block;
        margin: 20px 15px;

        .saving-heading {
            text-align: left;
            font-size: 14px;
            font-weight: 500;
            color: #6b6c6f;
            text-transform: uppercase;
            display: block;
            opacity: 1;
            margin: 15px 0px 10px 50px;
        }
        .text {
            text-align: left;
            font-size: 34px;
            font-family: Poppins;
            font-weight: bold;
            letter-spacing: 0px;
            color: #333333;
            opacity: 1;
            padding-left: 50px;
            position: relative;
            .text-dollar {
                font-size: 14px;
                font-weight: bold;
                position: absolute;
                top: -10;
                margin-left: -10px;
            }
        }
        .subtext {
            display: block;

            font-size: 14px;
            letter-spacing: 0px;
            color: #6b6c6f;
            margin: 10px 0px 10px 50px;
        }
    }

    .mainQuestion {
        background-color: #f0f4fc;
        width: 95%;
        margin: 20px auto;
        padding: 20px 0px;
        position: relative;
        margin-top: 100px;

        @media (max-width: 340px) {
            span {
                button {
                    span {
                        font-size: 14px !important;
                    }
                }
            }
        }
    }
    // .MikeContainer {
    //     margin: 17px 10px 10px;
    //     display: flex;
    //     justify-content: space-between;
    //     @media screen and (max-width: 1000px) {
    //         margin: 17px 20px 10px;
    //     }
    //     .ImagecontainerMike {
    //         flex: 0.2;
    //         margin-top: 20px;
    //     }

    //     .containerMikeDetails {
    //         flex: 0.8;
    //         margin: 35px auto 20px;
    //         @media screen and (max-width: 1400px) {
    //             margin: 20px 20px;
    //         }
    //         .email {
    //             padding-left: 20px;
    //             @media screen and (max-width: 1400px) {
    //                 padding-left: 0px;
    //             }
    //         }
    //     }
    //     .MikeText {
    //         text-align: left;
    //         font-size: 16px;

    //         font-weight: bold;
    //         font-family: 'Roboto';
    //         letter-spacing: 0px;
    //         color: #333333;
    //         opacity: 1;
    //     }
    //     .phoneEmail {
    //         display: flex;
    //         flex-direction: row;
    //         justify-content: start;
    //         width: 90%;
    //         @media screen and (max-width: 1400px) {
    //             flex-direction: column;
    //         }
    //     }
    // }
    .details {
        text-align: left;
        font-size: 14px;
        letter-spacing: 0px;
        color: #6b6c6f;
        opacity: 1;
        padding-left: 26px;
    }
}
.blurBackground {
    filter: blur(5px);
    -webkit-filter: blur(5px);
    opacity: 0.5;
}

.brokerEmail,
.brokenPhoneNumber {
    @media (max-width: 340px) {
        font-size: 12px !important;
    }
}

.brokerBannerHeading,
.brokerNameCover {
    @media (max-width: 340px) {
        font-size: 14px !important;
    }
}
