.trusted-pros-ContentDiv {
    padding: 10px 0px 30px 20px;
    font-weight: bold;
    font-size: 22px;
    color: #333333;
    height: 50px;

    @media (max-width: 1024px) {
        font-size: 18px;
    }

    @media (max-width: 768px) {
        font-size: 14px;
        padding: 10px 0px 10px 20px;
    }
}

.trusted-pros-ImgDiv {
    height: 100%;
    width: 100%;
    margin: auto;
   
}
