.tableMainClass {
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow-wrap: break-word;
    font-family: Roboto;
    padding: 5px 0px 5px 0px;

    @media (max-width: 600px) {
        box-shadow: 0px 2px 8px #00000029;
        margin-top: 10px;
        border-radius: 4px;
        .tableCheck {
            width: 60%;
            display: flex;
            flex-direction: column;
        }
    }
    .tableCheck {
        width: 65%;
        display: flex;
        align-items: center;
        // flex-direction: column;

        @media (max-width: 768px) {
            width: 80%;
        }
    }

    .tableRowSecondLast {
        // width: 20%;
        font-size: 16px;
        font-weight: 500;
        width: 100%;
        @media (max-width: 600px) {
            font-size: 13px;
            width: 100%;
        }
    }
    .align-items-Checkbox {
        text-align: center;
    }
    .tableRow1 {
        width: 20%;
        font-size: 14px;
        font-weight: 500;

        .MuiCheckbox-colorSecondary.Mui-disabled {
            color: rgba(0, 0, 0, 0.26) !important;
        }

        @media (max-width: 750px) {
            font-size: 13px;
        }
        @media (max-width: 600px) {
            width: 27%;
        }
    }
    .tableRow2 {
        width: 13%;
        font-size: 14px;
        font-weight: 500;

        .MuiCheckbox-colorSecondary.Mui-disabled {
            color: rgba(0, 0, 0, 0.26) !important;
        }
        @media (max-width: 750px) {
            font-size: 13px;
        }
    }
    .tableRowLast {
        // width: 34%;
        font-size: 16px;
        font-size: 16px;
        font-weight: 500;
        width: 62%;

        color: #6b6c6f;
        @media (max-width: 600px) {
            font-size: 13px;
            width: 100%;
        }
    }
}
