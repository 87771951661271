.trustedPros-main-page-2 {
    margin-top: 100px;
    // background: #f5faf8;
    .MuiChip-root {
        background: #fff;
    }

    .selectInput-div-2 {
        width: 40%;
        height: fit-content;

        @media (max-width: 600px) {
            width: 100%;
            margin-top: 20px;
        }

        .standalone {
            width: 100%;
        }
        .standalone .select-new__control {
            height: 60px;
        }
    }
    .heading-standAlone {
        font-size: 32px;
        font-weight: bold;
        letter-spacing: 2px;
    }
    .Para-standAlone {
        font-size: 18px;
        opacity: 0.7;
    }
    .icon-card-skeleton {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    .trustedPros-OrderConfirmPage-2 {
        padding: 0 80px;
        background: #fff;
        // position: sticky;
        // top: 75px;

        @media (max-width: 600px) {
            padding: 0 20px;
        }
    }
    .MostPopularServiceDiv-2 {
        padding: 30px 80px;

        .MostPopularServiceTitleDiv {
            display: flex;
            justify-content: space-between;
            padding-bottom: 20px;

            .MostPopularServiceTitle {
                font-size: 24px;
                line-height: 32px;
                font-weight: bold;
                color: #333333;

                @media (max-width: 1024px) {
                    font-size: 20px;
                }

                @media (max-width: 768px) {
                    font-size: 16px;
                }
            }

            .MostPopularServiceViewAllService {
                font-size: 14px;
                line-height: 42px;
                color: #006dff;
                font-weight: bold;
                cursor: pointer;

                @media (max-width: 768px) {
                    font-size: 11px;
                }
                @media (max-width: 600px) {
                    // display: none;
                }
            }
        }

        .PopularCardsDiv-2 {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            background: #f5faf8;
            @media (max-width: 600px) {
                justify-content: center;
                align-items: center;
            }
            .popularCard {
                margin: 10px 6px;
                background: #ffffff !important;
                border: 1px solid #dadce0 !important;

                @media (min-width: 1441px) {
                    width: 15% !important;
                }
                @media (max-width: 600px) {
                    width: 45% !important;
                }

                img {
                    height: 100px !important;
                    width: 100px !important;

                    @media (max-width: 1024px) {
                        height: 70px !important;
                        width: 70px !important;
                    }

                    @media (max-width: 768px) {
                        height: 50px !important;
                        width: 50px !important;
                    }
                }
            }
        }

        .MostPopularServiceViewAllServiceMobile {
            display: none;
        }

        .PopularCardsDivMobile {
            display: none;
            .popularCardMobile {
                @media (max-width: 600px) {
                    margin-bottom: 23px;
                }
                @media (max-width: 375px) {
                    width: 165px !important;
                }
                @media (max-width: 320px) {
                    width: 142px !important;
                }
            }
            @media (max-width: 600px) {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
            }
        }

        @media (max-width: 1366px) {
            padding: 15px 60px 0;
        }
        @media (max-width: 600px) {
            padding: 10px 10px 0;
        }
    }

    .helpInputAllService-2 {
        width: 50%;
        height: fit-content;

        @media (max-width: 600px) {
            width: 100%;
        }

        .MuiOutlinedInput-root {
            height: auto !important;
        }
        .MuiFormControl-root {
            border-radius: 4px 0 0 4px;
            min-width: 100%;
            // width: auto;
            @media (max-width: 600px) {
                border-radius: 4px;
                width: 100%;
            }
        }

        // .MuiAutocomplete-inputRoot {
        //     flex-wrap: nowrap;
        // }
        .MuiInputLabel-outlined.MuiInputLabel-shrink {
            transform: translate(16px, 0px) scale(0.75);
        }

        &::-webkit-scrollbar {
            width: 4px;
            height: 3px;
        }

        &::-webkit-scrollbar-track {
            background: lightgray;
        }

        /* scrollbar handle */
        &::-webkit-scrollbar-thumb {
            background-color: #989b9e;
        }
    }

    .ViewAllServiceBackIconDiv {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    .viewAllTitle {
        padding: 0 20px;
        font-size: 24px;
        font-weight: bold;
    }

    .search-address-div {
        display: flex;
        align-items: flex-end;

        @media (max-width: 600px) {
            flex-direction: column;
        }
    }
}
