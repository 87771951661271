.checkboxCardBody {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .checkBoximg {
        width: 70px;
        height: 70px;
    }
    svg{
        height: 70px;
    }
    @media (max-width: 600px) {
        display: flex;
        flex-direction: row;
        .checkBoximg {
            width: 30px;
            height: 30px;
            margin-right: 10px;
        }

        .checkboxCardImage {
            @media (max-width: 600px) {
                height: 70%;
                width: auto;
                margin-right: 10px;
            }
        }
    }
}

.checkboxCards .individualCard {
    @media (max-width: 600px) {
        display: flex;
        width: calc(100vw - 40px);
        height: 55px;
    }
}
.hscheckboxCards .hsindividualCard {
    @media (max-width: 600px) {
        display: flex;
        width: calc(100vw - 40px);
        height: 55px;
    }
}
