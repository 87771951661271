.checkbox-image-div-mobile {
    display: flex;
    justify-content: space-between;
}

.checkbox-image-mobile {
    display: flex;
}

.rating-button-mobile {
    display: flex;
    color: #009cbd !important;
    border: 1px solid #009cbd !important;
    padding: 3px 10px 3px 3px;
    border-radius: 25px;
    font-size: 14px;
    background-color: #f1f9fb;
}
.rating-button-mobilenoDoug {
    display: flex;
    color: #006dff !important;
    border: 1px solid #006dff !important;
    padding: 3px 10px 3px 3px;
    border-radius: 25px;
    font-size: 14px;
    background-color: #f1f9fb;
}

.rating-div-mobile {
    width: 20%;
    margin: 20px auto;
}

.only-image-mobile {
    display: flex;
    justify-content: center;
    align-items: center;
}

.best-moves-mobile {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.movers-heading-mobile {
    margin-top: 20px;
    width: 100%;
    font-weight: bold;
    text-align: center;
}

.last-icon-div-mobile {
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items: center;
    padding: 20px 0 20px 0;
    margin-top: 20px;
    border-bottom: 1px solid #d8dce6;
    border-top: 1px solid #d8dce6;
}

.years-licencsed-text-mobile {
    text-align: center;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
    margin: 2px 0px;
}

.last-div-mobile {
    padding: 0 20px;
}

.image-image-mobile {
    height: 60px;
    width: 160px;
    @media (max-width: 320px) {
        width: 100px;
    }
}
