.SubscriptionTopMainCard {
    display: flex;
    align-items: center;
    padding: 10px 12px;

    .imageSubscriptionTopCard {
        img {
            height: 47px;

            @media (max-width: 1024px) {
                height: 30px;
            }
        }
    }

    .iconTitleDiv {
        width: 90%;
        margin-left: 20px;
        font-size: 16px;
        line-height: 24px;
        font-weight: bold;

        .progress {
            margin-bottom: 0;
            .progressDiv {
                margin: 0;
                font-size: 11px;
            }
        }
        @media (max-width: 1024px) {
            margin-left: 13px;
            font-size: 14px;
            line-height: 19px;
        }
    }
}
