.cardMainResponsive {
    // padding-top: 10px;
}

.titleDivResponsive {
    width: fit-content;
    text-align: center;
    // margin: 0 20px;
    font-size: 16px;
    color: #333333;
    font-weight: 600;
}

.featureDivResponsive {
    background: #f1f9fb;
}

.features .featuresFlexDivResponsive {
    margin: 10px 0px;
    display: flex;
    // width: 50%;
    // justify-content: flex-start;
}

.buttonDivResponsive {
    // width: 10%;
    display: flex;
    justify-content: flex-end;
}

.flexDivPlanCardResponsive {
    height: auto;
    margin: 20px 15px;
}

.flexDivPlanCardResponsive .availableSpeedMainDivResponsive {
    border-bottom: none;
    text-align: center;
    margin: 10px 0 15px 0;
}

.flexDivPlanCard .planRateDiv {
    padding-top: 50px;
    border-bottom: none;
    text-align: center;
}

.pricingResponsive {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 20px 0px 0px 0px;
    padding-bottom: 10px;
}

.featureDivResponsive {
    display: flex;
    color: #009cbd;
    border: 1px solid #009cbd;
    margin-right: 5px;
    padding: 5px 10px;
    border-radius: 25px;
    font-size: 14px;
}
.featureDivResponsivenoDoug {
    display: flex;
    color: #006dff !important;
    border: 1px solid #006dff !important;
    margin-right: 5px;
    padding: 5px 10px;
    border-radius: 25px;
    font-size: 14px;
}
.LogoDivResponsive {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 15px 0px 15px 0px;
}

.availableSpeedMainDivResponsive .availableSpeedContent {
    font-size: 16px;
    font-weight: 800;
    margin: 0;
}
