.disclamerContainer{
    margin:10px 0px;
   
    .disclamerTitle{
        font-size:12px;
        font-weight:bold;
        color:#333;
    }
     .disclamerContent{
        font-size: 12px;
        line-height: 1.5;
        font-weight: normal;
        color:#6B6C6F;

        @media(max-width: 375px) {
            font-size: 9px;
        }
    }

}