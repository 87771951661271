// .card-date-mobile{

//     @media(max-width:768px){
//         width: 97% !important;
//     }

// }

.date-picker {
    display: none;
}

.section-3-heading-date {
    font-size: 12px;
    opacity: 0.8;
    font: 'Roboto';
}

.section-3-number-date {
    font-weight: bold;
    padding-top: 10px;
    padding-bottom: 5px;
    font-size: 16px;
}

.section-3-content-date {
    font-size: 12px;
}

.cardContent-date {
    margin: 10px 0 0 24px;
}

.card-date-mobileD {
    height: fit-content !important;
    margin: 10px 0px 20px 20px !important;
    width: 44% !important;
    .cardContent-date {
        margin: 10px 10px 10px 24px;
        @media (max-width: 600px) {
            margin: 10px;
        }
    }
}
.card-date-mobile {
    height: fit-content !important;
    margin: 10px 0px 20px 20px !important;
    width: 44% !important;
    .cardContent-date {
        margin: 10px 10px 10px 24px;
        @media (max-width: 600px) {
            margin: 10px;
        }
    }
}
