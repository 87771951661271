.points-div{
    @media(max-width:425px){
        display: none !important;
    }
}

.card-main-class{
    @media(max-width:1024px){
        width: 238px !important;
        // height: 350px !important;
    }

    @media(max-width:768px){
        width: 400px !important;
        // height: 350px !important;
    }

    @media(max-width:425px){
        width: 250px !important;
        height: 250px !important;
    }
  
}