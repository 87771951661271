.UpcomingTaskPrimaryCardMain {
    display: flex;
    justify-content: space-between;
    border: 1px solid #d92f25;
    border-radius: 4px;
    background: #d92f2508;
    padding: 20px 10px 20px 20px;
    flex-wrap: wrap;
    color: #333333;

    .UpcomingTaskFirstTitle {
        font-size: 22px;
        line-height: 20px;        
        font-weight: 600;
        margin-bottom: 7px;
        width: 100%;

        @media (max-width: 1024px) {
            font-size: 16px;
        }
        @media (max-width: 375px) {
            margin-bottom: 10px;
        }
    }

    .UpcomingTaskPrimaryCardLeftDiv {
        width: 70%;
        font-size: 14px;
        line-height: 1.6;
        color: #333333;

        .UpcomingTaskFirstDescription {      
            margin-bottom: 15px;
        }

        .UpcomingTaskFirstButtonDiv {
            
            button {
                border-radius: 4px !important;
                width: auto !important;
                font-size: 14px !important;
                height: auto !important;
                padding: 7px 28px !important;
                @media (max-width: 1024px){
                    font-size: 12px !important;
                    padding: 5px 22px !important;
                }
            }   
            
        }

        .progress {
            margin-bottom: 20px;

            @media (max-width: 1024px) {

                .progressDiv {
                    margin-bottom: 10px;
                }
            }
        }
        .checkDetailsMainDiv {
            margin-bottom: 24px;
            margin-top: 20px;

            .checkDetailsDiv {
                display: flex;
                align-items: center;
                margin: 10px 0;

                .checkIcon {
                    color: #dadce0;
                    margin-right: 10px;
                }
                .checkIconCompleted {
                    color: #00d56d;
                    margin-right: 10px;
                }

                @media (max-width: 1024px) {
                    margin: 5px 0;

                    .checkTitleDiv {
                        font-size: 12px;
                    }
                    .checkIcon,
                    .checkIconCompleted {
                        font-size: 18px;
                    }
                }
                .checkTitleDiv {
                    font-weight: 600;
                }
            }

        }
    }
    .first_card_bullet_points {
        display: flex;
        flex-direction: column;

        img {
            width: 20px;
        }
        
        div {
            div {
                display: flex;
                margin-bottom: 5px;
            }
        }
    }

    .first_card_bullet_points > div > div > div:first-child {
        width: 35px;
    }
    .UpcomingTaskPrimaryCardRightDiv{
        width: 30%;
        img {
            width: auto;
            max-width: 100%;
            height: auto;
            max-height: 90%;
        }
    }
    .imageUpcomingTaskFirstDiv {
        align-items: flex-end;
        display: flex;

        @media (max-width: 1024px) {
            width: 30%;
            img {
                width: 100%;
            }
        }
    }
    @media (max-width: 1024px) {
        padding: 12px;
    }
}

@media (max-width: 1024px){
    .first_card_bullet_points p {
        margin-bottom: 8px;
    }
}