.callQuoteSkeletonCard {
    border: 1px solid #e2e2e2;
    padding: 20px;
    display: flex;
    box-shadow: #9c9da1 0px 0px 10px;
    justify-content: space-between;
    margin: 15px 0 0 0;

    .callQuoteSkeletonCard1 {
        width: 30%;
    }
    span {
        width: 100%;
    }
    .callQuoteSkeletonCard2 {
        width: 50%;
        display: flex;
        align-items: center;
        .callQuoteSkeletonCard3 {
            width: 50%;
            margin-right: 10px;
        }
        .callQuoteSkeletonCard4 {
            width: 50%;
        }
    }
    @media (max-width: 1024px) {
        flex-direction: column;
        padding: 20px 30px;
        .callQuoteSkeletonCard1 {
            width: 100%;
            margin-bottom: 5px;
        }
        .callQuoteSkeletonCard2 {
            width: 100%;
            flex-direction: column;
            .callQuoteSkeletonCard3 {
                width: 100%;
                margin-right: 0px;
                margin-bottom: 5px;
            }
            .callQuoteSkeletonCard4 {
                width: 100%;
            }
        }
    }
}
.PlanskeletonCard {
    border: 1px solid #e2e2e2;
    padding: 20px;
    display: flex;
    box-shadow: #9c9da1 0px 0px 10px;
    justify-content: space-between;
    margin: 15px 0 0 0;

    .PlanskeletonCard1 {
        width: 30%;
    }
    span {
        width: 100%;
    }
    .PlanskeletonCard2 {
        width: 25%;
    }
    .PlanskeletonCard3 {
        width: 25%;
    }
    @media (max-width: 1024px) {
        flex-direction: column;
        padding: 20px 30px;
        .PlanskeletonCard1 {
            width: 100%;
            margin-bottom: 5px;
        }
        .PlanskeletonCard2 {
            width: 100%;
            margin-bottom: 5px;
        }
        .PlanskeletonCard3 {
            width: 100%;
        }
    }
}
