.CustomServiceCardMain {
    height: 100%;
    border-radius: 4px;
    display: flex;
    border: 1px solid #CCC;
    margin-right: 10px;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px 20px;

    .homeServiceCardInnerDiv {
        text-align: left;

        @media (max-width: 600px) {
            text-align: left;
        }
    }

    .image {
        padding: 20px 0px;

        .imageHomeService {
            width: 100%;
            max-height: 50px;
        }

        // @media (max-width: 1366px) {
        //     width: 125px;
        // }

        @media (max-width: 1024px) {
            height: auto;
            .imageHomeService {
                height: 110px;
            }
        }
        @media (max-width: 768px) {
            padding: 10px;
            .imageHomeService {
                height: 60px;
            }
        }
    }

    .titleHomeService {
        font-size: 22px;
        line-height: 30px;
        font-weight: bold;
        color: #333333;
        @media (max-width: 1024px) {
            font-size: 18px;
            line-height: 24px;
        }
        @media (max-width: 768px) {
            font-size: 16px;
        }
    }

    .descriptionHomeService {
        color: #6b6c6f;
        font-size: 14px;
        line-height: 24px;
        // text-align: center;
        margin: 15px 0;

        @media (max-width: 1024px) {
            font-size: 12px;
            line-height: 20px;
            margin: 10px 0;
        }
    }

    @media (max-width: 1024px) {
        padding: 10px 20px;

        .buttonDivHomeService {
            .buttonHomeService {
                font-size: 12px !important;
                width: 130px !important;
                font: normal normal medium 14px/22px Roboto;
            }
        }
    }

    @media (max-width: 600px) {
        flex-direction: row-reverse;
    }
}
