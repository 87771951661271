.modalAppointment {
    padding: 20px;
    position: absolute;
    width: 70%;
    height: 90%;
    background: #fff;
    overflow-y: scroll;

    @media (max-width: 600px) {
        width: 85%;
    }
}

.modalAppointment::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
}

.appointment-heading {
    padding: 20px;
    text-align: center;
    font-size: 20px;
    border-bottom: 1px solid #d9d9d9;
    font-weight: 600;
    color: #333;
}

.inputs-div {
    display: flex;
    justify-content: space-between;

    .error {
        color: red;
        font-size: 12px;
        padding: 5px 0;
    }

    .left-input-div {
        width: 50%;
        border-right: 1px solid #d9d9d9;

        .div-1-name {
            margin: 10px 10px;
            .MuiFormControl-root {
                width: 90%;
            }
            .MuiIconButton-root {
                @media (max-width: 600px) {
                    padding: 12px 0;
                }
            }
            .MuiInputBase-input {
                padding: 5px 10px;

                @media (max-width: 600px) {
                    padding: 5px 0;
                }
            }
            .MuiInputBase-input:-webkit-autofill {
                padding: 5px 10px;
            }
            .MuiInput-root {
                @media (max-width: 600px) {
                    margin-top: 24px;
                }
            }
        }
    }
    .right-input-div {
        width: 47%;

        .selected-vendors {
            margin: 50px 0 0 0;
        }
    }
}
.buttons-div {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
}

.standAlone-recommended-main-page {
    padding: 100px 60px;
    display: flex;
    justify-content: space-between;

    @media (max-width: 800px) {
        flex-direction: column-reverse;
        padding: 100px 20px;
    }

    .left-recommendation {
        width: 65%;
        @media (max-width: 800px) {
            width: 100%;
        }

        .vendors-heading {
            padding: 10px 0;
        }
    }

    .right-recommendation {
        width: 30%;
        .get-quote-card {
            margin-top: 40px;
            .clear-text-mobile {
                display: none;
            }
            .clear-text {
                display: inline-block;
                float: right;
            }

            .selected-movers-count {
                display: flex;
                justify-content: space-between;
            }
        }
        @media (max-width: 800px) {
            width: 100%;
        }
    }
}

.standAlone-recommended-no-vendors-page {
    padding: 100px 60px;

    @media (max-width: 800px) {
        padding: 100px 20px;
    }

    .NoVendorsCenterDiv {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 30px;

        .trustedProsCarpenterRow {
            margin-top: 20px;
            text-align: center;
            font-size: 18px;
        }

        .trustedProsCarpenterDesc2 {
            margin-bottom: 30px;
        }
    }
}

.name-arrow-wrapper {
    display: flex;
}

.standalone-textarea-textarea {
    display: flex;
    justify-content: flex-start;
    margin-left: 10px;

    @media (max-width: 1024px) {
        justify-content: space-around;
        margin-left: 0;
        align-items: center;
    }
    // @media (max-width: 425px) {
    //     display: none;
    // }
}

.standalone-textarea {
    text-align: left;
    font-size: 14px;
    font-weight: bold;
    margin: 10px 10px 20px;

    // @media (max-width: 425px) {
    //     display: none;
    // }
}

.submitJobInfo {
    @media (max-width: 400px) {
        font-size: 13px !important;
    }
    @media (max-width: 340px) {
        font-size: 12px !important;
    }
}

.MuiFormHelperText-root {
    color: red !important;
}
