.mainimage {
    margin: 10px auto;
}
.mainText {
    margin-top: 6px;
    text-align: center;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
    font-size: 12px;
    font-family: 'Roboto';
    font-weight: bold;
    display: block;
}
.mainSubText {
    text-align: center;
    letter-spacing: 0px;
    color: #6b6c6f;
    opacity: 1;
    font-size: 12px;
    font-family: 'Roboto';
    font-weight: normal;
}

.calculatorCardContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 1px solid #E0E0E0;
    border-radius: 4px;
    padding: 10px;

    .AffordabilityCardMobileMain {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: row;
        .text {
            margin-left: 4px;
            font: normal normal bold 14px/23px roboto;
            color: #333333;
            text-align: left;
            letter-spacing: 0px;
            color: #333333;
            opacity: 1;
        }

        .arrowIconCover {
            &.activeArrow {
                transform: rotate(180deg) !important;
            }
        }

        img {
            @media(max-width: 375px) {
                width: 24px;
            }
        }
    }

  ul {
    padding: 0;
    margin-bottom: 0px;
    li {
        list-style: none;
        padding: 10px 0px;

        .text {
            opacity: 0.7 !important;
        }
    }
  }
}
.Monthlyexpenses.totalEx {
    font-size: 14px;
    display: revert;
    width: 42%;

    @media (max-width: 480px) {
        width: 100%;
    }
}
