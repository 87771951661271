.addTemplateUpperComponent {
    height: 50vh;
    @media (min-height: 800px) {
        height: 70vh;
    }
    @media (max-width: 600px) {
        height: unset;
    }

    .homeSecurityImage {
        background: url('../../Assets/images/DouglasImages/desktop/homeSecurity1.jpeg');
        @media (max-width: 600px) {
            background: url('../../Assets/images/DouglasImages/mobile/homeSecurity.jpg') no-repeat center center/cover;
        }
    }
    .internetImage {
        background: url('../../Assets/images/DouglasImages/desktop/internet1.jpeg');
        @media (max-width: 600px) {
            background: url('../../Assets/images/DouglasImages/mobile/internet.jpg') no-repeat center center/cover;
        }
    }
    .movingImage {
        background: url('../../Assets/images/DouglasImages/desktop/moving1.jpeg');
        @media (max-width: 600px) {
            background: url('../../Assets/images/DouglasImages/mobile/moving.jpg') no-repeat center center/cover;
        }
    }
    .insuranceImage {
        background: url('../../Assets/images/DouglasImages/desktop/insurance1.jpeg');
        @media (max-width: 600px) {
            background: url('../../Assets/images/DouglasImages/mobile/insurance.jpg') no-repeat center center/cover;
        }
    }
    .homeWarrantyImage {
        background: url('../../Assets/images/DouglasImages/desktop/custom1.jpeg');
        @media (max-width: 600px) {
            background: url('../../Assets/images/DouglasImages/mobile/custom.jpg') no-repeat center center/cover;
        }
    }
    .addtemplate-main-div {
        height: 100%;
        @media (max-width: 600px) {
            height: unset;
        }
    }
}

.TemplateUpperComponent {
    margin-top: 10px;

    .imageTemplate {
        background: url('../../Assets/images/DouglasImages/desktop/insurance.jpg');

        @media (max-width: 600px) {
            background: url('../../Assets/images/DouglasImages/mobile/insurance.jpg') no-repeat center center/cover;
        }
    }
    .checklistImage {
        background: url('../../Assets/images/DouglasImages/desktop/checklist.jpg');

        @media (max-width: 600px) {
            background: url('../../Assets/images/DouglasImages/mobile/checklist.jpg') no-repeat center center/cover;
        }
    }
    .customImage {
        background: url('../../Assets/images/DouglasImages/desktop/custom.jpg');

        @media (max-width: 600px) {
            background: url('../../Assets/images/DouglasImages/mobile/custom.jpg') no-repeat center center/cover;
        }
    }

    .dmvImage {
        background: url('../../Assets/images/DouglasImages/desktop/dmv.jpg');

        @media (max-width: 600px) {
            background: url('../../Assets/images/DouglasImages/mobile/dmv.jpg') no-repeat center center/cover;
        }
    }

    .mortgageImage {
        background: url('../../Assets/images/DouglasImages/desktop/mortgage.jpg');

        @media (max-width: 600px) {
            background: url('../../Assets/images/DouglasImages/mobile/mortgage.jpg') no-repeat center center/cover;
        }
    }

    .titleImage {
        background: url('../../Assets/images/DouglasImages/desktop/title.jpg');

        @media (max-width: 600px) {
            background: url('../../Assets/images/DouglasImages/mobile/title.jpg') no-repeat center center/cover;
        }
    }
    .trustedProsImage {
        background: url('../../Assets/images/DouglasImages/desktop/trustedPros.jpg');

        @media (max-width: 600px) {
            background: url('../../Assets/images/DouglasImages/mobile/trustedPros.jpg') no-repeat center center/cover;
        }
    }
    .updateAddressImage {
        background: url('../../Assets/images/DouglasImages/desktop/updateAddress.jpg');

        @media (max-width: 600px) {
            background: url('../../Assets/images/DouglasImages/mobile/updateAddress.jpg') no-repeat center center/cover;
        }
    }
    .utilityImage {
        background: url('../../Assets/images/DouglasImages/desktop/utility.jpg');

        @media (max-width: 600px) {
            background: url('../../Assets/images/DouglasImages/mobile/utility.jpg') no-repeat center center/cover;
        }
    }
    .insuranceCustomImage {
        background: url('../../Assets/images/DouglasImages/desktop/insuranceCustom.jpg');

        @media (max-width: 600px) {
            background: url('../../Assets/images/DouglasImages/mobile/insuranceCustom.jpg') no-repeat center
                center/cover;
        }
    }
    .template-main-div {
        padding: 70px 0px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        display: flex;
        justify-content: center;
        align-items: center;

        // height: 300px;
        .imageBackground {
            position: relative;
        }
        .template-main-div1 {
            background-color: #ffffff;
            display: flex;
            flex-direction: column;
            padding: 20px;
            // align-items: center;
            justify-content: center;
            align-items: center;
            text-align: center;
            .buttonDual {
                display: flex;
                .btnIC {
                    margin: 2px 0px !important;
                }
                @media (max-width: 600px) {
                    flex-direction: column;
                }
            }
            .HeaderText {
                color: #100b28;
                font-size: 32px;
                margin: 0px 0 16px 0;
                // text-transform: capitalize;
                // font-family: 'EuclidCircularA-Regular';
                font-family: 'Helvetica';
                @media (max-width: 600px) {
                    font-size: 20px;
                }
            }
            .subheaderText {
                font-family: 'EuclidCircularA-Regular';
                color: #100b28;
                font-size: 16px;
                margin: 0px 0 15px 0;
                // text-transform: capitalize;
                @media (max-width: 600px) {
                    font-size: 14px;
                    font-family: 'EuclidCircularA-Light';
                }
            }
            .subheaderTextsecondLine {
                font-family: 'EuclidCircularA-Regular';
                color: #100b28;
                font-size: 16px;
                margin: 0px 0 5px 0;
                // text-transform: capitalize;
                @media (max-width: 600px) {
                    font-size: 14px;
                    font-family: 'EuclidCircularA-Light';
                }
            }
            .highlightedText {
                // font-family: 'EuclidCircularA-Semibold';
                // font-family: 'Helvetica-Bold';
                font-family: 'Helvetica';
                font-weight: bold;
                @media (max-width: 600px) {
                    font-size: 24px;
                }
            }
            @media (max-width: 600px) {
                padding: 20px 10px;
            }
        }
        @media (max-width: 600px) {
            position: relative;
            min-height: 315px;
            margin-bottom: 230px;
        }
        @media (max-width: 1366px) {
            padding: 70px 0px;
        }

        .borderDiv {
            padding: 35px;
            max-width: 900px;
            min-width: 750px;
            @media (max-width: 1024px) {
                margin: 0px 50px;
                min-width: unset !important;
            }
            @media (max-width: 600px) {
                position: absolute !important;
                top: 60% !important;
                margin: 0px 30px !important;
                left: 0;
                right: 0;
            }
        }
        .borderDiv1 {
            padding: 35px;
            max-width: 900px;
            min-width: 750px;
            @media (max-width: 1024px) {
                margin: 0px 50px;
                min-width: unset !important;
            }
            @media (max-width: 600px) {
                position: absolute;
                top: 70%;
                margin: 0px 30px;
                left: 0;
                right: 0;
            }
        }
        .adjustTopTemplateUpper {
            @media (max-width: 600px) {
                top: 40% !important;
            }
        }
    }
    @media (max-width: 600px) {
        margin-top: -5px;
    }
}
@media (max-width: 340px) {
    .isDouglasEllimanCard {
        transform: translateY(-40px);
    }
}

.douglasEllimenSite {
    .templateDE {
        height: 71vh;
    }
}
