/* Note: in order to differentiate the CSS of homeOwnerdashboard(moveeasy) and the DouglasElliman throughout the code a conditional CSS classnames are used by naming conventions specific to douglas and moveeasy*/

/* .App {
    text-align: center;
} */
@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');
@font-face {
    font-family: 'Lato';
    src: local('Lato'), url('./Assets/font/Lato/Lato-Regular.ttf') format('ttf');
}

@font-face {
    font-family: 'Lato';
    src: local('Lato'), url('./Assets/font/Lato/Lato-Regular.ttf') format('ttf');
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

.App::-webkit-scrollbar {
    display: none;
}

/* html,
body {
   max-width: 100%;
    overflow-x: hidden; 
    overflow-y: hidden; 
} */

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.autocomplete-dropdown-container {
    position: absolute;
    width: 350px;
    background: #fff;
    z-index: 1000;
}

@media (max-width: 600px) {
    .autocomplete-dropdown-container {
        width: 55%;
    }
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
.notification-container--bottom-center {
    bottom: 65px !important;
}
/* 
.MuiStepper-horizontal {
    display: none !important;
}

.linearProgress .MuiLinearProgress-barColorPrimary {
    background-color: #00d56d;
} */
html::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
}
html,
body {
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
}

.notifications-component {
    z-index: 300000 !important;
}

@media(max-width: 768px) {
    .AppMainPage {
        margin-bottom: 40px !important;
    }  
}

.tooltipCover {
    padding: 20px 10px;
    max-width: 400px;
    width: fit-content;
    z-index: 999999;
}