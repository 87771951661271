.PowerKioskCardSkeletonCard {
    border: 1px solid #e2e2e2;

    .PowerKioskCardSkeletonCard1 {
        span {
            width: 100%;
        }
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 40px 5px;
        flex-direction: column;

        .div0 {
            width: 100%;
        }
        .div1 {
            width: 50%;
            margin-top: 10px;
            margin-bottom: 30px;
        }
        .div2 {
            width: 70%;
            margin-bottom: 10px;
        }
        .div3 {
            width: 50%;
            margin-bottom: 10px;
        }
        .div4 {
            width: 50%;
            margin-bottom: 10px;
        }
        .div5 {
            width: 50%;
            margin-bottom: 10px;
        }
        .div6 {
            width: 50%;
            margin-bottom: 10px;
        }
        .div7 {
            width: 60%;
            margin-bottom: 10px;
        }
        .div8 {
            width: 30%;
        }
    }
}
