.box {
    position: relative;
    background: #fff;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

/* top left*/

.MainBanner {
    background: #f0f4fc 0% 0% no-repeat padding-box;
    border: 0.5px solid #f0f4fc;
    border-radius: 4px;
    opacity: 1;
    width: 99%;
    height: auto;
    margin: 15px 10px;
    margin-left: 0;
    padding: 6px 10px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    position: relative;
    .mobile-nameBanner{
        display: none;
    }
    @media (max-width: 768px) {
        margin-right: 0px;
        width: 100%;
    }
    @media (max-width: 700px) {
        width: 100%;
        height: auto;
        margin: 2px auto;
    }
    @media (max-width: 480px) {
        margin-top: 22px;
        flex-direction: column;
        padding: 15px;
        width: 100%;
    }
    .banner-Container {
        display: flex;
        flex-direction: column;
        color: '#6B6C6F';
        font-size: 12px;
        font-weight: 'bold';
        margin: 0px 10px;

        .ribbonCover {
            left: 0;
            position: absolute;
            top: 0;
        }
    }
    .bannerImage {
        flex: 0.1;
        color: #6b6c6f;
        margin: 10px auto;
        text-align: center;
        .nameBanner {
            font-size: 14px;
            font-weight: bold;
            color: rgba(107, 108, 111, 1);
            display: block;
            text-align: center;
        }
    }
    .bannerTitle {
        margin: 0px 20px;
        font-weight: bold;
        font-size: 18px;
        line-height: 30px;

        @media (max-width: 768px) {
            font-size: 18px;
        }
        @media (max-width: 600px) {
            font-size: 14px;
            margin: 10px auto;
            line-height: 26px;
        }
    }
    .bannerSubTitle {
        color: #6b6c6f;
        font-weight: normal;
        font-size: 12px;
        line-height: 24px;
        max-width: 512px;
        display: block;
        @media (max-width: 600px) {
            font-size: 12px;
        }
    }
    .learnMore {
        width: 20%;
        margin-right: 30px;
        @media (max-width: 600px) {
            width: 100%;
            margin: auto;
        }
        @media (max-width: 480px) {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
        }

        .text {
            font-size: 12px;
            color: #006dff;
            text-decoration: none;
            margin-top: 70px;
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
            font-weight: normal;
            @media screen and (max-width: 600px) {
                margin-top: 0px;
                margin-bottom: 10px;
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
            }
            @media (max-width: 480px) {
                margin-bottom: 0px;
          }
        }
    }

    &.dealsBannerCover {
        justify-content: flex-start;

        .banner-Container {
            margin-left: 25px;
        }

        .learnMore {
            position: absolute;
            right: 0;
            margin-bottom: 10px;
        }

        @media(max-width: 480px) {
            .bannerTitle {
                margin: 0 !important;
                width: 100%;
            }
            .learnMore {
                position: relative;
            }  
        }
    }
}

