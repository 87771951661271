.MainContainerHomeEquity {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    width: 98%;
    margin: 20px auto;

    @media screen and (max-width: 700px) {
        flex-direction: column;
    }

    .MainContainerHomeEquityBox {
        width: 100%;
        flex: 0.55;
        margin: 20px 0px;

        @media (max-width: 800px) and (min-width: 700px) {
            flex: 0.45;
            margin: 4px 0px;
        }

        .EquityAmountLabel {
            font: normal normal normal 14px/24px Roboto;
            letter-spacing: 0px;
        }

        .EquityAmount {
            font: normal normal bold 16px/24px Roboto;
            letter-spacing: 0px;
        }
    }

    .sliderParent {
        background: #d8e5ff 0% 0% no-repeat padding-box;
        opacity: 1;
        width: 100% !important;
    }

    .sliderChild {
        background: #0066f5 0% 0% no-repeat padding-box;
        opacity: 1;
        // width:80%;
        height: 100%;
        text-align: right;
        padding: 20px 20px;
        display: inline-block;
        font: normal normal bold 16px/22px Roboto;
        letter-spacing: 0px;
        color: #ffffff;
        opacity: 1;
    }

    .sliderHomeEquity {
        position: absolute;
        margin-left: -10px;
        margin-top: 10px;
        width: 18px;
        display: inline-block;
        height: 47px;
        background: #fff 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 9px;
        opacity: 1;
    }

    .MainContainerHomeEquityBox1 {
        flex: 0.4;
        width: 100%;
        margin: 20px 0px;

        @media (max-width: 800px) and (min-width: 700px) {
            flex: 0.45;
            margin: 4px 0px;
        }

        .HomeEquityViewHaloc {
            background: #ffffff 0% 0% no-repeat padding-box;
            box-shadow: 0px 1px 3px #0000000a;
            border: 0.5px solid #e0e0e0;
            opacity: 1;

            .HomeEquityViewHalocMain {
                display: flex;
                width: 100%;
                flex-wrap: wrap;
                padding: 18px 20px;
                position: relative;
                cursor: pointer;

                .HomeEquityViewHalocBox {
                    flex: 0.1;
                }

                .HomeEquityViewHalocBox-svgIcon path {
                    fill: 'red';
                }

                .HomeEquityViewHalocBox1 {
                    text-align: left;
                    letter-spacing: 0px;
                    color: #6b6c6f;
                    opacity: 1;
                    margin: 0px 0 12px;
                    font-size: 14px;
                    line-height: 22px;
                    padding-top: 2px;
                    font-weight: 500;

                    &.active {
                        color: #0066f5;
                    }
                }
            }
            .closeIcon {
                cursor: pointer;
                transition: 0.3s all ease-in-out;
            }
            .openIcon {
                transform: rotate(-180deg);
                transition: 0.3s all ease-in-out;
            }
            .HomeEquityViewBoxHeading {
                display: flex;
                width: 100%;
                .HomeEquityViewHalocBox1 {
                    flex: 1 1;
                    margin-bottom: 1px;
                }
            }
            .HomeEquityViewHalocBoxData {
                width: 100.5%;
                flex: 0 0 100% !important;
                margin-top: 14px;
                position: absolute;
                top: 47px;
                left: -1px;
                padding-left: 48px;
                border: 1px solid #e0e0e0;
                background: #fff;
                z-index: 99;
                border-top: none;
            }
            .HomeEquityViewHalocData {
                .HomeEquityViewHalocData-amount {
                    font: normal normal normal 22px/23px Poppins;
                    letter-spacing: 0px;
                    margin-bottom: 12px;
                    color: #333333;
                    &.red {
                        color: #d92f25;
                        margin-left: -10px;
                    }
                    &.available-heloc {
                        color: #0066f5;
                    }

                    @media (max-width: 375px) {
                        font-size: 20px;
                    }
                }
                .HomeEquityViewHalocBox1 {
                    &.borderBottom {
                        position: relative;
                        padding-bottom: 13px;
                        &:after {
                            position: absolute;
                            content: '';
                            height: 1px;
                            width: 85%;
                            left: 0;
                            background: #83838324;
                            bottom: 1px;
                        }
                    }
                }
                .estval {
                    text-align: left;
                    font: normal normal normal 14px/24px Roboto;
                    color: #6b6c6f;
                    margin-left: 8px;
                }
                .HomeEquityViewHalocDataVal {
                    text-align: left;
                    font: normal normal normal 14px/24px Roboto;
                    letter-spacing: 0px;
                    color: #6b6c6f;
                    margin-left: 5px;
                }
                .HomeEquityViewHalocData-percentage {
                    text-align: left;
                    font: normal normal normal 22px/23px Poppins;
                    letter-spacing: 0px;
                    color: #6b6c6f;
                    .axe {
                        color: #333333;
                        margin-right: 5px;
                        font-size: 22px;
                    }
                }
                .HomeEquityViewHalocData-boxPercentage {
                    margin-bottom: 24px;
                    position: relative;
                    &:after {
                        position: absolute;
                        content: '';
                        height: 1px;
                        width: 85%;
                        left: 0;
                        background: #83838324;
                        bottom: -11px;
                    }
                    .pr {
                        margin-bottom: 12px;
                    }
                }
            }
        }
    }
}
