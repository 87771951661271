.main-head {
    margin: 30px 0px 0px 80px;
    @media (max-width: 1366px) {
        margin: 30px 0px 0px 60px;
    }
    @media (max-width: 600px) {
        margin: 30px 0px 0px 10px;
    }
}

.heading {
    font-size: 24px;
    font-weight: 600;
    font-family: sans-serif;
}
.headingSteps {
    font-size: 24px;
    font-weight: 600;
    font-family: sans-serif;
}

.pathWaranty {
    display: flex;
    // margin-top: -25px;
    font-size: 12px;
}
.Para {
    color: #bebebe;
}

.WarantycoverDiv {
    height: 50vh;
    display: flex;
    align-items: center;
    @media (min-height: 1000px) {
        height: 70vh;
    }
    @media (max-height: 850px) {
        height: 55vh;
    }
    @media (max-height: 750px) {
        height: 60vh;
    }
    @media (max-height: 680px) {
        height: 60vh;
    }
    @media (max-height: 580px) {
        height: 75vh;
    }
}
.landingPage {
    text-align: center;
    padding: 0px 100px 0px 100px;
    width: 100%;

    .heading {
        font-size: 24px;
        font-weight: 600;
        font-family: sans-serif;
    }
    .subtext {
        font-size: 16px;
    }
    .btn-div {
        display: flex;
        justify-content: flex-end;
        // margin-top: -20px !important;
        .btn-div1 {
            margin-right: 15px;
            width: 220px;
        }
        .btn-div {
            width: 125px;
        }
    }
    .line {
        border-top: 0.5px solid #d2d2d2;
        margin-top: 40px;
        margin-bottom: 40px;
    }
    @media (max-width: 600px) {
        padding: 0 10px;
        .heading {
            font-size: 22px;
        }
        .subtext {
            font-size: 12px;
        }
        .mainImg {
            width: 90%;
            height: 100%;
        }
        .line {
            display: none;
        }
        .btn-div {
            flex-direction: column;
            .btn-div1 {
                margin: 0 0 15px 0;
                width: 100%;
            }
            .btn-div2 {
                width: 100%;
            }
        }
    }
}
.warantyAddressCard {
    // padding: 0px 100px 0px 100px;
    display: flex;
    justify-content: center;

    @media (max-width: 425px) {
        justify-content: left;

        // margin-top: 250px;
    }
}

.progressStepD .MuiLinearProgress-barColorPrimary {
    background: #009cbd !important;
}
.progressStep .MuiLinearProgress-barColorPrimary {
    background: #006dff !important;
}
.warantyBoxCard {
    width: fit-content;
    height: auto;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 0px 10px 0px 20px;
    // margin-top: -100px;

    @media (max-width: 768px) {
        justify-content: left;
        padding: 0;
        // margin-top: 50px;
    }
}

.warantyAddressCard .addressFlexDivNew {
    display: flex;
    justify-content: center;
    margin: 0px 15px;
    // width: 330px;
    @media (max-width: 768px) {
        flex-direction: column;
        margin: 10px auto;
    }
}
.warantyBox {
    text-align: left;
    margin-top: 15px;
    .MuiFilledInput-root {
        width: 100%;
        background-color: #fff !important;
        border: 1px solid #bebebe !important
        ;
        border-radius: 5px !important;
        color: #323744;

        // @media (max-width: 768px) {
        //     height: 40px;
        // }
    }
    // .MuiFilledInput-root {
    //     //     border: 1px solid #100b28 !important;
    //     //     border-radius: 0px !important;
    //     //     color: #323744;
    //     // }

    .MuiFilledInput-root.Mui-focused {
        width: 100%;
        background-color: #fff !important;
        border: 1px solid #006df5 !important;
        border-radius: 5px;

        // @media (max-width: 768px) {
        //     height: 40px;
        // }
    }

    .MuiFilledInput-root.Mui-error {
        width: 100%;
        background-color: #fff !important;
        border: 1px solid red;
        border-radius: 5px;

        // @media (max-width: 768px) {
        //     height: 40px;
        // }
    }

    .MuiFilledInput-root:focus {
        border: 1px solid #006dff !important;
        background: transparent;
    }

    .MuiFilledInput-underline:before,
    .MuiFilledInput-underline::after {
        border-bottom: none !important;
    }
    // .signUpInput input:focus {
    //     background-color: #fff;
    //     border: 1px solid #006df5;
    //     border-radius: 5px;
    // }
    .MuiInputLabel-filled.MuiInputLabel-shrink {
        z-index: 1 !important;
        color: #6b6c6f;

        @media (max-width: 768px) {
            font-size: 12px !important;
        }
    }
    .MuiFormLabel-root.Mui-focused {
        color: #006df5 !important;
    }
    .MuiInputLabel-filled.MuiInputLabel-shrink.Mui-focused {
        color: #006dff !important;
    }
}
.warantyBoxDouglas {
    .MuiFilledInput-root:focus {
        border: 1px solid #009cbd !important;
        background: transparent;
    }
    .MuiFilledInput-root.Mui-focused {
        width: 100%;
        background-color: #fff !important;
        border: 1px solid #009cbd !important;
        border-radius: 5px;

        // @media (max-width: 768px) {
        //     height: 40px;
        // }
    }
    .MuiFormLabel-root.Mui-focused {
        color: #009cbd !important;
    }
    .MuiInputLabel-filled.MuiInputLabel-shrink.Mui-focused {
        color: #009cbd !important;
    }
}

.warantyAddressCard .innerInputsWaranty {
    display: flex;
    width: 100%;
    @media (max-width: 768px) {
        // flex-direction: column;
        justify-content: left;
        // margin-left: -20px;
        margin-top: 0px;
    }
}

.inputWarrantyCoverDiv {
    display: flex;
    width: 85%;
    @media (max-width: 768px) {
        flex-direction: column;
    }
}

.addressFlexDivIconWarantyInput {
    display: flex;
    justify-content: space-between;
    // margin: 0px 15px;
    width: 100%;

    @media (max-width: 768px) {
        flex-direction: row;
        justify-content: left;
        margin: 0px auto;
        width: 100% !important;
    }
}
.warantyStreet {
    // display: flex;
    // justify-content: center;
    margin: 0px 47px 0px 5px;
    width: 30%;

    @media (max-width: 768px) {
        flex-direction: column;
        justify-content: left;
        margin: 10px auto 15px auto;
        width: 100% !important;
    }
}

.addressFlexDivIconInput {
    display: flex;
    justify-content: center;
    margin: 0px 15px;
    width: 50%;

    @media (max-width: 768px) {
        flex-direction: row;
        justify-content: left;
        margin: 0px auto;
        width: 100% !important;
    }
}
.iconWaranty {
    width: 15%;
}
.autoStreet {
    width: 85%;
    @media (max-width: 768px) {
        width: 85%;
    }
}

.warrantyAddressDiv {
    display: flex;
    align-items: center;
    position: absolute;
    right: 80px;
    top: 180px;

    .MuiFilledInput-root {
        background-color: transparent;
    }
    .MuiFilledInput-input {
        padding: 10px 5px !important;
        border-bottom: 1px solid #006dff;
        color: #006dff !important;
    }

    .MuiFormControl-root {
        width: 300px !important;
    }
    @media (max-width: 735px) {
        position: unset;
    }
    @media (max-width: 400px) {
        .MuiFormControl-root {
            width: 100% !important;
        }
    }
}
.warrantyAddressDivDoug {
    .MuiFilledInput-input {
        padding: 10px 5px;
        border-bottom: 1px solid#009cbd;
        color: #009cbd !important;
    }
}
.waranty-main-pageD {
    // .warantyBox .MuiFilledInput-root {
    //     border: 1px solid #100b28 !important;
    //     border-radius: 0px !important;
    //     color: #323744;
    // }
    // .MuiInputLabel-filled.MuiInputLabel-shrink.Mui-focused {
    //     color: #100b28 !important;
    // }

    .react-select-waranty .select-state__control,
    .react-select .select-state__control {
        border: 1px solid #100b28 !important;
        border-radius: 0px !important;
        color: #323744;
        box-shadow: none !important;
    }
    .select-state__menu {
        background-color: #fff !important;
    }
    .MuiCheckbox-colorPrimary.Mui-checked,
    .radioButtons .MuiRadio-colorSecondary.Mui-checked,
    .addressRoomIcon {
        color: #009cbd !important;
    }
    .MuiFilledInput-input {
        padding: 27px 12px 10px;
        // border-bottom: 1px solid#009cbd;
        // color: #009cbd;
    }
    .MuiFilledInput-root:focus {
        border: 1px solid #009cbd !important;
        background: transparent;
    }

    // .warrantyAddressDivDoug {
    //     .MuiFilledInput-input {
    //         border-bottom: 1px solid #009cbd !important;
    //         color: #009cbd !important;
    //     }
    // }
}
.waranty-main-page {
    padding: 10px 80px;

    @media (max-width: 1366px) {
        // padding:0px 25px
        padding: 10px 60px;
    }

    @media (max-width: 600px) {
        // padding:0px 25px
        padding: 0px 10px;
    }
}

.coverDivWaranty-2 {
    // height: 70vh;
    display: flex;
    align-items: center;
    @media (min-height: 850px) {
        // height: 70vh;
        height: unset;
    }
    @media (min-height: 1000px) {
        // height: 80vh;
    }

    .react-select .select-state__control .select-state__indicators .select-state__indicator {
        height: 70%;
    }
}

.landingPageWarantyMain {
    text-align: center;
    padding: 50px 80px 0px 80px;
    width: 100%;

    @media (max-width: 1366px) {
        padding: 50px 60px 0px 60px;
    }

    @media (max-width: 425px) {
        padding: 20px 10px 0px 10px;
    }
}

.landingPageWaranty {
    text-align: center;
    padding: 0px 80px 0px 80px;
    width: 100%;

    @media (max-width: 1366px) {
        padding: 0px 60px 0px 60px;
    }

    @media (max-width: 425px) {
        padding: 0px 10px 0px 10px;
    }
}

.btn-div {
    display: flex;
    justify-content: flex-end;
    // margin-top: -25px;
}

.coverDivMove .line {
    border-top: 0.5px solid #d2d2d2;
    margin-top: 40px;
    margin-bottom: 40px;

    @media (max-width: 425px) {
        margin-bottom: 0;
        display: none;
    }
}
.MuiDialog-paperWidthSm {
    max-width: 600px;
    height: 400px;
}

.steps {
    padding: 0px 80px 0px 80px;
    text-align: center;
    margin-top: 5%;
}

//stepper styless

.buttons-waranty {
    display: flex;
    justify-content: space-between;
    width: 100%;

    @media (max-width: 768px) {
        justify-content: center !important;
        // padding-bottom: 100px;
    }
}

.skipStyle {
    text-decoration: underline;
    color: #009cbd;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.waranty-main-page .Para11Waranty {
    text-align: center;
    color: #333333;
    font-size: 12px;
    margin-top: 40px;
    margin-bottom: 10px;
}

.warantyState {
    width: 22%;
    margin: 0px 20px 0px 14px;
    @media (max-width: 768px) {
        width: 100%;
        margin: 0px auto;
    }
}
.warantyCity {
    width: 40%;
    @media (max-width: 768px) {
        width: 100%;
        margin: 15px 0px;
    }
}
.warantyZipcode {
    width: 33%;
    // margin-right: 44px;
    @media (max-width: 768px) {
        width: 100%;
        margin-top: 15px;
    }
}
.warantyCityStateDiv {
    display: flex;
    justify-content: space-between;
    @media (max-width: 768px) {
        flex-direction: column;
    }
}

.stateTextAddressWaranty {
    color: #6b6c6f;
    position: absolute;
    z-index: 9;
    font-size: 14px;
    transform: translate(7px, 6px) scale(0.75);

    @media (max-width: 600px) {
        transform: translate(7px, 25px) scale(0.75);
    }
}

.react-select-waranty .select-state__control {
    height: 58px !important;
    width: 100%;
    margin-right: 10px;
    font-size: 16px;
    @media (max-width: 600px) {
        width: 100%;
        margin: 0px;
    }
}

// .react-select-waranty .select-state__control .select-state__indicators .select-state__indicator {
//     margin-top: -15px;
// }
.warantyCity .select-state__menu {
    z-index: 9000;
    height: 80px !important;
}

.warantyCity .react-select-waranty .css-yk16xz-control {
    border-color: #6b6c6f;
}

.warantyCity .react-select-waranty div {
    font-size: 16px;
    color: #6b6c6f;
    // padding-top: 6px;
}
s .react-select-waranty div {
    padding-top: 6px;
}
.warantyCity .error {
    font-size: 12px;
    color: red;
}
.input-waranty .MuiOutlinedInput-root {
    width: 100% !important;
}
.warantyState .css-2b097c-container {
    width: 100% !important;

    @media (max-width: 768px) {
        width: 100% !important;
    }
}
.warantyAddress {
    font-size: 12px;
    text-align: left;
    margin: 30px 0px 20px 78px;
    color: #6b6c6f;
    display: flex;
    align-items: center;
    @media (max-width: 768px) {
        margin: 18px auto;
        text-align: center;
        justify-content: center;
    }
}
.warantyNewConst {
    font-size: 16px;
    text-align: left;
    margin: 0px 0px 22px 70px;
    @media (max-width: 768px) {
        margin: 18px auto;
        text-align: center;
    }
}
.topWarantyMargin {
    margin-top: 10px;
}
.choiceFilling {
    // display: flex;
    align-items: center;
    margin-top: 14px;
    margin-left: 15px;

    @media (max-width: 768px) {
        justify-content: center;
    }
}
.checkButonBox {
    width: 14%;
    @media (max-width: 768px) {
        display: none;
    }
}
.checkboxContentHead {
    font-size: 14px;
}
.iAm {
    font-size: 16px;
    font-weight: bold;
    margin-right: 23px;
}

// .__react_component_tooltip {
//     border-radius: 3px !important;
//     display: inline-block !important;
//     font-size: 13px !important;
//     left: -999em !important;
//     opacity: 0 !important;
//     padding: 8px 21px !important;
//     position: fixed !important;
//     pointer-events: none !important;
//     transition: opacity 0.3s ease-out !important;
//     top: -999em !important;
//     visibility: hidden !important;
//     z-index: 999 !important;
//     color: grey !important;
//     border: 1px solid grey !important;
// }


.MuiFormControlLabel-root {
    margin-left: 0 !important;
}
.MuiCheckbox-colorSecondary.Mui-checked {
    color: #009cbd !important;
}

.MuiIconButton-root {
    padding-left: 0px !important;
}
.warantyAddressCard {
    .inputsWarantyCase2 {
        display: flex;
        width: 100%;
        @media (max-width: 768px) {
            flex-direction: column;
        }
    }
}

.warantyAddressCard .input1 .MuiOutlinedInput-root,
.warantyAddressCard .react-select {
    // width: 250px !important;
    width: 91% !important;
    // height: 52px !important;

    @media (max-width: 768px) {
        // width: 400px !important;
        width: 100% !important;
    }
    @media (max-width: 425px) {
        // width: 250px !important;
    }
}
.warantyAddressCard .react-select .select-state__control {
    height: 56px !important;
    // width: 250px !important;

    @media (max-width: 768px) {
        width: 100% !important;
    }
}
.storageFacilityDiv .selectDiv .react-select,
.Storage-main-div .selectDiv .react-select {
    width: 150px;
}

.storageFacilityDiv .selectDiv .react-select .css-yk16xz-control,
.Storage-main-div .selectDiv .react-select .css-yk16xz-control {
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #333333;
}
.warantySelectFilter {
    width: 80%;
}
.iconDiv {
    display: flex;
    align-items: flex-end !important;
    height: 100px;
    width: 82px;
}
.icons {
    margin-right: 20px;
    width: 50px;
    height: 50px;

    @media (max-width: 425px) {
        margin-right: 10px !important;
        margin-left: 0px !important;
        width: 45px !important;
        height: 45px !important;
    }
}
.iconsWarantyCase1 {
    margin-right: 20px;
    width: 240px;
    height: 53px;
    @media (max-width: 1024px) {
        width: 100px;
        height: 53px;
    }

    @media (max-width: 768px) {
        margin-right: 10px !important;
        margin-left: 0px !important;
        width: 45px !important;
        height: 66px !important;
    }
}
.iconsWarantyCase4 {
    width: 102px;
    height: 58px;
    @media (max-width: 1024px) {
        width: 100px;
        height: 53px;
    }

    @media (max-width: 768px) {
        margin-left: 0px !important;
        width: 67px !important;
        height: 66px !important;
    }
}
.radioButtons {
    .MuiFormGroup-root {
        display: flex !important;
        flex-direction: row !important;
        flex-wrap: nowrap !important;
    }
    .MuiRadio-colorSecondary.Mui-checked {
        color: #0066f5 !important;
    }
    .MuiFormControlLabel-root {
        margin-right: 25px;
    }
}
.yesNoWarantyStep3 {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 10px;
}
.innerContentYesNoStep2 {
    display: flex;
    justify-content: space-between;

    .date11 {
        margin-top: 0px !important;
        margin-bottom: 0px !important;

        .MuiFilledInput-root {
            height: 100%;
        }
    }
    @media (max-width: 768px) {
        flex-direction: column;
        width: 100%;
    }
}
.yesNo {
    width: 50%;

    @media (max-width: 768px) {
        width: 100%;
        padding-bottom: 10px;
    }
}
.yesSide {
    margin-right: 20px;
    @media (max-width: 768px) {
        margin-right: 0px;
    }
}
.noSide {
    margin-left: 20px;
    @media (max-width: 768px) {
        margin-left: 0px;
    }
}
.paddingYesNo {
    padding-top: 16px;
    width: 46%;
    @media (max-width: 768px) {
        padding-top: 0px;
        width: 100%;
    }
}
.workingWithTitleCompany {
    font-size: 16px;
    text-align: left;
    margin-top: 15px;
}
.workingWithTitleCheckCompany {
    .MuiIconButton-root {
        padding: 0px 9px 0px 0px;
    }

    font-size: 16px;
    text-align: left;
    padding-bottom: 24px;
}
.sureUwant {
    font-size: 16px;
    color: #6b6c6f;
    display: flex;
    align-items: center;
    padding-top: 10px;
    @media (max-width: 768px) {
        justify-content: center;
    }
}
.waranty-main-page .MuiFormControl-root {
    width: 100%;
}
.heading-padding {
    padding-bottom: 30px;
}
.btnWaranty3 {
    width: 115px !important;
}
.waranty-Booked-Cards {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin-right: 80px;
    @media (max-width: 1366px) {
        margin-right: 60px;
    }
    @media (max-width: 768px) {
        flex-direction: column;
    }
    @media (max-width: 600px) {
        margin-right: 10px;
    }
}
.previously-book-head {
    font-size: 24px;
    font-weight: bold;
    color: #333333;
    font-family: Roboto;
    padding-bottom: 20px;
    @media (max-width: 600px) {
        font-size: 18px;
    }
}
.main-card-booked {
    display: flex;
    justify-content: space-between;

    @media (max-width: 425px) {
        .booked-img {
            display: none;
        }
    }
}
.dateChangeClass {
    .date11 .MuiFilledInput-root {
        height: 58px !important;
    }
    .MuiFormControl-marginNormal {
        margin-top: 0px !important;
        margin-bottom: 0px !important;
    }
    .date11 {
        margin-top: 0px !important;
    }
}

.warant-card-moving {
    margin: 10px 80px 40px 0px;
    // background: #08192f;
    color: #fff;
    padding: 30px 0px 0 25px;
    border-radius: 5px;
    @media (max-width: 1366px) {
        margin: 10px 60px 40px 0px;
    }

    @media (max-width: 600px) {
        margin: 10px 10px 40px 0px;
    }

    @media (max-width: 425px) {
        padding: 10px;
    }
}
.tdd59fde1-0a48-4f26-8991-23470fae7fa6 {
    color: grey;
    background: #fff;
    border: 3px solid grey;
}

.summaryHeaderHome p {
    width: 650px;
    max-width: 100%;
    margin: 0 auto 30px;
}

.summaryboxHome .summarybox_head {
    padding: 22px 40px;
}

.summaryboxHome .summarybox_body {
    padding: 30px 40px;
}

.summaryboxHome .summaryfooter {
    padding: 5px 40px;
}

.summaryboxHome .summaryfooter .readmorebtn {
    padding: 17px 0;
}
.summaryHeaderHome h3 {
    font-weight: 600;
}

.summaryboxHome .summarybox_head .summaryHeader {
    font-weight: 600;
}
.summarybox_body_homeWarranty .summaryContent .summaryContent_l .img_wrapper img{
    max-height: 100%!important
}

.summaryboxEnergy .summarybox_body .main-final-utility .borderFinal{
    border: unset;
}
.hw_summary .call_icon{
    height: auto!important;
    width: auto!important;
    img{
        height: 17px!important;
        width: 17px!important;
    }
}