.autoShippingHeading {
    font-size: 24px;
    line-height: 28px;
    font-weight: bold;
    margin-top: 30px;
    margin-bottom: 10px;
}
.moving-main-pageDouglas {
    .tab-active {
        color: #009cbd !important;
        border-bottom: 2px solid #009cbd !important;
    }
}
.autoShippingSubHeading {
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 30px;
}

.autoShippingOrderFlexDiv {
    display: flex;
    justify-content: space-between;

    .autoShippingFormDiv {
        width: 59%;
        border-radius: 4px;
        border: 1px solid #c6c6c6;
        height: fit-content;

        .formHeading {
            font-size: 16px;
            line-height: 22px;
            font-weight: bold;
            padding: 10px 20px;
        }

        .personalDetailsAutoshipping {
            padding: 20px;

            .personalDetailsHeading {
                font-size: 14px;
                font-weight: bold;
                line-height: 24px;
                margin-bottom: 15px;
            }

            .autoshippingInputFlexDiv {
                display: flex;
                justify-content: space-between;
                margin-bottom: 20px;

                .autoshippingOrderInput {
                    width: 32%;

                    .autoshippingInput1 {
                        width: 100%;

                        .MuiFilledInput-underline:before,
                        .MuiFilledInput-underline:after {
                            border: none;
                        }

                        .MuiFilledInput-root {
                            background-color: #ffffff;
                        }

                        .MuiFilledInput-input {
                            border-radius: 4px;
                            border: 1px solid #e0e0e0;
                        }

                        .MuiFilledInput-input:focus {
                            border: 1px solid #006dff;
                        }

                        .MuiInputLabel-filled.MuiInputLabel-shrink {
                            z-index: 1 !important;
                        }
                    }

                    @media (max-width: 600px) {
                        width: 100%;
                        margin-bottom: 15px;
                    }
                }

                .checkbox-div {
                    width: 48%;
                }

                .autoshippingDateInput {
                    width: 50%;

                    .autoshippingInput1 {
                        width: 100%;

                        .MuiFilledInput-underline:before,
                        .MuiFilledInput-underline:after {
                            border: none;
                        }

                        .MuiFilledInput-root {
                            background-color: #ffffff;
                        }

                        .MuiFilledInput-input {
                            border-radius: 4px;
                            border: 1px solid #e0e0e0;
                        }

                        .MuiFilledInput-input:focus {
                            border: 1px solid #006dff;
                        }

                        .MuiInputLabel-filled.MuiInputLabel-shrink {
                            z-index: 1 !important;
                        }
                    }

                    @media (max-width: 600px) {
                        width: 100%;
                        margin-bottom: 15px;
                    }
                }

                @media (max-width: 600px) {
                    flex-wrap: wrap;
                }
            }

            @media (max-width: 600px) {
                button {
                    width: 100% !important;
                    margin: 0 !important;
                }
            }
        }

        @media (max-width: 768px) {
            width: 100%;
        }
    }

    .autoShippingPlansDiv {
        width: 39%;
        border-radius: 4px;
        border: 1px solid #c6c6c6;

        .planDetails {
            width: 50%;
            padding: 20px;

            .destinationText {
                font-size: 16px;
                line-height: 22px;
                font-weight: bold;
                color: #333333;
            }

            .destDetails {
                font-size: 14px;
                line-height: 24px;
            }

            @media (max-width: 768px) {
                width: 94%;
                padding: 0 20px 20px;
            }
        }

        .planDetailHeading {
            font-size: 22px;
            line-height: 32px;
            font-weight: 500;
            padding: 10px 20px;
            border-bottom: 1px solid #c6c6c6;

            .expandIcon {
                display: none;
            }

            @media (max-width: 768px) {
                border-bottom: none;
                display: flex;
                justify-content: space-between;

                .expandIcon {
                    display: block;
                }
            }
        }

        @media (max-width: 768px) {
            width: 100%;
            margin-bottom: 30px;
        }
    }

    @media (max-width: 768px) {
        flex-wrap: wrap;
        flex-direction: column-reverse;
    }
}
.autoShippingOrderFlexDivD {
    .MuiInputLabel-filled.MuiInputLabel-shrink {
        z-index: 1 !important;
        color: #100b28;
    }
    .MuiFilledInput-input,
    .MuiFilledInput-input:focus,
    .date-autoshipping .MuiInputBase-root {
        border: 1px solid #100b28 !important;
        border-radius: 0px !important;
        color: #323744;
    }
    .MuiInputLabel-filled.MuiInputLabel-shrink.Mui-focused {
        color: #100b28 !important;
    }
    .datePickerDiv.MuiInputLabel-formControl {
        left: 10px !important;
    }
    .MuiFormGroup-root.customRadio .MuiRadio-colorSecondary.Mui-checked {
        color: #009cbd !important;
    }
}
