.main-class-diy {
    width: 100%;
    box-shadow: 0 0 10px #00000029;

    .discountmainDiv {
        height: 70px;
    }

    .discount-div {
        display: flex;
        justify-content: center;
        align-items: center;
        background: #e2e2e2;
        padding: 20px 0;

        .tag-div {
            border-radius: 50%;
            background: #1966e7;
            padding: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 10px;
        }
        .discount-value {
            font-weight: bold;
            color: #1966e7;
            font-size: 14px;
        }
    }
    .body-div {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        //  margin-top: 20px;
        .logo-div {
            width: 30%;
            // height: 30%;
            height: 80px;
            margin: 10px auto;
            display: flex;
            margin-top: 20px;
            justify-content: center;
            align-items: center;

            .logo-diy {
                width: 100%;
                max-height: 100%;
            }
        }
        .title-div {
            padding: 20px 0;
            font-size: 22px;
            font-weight: bold;
            // width: 60%;
            margin-left: auto;
            margin-right: auto;
            text-align: center;
        }
        .rating-capsule-div {
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid #1966e7;
            border-radius: 25px;
            padding: 5px 10px;
            background: #dfe9fa;
        }
        .star-diy {
            padding: 5px;
            border-radius: 50%;
            background: #1966e7;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 5px;
        }
        .rate-diy {
            color: #1966e7;
            font-weight: bold;
        }

        .rating-div {
            padding: 20px 0;
        }
        .reviews-div {
            color: #6b6c6f;
            text-decoration: underline;
            padding-bottom: 20px;
        }
        .button-div {
            width: 80%;
            margin: 20px auto;
        }
        .button-know {
            width: 100% !important;
            border-radius: 8px !important;
            margin-bottom: 20px !important;
        }
    }
}
