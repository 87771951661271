.cardMain {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

// .LogoDiv {
//     // width: 30%;
// }

.titleDiv {
    width: fit-content;
    text-align: center;
    margin: 0 20px;
    font-size: 16px;
    color: #333333;
    font-weight: 600;
}

.featureDiv {
    background: #f1f9fb;
}

.features .featuresFlexDivPlan {
    width: 50%;
    display: flex;
    justify-content: flex-start;
    margin: 0px;
}

.buttonDiv {
    width: 15%;
    display: flex;
    justify-content: flex-end;
}

.flexDivPlanCard {
    display: flex;
    border-top: 1px solid #00000029;

    height: auto;
}

.flexDivPlanCard .availableSpeedMainDiv {
    // padding-top: 50px;
    border-bottom: none;
    width: 25%;
    text-align: center;
    border-right: 1px solid #00000029;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.flexDivPlanCard .planRateDiv {
    padding-top: 50px;
    border-bottom: none;
    width: 25%;
    text-align: center;
}

.pricing {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 25%;
}
