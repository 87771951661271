.main-div-TrustedPros {
    box-shadow: 0px 3px 10px #00000029;
    border: 1px solid #e2e2e2;
    border-radius: 4px;
    opacity: 1;
    max-height: 367px;
    // border: 1px solid #009cbd !important;

    width: 46%;
    font-family: 'Roboto';
    @media (max-width: 1024px) {
        width: 46%;
    }
    @media (max-width: 768px) {
        width: 40%;
    }
    @media (max-width: 600px) {
        width: 50%;
    }
    @media (max-width: 600px) {
        width: 100%;
    }
}
.SubmitTrust {
    border: 1px solid #009CBD;
    background: #F1F9FB 0% 0% no-repeat padding-box;
    max-height: 367px;
}
.SubmitTrust1{
    border: 1px solid #00D56D;
    background: #f0fbf5 0% 0% no-repeat padding-box;
    max-height: 367px;

}
.SubmitTrustSucess {
    height: 146px;
    background: #f0fbf5 0% 0% no-repeat padding-box;
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: 40px;
    .sub-div-trust-img {
        display: flex;

        justify-content: center;
    }
}
.sub-div-trust {
    height: 146px;
    background: #7c8da0 0% 0% no-repeat padding-box;
    .sub-div-trust-img {
        display: flex;
        justify-content: center;
    }
}
.sub-div-Info {
    height: 221px;
    padding: 10px 22px 0px 22px;
    font-family: 'Roboto';
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .sub-div-divide {
        .sub-div-homePro {
            text-align: left;
            font-size: 18px;
            font-weight: bold;
        }
        .sub-div-homeProDetail {
            color: #6b6c6f;
            font-size: 14px;
            text-align: left;
            line-height: 24px;
        }
    }
    .sub-div-button-Requested {
        margin-bottom: 16px;
    }
}
