.blogCardMain {
    width: 345px;
    height: 430px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 3px;
    box-shadow: 0px 1px 3px #0000000A;
    border: 0.5px solid #E0E0E0;
    opacity: 1;
    margin: 10px;
    padding: 10px;
    position: relative;

    &:first-child {
        margin-left: 0;
    }

    @media screen and (min-width: 600px) {
        // width: 70%;
        width: 98%;
        height: 430px;
    }
    @media screen and (max-width: 374px) {
        width: 300px;
    }

    .blogCardContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .blogCardContainerText {
            font-weight: bold;
            text-align: center;
            margin: 10px auto;
            font-family: 'Roboto';
            font-size: 12px;

        }

        .blogImgCover {
            img {
                width: 100%;
                @media(max-width: 340px) {
                    width: 265px;
                }
            }
        }
    


        .blogCardContainerContent {
            text-align: 'center';

            font-family: 'Roboto';

            font-size: 20px;
            margin: 10px;

            @media(max-width: 375px) {
                font-size: 18px;
            }
            @media(max-width: 340px) {
                font-size: 16px;
            }
        }


        .blogImage {
            display: 'flex';
            width: 100%;
            height: 100px;
        }
    }

    .blogCardContainerReadMore {
        position: absolute;
        bottom: 0;
        margin: 10px;
        font-size: 14px;
        cursor: pointer;

        span {
           color: #0066F5;
           font-size: 14px;
        }
    }
}

.EquityBanner {
    width: 99%;
    height: auto;
    background: #D92F250D 0% 0% no-repeat padding-box;
    border-radius: 4px;
    box-shadow: null;
    border: 1px solid #D92F25;
    opacity: 1;
    margin: 15px auto;
    margin-left: 0;
    padding: 10px 10px;
    display: flex;
    justify-content: space-around;

    @media(max-width: 768px) {
        width: 100%;
        margin-right: 0px;
    }
    @media(max-width: 480px) {
        flex-direction: column;
    }

    .equityBannerTitle {
        margin: 0px 20px;
        font-weight: bold;
        font-size: 18px; 
        line-height: 30px;

        @media(max-width: 480px) {
            line-height: 24px;
            font-size: 16px; 
        }

        .bannerDescription {
            color: #6B6C6F;
            font-weight: normal; 
            font-size: 14px;
            @media(max-width: 480px) {
                font-size: 12px;
                line-height: 20px;
            }
        }
    }
}



.blogCardMainMobile {
    background: 0% 0% no-repeat padding-box;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #E0E0E0;
    border-radius: 4px;
    opacity: 1;
}