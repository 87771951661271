.servicePageMain{
    padding: 0 80px;


    @media (max-width: 1366px) {
        padding: 0px 60px;
    }

    @media (max-width: 600px) {
        padding: 0px 10px;
    }
    text-align: center;

    .main-heading{
        font-size: 30px;
        font-weight: bold;
    }
    .sub-heading{
        opacity: 0.8;
        padding: 10px 0;
    }
    .service-cards-div{
        display: flex;
        text-align: left;
        justify-content: space-between;
    }
    .service-card-div{
        width: 32%;
    }
    .button-div{
        padding: 20px 0;
    }
}