.welcome-card-main {
    border-radius: 5px;
    background-color: #fff;

    @media (max-width: 425px) {
        width: 340px !important;
        // height: 215px !important;
    }
}

.welcome-image {
    padding: 1.5px 20px;
    height: 92%;
    @media (max-width: 425px) {
        width: 120px !important;
        padding: 0px 12px;
        // height: 100px !important;
        // margin-top: 20%;
    }
}

.main-cards {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
}

.main-card-1 {
    width: 40%;

    @media (max-width: 425px) {
        width: 40%;
    }
}
.left-divD {
    @media (max-width: 600px) {
        margin-left: 10px;
    }
}
.main-card-2 {
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    // margin-left:20px;
    padding: 15px;

    @media (max-width: 425px) {
        width: 60%;
        padding: 10px 10px 0 0;
    }
}

.main-title {
    font-size: 16px;
    font-weight: bold;
    margin: 10px 0px;
}

.main-subtitle {
    font-size: 12px;
    // margin-bottom:10px ;
    width: 80%;

    @media (max-width: 425px) {
        width: 100%;
    }
}

.main-button-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 20px;

    @media (max-width: 425px) {
        margin-top: 20px;
    }
}
