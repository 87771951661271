.callPageMain{
    width: 100%;
    height: fit-content;
    // margin-top: -70px;


}




.iFrame{
    border: none;
    width: 100%;
    height: 90vh;
    overflow: hidden;

   
    .iframe{
        width: 100%;
        height: 100%;
        overflow: hidden !important;
        border: none;

   

     body{
         overflow: hidden !important;
     }

     #root{
         overflow: hidden !important;
     }
    }

 
 
      
}










.buttons-below{
    display: flex;
    padding: 20px;
    justify-content: center;
    align-items: center;
}