.cardDivChecklist {
    padding: 13px 18px 13px 23px;
    border: 1px solid #e8f2ff;
    background-color: #fafcff;
    border-radius: 5px;
    margin-bottom: 10px;
    min-height: 98px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .cardInnerContentChecklist {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .cardContentChecklist {
            width: 50%;

            .upperContentChecklist {
                display: flex;
                align-items: center;

                .upperContentMainChecklist {
                    color: #333333;
                    font-size: 14px;
                    font-weight: bold;
                }

                .upperContentBlueChecklist {
                    border: 1px solid #009cbd;
                    background-color: #f1f9fb;
                    color: #009cbd;
                    border-radius: 15px;
                    padding: 4px 7px;
                    font-size: 12px;
                    margin-left: 10px;

                    @media (max-width: 600px) {
                        margin-left: 0px;
                        margin-top: 10px;
                    }
                }

                @media (max-width: 600px) {
                    flex-direction: column;
                }
            }

            .upperContentParaChecklist {
                font-size: 14px;
                color: #969696;
                margin-top: 15px;
            }

            @media (max-width: 1024px) {
                width: 100%;
            }
        }

        .checklistCardHover {
            display: none;

            @media (max-width: 1024px) {
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 10px 0;

                .checklistDone {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    margin-right: 20px;
                }

                .circleDiv {
                    width: 30px;
                    height: 30px;
                    border: 1px solid #e8e8e8;
                    background-color: #ffffff;
                    border-radius: 50%;
                    margin-bottom: 5px;
                    padding: 10px;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                }

                .otherCircle {
                    width: 30px;
                    height: 30px;
                    border: 1px solid #009cbd;
                    background-color: #009cbd;
                    border-radius: 50%;
                    margin-bottom: 5px;
                    padding: 10px;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                }

                .otherCircle > svg {
                    fill: #ffffff;
                }

                .textChecklistCard {
                    color: #333333;
                    font-size: 12px;
                    font-weight: bold;
                }
            }

            @media (max-width: 600px) {
                width: 100%;
            }
        }

        .buttonCardChecklist {
            width: 113px;
            height: 53px;

            @media (max-width: 1024px) {
                margin-top: 10px;
            }

            @media (max-width: 600px) {
                width: 100%;
            }
        }
        .buttonCardChecklist1 {
            width: 150px;
            height: 53px;

            @media (max-width: 1024px) {
                margin-top: 10px;
            }

            @media (max-width: 600px) {
                width: 100%;
            }
        }

        @media (max-width: 1024px) {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    @media (max-width: 1024px) {
        padding: 10px;
        height: auto;
        flex-direction: center;
    }
}
.cardDivChecklist:hover {
    @media (min-width: 1024px) {
        border: 1px solid #006dff !important;
        border-radius: 5px;

        .checklistCardHover {
            display: flex;
        }

        .checklistDone {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-right: 20px;
        }

        .circleDiv {
            width: 30px;
            height: 30px;
            border: 1px solid #e8e8e8;
            background-color: #ffffff;
            border-radius: 50%;
            margin-bottom: 5px;
            padding: 10px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }

        .circleDiv:hover {
            border: 1px solid #009cbd;
            background-color: #009cbd;
        }
        .circleDiv:hover > svg {
            fill: #ffffff;
        }

        .otherCircle {
            width: 30px;
            height: 30px;
            border: 1px solid #009cbd;
            background-color: #009cbd;
            border-radius: 50%;
            margin-bottom: 5px;
            padding: 10px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }

        .otherCircle > svg {
            fill: #ffffff;
        }

        .textChecklistCard {
            color: #333333;
            font-size: 12px;
            font-weight: bold;
        }
    }
}
.DouglascardDivChecklist {
    .upperContentBlueChecklist {
        border: 1px solid #009cbd !important;
        color: #009cbd !important;
    }
    .checklistCardHover {
        @media (max-width: 1024px) {
            .otherCircle {
                border: 1px solid #009cbd !important;
                background-color: #009cbd !important;
            }
        }
    }
}
.DouglascardDivChecklist:hover {
    @media (min-width: 1024px) {
        border: 1px solid #009cbd !important;
        .circleDiv:hover {
            border: 1px solid #009cbd !important;
            background-color: #009cbd !important;
        }
        .otherCircle {
            border: 1px solid #009cbd !important;
            background-color: #009cbd !important;
        }
    }
}

.AddTaskDivChecklist {
    padding: 13px 18px 13px 23px;
    border: 1px solid #e8f2ff;
    background-color: #fafcff;
    border-radius: 5px;
    margin-bottom: 10px;
    height: 98px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .cardInnerContentChecklist {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .cardContentChecklist {
            width: 50%;

            .upperContentChecklist {
                display: flex;
                align-items: center;

                .upperContentMainChecklist {
                    color: #333333;
                    font-size: 18px;
                    font-weight: bold;
                }

                @media (max-width: 600px) {
                    flex-direction: column;
                }
            }

            @media (max-width: 1024px) {
                width: 100%;
            }
        }

        .checklistCardHover {
            display: none;

            @media (max-width: 1024px) {
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 10px 0;

                .checklistDone {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    margin-right: 20px;
                }

                .circleDiv {
                    width: 30px;
                    height: 30px;
                    border: 1px solid #e8e8e8;
                    background-color: #ffffff;
                    border-radius: 50%;
                    margin-bottom: 5px;
                    padding: 10px;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                }

                .otherCircle {
                    width: 30px;
                    height: 30px;
                    border: 1px solid #006dff !important;
                    background-color: #006dff !important;
                    border-radius: 50%;
                    margin-bottom: 5px;
                    padding: 10px;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                }

                .otherCircle > svg {
                    fill: #ffffff;
                }

                .textChecklistCard {
                    color: #333333;
                    font-size: 12px;
                    font-weight: bold;
                }
            }

            @media (max-width: 600px) {
                width: 100%;
            }
        }

        .buttonCardChecklist {
            width: 138px;
            height: 53px;

            @media (max-width: 1024px) {
                margin-top: 10px;
            }

            @media (max-width: 600px) {
                width: 100%;
            }
        }

        @media (max-width: 1024px) {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    @media (max-width: 1024px) {
        padding: 10px;
        height: auto;
    }
}
.AddTaskDivChecklistDoug {
    padding: 13px 18px 13px 23px;
    border: 1px solid #e8f2ff;
    background-color: #fafcff;
    border-radius: 5px;
    margin-bottom: 10px;
    height: 98px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .cardInnerContentChecklist {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .cardContentChecklist {
            width: 50%;

            .upperContentChecklist {
                display: flex;
                align-items: center;

                .upperContentMainChecklist {
                    color: #333333;
                    font-size: 18px;
                    font-weight: bold;
                }

                @media (max-width: 600px) {
                    flex-direction: column;
                }
            }

            @media (max-width: 1024px) {
                width: 100%;
            }
        }

        .checklistCardHover {
            display: none;

            @media (max-width: 1024px) {
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 10px 0;

                .checklistDone {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    margin-right: 20px;
                }

                .circleDiv {
                    width: 30px;
                    height: 30px;
                    border: 1px solid #e8e8e8;
                    background-color: #ffffff;
                    border-radius: 50%;
                    margin-bottom: 5px;
                    padding: 10px;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                }

                .otherCircle {
                    width: 30px;
                    height: 30px;
                    border: 1px solid #009cbd !important;
                    background-color: #009cbd !important;
                    border-radius: 50%;
                    margin-bottom: 5px;
                    padding: 10px;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                }

                .otherCircle > svg {
                    fill: #ffffff;
                }

                .textChecklistCard {
                    color: #333333;
                    font-size: 12px;
                    font-weight: bold;
                }
            }

            @media (max-width: 600px) {
                width: 100%;
            }
        }

        .buttonCardChecklist {
            width: 138px;
            height: 53px;

            @media (max-width: 1024px) {
                margin-top: 10px;
            }

            @media (max-width: 600px) {
                width: 100%;
            }
        }

        @media (max-width: 1024px) {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    @media (max-width: 1024px) {
        padding: 10px;
        height: auto;
    }
}
.AddTaskDivChecklist:hover {
    @media (min-width: 1024px) {
        border: 1px solid #006dff !important;
        border-radius: 5px;

        .checklistCardHover {
            display: flex;
        }

        .checklistDone {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-right: 20px;
        }

        .circleDiv {
            width: 30px;
            height: 30px;
            border: 1px solid #e8e8e8;
            background-color: #ffffff;
            border-radius: 50%;
            margin-bottom: 5px;
            padding: 10px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }

        .circleDiv:hover {
            border: 1px solid #006dff !important;
            background-color: #006dff !important;
        }
        .circleDiv:hover > svg {
            fill: #ffffff;
        }

        .otherCircle {
            width: 30px;
            height: 30px;
            border: 1px solid #006dff;
            background-color: #006dff;
            border-radius: 50%;
            margin-bottom: 5px;
            padding: 10px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }

        .otherCircle > svg {
            fill: #ffffff;
        }

        .textChecklistCard {
            color: #333333;
            font-size: 12px;
            font-weight: bold;
        }
    }
}
.AddTaskDivChecklistDoug:hover {
    @media (min-width: 1024px) {
        border: 1px solid #009cbd !important;
        border-radius: 5px;

        .checklistCardHover {
            display: flex;
        }

        .checklistDone {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-right: 20px;
        }

        .circleDiv {
            width: 30px;
            height: 30px;
            border: 1px solid #e8e8e8;
            background-color: #ffffff;
            border-radius: 50%;
            margin-bottom: 5px;
            padding: 10px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }

        .circleDiv:hover {
            border: 1px solid #009cbd;
            background-color: #009cbd;
        }
        .circleDiv:hover > svg {
            fill: #ffffff;
        }

        .otherCircle {
            width: 30px;
            height: 30px;
            border: 1px solid #009cbd;
            background-color: #009cbd;
            border-radius: 50%;
            margin-bottom: 5px;
            padding: 10px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }

        .otherCircle > svg {
            fill: #ffffff;
        }

        .textChecklistCard {
            color: #333333;
            font-size: 12px;
            font-weight: bold;
        }
    }
}
.checklistCompleteButton {
    display: flex;
    @media (max-width: 1024px) {
        margin-top: 15px;
    }
    @media (max-width: 600px) {
        justify-content: center;
        width: 100%;
    }
}

.checklist-overdue-content-header1 {
    display: flex;
    flex-direction: row;
    margin: 30px 0px;

    .checklist-overdue-select {
        width: 35%;
        .css-1pahdxg-control {
            border-color: #006dff !important;
        }
        .css-1pahdxg-control:hover {
            border-color: #006dff !important;
        }
        .react-select .css-1pahdxg-control:focus {
            border-color: #006dff !important;
        }
    }

    .checklist-overdue-selectDoug {
        .css-1pahdxg-control {
            border-color: #009cbd !important;
        }

        .css-1pahdxg-control:hover {
            border-color: #009cbd !important;
        }
        .react-select .css-1pahdxg-control:focus {
            border-color: #009cbd !important;
        }
    }

    .checklist-overdue-weekrange {
        color: #333333;
        font-size: 24px;
        font-family: Roboto;
        font-weight: bold;
        text-align: center;
        padding: 0px 10px;
        @media (max-width: 600px) {
            padding: 0;
        }
    }

    .checklist-overdue-carousel-icon,
    .checklist-overdue-carousel-icon-disabled {
        border: 1px solid #006dff !important;
        border-radius: 4px;
        padding: 2px 0px 0px 0px;
        cursor: pointer;
    }
    .checklist-overdue-carousel-iconDoug,
    .checklist-overdue-carousel-icon-disabled {
        border: 1px solid #009cbd !important;
        border-radius: 4px;
        padding: 2px 0px 0px 0px;
        cursor: pointer;
    }

    .checklist-overdue-carousel-icon-disabled {
        border: 1px solid #d8dce6;
        cursor: not-allowed;
    }

    .checklist-overdue-carousel-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        padding-bottom: 20px;
    }

    .checklist-overdue-showme {
        color: #009cbd;
        padding-left: 6px;
    }

    .checklist-overdue-carousel-text {
        color: #333333;
        font-size: 14px;
        font-family: Roboto;
        text-align: center;
    }

    @media (max-width: 768px) {
        margin: 0;
        flex-direction: column;
        .checklist-overdue-menu-wrapper {
            width: 28%;
        }
        .checklist-overdue-carousel-text {
            margin-bottom: 15px;
            width: 96%;
        }
    }
    @media (max-width: 600px) {
        .checklist-overdue-menu-wrapper,
        .checklist-overdue-carousel-text,
        .checklist-overdue-select {
            width: 100%;
            display: flex;
            justify-content: center;
            padding-bottom: 12px;
        }
    }

    .addressFlexDiv {
        display: flex;
        justify-content: flex-start;
        margin: 0px;
        .react-select {
            width: 230px;
            height: 54px;
        }

        @media (max-width: 768px) {
            margin: 15px 0px 25px 0;
        }
        @media (max-width: 600px) {
            width: 100%;
            margin: 15px 0px 15px 0;
            .react-select {
                width: 100%;
            }
        }
    }
    .addressFlexDiv > div {
        @media (max-width: 600px) {
            width: 100%;
        }
    }
    .addressFlexDiv .select-state__menu {
        z-index: 1000;
        text-align: left;
    }
    .addressFlexDiv .react-select .select-state__control .select-state__indicators .select-state__indicator:after {
        display: none;
    }
    .addressFlexDiv .react-select .select-state__control .select-state__indicators svg {
        display: inline;
        // margin-top: 10px;
    }
    .addressFlexDiv .react-select div {
        font-size: 16px;
    }
}

// .css-1pahdxg-control {
//     border: 1px solid #009cbd !important;
// }
