.dashboard-main-page-home-owner {

    @media(max-width: 420px) {
        overflow: hidden;
    }
    .dashboardHeader-home-owner {
        background-image: linear-gradient(white, #ededed);

        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        padding: 140px 0px 0px 80px;

        .welcomeTextDiv1 {
            .welomeText1 {
                @media(max-width: 375px) {
                    font-size: 14px !important;
                }
            }
            .nameDiv1 {
                font-family: Poppins !important;
                font-weight: 700 !important;
                @media(max-width: 375px) {
                    font-size: 20px !important;
                }
            }
        }

        @media (max-width: 1200px) {
            padding: 70px 0px 0px 80px;
            justify-content: space-evenly;
        }

        @media (max-width: 1366px) {
            padding: 170px 60px 0px 60px;
            justify-content: space-between;
        }

        @media (max-width: 800px) {
            padding: 40px 0px 15px;
            background-image: none;
            display: block;
        }

        .dashboardHeaderTitleDiv-home-owner {
            width: 40%;

            @media (max-width: 1200px) {
                width: 50%;
            }

            @media (max-width: 800px) {
                width: 98%;
                margin: 0 auto;
            }
            .welcomeTextDiv1 {
                margin-left: 10px;
                .welomeText1 {
                    margin: 0px auto 0px;
                    text-align: left;
                    font: normal normal 100 22px/32px Roboto;
                    letter-spacing: 0px;
                    color: #6b6c6f;
                    opacity: 1;
                }
                .nameDiv1 {
                    margin: 0px auto 10px;
                    text-align: left;
                    font: normal normal bold 32px/30px Roboto;
                    letter-spacing: 0px;
                    color: #333333;
                    opacity: 1;
                }
            }
        }

        .dashboardHeaderTitleDiv-home-owner {
            .card-section-1 {
                width: 100%;
                border: none;
                padding: 0px;
            }

            .overflow-auto1 {
                position: relative;
            }

            .card-height-width-2 {
                height: auto !important;

                @media (max-width: 768px) {
                    width: 96%;
                }
            }

            .pencilIcon {
                margin-left: 10px;
                font-size: 14px;
                color: #333333;
            }

            .addressDiv {
                display: flex;
                margin-top: 20px;

                .zipcode {
                    font-size: 16px;
                    line-height: 22px;
                    font-weight: bold;
                    color: #333333;
                    margin-right: 10px;
                }

                .street {
                    font-size: 16px;
                    line-height: 24px;
                    color: #333333;
                }
            }
        }

        .dashboardImgDiv-home-owner {
            width: 70%;

            margin: 0px 30px;

            display: flex;
            align-items: flex-end;

            @media (max-width: 1200px) {
                width: 60%;
            }
            @media (max-width: 1366px) {
                width: 60%;
                margin: 0px 0px 0px 30px;
            }

            @media (max-width: 800px) {
                width: 90%;
                margin: auto;

                display: flex;
                align-items: flex-end;
            }

            img {
                width: 100%;
            }
        }

        @media (max-width: 600px) {
            background-image: linear-gradient(53deg, #fdfbfb, #ebedee);
        }
    }
    .dashboardBottom1 {
        background-color: #fbf9f9;
        width: 100%;
        .dashboardBlocks {
            padding: 20px 90px;

            .blocksTitleDiv {
                display: flex;

                margin-left: -10px;
                justify-content: flex-start;
                @media screen and (max-width: 1000px) {
                    margin-left: 2px;
                }
                .blocksIconRed {
                    color: red;
                    margin-right: 13px;
                    font-size: 35px;

                    @media (max-width: 1024px) {
                        font-size: 23px;
                        margin-right: 8px;
                    }
                }

                .blocksIconGrey {
                    color: #6b6c6f;
                    margin-right: 13px;
                    font-size: 35px;
                }

                .blocksTitle {
                    font-size: 24px;
                    line-height: 32px;
                    font-weight: bold;
                    color: #333333;

                    @media (max-width: 1024px) {
                        font-size: 18px;
                        line-height: 24px;
                    }
                }
            }

            @media (max-width: 1024px) {
                padding: 0px 60px;
            }

            @media (max-width: 768px) {
                padding: 0 30px 40px 30px;
            }

            @media (max-width: 600px) {
                padding: 0 10px 40px 10px;
            }
        }
    }
    .dashboardBottom {
        padding: 0px 90px;

        .dashboardBlocks {
            margin: 35px 0;
            .blocksTitleDiv {
                display: flex;
                align-items: center;

                .blocksIconRed {
                    color: red;
                    margin-right: 13px;
                    font-size: 35px;

                    @media (max-width: 1024px) {
                        font-size: 23px;
                        margin-right: 8px;
                    }
                }

                .blocksIconGrey {
                    color: #6b6c6f;
                    margin-right: 13px;
                    font-size: 35px;
                }

                .blocksTitle {
                    font-size: 24px;
                    line-height: 32px;
                    font-weight: bold;
                    color: #333333;

                    @media (max-width: 1024px) {
                        font-size: 18px;
                        line-height: 24px;
                    }
                }
            }

            .homeserviceCardFlexDiv1 {
                margin-top: 20px;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                // justify-content: space-between;
                background-color: #ffffff;
                .homeServiceCardDiv {
                    min-width: 30%;
                    border: 1px solid #dadce0;
                    margin-right: 14px;
                    margin-bottom: 14px;
                    border-radius: 4px;

                    @media (max-width: 600px) {
                        margin-right: 0px !important;
                    }

                    .homeServiceCardBottomDiv {
                        // height: 82%;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        // border: 1px solid #dadce0;
                        padding-bottom: 20px;

                        .buttonDivhomeOwnerDashboard {
                            display: flex;
                            justify-content: center;

                            @media (max-width: 1024px) {
                                .buttonhomeOwnerDashboard {
                                    height: 35px !important;
                                    font-size: 12px !important;
                                    width: 130px !important;
                                }
                            }
                        }

                        @media (max-width: 768px) {
                            padding-bottom: 10px;
                        }
                    }

                    @media (max-width: 600px) {
                        width: 100%;
                        margin-bottom: 10px;
                    }
                }

                @media (max-width: 600px) {
                    flex-wrap: wrap;
                }
            }

            .homeserviceCardFlexDiv {
                margin-top: 20px;
                display: flex;
                justify-content: space-between;
                background-color: #ffffff;

                .homeServiceCardDiv {
                    width: 32%;
                    border: 1px solid #dadce0;
                    margin-right: 10px;
                    border-radius: 4px;

                    @media (max-height: 600px) {
                        margin-right: 0px;
                    }

                    .homeServiceCardBottomDiv {
                        // height: 82%;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        // border: 1px solid #dadce0;
                        padding-bottom: 20px;

                        .buttonDivhomeOwnerDashboard {
                            display: flex;
                            justify-content: center;

                            @media (max-width: 1024px) {
                                .buttonhomeOwnerDashboard {
                                    height: 35px !important;
                                    font-size: 12px !important;
                                    width: 130px !important;
                                }
                            }
                        }

                        @media (max-width: 768px) {
                            padding-bottom: 10px;
                        }
                    }

                    @media (max-width: 600px) {
                        width: 100%;
                        margin-bottom: 10px;
                    }
                }

                @media (max-width: 600px) {
                    flex-wrap: wrap;
                }
            }

            .upcomingTaskFlexDiv {
                display: flex;
                justify-content: space-between;
                margin: 20px 0;
                color: #333333;
                background-color: #ffffff;

                .upcomingTaskLeftDiv {
                    width: 49%;

                    @media (max-width: 600px) {
                        width: 100%;
                        margin-bottom: 12px;
                    }
                }

                .upcomingTaskRightDiv {
                    width: 47%;
                    margin-right: 20px;

                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    @media (max-width: 600px) {
                        width: 100%;
                        margin-right: 0px;
                    }
                }

                @media (max-width: 600px) {
                    flex-wrap: wrap;
                }
            }

            @media (max-width: 600px) {
                margin: 15px 0;
            }
        }

        @media (max-width: 1024px) {
            padding: 0px 60px;
        }

        @media (max-width: 768px) {
            padding: 0 30px 10px 30px;
        }

        @media (max-width: 600px) {
            padding: 0 10px 10px 10px;
        }
    }
}

.homeOwnerDashboardFormModal {
    background: #fff;
    width: 100%;
    height: auto;
    position: absolute;
    top: 0%;
    max-height: 100vh;
    min-height: 100vh;
    overflow: auto;

    @media (max-width: 600px) {
        width: 93%;
        min-height: 100%;
        top: 0%;
        max-height: auto;
        background: #ffff;
        padding: 15px;
    }
}

.react-joyride__tooltip {
    width: 95% !important;
}

.imageChanges {
    display: flex;
    flex-direction: row;
    height: 100%;
    justify-content: space-between;
}

.homeValuationSub {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: stretch;
    justify-content: space-between;
    @media screen and (max-width: 768px) {
        flex-direction: column;
    }
}

.sectionTitle {
    font-size: 24px;
    line-height: 32px;
    font-weight: bold;
    color: #333333;
    padding: 0 0 15px 15px;

    @media (max-width: 1024px) {
        font-size: 18px;
        line-height: 24px;
    }
}

.light-12px-font {
    text-align: left;
    font-size: 12px;
    letter-spacing: 0px;
    color: rgba(107, 108, 111, 1);
    text-transform: capitalize;
    opacity: 1;
}

.bold-14px-font {
    text-align: left;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0px;
    color: rgba(51, 51, 51, 1);
    text-transform: capitalize;
    opacity: 1;
}
.learnMore {
    display: flex;
    align-items: right;
    justify-content: flex-end;

    margin: 16px 0px 6px 4px;

    @media (max-width: 600px) {
        width: 100%;
        margin: auto;
    }

    .text {
        font-size: 12px;

        color: #006dff;
        text-decoration: none;

        @media screen and (max-width: 600px) {
            margin-top: 0px;
        }
    }
}
.homeValuationMain {
    display: flex;
    flex-direction: row;

    //   flex-wrap: nowrap;
    //   align-items:stretch;
    //   justify-content: space-between;
    margin-top: 20px;
    width: 100%;

    .specCardCoverRow {
        width: 100%;
    }

    @media screen and (max-width: 800px) {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .refinanceClass {
        width: 24%;
        max-width: 284px;
        box-shadow: 0px 3px 10px #00000029;
        border: 0.5px solid #dadce0;
        border-radius: 4px;
        opacity: 1;
        margin-right: 24px;
        @media screen and (max-width: 1200px) and (min-width: 800px) {
            width: 47%;
        }

        @media screen and (max-width: 800px) {
            width: 94%;
            margin: 10px auto;
        }
        @media(max-width: 375px) {
            width: 92%;
        }
    }
    .homeImprovementClass {
        width: 24%;
        max-width: 284px;
        box-shadow: 0px 3px 10px #00000029;

        border: 0.5px solid #dadce0;
        border-radius: 4px;
        position: relative;
        background-color: #273e59;
        margin-right: 22px;

        @media(max-width: 375px) {
            width: 92% !important; 
        }
        .homeImprovementClassImage {
            position: absolute;
            bottom: 0px;
            right: 6px;
            .main--head-div-home-imporvement {
                @media(max-width: 768px) {
                    font-size: 24px !important;
                }
            }
            @media screen and (max-width: 730px) {
                display: flex;
                align-items: flex-end;
                justify-content: flex-end;
                padding-top: 15px;
                position: relative;
                bottom: 0;
            }
        }

        @media screen and (max-width: 1200px) and (min-width: 800px) {
            width: 47%;
        }
        @media screen and (max-width: 800px) {
            width: 94%;
            margin: 10px auto;
        }
    }

    .homeCardUpperDiv {
        .homeCardUpperDiv1 {
            color: #8d8c8c;
            font-family: 'Roboto';
            font-size: 16px;
        }

        .Main {
            margin-top: 0px;
            @media(max-width: 375px) {
                display: flex;
                flex-direction: column;

                .MainBox1 {
                    text-align: center;
                }
                .MainBox2 {
                    .loan-details-grp1-info-title {
                        font-size: 16px !important;
                        text-align: center;
                    }
                    .loan-details-grp1-info-description {
                        width: 100%;
                    }

                    button {
                        width: 100% !important;
                    }
                }
            }
           
        }

    }

    .homeCardEstimate {
        width: 50%;
        flex: 1;
        padding: 0;
        @media screen and (max-width: 1200px) and (min-width: 800px) {
            width: 100%;
        }
        @media screen and (max-width: 800px) {
            width: 96%;
            margin: auto;
        }

        @media(max-width: 375px) {
            width: 98% !important;
        }

        // @media screen (max-width:2300px) and (max-width: 600px){
        //       width: 47%;
        // }
        .homeCardUpperDiv {
            background: #ffffff 0% 0% no-repeat padding-box;
            box-shadow: 0px 3px 10px #00000029;
            border: 0.5px solid #dadce0;
            border-radius: 4px;
            opacity: 1;

            padding: 21px 20px 24px 20px;
            @media screen and (max-width: 600px) {
                background: #ffffff 0% 0% no-repeat padding-box;
                box-shadow: 0px 1px 3px #0000000a;
                border: 0.5px solid #dadce0;
                border-radius: 4px;
                opacity: 1;
            }

            .homeCardUpperDiv2 {
                .homeCardUpperInnerDivHeadingText {
                    font-size: 12px;
                    font-weight: medium;
                    font-family: 'Roboto';
                    letter-spacing: 0px;
                    color: #6b6c6f;
                    text-transform: capitalize;
                    opacity: 1;
                    @media screen and (max-width: 600px) {
                        font-size: 10px;
                        font-weight: medium;
                        font-family: 'Roboto';
                    }
                }
                .homeCardUpperInnerDivSubHeadingText {
                    font: normal normal bold 14px/24px Roboto;
                    letter-spacing: 0px;
                    color: #333333;
                    text-transform: capitalize;
                    opacity: 1;
                }
            }
        }

        .homeCardMain {
            display: flex;
            justify-content: space-between;
            width: 100%;
            height: 100%;
            @media screen and (max-width: 600px) {
                flex-direction: column;
                width: 100%;
            }
            .homeCardMainBlock {
                flex: 0.47;
                margin: 26px 0px 0px;
                @media screen and (max-width: 600px) {
                    margin: 14px 0px 0px;
                }
            }
        }
        .homeCardStyle {
            background: #ffffff 0% 0% no-repeat padding-box;
            box-shadow: 0px 3px 10px #00000029;
            border: 0.5px solid #dadce0;
            border-radius: 4px;
            opacity: 1;
            @media screen and (max-width: 600px) {
                background: #ffffff 0% 0% no-repeat padding-box;
                box-shadow: 0px 1px 3px #0000000a;
                border: 0.5px solid #dadce0;
                border-radius: 4px;
                opacity: 1;
            }
        }

        @media (max-width: 600px) {
            width: 98%;
        }
    }
}

.loan-details-grp1-info-title {
    text-transform: capitalize !important;
}

.Disclammer {
    text-align: center;
    font: normal normal normal 11px/22px Roboto;
    letter-spacing: 0px;
    color: #6b6c6f;
    text-transform: capitalize;
    opacity: 1;
    margin-bottom: 4px;
}
