.main-div-HomeWarantyDetails {
    display: flex;
    width: 100%;
    .main-cards-homewarantydetails {
        display: flex;
        // flex-wrap: wrap;
        padding-bottom: 20px;
        width: 75%;
        overflow-x: auto;
        padding-left: 2px;
        @media (max-width: 768px) {
            width: 100%;
        }
        .card-2-homewarantydetails {
            width: 30%;
            border-top: 1px solid #e2e2e2;
            margin: 0px;
            @media (max-width: 768px) {
                width: 35%;
            }

            @media (max-width: 600px) {
                width: 90%;
            }
            // @media (max-width: 425px) {
            //     width: 50%;
            // }
            // @media (max-width: 325px) {
            //     width: 52%;
            // }
        }
        .card-3-homewarantydetails {
            width: 10%;

            margin: 0px;

            @media (max-width: 600px) {
                width: 10%;
            }
            @media (max-width: 425px) {
                width: 50%;
            }
            @media (max-width: 325px) {
                width: 52%;
            }
        }
    }
    .card-1-homewarantydetails {
        width: 25%;

        margin: 0px;
        .MuiSvgIcon-root {
            fill: #98bdf4 !important;
        }

        @media (max-width: 600px) {
            width: 80%;
        }
        // @media (max-width: 425px) {
        //     width: 50%;
        // }
        // @media (max-width: 325px) {
        //     width: 52%;
        // }
    }
    .comman-subContent-style {
        height: 66px;
        font-size: 16px;
        font-family: Roboto;
        font-weight: bold;
        background-color: #afcdfb36;
        // border-bottom: 15px solid #fff;
        display: flex;
        align-items: center;
        // justify-content: center;
        text-align: left;
        padding: 10px 0px 10px 60px;
        
        @media (max-width: 1024px) {
            padding-left: 32px;
        }
        @media (max-width: 768px) {
            padding-left: 0px;
            font-size: 12px;
        }
    }
    .style-color-even {
        background-color: #fff;
    }
    .comman-subContent-inside {
        height: 66px;
        font-size: 16px;
        font-family: Roboto;
        font-weight: 500;
        padding: 10px 0px 10px 60px;
        border-bottom: 1px solid #e2e2e2;
        display: flex;
        align-items: center;
        // justify-content: center;
        text-align: left;
        @media (max-width: 1024px) {
            padding-left: 39px;
            font-size: 12px;
        }
        @media (max-width: 768px) {
            padding-left: 9px;
            font-size: 12px;
        }
    }
    .comman-subContent-inside1 {
        height: 66px;
        font-size: 16px;
        font-family: Roboto;
        font-weight: 500;
        // padding-left: 60px;
        padding: 10px 0px;
        border-bottom: 1px solid #e2e2e2;
        display: flex;
        align-items: center;
        justify-content: center;
        // justify-content: center;
        // text-align: left;
        @media (max-width: 768px) {
            // padding-left: 20px;
            font-size: 12px;
        }
    }

    .brand-title-waranty {
        height: 218px !important;
        font-weight: bolder;
        text-align: center;
        font-size: 24px;
        color: #333;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        @media (max-width: 600px) {
            height: 176px !important ;
        }
        @media (max-width: 375px) {
            height: 154px !important ;
        }
        .icon-waranty-view {
            padding-top: 20px;
            font-size: 22px;
            height: 86px;
            overflow-y: scroll;

            &::-webkit-scrollbar {
                width: 4px;
                height: 3px;
            }
    
            &::-webkit-scrollbar-track {
                background: transparent;
            }

            &::-webkit-scrollbar-thumb {
                background-color: lightgray;
            }

            @media (max-width: 768px) {
                font-size: 20px;
            }
            @media (max-width: 600px) {
                font-size: 16px;
                height: 45px;
            }
            @media (max-width: 375px) {
                font-size: 12px;
                height: 27px;
                margin: 0px 12px;
            }

            .shield {
                color: #6b6c6f;
                font-family: 'Roboto';
                font-size: 16px;
                line-height: 32px;
                font-weight: bold;

                img {
                    height: 50px;
                }

                .firstLetter {
                    margin-top: -52px;
                    // margin-left: 20px;
                }
            }
        }

        &.empty-title-box {
            margin-bottom: 1px;
        }
    }
    .recommended-waranty-card {
        height: 39px;

        .inner-recommend {
            background-color: #009cbd;
            font-weight: bold;
            font-size: 16px;
            font-family: Roboto;
            height: 39px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
        }
    }
    .monthly-fees {
        text-align: center;
        font-weight: bold;
        font-size: 16px;
        padding: 10px 0 20px 0;
        border-bottom: 1px solid #e2e2e2;
    }
    .dollar-plan-price {
        font-size: 30px;
        font-weight: 500;
        font-family: Roboto;
        padding-bottom: 10px;
        span {
            font-weight: normal;
        }
        @media (max-width: 768px) {
            font-size: 28px;
        }
        @media (max-width: 600px) {
            font-size: 24px;
        }
        @media (max-width: 375px) {
            font-size: 18px;
        }
    }
    .submitButtonView {
        margin-bottom: 10px;
        @media (max-width: 375px) {
            margin: 0px 11px;
        }
    }
    .submitButtonViewDetail {
        width: 130px !important;
        @media (max-width: 375px) {
            width: 110px !important;
        }
    }

    .card-2-homewarantydetails {
        @media (max-width: 600px) {
            box-shadow: 4px 0 5px -2px #e2e2e2;
        }
    }

    .card-2-homewarantydetails {
        width: 25%;

        @media (max-width: 1024px) {
            width: 40%;
        }
        @media (max-width: 600px) {
            width: 88%;
        }
    }
}
