.TableContainer {
    width: 100%;
    margin: auto;
    //    @media screen and (max-width: 1360px) and (min-width: 600px){
    //         width:8%;
    //         margin:auto;
    //     }
    .TableHeading {
        text-align: left;
        font-size: 12px;
        font-weight: bold;
        font-family: 'Roboto';
        padding: 20px 0px;
        letter-spacing: 0px;
        color: #6b6c6f;
        opacity: 1;
    }
    .TableConatinerText {
        text-align: left;
        font-size: 14px;
        font-weight: 600;
        font-family: 'Roboto';
        padding: 20px 0px;
        border-top: 0.5px solid #dadce0;
        img {
            margin-top: -5px;
        }

        color: #333333;
        text-transform: capitalize;
        opacity: 1;

        @media(max-width: 1280px) {
            button {
                width: 130px !important;
            }
        }
    }

    table {
        @media(max-width: 768px) {
            width: 100%;
        }
    }
}
