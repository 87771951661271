.homeServiceCardMain {
    // border: 1px solid #dadce0;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
    // padding: 20px 40px;

    .homeServiceCardInnerDiv {
        text-align: center;

        @media (max-width: 600px) {
            text-align: left;
        }
    }

    .image {
        height: 160px;

        .imageHomeService {
            width: 100%;
            max-height: 120px;
        }

        // @media (max-width: 1366px) {
        //     width: 125px;
        // }

        @media (max-width: 1024px) {
            height: auto;
            .imageHomeService {
                height: 110px;
            }
        }
        @media (max-width: 768px) {
            padding: 10px;
            .imageHomeService {
                height: 60px;
            }
        }
    }

    .titleHomeService {
        font-size: 22px;
        line-height: 30px;
        font-weight: bold;
        color: #333333;
        @media (max-width: 1024px) {
            font-size: 18px;
            line-height: 24px;
        }
        @media (max-width: 768px) {
            font-size: 18px;
        }
    }

    .descriptionHomeService {
        color: #6b6c6f;
        font-size: 14px;
        line-height: 24px;
        // text-align: center;
        margin: 15px 0;
        max-width: 340px;

        @media (max-width: 1024px) {
            font-size: 12px;
            line-height: 20px;
            margin: 10px 0;
        }
    }

    .buttonDivHomeService {
        button {
            border-radius: 4px !important;
        }
    }
    @media (max-width: 1024px) {
        padding: 10px 20px;

        .buttonDivHomeService {
            .buttonHomeService {
                height: 40px !important;
                font-size: 12px !important;
                width: 130px !important;
            }
        }
    }
   

    @media (max-width: 600px) {
        flex-direction: row-reverse;
    }
}
