.reserveStorageThankYouMainPage {
    display: flex;
    justify-content: center;
    margin-top: 60px;
    flex-direction: column;
    align-items: center;

    @media (max-width: 425px) {
        margin-top: 35px;
    }
}

.reserveStorageThankYouHeading {
    font-size: 24px;
    line-height: 28px;
    font-weight: bold;

    @media (max-width: 1024px) {
        font-size: 22px;
        line-height: 31px;
        width: 66%;
        text-align: center;
        margin-bottom: 11px;
    }

    @media (max-width: 768px) {
        font-size: 20px;
    }
}

.reserveStorageThankYouSubHeading {
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 30px;

    @media (max-width: 1024px) {
        font-size: 12px;
        line-height: 18px;
        width: 70%;
        text-align: center;
    }
}

.reserveStorageThankYouMainPage .selectedStorageDiv {
    @media (max-width: 1024px) {
        width: 65%;
    }

    @media (max-width: 768px) {
        display: block;
        width: 80%;
    }

    @media (max-width: 425px) {
        display: block;
        width: 100%;
    }
}

.reserveStorageThankYouMainPage .selectedStotageHeading {
    @media (max-width: 768px) {
        font-size: 18px;
    }
    @media (max-width: 425px) {
        border-bottom: none;
    }
}

.reserveStorageThankYouMainPage .storageDiv .unitCard {
    @media (max-width: 425px) {
        flex-wrap: wrap;
        justify-content: flex-start;
    }
}

.reserveStorageThankYouMainPage .storageDiv .unitCard .unitLogo {
    @media (max-width: 425px) {
        width: 30%;
    }
}
.reserveStorageThankYouMainPage .storageDiv .unitCard .unitTitleDiv {
    @media (max-width: 425px) {
        width: 69%;
    }
}

.reserveStorageThankYouMainPage .storageDiv .unitCard .unitCurrencyDiv {
    @media (max-width: 425px) {
        margin-top: 30px;
        width: 100%;
        justify-content: center;
    }
}

.ReserveStorageThankyouPageMainDiv {
    padding: 0 80px;

    // .douglusChanges {
    //     .tab-active {
    //         font-weight: 700;
    //         color: #009cbd !important;
    //         border-bottom: 3px solid #009cbd !important;
    //         cursor: pointer;
    //         padding-bottom: 10px;
    //     }
    // }

    // .moveeasychanges {
    //     .tab-active {
    //         font-weight: 700;
    //         color: #006dff !important;
    //         border-bottom: 3px solid #006dff !important;
    //         cursor: pointer;
    //         padding-bottom: 10px;
    //     }
    // }

    @media (max-width: 1366px) {
        padding: 0 60px;
    }

    @media (max-width: 425px) {
        padding: 0 10px;
    }
}


.tab-active {
    font-weight: 700;
    color: #009cbd ;
    border-bottom: 3px solid #009cbd ;
    cursor: pointer;
    padding-bottom: 10px;
}