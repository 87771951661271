.main-div-home-project {
    padding: 22px 10px;
    padding-top: 0px;
    position: relative;
    width: 182px;
    height: 100%;
    margin: 30px auto;
    margin-right: 0px;
    background: #273e59 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 10px #00000029;
    border: 0.5px solid #273e59;
    opacity: 1;
    border-radius: 4px;
    display: flex;
    flex-direction: column;


    @media (max-width: 600px) {
        width: 100%;
        margin: auto;
        padding: 10px 10px 10px 10px;
    }
    @media (max-width: 768px) {
        width: 100%;
    }

    @media (max-width: 480px) {
       height: 281px;
    }
    .main--head-div-home-project {
        font-size: 24px;
        line-height: 38px;
        margin: 20px 0px 0px;
        font-family: 'Roboto';
        font-weight: normal;
        text-align: left;
        color: rgba(255, 255, 255, 0.8);
        @media screen and (max-width: 600px) {
            font-size: 24px;
            line-height: 38px;
            width: 90%;
        }

        .main--headColor-div-home-project {
            font-weight: 700;
            color: rgba(255, 255, 255, 1);
        }
        padding-bottom: 17px;
    }
    .main--button-div-home-project {
        padding-bottom: 17px;
    }
    .main--image-div-home-project {
        position: absolute;
        bottom: 0;
        right: 0;

        img {
            width: 100%;
            @media(max-width: 732px) {
                width: 60%;
            }
        }
        
        @media(max-width: 730px) {
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
            padding-top: 15px;
            bottom: 0;
        }
        @media(max-width: 480px) {
            padding-top: 0px;
            position: relative;
            transform: translateY(-115px);
            img {
                    width: 56%;
            }
        }
        @media(max-width: 380px) {
            transform: translateY(-90px);
        }
        @media(max-width: 340px) {
            transform: translateY(-60px);
        }
    }
    // display: flex;
    // align-items: flex-end;
    // justify-content: center;
    // padding-top: 15px;

    // @media screen and (max-width: 600px) {
    //     padding-top: -10px;
    //     display: flex;
    // align-items: flex-end;
    // justify-content:flex-end;

    // }
}
