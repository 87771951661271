.documentryMainDiv {
    .documentDataImages {
        margin: 0px 10px;
        // width: 20%;
        width: 160px;
        // flex-direction: row;

        .documentDataValue {
            // width: 100%;
            text-align: center;
            cursor: pointer;
            width: 150px;

            .documentImagesInfo {
                img {
                    height: 100px;
                    // height: 240px;
                    max-height: 100%;
                    width: 100%;
                }
            }
        }

        @media (max-width: 600px) {
            width: 44%;
            margin: 10px;
        }
    }
    .scroll-cardsDocumentry {
        flex-wrap: wrap;
    }

    .titleButton {
        position: absolute;
        top: 230px;
        right: 0;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        color: #0066f5;
        align-items: center;
        font-size: 16px;
        line-height: 30px;
        margin-right: 55px;

        @media (max-width: 600px) {
            margin-right: 10px;
            top: 165px;
            right: 55px;
        }
    }
    .titleButtonNotFound {
        cursor: pointer;
        display: flex;
        // justify-content: space-between;
        color: #0066f5;
        align-items: center;
        font-size: 16px;
        line-height: 30px;
        justify-content: center;
    }
    .imageGuy {
        display: flex;
        justify-content: center;
    }
    .documentAdding {
        display: flex;
        margin: 0px auto;
        align-items: center;
        flex-direction: column;
    }
    .titleFlexDivDocument {
        display: flex;
        justify-content: space-between;
        padding-left: 37px;
    }
    .headingDocumentryNextStep {
        font-size: 24px;
        font-weight: bold;
        font-family: sans-serif;
        padding: 22px 0px 0px 0px;
        font-family: 'Roboto';
        display: flex;
        align-items: center;
        .arrowIconDivNext {
            margin-right: 12px;
            cursor: pointer;
            .MuiSvgIcon-root {
                height: 20px;
            }
        }

        @media (max-width: 1440px) {
            margin-top: 30px;
        }
        @media (max-width: 600px) {
            margin-top: 0px;
        }
    }

    .documentryPros {
        @media (max-width: 600px) {
            .MuiTabs-root {
                padding: 0 0px;
            }
        }
    }
}
