.ExtraPagesMainDiv {
    display: flex;
    align-items: center;
    justify-content: center;

    // background-image: url('../Assets/images/Badreq.svg');
    .ErrorNumber {
        font-size: 300px;
        font-weight: bold;
        text-align: center;
    }

    .ErrorTitle {
        font-size: 34px;
        font-weight: bold;
        color: #333333;
        text-align: center;
        font-family: Roboto;
        @media (max-width: 600px) {
            font-size: 21px;
            font-weight: 600;
            font-family: 'Roboto';
            text-transform: lowercase;
        }
    }
    .ErrorSubText {
        font-size: 22px;
        color: #333333;
        text-align: center;
        // width: 500px;
        line-height: 20px;
        font-family: Roboto;
        padding-top: 14px;
        @media (max-width: 600px) {
            font-size: 16px;

            font-family: 'Roboto';
        }
    }
    .ErrorBtn {
        margin-top: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 40px;
        @media (max-width: 600px) {
            flex-direction: column;
        }
        // flex-direction: column;
    }

    .buttonErrorRequest {
        width: 150px;
        @media (max-width: 600px) {
            width: 200px !important;
        }
    }
}
.scheduleCallExtra {
    position: absolute;
    width: 700px;
    height: 650px;
    background-color: #ffffff;
    left: 30%;
    top: 10%;
    .scheduleCallBack {
        display: none;
    }
    @media (max-width: 1440px) {
        left: 25%;
    }
    @media (max-width: 1024px) {
        left: 15%;
    }
    @media (max-width: 960px) {
        left: 5%;
    }
    @media (max-width: 600px) {
        left: 0;
        top: 0;
        z-index: 2000;
        width: 100%;
        height: 100%;
        .scheduleCallBack {
            display: block;
            padding: 30px 0 0 30px;
        }
    }
}
@media (min-width: 600px) {
    .badRequest {
        background: url('../Assets/images/Badreq.svg') no-repeat center center/cover;
        height: 100vh;
        width: 100vw;
    }
    .serverError {
        background: url('../Assets/images/backGroundErrorImage.svg') no-repeat center center/cover;
        height: 100vh;
        width: 100vw;
    }
    .fourHunderedImagesCheck {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .mobilefourHunderedImages {
        display: none;
    }
}
@media (max-width: 600px) {
    .badRequest {
        background: url('../Assets/images/mobileBadRequest.svg') no-repeat center center/cover;
        height: 100vh;
        width: 100vw;
    }
    .serverError {
        background: url('../Assets/images/mobileBackgroundError.svg') no-repeat center center/cover;
        height: 100vh;
        width: 100vw;
    }
    .fourHunderedImagesCheck {
        display: none;
    }

    .fourHunderedImages {
        display: none;
    }
    .mobilefourHunderedImages {
        display: unset;
        img {
            width: 100%;
        }
    }
}

// .badRequestMobile {

//     height: 100vh;
//     width: 100vw;
// }
.badRequestAlign {
    padding-top: 97px;
    @media (max-width: 600px) {
        padding-top: 120px;
    }
}
