.main-heading {
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    margin-top: 20px;
    margin-bottom: 10px;
}

.sub-heading {
    text-align: center;
    font-size: 16px;
    margin-bottom: 20px;
}

.selected-heading {
    text-align: left;
    font-weight: bold;
    margin-bottom: 20px;
}
.selected-movers-cards {
    display: flex;
    flex-direction: column;
    align-items: left;
}

.stepper-steps {
    width: 100%;
}

.wrapper {
    // text-align: center;
    // width: 40%;
    // margin-left: auto;
    // margin-right: auto;
    align-items: center;
    display: flex;
    justify-content: center;
}

.what-next {
    text-align: left;
    font-size: 20px;
    margin-bottom: 20px;
}
