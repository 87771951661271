.card-heading {
    color: #333333;
    font-size: 22px;
    font: Roboto;
    padding: 22px 18px;
}

.content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0px 18px;
    padding-bottom: 24px;
}

.content .logoDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.estimate {
    color: #333333;
    font-size: 12px;
    font: Roboto, Light;
    text-align: center;
    margin-top: 10px;
}

.premium {
    color: #333333;
    font-size: 16px;
    font-family: Roboto;
    padding-top: 8px;
}

.divider {
    border-bottom: 1px solid #d8dce6;
}

.premium-monthly {
    color: #333333;
    font-size: 48px;
    font-weight: 800;
    font-family: Poppins, Bold;
}

.coverageCurrency1 {
    font-size: 24px;
    margin-top: 10px;
}

@media (max-width: 600px) {
    .coverageCurrency1 {
        font-size: 16px;
        margin-top: 15px;
    }
}
